import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import AuthCallback from '../../auth/AuthCallback';
import AuthError from '../../auth/AuthError';
import { ClearAuthStorageComponent } from '../../auth/ClearAuthStorageComponent';
import Logout from '../../auth/Logout';
import { SessionExpiry } from '../../auth/SessionExpiry';
import * as RouteNames from '../../common/constants/RouteNames';
import AccountAdmin from '../../component/authentication/AccountAdmin.default';
import GenericError from '../../component/authentication/GenericError';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import RedirectToService from '../../component/authentication/RedirectToService';
import Unregistered from '../../component/authentication/Unregistered';
import RestrictedPolicyError from '../../component/securitySettings/subcomponents/RestrictedPolicyError';
import { TestConnection } from '../../component/users/subcomponents/TestConnection';
import App from '../App.default';
import PortalRedirectComponent from '../PortalRedirectComponent';
import ShellRouteWrapper from '../ShellRouteWrapper';

const routes: RouteObject[] = [
    {
        element: <App />,
        children: [
            {
                path: '/',
                element: <Navigate to="/portal_/cloudrpa" />,
            },
            {
                path: '/host',
                element: <Navigate to="/host/portal_/cloudrpa" />,
            },
            {
                path: '/portal_',
                element: <PortalRedirectComponent />,
            },
            {
                path: '/portal_/cloudrpa',
                element: <AccountAdmin />,
            },
            {
                path: '/portal_/logout',
                element: <Logout />,
            },
            {
                path: '/portal_/testconnection',
                element: <TestConnection />,
            },
            {
                path: '/portal_/restricted-policy',
                element: <RestrictedPolicyError />,
            },
            {
                path: '/portal_/unregistered',
                element: <Unregistered />,
            },
            {
                path: '/portal_/genericerror',
                element: <GenericError />,
            },
            {
                path: '/portal_/loginsuccess',
                element: <AuthCallback />,
            },
            {
                path: '/portal_/sessionexpired',
                element: <SessionExpiry />,
            },
            {
                path: '/portal_/autherror',
                element: <AuthError />,
            },
            {
                path: '/portal_/clearAuthStorage',
                element: <ClearAuthStorageComponent />,
            },
            {
                path: RouteNames.Root,
                element: <AccountAdmin />,
            },
            {
                path: RouteNames.CloudRPA,
                element: <AccountAdmin />,
            },
            {
                path: RouteNames.LoginSuccess,
                element: <AuthCallback />,
            },
            {
                path: RouteNames.RedirectToService,
                element: <RedirectToService />,
            },
            {
                path: RouteNames.PortalPrefix,
                element: <ShellRouteWrapper />,
            },
            {
                path: '/:accountName',
                element: <PortalRedirectComponent />,
            },
            {
                path: `${RouteNames.PortalPrefix}/*`,
                element: <ShellRouteWrapper />,
            },
            {
                path: '*', // Fallback for invalid urls
                element: <InvalidUrl />,
            },
        ],
    },
];

export default routes;
