import type { IRobotAndServicesLicenses } from '@experiences/interfaces';
import { get } from '@experiences/util';

export const licenseManagementAccountUrl = '/api/license/management/account';

export function getRobotsAndServices(accessToken: string, accountUserId: string, accountGlobalId: string) {
    return get<IRobotAndServicesLicenses>(`${licenseManagementAccountUrl}/available`, {
        accessToken,
        urlParams: {
            accountUserId,
            accountGlobalId,
        },
    });
}
