export function isBrowserIE() {
    // Internet Explorer 6-11
    const isIE = /* @cc_on!@ */ false || !!(document as any).documentmode;
    return isIE;
}

export function isBrowserSafari() {
    const isSafari = (function(p) {
        return p.toString() === '[object SafariRemoteNotification]';
    // @ts-ignore
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    return isSafari;
}
