import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import intersection from 'lodash/intersection';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import type { Row } from 'react-table';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IGroup } from '../../common/interfaces/cis/group';
import type { IGroupAllocations } from '../../common/interfaces/licenses';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import {
    deleteGroups,
    getGroups,
    getGroupsWithLicenses,
    groupUrl,
} from '../../services/identity/GroupService';
import {
    accountGlobalId,
    EnableUserLicensingSelector,
    groupIdsForUser,
    isAdminSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import { UiGrid } from '../common/UiGrid';
import type { IActionHeader } from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UserGroup } from '../common/UserGroups';

type IGroupWithLicenses = IGroup & Partial<IGroupAllocations>;

const useStyles = makeStyles(() =>
    createStyles({ addGroups: { marginLeft: '5px' } }),
);

const GroupsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const currentGroupIds = useSelector(groupIdsForUser);
    const isLicenseExpired = useLicenseExpired();

    const createDialog = useShowDialog();

    const dataUrl = useMemo(() => {
        const baseUrl = EnableUserLicensing && isAdmin ? `${groupUrl}/licenses` : `${groupUrl}`;
        return `${baseUrl}?partitionGlobalId=${partitionGlobalId}`;
    }, [ EnableUserLicensing, isAdmin, partitionGlobalId ]);

    const {
        data: groups, isValidating: loading, error, mutate,
    } = useSWR(
        {
            url: dataUrl,
            partitionGlobalId,
        },
        EnableUserLicensing && isAdmin ? getGroupsWithLicenses : getGroups,
    );

    const openDeleteDialog = useCallback(
        async (rows: Array<Row<IGroupWithLicenses>> | Row<IGroupWithLicenses>) => {
            const groups = Array.isArray(rows) ? rows.map(row => row.original) : [ rows.original ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_GROUP' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_GROUPS' },
                    {
                        0:
                groups.length === 1
                    ? decodeSanitizedHtml(groups[0].name)
                    : translate({ id: 'CLIENT_SELECTED_GROUPS_TEXT' }),
                    },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_GROUPS_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const groupIDs = groups.map(group => group.id);
                const includesCurrentUser = intersection(currentGroupIds, groupIDs).length > 0;
                await deleteGroups(partitionGlobalId, { groupIDs }, includesCurrentUser);
                if (groups.length === 1) {
                    createNotification(
                        translate({ id: 'CLIENT_GROUP_DELETED' }, { 0: decodeSanitizedHtml(groups[0].name) }),
                        notificationType.SUCCESS
                    );
                } else {
                    createNotification(translate({ id: 'CLIENT_GROUPS_DELETED' }), notificationType.SUCCESS);
                }
                mutate();
            }
        },
        [ createDialog, translate, currentGroupIds, partitionGlobalId, mutate, createNotification ],
    );

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const actionList: IActionHeader[] = [];

        const addGroups: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_GROUPS_ADD_GROUP' }),
            icon: <AddIcon />,
            click: () => navigate(`${getRoute(RouteNames.Groups)}/add`),
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.addGroups,
            dataCy: 'ui-grid-add-groups-button',
        };

        actionList.push(addGroups);

        return actionList;
    }, [ classes.addGroups, getRoute, isAdmin, navigate, translate ]);

    return (
        <UiGrid<IGroupWithLicenses>
            data-cy="groups-ui-grid"
            loading={loading}
            search
            searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
            extraActionButtons={extraActionHeaderButtons}
            hiddenColumns={[ 'type' ]}
            initialSort={[ { id: 'type' }, { id: 'name' } ]}
            checkbox={isAdmin}
            disableCheckboxPerRow={row => row.original.type === 0}
            pagination
            refresh={() => mutate()}
            columns={[
                {
                    accessor: 'name',
                    sortName: translate({ id: 'CLIENT_DISPLAY_NAME' }),
                    Header: translate({ id: 'CLIENT_DISPLAY_NAME' }),
                    width: EnableUserLicensing && isAdmin ? 50 : 100,
                    sortType: 'alphanumeric',
                    Cell: ({ row }) => <>
                        {decodeSanitizedHtml(row.original.name)}
                    </>,
                },
                ...(EnableUserLicensing && isAdmin && !isUnlicensedMode
                    ? [
                        {
                            accessor: 'userBundleLicenses',
                            Header: translate({ id: 'CLIENT_LICENSES_ALLOCATION_RULES' }),
                            width: 50,
                            Cell: ({ row }: { row: Row<IGroupWithLicenses> }) => {
                                const groups = row.original;
                                const licenseList = groups.useExternalLicense
                                    ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
                                    : groups.userBundleLicenses?.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ');
                                return (
                                    <Tooltip title={licenseList && licenseList.length !== 0 ? licenseList : ''}>
                                        <span data-cy="license-allocation-rule">
                                            {licenseList && licenseList.length !== 0 ? licenseList : translate({ id: 'CLIENT_NO_ALLOCATION_RULE' })}
                                        </span>
                                    </Tooltip>
                                );
                            },
                        },
                    ]
                    : []),
                {
                    accessor: 'type',
                    sortType: 'alphanumeric',
                },
            ]}
            data={groups ?? []}
            error={error}
            tableActions={
                isAdmin
                    ? [
                        {
                            type: ButtonType.Button,
                            label: translate({ id: 'CLIENT_DELETE' }),
                            icon: <DeleteForeverOutlinedIcon />,
                            actionType: GridActionType.Selected,
                            click: openDeleteDialog,
                        },
                    ]
                    : []
            }
            rowActions={
                isAdmin
                    ? [
                        {
                            type: ButtonType.Icon,
                            label: translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' }),
                            tooltip: translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' }),
                            actionType: GridActionType.Row,
                            invisible: !EnableUserLicensing || isUnlicensedMode,
                            icon: <AllocateLicenseIcon />,
                            dataCy: 'ui-grid-edit-group-allocations-button',
                            click: row => {
                                navigate(`${getRoute(RouteNames.Groups)}/allocations/edit`,
                                    {
                                        state: {
                                            group: row.original,
                                            previousLocation: location.pathname,
                                        },
                                    });
                            },
                            disable: () => isLicenseExpired,
                        },
                        {
                            type: ButtonType.Icon,
                            label: translate({ id: 'CLIENT_EDIT' }),
                            tooltip: translate({ id: 'CLIENT_EDIT' }),
                            actionType: GridActionType.Row,
                            icon: <EditIcon />,
                            click: row => navigate(`${getRoute(RouteNames.Groups)}/edit/${row.original.id}`),
                            disable: row => row?.original?.id === UserGroup.Everyone,
                        },
                        {
                            type: ButtonType.Icon,
                            label: translate({ id: 'CLIENT_DELETE' }),
                            tooltip: translate({ id: 'CLIENT_DELETE' }),
                            actionType: GridActionType.Row,
                            icon: <DeleteForeverOutlinedIcon />,
                            click: openDeleteDialog,
                            disable: row => row?.original?.type === 0,
                        },
                    ]
                    : []
            }
        />
    );
};

export default GroupsPageComponent;
