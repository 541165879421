import {
    get,
    post,
    put,
} from '@experiences/util';
import type { AxiosError } from 'axios';
import axios from 'axios';

import { getTranslationId } from '../helpers/EcommerceStripeErrorCodeMapper';
import type {
    IAddressDetails,
    IAddressValidationResult,
    IBusinessInfoPayload,
    ICheckOrganizationResponse,
    ICreateSubscriptionDto,
    IDirectBuyData,
    IErrorWithStatus,
    IMarketoInfo,
    INonUsTaxes,
    IOrderConfirmation,
    IPackagesConfig,
    IPlanDetails,
    IProductAndPricesWithDefaultCurrency,
    IProductBase,
    IProductPriceOptions,
    IProductPrices,
    ISkuPackage,
    IStripeIntegrationConfig,
    ISubscriptionDetails,
    ISubscriptionUpdate,
    ITaxResponse,
    IUpcomingInvoiceRequest,
    IUpcomingInvoiceResponse,
    IUpdateSubscriptionDto,
    IVatValidation,
} from '../interfaces/ecommerce';

export const billingUrl = '/api/billing';
export const billingPublicUrl = '/api/billingPublic';
export const billingSubscriptionUri = '/api/billing/subscription';

export function validateEmailBlacklist(email: string) {
    return get('/api/auth/Email/validate', {
        urlParams: { email },
        accessToken: '',
    });
}

export async function getPackagesConfig({
    accountName, countryCode,
}: { url: string; accountName: string; countryCode?: string; closed?: boolean }) {
    const urlParams: any = { accountName };
    if (countryCode) {
        urlParams['countryCode'] = countryCode;
    }
    return await get<IPackagesConfig>(`${billingUrl}/packagesConfig`, {
        urlParams,
        accessToken: '',
    });
}

export async function getStripeIntegrationConfiguration({ accessToken }: { url: string; accessToken: string; accountName: string }) {
    return await get<IStripeIntegrationConfig>(`${billingUrl}/stripeIntegrationConfig`, { accessToken });
}

export async function getPrices(
    productConfigListRequest: string[],
    currency: string) {
    const publicHost = window.env.ECOMMERCE_HOST.replace('{organizationGuid}/', '');
    const sortedProductCodes = [ ...productConfigListRequest ].sort();
    const url = `${publicHost}prices-with-discounts?currency=${currency}&productCodes=` +
        `${sortedProductCodes.join(',')}`;
    return axios.get<IProductAndPricesWithDefaultCurrency>(url);
}

export async function getUsTaxValue({
    countryCode, zipcode, amount, accountName, accessToken,
}: {
    url: string;
    countryCode: string; zipcode: string; amount: number; accountName: string; accessToken: string;
}) {
    const urlParams = {
        countryCode,
        zipcode,
        amount,
        accountName,
    };
    if (amount) {
        urlParams['amount'] = amount;
    }
    try {
        return await get<ITaxResponse>(`${billingUrl}/getUsTaxes`, {
            urlParams,
            accessToken,
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorWithStatus = new Error('An error occurred while fetching US taxes.') as IErrorWithStatus;
        errorWithStatus.translationId = 'CLIENT_ERROR_US_TAXES';
        errorWithStatus.status = axiosError.response?.status ?? 400;
        errorWithStatus.stack = axiosError.stack;
        throw errorWithStatus;
    }
}

export async function getNonUsTaxValue({
    countryCode, amount, accountName, accessToken,
}: { url: string; countryCode: string; amount: number; accountName: string; accessToken: string }) {
    const urlParams = {
        countryCode,
        amount,
        accountName,
    };
    if (amount) {
        urlParams['amount'] = amount;
    }
    try {
        return await get<INonUsTaxes>(`${billingUrl}/getNonUsTaxes`, {
            urlParams,
            accessToken,
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorWithStatus = new Error('An error occurred while fetching NON-US taxes.') as IErrorWithStatus;
        errorWithStatus.translationId = 'CLIENT_ERROR_NON_US_TAXES';
        errorWithStatus.status = axiosError.response?.status ?? 400;
        errorWithStatus.stack = axiosError.stack;
        throw errorWithStatus;
    }
}

export async function validateUsTaxes(addressDetails: IAddressDetails, accessToken: string, accountName?: string) {
    try {
        const url = `${billingUrl}/validateUsAddress`;
        return await post<IAddressValidationResult>(url, {
            urlParams: { accountName },
            accessToken,
            body: addressDetails,
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorWithStatus = new Error('An error occurred while Validating Address.') as IErrorWithStatus;
        errorWithStatus.translationId = 'CLIENT_ERROR_VALIDATING_ADDRESS';
        errorWithStatus.status = axiosError.response?.status ?? 400;
        errorWithStatus.stack = axiosError.stack;
        throw errorWithStatus;
    }
}

export async function validateEuVat({
    countryCode, vatId, accountName, accessToken,
}: {
    url: string;
    countryCode: string;
    vatId: string;
    accountName: string;
    accessToken: string;
}) {
    const urlParams = {
        countryCode,
        vatId,
        accountName,
    };

    return await get<IVatValidation>(`${billingUrl}/validateEuVat`, {
        urlParams,
        accessToken,
    });
}

export async function createInactiveSubscription(subscriptionDetails: ISubscriptionDetails, accountName: string, accessToken: string) {
    try {
        return await post<ICreateSubscriptionDto>(`${billingUrl}/createInactiveSubscription`, {
            urlParams: { accountName },
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while creating subscription.', error as AxiosError);
    }
}

export async function createDirectBuyInactiveSubscription(
    subscriptionDetails: ISubscriptionDetails, accessToken: string) {
    try {
        const url = `${billingUrl}/createDirectBuyInactiveSubscription`;
        return await post<ICreateSubscriptionDto>(url, {
            urlParams: { accountName: 'accountName' },
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while creating subscription.', error as AxiosError);
    }
}

export async function getProductsPricesInAllCurrencies({
    accountName, accessToken,
}: { url: string; accountName: string; accessToken: string }) {
    return await get<IProductPriceOptions[]>(`${billingUrl}/productsPricesInAllCurrencies`, {
        urlParams: { accountName },
        accessToken,
    });
}

export async function updateSubscription(subscriptionDetails: ISubscriptionUpdate, accountName: string, accessToken: string) {
    try {
        return await put<IUpdateSubscriptionDto>(`${billingUrl}/updateSubscription`, {
            urlParams: { accountName },
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while updating subscription.', error as AxiosError);
    }
}

export async function isOrganizationReady(organizationGuid: string, accessToken: string) {
    return await get<ICheckOrganizationResponse>(`${billingUrl}/isOrgReady`, {
        urlParams: { organizationGuid },
        accessToken,
    });
}

export async function getExistingUserMarketoData({
    accessToken, accountName,
}: { url: string; accessToken: string; accountName?: string }) {
    const marketoInfo = await get<IMarketoInfo>(`${billingUrl}/marketo`, {
        urlParams: { accountName },
        accessToken,
        cancelTokenSource: axios.CancelToken.source(),
        timeout: 10000,
    });

    if (marketoInfo != null) {
        return {
            ...marketoInfo,
            businessEmail: marketoInfo.businessEmail,
            companyName: marketoInfo.organizationName,
            userLanguage: marketoInfo.cloudplatformLanguage,
        } as IBusinessInfoPayload;
    }

    return {} as IBusinessInfoPayload;
}

export async function sendUserMarketoData(marketoData: IBusinessInfoPayload, accessToken: string, accountName?: string) {
    await post(`${billingUrl}/marketo`, {
        urlParams: { accountName },
        body: marketoData,
        accessToken,
        timeout: 10000,
    });
}

export function getOrderConfirmationDetails({
    stripeSubscriptionId, accountName, accessToken, url: _url,
}: { stripeSubscriptionId?: string; accountName: string; accessToken: string; url: string }) {
    return get<IOrderConfirmation>(`${billingUrl}/subscription`, {
        urlParams: {
            stripeSubscriptionId,
            accountName,
        },
        accessToken,
    });
}

export function getDirectBuyOrderConfirmationDetails({
    organizationId, countryCode, stripeSubscriptionId, accessToken,
}: {
    url: string;
    organizationId: string;
    countryCode: string;
    stripeSubscriptionId: string;
    accessToken: string;
}) {
    return get<IOrderConfirmation>(`${billingUrl}/directBuyOrderConfirmation`, {
        urlParams: {
            organizationId,
            countryCode,
            stripeSubscriptionId,
        },
        accessToken,
    });
}

export function getFixedCurrencyIfPresent(countryCode: string, accountName: string, accessToken: string) {
    return get<string>(`${billingUrl}/customerCurrency`, {
        urlParams: {
            countryCode,
            accountName,
        },
        accessToken,
    });
}

export async function fetchPlanDetails({
    accountName, accessToken, url: _url,
}: { accountName: string; accessToken: string; url: string }) {
    const urlParams = { accountName };
    return get<IPlanDetails>(`${billingUrl}/planDetails`, {
        urlParams,
        accessToken,
    });
}

export async function getUpcomingInvoice({
    upcomingInvoiceRequest, accountName, accessToken,
}: { url: string; upcomingInvoiceRequest: IUpcomingInvoiceRequest; accountName: string; accessToken: string }) {
    try {
        return await put<IUpcomingInvoiceResponse>(`${billingUrl}/upcomingInvoice`, {
            urlParams: { accountName },
            body: upcomingInvoiceRequest,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while fetching the upcoming invoice.', error as AxiosError);
    }
}

function buildError(message: string, error: AxiosError) {
    const errorWithStatus = new Error(message) as IErrorWithStatus;
    const data: any = error.response?.data || {};
    errorWithStatus.translationId = getTranslationId(data.ErrorCode);
    if (error.response?.status) {
        errorWithStatus.status = error.response?.status;
    }
    errorWithStatus.stack = error.stack;
    errorWithStatus.message = data.Message;
    errorWithStatus.description = data.Description;
    return errorWithStatus;
}

export async function buildSkuPackageFromData(directBuyData: IDirectBuyData) {
    const pricesResponse = await getPrices(
        Object.keys(directBuyData?.productQuantities ?? {}),
        directBuyData.currency);
    const prices = pricesResponse?.data;
    if (prices) {
        const products = [] as IProductBase[];
        Object.keys(directBuyData?.productQuantities).forEach(code => {
            products.push({
                code,
                quantity: directBuyData?.productQuantities[code],
            });
        });
        const currency = prices?.defaultCurrency;
        const commitmentType = directBuyData.commitmentType;
        const price = getTotalPrice(prices?.products, directBuyData, currency);
        const skuPackageFromProducts = {
            price,
            products,
            planType: commitmentType,
            currency,
        } as ISkuPackage;
        return skuPackageFromProducts;
    }

    return undefined;

}

export function getTotalPrice(productsPrices: IProductPrices[], directBuyData: IDirectBuyData, currency: string) {
    let price = 0;
    productsPrices.forEach(product => {
        const productQuantity = directBuyData.productQuantities[product.code];
        if (productQuantity) {
            const quantity = productQuantity;
            price += (quantity * product.prices[`${currency}`]);
        }
    });
    return price;
}

export async function getPackagesConfigByCurrency({
    url: _url, accountName, accessToken, currency,
}: { url: string; accountName: string; accessToken: string; countryCode: string; currency?: string }) {
    const urlParams: any = { accountName };
    if (currency) {
        urlParams['currency'] = currency;
    }
    return get<IPackagesConfig>(`${billingUrl}/packagesConfigByCurrency`, {
        urlParams,
        accessToken,
    });
}
export async function getPackagesConfigPublic({
    url: _url, accountName, accessToken, currency, countryCode,
}: { url: string; accountName: string; accessToken: string; countryCode?: string; currency?: string }) {
    const urlParams: any = { accountName };
    if (currency) {
        urlParams['currency'] = currency;
    }
    if (countryCode) {
        urlParams['countryCode'] = countryCode;
    }
    return get<IPackagesConfig>(`${billingPublicUrl}/publicPackagesConfig`, {
        urlParams,
        accessToken,
    });
}

export async function getPackagesPricesPublic({
    url: _url, accountName, accessToken,
}: { url?: string; accountName?: string; accessToken?: string }) {
    const urlParams = { accountName };
    return await get<IProductPriceOptions[]>(`${billingPublicUrl}/publicPackagesPrices`, {
        urlParams,
        accessToken: accessToken ?? '',
    });
}

export function startCartAbandonmentCampaign(source: string, accessToken: string) {
    const urlParams: any = { source };
    return post<void>(`${billingUrl}/startCartAbandonmentCampaign`, {
        urlParams,
        accessToken,
    });
}
