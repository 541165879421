import {
    getDisplayName,
    getFormattedDate,
} from '@experiences/util';

import { getNextAuditEvents } from '../../../services/audit/AuditService';
import getUsersInPartition from '../../../services/identity/UserPartitionService';
import { getQuickFilterLabel } from '../../common/UiDatePicker/UiDatePickerUtil';

const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

export function getNextEvents(start: number, nextUrl?: string | null) {
    return nextUrl ?
        getNextAuditEvents(nextUrl, start) :
        null ;
}

export function getSuggestValue(value: string[]) {
    return value?.map(s => ({
        id: s,
        text: s,
    }));
}

export function getDropdownValue(value: any, label: string) {
    return {
        value,
        label,
    };
}

export function getSearchSourceFactory(search?: string, inputs?: Array<{ name: string; id: string }>) {
    return Promise.resolve({
        data: inputs?.filter(input => search ? input?.name.includes(search) : true).map((s) => ({
            id: s.id,
            text: s.name,
        })) ?? [],
        total: inputs?.filter(input => search ? input?.name.includes(search) : true).length ?? 0,
    });
}

export function getDateFilterString(from?: Date, to?: Date) {
    const quickFilterLabel = getQuickFilterLabel(from, to);

    if (quickFilterLabel) {
        return { id: quickFilterLabel };
    }

    if (from && to) {
        return {
            id: 'CLIENT_DATE_CREATED_FROM_TO',
            args: {
                0: getFormattedDate(from, DATE_TIME_FORMAT),
                1: getFormattedDate(to, DATE_TIME_FORMAT),
            },
        };
    }

    return { id: 'CLIENT_ALL' };
}

export async function getUserFilterOptions(organizationId: string, search = '', fetchSize = 10, skip = 0) {
    const users = await getUsersInPartition({
        pagination: {
            top: fetchSize,
            searchTerm: search,
            skip,
            sortBy: 'Name',
            sortOrder: 'asc',
        },
        partitionGlobalId: organizationId,
    });

    return {
        total: users?.totalCount,
        data: users?.results.map(u => ({
            id: u.id,
            value: u.id,
            text: getDisplayName(u),
        })),
    };
}
