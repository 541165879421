import { AccountLicense } from '@experiences/constants';
import { useIsEcommerceEnabled } from '@experiences/ecommerce';
import { UiText } from '@experiences/ui-common';
import { isBrowserIE } from '@experiences/util';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FontVariantToken } from '@uipath/apollo-core';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import isString from 'lodash/isString';
import React from 'react';
import { useSelector } from 'react-redux';

import { accountType } from '../../../store/selectors';

const useStyles = makeStyles<Theme, { header?: IUiPanelHeaderProps }>((theme) =>
    createStyles({
        root: (props) => ({
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: props.header?.marginTop ?? '0px',
        }),
        scrollBar: {
            scrollbarColor: `unset ${theme.palette.semantic.colorForegroundDeEmp}`,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': { width: '0.5em' },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:vertical:hover': { backgroundColor: theme.palette.semantic.colorForegroundDisable },
        },
        contentContainer: {
            width: '100%',
            padding: '24px',
            maxWidth: '1920px',
        },
        mainContainerNotIe: {
            display: 'flex',
            flexDirection: 'column',
        },
        mainContainerNotIeAlertBar: {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 133px)',
        },
        mainContainer: {
            overflow: 'auto',
            flex: 1,
            backgroundColor: theme.palette.semantic.colorBackground,
            alignItems: 'center',
        },
        disableMainContainerRightPadding: {
            overflow: 'auto',
            padding: '24px',
            flex: 1,
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        disableMainContainerPadding: {
            overflow: 'auto',
            flex: 1,
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        sideBarContainer: {
            width: '336px',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            overflow: 'auto',
            padding: '32px',
        },
        sideBarContainerAlertBar: {
            width: '336px',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            height: 'calc(100vh - 133px)',
            overflow: 'auto',
            padding: '32px',
        },
        headerBar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
            lineHeight: '32px',
            minHeight: '36px',
        },
        greetingText: {
            fontFamily: Tokens.FontFamily.FontTitle,
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '40px',
            color: theme.palette.semantic.colorForeground,
        },
        headerText: (props) => ({
            fontWeight: props.header?.fontWeight ?? 600,
            fontSize: props.header?.fontSize ?? '18px',
            lineHeight: '35px',
        }),
        disableHeaderGutter: { marginBottom: '0px' },
        '@media all and (max-width: 600px)': {
            sideBarContainer: { display: 'none' },
            mainWithSideBar: { width: '100%' },
        },
    }),
);

interface IUiPanelHeaderProps {
    title: string | React.ReactNode;
    variant?: FontVariantToken;
    fontWeight?: number;
    fontSize?: string;
    actions?: React.ReactNode;
    disableGutter?: boolean;
    disableRightPadding?: boolean;
    disablePadding?: boolean;
    className?: string;
    isGreeting?: boolean;
    marginTop?: string;
}

export const UiPanel: React.FC<{
    children?: React.ReactNode;
    sideBar?: React.ReactNode;
    header?: IUiPanelHeaderProps;
    licenseInGracePeriodBanner?: React.ReactNode;
    licenseExpiredBanner?: React.ReactNode;
    trialPerSkuLicenseInGracePeriodBanners?: React.ReactNode;
    licensePendingChangeBanner?: React.ReactNode;
    licensePaymentPendingBanner?: React.ReactNode;
    banner?: React.ReactNode;
    freBanner?: React.ReactNode;
    navigationElement?: React.ReactNode;
}> = ({
    children,
    sideBar,
    header,
    licenseInGracePeriodBanner,
    licenseExpiredBanner,
    trialPerSkuLicenseInGracePeriodBanners,
    licensePendingChangeBanner,
    licensePaymentPendingBanner,
    banner,
    freBanner,
    navigationElement,
}) => {
    const classes = useStyles({ header });

    const currentAccountType = useSelector(accountType);
    const isEcommerceEnabled = useIsEcommerceEnabled();

    return (
        <div className={classes.root}>
            <div
                className={clsx(
                    header?.disablePadding
                        ? classes.disableMainContainerPadding
                        : header?.disableRightPadding
                            ? classes.disableMainContainerRightPadding
                            : classes.mainContainer,
                    !(isBrowserIE() && sideBar) &&
                        (isEcommerceEnabled &&
                        (AccountLicense[currentAccountType] ===
                            AccountLicense.TRIAL)
                            ? classes.mainContainerNotIeAlertBar
                            : classes.mainContainerNotIe),
                    classes.scrollBar,
                )}
                data-cy="main-container"
            >
                <div className={classes.contentContainer}>
                    {licenseInGracePeriodBanner}
                    {licenseExpiredBanner}
                    {trialPerSkuLicenseInGracePeriodBanners}
                    {freBanner}
                    {licensePendingChangeBanner}
                    {licensePaymentPendingBanner}
                    {navigationElement}
                    {header && (
                        <div
                            className={clsx(
                                classes.headerBar,
                                header.disableGutter
                                    ? classes.disableHeaderGutter
                                    : '',
                            )}
                        >
                            {isString(header.title) ? (
                                <UiText
                                    data-cy="ui-panel-header-title"
                                    variant={header.variant}
                                    className={clsx(
                                        header.isGreeting ? classes.greetingText : classes.headerText,
                                        header.className,
                                    )}
                                    role="heading"
                                    aria-level={1}
                                >
                                    {header.title}
                                </UiText>
                            ) : (
                                header.title
                            )}
                            {header.actions}
                        </div>
                    )}
                    {banner}
                    {children}
                </div>
            </div>
            {sideBar && (
                <div
                    className={clsx(
                        isEcommerceEnabled &&
                            (AccountLicense[currentAccountType] ===
                                AccountLicense.TRIAL)
                            ? classes.sideBarContainerAlertBar
                            : classes.sideBarContainer,
                        classes.scrollBar,
                    )}
                >
                    {sideBar}
                </div>
            )}
        </div>
    );
};
