import type { IFolderTreeDTO } from '@experiences/ui-common';
import { UiMultiSelectTree } from '@experiences/ui-common';
import * as React from 'react';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    folderNavigationUrl,
    getFolderStructure,
    getUserNotificationSubscription,
    updateUserPublisherSubscriptionSubscription,
    userNotificationSubscriptionUri,
} from '../../../services/notification-preferences';
import { accountLogicalName } from '../../../store/selectors';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import {
    createTreeFromFolderDTO,
    findTreeNode,
    updateNodesAndReturnEntities,
} from '../helpers/topicsGrouper';
import type {
    IFolderUpdatePayload,
    IFolderUpdatePayloadEntity,
} from '../interfaces/notificationSettings';

const DynamicFilterComponent: React.FC<{
    handleError(error: Error): void;
    urlTemplate: string;
    publisherName: string;
    hasUserOptin: boolean;
}> = ({
    handleError, urlTemplate, publisherName, hasUserOptin,
}) => {
    const accountName = useSelector(accountLogicalName);
    const {
        selectedTenant: {
            id: tenantId, name: tenantName,
        },
    } = useTenantsContext();
    const [ treeNodes, setTreeNodes ] = React.useState<IFolderTreeDTO[]>([]);

    const { data: foldersData } = useSWR(
        tenantId ? {
            requestUri: folderNavigationUrl,
            selectedTenantId: tenantId,
            urlTemplate,
            accountName,
            tenantName,
        } : null,
        getFolderStructure,
    );

    const {
        data: publishersData,
        mutate: mutatePublisher,
    } = useSWR(
        tenantId ? {
            requestUri: userNotificationSubscriptionUri,
            selectedTenantId: tenantId,
            publisherName,
        } : null,
        getUserNotificationSubscription,
        { revalidateOnFocus: false }
    );

    const publisher = publishersData?.publishers[0];
    const [ publisherId, entityTypes, entities ] = [ publisher?.id, publisher?.entityTypes, publisher?.entities ];

    const tree = useMemo(() => {
        if (entityTypes !== undefined && entities !== undefined && entityTypes.length > 0) {
            const typeDTO = entityTypes[0];
            const folders = createTreeFromFolderDTO(entities, typeDTO, foldersData);
            setTreeNodes(folders);
            return folders;
        }
    }, [ entityTypes, entities, foldersData ]);

    const startChangeNodes = useCallback((keys: string[], isSubscribed: boolean) => {

        const folders = [ ...treeNodes ];
        keys.forEach((key) => {
            const treeNode = findTreeNode(key, folders);
            if (treeNode !== undefined) {
                treeNode.isUpdating = true;
            }
        });
        setTreeNodes(folders);

    }, [ treeNodes ]);

    const endChangeNodes = useCallback((keys: string[], isSubscribed: boolean) => {
        const folders = [ ...treeNodes ];
        keys.forEach((key) => {
            const treeNode = findTreeNode(key, folders);
            if (treeNode !== undefined) {
                treeNode.isChecked = isSubscribed;
                treeNode.isUpdating = false;
            }
        });
        setTreeNodes(folders);
    }, [ entityTypes, treeNodes ]);

    const handleChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const {
                currentTarget: {
                    id, checked,
                },
            } = event;
            startChangeNodes([ id ], checked);
            const folders: IFolderTreeDTO[] = treeNodes ?? [];
            const treeNode = findTreeNode(id, folders);
            const entity = entityTypes[0];
            const payloadEntities: IFolderUpdatePayloadEntity[] = updateNodesAndReturnEntities(entity, checked, treeNode);
            const payload = {
                publisherID: publisherId,
                isUserOptIn: hasUserOptin,
                entities: payloadEntities,
            } as IFolderUpdatePayload;
            try {
                await updateUserPublisherSubscriptionSubscription(payload, tenantId);
                mutatePublisher();

            } catch (error) {
                handleError(error as Error);
                endChangeNodes([ id ], !checked);
            }
        }, [
            startChangeNodes,
            treeNodes,
            entityTypes,
            publisherId,
            hasUserOptin,
            tenantId,
            mutatePublisher,
            handleError,
            endChangeNodes,
        ]);

    const handleSelectAllChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const { target: { checked } } = event;

            const folders = [ ...treeNodes ];
            const keys: string[] = folders.filter(x => !x.isCategorized).map((folder) => folder.Key);
            startChangeNodes(keys, checked);
            const entity = entityTypes[0];
            let payloadEntities: IFolderUpdatePayloadEntity[] = [];
            folders.filter(x => !x.isCategorized).forEach(treeNode => {
                payloadEntities = [ ...payloadEntities, ...updateNodesAndReturnEntities(entity, checked, treeNode) ];
            });

            const payload = {
                publisherID: publisherId,
                isUserOptIn: hasUserOptin,
                entities: payloadEntities,
            } as IFolderUpdatePayload;
            try {
                await updateUserPublisherSubscriptionSubscription(payload, tenantId);
                mutatePublisher();
            } catch (error) {
                handleError(error as Error);
                endChangeNodes(keys, checked);
            }
        }, [
            treeNodes,
            startChangeNodes,
            entityTypes,
            publisherId,
            hasUserOptin,
            tenantId,
            mutatePublisher,
            handleError,
            endChangeNodes,
        ]);

    return (
        <UiMultiSelectTree
            onChange={handleChange}
            onChangeAll={handleSelectAllChange}
            treeNodes={tree} />
    );
};

export default DynamicFilterComponent;
