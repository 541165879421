export const Entitlements = {
    IpRestrictions: 'Platform.IpRestrictions',
    CustomerManagedKey: 'Platform.CustomerManagedKey',
    Insights: 'Insights.FullMode',
};

export const entitlementsToLegacyCodes: { [key: string]: string } = {
    'Insights.FullMode': 'ANL',
    'AutomationHub.Enabled': 'AHUB',
    'ProcessMining.Enabled': 'PM',
    'TestManager.Enabled': 'TMH',
};
