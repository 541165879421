import type { Region } from '@experiences/constants';

export enum MigrationAvailability {
    ENABLED = 'Enabled',
    SCHEDULED = 'Scheduled',
    RUNNING = 'Running',
    PENDINGFINALIZATION = 'PendingFinalization',
}

export const MigrationStartWindowDays = 0;
export const MigrationEndWindowDays = 14;

export interface IMigrationResponse {
    instanceId: string;
}

export interface IMigrationRequest {
    sourceRegion: Region;
    destinationRegion: Region;
    timeOfDayStartIntervalTicks: number;
    timeOfDayEndIntervalTicks: number;
    scheduleStartDate: string;
    scheduleEndDate: string;
}

export interface IMigrationStatus {
    instanceId: string;
    sourceRegion: string;
    destinationRegion: string;
    scheduleEndDate: string;
    scheduleStartDate: string;
    status: string;
    timeOfDayEndIntervalTicks: number;
    timeOfDayStartIntervalTicks: number;
}

interface ITenantServiceRegion {
    service: string;
    region: Region;
}

export interface IRegionsPerService {
    availableRegionsPerService: Record<string, ITenantServiceRegion[]>;
}
