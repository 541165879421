export async function getResponseData<T>(response: Response): Promise<T> {
    const text = await response.text();
    try {
        return JSON.parse(text) as T;
    } catch {
    // Prefer giving actual response data when not an object.
        return (text as unknown) as T;
    }
}

export function getFileName(disposition: string | null | undefined) {
    let filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches?.[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
}
