import type {
    AccountType,
    IPersonaFit,
    Persona,
} from '@experiences/interfaces';

import { isDominantPersona } from './DominantUtil';

export function getDominantPersona(personaFit: IPersonaFit,
    isAdmin: boolean,
    accountType: AccountType,
    personaPriorityOrder: Persona[],
) {
    if (accountType === 'FREEKIT') {
        return isAdmin ? 'AUTOMATION_EXPRESS_ADMIN' : 'AUTOMATION_EXPRESS_USER';
    }

    if (isAdmin) {
        return 'ADMIN';
    }

    for (const persona of personaPriorityOrder) {
        if (isDominantPersona(personaFit, persona)) {
            return persona;
        }
    }

    return 'UNKNOWN';
}
