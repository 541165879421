import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { accountLogicalName } from '../../store/selectors';

const useNavigateToHome = (accountNameOverride?: string) => {
    const currentAccountName = useSelector(accountLogicalName);

    return useCallback(() => {
        const accountName = accountNameOverride ?? currentAccountName;
        window.location.href = generatePath(RouteNames.Home, { accountName });
    }, [ accountNameOverride, currentAccountName ]);
};

export default useNavigateToHome;
