import type {
    IExternalAppDataContext,
    IExternalClient,
} from '@experiences/interfaces';
import { UiDataContextProvider } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { routePaths } from '../../../common/constants/routePaths';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import {
    externalClientUrl,
    getExternalClientById,
} from '../../../services/identity/ExternalClientService';
import { accountGlobalId } from '../../../store/selectors';

const ExternalAppContext = React.createContext<IExternalClient | undefined>(undefined);

export const useGetExternalApp = () => React.useContext(ExternalAppContext);

export const AddEditExternalAppsProvider: React.FC<PropsWithChildren<{ type: 'add' | 'edit' }>> = ({
    children, type,
}) => {
    const { formatMessage: translate } = useIntl();

    const { original } = useBreadcrumbs();

    const { id } = useParams<{ id: string }>();
    const partitionGlobalId = useSelector(accountGlobalId);

    const { data } = useSWR(
        type === 'edit' && id ? {
            url: `${externalClientUrl}/${id}`,
            partitionGlobalId,
            id,
        } : null,
        getExternalClientById,
    );

    const breadCrumbLinks = useMemo(() => [
        ...original,
        {
            index: 2,
            link: routePaths.adminExternalAppsOauthAdd,
            name: translate({ id: 'CLIENT_ADD_APPLICATION' }),
        },
        {
            index: 2,
            link: routePaths.adminExternalAppsOauthEdit,
            name: translate({ id: 'CLIENT_PAGE_EDIT_EXTERNAL_APPLICATION' }),
        },
    ], [ original, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        <ExternalAppContext.Provider value={data}>
            <UiDataContextProvider<IExternalAppDataContext> initialState={{
                resources: undefined,
                confidential: true,
            }}>
                {children}
            </UiDataContextProvider>
        </ExternalAppContext.Provider>
    </BreadcrumbProvider>;
};
