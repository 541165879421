import type { Dispatch } from 'react';
import { createAction } from 'redux-act';

import type { IAnnouncement } from '../../common/interfaces/announcement';

export const markAllAnnouncementsAsRead = createAction('MarkAllAnnouncementsAsRead');

export function markAnnouncementRead() {
    return (dispatch: Dispatch<any>, getState: any) => {
        const announcements = getState().announcements.announcementList as IAnnouncement[];
        announcements.forEach(announcement => {
            localStorage.setItem(announcement.localStorageKey, 'true');
        });

        dispatch(markAllAnnouncementsAsRead());
    };
}
