import type { Token } from '@experiences/interfaces';
import { jwtDecode } from 'jwt-decode';
import {
    useCallback,
    useMemo,
} from 'react';

import useUserInfo from './UserInfo';

function useCheckAuthTypeWithToken() {
    const getAuthTypeWithToken = useCallback((token: string) => {
        if (!token) {
            return {
                checkTokenAuthIsBasic: false,
                checkTokenTypeIsAuth0: false,
                checkTokenAuthIsAzure: false,
                checkTokenAuthIsMicrosoft: false,
                checkTokenAuthIsGoogle: false,
                checkTokenAuthIsLinkedIn: false,
                checkTokenAuthIsLocal: false,
                checkTokenAuthIsSAML: false,
                loading: true,
            };
        }

        const decoded = jwtDecode<Token>(token);

        const checkTokenTypeIsAuth0 =
      decoded.ext_idp_disp_name?.toLowerCase() === 'auth0' || decoded.ext_idp_disp_name?.toLowerCase() === 'globalidp';

        const checkTokenAuthIsAzure = decoded.ext_idp_disp_name?.toLowerCase() === 'aad';

        const checkTokenAuthIsMicrosoft = decoded.auth0_con === 'UiPath-AADV2';

        const checkTokenAuthIsGoogle = decoded.auth0_con === 'google-oauth2';

        const checkTokenAuthIsLinkedIn = decoded.auth0_con === 'linkedin';

        const checkTokenAuthIsLocal = decoded.idp?.toLowerCase() === 'local';

        const checkTokenAuthIsSAML = decoded.idp?.toLowerCase() === 'saml2';

        const checkTokenAuthIsBasic = decoded.auth0_con?.toLowerCase() === 'username-password-authentication';

        return {
            checkTokenAuthIsBasic,
            checkTokenTypeIsAuth0,
            checkTokenAuthIsAzure,
            checkTokenAuthIsMicrosoft,
            checkTokenAuthIsGoogle,
            checkTokenAuthIsLinkedIn,
            checkTokenAuthIsLocal,
            checkTokenAuthIsSAML,
            loading: false,
        };
    }, [ ]);

    return getAuthTypeWithToken;
}

export function useCheckAuthTypeFn() {
    const getAuthType = useCheckAuthTypeWithToken();
    return getAuthType;
}

export default function useCheckAuthTypes() {
    const { token } = useUserInfo();
    const getAuthType = useCheckAuthTypeWithToken();

    return useMemo(() => getAuthType(token), [ getAuthType, token ]);
}
