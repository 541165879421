import {
    getRegionTranslationId,
    Region,
} from '@experiences/constants';
import { UiText } from '@experiences/ui-common';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

import {
    getFriendlyName,
    getIconName,
} from '../../../common/constants/ServicesMapping';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        padding: '16px',
        borderRadius: '3px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
}));

type UiMigrationServiceCardProps = {
    serviceId: string;
    region: string;
    newRegion: string;
    supportedServicesInNewRegion?: string[];
};

export const UiMigrationServiceCard: React.FC<UiMigrationServiceCardProps> = ({
    serviceId: id, region, newRegion, supportedServicesInNewRegion,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    let displayRegion = region;
    if (supportedServicesInNewRegion && supportedServicesInNewRegion.indexOf(id) >= 0) {
        displayRegion = newRegion;
    }

    let chipClassName = 'mini';
    if (newRegion !== Region.None && displayRegion !== newRegion) {
        chipClassName = 'warning-mini';
    }

    return (
        <div className={classes.card} >
            <div className={classes.title}>
                <PortalCustomIcon name={getIconName(id)} />
                <UiText variant={FontVariantToken.fontSizeMBold}>
                    {getFriendlyName(id)}
                </UiText>
            </div>
            <Chip
                label={translate({ id: getRegionTranslationId(displayRegion) })}
                className={chipClassName}
                data-cy={`service-region-chip-${id}`}
                size="small" />
        </div>
    );
};
