import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useIsSignupDirectBuyFlow } from '../helpers/EcommerceHelpers';
import { useEcommerce } from '../helpers/useEcommerce';
import type { ISubscriptionForm } from '../interfaces/ecommerce';
import EcommerceAddressLine1 from './EcommerceAddressLine1';
import EcommerceAddressLine1NonAutocomplete from './EcommerceAddressLine1NonAutocomplete';
import EcommerceAddressLine2 from './EcommerceAddressLine2';
import EcommerceBusinessEmailAndCompany from './EcommerceBusinessEmailAndCompany';
import EcommerceBusinessFirstAndLastNames from './EcommerceBusinessFirstAndLastNames';
import EcommerceCountryAndCurrency from './EcommerceCountryAndCurrency';
import { EcommerceDataStorageRegion } from './EcommerceDataStorageRegion';
import EcommerceStateAndCity from './EcommerceStateAndCity';
import EcommerceTaxId from './EcommerceTaxId';
import EcommerceZipCode from './EcommerceZipCode';

const useStyles = makeStyles((theme) =>
    createStyles({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        sectionTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '8px',
        },
    }),
);

const EcommerceCheckoutBillingAddress: React.FC<{
    currencies: string[];
    disabled: boolean;
    countryChangeHandler: any;
}> = ({
    currencies, disabled, countryChangeHandler,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const EnableEcommercePlanSelectionRevampM0 = getFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);
    const EnableEcommerceAddressBillingValidation = getFeatureFlagValue(Features.EnableEcommerceAddressBillingValidation.name);
    const {
        setEcommerceCurrencyValue,
        setEcommerceTaxIdValue,
        countryCode,
        currentSkuPackageDetails,
    } = useEcommerce();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const {
        watch,
        trigger,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const {
        country: currentCountry,
        taxId: currentTaxId,
        currency: currentCurrency,
    } = watch();

    useEffect(() => {
        if (countryCode && currentCountry !== countryCode && !disabled) {
            countryChangeHandler(currentCountry);
        }
    }, [ countryCode, currentCountry, disabled, countryChangeHandler ]);

    useEffect(() => {
        if (!EnableEcommercePlanSelectionRevampM0 && currentCurrency) {
            setEcommerceCurrencyValue(currentCurrency);
        }
    }, [ setEcommerceCurrencyValue, currentCurrency, errors.currency, EnableEcommercePlanSelectionRevampM0 ]);

    const [ debouncedTaxId ] = useDebounce(currentTaxId, 1000);
    useEffect(() => {
        if (debouncedTaxId) {
            setEcommerceTaxIdValue(debouncedTaxId);
        }
    }, [ setEcommerceTaxIdValue, debouncedTaxId, errors.taxId ]);

    const [ addressLine1, city, state, zipcode ] = watch([
        'addressLine1',
        'city',
        'state',
        'zipcode',
    ]);
    useEffect(() => {
        if (
            (addressLine1 && errors.addressLine1?.type === 'addressError') ||
            (city && errors.city?.type === 'addressError') ||
            (state && errors.state?.type === 'addressError') ||
            (zipcode && errors.zipcode?.type === 'addressError')
        ) {
            trigger([ 'addressLine1', 'city', 'state', 'zipcode' ]);
        }
    }, [ addressLine1, city, state, zipcode, errors, trigger ]);

    return (
        <div className={classes.formContainer}>
            <div className={classes.row}>
                <UiText className={classes.sectionTitle}>
                    {translate({ id: 'CLIENT_STEP_1_BILLING_INFORMATION' })}
                </UiText>
            </div>
            <EcommerceBusinessFirstAndLastNames
                disabled={disabled}
            />
            <EcommerceBusinessEmailAndCompany
                disabled={disabled}
                planType={currentSkuPackageDetails.planType}
            />

            <EcommerceCountryAndCurrency
                disabled={disabled}
                currencies={currencies}
            />
            {currentCountry === 'JP' ? (
                <>
                    <div className={classes.row}>
                        <EcommerceZipCode
                            disabled={disabled}
                        />
                    </div>

                    <EcommerceStateAndCity
                        showCityFirst
                        disabled={disabled}
                    />
                    <div className={classes.row}>
                        {EnableEcommerceAddressBillingValidation ? (
                            <EcommerceAddressLine1
                                disabled={disabled}
                            />
                        ) : (
                            <EcommerceAddressLine1NonAutocomplete
                                disabled={disabled}
                            />
                        )}
                        <EcommerceAddressLine2
                            disabled={disabled}
                        />
                    </div>

                    <EcommerceTaxId
                        disabled={disabled}
                    />
                </>
            ) : (
                <>
                    <div className={classes.row}>
                        {EnableEcommerceAddressBillingValidation ? (
                            <EcommerceAddressLine1
                                disabled={disabled}
                            />
                        ) : (
                            <EcommerceAddressLine1NonAutocomplete
                                disabled={disabled}
                            />
                        )}
                        <EcommerceAddressLine2
                            disabled={disabled}
                        />
                    </div>

                    <EcommerceStateAndCity
                        showCityFirst
                        disabled={disabled}
                    />

                    {(currentCountry === 'US' || currentCountry === 'CA') &&
                        <div className={classes.row}>
                            <EcommerceZipCode
                                disabled={disabled}
                            />
                        </div>}

                    { isSignupDirectBuyFlow && (
                        <EcommerceDataStorageRegion
                            disabled={disabled}
                        />
                    )}

                    {(currentCountry !== 'US') &&
                        <EcommerceTaxId
                            disabled={disabled}
                        />}
                </>
            )}
        </div>
    );
};

export default EcommerceCheckoutBillingAddress;
