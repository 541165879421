export interface IError {
    code: string;
    description: string;
}

export enum ServiceErrorCode {
    InsufficientLicense = 1000,
    DuplicateActiveService = 1001,
    ClientError = 1002,
    UnexpectedError = 1999,
    BadRequest = 2000,
    Conflict = 2001,
    NotFound = 2002,
    Timeout = 2003,
    Gone = 2004,
    FirstPartyServiceClientError = 2005,
    LocationServiceClientError = 2006,
    LAServiceClientError = 2007,
    LRMServiceClientError = 2008,
}
