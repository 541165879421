import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ITrialServices } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import RobotUsage from '../../usage/helpers/RobotUsage';
import LoadingStateSkeletonComponent from '../subcomponents/LoadingStateSkeletonComponent';
import { CurrentTrialsPanel } from './CurrentTrials';
import { FreeTrialsPanel } from './FreeTrialsPanel';

const useStyles = makeStyles(theme =>
    createStyles({
        panel: { marginTop: '21px' },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '8px',
        },
    }),
);

export const TrialPerSkuPanel: React.FC<{
    trialServices: ITrialServices | undefined;
    tenantIds?: string[];
    isHome?: boolean;
}> = ({
    trialServices, tenantIds, isHome,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const enableRobotUnits = useFeatureFlagValue(Features.EnableRobotUnits.name);
    const hasRobotUnitsLicense = useMemo(() => trialServices ? isHome &&
        enableRobotUnits && trialServices.currentTrials.flatMap(t => t.products).some(p => p.code === 'RU') : false,
    [ trialServices, isHome, enableRobotUnits ]);

    const showLicenseUsage = useMemo(() => hasRobotUnitsLicense, [ hasRobotUnitsLicense ]);

    return trialServices?.freeTrials && trialServices.currentTrials ? (
        <>
            <div className={classes.panel}>
                <FreeTrialsPanel freeTrials={trialServices.freeTrials} />
            </div>
            <div className={classes.panel}>
                <CurrentTrialsPanel currentTrials={trialServices.currentTrials} />
            </div>
            {showLicenseUsage && (
                <div>
                    <UiText className={classes.subTitle}>
                        {translate({ id: 'CLIENT_USAGE_TITLE' })}
                    </UiText>
                    {hasRobotUnitsLicense && (
                        <RobotUsage
                            tenantIds={tenantIds}
                            isServiceMode={false}
                            productsToDisplay={[ 'RU' ]}
                        />
                    )}
                </div>
            )}
        </>
    ) : (<LoadingStateSkeletonComponent
        childGridColumns={4}
        childGridElements={[ 1, 2 ]} />);
};
