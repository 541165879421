import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    ApStepper,
    ApStepperStep,
} from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const SecuritySettingsSAMLFormStepper: React.FC<{ activeStep: number }> = ({ activeStep }) => {
    const { formatMessage: translate } = useIntl();

    const EnableSAMLUXRefactor = useFeatureFlagValue(Features.EnableSAMLUXRefactor.name);

    const steps = useMemo(() => EnableSAMLUXRefactor
        ? [
            { label: 'CLIENT_CONFIGURE_IDP' },
            { label: 'CLIENT_ATTRIBUTE_MAPPING' },
        ]
        : [
            { label: 'CLIENT_GENERAL_DETAILS' },
            { label: 'CLIENT_PROVISIONING_SETTINGS' },
            { label: 'CLIENT_ADVANCED_DETAILS' },
        ],
    [ EnableSAMLUXRefactor ]);

    return (
        <ApStepper
            data-cy='saml-form-stepper'
            activeStep={activeStep}>
            {steps.map((step, index) => (
                <ApStepperStep
                    key={index}
                    label={translate({ id: step.label })} />

            ))}
        </ApStepper>
    );
};

export default SecuritySettingsSAMLFormStepper;
