import { UiAlertBanner } from '@experiences/ui-common';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { ILicenseAndKey } from '../../../common/interfaces/licenses';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../../services/licensing/management/AccountService';
import {
    isAdminSelector,
    isUnlicensedSelector,
} from '../../../store/selectors';

export const UiLicenseExpiredBanner: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);

    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const {
        data, isValidating,
    } = useSWR<ILicenseAndKey, Error>(
        isAdmin && !isUnlicensedMode ? licenseManagementAccountUrl : null,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const isLicenseExpired = useMemo(
        () => !isValidating && data && data.accountLicense.licenseStatus === 'EXPIRED',
        [ data, isValidating ]
    );

    return (
        <>
            {isLicenseExpired && (
                <div data-cy="ui-license-expired-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        {translate({ id: 'CLIENT_LICENSE_EXPIRED_WARNING_BANNER' })}
                    </UiAlertBanner>
                </div>
            )}
        </>
    );
};
export default UiLicenseExpiredBanner;
