import type {
    ITenantServiceLicense,
    ITenantServiceProduct,
} from '../../interfaces/service';

const customTenantAllocationSections = [ 'aiservices' ];

const productToCustomTenantAllocationSection: Record<string, string> = { AIU: 'aiservices' };

const productToCustomServiceDependencies: Record<string, string[]> = {
    AIU: [
        'taskmining',
        'du',
        'aifabric',
        'reinfer',
    ],
};

export const isProductUnavailableInAllocationSection = (product: ITenantServiceProduct, sectionName: string, tenantEnabledServices: string[]): boolean => {
    if (process.buildConfigs.unavailableProductCodes?.includes(product.code) ?? false) {
        return true;
    }

    if (sectionName === productToCustomTenantAllocationSection[product.code]) {
        if (product.code in productToCustomServiceDependencies &&
            (product.quantity === 0 &&
                !tenantEnabledServices.some(service => productToCustomServiceDependencies[product.code].includes(service)))) {
            return true;
        }
    }

    return false;
};

export const mapServiceLicenseToTenantCustomAllocationSections = (serviceLicense: ITenantServiceLicense): ITenantServiceLicense[] => {
    if (serviceLicense.serviceType !== 'tenant') {
        return [ serviceLicense ];
    }

    const sections: ITenantServiceLicense[] = [];

    for (const product of serviceLicense.products) {
        const sectionName = productToCustomTenantAllocationSection[product.code];
        const idx = sections.findIndex(s => s.serviceType === sectionName);

        if (idx === -1) {
            sections.push({
                serviceType: sectionName,
                products: [ product ],
            });
        } else {
            sections[idx].products.push(product);
        }
    }

    return sections;
};

export const mapTenantAllocationSectionToServiceLicense = (allocationSection: ITenantServiceLicense): ITenantServiceLicense => {
    if (customTenantAllocationSections.find(section => section === allocationSection.serviceType) === undefined) {
        return allocationSection;
    }

    return {
        serviceType: 'tenant',
        products: allocationSection.products,
    };
};

export const minMaxAllowedProductQty = (product: ITenantServiceProduct) => {
    const minQty = (product.reserved + product.used) || 0;
    const maxQty = product.quantity + product.available > 0
        ? product.quantity + product.available
        : product.quantity;

    return {
        minQuantity: minQty,
        maxQuantity: Math.max(minQty, maxQty),
    };
};
