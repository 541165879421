/* eslint-disable max-len */
export const routePaths = {
    home: '/home',

    profile: '/profile',

    notifications: '/notifications',

    resourceCenter: '/resource-center',

    admin: '/admin',

    adminIdentities: '/admin/identities',
    adminIdentitiesUsers: '/admin/identities/users',
    adminIdentitiesUsersAdd: '/admin/identities/users/add',
    // TODO: Refactor this route to pass in :userId
    adminIdentitiesUsersEdit: '/admin/identities/users/edit',
    adminIdentitiesUsersBulkAdd: '/admin/identities/users/bulk-add',
    // TODO: Refactor all licensing to pass in :userId
    adminIdentitiesUsersLicenseAdd: '/admin/identities/users/license/add',
    adminIdentitiesUsersLicenseEdit: '/admin/identities/users/license/edit',
    adminIdentitiesUsersLicenseInheritance: '/admin/identities/users/license/inheritance',
    adminIdentitiesGroups: '/admin/identities/groups',
    adminIdentitiesGroupsAdd: '/admin/identities/groups/add',
    adminIdentitiesGroupsEdit: '/admin/identities/groups/:id',
    adminIdentitiesGroupsLicenseAdd: '/admin/identities/groups/license/add',
    adminIdentitiesGroupsLicenseEdit: '/admin/identities/groups/license/edit',
    adminIdentitiesRobots: '/admin/identities/robots',
    adminIdentitiesRobotsAdd: '/admin/identities/robots/add',
    // TODO: Refactor this route to pass in :robotId
    adminIdentitiesRobotsEdit: '/admin/identities/robots/edit',
    // Shared profile routes
    adminIdentitiesProfileGroupMemberships: '/admin/identities/:entity/:id/group-memberships',
    adminIdentitiesProfileGroupMembershipsAdd: '/admin/identities/:entity/:id/group-memberships/add',
    adminIdentitiesProfileMembers: '/admin/identities/:entity/:id/members',
    adminIdentitiesProfileMembersAdd: '/admin/identities/:entity/:id/members/add',
    adminIdentitiesProfileAccess: '/admin/identities/:entity/:id/access',
    adminIdentitiesProfileInfo: '/admin/identities/:entity/:id/info',
    adminIdentitiesProfileLicenses: '/admin/identities/:entity/:id/licenses',
    adminIdentitiesProfileLicensesEdit: '/admin/identities/:entity/:id/licenses/edit',
    adminIdentitiesProfileLicensesInheritance: '/admin/identities/:entity/:id/licenses/inheritance',

    adminAiTrustSettings: '/admin/ai-trust-settings',

    // TODO: Refactor edit routes to take an id
    adminLicenses: '/admin/licenses',
    adminLicensesUpgrade: '/admin/licenses/upgrade',
    adminLicensesUsers: '/admin/licenses/users',
    adminLicensesUsersAdd: '/admin/licenses/users/add',
    adminLicensesUsersEdit: '/admin/licenses/users/edit',
    adminLicensesUsersInherit: '/admin/licenses/users/inherit',
    adminLicensesGroups: '/admin/licenses/groups',
    adminLicensesGroupsAdd: '/admin/licenses/groups/add',
    adminLicensesGroupsEdit: '/admin/licenses/groups/edit',
    adminLicensesTenantEdit: '/admin/licenses/tenant/:type/:tenantId',
    // TODO: Routes for tabs in the licensing page. Currently not supported
    adminLicensesRobots: '/admin/licenses/robots',
    adminLicensesConsumables: '/admin/licenses/consumables',
    adminLicensesBuyProPresets: '/admin/licenses/buy-pro-presets',
    adminLicensesPending: '/admin/licenses/pending',
    adminLicensesOffline: '/admin/licenses/offline',
    adminLicensesOnline: '/admin/licenses/online',
    adminLicensesSuccess: '/admin/licenses/success',

    adminSecurity: '/admin/security',
    adminSecurityAuthentication: '/admin/security/authentication',
    adminSecurityAuthenticationConfigure: '/admin/security/authentication/configure',
    adminSecurityAuthenticationConfigureSAML: '/admin/security/authentication/configure/saml',
    adminSecurityAuthenticationConfigureAAD: '/admin/security/authentication/configure/aad',
    adminSecurityAuthenticationConfigureAD: '/admin/security/authentication/configure/ad',
    adminSecurityAuthenticationConfigureSAMLProvisioningRules: '/admin/security/authentication/configure/saml/provisioning-rules',
    adminSecurityAuthenticationConfigureSAMLProvisioningRulesAdd: '/admin/security/authentication/configure/saml/provisioning-rules/add',
    adminSecurityAuthenticationConfigureSAMLProvisioningRulesEdit: '/admin/security/authentication/configure/saml/provisioning-rules/edit/:id',
    adminSecurityAuthenticationAttributeMapping: '/admin/security/authentication/attribute-mapping/:type',
    adminSecurityIpRestriction: '/admin/security/ip-restriction',
    adminSecurityIpRestrictionAdd: '/admin/security/ip-restriction/add',
    adminSecurityIpRestrictionEdit: '/admin/security/ip-restriction/edit/:id',
    adminSecurityIpRestrictionBulkAdd: '/admin/security/ip-restriction/bulk-add',
    adminSecuritySessionPolicy: '/admin/security/session-policy',
    adminSecurityEncryption: '/admin/security/encryption',
    adminSecurityEncryptionAdd: '/admin/security/encryption/add',
    adminSecurityEncryptionEdit: '/admin/security/encryption/edit',
    adminSecurityAccessRestriction: '/admin/security/access-restriction',
    adminSecurityAccessRestrictionAdd: '/admin/security/access-restriction/add',

    adminAudit: '/admin/audit',
    adminAuditDialog: '/admin/audit/dialog',

    adminExternalApps: '/admin/external-apps',
    adminExternalAppsOauth: '/admin/external-apps/oauth',
    adminExternalAppsOauthAdd: '/admin/external-apps/oauth/add',
    adminExternalAppsOauthAddScopes: '/admin/external-apps/oauth/add/scopes',
    adminExternalAppsOuathAddScopesName: '/admin/external-apps/oauth/add/scopes/:name',
    adminExternalAppsOauthEdit: '/admin/external-apps/oauth/edit/:id',
    adminExternalAppsOuathEditScopes: '/admin/external-apps/oauth/edit/:id/scopes',
    adminExternalAppsOauthEditScopesName: '/admin/external-apps/oauth/edit/:id/scopes/:name',
    adminExternalAppsPat: '/admin/external-apps/pat',
    adminExternalAppsPatSettings: '/admin/external-apps/pat/settings',
    adminExternalAppsRegistrations: '/admin/external-apps/registrations',
    adminExternalAppsRegistrationsDetails: '/admin/external-apps/registrations/details/:publisherId/:id/:type',

    adminEmailSettings: '/admin/email-settings',

    adminSettings: '/admin/settings',
    adminSettingsGeneral: '/admin/settings/general',
    adminSettingsGeneralWarning: '/admin/settings/general/warning',
    adminSettingsAdvanced: '/admin/settings/advanced',
    adminSettingsLoginCustomization: '/admin/settings/login-customization',
    adminSettingsAdvancedMigration: '/admin/settings/advanced/migration',
    adminSettingsAdvancedMigrationEdit: '/admin/settings/advanced/migration/edit',

    adminTagsMsi: '/admin/tags',
    adminTagsMsiLabels: '/admin/tags/labels',
    adminTagsMsiLabelsAdd: '/admin/tags/labels/add',
    adminTagsMsiLabelsEdit: '/admin/tags/labels/edit/:id',
    adminTagsMsiProperties: '/admin/tags/properties',
    adminTagsMsiPropertiesAdd: '/admin/tags/properties/add',
    adminTagsMsiPropertiesAddValues: '/admin/tags/properties/add/values',
    adminTagsMsiPropertiesEdit: '/admin/tags/properties/edit/:editType/:id',
    adminTagsMsiPropertiesEditValues: '/admin/tags/properties/edit/:editType/:id/values',

    adminUserConsent: '/admin/user-consent',
    adminUserConsentCreate: '/admin/user-consent/create',
    adminUserConsentEdit: '/admin/user-consent/edit/:id',

    adminTenant: '/admin/tenant',
    adminTenantCreate: '/admin/tenant/create',
    adminTenantHome: '/admin/tenant/:tenantId',

    adminTenantServices: '/admin/tenant/:tenantId/services',
    adminTenantServicesAdd: '/admin/tenant/:tenantId/services/add',
    adminTenantServicesConfigure: '/admin/tenant/:tenantId/services/:type',
    adminTenantServicesConfigureService: '/admin/tenant/:tenantId/services/:type/:serviceType',
    adminTenantServicesUsage: '/admin/tenant/:tenantId/services/usage',
    adminTenantServicesLogExport: '/admin/tenant/:tenantId/services/log-export',

    adminTenantManageAccess: '/admin/tenant/:tenantId/manage-access',
    adminTenantManageAccessCheckAccess: '/admin/tenant/:tenantId/manage-access/check-access',

    adminTenantLicenses: '/admin/tenant/:tenantId/licenses',
    adminTenantLicensesEditAllocation: '/admin/tenant/:tenantId/licenses/:type',

    adminTenantTags: '/admin/tenant/:tenantId/tags',
    adminTenantTagsLabels: '/admin/tenant/:tenantId/tags/labels',
    adminTenantTagsLabelsAdd: '/admin/tenant/:tenantId/tags/labels/add',
    adminTenantTagsLabelsEdit: '/admin/tenant/:tenantId/tags/labels/edit/:id',
    adminTenantTagsProperties: '/admin/tenant/:tenantId/tags/properties',
    adminTenantTagsPropertiesAdd: '/admin/tenant/:tenantId/tags/properties/add',
    adminTenantTagsPropertiesAddValues: '/admin/tenant/:tenantId/tags/properties/add/values',
    adminTenantTagsPropertiesEdit: '/admin/tenant/:tenantId/tags/properties/edit/:editType/:id',
    adminTenantTagsPropertiesEditValues: '/admin/tenant/:tenantId/tags/properties/edit/:editType/:id/values',

    adminTenantGateway: '/admin/tenant/:tenantId/gateway',
    adminTenantGatewayAddEdit: '/admin/tenant/:tenantId/gateway/:type',
    adminTenantGatewayAdd: '/admin/tenant/:tenantId/gateway/add',
    adminTenantGatewayEdit: '/admin/tenant/:tenantId/gateway/edit',
    adminTenantGatewayConnections: '/admin/tenant/:tenantId/gateway/connections/:vpnGatewayKey',
    adminTenantGatewayConnectionsAddEdit: '/admin/tenant/:tenantId/gateway/connections/:vpnGatewayKey/:type',
    adminTenantGatewayConnectionsAdd: '/admin/tenant/:tenantId/gateway/connections/:vpnGatewayKey/add',
    adminTenantGatewayConnectionsEdit: '/admin/tenant/:tenantId/gateway/connections/:vpnGatewayKey/edit',

    adminTenantAudit: '/admin/tenant/:tenantId/audit',

    adminTenantSettings: '/admin/tenant/:tenantId/settings',
    adminTenantSettingsMigration: '/admin/tenant/:tenantId/settings/migration',
    adminTenantSettingsMigrationEdit: '/admin/tenant/:tenantId/settings/migration/edit',
} as const;

// export type RoutePath = typeof routePaths[keyof typeof routePaths];
