import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { NotificationAdminSettingsEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
    useUserReadableTime,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    deleteProfile,
    getSubscriptionProfiles,
    SubscriptionProfileMetadata,
} from '../../../services/notification-preferences';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiGrid } from '../../common/UiGrid';
import type { IActionHeader } from '../../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../../common/UiGrid/grid';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import {
    type INotificationProfile,
    ProfileType,
} from '../interfaces/notificationSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            marginTop: '24px',
            lineHeight: Tokens.FontFamily.FontMLineHeight,
        },
        subheading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            marginTop: '4px',
        },
        mainHeading: { paddingBottom: '20px' },
        redIcon: { color: theme.palette.semantic.colorErrorIcon },
        mainContentPlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
        },
    }),
);

const NotificationsProfiles: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const { userReadableTime } = useUserReadableTime();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const createNotification = useUiSnackBar();
    const {
        data: profilesData, mutate, isValidating: isValidating, error,
    } = useSWR(
        tenantId ? {
            requestUri: SubscriptionProfileMetadata,
            selectedTenantId: tenantId,
        } : null,
        getSubscriptionProfiles,
    );
    const openTokenDrawer = useCallback(() => {
        navigate(getRoute(RouteNames.AddUserGroupProfile));
    }, [ getRoute, navigate ],
    );
    const { logEvent } = useTelemetryHelper();
    const openDeleteDialog = useCallback(
        async (data: { displayName: string; name: string }) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_PROFILE_TITLE' }),
                body: (
                    <UiText>
                        {translate({ id: 'CLIENT_DELETE_PROFILE_CONFIRMATION' }, { profilename: data.displayName })}
                    </UiText>
                ),
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });

            if (proceed) {
                try {
                    const id = data.name;
                    await deleteProfile(id, tenantId);
                    createNotification(
                        translate({ id: 'CLIENT_DELETE_PROFILE_CONFIRMATION_DELETED_SUCCESSFULLY' }),
                        notificationType.SUCCESS
                    );
                    mutate();
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                }
            }
        }, [ createDialog, translate, tenantId, createNotification, mutate, setErrorMessage, getErrorMessage ]);

    const onEditRow = useCallback(
        async (data: {
            name: string;
            displayName: string;
            profileType: ProfileType;
            isLocalGroup: boolean;
            hasEmail: boolean;
        }) => {
            const identifier = data.name;
            if (data.profileType === ProfileType.User) {
                logEvent(NotificationAdminSettingsEvent.EditDefaultProfile);
            }

            const editProfileRoute = RouteNames.AddNotificationSettingsProfile
                .replace('/:id', `/${identifier}`)
                .replace('/:name', `/${data.displayName}`);
            navigate(getRoute(editProfileRoute), {
                state: {
                    identifier,
                    type: data.profileType,
                    local: data.isLocalGroup,
                    hasEmail: data.hasEmail,
                },
            });
        }, [ getRoute, logEvent, navigate ],
    );
    const [ showInfoAlert, setshowInfoAlert ] = React.useState<boolean>(true);

    const actions: IActionHeader[] = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'ADD_USER_GROUP' }),
            icon: <AddIcon />,
            click: row => {
                openTokenDrawer();
            },
            variant: 'contained',
            dataCy: 'ui-grid-add-token-button',
        },
    ], [ openTokenDrawer, translate ]);
    const isContentReady = useMemo(() => profilesData?.value.length && !isValidating, [ profilesData?.value, isValidating ]);
    return (
        <>
            {isContentReady && <div>
                <UiText
                    className={clsx(classes.heading)}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_USER_GROUP_NOTIFICATION_PROFILE' })}
                </UiText>
                <UiText
                    className={clsx(classes.subheading, classes.mainHeading)}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_USER_GROUP_NOTIFICATION_PROFILE_SUBHEADING' })}
                </UiText>
                {showInfoAlert && <PortalAlertBar
                    status="info"
                    cancelable={false}
                    onAlertDismissed={() => setshowInfoAlert(false)}>
                    <div>
                        {translate({ id: 'CLIENT_NOTIFICATION_USER_GROUP_INFO' })}
                    </div>
                </PortalAlertBar>}
                <UiGrid<INotificationProfile>
                    dataCy="profiles-ui-grid"
                    tableHeight={`${60 * (profilesData?.value === undefined ? 1 : profilesData?.value.length) + 125}px`}
                    pagination
                    search
                    searchPlaceholder='Search'
                    extraActionButtons={actions}
                    initialSort={[ { id: 'displayName' }, { id: 'description' } ]}
                    loading={isValidating}
                    columns={[
                        {
                            accessor: 'displayName',
                            Header: translate({ id: 'CLIENT_USER_GROUP' }),
                            disableSortBy: false,
                            width: 15,
                        },
                        {
                            accessor: 'description',
                            Header: translate({ id: 'CLIENT_USER_GROUP_GRID_COLUMN_DESCRIPTION' }),
                            disableSortBy: false,
                            width: 40,
                        },
                        {
                            accessor: 'modifiedByUser',
                            Header: translate({ id: 'CLIENT_USER_GROUP_GRID_COLUMN_LASTMODIFIED' }),
                            width: 15,
                        },
                        {
                            accessor: 'lastModifiedon',
                            Header: translate({ id: 'CLIENT_USER_GROUP_GRID_COLUMN_LASTMODIFIED_ON' }),
                            width: 15,
                            Cell: ({ row }) => <div>
                                {
                                    !row.original.modifiedTime ? (
                                        <div>
                                            {translate({ id: 'CLIENT_NEVER' })}
                                        </div>
                                    ) : (
                                        <div>
                                            {userReadableTime(row.original.modifiedTime)}
                                        </div>
                                    )
                                }
                            </div>,
                        },
                    ]}
                    data={profilesData?.value ?? []}
                    rowActions={[
                        {
                            type: ButtonType.Icon,
                            icon: <EditIcon />,
                            label: translate({ id: 'CLIENT_EDIT' }),
                            tooltip: translate({ id: 'CLIENT_EDIT' }),
                            actionType: GridActionType.Row,
                            click: row => onEditRow(row.original),
                            dataCy: 'action-edit-profile',
                        },
                        {
                            type: ButtonType.Icon,
                            disable: (row) => row?.original.profileType === 'User',
                            icon: <DeleteIcon />,
                            label: translate({ id: 'CLIENT_DELETE' }),
                            tooltip: (row) => translate({ id: row?.original.profileType === 'User' ? 'CLIENT_NS_PROFILE_DELETE_TOOLTIP' : 'CLIENT_DELETE' }),
                            actionType: GridActionType.Row,
                            click: row => openDeleteDialog(row.original),
                            dataCy: 'action-delete-profile',
                        },
                    ]}
                />
            </div>}
            {(!isContentReady && !error) && (
                <div className={classes.mainContentPlaceholder}>
                    <CircularProgress
                        thickness={2}
                        size={36} />
                </div>
            )}
        </>
    );
};

export default NotificationsProfiles;
