import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { Navigate } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { AuditPageWithProvider } from './AuditPage';
import { AuditPageComponentLegacyWithProvider } from './AuditPageComponentLegacy';

/**
 * allows switching the audit experience on/off by rerouting the request
 * to the legacy route /auditLog or the new route, /audit
 *
 * NOTE: can be deprecated once audit experience is fully rolled out
 * @returns redirect to new audit experience or the old
 */
const AuditRedirect = () => {
    const getRoute = useRouteResolver();

    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);

    return <Navigate
        replace
        to={
            EnableUnifiedAuditExperience ? getRoute(RouteNames.Audit) : getRoute(RouteNames.AuditLogs)
        } />;
};

const AuditLegacyRoutingRedirect = ({ unifiedAuditRoute }: PropsWithChildren<{ unifiedAuditRoute?: boolean }>) => {
    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);

    if (unifiedAuditRoute) {
        return EnableUnifiedAuditExperience ? <AuditPageWithProvider /> : <AuditRedirect />;
    }

    return EnableUnifiedAuditExperience ? <AuditRedirect /> : <AuditPageComponentLegacyWithProvider />;
};

/**
 * Used for legacy routing experience
 */
export const AuditLegacyRoutingRedirectWithProvider = ({ unifiedAuditRoute }: PropsWithChildren<{ unifiedAuditRoute?: boolean }>) =>
    <AuditLegacyRoutingRedirect unifiedAuditRoute={unifiedAuditRoute} />;
