import { concurrentProductCodes } from '../../../../common/constants/Constant';
import type { ITenantServiceLicense } from '../../interfaces/service';
import {
    sortedProductCodes,
    sortedServiceTypes,
} from './ManageLicensesHelper';

export const getProductsFromServices = (licenseData: ITenantServiceLicense[] | undefined, concurrentLicenseEnabled: boolean) => {
    const initialLicenses: { [code: string]: string | number | undefined } = {};
    licenseData
        ?.sort((l1, l2) => sortedServiceTypes.indexOf(l1.serviceType) - sortedServiceTypes.indexOf(l2.serviceType))
        .forEach(serviceLicense => {
            serviceLicense.products = serviceLicense.products.filter(
                product => !process.buildConfigs.unavailableProductCodes?.includes(product.code) ?? true);
            serviceLicense.products.sort((p1, p2) => sortedProductCodes.indexOf(p1.code) - sortedProductCodes.indexOf(p2.code));
            serviceLicense.products.forEach(product => {
                initialLicenses[product.code] = Math.max(product.available ?? 0, 0);
            });
            if (serviceLicense.serviceType === 'orchestrator' && !concurrentLicenseEnabled) {
                serviceLicense.products = serviceLicense.products.filter(
                    product => concurrentProductCodes.indexOf(product.code) === -1,
                );
            }
        });
    return initialLicenses;
};
