/* eslint-disable max-len */
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    portalTelemetry,
    SeverityLevel,
    UsersEvent,
} from '@experiences/telemetry';
import {
    GlobalStyles,
    useApolloTheme,
} from '@experiences/theme';
import {
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    getDisplayName,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutline';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import type { Row } from 'react-table';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { UserType } from '../../common/constants/UsersConstant';
import useSimpleGroup from '../../common/hooks/SimpleGroup';
import { useCheckAADEnabled } from '../../common/hooks/useCheckAADEnabled';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { useLoginProvider } from '../../common/hooks/useLoginProvider';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IUserCIS } from '../../common/interfaces/cis/user';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import ExternalLicenseIcon from '../../images/icons/ExternalLicenseIcon';
import {
    checkIfOnlyUserInGroup,
    groupUrl,
} from '../../services/identity/GroupService';
import { createInviteUrl } from '../../services/identity/UserInvite';
import getUsersInPartition, {
    getUsersInPartitionWithLicenses,
    userPartitionUrl,
} from '../../services/identity/UserPartitionService';
import { deleteUsers } from '../../services/identity/UserService';
import {
    accountGlobalId,
    accountType,
    companyName,
    EnableUserLicensingSelector,
    isAdminSelector,
    isHostModeSelector,
    isUnlicensedSelector,
    organizationLanguage,
    userGlobalId,
} from '../../store/selectors';
import type {
    IAction,
    IActionHeader,
} from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiPaginatedGrid } from '../common/UiPaginatedGrid';
import { UserGroup } from '../common/UserGroups';
import type {
    IUserCISResponse,
    IUserLicenses,
} from './interfaces/userLicense';
import ResendInvitationDialogBody from './subcomponents/ResendInvitationDialogBody';

type IUserWithLicenses = IUserCIS & Partial<IUserLicenses>;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        userNameCell: { display: 'flex' },
        userRoleLabel: {
            fontSize: '11px',
            fontWeight: 'bold',
            marginLeft: '8px',
            backgroundColor: theme.palette.semantic.colorBackgroundHigh,
            height: '18px',
            marginTop: '1px',
            '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundHigh },
        },
        azureDescription: {
            marginTop: '8px',
            marginBottom: '8px',
        },
        resendButton: {
            marginLeft: '8px',
            fontWeight: 600,
            color: theme.palette.semantic.colorPrimary,
            backgroundColor: theme.palette.semantic.colorBackground,
            borderWidth: '0px',
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
        },
        licenseIcon: {
            height: '20px',
            width: '20px',
            marginRight: '4px',
        },
        tableActionButton: {
            color: theme.palette.semantic.colorPrimary,
            fontSize: '14px',
        },
        leftSpacing: { marginLeft: '5px' },
    }),
}));

const UsersCISPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const { themeId } = useApolloTheme();
    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const { data: { refresh: refreshData } } = useUiDataContext<{ refresh: boolean }>();

    const userId = useSelector(userGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgLanguage = useSelector(organizationLanguage);
    const orgName = useSelector(companyName);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const isHostMode = useSelector(isHostModeSelector);
    const subscriptionPlan = useSelector(accountType);

    const {
        loading: groupLoading, getGroupsForUser,
    } = useSimpleGroup(true);

    const isSSOConnectionEnabled = useCheckSSOEnabled();
    const isLicenseExpired = useLicenseExpired();
    const isAADConnectionEnabled = useCheckAADEnabled();
    const createDialog = useShowDialog();
    const loginProvider = useLoginProvider();
    const setErrorMessage = useCentralErrorSetter();

    const {
        data: lastAdminInOrg, isValidating,
    } = useSWR(
        !process.buildConfigs.disableUsersLeaveOrg && isAdmin && !isSSOConnectionEnabled
            ? {
                url: `${groupUrl}/:id/checkIfOnlyUserInGroup`,
                partitionGlobalId,
                userGlobalId: userId,
                groupId: UserGroup.Administrators,
            }
            : null,
        checkIfOnlyUserInGroup,
    );

    const [ refresh, setRefresh ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ disabledEmails, setDisabledEmails ] = React.useState<string[]>([]);

    const dataUrl = useMemo(
        () => (EnableUserLicensing && isAdmin ? `${userPartitionUrl}/licenses` : `${userPartitionUrl}/users`),
        [ EnableUserLicensing, isAdmin ],
    );

    useEffect(() => {
        setLoading(groupLoading || isValidating);
    }, [ groupLoading, isValidating ]);

    useEffect(() => {
        setRefresh(refreshData);
    }, [ refreshData ]);

    const deleteUsersAsync = useCallback(
        async (users: IUserCIS[], deleteCurrentUser: boolean = false) => {
            try {
                if (users.length > 0) {
                    const userIds = users.map(user => user.id);
                    createNotification(translate({ id: 'CLIENT_USER_DELETED' }), notificationType.INPROGRESS);
                    await deleteUsers(partitionGlobalId, userIds, deleteCurrentUser, isHostMode);

                    createNotification(
                        translate({ id: 'CLIENT_SUCCESSFULLY_DELETED_USERS' }),
                        notificationType.SUCCESS
                    );

                    portalTelemetry.trackEvent({
                        name: UsersEvent.Delete,
                        properties: {
                            Message: 'INV-USERS-05 - User(s) deleted',
                            Code: 'INV-USERS-05',
                            SubscriptionType: subscriptionPlan,
                            NoOfDeletedUsers: (users.length).toString(),
                        },
                    });

                    setRefresh(true);
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        },
        [ createNotification, translate, partitionGlobalId, isHostMode, subscriptionPlan, setErrorMessage, getErrorMessage ],
    );

    const openDeleteDialog = useCallback(
        async (rows: Array<Row<IUserCIS>> | Row<IUserCIS>) => {
            const users: IUserCIS[] = Array.isArray(rows) ? rows.map(row => row.original) : [ rows.original ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_USER' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_USERS' },
                    { 0: users.length === 1 ? getDisplayName(users[0]) : translate({ id: 'CLIENT_SELECTED_USERS_TEXT' }) },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_USER_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const filteredUsers = users.filter(user => user.id !== userId);
                deleteUsersAsync(filteredUsers);
            }
        },
        [ translate, createDialog, deleteUsersAsync, userId ],
    );

    const openLeaveOrgDialog = useCallback(
        async (row: Row<IUserCIS>) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_LEAVE_ORG' }),
                body: `${translate({ id: 'CLIENT_SHOULD_LEAVE_ORG' }, { name: orgName })}${translate({ id: 'CLIENT_SINGLE_USER_DELETE_SUFFIX' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
            });
            if (proceed) {
                const currentUser = [ row.original ];
                try {
                    await deleteUsersAsync(currentUser, true);
                    createNotification(translate({ id: 'CLIENT_USER_LEAVE_ORG' }), notificationType.INPROGRESS);
                    navigate('/portal_/logout');
                } catch (error) {
                    createNotification(translate({ id: 'CLIENT_USER_LEAVE_FAILED' }), notificationType.ERROR);
                }
            }
        },
        [ createDialog, translate, orgName, deleteUsersAsync, createNotification, navigate ],
    );

    const openAdminLeaveOrgDialog = useCallback(
        async (row: Row<IUserCIS>) => {
            if (!lastAdminInOrg) {
                openLeaveOrgDialog(row);
            } else {
                await createDialog({
                    title: translate({ id: 'CLIENT_UNABLE_LEAVE_ORG' }),
                    body: translate({ id: 'CLIENT_LEAVE_ORG_MIN_ONE_ADMIN' }),
                    icon: 'error',
                    primaryButtonText: 'OK',
                });
            }
        },
        [ translate, createDialog, openLeaveOrgDialog, lastAdminInOrg ],
    );

    const openResendInviteDialog = useCallback(
        async (row: Row<IUserCIS>) => {
            const inviteUrl = createInviteUrl(
                window.location.origin,
                partitionGlobalId,
                row.original.email,
                loginProvider,
                orgName,
            );

            await createDialog({
                title: translate({ id: 'CLIENT_RESEND_INVITE' }),
                customDialogContent: ResendInvitationDialogBody,
                customDialogContentProps: {
                    user: row.original,
                    orgLanguage,
                    orgName,
                    disabledEmails,
                    setDisabledEmails,
                    loginProvider,
                    inviteUrl,
                },
            });
        },
        [ createDialog, translate, partitionGlobalId, disabledEmails, orgLanguage, orgName, loginProvider ],
    );

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableAzureB2CAuthentication = useFeatureFlagValue(Features.EnableAzureB2CAuthentication.name);

    const ShowInviteUserButton = DisableFeatureFedRamp ? EnableAzureB2CAuthentication : true;

    const EnableUserInvite = useMemo(
        () =>
            !isUnlicensedMode && !process.buildConfigs.disableUserInvite && ShowInviteUserButton,
        [ ShowInviteUserButton, isUnlicensedMode ],
    );

    const handleInviteUser = useCallback(() => {
        navigate(`${getRoute(RouteNames.Users)}/invite`);
    }, [ getRoute, navigate ]);

    const handleBulkInviteUsers = useCallback(() => {
        portalTelemetry.trackTrace({
            message: `Clicked on Upload CSV button`,
            severityLevel: SeverityLevel.Info,
        });
        navigate(`${getRoute(RouteNames.Users)}/bulkinvite`);
    }, [ getRoute, navigate ]);

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const actionList: IActionHeader[] = [];

        const addUsersOnPrem: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_USER' }),
            icon: <AddIcon />,
            click: () => navigate(`${getRoute(RouteNames.Users)}/add`),
            invisible: !isAdmin,
            variant: 'contained',
            className: clsx(classes.leftSpacing),
            dataCy: 'add-user-button',
        };

        const inviteUsersBulk: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_BULK_INVITE' }),
            icon: <AddToPhotosIcon />,
            click: handleBulkInviteUsers,
            invisible: !(isAdmin && EnableUserInvite),
            variant: 'text',
            className: clsx(classes.tableActionButton, classes.leftSpacing),
            dataCy: 'bulk-invite-users-button',
        };

        const inviteUsers: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_INVITE_USER' }),
            icon: <AddIcon />,
            click: handleInviteUser,
            invisible: !(isAdmin && EnableUserInvite),
            variant: 'contained',
            className: clsx(classes.leftSpacing),
            dataCy: 'invite-users-button',
        };

        process.buildConfigs.name === 'msi' || process.buildConfigs.name === 'k8s'
            ? actionList.push(inviteUsersBulk, addUsersOnPrem, inviteUsers)
            : actionList.push(inviteUsersBulk, inviteUsers);

        return actionList;
    }, [
        handleInviteUser,
        classes.leftSpacing,
        classes.tableActionButton,
        translate,
        isAdmin,
        EnableUserInvite,
        handleBulkInviteUsers,
        navigate,
        getRoute,
    ]);

    const getRowActions = useCallback(() => {
        const actions: Array<IAction<IUserCIS>> = [];
        if (isAdmin) {
            if (EnableUserLicensing && !isUnlicensedMode) {
                actions.push({
                    type: ButtonType.Icon,
                    label: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                    tooltip: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                    actionType: GridActionType.Row,
                    icon: <AllocateLicenseIcon />,
                    dataCy: 'ui-grid-edit-user-allocations-button',
                    click: row => {
                        navigate(getRoute(RouteNames.UsersUserEditLicenseAllocation),
                            {
                                state: {
                                    user: row.original,
                                    previousLocation: location.pathname,
                                },
                            });
                    },
                    disable: () => isLicenseExpired,
                });
            }

            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_EDIT' }),
                tooltip: translate({ id: 'CLIENT_EDIT' }),
                actionType: GridActionType.Row,
                icon: <EditIcon />,
                click: row => {
                    const user: IUserCIS = row.original;
                    navigate(`${getRoute(RouteNames.Users)}/edit`,
                        { state: { user } });
                },
                dataCy: 'edit-user-button',
            });
            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_DELETE' }),
                tooltip: translate({ id: 'CLIENT_DELETE' }),
                actionType: GridActionType.Row,
                icon: <DeleteForeverOutlinedIcon />,
                invisible: row => row?.original?.id === userId,
                click: openDeleteDialog,
                dataCy: 'delete-user-button',
            });
        }

        if (!process.buildConfigs.disableUsersLeaveOrg) {
            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_LEAVE_ORG' }),
                tooltip: translate({ id: 'CLIENT_LEAVE_ORG' }),
                actionType: GridActionType.Row,
                icon: <ExitToAppIcon />,
                invisible: row => row?.original?.id !== userId,
                click: isAdmin ? openAdminLeaveOrgDialog : openLeaveOrgDialog,
                dataCy: 'leave-org-button',
            });
        }

        return actions;
    }, [
        isAdmin,
        EnableUserLicensing,
        isUnlicensedMode,
        translate,
        openDeleteDialog,
        navigate,
        getRoute,
        isLicenseExpired,
        userId,
        openAdminLeaveOrgDialog,
        openLeaveOrgDialog,
    ]);

    const getLicenseEntry = useCallback(
        (user: IUserWithLicenses) => {
            const text = user.useExternalLicense
                ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
                : user.userBundleCodes?.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ');

            const tooltip = user.inheritedFromGroup ? 'CLIENT_INHERIT_FROM_GROUP' : 'CLIENT_ALLOCATED_TO_USER';

            const Icon = user.useExternalLicense
                ? ExternalLicenseIcon
                : user.inheritedFromGroup
                    ? PeopleOutlineOutlinedIcon
                    : PersonOutlined;

            return {
                text,
                tooltip: text ? translate({ id: tooltip }) : '',
                Icon: text ? Icon : null,
            };
        },
        [ translate ],
    );

    return (
        <>
            {isAADConnectionEnabled && (
                <UiText className={classes.azureDescription}>
                    {translate({
                        id:
              DisableFeatureFedRamp || process.buildConfigs.isOnPrem
                  ? 'CLIENT_AZURE_USERS_DESCRIPTION_NO_INVITE'
                  : 'CLIENT_AZURE_USERS_DESCRIPTION',
                    })}
                </UiText>
            )}
            <UiPaginatedGrid<IUserWithLicenses, IUserCISResponse, { partitionGlobalId: string }>
                url={dataUrl}
                fetcher={EnableUserLicensing && isAdmin ? getUsersInPartitionWithLicenses : getUsersInPartition}
                fetcherArgs={{ partitionGlobalId }}
                data-cy="users-ui-grid"
                loading={loading}
                search
                searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                extraActionButtons={extraActionHeaderButtons}
                refreshData={{
                    current: refresh,
                    set: setRefresh,
                }}
                filters
                checkbox={isAdmin}
                disableCheckboxPerRow={row => row.original.id === userId}
                columns={[
                    ...(process.buildConfigs.enableUsernameColumn
                        ? [
                            {
                                accessor: 'userName',
                                sortName: 'UserName',
                                Header: translate({ id: 'CLIENT_USERNAME' }),
                                width: 25,
                                Cell: (({ row }: { row: Row<IUserWithLicenses> }) => (
                                    <div className={classes.userNameCell}>
                                        <Tooltip
                                            arrow
                                            title={`${row.original.userName}
                                            ${getGroupsForUser(row.original.groupIDs)?.find(group => group === 'Administrators') ? translate({ id: 'CLIENT_ORG_ADMIN' }) : ''}`}>
                                            <span>
                                                {row.original.userName}
                                            </span>
                                        </Tooltip>
                                        {!isHostMode &&
                                            getGroupsForUser(row.original.groupIDs)?.find(group => group === 'Administrators') ? (
                                                <Chip
                                                    className={classes.userRoleLabel}
                                                    label={translate({ id: 'CLIENT_ORG_ADMIN' })}
                                                    style={{ color: themeId === 'dark' ? Tokens.Colors.ColorOrange700 : Tokens.Colors.ColorOrange300 }}
                                                />
                                            ) : null}
                                    </div>
                                ) as React.ReactNode) as any,
                            },
                            {
                                accessor: 'name',
                                sortName: 'Name',
                                Header: translate({ id: 'CLIENT_NAME' }),
                                width: 25,
                                Cell: (({ row }: { row: Row<IUserWithLicenses> }) => (
                                    <div>
                                        <Tooltip
                                            arrow
                                            title={getDisplayName(row.original)}>
                                            <span>
                                                {getDisplayName(row.original)}
                                            </span>
                                        </Tooltip>
                                    </div>
                                ) as React.ReactNode) as any,
                            },
                        ]
                        : [
                            {
                                accessor: 'userName',
                                sortName: 'Name',
                                Header: translate({ id: 'CLIENT_NAME' }),
                                width: 25,
                                Cell: (({ row }: { row: Row<IUserWithLicenses> }) => (
                                    <div className={classes.userNameCell}>
                                        <Tooltip
                                            arrow
                                            title={`${getDisplayName(row.original)}
                                            ${getGroupsForUser(row.original.groupIDs)?.find(group => group === 'Administrators') ? translate({ id: 'CLIENT_ORG_ADMIN' }) : ''}`}>
                                            <span>
                                                {getDisplayName(row.original)}
                                            </span>
                                        </Tooltip>
                                        {getGroupsForUser(row.original.groupIDs)?.find(group => group === 'Administrators') ? (
                                            <Chip
                                                className={classes.userRoleLabel}
                                                label={translate({ id: 'CLIENT_ORG_ADMIN' })}
                                                style={{ color: themeId === 'dark' ? Tokens.Colors.ColorOrange700 : Tokens.Colors.ColorOrange300 }}
                                            />
                                        ) : null}
                                    </div>
                                ) as React.ReactNode) as any,
                            },
                        ]),
                    {
                        accessor: 'email',
                        sortName: 'Email',
                        Header: translate({ id: 'CLIENT_EMAIL' }),
                        width: 25,
                    },
                    ...(!isHostMode
                        ? [
                            {
                                accessor: 'groups',
                                Header: translate({ id: 'CLIENT_GROUPS' }),
                                width: 25,
                                disableSortBy: true,
                                Cell: ({ row }: { row: Row<IUserWithLicenses> }) => {
                                    const groups = getGroupsForUser(row.original.groupIDs)?.join(', ');
                                    return groups ? (
                                        <Tooltip
                                            arrow
                                            placement="bottom-start"
                                            title={groups}>
                                            <span style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}>
                                                {groups}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span>
                                            {translate({ id: 'CLIENT_NO_GROUPS_FOUND' })}
                                        </span>
                                    );
                                },
                            },
                        ]
                        : []),
                    ...(EnableUserLicensing && isAdmin && !isUnlicensedMode
                        ? [
                            {
                                accessor: 'userBundleLicenses',
                                Header: translate({ id: 'CLIENT_LICENSES' }),
                                width: 25,
                                disableSortBy: true,
                                Cell: ({ row }: { row: Row<IUserWithLicenses> }) => {
                                    const {
                                        text, tooltip, Icon,
                                    } = getLicenseEntry(row.original);
                                    return (
                                        <Tooltip
                                            title={tooltip}
                                            placement="bottom-start">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                {Icon && <Icon className={clsx(classes.licenseIcon, classes.icon)} />}
                                                <span data-cy="user-license">
                                                    {text}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]
                        : []),
                    {
                        accessor: 'lastLoginTime',
                        sortName: 'LastLoginTime',
                        Header: translate({ id: 'CLIENT_LAST_ACTIVE' }),
                        width: 25,
                        Cell: ({ row }) => {
                            const user: IUserCIS = row.original;
                            return !user.lastLoginTime || new Date(user.lastLoginTime).getTime() <= 0 ? (
                                !process.buildConfigs.disableUserInvite &&
                  user.type !== UserType.DirectoryUser &&
                  !DisableFeatureFedRamp && (
                                    <span>
                                        {isAdmin ? translate({ id: 'CLIENT_PENDING' }) : translate({ id: 'CLIENT_PENDING_INVITATION' })}
                                        {isAdmin && (
                                            <Tooltip title={translate({ id: 'CLIENT_RESEND' })}>
                                                <button
                                                    className={classes.resendButton}
                                                    data-cy="resend-invite-button"
                                                    onClick={() => openResendInviteDialog(row)}
                                                >
                                                    {translate({ id: 'CLIENT_RESEND' })}
                                                </button>
                                            </Tooltip>
                                        )}
                                    </span>
                                )
                            ) : (
                                <FormattedDate
                                    value={new Date(user.lastLoginTime)}
                                    year="numeric"
                                    month="short"
                                    day="numeric" />
                            );
                        },
                    },
                ]}
                tableActions={
                    isAdmin
                        ? [
                            {
                                type: ButtonType.Button,
                                label: translate({ id: 'CLIENT_DELETE' }),
                                icon: <DeleteForeverOutlinedIcon />,
                                actionType: GridActionType.Selected,
                                click: openDeleteDialog,
                            },
                        ]
                        : []
                }
                rowActions={getRowActions()}
            />
        </>
    );
};

export default UsersCISPageComponent;
