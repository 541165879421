import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
    }),
);

const LoadingStateSkeletonComponent: React.FC<{
    childGridColumns: number;
    childGridElements: number[];
}> = ({
    childGridColumns,
    childGridElements,
}) => {

    const classes = useStyles();

    return (<>
        <Grid
            container
            spacing={3}>
            {childGridElements.map(number => (
                <Grid
                    key={number}
                    item
                    xs={childGridColumns}>
                    <div className={classes.content}>
                        <div style={{ flex: 0.9 }}>
                            <Skeleton
                                variant="text"
                                width={150}
                                style={{ marginBottom: '8px' }} />
                            <Skeleton
                                variant="rectangular"
                                height={36} />
                        </div>
                        <Skeleton
                            variant="circular"
                            height={28}
                            width={28} />
                    </div>
                </Grid>
            ))}
        </Grid>
    </>
    );
};

export default LoadingStateSkeletonComponent;
