import { isImage } from '@experiences/util';
import type {
    DetailedHTMLProps,
    FC,
    ImgHTMLAttributes,
} from 'react';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';

export const UiSafeImg: FC<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({
    src, alt, ...props
}) => {
    const [ imgSrc, setImgSrc ] = useState(src);
    const [ error, setError ] = useState(false);

    useEffect(() => {
        setImgSrc(src);
        setError(false);
    }, [ src ]);

    const onError = useCallback(() => {
        setError(true);
    }, []);

    if (imgSrc && isImage(imgSrc) && !error) {
        return <img
            src={imgSrc}
            alt={alt}
            onError={onError}
            {...props} />;
    }

    return null;
};
