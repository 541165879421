import {
    DAYS,
    isSameDate,
} from '@experiences/util';

export enum QuickFilters {
    None = -1,
    LastDay = 1,
    LastWeek = 7,
    Last30Days = 30,
    Last90Days = 90,
}

export const quickFilterLabels: Record<QuickFilters, string> = {
    [QuickFilters.None]: '',
    [QuickFilters.LastDay]: 'CLIENT_LAST_DAY',
    [QuickFilters.LastWeek]: 'CLIENT_LAST_WEEK',
    [QuickFilters.Last30Days]: 'CLIENT_LAST_30_DAYS',
    [QuickFilters.Last90Days]: 'CLIENT_LAST_90_DAYS',
};

export function getQuickFilter(from?: Date, to?: Date): QuickFilters | undefined {
    let quickFilter: QuickFilters | undefined = undefined;

    // if the to is not today, then its definitely not a quick filter
    if (to && !isSameDate(to, new Date())) {
        return undefined;
    }

    Object.values(QuickFilters).forEach((filter) => {
        if (from && isSameDate(from, new Date(Date.now() - Number(filter) * DAYS))) {
            quickFilter = filter as QuickFilters;
        }
    });

    return quickFilter;
}

export function getQuickFilterLabel(from?: Date, to?: Date) {
    const quickFilter = getQuickFilter(from, to);

    if (!quickFilter) {
        return undefined;
    }

    return quickFilterLabels[quickFilter];
}
