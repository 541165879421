import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import token from '@uipath/apollo-core/lib';
import {
    ApDropdown,
    ApDropdownItem,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import { AlertDurationEnum } from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        divider: {
            marginBottom: token.Spacing.SpacingXl,
            borderColor: theme.palette.semantic.colorBorderDeEmp,
        },
        mainHeading: { marginBottom: token.Padding.PadXxl },
        mainHeadingArea: { marginBottom: token.Spacing.SpacingXl },
        dropdown: {
            width: '220px',
            minWidth: '120px',
        },
    }),
);

const durationTranslationKey = {
    [AlertDurationEnum.s3]: 'CLIENT_DURATION_SECONDS',
    [AlertDurationEnum.s5]: 'CLIENT_DURATION_SECONDS_DEFAULT',
    [AlertDurationEnum.s10]: 'CLIENT_DURATION_SECONDS',
    [AlertDurationEnum.s30]: 'CLIENT_DURATION_SECONDS',
    [AlertDurationEnum.s60]: 'CLIENT_DURATION_1_MINUTE',
    [AlertDurationEnum.NODISMISS]: 'CLIENT_UNTIL_DISMISS',
};

const ProfileAccessibilitySettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const alertDuration = window?.PortalShell?.Alert?.alertDuration;

    const selectedDuration = !alertDuration ? AlertDurationEnum.NODISMISS.toString() : alertDuration.toString();

    const durationDropdownItems = useMemo(() => Object.entries(durationTranslationKey).map(([ key, value ]) => (
        <ApDropdownItem
            value={key}
            label={translate({ id: value }, { 0: parseInt(key) / 1000 })}
            key={`ap-dropdown-item-${key}`}
        />
    )), [ translate ]);

    const handleSelectDurationChange = useCallback((event: ApDropdownCustomEvent<string | string[]>) => {
        if (window.PortalShell?.Alert) {
            try {
                const value = parseInt(event.detail as string);
                if (window.PortalShell.Alert.alertDuration !== value) {
                    window.PortalShell.Alert.alertDuration = value;
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Failed to parse alert duration, defaulting to 5 seconds.', error);
            }
        }
    }, []);

    return (
        <>
            <div className={classes.mainHeadingArea}>
                <UiText
                    className={classes.mainHeading}
                    variant={FontVariantToken.fontSizeLBold}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_ACCESSIBILITY' })}
                </UiText>
                <UiText
                    color="var(--color-foreground-de-emp)"
                    data-cy="profile-accessibility-settings-description">
                    {translate({ id: 'CLIENT_ACCESSIBILITY_SETTINGS_DESCRIPTION' })}
                </UiText>
            </div>

            <Divider className={classes.divider} />

            <UiStack
                direction="row"
                gap={SpacingToken.XXL}>
                <UiStack
                    direction="column"
                    mt={SpacingToken.XS}
                    gap={SpacingToken.S}>
                    <UiText
                        variant={FontVariantToken.fontSizeMBold}
                        color="var(--color-foreground-de-emp)"
                        role="heading"
                        aria-level={2}>
                        {translate({ id: 'CLIENT_ACCESSIBILITY_SETTINGS_ALERT_TIMING_HEADER' })}
                    </UiText>
                    <UiText color="var(--color-foreground-de-emp)">
                        <FormattedMessage
                            id='CLIENT_ACCESSIBILITY_SETTINGS_ALERT_TIMING_DESCRIPTION'
                            values={{
                                u: (msg: React.ReactNode[]) => <u>
                                    {msg}
                                </u>,
                            }}
                        />
                    </UiText>
                </UiStack>

                <ApDropdown
                    data-cy="alert-duration-dropdown"
                    label={translate({ id: 'CLIENT_SHOW_ALERTS_FOR' })}
                    selectedValue={selectedDuration}
                    onSelectedValueChanged={handleSelectDurationChange}
                    className={classes.dropdown}>
                    {durationDropdownItems}
                </ApDropdown>
            </UiStack>
        </>
    );
};

export default ProfileAccessibilitySettingsComponent;
