import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useModalState,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import FileSaver from 'file-saver';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { useUpdateUserProfilePersona } from '../../../common/hooks/useUpdateUserProfilePersona';
import type {
    IActivateOfflineData,
    IOfflineActivationRequestResponse,
} from '../../../common/interfaces/license.activation';
import { deactivateOffline } from '../../../services/licensing/management/ActivationService';
import {
    isHostModeSelector,
    profile,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import LicenseActivationErrorDialogBody from './LicenseActivationErrorDialogBody';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        stepInstruction: {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 'bold',
            fontSize: '14px',
        },
        stepper: { paddingLeft: '0px' },
        warnContainer: {
            marginTop: '12px',
            display: 'flex',
            alignItems: 'top',
        },
        iconContainer: {
            height: '32px',
            width: '32px',
        },
        warnText: { marginLeft: '10px' },
        warnTextLine: { fontSize: '14px' },
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            fontSize: '32px',
        },
        list: {
            margin: '12px 0px',
            boxShadow: 'unset',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        bullet: { fontSize: '16px' },
    }),
}));

const DeactivateLicenseOfflineComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const accountProfile = useSelector(profile);
    const isHostMode = useSelector(isHostModeSelector);
    const updateUserProfilePersona = useUpdateUserProfilePersona();
    const { state } = useLocation();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const getRoute = useRouteResolver();
    const licenseCode = useMemo(
        () => accountProfile.accountUserDto.licenseCode,
        [ accountProfile.accountUserDto.licenseCode ],
    );
    const [ previousLocation ] = useMemo(() => [ state?.previousLocation ], [ state ]);
    const [ downloadInProgress, setDownloadInProgress ] = useState(false);
    const [ localDeactivated, setLocalDeactivated ] = useState(false);
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();
    const { handleSubmit } = useForm<IActivateOfflineData>({
        mode: 'onChange',
        defaultValues: {
            accountGlobalId: '',
            licenseCode: '',
            haveFile: '',
            file: undefined,
        },
    });

    const {
        open, close,
    } = useModalState(getRoute(previousLocation ?? RouteNames.Licensing));

    const saveActivationRequestFile = useCallback(
        (downloadedActivationRequestData: IOfflineActivationRequestResponse, filename: string) => {
            const downloadedActivationRequest = new Blob([ downloadedActivationRequestData.activationRequest ], { type: 'charset=utf-8' });
            FileSaver.saveAs(downloadedActivationRequest, filename);
        },
        [],
    );

    const downloadDeactivationRequest = useCallback(async () => {
        try {
            if (!downloadInProgress) {
                setDownloadInProgress(true);
                createNotification(translate({ id: 'CLIENT_DOWNLOAD_IN_PROGRESS' }), notificationType.INPROGRESS);
                const activationRequestObject: IOfflineActivationRequestResponse = await deactivateOffline(
                    licenseCode,
                    isHostMode,
                );
                setLocalDeactivated(true);
                saveActivationRequestFile(activationRequestObject, translate({ id: 'CLIENT_DEACTIVATION_REQUEST' }));
            }
        } catch (error) {
            const errorObject = await getErrorObject(error);
            if (errorObject.response?.status === 422) {
                await createDialog({
                    title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                    icon: 'error',
                    unclosable: false,
                    showCancel: false,
                    customDialogContent: LicenseActivationErrorDialogBody,
                    customDialogContentProps: { operationStatus: errorObject.response.data.operationStatus },
                });
            } else {
                setErrorMessage(await getErrorMessage(error));
            }
        } finally {
            setDownloadInProgress(false);
        }
    }, [
        downloadInProgress,
        createNotification,
        translate,
        licenseCode,
        isHostMode,
        saveActivationRequestFile,
        getErrorObject,
        createDialog,
        setErrorMessage,
        getErrorMessage,
    ]);

    const onSubmit = useCallback(async () => {
        if (localDeactivated) {
            createNotification(translate({ id: 'CLIENT_DEACTIVATE_SUCCESSFULLY' }), notificationType.SUCCESS);
            updateUserProfilePersona();
        }
        close();
    }, [ localDeactivated, close, createNotification, translate, updateUserProfilePersona ]);

    const steps = useMemo(() => [
        translate({ id: 'CLIENT_DEACTIVATE_DOWNLOAD_RESPONSE_FILE' }),
        translate({ id: 'CLIENT_DEACTIVATE_YOUR_LICENSE' }),
    ], [ translate ]);

    const stepsContent = useMemo(() => [
        <Button
            key={1}
            disabled={downloadInProgress}
            variant="outlined"
            data-cy="download-activation-request"
            onClick={() => downloadDeactivationRequest()}
        >
            {translate({ id: 'CLIENT_DOWNLOAD' })}
        </Button>,
        <FormattedMessage
            key={2}
            id="CLIENT_GO_TO_ACTIVATION_PORTAL_UPLOAD_FILE"
            values={{
                a: (msg: React.ReactNode[]) =>
                    (
                        <a
                            className={classes.a}
                            href="https://activate.uipath.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {msg}
                        </a>
                    ),
            }}
        />,
    ], [ downloadDeactivationRequest, translate, downloadInProgress, classes ]);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_DEACTIVATE_LICENSE' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => onSubmit(),
            }}
            loading={downloadInProgress}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            color="primary"
                            disabled={localDeactivated}
                            onClick={() => close()}
                            data-cy="cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            data-cy="ok-submit-button">
                            {translate({ id: 'CLIENT_OK' })}
                        </Button>
                    </div>
                }
                isDrawer
            >
                <Stepper
                    orientation="vertical"
                    className={classes.stepper}>
                    {steps.map((label, index) => (
                        <Step
                            key={label}
                            active>
                            <StepLabel>
                                <div className={classes.stepInstruction}>
                                    {label}
                                </div>
                            </StepLabel>
                            <StepContent>
                                <UiText>
                                    {stepsContent[index]}
                                </UiText>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <div className={classes.warnContainer}>
                    <span className={classes.iconContainer}>
                        <WarningIcon className={classes.yellowIcon} />
                    </span>
                    <div className={classes.warnText}>
                        <UiText className={classes.warnTextLine}>
                            {translate({ id: 'CLIENT_DEACTIVATE_OFFLINE_WARN' })}
                        </UiText>
                        <List className={classes.list}>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecordIcon className={classes.bullet} />
                                </ListItemIcon>
                                <ListItemText>
                                    <UiText className={classes.warnTextLine}>
                                        {translate({ id: 'CLIENT_DEACTIVATE_OFFLINE_WARN_L1' })}
                                    </UiText>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecordIcon className={classes.bullet} />
                                </ListItemIcon>
                                <ListItemText>
                                    <UiText className={classes.warnTextLine}>
                                        {translate({ id: 'CLIENT_DEACTIVATE_OFFLINE_WARN_L2' })}
                                    </UiText>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </UiForm>
        </UiDrawer>
    );
};

export default DeactivateLicenseOfflineComponent;
