import React from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate,
    useParams,
} from 'react-router-dom';

import * as RouteNames from '../common/constants/RouteNames';
import { accountLogicalName } from '../store/selectors';

const PortalRedirectComponent = () => {
    const accountNameSelector = useSelector(accountLogicalName);
    const { accountName } = useParams() as { accountName?: string };

    const account = accountName ?? accountNameSelector;

    return account ? <Navigate
        to={RouteNames.PortalPrefix.replace(':accountName', account)}
    /> : null;
};

export default PortalRedirectComponent;
