import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IResetPasswordData } from '../../../common/interfaces/cis/account';
import { changePassword } from '../../../services/identity/AccountService';
import { userGlobalId } from '../../../store/selectors';
import { mapResetPasswordDataToChangePasswordDto } from '../../../util/AccountUtil';
import EditPasswordFormComponent, {
    defaultEditPasswordData,
    useEditPasswordStyles,
} from '../../common/EditPasswordFormComponent';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';

const useStyles = makeStyles(() =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const ResetPasswordComponent: React.FC = () => {
    const createNotification = useUiSnackBar();
    const classes = useStyles();
    const userId = useSelector(userGlobalId);
    const editPasswordClasses = useEditPasswordStyles();
    const { formatMessage: translate } = useIntl();
    const {
        open, close,
    } = useModalState(RouteNames.PrivacyAndSecuritySettings);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(translate({ id: 'CLIENT_RESET_PASSWORD_GENERIC_ERROR' }));
    const methods = useForm<IResetPasswordData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditPasswordData,
            currentPassword: '',
        },
    });
    const {
        handleSubmit, formState: {
            errors, isDirty, isSubmitting,
        }, control,
    } = methods;

    const onSubmit = useCallback(
        async (data: IResetPasswordData) => {
            try {
                await changePassword(mapResetPasswordDataToChangePasswordDto(data, userId));
                createNotification(translate({ id: 'CLIENT_RESET_PASSWORD_SUCCESS' }), notificationType.SUCCESS);
                close(true);
            } catch (error) {
                setShowDrawerError(true);
                const errMessage = (error as any)?.response?.data?.errorMessage;
                if (errMessage) {
                    setErrorMessage(errMessage);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_RESET_PASSWORD_GENERIC_ERROR' }));
                }
            }
        },
        [ close, createNotification, translate, userId ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_CHANGE_PASSWORD' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: errorMessage,
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="reset-password-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <div className={editPasswordClasses.input}>
                    <UiText
                        className={clsx(editPasswordClasses.inputLabel, editPasswordClasses.inputMargin)}
                        id="currentPasswordLabel">
                        {translate({ id: 'CLIENT_CURRENT_PASSWORD' })}
                    </UiText>
                    <Controller
                        control={control}
                        name="currentPassword"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                type="password"
                                autoComplete="new-password"
                                error={!!errors.currentPassword}
                                helperText={
                                    errors.currentPassword?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                }
                                fullWidth
                                InputProps={{ className: 'Tall' }}
                                inputProps={{ 'aria-labelledby': 'currentPasswordLabel' }}
                                data-cy="reset-password-current-password"
                            />
                        )}
                    />
                </div>
                <FormProvider {...methods}>
                    <EditPasswordFormComponent
                        respectPasswordRequirements
                        required />
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default ResetPasswordComponent;
