import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import * as React from 'react';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { NotificationSettingsMode } from './interfaces/notificationSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        selectButton: {
            paddingLeft: Tokens.Padding.PadXxl,
            fontSize: `${Tokens.FontFamily.FontLSize} !important`,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold} !important`,
        },
        inputBase: {
            marginRight: '8px',
            marginBottom: '18px',
            fontWeight: Tokens.FontFamily.FontWeightBold,
        },
        selectIcon: {
            color: theme.palette.semantic.colorIconDefault,
            verticalAlign: 'bottom',
            right: Tokens.Padding.PadXs,
        },
        menuItem: { fontSize: Tokens.FontFamily.FontMSize },
    }),
);
const NotificationSettingSelectionMenu: React.FC<{
    handleActionMenu: any;
}> = ({ handleActionMenu }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ modeResxMap ] = useState(new Map<NotificationSettingsMode, string>([
        [ NotificationSettingsMode.MyNotification, 'CLIENT_NOTIFICATION_SETTING_MODE_MYNOTIFICATION' ],
        [ NotificationSettingsMode.ManageNotification, 'CLIENT_NOTIFICATION_SETTING_MODE_MANAGENOTIFICATION' ],
    ]));

    useEffect(() => {
        const isNSManageMode = sessionStorage.getItem('isNSManageMode');
        const arr = isNSManageMode === 'true' ?
            translate({ id: modeResxMap.get(NotificationSettingsMode.ManageNotification) }) :
            translate({ id: modeResxMap.get(NotificationSettingsMode.MyNotification) });

        setSelected([ arr ]);
    }, [ modeResxMap, translate ]);

    const handleClose = (manageMode: boolean) => (event: any) => {
        handleActionMenu(manageMode);
        sessionStorage.setItem('isNSManageMode', manageMode.toString());
    };

    const [ selected, setSelected ] = useState<string[]>([]);

    const change = useCallback((event: SelectChangeEvent<string[]>) => {
        const menuValue = event.target.value as string;
        setSelected([ menuValue ]);
    }, []);

    return (
        <Select
            value={selected}
            displayEmpty
            disableUnderline
            IconComponent={ExpandMoreIcon}
            classes={{
                select: classes.selectButton,
                icon: classes.selectIcon,
            }}
            className={classes.inputBase}
            onChange={change}
            data-cy="admin-select-menu"
            variant="standard"
        >
            {Object.values(NotificationSettingsMode)
                .map((mode, cellIndex) => (
                    <MenuItem
                        key={translate({ id: modeResxMap.get(mode) })}
                        onClick={handleClose(mode === NotificationSettingsMode.MyNotification ? false : true)}
                        value={translate({ id: modeResxMap.get(mode) })}
                        className={classes.menuItem}
                        disableRipple>
                        {translate({ id: modeResxMap.get(mode) })}
                    </MenuItem>
                ))}
        </Select>
    );
};
export default NotificationSettingSelectionMenu;
