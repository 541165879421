import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    getDisplayName,
    getSortedUserBundleConfigurations,
    useModalState,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { duration } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import type { NavigationType } from 'react-router-dom';
import {
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrchRoleAssignmentForAX } from '../../../common/hooks/useOrchRoleAssignmentForAX';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { userPartitionUrl } from '../../../services/identity/UserPartitionService';
import type { ISubmitUsersAllocations } from '../../../services/licensing/accountant/UserLicenseService';
import {
    deleteUserExplicitAllocations,
    getEditableUserLicense,
    putUserLicenseAllocation,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import { EnableUserLicensingSelector } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IUserLicenseExplicitAllocation } from '../interfaces/license';
import { LicenseAllocationMethod } from '../interfaces/license';
import { LicensingAllocationFormComponent } from './LicensingAllocationFormComponent';
import { sendPendoTrackEventForAKit } from './LicensingHelpers';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        selectLicenses: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '28px',
            marginBottom: '2px',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const EditUserAllocationComponent: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const navType: NavigationType = useNavigationType();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const getRoute = useRouteResolver();
    const { state } = useLocation();
    const {
        showUseOrchRoleAssignmentDialogForAX, isLoadingTenantOrchRoleInfo,
    } = useOrchRoleAssignmentForAX();

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const {
        user, previousLocation,
    } = state;

    const {
        open, close,
    } = useModalState(previousLocation ?? getRoute(RouteNames.Licensing));

    const [ loading, setLoading ] = useState(false);

    const methods = useForm<IUserLicenseExplicitAllocation>({
        mode: 'onSubmit',
        defaultValues: {
            licenseAllocationMethod: LicenseAllocationMethod.EXPLICIT,
            userBundleLicenses: [],
            useExternalLicense: false,
        },
    });

    const {
        handleSubmit, reset, formState: { isDirty },
    } = methods;

    const { mutate } = useSWRConfig();

    const { data: availableUserLicenses } = useSWR(
        user ? {
            url: userLicenseUrl,
            userGlobalId: user.id,
        } : null,
        getEditableUserLicense,
    );

    const validUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);

    const [ allocatedUserBundles, availableUserBundles, useExternalLicense, allocationType, licenseInheritance ] =
        useMemo(
            () => [
                availableUserLicenses?.allocatedUserBundles,
                availableUserLicenses?.availableUserBundles
                    ?.filter(p => p.allocated !== 0 || p.total !== 0)
                    .filter(p => validUserBundleCodes.includes(p.code)),
                availableUserLicenses?.useExternalLicense ?? false,
                availableUserLicenses?.explicitAllocation
                    ? LicenseAllocationMethod.EXPLICIT
                    : LicenseAllocationMethod.GROUP,
                availableUserLicenses?.groupLicenseInheritance,
            ],
            [ availableUserLicenses, validUserBundleCodes ],
        );

    useEffect(() => {
        if (!EnableUserLicensing || !user) {
            console.warn('Feature not enabled or missing data. Closing...');
            navigate('/invalidurl');
        }
    }, [ EnableUserLicensing, navigate, user ]);

    useEffect(() => {
        if (allocatedUserBundles) {
            reset({
                userBundleLicenses: allocatedUserBundles,
                useExternalLicense,
                licenseAllocationMethod: allocationType,
            });
        }
    }, [ allocatedUserBundles, useExternalLicense, allocationType, reset ]);

    const onSubmit = useCallback(
        async (data: IUserLicenseExplicitAllocation) => {
            setLoading(true);

            try {
                if (data.licenseAllocationMethod === LicenseAllocationMethod.GROUP) {
                    await deleteUserExplicitAllocations([ user.id ]);
                } else {
                    const payload: ISubmitUsersAllocations = {
                        userIds: [ user.id ],
                        licenseCodes: data.useExternalLicense ? [] : data.userBundleLicenses,
                        useExternalLicense: data.useExternalLicense,
                    };
                    await putUserLicenseAllocation(payload);
                    sendPendoTrackEventForAKit(allocatedUserBundles, data.userBundleLicenses);
                }
            } catch (error) {
                close();
                return;
            }

            createNotification(
                translate(
                    { id: 'CLIENT_SUCCESS_EDITING_EXPLICIT_ALLOCATIONS' },
                    { 0: getDisplayName(user) },
                ),
                notificationType.SUCCESS
            );
            await mutate(trialServicesSwrKey);
            await mutate((key: any) => key?.url === `${userPartitionUrl}/licenses`);
            setLoading(false);
            close(true);

            if (data.userBundleLicenses.includes('AKIT')) {
                showUseOrchRoleAssignmentDialogForAX();
            }
        },
        [
            user,
            close,
            createNotification,
            translate,
            allocatedUserBundles,
            mutate,
            showUseOrchRoleAssignmentDialogForAX,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_EDIT_EXPLICIT_ALLOCATIONS_FOR_USER' }, { 0: getDisplayName(user) })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
                transitionDuration: navType !== 'POP' ? duration.enteringScreen : 0,
            }}
            loading={!availableUserLicenses || isLoadingTenantOrchRoleInfo}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    <UiText className={classes.selectLicenses}>
                        {translate({ id: 'CLIENT_USER_LICENSE_ALLOCATION' })}
                    </UiText>
                    <LicensingAllocationFormComponent
                        availableUserBundles={availableUserBundles}
                        allocatedUserBundles={allocatedUserBundles}
                        groupLicenseInheritance={licenseInheritance}
                        userId={user?.id}
                        withRadioGroup
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default EditUserAllocationComponent;
