import type { IFieldAttribute } from './attribute';
import type { IDirectoryEntry } from './directory';
import type { IGroup } from './group';

export enum SAMLConditionType {
    Is = 'Is',
    IsNot = 'IsNot',
    Contains = 'Contains',
    NotContains = 'NotContains',
    IsCaseInsensitive = 'IsCaseInsensitive',
    ContainsCaseInsensitive = 'ContainsCaseInsensitive',
}

export interface ISAMLProvisioningCondition {
    ClaimName: string;
    ConditionType: SAMLConditionType;
    Value: string;
}

interface ISAMLProvisioningRule {
    Id?: number;
    Name: string;
    Description: string;
    GroupsToAssign: IDirectoryEntry[] | string[];
    Conditions: ISAMLProvisioningCondition[];
}

export interface ISAMLRule {
    id?: number;
    partitionGlobalId: string;
    name: string;
    description: string;
    enabled: boolean;
    definition: ISAMLProvisioningCondition[] | string;
    assignedGroups?: IGroup[] | string[];
}

export interface ISAMLProvisioningSetting {
    AccountLinkConfirmation: boolean;
    AllowedDomains: string;
    UniqueIdClaimName: string;
    CustomEmailClaimName: string;
    ProvisioningRules?: ISAMLProvisioningRule[];
    AttributeMapper: Record<string, any>;
}

interface ISAMLAdvancedSettings {
    OutboundSigningAlgorithm: string;
    MinimumIncomingSigningAlgorithm: string;
    IdentityProviderWantsAuthenticationRequestsSigned: boolean;
    IdentityProviderOutboundSigningAlgorithm: string;
}

export interface ISamlFormData {
    SingleSignOnServiceUrl: string;
    IdentityProviderEntityId: string;
    SigningCertificateLocation: {
        CertificateText: string;
    };
    AllowUnsolicitedAuthnResponse?: boolean;
    ExternalAuthUserMappingStrategy?: number;
    Saml2BindingType?: string;
    ServiceCertificateUsage?: string;
    IdentityProviderMetadataUrl?: string;
    ServiceProviderEntityId?: string;
    ProvisioningSetting: ISAMLProvisioningSetting;
    AdvancedSettings?: Partial<ISAMLAdvancedSettings>;
    attributes?: IFieldAttribute[];
}
