import { UiSelect } from '@experiences/ui-common';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import useEcommerceDataStorageRegionViewModel from './useEcommerceDataStorageRegionViewModel';

export const EcommerceDataStorageRegion: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const { formatMessage: translate } = useIntl();
    const {
        regions, control, errors,
    } = useEcommerceDataStorageRegionViewModel();

    return (
        <Controller
            control={control}
            rules={{
                required: true,
                validate: p => !!p?.trim(),
            }}
            name="region"
            render={({ field }) => (
                <UiSelect
                    {...field}
                    id="region"
                    name="region"
                    control={control}
                    disabled={disabled}
                    inputLabel={translate({ id: 'CLIENT_DATA_STORAGE_REGION' })}
                    options={regions ?? []}
                    error={!!errors.region}
                    helperText={
                        errors.logicalName?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_DATA_STORAGE_REGION' }) },
                    )
                    }
                    required
                    fullWidth
                    dataCy="ecommerce-form-data-region"
                />

            )}
        />
    );
};
