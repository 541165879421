import { useMemo } from 'react';

export enum Platform {
    Windows = 'win',
    MacOS = 'mac',
    Linux = 'linux',
    Unknown = 'unknown',
}

/**
 * Custom hook to detect the user's platform and return a simplified platform string.
 * Prefers navigator.userAgentData for modern browsers with a fallback to navigator.userAgent.
 */
export const usePlatformHost = (): Platform => {
    const detectedPlatform = useMemo(() => {
        try {
            // Unified platform detection logic
            const platform = (navigator as any).userAgentData?.platform.toLowerCase() || navigator.userAgent.toLowerCase();

            if (platform.includes(Platform.Windows)) {
                return Platform.Windows;
            }
            if (platform.includes(Platform.MacOS)) {
                return Platform.MacOS;
            }
            if (platform.includes(Platform.Linux)) {
                return Platform.Linux;
            }
        } catch (error) {
            // Fallback to unknown platform
        }
        return Platform.Unknown;
    }, []);

    return detectedPlatform;
};
