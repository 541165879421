import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    Log,
    UiPathAuthProvider,
} from '@uipath/auth-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthConfiguration } from './configuration/AuthConfiguration.default';
import useAuthCallback from './hooks/Callback';
import { AuthLogger } from './utils/Logger';

export const PortalAuthenticationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();
    const onSigninCallback = useAuthCallback();

    const EnableAuthLogDebugger = useFeatureFlagValue(Features.EnableAuthLogDebugger.name);

    useEffect(() => {
        if (EnableAuthLogDebugger) {
            Log.setLogger(AuthLogger.getInstance());
            Log.setLevel(Log.DEBUG);
        }
    }, [ EnableAuthLogDebugger ]);

    return (
        <UiPathAuthProvider
            configuration={getAuthConfiguration()}
            onSigninCallback={onSigninCallback}
            onError={(error) => navigate('/portal_/autherror', { state: error })}
        >
            {children}
        </UiPathAuthProvider>
    );
};
