import { Duration } from '../interfaces/notifications';

export const getTimeTick = (filterValue: string) => {
    const showTillDate = new Date();
    switch (filterValue) {
        case Duration.LastHour.toString():
            showTillDate.setHours(showTillDate.getHours() - Duration.LastHour);
            break;
        case Duration.Last24Hour.toString():
            showTillDate.setHours(showTillDate.getHours() - Duration.Last24Hour);
            break;
        case Duration.LastWeek.toString():
            showTillDate.setDate(showTillDate.getDate() - Duration.LastWeek);
            break;
        case Duration.LastMonth.toString():
            showTillDate.setDate(showTillDate.getDate() - Duration.LastMonth);
            break;
    }
    showTillDate.setMinutes(0, 0, 0);
    return Math.floor(showTillDate.getTime() / 1000);
};
