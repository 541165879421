import { UiText } from '@experiences/ui-common';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        themeRadioGroup: {
            display: 'grid',
            gap: Tokens.Padding.PadS,
            gridTemplateRows: '1fr min-content',
        },
        themeRadioImage: {
            marginLeft: `-${Tokens.Spacing.SpacingXl}`,
            borderRadius: '10px',
        },
        themeRadioControl: {
            width: 'min-content',
            alignItems: 'end',
        },
        themeRadioLabel: {
            gridTemplateRows: 'min-content 42px',
            gap: Tokens.Padding.PadM,
            alignItems: 'center',
        },
        selectedThemeImage: {
            outline: `${Tokens.Border.BorderThickM} solid ${theme.palette.semantic.colorPrimary}`,
            outlineOffset: '-2px',
        },
        radio: { color: theme.palette.semantic.colorForegroundDeEmp },
        themeLabel: {
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightMedium,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '16px',
            marginBottom: '10px',
        },
        chosenByOS: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: Tokens.FontFamily.FontSSize,
            marginTop: '-2px',
            marginBottom: '-18px',
        },
    }),
);

interface ThemeRadioComponentProps {
    theme: string;
    value: string;
    disabled: boolean;
    imgSrc: string;
    label: string;
}

const ThemeRadioComponent: FC<ThemeRadioComponentProps> = ({
    theme, value, disabled, imgSrc, label,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const labelName = `theme-${theme}`;
    const selected = value === theme;

    return <Box
        className={classes.themeRadioGroup}>
        <FormControlLabel
            className={classes.themeRadioControl}
            control={<Radio
                className={classes.radio}
                data-cy={`${labelName}-radio`}
                name={labelName}
                value={theme}
                disabled={disabled}
                checked={selected}
                color="primary" />}
            label={
                <Box className={classes.themeRadioLabel}>
                    <img
                        className={clsx({
                            [classes.themeRadioImage]: true,
                            [classes.selectedThemeImage]: selected,
                        })}
                        src={imgSrc}
                        alt="" />
                    <UiText className={classes.themeLabel}>
                        {label}
                    </UiText>
                    { selected && disabled && (
                        <UiText className={classes.chosenByOS}>
                            {translate({ id: 'CLIENT_CHOSEN_AUTOMATICALLY_BY_YOUR_OS' })}
                        </UiText>
                    )}
                </Box>
            }
        />
    </Box>;
};

export default ThemeRadioComponent;
