import { Entity } from '@experiences/interfaces';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApLink,
    ApTooltip,
    PortalCustomIcon,
} from '@uipath/portal-shell-react';
import intersection from 'lodash/intersection';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import urljoin from 'url-join';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IGroup } from '../../common/interfaces/cis/group';
import type { IGroupAllocations } from '../../common/interfaces/licenses';
import {
    deleteGroups,
    getGroups,
    getGroupsWithLicenses,
    groupUrl,
} from '../../services/identity/GroupService';
import {
    accountGlobalId,
    accountLogicalName,
    EnableUserLicensingSelector,
    groupIdsForUser,
    isAdminSelector,
} from '../../store/selectors';
import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import { ApDataGridMoreRowActionsComponent } from '../common/ApDataGrid/ApDataGridMoreRowActionsComponent';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';

type IGroupWithLicenses = IGroup & Partial<IGroupAllocations>;

const GroupsPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const currentGroupIds = useSelector(groupIdsForUser);

    const createDialog = useShowDialog();

    const dataUrl = useMemo(() => {
        const baseUrl = EnableUserLicensing && isAdmin ? `${groupUrl}/licenses` : `${groupUrl}`;
        return `${baseUrl}?partitionGlobalId=${partitionGlobalId}`;
    }, [ EnableUserLicensing, isAdmin, partitionGlobalId ]);

    const {
        data: groups, isLoading: loading, mutate,
    } = useSWR(
        {
            url: dataUrl,
            partitionGlobalId,
        },
        EnableUserLicensing && isAdmin ? getGroupsWithLicenses : getGroups,
    );

    const openDeleteDialog = useCallback(
        async (rows: IGroupWithLicenses[] | IGroupWithLicenses) => {
            const groupsToDelete = Array.isArray(rows) ? rows.map(row => row) : [ rows ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_GROUP' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_GROUPS' },
                    {
                        0: groupsToDelete.length === 1
                            ? decodeSanitizedHtml(groupsToDelete[0].name)
                            : translate({ id: 'CLIENT_SELECTED_GROUPS_TEXT' }),
                    },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_GROUPS_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const groupIDs = groupsToDelete.map(group => group.id);
                const includesCurrentUser = intersection(currentGroupIds, groupIDs).length > 0;
                await deleteGroups(partitionGlobalId, { groupIDs }, includesCurrentUser);
                if (groupsToDelete.length === 1) {
                    createNotification(translate({ id: 'CLIENT_GROUP_DELETED' },
                        { 0: decodeSanitizedHtml(groupsToDelete[0].name) }), notificationType.SUCCESS);
                } else {
                    createNotification(translate({ id: 'CLIENT_GROUPS_DELETED' }), notificationType.SUCCESS);
                }
                mutate();
            }
        },
        [ createDialog, translate, currentGroupIds, partitionGlobalId, mutate, createNotification ],
    );

    return (
        <ApDataGridWrapper<IGroupWithLicenses>
            data={groups ?? []}
            unpaginated
            initialFilters={{
                sort: [
                    {
                        field: 'type',
                        direction: 'asc',
                        title: translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' }),
                    },
                ],
            }}
            loading={loading}
            disableSelectionByEntry={(entry: IGroupWithLicenses) => entry?.type === 0 ? translate({ id: 'CLIENT_DISABLED' }) : null}
            refresh={mutate}
            dataCy="ap-data-grid"
        >
            <ApDataGridHeader<IGroupWithLicenses> search>
                <ApDataGridHeaderButton<IGroupWithLicenses>
                    id='addGroups'
                    key='addGroups'
                    type='main'
                    buttonType='mat-flat-button'
                    color='primary'
                    text={translate({ id: 'CLIENT_ADD_LOCAL_GROUP' })}
                    label={translate({ id: 'CLIENT_ADD_LOCAL_GROUP' })}
                    onClick={() => navigate(`${getRoute(RouteNames.Groups)}/add`)}
                    dataCy='add-groups-button'
                    visible={isAdmin}
                />
                <ApDataGridHeaderButton<IGroupWithLicenses>
                    id='delete'
                    key='delete'
                    type='action'
                    buttonType='mat-flat-button'
                    visible={isAdmin}
                    color="warn"
                    text={translate({ id: 'CLIENT_DELETE' })}
                    label={translate({ id: 'CLIENT_DELETE' })}
                    onClick={(rows?: IGroupWithLicenses[]) => openDeleteDialog(rows ?? [])}
                    dataCy='delete-groups-button'
                />
            </ApDataGridHeader>

            <ApDataGridColumn<IGroupWithLicenses>
                property="displayName"
                title={translate({ id: 'CLIENT_NAME' })}
                width={EnableUserLicensing && isAdmin ? 30 : 100}
                sortable
                searchable
                render={row => (
                    <UiStack
                        direction="row"
                        gap={SpacingToken.Micro}
                        pb={2}
                        pt={2}
                        align="center">
                        <ApTooltip content={translate({ id: 'CLIENT_LOCAL_GROUP' })}>
                            <PortalCustomIcon
                                name='local_group'
                                aria-label={translate({ id: 'CLIENT_LOCAL_GROUP' })} />
                        </ApTooltip>
                        <ApLink
                            data-cy="name-link"
                            href={urljoin(origin, accountName, 'portal_', routePaths.adminIdentitiesProfileMembers.replace(':id', row.id).replace(':entity', Entity.GROUPS))}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(routePaths.adminIdentitiesProfileMembers.replace(':id', row.id).replace(':entity', Entity.GROUPS));
                            }}>
                            <UiText variant={FontVariantToken.fontSizeM}>
                                {row.name}
                            </UiText>
                        </ApLink>
                    </UiStack>
                )}
            />

            <ApDataGridColumn<IGroupWithLicenses>
                property="type"
                sort="asc"
                title={translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' })}
                sortable
                render={row => <>
                    {translate({ id: row.type === 0 ? 'CLIENT_BUILT_IN' : 'CLIENT_CUSTOM' })}
                </>}
            />

            { isAdmin && (
                <ApDataGridRowActions>
                    <ApDataGridRowButton<IGroupWithLicenses>
                        id="more-actions"
                        label={translate({ id: 'CLIENT_MORE_ACTIONS' })}
                        render={row => (
                            <ApDataGridMoreRowActionsComponent<IGroupWithLicenses>
                                row={row}
                                actions={[
                                    {
                                        id: 'show-details',
                                        label: translate({ id: 'CLIENT_SHOW_DETAILS' }),
                                        click: (r) => navigate(routePaths.adminIdentitiesProfileMembers.replace(':id', r.id).replace(':entity', Entity.GROUPS)),
                                        type: ButtonType.Button,
                                        actionType: GridActionType.Row,
                                    },
                                    {
                                        id: 'delete',
                                        label: translate({ id: 'CLIENT_DELETE' }),
                                        click: openDeleteDialog,
                                        disable: (r) => r?.type === 0,
                                        type: ButtonType.Button,
                                        actionType: GridActionType.Row,
                                    },
                                ]}
                            />
                        )} />
                </ApDataGridRowActions>
            )}

            <ApDataGridFooter
                pageSizes={[ 5, 10, 25, 50 ]}
            />

        </ApDataGridWrapper>
    );
};

export default GroupsPageComponent;
