import { UiText } from '@experiences/ui-common';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import type { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { TimeView } from '@mui/x-date-pickers/models';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import type { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApButton } from '@uipath/portal-shell-react';
// eslint-disable-next-line no-restricted-imports
import type { Moment } from 'moment';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import * as React from 'react';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import {
    getQuickFilter,
    quickFilterLabels,
    QuickFilters,
} from './UiDatePickerUtil';

const useStyles = makeStyles((theme) => ({
    popper: {
        display: 'flex',
        zIndex: 100,
    },
    paper: {
        display: 'flex',
        background: theme.palette.semantic.colorBackgroundRaised,
    },
    quickFilters: {
        display: 'flex',
        width: '150px',
    },
    divider: {
        width: '1px',
        height: 'inherit',
    },
    dash: { margin: '30px 30px 0px 30px' },
    dateTimeContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '8px',
    },
    dateTime: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.semantic.colorBorder}`,
    },
    calendar: {
        display: 'flex',
        flex: 1.7,
    },
    clock: {
        display: 'flex',
        flex: 1,
        marginRight: '8px',
        borderBottom: 'none',
        borderLeft: `1px solid ${theme.palette.semantic.colorBorder}`,
        '& .MuiMultiSectionDigitalClockSection-root': {
            maxHeight: '336px',
            borderRadius: 'unset',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '12px 12px',
    },
}));

interface CustomPickerDayProps extends PickersDayProps<Moment> {
    isSelected?: boolean;
    isHovered?: boolean;
}

const CustomPickersDay = styled(PickersDay,
    { shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered' })<CustomPickerDayProps>
(({
    theme, isSelected, isHovered,
}) => ({
    borderRadius: '50%',
    padding: '0 2px',
    width: '40px',
    height: '40px',
    ...(isHovered && {
        borderRadius: 0,
        backgroundColor: theme.palette.semantic.colorBackgroundSelected,
        '&:hover, &:focus': {
            color: theme.palette.semantic.colorForegroundInverse,
            backgroundColor: theme.palette.semantic.colorPrimary,
        },
    }),
    ...(isSelected && {
        borderRadius: '50%',
        backgroundColor: theme.palette.semantic.colorPrimary,
        color: theme.palette.semantic.colorForegroundInverse,
        '&:hover, &:focus': { backgroundColor: theme.palette.semantic.colorPrimary },
    }),

})) as React.ComponentType<CustomPickerDayProps>;

const CalendarDay = (props: PickersDayProps<Moment> & { highlightedDays?: Moment[] }) => {
    const {
        highlightedDays, day, ...rest
    } = props;

    const isSelected = highlightedDays?.some(d => d.isSame(day, 'dates') && d.isSame(day, 'months'));
    const isHovered = day.isAfter(highlightedDays?.[0]) && day.isBefore(highlightedDays?.[1]);

    return <CustomPickersDay
        {...rest}
        selected={false}
        isSelected={isSelected}
        isHovered={isHovered}
        day={day} />;
};

export interface DateTimeRange {
    from?: Date;
    to?: Date;
}

interface UiDatePickerProps extends PopperProps {
    calendar?: DateTimePickerProps<any>;
    value: DateTimeRange;
    setValue: (value: DateTimeRange) => void;
    onClose: () => void;
}

export const UiDatePicker = ({
    onClose, calendar, value, setValue, ...rest
}: UiDatePickerProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ isFromDateActive, setIsFromDateActive ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ quickFilters, setQuickFilters ] = useState(QuickFilters.None);
    const [ fromDate, setFromDate ] = useState<Moment | undefined>(moment(value.from));
    const [ toDate, setToDate ] = useState<Moment | undefined>(moment(value.to));

    const selectedDays = [ fromDate, toDate ];
    const selectedTime = isFromDateActive ? fromDate : toDate;

    const onCalendarDateChange = useCallback((date: Moment) => {
        const callback: React.SetStateAction<Moment | undefined> = prev => moment(prev?.set({
            date: date.date(),
            month: date.month(),
            year: date.year(),
        }));

        isFromDateActive ? setFromDate(callback) : setToDate(callback);

        setQuickFilters(QuickFilters.None);
    }, [ isFromDateActive ]);

    const onTimePickerChange = useCallback((time: Moment, view?: TimeView | 'meridiem') => {
        let callback: React.SetStateAction<Moment | undefined> = (_: Moment | undefined) => _;

        switch (view) {
            case 'hours':
                callback = prev => moment(prev?.set('hour', time.hour()));
                break;
            case 'minutes':
                callback = prev => moment(prev?.set('minute', time.minute()));
                break;
            case 'meridiem' :
                callback = _ => time;
                break;
            default:
                // do nothing
        }

        isFromDateActive ? setFromDate(callback) : setToDate(callback);
    }, [ isFromDateActive ]);

    const onQuickFilterChange = useCallback((filter: string) => {
        setFromDate(moment().subtract(Number(filter), 'days'));
        setToDate(moment());
        setQuickFilters(filter as any);
    }, [ ]);

    useEffect(() => {
        setError(fromDate?.isAfter(toDate) ?? false);
    }, [ fromDate, toDate ]);

    useEffect(() => {
        // set quick filter if the selected date range matches the filter date range
        const quickFilter = getQuickFilter(fromDate?.toDate(), toDate?.toDate());

        if (quickFilter) {
            setQuickFilters(quickFilter);
        }
    }, [ fromDate, toDate ]);

    return <LocalizationProvider dateAdapter={AdapterMoment}>
        <Popper
            {...rest}
            className={classes.popper}>
            <ClickAwayListener onClickAway={() => onClose()}>
                <Paper className={classes.paper}>
                    <div className={classes.quickFilters}>
                        <List style={{ width: 'inherit' }}>
                            {Object.entries(quickFilterLabels)
                                .filter(([ , v ]) => !!v)
                                .map(([ k, v ], i) =>
                                    <ListItem
                                        key={i}
                                        disablePadding>
                                        <ListItemButton
                                            selected={quickFilters.toString() === k}
                                            onClick={() => onQuickFilterChange(k)}>
                                            <ListItemText primary={translate({ id: v })} />
                                        </ListItemButton>
                                    </ListItem>)}
                        </List>
                    </div>
                    <Divider className={classes.divider} />
                    <div>
                        <div className={classes.dateTimeContainer}>
                            <DateTimePicker
                                {...calendar}
                                label={translate({ id: 'CLIENT_FROM_CAPITAL' })}
                                open={false}
                                disableOpenPicker
                                value={moment(fromDate)}
                                onChange={(v) => {
                                    setQuickFilters(QuickFilters.None);
                                    setFromDate(v);
                                }}
                                slotProps={{
                                    textField: {
                                        focused: isFromDateActive,
                                        onClick: () => setIsFromDateActive(true),
                                    },
                                }}
                            />
                            <UiText className={classes.dash}>
                                –
                            </UiText>
                            <DateTimePicker
                                {...calendar}
                                label={translate({ id: 'CLIENT_TO_CAPITAL' })}
                                open={false}
                                disableOpenPicker
                                value={moment(toDate)}
                                onChange={(v) => {
                                    setQuickFilters(QuickFilters.None);
                                    setToDate(v);
                                }}
                                slotProps={{
                                    textField: {
                                        focused: !isFromDateActive,
                                        onClick: () => {
                                            setIsFromDateActive(false);
                                        },
                                    },
                                }} />

                        </div>
                        <div className={classes.dateTime}>
                            <DateCalendar
                                className={classes.calendar}
                                disableFuture={calendar?.disableFuture}
                                minDate={calendar?.minDate}
                                maxDate={calendar?.maxDate}
                                slots={{ day: CalendarDay }}
                                slotProps={{ day: { highlightedDays: selectedDays } as any }}
                                onChange={(date) => onCalendarDateChange(date)}
                            />
                            <MultiSectionDigitalClock
                                className={classes.clock}
                                value={selectedTime}
                                onChange={(time, _, view) => onTimePickerChange(time, view)} />
                        </div>
                        <div className={classes.actions}>
                            {error && <UiText
                                variant={FontVariantToken.fontSizeSBold}
                                color={theme.palette.semantic.colorErrorText}
                                style={{ marginRight: '8px' }}>
                                {translate({ id: 'CLIENT_FROM_EXCEEDS_TO_DATE' })}
                            </UiText>}
                            <ApButton
                                label={translate({ id: 'CLIENT_APPLY' })}
                                disabled={!!error}
                                onClick={() => {
                                    setValue({
                                        from: fromDate?.toDate(),
                                        to: toDate?.toDate(),
                                    });
                                    onClose();
                                }} />
                        </div>
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popper>
    </LocalizationProvider>;
};
