import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { UiRouteObject } from '@experiences/util';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { RouteObject } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import HomePageComponent from '../../component/home/HomePageComponent';
import NotificationPageComponent from '../../component/notifications/NotificationPageComponent';
import AddUserGroupModal from '../../component/notificationSettings/Admin/AddUserGroupModal';
import ProfilePageComponent from '../../component/profile/ProfilePageComponent';
import CreatePersonalAccessTokenDrawerComponent from '../../component/profile/subcomponents/CreatePersonalAccessTokenDrawerComponent';
import ResourceCenter from '../../component/resourceCenter/ResourceCenter.k8s';
import ResetPasswordComponent from '../../component/users/subcomponents/ResetPasswordComponent';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';
import { recursiveRouteReplace } from './routeHelpers';
import useHostPrivateShellRoutes from './useHostPrivateShellRoutes.default';
import useOrgAdminPrivateShellRoutes from './useOrgAdminPrivateShellRoutes.default';

const usePrivateShellRoutes = () => {
    const EnableNotificationSettings = useFeatureFlagValue(Features.EnableNotificationSettings.name);
    const EnablePrivacyAndSecuritySettings = !useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);
    const EnableProfileNavigationSettings = useFeatureFlagValue(Features.EnableProfileNavigationSettings.name);
    const EnableThemeSettings = useFeatureFlagValue(Features.EnableHighContrastLightTheme.name);

    const isAdmin = useSelector(isAdminSelector);
    const isHostMode = useSelector(isHostModeSelector);
    const orgAdminPrivateShellRoutes = useOrgAdminPrivateShellRoutes();
    const hostPrivateShellRoutes = useHostPrivateShellRoutes();

    return useMemo<RouteObject[]>(() => {
        const nestedRoutes = [
            ...(isAdmin && !isHostMode ? orgAdminPrivateShellRoutes : []),
            ...(isHostMode
                ? hostPrivateShellRoutes
                : [
                    {
                        path: RouteNames.Home,
                        element: <HomePageComponent />,
                    },
                    {
                        path: RouteNames.ResourceCenter,
                        element: <ResourceCenter />,
                    },
                ]),
            {
                path: RouteNames.Profile,
                element: <ProfilePageComponent />,
            },
            {
                path: RouteNames.ThemeSettings,
                element:
                CheckGuard(EnableThemeSettings,
                    <ProfilePageComponent />
                ),
            },
            {
                path: RouteNames.NavigationSettings,
                element:
                CheckGuard(EnableProfileNavigationSettings && !isHostMode,
                    <ProfilePageComponent />
                ),
            },
            {
                path: RouteNames.PrivacyAndSecuritySettings,
                element:
                CheckGuard(EnablePrivacyAndSecuritySettings, <ProfilePageComponent />),
                children: [
                    {
                        path: RouteNames.ChangePassword,
                        element: <ResetPasswordComponent />,
                    },
                ],
            },
            {
                path: RouteNames.NotificationSettings,
                element:
                CheckGuard(EnableNotificationSettings && !isHostMode, <ProfilePageComponent />),
                children: [
                    {
                        path: RouteNames.AddUserGroupProfile,
                        element: <AddUserGroupModal />,
                    },
                    { path: RouteNames.AddNotificationSettingsProfile },
                ],
            },
            {
                path: RouteNames.Notifications,
                element: <NotificationPageComponent />,
            },
            {
                path: RouteNames.PersonalAccessToken,
                element:
                CheckGuard(EnableReferenceTokens && !isHostMode, <ProfilePageComponent />),
                children: [
                    {
                        path: RouteNames.AddPersonalAccessToken,
                        element: <CreatePersonalAccessTokenDrawerComponent />,
                    },
                ],
            },
            {
                path: '*', // Fallback for invalid urls
                element: <InvalidUrl />,
            },
        ] satisfies UiRouteObject[];

        const replacedRoutes = recursiveRouteReplace(nestedRoutes, RouteNames.PortalPrefix);
        return replacedRoutes as RouteObject[];
    }, [
        EnableNotificationSettings,
        EnablePrivacyAndSecuritySettings,
        EnableThemeSettings,
        EnableProfileNavigationSettings,
        EnableReferenceTokens,
        hostPrivateShellRoutes,
        isAdmin,
        isHostMode,
        orgAdminPrivateShellRoutes,
    ]);
};

export default usePrivateShellRoutes;
