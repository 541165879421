import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
} from '../../services/identity/AuthenticationSettingService';
import { useCheckAuthenticationSetting } from './useCheckAuthenticationSetting';

export const useCheckAADEnabled = () => {
    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const authenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.AAD];
    return authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.AAD;
};
