import type { IScope } from '@experiences/interfaces';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useGetTranslatedScopeDescription = () => {
    const { formatMessage: translate } = useIntl();

    return useCallback((scope: IScope) => {
        if (scope.name.includes('Du')) {
            const service = scope.displayName?.split('.')[1];
            return translate({ id: 'CLIENT_PRODUCT_ACCESS_TO_DU' }, { service });
        }

        const first = scope.description ? scope.description.split(' ')[0] : '';
        const last = scope.description ? scope.description.split(' ').pop() : '';
        const access = scope.name.split('.')[2]?.toUpperCase();
        return [ first, translate({ id: `CLIENT_${access ? access : 'READ_WRITE'}_ACCESS` }), last ].join(' ');
    }, [ translate ]);
};
