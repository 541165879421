import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { getEnvVariableValue } from '@experiences/util';

import type { sidebarData } from '../../common/interfaces/sidebar';

function Sidebar(): sidebarData {
    const isGov = getFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const docLink = isGov
        ? 'https://docs.uipath.com/automation-cloud-public-sector/automation-cloud-public-sector/latest'
        : `https://docs.uipath.com/automation-suite/automation-suite/${getEnvVariableValue('DOCUMENTATION_VERSION')}`;
    return ({
        'sidebarContent': {
            'downloadStudioPosition': 0,
            'sidebarLinkContainersCollection': {
                'items': [
                    {
                        'title': 'RESOURCES',
                        'sidebarLinksCollection': {
                            'items': [
                                {
                                    'titleText': 'QUESTIONS',
                                    'linkHref': docLink,
                                    'linkText': 'VISIT',
                                }, {
                                    'titleText': 'AUTOMATION',
                                    'linkHref': 'https://forum.uipath.com',
                                    'linkText': 'COMMUNITY',
                                }, {
                                    'titleText': 'TECHNICAL',
                                    'linkHref': 'https://www.uipath.com/support',
                                    'linkText': 'SUPPORT',
                                },
                            ],
                        },
                    },
                ],
            },
        },
    });
}

export const SidebarStatic: sidebarData = Sidebar();
