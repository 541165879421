import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import { ExternalProvidersAADPage } from '../authsettings/subcomponents/ExternalProvidersAADPage';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import SecuritySettingsSAMLForm from './subcomponents/SecuritySettingsSAMLForm';

const ConfigureSSOPage: React.FC<{ type: 'aad' | 'saml2' }> = ({ type }) => {
    const EnableSAMLUXRefactor = useFeatureFlagValue(Features.EnableSAMLUXRefactor.name);

    const provider = type === 'saml2' ? 'saml' : type;

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            maxWidth={EnableSAMLUXRefactor ? '1140px' : undefined}
            position='center'
            tenantShellHidden={EnableSAMLUXRefactor}
            enableBackground={EnableSAMLUXRefactor}
            data-cy={`security-settings-configure-${provider}-panel`}
        >
            {provider === 'aad'
                ? <ExternalProvidersAADPage />
                : <SecuritySettingsSAMLForm />}
        </UiPageContainer>
    );
};

export const ConfigureSSOPageWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const { type } = useParams() as { type: 'aad' | 'saml2' };

    const organizationName = useOrganizationName();
    const provider = type === 'saml2' ? 'saml' : type;

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 2,
            link: !process.buildConfigs.isOnPrem ? RouteNames.SecuritySettingsConfigure : RouteNames.AuthSettings,
            name: translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_${provider.toUpperCase()}_TITLE` }),
        },
    ], [ organizationName, provider, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <ConfigureSSOPage type={type} />
    </BreadcrumbProvider>;
};

export default ConfigureSSOPage;
