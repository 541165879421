import type { TenantTagsTabType } from '@experiences/interfaces';
import { TenantTagsTab } from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import TenantTagsLabelsComponent from './labels/TenantTagsLabelsComponent';
import TenantTagsPropertiesComponent from './properties/TenantTagsPropertiesComponent';
import useTenantTagsTabsViewModel from './TenantTagsTabsViewModel';

const TabPanel: React.FC<{ value: TenantTagsTabType }> = ({ value }) => {
    switch (value) {
        case TenantTagsTab.LABELS:
            return <TenantTagsLabelsComponent />;
        case TenantTagsTab.PROPERTIES:
            return <TenantTagsPropertiesComponent />;
    }
    return null;
};

const TenantTagsTabs: React.FC<{ type: TenantTagsTabType }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const {
        tenantId, tabValueState: [ tabValue, setTabValue ],
    } = useTenantTagsTabsViewModel({ type });

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={(_: any, newValue: TenantTagsTabType) => setTabValue(newValue)}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={
                    { width: '100%' }
                }>
                <Tab
                    value={TenantTagsTab.LABELS}
                    label={translate({ id: 'CLIENT_LABELS' })}
                    onClick={() => navigate(getRoute(process.buildConfigs.showForMSI
                        ? RouteNames.TenantTagsLabelsMsi
                        : RouteNames.TenantTagsLabels.replace(':tenantId', tenantId)))}
                    data-cy="labels-tab"
                    className="default"
                />
                <Tab
                    value={TenantTagsTab.PROPERTIES}
                    label={translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' })}
                    onClick={() => navigate(getRoute(process.buildConfigs.showForMSI
                        ? RouteNames.TenantTagsPropertiesMsi
                        : RouteNames.TenantTagsProperties.replace(':tenantId', tenantId)))}
                    data-cy="properties-tab"
                    className="default"
                />
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default TenantTagsTabs;
