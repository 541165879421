import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PaymentElement } from '@stripe/react-stripe-js';
import type { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountLogicalName } from '../helpers/EcommerceSelectors';
import useEcommercePaymentElementFormViewModel from '../helpers/useEcommercePaymentElementFormViewModel';
import type {
    IBusinessInfoPayload,
    ISubscriptionForm,
} from '../interfaces/ecommerce';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        sectionTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
            marginTop: '36px',
        },
        separator: {
            width: '100%',
            height: '1px',
            backgroundColor: theme.palette.semantic.colorBorderDeEmp,
            marginTop: '36px',
        },
        payNowButton: { width: '100%' },
        paymentElement: {
            marginTop: '24px',
            height: '800px',
            marginBottom: '100px',
        },
        nonTaxableEntity: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            margin: '8px 0px',
        },
    }),
}));

export const EcommercePaymentElementForm: React.FC<{
    existingMarketoData: IBusinessInfoPayload | undefined;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setPaymentElementFormCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    setTermsAndConditionsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
    termsAndConditionsAccepted: boolean;
    setCheckoutFormRef: any;
}> = ({
    existingMarketoData,
    setLoading,
    setPaymentElementFormCompleted,
    setTermsAndConditionsAccepted,
    termsAndConditionsAccepted,
    setCheckoutFormRef,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const currentAccountName = useSelector(accountLogicalName);

    useEffect(() => {
        const termsAndConditionsCheckbox = document.querySelector('[name="termsAndConditionsAccepted"]') as HTMLInputElement | null;
        if (termsAndConditionsCheckbox) {
            setTermsAndConditionsAccepted(termsAndConditionsCheckbox.checked);
            termsAndConditionsCheckbox.addEventListener('change', function() {
                if (this.checked !== termsAndConditionsAccepted) {
                    setTermsAndConditionsAccepted(this.checked);
                }
            });
        }
    }, [ termsAndConditionsAccepted, setTermsAndConditionsAccepted ]);
    const { watch } = useFormContext<ISubscriptionForm>();
    const {
        city, country, state, businessEmail, zipcode, firstName, lastName, logicalName,
    } = watch();

    const paymentElementsChangeHandler = useCallback((event: StripePaymentElementChangeEvent) => {
        setPaymentElementFormCompleted(event.complete);
    }, [ setPaymentElementFormCompleted ]);

    const { paymentElementHandleSubmit } = useEcommercePaymentElementFormViewModel(
        currentAccountName ?? logicalName, setLoading, existingMarketoData);

    return (
        <form
            onSubmit={paymentElementHandleSubmit}
            style={{ display: 'contents' }}
            ref={ref => setCheckoutFormRef(ref)}
        >
            <div>
                <div className={classes.separator} />
                <UiText className={classes.sectionTitle}>
                    {translate({ id: 'CLIENT_STEP_2_PAYMENT_INFORMATION' })}
                </UiText>

                <div className={classes.paymentElement}>
                    <PaymentElement
                        onChange={paymentElementsChangeHandler}
                        options={{
                            defaultValues: {
                                billingDetails: {
                                    email: businessEmail,
                                    name: `${firstName} ${lastName}`,
                                    address: {
                                        country,
                                        city,
                                        state,
                                        postal_code: zipcode ?? '',
                                    },
                                },
                            },
                        }} />
                </div>
            </div>
        </form>
    );
};

export default EcommercePaymentElementForm;
