import {
    UiCopyButton,
    UiText,
} from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            marginTop: '20px',
            padding: '8px',
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            borderRadius: '3px',
        },
        text: {
            flex: 'auto',
            minWidth: 0,
            textAlign: 'left',
            maxWidth: '80%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        },
    }),

);

const DisplayAccessToken: FC<{ token: string }> = ({ token }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return (
        <div>
            <UiText>
                {translate({ id: 'CLIENT_TOKEN_GENERATED_MESSAGE' })}
            </UiText>
            <div className={classes.container}>
                <UiText
                    className={classes.text}
                    data-cy="generated-token">
                    {token}
                </UiText>
                <UiCopyButton
                    textToCopy={token}
                    data-cy="token-copy"
                />
            </div>
        </div>
    );
};

export default DisplayAccessToken;
