import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const StatsigUserCookieKey = 'UiPathStatsigUserId';

export function getStatsigUserId() {
    const userId = Cookies.get(StatsigUserCookieKey);

    if (userId) {
        return userId;
    }

    const id = uuidv4();

    Cookies.set(StatsigUserCookieKey, id, {
        domain: '.uipath.com',
        expires: 365,
        secure: true,
    });

    return id;
}
