import {
    useCallback,
    useMemo,
} from 'react';
import type { UseFormReturn } from 'react-hook-form';

import type {
    ICustomPlanForm,
    IPackage,
} from '../interfaces/ecommerce';

const useEcommerceProductQuantityViewModel = (
    productCode: string,
    customSkuPackage: IPackage,
    useFormMethods: UseFormReturn<ICustomPlanForm>,
    minQuantity?: number,
) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useFormMethods;

    const productQuantity = useMemo(() => customSkuPackage.productQuantities.find(pq => pq.code === productCode),
        [ customSkuPackage.productQuantities, productCode ]);

    const preventOutOfBoundValues = useCallback(
        (event: any) => {
            const { value } = event.target;
            const minimumValue = minQuantity ?? 0;

            if (!value) {
                event.target.value = productQuantity!.minQuantity;
            } else if (value < 0) {
                event.target.value = 0;
            } else if (value > productQuantity!.maxQuantity!) {
                event.target.value = productQuantity!.maxQuantity;
            } else if (value < minimumValue) {
                event.target.value = minimumValue;
            } else {
                event.target.value = Number(value);
            }
        },
        [ productQuantity, minQuantity ],
    );
    const preventAlphaNumericValues = useCallback(
        (evt: any) => {
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            }
        },
        [ ],
    );
    const onChangeCustomSkuPackage = useCallback(
        async (data: ICustomPlanForm) => {
            customSkuPackage?.productQuantities
                .forEach(pq => (pq.quantity = data.productQuantities[pq.code] ?? 0));
        },
        [ customSkuPackage ],
    );

    const preventEmptyValues = useCallback(
        (name: any, event: any) => {
            if (!event.target.value) {
                setValue(name, productQuantity?.minQuantity ?? 0, { shouldValidate: true });
                handleSubmit(onChangeCustomSkuPackage)();
            }
        },
        [ setValue, handleSubmit, onChangeCustomSkuPackage, productQuantity ],
    );

    return {
        control,
        errors,
        setValue,
        handleSubmit,
        productQuantity,
        preventOutOfBoundValues,
        preventAlphaNumericValues,
        onChangeCustomSkuPackage,
        preventEmptyValues,
    };
};

export default useEcommerceProductQuantityViewModel;
