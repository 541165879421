import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiProgressButton,
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import {
    ExternalAuthenticationScheme,
    ExternalUserMappingStrategy,
    externalUserMappingStrategyOptions,
    Saml2BindingType,
    samlBindingTypeOptions,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type {
    ISaml2ProviderSettings,
    ISamlConfigData,
} from '../../../common/interfaces/externalIdentity';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultEditIdentityProviderData,
    mapExternalIdentityPayloadToErrorCode,
    mapExternalIdentityPayloadToSamlConfigData,
    mapSamlConfigDataToExternalIdentityPayload,
} from '../../../util/ExternalIdentityProviderUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import EditCertificateLocationsFormComponent, { defaultEditCertificateLocationsData } from './EditCertificateLocationsFormComponent.default';
import EditIdentityProviderFormComponent from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        section: { marginTop: '20px' },
        selectInput: { marginTop: '12px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const ConfigureSamlComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);

    const { close } = useModalState(RouteNames.SecuritySettings);

    const classes = useStyles();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorDialog = useCentralErrorSetter();
    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const methods = useForm<ISamlConfigData>({
        mode: 'onChange',
        defaultValues: {
            ...defaultEditIdentityProviderData,
            ...defaultEditCertificateLocationsData,
            serviceProviderEntityId: `${window.location.origin}${process.buildConfigs.identityBaseRoute}`,
            identityProviderEntityId: '',
            singleSignOnServiceUrl: '',
            allowUnsolicitedAuthnResponse: false,
            returnUrl: '',
            externalAuthUserMappingStrategy: ExternalUserMappingStrategy.ByUserEmail,
            saml2BindingType: Saml2BindingType.HttpRedirect,
            externalAuthUserIdentifierClaim: '',
        },
    });

    const {
        handleSubmit, reset, control, watch, formState: {
            errors, isDirty, isSubmitting,
        },
    } = methods;

    const fetchedExternalIdentity = useExternalIdentity(ExternalAuthenticationScheme.Saml2);
    const externalAuthUserMappingStrategy = watch('externalAuthUserMappingStrategy');

    const { errorCode } = useMemo(() => {
        if (!fetchedExternalIdentity) {
            return { errorCode: undefined };
        }

        const { settings } = fetchedExternalIdentity;
        const parsedSettings = settings ? (JSON.parse(settings) as ISaml2ProviderSettings) : undefined;
        return { errorCode: parsedSettings?.ErrorCode };
    }, [ fetchedExternalIdentity ]);

    const onSubmit = useCallback(
        async (data: ISamlConfigData) => {
            const payload = mapSamlConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            try {
                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_IDP_SAVED_SUCCESSFULLY' }, { idp: 'SAML2' }),
                    body: <UiText>
                        {translate({ id: 'CLIENT_SUCCESSFULLY_SAVED' }, { idp: 'SAML2' })}
                    </UiText>,
                    icon: 'info',
                    showCancel: true,
                    primaryButtonText: translate({ id: 'CLIENT_ENABLE_NOW' }),
                    cancelButtonText: translate({ id: 'CLIENT_ENABLE_LATER' }),
                });
                payload.isActive = proceed;

                const newExternalIdentityConfig = fetchedExternalIdentity
                    ? await updateExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    })
                    : await createExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });

                const errorCode = mapExternalIdentityPayloadToErrorCode(newExternalIdentityConfig);
                if (errorCode) {
                    setErrorDialog(errorCode);
                    return;
                }

                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }), notificationType.SUCCESS);
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                const parsedErrorMessage = await getErrorMessage(error);
                setErrorDialog(parsedErrorMessage || translate({ id: 'CLIENT_CONFIGURE_SAML_GENERIC_ERROR' }));
            }
        },
        [
            fetchedExternalIdentity,
            partitionGlobalId,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            createDialog,
            setErrorDialog,
            getErrorMessage,
        ],
    );

    useEffect(() => {
        if (fetchedExternalIdentity) {
            reset(mapExternalIdentityPayloadToSamlConfigData(fetchedExternalIdentity));
        }
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_SAML_SSO_CONFIGURATION' }),
        },
    ], [ organizationName, translate ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            maxWidth="900px"
            position='center'>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="configure-saml-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                addScrollPadding
            >
                {errorCode && (
                    <PortalAlertBar
                        status="error"
                        cancelable={false}>
                        <UiText style={{ paddingRight: '8px' }}>
                            {errorCode}
                        </UiText>
                    </PortalAlertBar>
                )}
                <FormProvider {...methods}>
                    <EditIdentityProviderFormComponent />
                </FormProvider>
                <div className={classes.section}>
                    <UiText
                        className={clsx(classes.inputLabel, classes.inputMargin)}
                        id="serviceProviderEntityIdLabel">
                        {translate({ id: 'CLIENT_SERVICE_PROVIDER_ENTITY_ID' })}
                    </UiText>
                    <Controller
                        name="serviceProviderEntityId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.serviceProviderEntityId}
                                helperText={
                                    errors.serviceProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                }
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                InputProps={{ className: 'Tall' }}
                                inputProps={{ 'aria-labelledby': 'serviceProviderEntityIdLabel' }}
                                data-cy="configure-saml-service-provider-entity-id"
                            />
                        )}
                    />
                </div>
                <div className={classes.section}>
                    <UiText
                        className={clsx(classes.inputLabel, classes.inputMargin)}
                        id="identityProviderEntityIdLabel">
                        {translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}
                    </UiText>
                    <Controller
                        name="identityProviderEntityId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.identityProviderEntityId}
                                helperText={
                                    errors.identityProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                }
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                InputProps={{ className: 'Tall' }}
                                inputProps={{ 'aria-labelledby': 'identityProviderEntityIdLabel' }}
                                data-cy="configure-saml-identity-provider-entity-id"
                            />
                        )}
                    />
                </div>
                <div className={classes.section}>
                    <UiText
                        className={clsx(classes.inputLabel, classes.inputMargin)}
                        id="ssoServiceUrlLabel">
                        {translate({ id: 'CLIENT_SINGLE_SIGN_ON_SERVICE_URL' })}
                    </UiText>
                    <Controller
                        name="singleSignOnServiceUrl"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="url"
                                error={!!errors.singleSignOnServiceUrl}
                                helperText={
                                    errors.singleSignOnServiceUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                }
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                InputProps={{ className: 'Tall' }}
                                inputProps={{ 'aria-labelledby': 'ssoServiceUrlLabel' }}
                                data-cy="configure-saml-single-sign-on-service-url"
                            />
                        )}
                    />
                </div>
                <div className={classes.section}>
                    <Controller
                        name="allowUnsolicitedAuthnResponse"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="configure-saml-allow-unsolicited-authn-response"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_ALLOW_UNSOLICITED_AUTHENTICATION_RESPONSE' })}
                                    </div>
                                }
                            />
                        )}
                    />
                </div>
                <div className={classes.section}>
                    <UiText
                        className={clsx(classes.inputLabel, classes.inputMargin)}
                        id="returnUrlLabel">
                        {translate({ id: 'CLIENT_RETURN_URL' })}
                    </UiText>
                    <Controller
                        name="returnUrl"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="url"
                                error={!!errors.returnUrl}
                                helperText={
                                    errors.returnUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                }
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                InputProps={{ className: 'Tall' }}
                                inputProps={{ 'aria-labelledby': 'returnUrlLabel' }}
                                data-cy="configure-saml-return-url"
                            />
                        )}
                    />
                </div>
                <UiSelect
                    control={control}
                    className={classes.selectInput}
                    name="externalAuthUserMappingStrategy"
                    inputLabel={translate({ id: 'CLIENT_EXTERNAL_USER_MAPPING_STRATEGY' })}
                    error={!!errors.externalAuthUserMappingStrategy}
                    options={externalUserMappingStrategyOptions}
                    required
                    helperText={
                        errors.externalAuthUserMappingStrategy?.type === 'required' &&
            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    fullWidth
                    dataCy="configure-saml-external-auth-user-mapping-strategy"
                />
                {externalAuthUserMappingStrategy?.toString() === ExternalUserMappingStrategy.ByUserName.toString() && (
                    <div className={classes.section}>
                        <UiText
                            className={clsx(classes.inputLabel, classes.inputMargin)}
                            id="externalAuthUserIdentifierClaimLabel">
                            {translate({ id: 'CLIENT_EXTERNAL_AUTH_USER_IDENTIFIER_CLAIM' })}
                        </UiText>
                        <Controller
                            name="externalAuthUserIdentifierClaim"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    error={!!errors.externalAuthUserIdentifierClaim}
                                    helperText={
                                        errors.externalAuthUserIdentifierClaim?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                    }
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    inputProps={{ 'aria-labelledby': 'externalAuthUserIdentifierClaimLabel' }}
                                    data-cy="configure-saml-external-auth-user-identifier-claim"
                                />
                            )}
                        />
                    </div>
                )}
                <UiSelect
                    control={control}
                    className={classes.selectInput}
                    name="saml2BindingType"
                    inputLabel={translate({ id: 'CLIENT_SAML_BINDING_TYPE' })}
                    error={!!errors.saml2BindingType}
                    options={samlBindingTypeOptions}
                    required
                    helperText={errors.saml2BindingType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    fullWidth
                    dataCy="configure-saml2-binding-type"
                />
                <FormProvider {...methods}>
                    <EditCertificateLocationsFormComponent />
                </FormProvider>
            </UiForm>
        </UiPageContainer>
    );
};

export default ConfigureSamlComponent;
