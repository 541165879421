import type { TaskOdata } from '@experiences/interfaces';
import { get } from '@experiences/util';
import buildQuery from 'odata-query';

export function getTask(accessToken: string, tenantName: string, filter: string) {
    const query = buildQuery({
        select: [ 'Title', 'Type', 'Priority', 'Id', 'CreationTime' ],
        filter: {
            IsDeleted: false,
            Status: filter,
        },
        expand: 'AssignedToUser',
        orderBy: [ 'IsCompleted asc', 'Status asc', 'Priority desc', 'CreationTime desc' ] as any,
        top: 5,
    });

    const url = `/${tenantName}/orchestrator_/odata/Tasks/UiPath.Server.Configuration.OData.GetTasksAcrossFolders()${query}`;

    return get<TaskOdata>(url, {
        accessToken,
        stripPortalPath: true,
    });
}
