import TableCell from '@mui/material/TableCell';
import type { ReactNode } from 'react';
import React from 'react';

import {
    CheckboxCellSize,
    RowHeight,
} from './constants';

interface ITableCellProps {
    id?: string;
    checkbox?: boolean;
    rowIndex?: number;
    children?: ReactNode;
    columnWidth: number;
    justifyContent?: any;
    wrap?: boolean;
    dataCy?: string;
    tabIndex?: number;
    ariaHidden?: boolean;
    headers?: string;
    header?: boolean;
}

export const UiTableCell = ({
    id = undefined,
    checkbox,
    rowIndex,
    children,
    columnWidth,
    justifyContent = 'flex-start',
    wrap = false,
    dataCy,
    tabIndex = 0,
    ariaHidden = false,
    headers = undefined,
    header = false,
}: ITableCellProps) => {
    const firstRowWithCheckbox = checkbox && rowIndex === 0;
    return (
        <TableCell
            tabIndex={tabIndex}
            style={{
                justifyContent,
                textAlign: 'center',
                paddingRight: justifyContent === 'flex-end' ? '16px' : '0',
                height: RowHeight + 'px',
                ...(wrap && { whiteSpace: 'unset' }),
                flex: firstRowWithCheckbox ? `0 0 ${CheckboxCellSize}px` : `1 1 ${columnWidth}px`,
            }}
            id={id}
            headers={headers}
            key={rowIndex}
            component={header ? 'th' : 'div' as any}
            padding={firstRowWithCheckbox ? 'checkbox' : 'normal'}
            data-cy={dataCy}
            role={ariaHidden ? 'none' : (header ? 'columnheader' : 'cell')}
            scope={header ? 'col' : undefined}
            aria-hidden={checkbox ? undefined : ariaHidden}
        >
            {children}
        </TableCell>
    );
};
