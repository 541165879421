import type { ILrmProduct } from '@experiences/interfaces';

import type {
    ILicensingFallbackConfiguration,
    IServiceLicense,
    IServiceLicenseAllocationRequest,
    ITenantServiceLicense,
} from '../../component/tenants/interfaces/service';
import {
    get,
    put,
} from '../utility/Requests.default';

const licenseManagementUri = '/api/manageLicense';
export const unavailableTenantId = '00000000-0000-0000-0000-000000000000';

export async function saveServiceLicenses(
    accountId: string,
    tenantId: string,
    serviceType: string,
    serviceLicense: IServiceLicenseAllocationRequest,
) {
    return put<IServiceLicense>(
        `${licenseManagementUri}/api/account/${accountId}/service-license/${tenantId}/${serviceType}`,
        { body: serviceLicense },
    );
}

export function getServiceLicenses({ partitionGlobalId }: { url: string; partitionGlobalId: string }) {
    return get<ILrmProduct[]>(`${licenseManagementUri}/api/account/${partitionGlobalId}/available-account-licenses`);
}

export function getTenantServiceLicenses({
    accountId, tenantId, services,
}: { url: string; accountId: string; tenantId: string; services: string[] }) {
    return get<ITenantServiceLicense[]>(`${licenseManagementUri}/api/account/${accountId}/service-licenses/${tenantId}`, {
        urlParams: { services },
        shouldEnumerate: false,
    });
}

export function getFallbackConfiguration({
    organizationId, tenantId, operationCode,
}: { url: string; organizationId: string; tenantId: string; operationCode: string }) {
    return get<ILicensingFallbackConfiguration>(`${licenseManagementUri}/api/account/fallback/configuration/${organizationId}/${tenantId}/${operationCode}`, {
        urlParams: { },
        shouldEnumerate: false,
    });
}

export async function saveFallbackConfiguration(
    organizationId: string,
    tenantId: string,
    fallbackConfiguration: ILicensingFallbackConfiguration,
) {
    return put<ILicensingFallbackConfiguration>(
        `${licenseManagementUri}/api/account/fallback/configuration/${organizationId}/${tenantId}`,
        { body: fallbackConfiguration },
    );
}
