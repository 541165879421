import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    ApBreadcrumb,
    ApBreadcrumbItem,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import type { IBreadCrumb } from '../../common/interfaces/breadcrumb';

const AdminBreadCrumbs: React.FC<{ breadCrumbTrail: IBreadCrumb[] }> = ({ breadCrumbTrail }) => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const items = useMemo(() => breadCrumbTrail.map((crumb) => ({
        link: crumb.link,
        name: crumb.name === 'host' ? translate({ id: 'CLIENT_HOST' }) : crumb.name,
    })), [ breadCrumbTrail, translate ]);

    const onClick = useCallback((link: string) => {
        navigate(getRoute(link));
    }, [ getRoute, navigate ]);

    return (
        <ApBreadcrumb
            onItemClick={ev => onClick(ev.detail.href)}
            data-cy="admin-bread-crumb-navigation">
            {items.map((crumb, index) => (<ApBreadcrumbItem
                key={index}
                href={crumb.link}
                label={crumb.name} />
            ))}
        </ApBreadcrumb>
    );
};

export default AdminBreadCrumbs;
