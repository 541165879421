import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import type { Store } from 'redux';
import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
import thunk from 'redux-thunk';

import {
    AppEnvironment,
    appState,
} from '../common/constants/Constant';
import rootReducer from './reducer';

const initialState: any = { profile: {} };

const state = sessionStorage.getItem(appState.REDUX_STORE_STATE);

if (state) {
    try {
        initialState.profile = JSON.parse(state);
    } catch (error) {
        portalTelemetry.trackTrace({
            message: `Error parsing stored app state: ${state}`,
            severityLevel: SeverityLevel.Major,
        });
    }
}

let store: Store;
if (process.env.NODE_ENV === AppEnvironment.DEVELOPMENT) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
} else {
    store = createStore(rootReducer, initialState, applyMiddleware(thunk));
}

export default store;
