import {
    AccountLicense,
    LogExportInsStorageType,
} from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import { AdminInsightsExportEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { IExportLogsData } from '../../../../services/insights/InsightsLogsExportService';
import {
    deleteLogsExportDetails,
    exportLogsUrl,
    getLogsExportDetails,
    postLogsExportDetails,
} from '../../../../services/insights/InsightsLogsExportService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import {
    logExportInsStorageTypeSelectItems,
    logExportInsStorageTypeSelectItemsOnPrem,
} from '../constant/InsightsLogExportConstant';
import {
    FormActionButtons,
    FormErrorHandler,
} from '../LogExportFormComponents';
import { AwsSqsInputs } from './AwsSqsInputs';
import { AzureEventHubInputs } from './AzureEventHubInputs';
import type { LogExportConnectionDetails } from './ConnectionDetailsType';
import { SplunkInputs } from './SplunkInputs';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formButtons: { display: 'flex' },
        cancelButton: { marginRight: '10px' },
        menuItem: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }),
);

const InsightsLogExportComponent: React.FC<{
    tenantId: string;
    tenantName: string;
}> = ({
    tenantId,
    tenantName,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ submitError, setSubmitError ] = useState('');
    const logicalName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const currentAccountType = useSelector(accountType);
    const { logEvent } = useTelemetryHelper();
    const createNotification = useUiSnackBar();
    const isK8s = process.buildConfigs.name === 'k8s';
    const storageTypeToSelect = isK8s ? logExportInsStorageTypeSelectItemsOnPrem : logExportInsStorageTypeSelectItems;

    const {
        open, close,
    } = useModalState(RouteNames.TenantServices.replace(':tenantId', tenantId));

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const createDialog = useShowDialog();

    const {
        data: blobFields,
        isValidating,
        error,
        mutate,
    } = useSWR(
        {
            url: exportLogsUrl,
            tenantId,
            accountId,
            accountLogicalName: logicalName,
            tenantName,
        },
        getLogsExportDetails,
        { shouldRetryOnError: false }, // prevents loading screen showing if there is no config set up
    );

    const [ showStorageFields, setShowStorageFields ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ storageType, setStorageType ] = useState(LogExportInsStorageType.None);

    const methods = useForm<LogExportConnectionDetails>({
        mode: 'onChange',
        defaultValues: blobFields?.connectionDetails,
    });

    const {
        handleSubmit, formState, reset,
    } = methods;

    const {
        isDirty, isValid,
    } = formState;

    const selectedStorageType = useMemo(
        () => storageType === LogExportInsStorageType.None ? blobFields?.storageType as LogExportInsStorageType : storageType,
        [ storageType, blobFields ]);

    const isSubmitDisabled = useMemo(() => !isDirty || !isValid, [ isDirty, isValid ]);

    const storageTypeDisplayId = storageTypeToSelect.find((obj) => obj.id === selectedStorageType)?.displayId;
    const storageTypeAriaLabel = storageTypeDisplayId ? translate({ id: 'CLIENT_STORAGE_TYPE' }) + ', ' + translate({ id: storageTypeDisplayId }) : '';

    // When we switch to different storage type, the control will be set to dirty even no changes made on inputs, this is to clean it up
    useEffect(() => {
        if (!isValidating) {
            if (selectedStorageType === blobFields?.storageType) {
                reset(blobFields?.connectionDetails);
            } else {
                reset({} as LogExportConnectionDetails);
            }
        }
    }, [ selectedStorageType, reset, blobFields, isValidating ]);

    useEffect(() => {
        setShowStorageFields(!!blobFields?.storageType && !!blobFields?.connectionDetails);
    }, [ blobFields ]);

    const deleteConfig = useCallback(async () => {
        if (!blobFields?.connectionDetails) {
            setShowStorageFields(false);
            return;
        }

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_LOGS_DELETE' }),
            body: translate({ id: 'CLIENT_INSIGHTS_DELETE_DETAILS' }),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
        });
        if (proceed) {
            setLoading(true);
            try {
                await deleteLogsExportDetails({
                    tenantId,
                    accountId,
                    accountLogicalName: logicalName,
                    tenantName,
                });
                createNotification(translate({ id: 'CLIENT_INSIGHTS_DELETE_SUCCESS' }));
                setLoading(false);
                setShowStorageFields(false);
                setStorageType(LogExportInsStorageType.None);
                mutate({
                    tenantKey: tenantId,
                    storageType: LogExportInsStorageType.AzureEventHub,
                    connectionDetails: { endpoint: '' },
                } satisfies IExportLogsData);
                reset({} as LogExportConnectionDetails);
            } catch (err) {
                createNotification(translate({ id: 'CLIENT_INSIGHTS_DELETE_ERROR' }));
                setLoading(false);
            }
        }
    }, [
        blobFields?.connectionDetails,
        createDialog,
        translate,
        tenantId,
        accountId,
        logicalName,
        tenantName,
        createNotification,
        mutate,
        reset,
    ]);

    const onSubmit = useCallback(
        async (data: LogExportConnectionDetails) => {
            setLoading(true);

            try {
                await postLogsExportDetails({
                    body: {
                        tenantKey: tenantId,
                        storageType: selectedStorageType,
                        connectionDetails: data,
                    },
                    tenantId,
                    accountId,
                    tenantName,
                    accountLogicalName: logicalName,
                });
                createNotification(translate({ id: 'CLIENT_INSIGHTS_DATA_SUCCESS' }, { 0: tenantName }));
                setLoading(false);
                close(true);
                logEvent(AdminInsightsExportEvent.Success);
            } catch (err) {
                const errorObject = await getErrorObject(err);
                const errorMessage = errorObject.response?.data || (await getErrorMessage(errorObject));
                setSubmitError(errorMessage);
                setLoading(false);
                logEvent(AdminInsightsExportEvent.Failed, { AdminInsightsExportError: errorMessage });
            }
        },
        [
            close,
            logicalName,
            createNotification,
            translate,
            getErrorMessage,
            getErrorObject,
            accountId,
            tenantId,
            tenantName,
            logEvent,
            selectedStorageType,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_CONFIGURATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => {
                    logEvent(AdminInsightsExportEvent.Cancel);
                    close();
                },
            }}
            loading={isValidating}
        >
            <FormErrorHandler
                error={error}
                submitError={submitError}
                setSubmitError={setSubmitError}
                currentAccountType={currentAccountType}
            />
            {isAdmin &&
                    AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY &&
                    !submitError &&
                    (!error || error.response?.status === 404) && (
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <FormActionButtons
                            showActions={showStorageFields}
                            classes={classes}
                            loading={loading}
                            onSubmit={handleSubmit(onSubmit)}
                            isSubmitDisabled={isSubmitDisabled}
                            close={() => {
                                logEvent(AdminInsightsExportEvent.Cancel);
                                close();
                            }}
                        />
                    }
                    isDrawer
                >
                    <div className={classes.input}>
                        <UiText className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_INSIGHTS_DATA_EXPORT' })}
                        </UiText>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showStorageFields}
                                    onClick={() => {
                                        if (showStorageFields) {
                                            deleteConfig();
                                        } else {
                                            setShowStorageFields(true);
                                        }
                                    }}
                                />
                            }
                            label={translate({ id: 'CLIENT_SEND_INSIGHTS_DATA_CUSTOM_STORAGE' })}
                            data-cy="logs-switch"
                        />
                    </div>
                    {showStorageFields && (
                        <>
                            <div className={classes.input}>
                                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                                    {translate({ id: 'CLIENT_STORAGE_TYPE' })}
                                </UiText>
                                <Select
                                    aria-label={translate({ id: 'CLIENT_STORAGE_TYPE' })}
                                    variant="outlined"
                                    value={selectedStorageType}
                                    fullWidth
                                    IconComponent={ExpandMoreIcon}
                                    onChange={(event) => setStorageType(event.target.value as LogExportInsStorageType)}
                                    data-cy="logs-types-select"
                                    MenuProps={
                                        { MenuListProps: { 'aria-label': translate({ id: 'CLIENT_STORAGE_TYPE' }) } }
                                    }
                                    inputProps={{ 'aria-label': storageTypeAriaLabel }}
                                >
                                    {storageTypeToSelect.map((item, i) => (
                                        <MenuItem
                                            key={i}
                                            selected={selectedStorageType === item.id}
                                            value={item.id}
                                            data-cy={`logs-item-${item.id}`}
                                            aria-label={translate({ id: item.displayId })}
                                        >
                                            <div className={classes.menuItem}>
                                                {translate({ id: item.displayId })}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <FormProvider {...methods} >
                                {
                                    selectedStorageType === LogExportInsStorageType.AzureEventHub &&
                                    <AzureEventHubInputs />
                                }
                                {
                                    selectedStorageType === LogExportInsStorageType.AwsSqs &&
                                    <AwsSqsInputs />
                                }
                                {
                                    selectedStorageType === LogExportInsStorageType.Splunk &&
                                    <SplunkInputs />
                                }
                            </FormProvider>
                        </>
                    )}

                </UiForm>
            )}
        </UiDrawer>
    );
};

export default InsightsLogExportComponent;
