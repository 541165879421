import { UiText } from '@experiences/ui-common';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => createStyles({ stackTrace: { marginTop: '12px' } }));

export const TagDeleteErrorDialog: React.FC<{ error: Error; type: 'Label' | 'Property' }> = ({
    error, type,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return <>
        <UiText>
            {translate({ id: `CLIENT_FAILED_TO_DELETE_${type.toUpperCase()}` })}
        </UiText>
        <Accordion
            className={classes.stackTrace}
            defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <UiText>
                    {translate({ id: 'CLIENT_OPEN_STACK_TRACE' })}
                </UiText>
            </AccordionSummary>
            <AccordionDetails>
                <code>
                    {error.stack}
                </code>
            </AccordionDetails>

        </Accordion>
    </>;
};
