import { UiText } from '@experiences/ui-common';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useEffect,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useEcommerce } from '../helpers/useEcommerce';
import type { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({
        taxIdController: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        linkEmailsCheckbox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        smallIcon: {
            fontSize: '1.2em',
            marginLeft: '4px',
        },
        headerSubText: {
            lineHeight: '16px',
            fontSize: '12px',
            fontFamily: 'Noto Sans',
            backgroundColor: 'rgba(255, 240, 241)',
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '12px',
            paddingRight: '10px',
        },
        bold: { fontWeight: 'bold' },
    }),
);

const mandatoryVatIdCountries = [ 'ZA' ];

const EcommerceTaxId: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const {
        country: currentCountry,
        zipcode: currentZipCode,
        taxId,
    } = watch();

    const {
        currentSkuPackageDetails,
        setEcommerceZipCodeValue,
    } = useEcommerce();

    const [ debouncedZipCode ] = useDebounce(currentZipCode, 1000);
    useEffect(() => {
        if (debouncedZipCode) {
            setEcommerceZipCodeValue(debouncedZipCode);
        }
    }, [ setEcommerceZipCodeValue, errors.zipcode, debouncedZipCode ]);

    const isRequired = useMemo(
        () => mandatoryVatIdCountries.includes(currentCountry),
        [ currentCountry ],
    );

    return (
        <div className={classes.taxIdController}>
            <Controller
                name="taxId"
                rules={{
                    required: isRequired,
                    validate: p => !isRequired || !!p?.trim(),
                }}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        id="taxId"
                        error={!!errors.taxId}
                        helperText={
                            ((errors.taxId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                            (errors.taxId?.type === 'validate' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })))
                        }
                        InputProps={{ className: 'Tall' }}
                        label={
                            <div className={classes.linkEmailsCheckbox}>
                                {translate({ id: isRequired ? 'CLIENT_TAX_ID_REQUIRED' : 'CLIENT_TAX_ID' })}
                                <Tooltip
                                    title={translate({ id: isRequired ? 'CLIENT_TAX_ID_REQUIRED_TOOLTIP' : 'CLIENT_TAX_ID_TOOLTIP' })}
                                    data-cy="ecommerce-form-tax-id-tooltip"
                                    role="tooltip">
                                    <InfoOutlined
                                        className={classes.smallIcon}
                                        tabIndex={0}
                                        aria-hidden={false}
                                        aria-label={translate(
                                            { id: isRequired ? 'CLIENT_TAX_ID_REQUIRED_TOOLTIP' : 'CLIENT_TAX_ID_TOOLTIP' }
                                        )} />
                                </Tooltip>
                            </div>
                        }
                        placeholder={translate({ id: 'CLIENT_TAX_ID_PLACEHOLDER' })}
                        data-cy="ecommerce-form-tax-id"
                        disabled={disabled}
                        fullWidth={currentCountry !== 'US' && currentCountry !== 'CA'}
                    />
                )}
            />
            { (currentSkuPackageDetails.tax?.serviceDown === true && taxId !== '') &&
            <Tooltip title="vat-error-message">
                <UiText
                    className={classes.headerSubText}
                    data-cy="vat-error-message">
                    <span className={classes.bold}>
                        {translate({ id: 'CLIENT_ECOMMERCE_VAT_DOWN_TOOLTIP_TEXT' }) + ' ' }
                    </span>
                    {translate({ id: 'CLIENT_ECOMMERCE_CONTINUE_WITHOUT_VAT_VALIDATION_TOOLTIP_TEXT' })}
                </UiText>
            </Tooltip> }
        </div>
    );
};

export default EcommerceTaxId;
