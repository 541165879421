import type { IConsumable } from '@experiences/interfaces';
import { ConsumablesEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import {
    consumableCodeToApiKeyIdentifier,
    consumableToLearnMoreLink,
    consumableToViewUsageMap,
} from '../../../../common/constants/Constant';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import { UiApiKey } from './UiApiKey';
import { UiConsumableCardLegend } from './UiConsumableCardLegend';
import { UiConsumablePool } from './UiConsumablePool';

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            height: '100%',
            gap: '4px',
            justifyContent: 'flex-start',
        },
        header: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        cardBottom: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flexGrow: 1,
        },
        cardLink: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '10px',
        },
        link: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            padding: '0 24px 0 0',
            '&:hover': { textDecoration: 'underline' },
        },
        selected: {
            borderLeft: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderRight: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderTop: `1px solid ${theme.palette.semantic.colorBorder}`,
        },
    }),
);

const viewUsageTelemetryMap = new Map<string, string>([
    [ 'RU', ConsumablesEvent.RuViewUsage ],
    [ 'AIU', ConsumablesEvent.AiuViewUsage ],
    [ 'APICALLS', ConsumablesEvent.ApiCallsViewUsage ],
]);

export const UiConsumableCard: React.FC<{
    consumable: IConsumable;
    handleOpenChart?: (selected: string) => void;
    isSelected?: boolean;
    tenantId?: string;
}> = ({
    consumable, handleOpenChart, isSelected, tenantId,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();

    const { logEvent } = useTelemetryHelper();
    const handleTelemetryForViewUsage = useCallback(() => {
        const telemetryEventName = viewUsageTelemetryMap.get(consumable.code);
        if (telemetryEventName) {
            if (tenantId) {
                logEvent(telemetryEventName, { CloudTenantId: tenantId });
            } else {
                logEvent(telemetryEventName);
            }
        }
    }, [ consumable.code, logEvent, tenantId ]);

    const shouldHaveApiKey = useMemo(() =>
        consumableCodeToApiKeyIdentifier[consumable.code] !== undefined
    , [ consumable.code ]);

    return (<Grid
        key={consumable.code}
        item
        xs={6}
        data-cy="licenses-grid-item">
        <div
            className={`${classes.content} ${isSelected ? classes.selected : ''}`}
            data-cy={`product-allocation-${consumable.code.toLowerCase()}`}>
            <div>
                <UiText
                    className={classes.header}
                    data-cy="licenseType">
                    {translate({ id: `CLIENT_CONSUMABLE_USAGE_HEADER_${consumable.code}` })}
                </UiText>
            </div>
            <div>
                {consumable.pools.map(consumptionPool => (
                    <UiConsumablePool
                        key={consumptionPool.name}
                        consumableCode={consumable.code}
                        pool={consumptionPool} />
                ))}
                <UiConsumableCardLegend />
            </div>
            <div className={classes.cardBottom}>
                {shouldHaveApiKey && <UiApiKey
                    consumableCode={consumable.code}
                    apiKey={consumable.apiKey}
                    tenantId={tenantId} />}
                <div className={classes.cardLink}>
                    {handleOpenChart && consumableToViewUsageMap[consumable.code] &&
                  <Link
                      data-cy={`view-usage-link-${consumable.code}`}
                      className={classes.link}
                      onClick={() => {
                          handleOpenChart(consumable.code);
                          handleTelemetryForViewUsage();
                      }}
                      tabIndex={0}
                      underline='none'>
                      {translate({ id: 'CLIENT_CONSUMABLE_VIEW_USAGE' })}
                  </Link>}
                    {consumableToLearnMoreLink[consumable.code] &&
                  <a
                      className={classes.link}
                      target="_blank"
                      rel="noreferrer"
                      href={getLocalizedLink(consumableToLearnMoreLink[consumable.code])}
                      data-cy="learn-more-link"
                  >
                      {translate({ id: 'CLIENT_LEARN_MORE' })}
                  </a>}
                </div>
            </div>
        </div>
    </Grid>);

};
