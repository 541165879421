import { useMemo } from 'react';
import {
    matchRoutes,
    useLocation,
} from 'react-router-dom';

import * as RouteNames from '../constants/RouteNames';

const useIsNoShowLeftNav = () => {
    const currentLocation = useLocation();

    return useMemo(() => !!matchRoutes([
        { path: RouteNames.Profile },
        { path: RouteNames.ThemeSettings },
        { path: RouteNames.AccessibilitySettings },
        { path: RouteNames.NavigationSettings },
        { path: RouteNames.NotificationSettings },
        { path: RouteNames.AddNotificationSettingsProfile },
        { path: RouteNames.PrivacyAndSecuritySettings },
        { path: RouteNames.OrganizationMigration },
        { path: RouteNames.PersonalAccessToken },
    ], currentLocation), [ currentLocation ]);
};

export default useIsNoShowLeftNav;
