import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import * as emailValidator from 'email-validator';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { ISubscriptionForm } from '../interfaces/ecommerce';
import { validateEmailBlacklist } from '../services/BillingService';

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '-8px',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
        },
    }),
);

const EcommerceBusinessEmailAndCompany: React.FC<{
    disabled: boolean;
    planType: string;
}> = ({
    disabled, planType,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const [ loading, setLoading ] = useState(false);
    const {
        businessEmail: businessEmail, companyName: companyName,
    } = watch();
    useEffect(() => {
        (async () => {
            if (businessEmail && !(await validateEmailBlacklist(businessEmail))) {
                setError('businessEmail', { type: 'invalidDomain' });
                setLoading(false);
            }
        })();
    }, [ businessEmail, setError, errors ]);
    const companyNameRef = useRef<HTMLInputElement | null>(null);
    const companyNameOnFocusCallback = useCallback(
        (event: any) => {
            const currentValue = event.target?.value;
            if (currentValue && currentValue !== companyName) {
                setValue('companyName', currentValue);
            }
        },
        [ companyName, setValue ],
    );

    return (
        <div className={classes.row}>
            <Controller
                render={({ field }) => (
                    <TextField
                        {...field}
                        style={{ marginRight: '12px' }}
                        variant="outlined"
                        required
                        id="companyName"
                        label={translate({ id: 'CLIENT_COMPANY_NAME_TEXT' })}
                        error={!!errors.companyName}
                        helperText={(errors.companyName?.type === 'required' && translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_COMPANY_NAME_TEXT' }) },
                        ))}
                        InputProps={{
                            className: 'Tall',
                            onFocus: companyNameOnFocusCallback,
                            inputProps: {
                                ref: companyNameRef,
                                autoComplete: 'off',
                            },
                        }}
                        fullWidth
                        disabled={disabled || loading}
                        data-cy="ecommerce-business-company-name"
                    />
                )}
                control={control}
                rules={{ required: true }}
                name="companyName"
            />
            <div className={classes.column}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="outlined"
                            required
                            id="businessEmail"
                            label={translate({ id: 'CLIENT_BUSINESS_EMAIL' })}
                            error={!!errors.businessEmail}
                            helperText={
                                (errors.businessEmail?.type === 'required' &&
                                    translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_BUSINESS_EMAIL' }) },
                                    )) ||
                                (errors.businessEmail?.type === 'invalidDomain' &&
                                    translate({ id: 'CLIENT_INVALID_EMAIL_DOMAIN_ERROR' })) ||
                                (errors.businessEmail?.type === 'invalid' &&
                                    translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' }))
                            }
                            InputProps={{ className: 'Tall' }}
                            fullWidth
                            disabled={disabled || loading}
                            data-cy="ecommerce-business-email"
                            placeholder={translate({ id: `CLIENT_BUSINESS_EMAIL_PLACEHOLDER_${planType}` })}
                        />
                    )}
                    control={control}
                    rules={{
                        required: true,
                        validate: { invalid: value => emailValidator.validate(value!) },
                    }}
                    name="businessEmail"
                />
                <p style={{ fontSize: '10px' }}>
                    {translate({ id: `CLIENT_BUSINESS_EMAIL_PLACEHOLDER_${planType}` })}
                </p>
            </div>
        </div>
    );
};

export default EcommerceBusinessEmailAndCompany;
