import type {
    IReferenceToken,
    IReferenceTokenPayload,
} from '../../common/interfaces/cis/profile';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const referenceTokenUrl = `${process.buildConfigs.identityApiBaseRoute}/ReferenceToken`;

export interface IReferenceTokenFilterOpts {
    userSearchTerm: string;
    top: number;
    skip: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

export function getReferenceToken(url: string) {
    return get<IReferenceToken[]>(`${referenceTokenUrl}`);
}

export function getAllReferenceTokens({
    partitionGlobalId, opts,
}: { url: string; partitionGlobalId: string; opts: IReferenceTokenFilterOpts }) {
    const paginationMap = new URLSearchParams(opts as Record<string, any>);
    return get<{ totalCount: number; results: IReferenceToken[] }>(
        `${referenceTokenUrl}/partition/${partitionGlobalId}?${paginationMap.toString()}`
    );
}

export function generateReferenceToken(payload: IReferenceTokenPayload) {
    return post<string>(`${referenceTokenUrl}`, { body: payload });
}

export function deleteReferenceToken(referenceTokenId: string) {
    return axiosDelete(`${referenceTokenUrl}/${referenceTokenId}`);
}

export function deleteReferenceTokens(partitionGlobalId: string, tokenIds: string[]) {
    return axiosDelete(referenceTokenUrl, {
        body: {
            partitionGlobalId,
            tokenIds,
        },
    });
}

export function deleteReferenceTokenByValue(partitionGlobalId: string, tokenValue: string) {
    return axiosDelete(`${referenceTokenUrl}/Revoke`, {
        body: {
            partitionGlobalId,
            referenceToken: tokenValue,
        },
    });
}

export function regenerateReferenceToken(referenceTokenId: string, payload: { expiration: string }) {
    return put<string>(`${referenceTokenUrl}/${referenceTokenId}`, { body: payload });
}
