import { UiText } from '@experiences/ui-common';
import { useUserReadableTime } from '@experiences/util';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type {
    IAnnouncement,
    INotification,
} from '../../common/interfaces/announcement';

const useStyles = makeStyles(theme =>
    createStyles({
        notificationHeader: {
            height: '45px',
            alignContent: 'center',
            display: 'table-cell',
            verticalAlign: 'middle',
        },
        notificationHeaderText: {
            fontWeight: 600,
            fontSize: '16px',
            marginLeft: '16px',
        },
        notificationButtonText: {
            fontWeight: 600,
            fontSize: '14px',
            textTransform: 'none',
        },
        notificationPrimaryText: {
            display: 'inherit',
            width: '95%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        notificationViewAll: {
            paddingTop: '9px',
            paddingBottom: '9px',
        },
        emptyNotifications: {
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        emptyNotificationsText: {
            fontSize: '16px',
            color: theme.palette.semantic.colorForegroundDisable,
        },
    }),
);

export const Notifications: React.FC<{ announcements: IAnnouncement[] }> = ({ announcements }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { userReadableTime } = useUserReadableTime();

    const translatedAnnouncements = useMemo(() => announcements.map(announcement => ({
        ...announcement,
        notificationText: translate({ id: announcement.announcementTranslationKey }),
    })), [ announcements, translate ]);

    const allNotificationsSorted = useMemo(() => {
        const allNotifications: INotification[] = [];

        if (translatedAnnouncements) {
            allNotifications.push(...translatedAnnouncements);
        }

        return allNotifications.sort(
            (notification1, notification2) =>
                notification1.notificationDate.getTime() - notification2.notificationDate.getTime(),
        );
    }, [ translatedAnnouncements ]);

    const Row: React.FC<{ notification: INotification }> = ({ notification }) => (
        <>
            <ListItemText
                primary={notification.notificationText}
                secondary={userReadableTime(notification.notificationDate)}
                classes={{ primary: classes.notificationPrimaryText }}
            />
            {!notification.read ? (
                <ListItemIcon style={{ transform: 'scale(.5)' }}>
                    <FiberManualRecordIcon color="primary" />
                </ListItemIcon>
            ) : null}
        </>
    );

    return (
        <Paper>
            <div className={classes.notificationHeader}>
                <UiText className={classes.notificationHeaderText}>
                    {translate({ id: 'CLIENT_NOTIFICATIONS' })}
                </UiText>
            </div>
            <Divider />
            {allNotificationsSorted.length > 0 ? (
                allNotificationsSorted.map((notification, index) => {
                    const announcementLink = (notification as IAnnouncement).announcementLink;
                    return (
                        index < 4 && (
                            <div key={notification.id}>
                                {announcementLink ? (
                                    <MenuItem
                                        component="a"
                                        href={announcementLink}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Row notification={notification} />
                                    </MenuItem>
                                ) : (
                                    <MenuItem>
                                        <Row notification={notification} />
                                    </MenuItem>
                                )}
                                <Divider />
                            </div>
                        )
                    );
                })
            ) : (
                <div>
                    <div className={classes.emptyNotifications}>
                        <div>
                            <VisibilityOffIcon
                                style={{
                                    display: 'flex',
                                    margin: '0 auto',
                                }}
                                color="disabled" />
                            <UiText className={classes.emptyNotificationsText}>
                                {translate({ id: 'CLIENT_NO_NOTIFICATIONS' })}
                            </UiText>
                        </div>
                    </div>
                    <Divider />
                </div>
            )}
        </Paper>
    );
};
