import type {
    IConnectionsResponse,
    IEmailConfiguration,
    IFolderUpdatePayload,
    INotificationProfileResponse,
    IOrchestratorFolderEntity,
    IProfileUpdateSubscriptionPayload,
    ISlackChannel,
    ISlackConnectionConfiguration,
    ITeamsChannel,
    ITeamsConnectionConfiguration,
    ITeamsTeam,
    IUpdateCategorySubscriptionModeRequestData,
    IUpdateUserSubscriptionsRequestData,
} from '../../component/notificationSettings/interfaces/notificationSettings';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

const initialApiPath = '/api/notificationSettings/userSubscription';
export const userNotificationSubscriptionUri = initialApiPath + '/';
const resetUserNotificationSubscriptionUri = initialApiPath + '/reset';
const resetUserNotificationSeveritySubscriptionUri = initialApiPath + '/CategorySubscription';
export const SubscriptionProfileMetadata = initialApiPath + '/SubscriptionProfileMetadata';
export const SubscriptionProfile = initialApiPath + '/SubscriptionProfile';
const UnsubscribeTenant = initialApiPath + '/UnsubscribeTenant';
export const UpdateDefaultSubscriptionUrl = initialApiPath + '/UpdateDefaultSubscription';
const resetUserNotificationPublisherSubscriptionUri = initialApiPath + '/PublisherSubscription';
export const folderNavigationUrl = '/api/notificationSettings/folderNavigation';
export const emailConfigurationUrl = initialApiPath + '/emailConfiguration';
const ConnectionProxy = initialApiPath + '/ConnectionProxy/Get?ConnectorType=';
const ConnectionProxySlack = ConnectionProxy + 'Slack';
const ConnectionProxyTeams = ConnectionProxy + 'Teams';
const ConnectionChannelsProxy = initialApiPath + '/ConnectionProxy/GetChannels';
const ConnectionTeamsProxy = initialApiPath + '/ConnectionProxy/GetTeams';
const ConnectionTeamsChannelsProxy = initialApiPath + '/ConnectionProxy/GetTeamsChannels';
export const slackConfigurationUrl = initialApiPath + '/ConnectionAffiliates';
export const teamsConfigurationUrl = initialApiPath + '/TeamsConnectionAffiliates';
const ConnectionAffiliatesBase = initialApiPath + '/ConnectionAffiliates/Get?ConnectorType=';
export const ConnectionAffiliates = ConnectionAffiliatesBase + 'Slack';
export const ConnectionAffiliatesTeams = ConnectionAffiliatesBase + 'Teams';

const getHeaders = (selectedTenantId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
});

const getHeaderswithAccount = (selectedTenantId: string, selectedAccountId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
    'X-UiPath-Internal-AccountId': selectedAccountId,
});

export function updateSlackConfiguration(requestUri: string,
    updateEmailConfigurationData: ISlackConnectionConfiguration,
    selectedTenantId: string) {
    return post<string>(requestUri, {
        body: { ...updateEmailConfigurationData },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateTeamsConfiguration(requestUri: string,
    updateEmailConfigurationData: ITeamsConnectionConfiguration,
    selectedTenantId: string) {
    return post<string>(requestUri, {
        body: { ...updateEmailConfigurationData },
        headers: getHeaders(selectedTenantId),
    });
}

export function getSlackChannels({
    SupplementConnectionId, startsWith, selectedTenantId, selectedAccountId,
}: { SupplementConnectionId: String;
    startsWith: string; selectedTenantId: string; selectedAccountId: string; }) {

    return get<ISlackChannel[]>(ConnectionChannelsProxy, {
        urlParams: {
            SupplementConnectionId,
            startsWith,
        },
        headers: getHeaderswithAccount(selectedTenantId, selectedAccountId),
    });
}

export function getTeamsChannels({
    SupplementConnectionId, TeamId, startsWith, selectedTenantId, selectedAccountId,
}: { SupplementConnectionId: String; TeamId: string;
    startsWith: string; selectedTenantId: string; selectedAccountId: string; }) {

    return get<ITeamsChannel[]>(ConnectionTeamsChannelsProxy, {
        urlParams: {
            SupplementConnectionId,
            TeamId,
            startsWith,
        },
        headers: getHeaderswithAccount(selectedTenantId, selectedAccountId),
    });
}

export function getTeams({
    SupplementConnectionId, startsWith, selectedTenantId, selectedAccountId,
}: { SupplementConnectionId: String;
    startsWith: string; selectedTenantId: string; selectedAccountId: string; }) {

    return get<ITeamsTeam[]>(ConnectionTeamsProxy, {
        urlParams: {
            SupplementConnectionId,
            startsWith,
        },
        headers: getHeaderswithAccount(selectedTenantId, selectedAccountId),
    });
}
export function getConnections({
    selectedTenantId, selectedAccontId,
}: { selectedTenantId: string; selectedAccontId: string }) {
    return get<IConnectionsResponse>(ConnectionProxySlack, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function getTeamsConnections({
    selectedTenantId, selectedAccontId,
}: { selectedTenantId: string; selectedAccontId: string }) {
    return get<IConnectionsResponse>(ConnectionProxyTeams, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function getAffiliatedConnection({
    requestUri, selectedTenantId, selectedAccontId,
}: { requestUri: string; selectedTenantId: string; selectedAccontId: string }) {
    return get<ISlackConnectionConfiguration>(requestUri, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function getTeamsAffiliatedConnection({
    requestUri, selectedTenantId, selectedAccontId,
}: { requestUri: string; selectedTenantId: string; selectedAccontId: string }) {
    return get<ITeamsConnectionConfiguration>(requestUri, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function getEmailConfiguration({
    requestUri, selectedTenantId, selectedAccountId,
}: { requestUri: string; selectedTenantId: string; selectedAccountId: string }) {
    return get<IEmailConfiguration>(requestUri, { headers: getHeaderswithAccount(selectedTenantId, selectedAccountId) });
}

export function resetEmailConfiguration(requestUri: string, selectedTenantId: string) {
    return axiosDelete(requestUri, { headers: getHeaders(selectedTenantId) });
}

export function updateEmailConfiguration(requestUri: string,
    updateEmailConfigurationData: IEmailConfiguration,
    selectedTenantId: string) {
    return post<string>(requestUri, {
        body: { ...updateEmailConfigurationData },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateDefaultSubscription({
    requestUri, selectedTenantId,
}: { requestUri: string; selectedTenantId: string }) {
    return post<string>(requestUri, {
        body: { TenantId: selectedTenantId },
        headers: getHeaders(selectedTenantId),
    });
}

export function getFolderStructure({
    requestUri, selectedTenantId, urlTemplate, accountName, tenantName,
}: { requestUri: string; selectedTenantId: string; urlTemplate: string;
    accountName: string; tenantName: string; }) {
    urlTemplate = urlTemplate.replace('{accountName}', accountName);
    urlTemplate = urlTemplate.replace('{tenantName}', tenantName);
    const headers = getHeaders(selectedTenantId);
    return get<IOrchestratorFolderEntity[]>(requestUri, {
        headers,
        urlParams: { urlTemplate },
    });
}

export function updateUserPublisherSubscriptionSubscription(
    updateUserSubscriptions: IFolderUpdatePayload,
    selectedTenantId: string) {
    return post<any>(resetUserNotificationPublisherSubscriptionUri, {
        body: { publisherSubscriptions: [ updateUserSubscriptions ] },
        headers: getHeaders(selectedTenantId),
    });
}

export function deleteProfile(profileName: string, selectedTenantId: string) {
    return axiosDelete(SubscriptionProfile + '?Name=' + profileName, { headers: getHeaders(selectedTenantId) });
}

export function getSubscriptionProfile(requestUri: string, selectedTenantId: string, selectedAccontId: string) {
    return get<any>(requestUri, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function getSubscriptionProfiles({
    requestUri, selectedTenantId,
}: { requestUri: string; selectedTenantId: string }) {
    return get<INotificationProfileResponse>(requestUri, { headers: getHeaders(selectedTenantId) });
}

export function getUserNotificationSubscription({
    requestUri, selectedTenantId, publisherName,
}: { requestUri: string; selectedTenantId: string; publisherName?: string }) {
    return get<any>(requestUri, {
        headers: getHeaders(selectedTenantId),
        urlParams: { publisherName },
    });
}

export function updateProfileNotificationSubscription(
    updateUserSubscriptions: IProfileUpdateSubscriptionPayload,
    selectedTenantId: string) {
    return post<any>(SubscriptionProfile, {
        body: { ...updateUserSubscriptions },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateUserNotificationTopicSubscription(
    updateUserSubscriptions: IUpdateUserSubscriptionsRequestData[],
    selectedTenantId: string) {
    return post<any>(userNotificationSubscriptionUri, {
        body: { userSubscriptions: [ ...updateUserSubscriptions ] },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateUserNotificationSeveritySubscriptionMode(
    updateNotificationMode: IUpdateCategorySubscriptionModeRequestData[],
    selectedTenantId: string) {
    return post<any>(resetUserNotificationSeveritySubscriptionUri, {
        body: { CategorySubscriptions: [ ...updateNotificationMode ] },
        headers: getHeaders(selectedTenantId),
    });
}

export async function resetUserNotificationSubscription(publisherId: string, selectedTenantId: string) {
    return await post<any>(resetUserNotificationSubscriptionUri, {
        body: { publisherId },
        headers: getHeaders(selectedTenantId),
    });
}

export async function UnsubscribeTenantNotificationSubscription(selectedTenantId: string) {
    return await post<string>(UnsubscribeTenant, {
        body: { tenantId: selectedTenantId },
        headers: getHeaders(selectedTenantId),
    });
}
