import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { profile } from '../../store/selectors';

const useProfileStateName = (): string[] => {

    const profileState = useSelector(profile);

    return useMemo(() => {
        const splitName: string[] = profileState.accountUserDto.firstName.split(' ');

        if (splitName.length > 1) {
            return [ splitName[0], splitName[1] ];
        }
        return [ profileState.accountUserDto.firstName, profileState.accountUserDto.lastName ];
    }
    , []);
};

export default useProfileStateName;
