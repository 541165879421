export type TagType = 'Label' | 'KeyValue';
export type DeleteType = 'Label' | 'Property' | 'Value';
export type LabelDataType = 'String' | 'Number' | 'Boolean' | 'Regex';
export enum EntityType {
    ACTION = 'Action',
    ACTION_CATALOG = 'ActionCatalog',
    ASSET = 'Asset',
    BUCKET = 'Bucket',
    TASK_CATALOG = 'TaskCatalog',
    QUEUE = 'Queue',
    PROCESS = 'Process',
    SCHEDULE = 'Schedule',
    FOLDER_PACKAGE = 'FolderPackage',
    MACHINE = 'Machine',
}
export const OrchestratorObjectType: EntityType[] = [
    EntityType.ASSET,
    EntityType.BUCKET,
    EntityType.TASK_CATALOG,
    EntityType.QUEUE,
    EntityType.PROCESS,
    EntityType.SCHEDULE,
    EntityType.FOLDER_PACKAGE,
    EntityType.MACHINE,
];
export enum OrderByDirection {
    Ascending = 'Ascending',
    Descending = 'Descending',
}
export enum PageDirection {
    First = 'First',
    Last = 'Last',
    Next = 'Next',
    Previous = 'Previous',
}

export enum PermissionType {
    Create = 'create',
    View = 'view',
    Edit = 'edit',
    Delete = 'delete',
}

export const TagPermissions = {
    [PermissionType.Create]: 'Tags.Create',
    [PermissionType.View]: 'Tags.View',
    [PermissionType.Edit]: 'Tags.Edit',
    [PermissionType.Delete]: 'Tags.Delete',
};

export interface EntityReferencesCount {
    count: number;
    entityType: EntityType;
}

export interface ITagSearchFiltersDto {
    startsWith: string;
    type: TagType;
    skip: number;
    take: number;
    offset: string;
    orderByDirection: string;
    pageDirection: string;
}

export interface ILabelModel {
    key: string;
    name: string;
    description: string;
    referencesCount: number;
    dataType: LabelDataType;
    regex?: string;
    type: TagType;
    accountKey?: string;
    tenantKey?: string;
    values?: string[];
    entityReferencesCount?: EntityReferencesCount[];
}

export interface IValueModel {
    key: string;
    displayValue: string;
    normalizedValue: string;
    entityReferencesCount?: EntityReferencesCount[];
}

export interface ILabelDeleteModel {
    identifiers: string[];
    type: TagType;
}

export interface ILabelModelPaginationResult {
    count: number;
    value: ILabelModel[];
}

export interface IValueModelPaginatedResult {
    count: number;
    value: IValueModel[];
}

export interface ITagModelObject extends ILabelModel {
    id: string;
}

export interface IValueModelObject extends IValueModel {
    id: string;
}

export const RestrictedTagLabel = [
    'UiPathStudioProcess',
    'UiPathStudioTest',
    'UiPathStudioTask',
    'UiPathStudioLibrary',
    'UiPathObjects',
    'UiPathActivities',
    'UiPathStudioX',
    'UiPathTestCases',
    'UiPathEntities',
    'UiPathWebServices',
    'UiPathStudioTemplate',
    'UiPathStudioLocalizedTemplate',
    'C#',
    'CSharp',
    'VB',
    'VisualBasic',
    'GettingStartedTemplate',
];

export enum TenantTagsTab {
    LABELS = 'labels',
    PROPERTIES = 'properties',
}

export type TenantTagsTabType = typeof TenantTagsTab[keyof typeof TenantTagsTab];
