import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useNavigateWithParams } from '@experiences/util';
import {
    useCallback,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useAuthentication from '../../../auth/hooks/Authentication';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';

const useOrganizationMismatchViewModel = () => {
    const EnableApolloSwitchingOrgDialog = useFeatureFlagValue(Features.EnableApolloSwitchingOrgDialog.name);

    const navigate = useNavigateWithParams();

    const [ shouldRenderDialog, setShouldRenderDialog ] = useState(!EnableApolloSwitchingOrgDialog);

    const accountNameInStore = useSelector(accountLogicalName);
    const accountGuidInStore = useSelector(accountGlobalId);
    const { accountName } = useParams() as { accountName: string };

    const { logout } = useAuthentication();

    const close = useCallback(() => {
        navigate(-1);
        setShouldRenderDialog(false);
    }, [ navigate ]);

    return {
        shouldRenderDialog,
        accountNameInStore,
        accountGuidInStore,
        accountName,
        logout,
        close,
    };
};

export default useOrganizationMismatchViewModel;
