import { Admin } from './Admin';
import { AutomationExpressAdmin } from './AutomationExpressAdmin';
import { AutomationExpressUser } from './AutomationExpressUser';
import { BusinessAnalyst } from './BusinessAnalyst';
import { BuisnessUser } from './BusinessUser';
import { CitizenDev } from './CitizenDev';
import { DefaultFallBack } from './DefaultFallback';
import { RPADev } from './RPADev';
import { Tester } from './Tester';

export const HomePages = [
    Admin,
    RPADev,
    CitizenDev,
    BusinessAnalyst,
    BuisnessUser,
    Tester,
    AutomationExpressAdmin,
    AutomationExpressUser,
    DefaultFallBack,
];
