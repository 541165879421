import { GlobalStyles } from '@experiences/theme';
import {
    UiAlertBanner,
    UiText,
} from '@experiences/ui-common';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
    userGlobalId,
} from '../../../../store/selectors';
import useEcommercePaymentFailedBannerViewModel from './useEcommercePaymentFailedBannerViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        closeIconAlignment: {
            float: 'right',
            marginTop: '-14px',
            alignSelf: 'flex-end',
            marginLeft: 'auto',
        },
        bannerText: {
            marginRight: 'auto',
            color: Tokens.Colors.ColorForegroundInverseLight,
            fontWeight: 400,
            float: 'left',
        },
        banner: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 'auto',
        },
    }),
}));

export const EcommercePaymentFailedBannerComponent: React.FC = () => {
    const classes = useStyles();
    const currentAccountName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);

    const {
        onClose, bannerMessage, shouldShowBanner,
    } = useEcommercePaymentFailedBannerViewModel(currentAccountName, partitionGlobalId, userId, isAdmin);

    return (
        <>
            {
                shouldShowBanner &&
                (
                    <div
                        data-cy="ecommerce-payment-failed-banner">
                        <UiAlertBanner
                            type="warning"
                            closeable={false}
                        >
                            <div className={classes.banner}>
                                <div
                                    className={classes.bannerText}>
                                    <UiText data-cy="ecommerce-payment-failed-banner-text">
                                        <FormattedMessage
                                            id={bannerMessage}
                                            values={{
                                                a: (msg: React.ReactNode[]) =>
                                                    (
                                                        <a
                                                            className={classes.a}
                                                            // TODO: Link proper documentation when ready
                                                            href="https://docs.uipath.com/automation-cloud/docs/purchasing-licenses"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {msg}
                                                        </a>
                                                    ),
                                            }}
                                        />
                                    </UiText>
                                </div>
                                <div className={classes.closeIconAlignment}>
                                    <IconButton
                                        data-cy="ecommerce-payment-failed-banner-close-icon"
                                        onClick={() => onClose()}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </UiAlertBanner>

                    </div>
                )
            }
        </>
    );
};
