import type {
    IPagination,
    ITagModelObject,
} from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    getLabels,
    getPermissions,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTagsDeleteDialog } from '../../common/useTagsDeleteDialog';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const useTenantTagsLabelsViewModel = () => {
    const navigate = useNavigateWithParams();
    const test = useParams() as { tenantId: string };

    const { tenantId } = test;
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();

    // Redux state
    const organizationName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);

    // Component state
    const [ pagination, setPagination ] = useState<IPagination>({
        searchTerm: '',
        top: DEFAULT_PAGE_SIZE,
        skip: DEFAULT_PAGE_INDEX * DEFAULT_PAGE_SIZE,
    });

    const { mutate } = useSWRConfig();

    const { data: tenant } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ? {
            url: organizationManagementTenantUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const { data: permissions } = useSWR(
        ((organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant)) ?
            {
                url: `${tagsUrl}/permissions`,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            } : null,
        getPermissions,
    );

    const {
        data: labelData, isValidating: loading, mutate: labelMutate,
    } = useSWR(
        ((organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant)) ?
            {
                url: `${tagsUrl}/label`,
                pagination,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            } : null,
        getLabels,
    );

    const openDeleteDialog = useTagsDeleteDialog('Label', organizationName, tenantId, () => {
        labelMutate();
        createNotification(translate({ id: 'CLIENT_TENANT_TAGS_DELETE_SUCCESS' }), notificationType.SUCCESS);
    });

    const handleAddLabel = useCallback(() => {
        navigate(getRoute(process.buildConfigs.showForMSI
            ? `${RouteNames.TenantTagsLabelsMsi}/add`
            : `${RouteNames.TenantTagsLabels}/add`.replace(':tenantId', tenantId)));
    }, [ getRoute, navigate, tenantId ]);

    const handleDeleteLabel = useCallback((rows: ITagModelObject[] | undefined) => openDeleteDialog(rows ?? []), [ openDeleteDialog ]);

    const onChangePagination = useCallback(({
        pageIndex, pageSize, searchTerm,
    }: any /* REACT_18_TODO: should be IGridFilters, but its not exported */) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
        };

        setPagination(newPaginationState);

        if (tenant) {
            mutate({
                url: `${tagsUrl}/label`,
                pagination: newPaginationState,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            });
        }
    }, [ accountId, mutate, organizationName, tenant ]);

    return {
        organizationName,
        tenantName: tenant?.name,
        tenantId,
        isAdmin,
        openDeleteDialog,
        handleAddLabel,
        handleDeleteLabel,
        permissions,
        labelData,
        loading,
        refresh: labelMutate,
        onChangePagination,
    };
};

export default useTenantTagsLabelsViewModel;
