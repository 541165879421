import { ApButton } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

type IUiDeleteButtonProps = {
    loading?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    'data-cy': string;
};

export const UiDeleteButton: React.FC<IUiDeleteButtonProps> = ({
    loading, disabled = false, onClick, 'data-cy': dataCy,
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        <ApButton
            loading={loading}
            variant="destructive"
            aria-label={translate({ id: 'CLIENT_DELETE' })}
            disabled={loading ?? disabled}
            label={translate({ id: 'CLIENT_DELETE' })}
            onClick={onClick}
            data-cy={dataCy}
        />
    );
};
