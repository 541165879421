import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ITrialServices } from '@experiences/interfaces';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { ILicenseAndKey } from '../../common/interfaces/licenses';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../store/selectors';
import {
    get,
    post,
} from '../utility/Requests.default';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    getConsumables,
    licenseManagementAccountConsumablesUrl,
    licenseManagementAccountUrl,
} from './management/AccountService';

export const trialServicesSwrKey = 'trial-services';

export const subscriptionCodeToServiceName: { [id: string]: string } = {
    'TRIAL-AHUB': 'automationhub',
    'TRIAL-ANL': 'insights',
    'TRIAL-PM': 'processmining',
    'TRIAL-RU': 'orchestrator',
};

// ts-unused-exports:disable-next-line
export const allowedSubscriptionPlans = [ 'ENTERPRISE', 'PRO' ];

function getTrialServices() {
    return get<ITrialServices>(`${licenseManagementAccountUrl}/trial-services`);
}

export function getTrialPerSkuLicensesInGrace() {
    return get<string[]>(`${licenseManagementAccountUrl}/trial-per-sku-licenses-in-grace`);
}

export function requestTrialLicense(subscriptionCode: string) {
    return post(`${licenseManagementAccountUrl}/request-trial-license`, { body: { serviceSubscription: subscriptionCode } });
}

export function useTrialServiceTab() {
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);
    const enableConsumablesTab = useFeatureFlagValue(Features.EnableConsumablesTab.name);

    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );
    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);
    const { data: trialServicesResponse } = useSWR<ITrialServices, Error>(
        isAdmin ? trialServicesSwrKey : null,
        getTrialServices,
    );
    const trialServicesAreAvailable = useMemo(() => {
        if (!trialServicesResponse) {
            return false;
        }
        return trialServicesResponse.freeTrials?.length > 0 || trialServicesResponse.currentTrials?.length > 0;
    }, [ trialServicesResponse ]);

    const shouldSeeTrialServicesTab = useMemo(() => {
        if (!isAdmin) {
            return false;
        }

        if (!allowedSubscriptionPlans.includes(license?.subscriptionPlan ?? '')) {
            return false;
        }

        return trialServicesAreAvailable;
    }, [ isAdmin, license, trialServicesAreAvailable ]);

    const { data: consumablesToRender } = useSWR(
        enableConsumablesTab ? {
            url: licenseManagementAccountConsumablesUrl,
            accountGlobalId: accountId,
            includeTrialConsumables: true,
        } : null,
        getConsumables,
    );

    return {
        shouldSeeTrialServicesTab,
        trialServicesResponse,
        consumablesToRender,
    };
}
