import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type {
    IFile,
    IPagination,
} from '@experiences/interfaces';
import { useLocalization } from '@experiences/locales';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import {
    SpacingToken,
    UiStack,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    isBeforeGivenDate,
    timestampToDateTime,
    useNavigateWithParams,
    useUserReadableTime,
} from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApButton } from '@uipath/portal-shell-react';
import FileSaver from 'file-saver';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import type { IAuditLogResponse } from '../../services/audit/AuditService';
import {
    auditUrl,
    downloadAuditLogs,
    getAuditLogs,
} from '../../services/audit/AuditService';
import {
    accountCreatedOn,
    accountGlobalId,
    accountType,
    userGlobalId,
} from '../../store/selectors';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import {
    DEFAULT_PAGINATION,
    UiPaginatedGrid,
} from '../common/UiPaginatedGrid';
import { shouldRenderAlertForOldestPage } from '../common/UiPaginatedGrid/PaginationUtils';
import { UiRefreshButton } from '../common/UiRefreshButton';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import { AuditPreviewToggle } from './AuditPreviewToggle';
import type { IAuditLog } from './interfaces/auditLog';

const useStyles = makeStyles(() =>
    createStyles({ operationCell: { maxWidth: '334px' } }),
);

const AuditPageComponent: React.FC = () => {
    const classes = useStyles();
    const setErrorMessage = useCentralErrorSetter();

    const { getErrorMessage } = useGetErrorInfo();
    const { userReadableTime } = useUserReadableTime();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const navigate = useNavigateWithParams();
    const language = useLocalization();

    const accountId = useSelector(userGlobalId);
    const createdOn = useSelector(accountCreatedOn);
    const type = useSelector(accountType);
    const partitionGlobalId = useSelector(accountGlobalId);

    const [ isAlertDismissed, setIsAlertDismissed ] = useState(false);
    const [ downloadInProgress, setDownloadInProgress ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);
    const [ pagination, getPagination ] = useState<IPagination>({
        ...DEFAULT_PAGINATION,
        sortBy: 'CreatedOn',
        sortOrder: 'desc',
    });

    const { mutate } = useSWRConfig();

    const refreshClick = useCallback(() => {
        mutate({
            url: auditUrl,
            pagination,
            language,
            partitionGlobalId,
        });
    }, [ language, mutate, pagination, partitionGlobalId ]);

    const logInfo = useCallback(
        (message: string) => {
            portalTelemetry.trackTrace({
                message,
                severityLevel: SeverityLevel.Info,
                properties: { accountId },
            });
        },
        [ accountId ],
    );

    const saveAuditFile = useCallback(
        (file: IFile) => {
            // The '\ufeff' appended in front of the data allows Excel to process JA characters correctly
            // It will not affect processing of English characters
            const auditData = new Blob([ '\ufeff' + file.data ], { type: 'application/vnd.ms-excel;charset=utf-8' });
            FileSaver.saveAs(auditData, file.filename);
            logInfo('Successfully downloaded audit records');
        },
        [ logInfo ],
    );

    const downloadLogs = useCallback(async () => {
        try {
            if (!downloadInProgress) {
                logInfo('Sending request to server to download audit details');
                setDownloadInProgress(true);
                createNotification(translate({ id: 'CLIENT_DOWNLOAD_IN_PROGRESS' }), notificationType.INPROGRESS);
                const auditFile = await downloadAuditLogs(language, partitionGlobalId);
                saveAuditFile(auditFile);
            }
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        } finally {
            setDownloadInProgress(false);
        }
    }, [
        downloadInProgress,
        logInfo,
        createNotification,
        translate,
        language,
        partitionGlobalId,
        saveAuditFile,
        setErrorMessage,
        getErrorMessage,
    ]);

    const grid = useMemo(() => <UiPaginatedGrid<IAuditLog, IAuditLogResponse, { language: string; partitionGlobalId: string }>
        url={auditUrl}
        fetcher={getAuditLogs}
        fetcherArgs={{
            language,
            partitionGlobalId,
        }}
        data-cy="auditlogs-ui-grid"
        initialSort={[
            {
                id: 'CreatedOn',
                desc: true,
            },
        ]}
        setLoading={setLoading}
        getPagedNumber={getPagination}
        pagination
        hideRefresh
        alert={{
            message: translate({ id: 'CLIENT_AUDIT_LIST_END_ALERT' }),
            onClose: () => setIsAlertDismissed(true),
            condition: (p, s, count) =>
                !isAlertDismissed &&
                    [ 'ENTERPRISE', 'TRIAL' ].indexOf(type) > -1 && // accountType is ENTERPRISE or TRIAL
                    shouldRenderAlertForOldestPage(p, s, count) && // user is on last/oldest page
                    isBeforeGivenDate(createdOn, '2020-11-12'), // account creation date before 2020-11-12
        }}
        columns={[
            {
                accessor: 'category',
                Header: translate({ id: 'CLIENT_CATEGORY' }),
                width: 18,
                Cell: ({ row }) => <>
                    {row.original.category}
                </>,
                disableSortBy: true,
            },
            {
                accessor: 'userName',
                Header: translate({ id: 'CLIENT_USER' }),
                width: 20,
                disableSortBy: true,
                Cell: ({ row }) => <>
                    {row.original.userName || translate({ id: 'CLIENT_SYSTEM' })}
                </>,
            },
            {
                accessor: 'action',
                Header: translate({ id: 'CLIENT_ACTION' }),
                width: 15,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <Tooltip
                        arrow
                        title={row.original.action}>
                        <UiText>
                            {row.original.action}
                        </UiText>
                    </Tooltip>
                ),
            },
            {
                accessor: 'operation',
                Header: translate({ id: 'CLIENT_OPERATION' }),
                width: 30,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <Tooltip
                        arrow
                        title={row.original.message}>
                        <div className={classes.operationCell}>
                            {row.original.message}
                        </div>
                    </Tooltip>
                ),
            },
            {
                accessor: 'createdOn',
                sortName: 'CreatedOn',
                Header: translate({ id: 'CLIENT_TIME' }),
                width: 16,
                Cell: ({ row }) => (
                    <Tooltip
                        arrow
                        title={timestampToDateTime(row.original.createdOn, language)}>
                        <span>
                            {userReadableTime(row.original.createdOn)}
                        </span>
                    </Tooltip>
                ),
            },
        ]}
        rowActions={[
            {
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_OPEN' }),
                tooltip: translate({ id: 'CLIENT_OPEN' }),
                actionType: GridActionType.Row,
                icon: <InfoOutlinedIcon />,
                click: row => {
                    navigate(RouteNames.AuditLogDialog, { state: { data: row.original } });
                },
            },
        ]}
    />, [
        classes.operationCell,
        createdOn,
        isAlertDismissed,
        language,
        navigate,
        partitionGlobalId,
        translate,
        type,
        userReadableTime,
    ]);

    const headerButtons = useMemo(() =>
        <UiStack
            direction="row"
            gap={SpacingToken.XS}
            align="center">
            <UiRefreshButton
                loading={isLoading}
                onClick={refreshClick} />
            <ApButton
                size='small'
                variant="primary"
                onClick={() => downloadLogs()}
                loading={downloadInProgress}
                data-cy="ui-grid-primary-action-button"
                label={translate({ id: 'CLIENT_DOWNLOAD' })} />
            <AuditPreviewToggle />
        </UiStack>,
    [ downloadInProgress, downloadLogs, isLoading, refreshClick, translate ]);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            position='left'
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            primaryActions={headerButtons}
        >
            {grid}
        </UiPageContainer>
    );
};

const AuditPageComponentLegacy: React.FC = () => (
    <>
        <AuditPageComponent />
        <UiSuspensefulOutlet />
    </>
);

export const AuditPageComponentLegacyWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.AuditLogs,
            name: translate({ id: 'CLIENT_AUDIT_LOG' }),
        },
    ], [ organizationName, translate ]);

    return (
        <BreadcrumbProvider
            breadcrumbs={breadCrumbLinks}
            legacy>
            <AuditPageComponentLegacy />
        </BreadcrumbProvider>
    );
};

export default AuditPageComponentLegacy;
