import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApButton } from '@uipath/portal-shell-react';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteTenantConfirmation: { maxWidth: '417px' },
        deleteText: { color: theme.palette.semantic.colorErrorText },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmDeleteSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const TenantDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, tenant, tenantDelete,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const [ textFieldValue, setTextFieldValue ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
    }, []);

    const deleteAndClose = useCallback(async () => {
        setLoading(true);

        try {
            await tenantDelete(tenant);
            closeDialog(true);
        } catch (error) {
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        }
    }, [ tenantDelete, tenant, closeDialog, getErrorObject, getErrorMessage, setErrorMessage ]);

    return (
        <>
            <UiText
                className={classes.deleteTenantConfirmation}
                data-cy="delete-confirmation-notes">
                {translate({ id: 'CLIENT_TENANT_SETTINGS_DELETE_DIALOG_REMINDER' }, { 0: tenant.name })}
            </UiText>
            <div className={classes.confirmDeleteSection}>
                <UiText data-cy="delete-confirmation-type-reminder">
                    <FormattedMessage
                        id="CLIENT_TENANT_DELETE"
                        values={{
                            strong: (chunk: React.ReactNode[]) => <strong>
                                {chunk}
                            </strong>,
                            0: <span className={classes.deleteText}>
                                {tenant.name}
                            </span>,
                        }}
                    />
                </UiText>
                <TextField
                    className={classes.textField}
                    autoComplete="off"
                    variant="outlined"
                    id="standard-name"
                    onChange={handleChange}
                    value={textFieldValue}
                    placeholder={translate({ id: 'CLIENT_TENANT_DELETE_CONFIRMATION_PLACEHOLDER' })}
                    data-cy="delete-confirmation-textfield"
                />
            </div>
            <div className={classes.buttonContainer}>
                <ApButton
                    key="cancelButton"
                    className={classes.cancelButton}
                    onClick={() => closeDialog()}
                    variant='tertiary'
                    data-cy="secondary-button-confirmation"
                    label={translate({ id: 'CLIENT_CANCEL' })}
                />
                <ApButton
                    loading={loading}
                    key="primaryButton"
                    variant='destructive'
                    data-cy='primary-button-confirmation'
                    onClick={() => deleteAndClose()}
                    disabled={textFieldValue !== tenant.name}
                    label={translate({ id: 'CLIENT_DELETE' })}
                />
            </div>
        </>
    );
};

export default TenantDeleteDialogBody;
