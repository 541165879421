import { UiText } from '@experiences/ui-common';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import type { SxProps } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

import { TenantStatusConstants } from '../../tenants/TenantConstants';

const useStyles = makeStyles(theme =>
    createStyles({
        card: { position: 'relative' },
        cardContent: {
            padding: '16px',
            '&:last-child': { paddingBottom: '36px' },
        },
        cardClickable: { '&:hover': { cursor: 'pointer' } },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '16px',
        },
        titleText: {
            marginLeft: '12px',
            fontWeight: 600,
            fontSize: '16px',
        },
        descriptionText: {
            fontWeight: 400,
            fontSize: '12px',
        },
        chip: {
            marginLeft: '12px',
            fontSize: '12px',
            fontWeight: 600,
        },
        disabledChip: {
            backgroundColor: `${theme.palette.semantic.colorBackgroundDisabled} !important`,
            color: `${theme.palette.semantic.colorForeground} !important`,
        },
        errorChip: {
            backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            color: `${theme.palette.semantic.colorErrorText} !important`,
        },
        errorText: { color: theme.palette.semantic.colorErrorText },
        errorBorder: { border: `1px solid ${theme.palette.semantic.colorErrorText} !important` },
        loadingText: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 600,
            marginLeft: '12px',
        },
        cardAction: {
            display: 'flex',
            justifyItems: 'space-between',
        },
        actions: {
            position: 'absolute',
            bottom: '12px',
            right: '5px',
            height: '32px',
        },
        statusChip: {
            position: 'absolute',
            bottom: '12px',
            left: '0px',
            height: '24px',
            verticalAlign: 'middle',
        },
        disabled: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            border: `1px solid ${theme.palette.semantic.colorBorderDisabled}`,
        },
        disabledText: { color: theme.palette.semantic.colorForegroundDisable },
    }),
);

export const UiServiceCard: React.FC<{
    title: string;
    titleIcon: ReactElement<any, any>;
    description: string;
    status: string;
    dataCy: string;
    actions: ReactElement<any, any>;
    retry?: React.ReactNode;
    sx?: SxProps;
    style?: React.CSSProperties;
    link: string;
}> = ({
    title, titleIcon, description, status, dataCy, actions, retry, sx, style, link,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <Card
            className={clsx({
                [classes.card]: true,
                [classes.errorBorder]: status === TenantStatusConstants.FAILED,
                [classes.disabled]: status === TenantStatusConstants.DISABLED,
            })}
            sx={sx}
            style={style}
            data-cy={dataCy}
            aria-label="ui-service-card">
            <CardContent
                classes={{
                    root: clsx({
                        [classes.cardContent]: true,
                        [classes.cardClickable]: status === TenantStatusConstants.ENABLED,
                    }),
                }}
                onClick={() => status === TenantStatusConstants.ENABLED && window.location.assign(link)}
                data-cy="service-card-content">
                <div className={classes.titleSection}>
                    {titleIcon}
                    <div>
                        <UiText
                            className={clsx({
                                [classes.titleText]: true,
                                [classes.errorText]: status === TenantStatusConstants.FAILED,
                                [classes.disabledText]: status === TenantStatusConstants.DISABLED,
                            })}>
                            {title}
                        </UiText>
                    </div>
                </div>
                <UiText className={clsx({
                    [classes.descriptionText]: true,
                    [classes.disabledText]: status === TenantStatusConstants.DISABLED,
                })}>
                    {description}
                </UiText>
            </CardContent>
            <CardActions className={classes.cardAction}>
                <div className={classes.statusChip}>
                    {(status === TenantStatusConstants.DISABLED || status === TenantStatusConstants.FAILED)
                        && <Chip
                            size='small'
                            clickable={false}
                            className={clsx({
                                [classes.chip]: true,
                                [classes.disabledChip]: status === TenantStatusConstants.DISABLED,
                                [classes.errorChip]: status === TenantStatusConstants.FAILED,
                            })}
                            label={translate({ id: `CLIENT_${status}` })}
                            data-cy='service-card-status-chip' />}
                    {status === TenantStatusConstants.FAILED && retry}
                    {(status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                        || status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS
                        || status === TenantStatusConstants.UPDATING)
                        && <UiText className={classes.loadingText}>
                            <CircularProgress
                                size={20}
                                style={{ marginRight: '8px' }} />
                            {translate({ id: `CLIENT_${status}` })}
                        </UiText>}
                </div>
                <div className={classes.actions}>
                    {actions}
                </div>
            </CardActions>
        </Card>
    );
};
