import {
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../common/constants/RouteNames';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import { UiPanel } from '../common/UiPanel/UiPanel';
import OrganizationGridComponent from './subcomponents/OrganizationGridComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        headerTitle: {
            display: 'flex',
            alignItems: 'center',
        },
        azureChip: {
            marginLeft: '8px',
            color: theme.palette.getContrastText(theme.palette.semantic.colorForegroundDeEmp),
            backgroundColor: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const OrganizationPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const handleAddOrganization = useCallback(() => {
        navigate(`${getRoute(RouteNames.Organizations)}/add`);
    }, [ getRoute, navigate ]);

    return (
        <UiPanel
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            header={{
                title: (
                    <div className={classes.headerTitle}>
                        <UiText
                            style={{
                                fontWeight: 600,
                                fontSize: '18px',
                                lineHeight: '35px',
                                padding: '3px 0',
                            }}
                            role="heading"
                            aria-level={1}>
                            {translate({ id: 'CLIENT_ORGANIZATION' })}
                        </UiText>
                    </div>
                ),
                actions: (
                    <Button
                        variant="contained"
                        onClick={handleAddOrganization}
                        data-cy="ui-grid-primary-action-button">
                        {translate({ id: 'CLIENT_ADD_ORGANIZATION' })}
                    </Button>
                ),
            }}
            data-cy="organization-panel"
        >
            <OrganizationGridComponent />
        </UiPanel>
    );
};

export default () => (
    <>
        <OrganizationPageComponent />
        <UiSuspensefulOutlet />
    </>
);
