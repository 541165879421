import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    matchPath,
    useLocation,
} from 'react-router-dom';

import {
    PortalAdminPrefix,
    PortalPrefix,
} from '../common/constants/RouteNames';
import {
    HideTenantShellRoutes,
    SettingsRoutes,
} from '../component/container/NavRoutes.default';
import { HostSettingsRoutes } from '../component/container/NavRoutesHost.default';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../store/selectors';

export function useSettingsRoutes() {
    const isHostMode = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);

    return (isHostMode && HostSettingsRoutes) || (isAdmin && SettingsRoutes) || [];
}

export function useIsSettingsRouteMatched() {
    const { pathname } = useLocation();
    const settingsRoutes = useSettingsRoutes();

    return useMemo(
        () =>
            settingsRoutes.some(route => {
                if (matchPath(`${PortalAdminPrefix}/*`, pathname)) {
                    return true;
                } else if (matchPath(route.path, pathname)) {
                    return true;
                }
                return route.subRoutes?.some(subRoute => matchPath(`${subRoute}/*`, pathname));
            }),
        [ pathname, settingsRoutes ],
    );
}

export function useShouldHideTenantShell() {
    const { pathname } = useLocation();

    return useMemo(
        () =>
            HideTenantShellRoutes.some(route => !!matchPath(route, pathname)
                || matchPath(`${PortalPrefix}${route}`, pathname)
            ),
        [ pathname ],
    );
}
