import { useCentralErrorSetter } from '@experiences/error';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IAADConfigData } from '../../../common/interfaces/externalIdentity';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultEditIdentityProviderData,
    mapAADConfigDataToExternalIdentityPayload,
    mapExternalIdentityPayloadToAADConfigData,
} from '../../../util/ExternalIdentityProviderUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import EditClientIdAndSecretFormComponent, { defaultEditClientIdAndSecretData } from './EditClientIdAndSecretFormComponent';
import EditIdentityProviderFormComponent from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(() =>
    createStyles({
        section: { marginTop: 20 },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const ConfigureAADComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);

    const { close } = useModalState(RouteNames.SecuritySettings);

    const classes = useStyles();
    const setErrorDialog = useCentralErrorSetter();
    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const [ showClientSecretPlaceholder, setShowClientSecretPlaceholder ] = useState(false);
    const methods = useForm<IAADConfigData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditIdentityProviderData,
            ...defaultEditClientIdAndSecretData,
            authority: '',
            logoutUrl: '',
        },
    });

    const {
        handleSubmit, formState: {
            errors, isSubmitting, isDirty,
        }, reset, control,
    } = methods;

    const fetchedExternalIdentity = useExternalIdentity(ExternalAuthenticationScheme.AzureAD);

    const onSubmit = useCallback(
        async (data: IAADConfigData) => {
            const payload = mapAADConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            try {
                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_IDP_SAVED_SUCCESSFULLY' }, { idp: 'Azure Active Directory' }),
                    body: <UiText>
                        {translate({ id: 'CLIENT_SUCCESSFULLY_SAVED' }, { idp: 'Azure Active Directory' })}
                    </UiText>,
                    icon: 'info',
                    showCancel: true,
                    primaryButtonText: translate({ id: 'CLIENT_ENABLE_NOW' }),
                    cancelButtonText: translate({ id: 'CLIENT_ENABLE_LATER' }),
                });
                payload.isActive = proceed;
                if (fetchedExternalIdentity) {
                    await updateExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });
                } else {
                    await createExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });
                }
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }), notificationType.SUCCESS);
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_AAD_GENERIC_ERROR' }));
            }
        },
        [
            fetchedExternalIdentity,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            createDialog,
            partitionGlobalId,
            setErrorDialog,
        ],
    );

    useEffect(() => {
        if (fetchedExternalIdentity) {
            reset(mapExternalIdentityPayloadToAADConfigData(fetchedExternalIdentity));
            setShowClientSecretPlaceholder(true);
        }
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_AZURE_AD_SSO_CONFIGURATION' }),
        },
    ], [ organizationName, translate ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            maxWidth="900px"
            position='center'>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="configure-aad-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                centerChild
            >
                <FormProvider {...methods}>
                    <EditIdentityProviderFormComponent />
                    <EditClientIdAndSecretFormComponent showClientSecretPlaceholder={showClientSecretPlaceholder} />
                    <div className={classes.section}>
                        <Controller
                            name="authority"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_AUTHORITY' })}
                                    required
                                    type="url"
                                    error={!!errors.authority}
                                    helperText={errors.authority?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    data-cy="configure-aad-authority"
                                    InputLabelProps={{ id: 'authorityLabel' }}
                                    inputProps={{ 'aria-labelledby': 'authorityLabel' }}
                                />
                            )}
                        />
                    </div>
                    <div className={classes.section}>
                        <Controller
                            name="logoutUrl"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_LOGOUT_URL' })}
                                    type="url"
                                    error={!!errors.logoutUrl}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    data-cy="configure-aad-logout-url"
                                    InputLabelProps={{ id: 'logoutUrlLabel' }}
                                    inputProps={{ 'aria-labelledby': 'logoutUrlLabel' }}
                                />
                            )}
                        />
                    </div>
                </FormProvider>
            </UiForm>
        </UiPageContainer>
    );
};

export default ConfigureAADComponent;
