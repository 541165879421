import type {
    CallbackState,
    User,
} from '@uipath/auth-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import setLastUsedOrganizationId from '../callback/LastUsedOrganizationId';
import setSignInCookie from '../callback/SignInCookie';
import useGetAuthProvider from './GetAuthProvider';

export default function useAuthCallback() {
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();

    const getAuthType = useGetAuthProvider();

    return useCallback(async (user: User | void) => {
        let redirect_uri = '/portal_/cloudrpa';

        if (user) {
            const loginMethod = await getAuthType(user.access_token);

            if (process.buildConfigs.enableAuthCallbackOps) {
                loginMethod && setSignInCookie(user, loginMethod);
                setLastUsedOrganizationId(user as User);
            }

            redirect_uri = (user.state as CallbackState)?.returnTo;
        }

        navigate(redirect_uri);
    }, []);
}
