import {
    UiAlertBanner,
    UiText,
} from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    billingUrl,
    fetchHistoryStatus,
} from '../../../services/licensing/BillingService';
import {
    accountLogicalName,
    isAdminSelector,
} from '../../../store/selectors';

const useStyles = makeStyles(() =>
    createStyles({
        bannerContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        bannerMessage: {

            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

export const EcommerceLicensePaymentPendingBanner: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const currentAccountName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);

    const { data: ecommerceHistoryStatus } = useSWR(
        {
            accountName: currentAccountName,
            url: `${billingUrl}/historyStatus`,
        },
        fetchHistoryStatus,
    );

    const shouldShowBanner = ecommerceHistoryStatus?.firstPaymentStatus === 'PAYMENT_OPEN'
        || ecommerceHistoryStatus?.incrementalPaymentStatus === 'PAYMENT_OPEN';
    const bannerMessage = () => {
        if (ecommerceHistoryStatus?.firstPaymentStatus === 'PAYMENT_OPEN') {
            return 'CLIENT_ECOMMERCE_PAYMENT_PENDING_WARNING_BANNER';
        }
        if (ecommerceHistoryStatus?.incrementalPaymentStatus === 'PAYMENT_OPEN') {
            return 'CLIENT_ECOMMERCE_INCREMENTAL_PAYMENT_PENDING_WARNING_BANNER';
        }
    };

    return (
        <>
            {
                (isAdmin && shouldShowBanner) &&
                    <div data-cy="ui-license-payment-pending-banner">
                        <UiAlertBanner
                            type="info"
                            closeable={false}>
                            <div className={classes.bannerContent}>
                                <div className={classes.bannerMessage}>
                                    <UiText>
                                        {translate(
                                            { id: bannerMessage() })}
                                    </UiText>
                                </div>
                            </div>
                        </UiAlertBanner>
                    </div>
            }
        </>
    );
};
