import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import CarouselItems from './CarouselItems';
import type { CarouselSlideProps } from './types/CarouselSlideProps';

const useStyles = makeStyles((theme) =>
    createStyles({
        carouselSlideItems: {
            flex: 2,
            minWidth: '0px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            justifyContent: 'space-between',
            paddingBottom: '28px',
            marginRight: '32px',
            marginLeft: '32px',
        },
        bottomLeftBgImageContainer: {
            flex: 1,
            flexBasis: '0',
            minWidth: '0',
            position: 'relative',
            display: 'flex',
            maxHeight: '200px',
        },
        bottomLeftImage: {
            maxWidth: '100%',
            maxHeight: '100%',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
        },
        '@media all and (max-width: 1150px)': { bottomLeftBgImageContainer: { display: 'none' } },
    }),
);

const CarouselSlideBottomLeftImageStyle = ({
    lineImage,
    title,
    description,
    backgroundImage,
    buttonText,
    buttonRedirectUrl,
    buttonType,
    setOpen,
    idx,
    currSlide,
}: CarouselSlideProps) => {
    const classes = useStyles();
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
            }}
            data-cy={`carousel-slide-${idx}`}
        >
            {backgroundImage && <div
                className={classes.bottomLeftBgImageContainer}
                data-cy={`bottom-left-img-${idx}`}
            >
                <img
                    alt="" // decorative image no alt text needed
                    src={backgroundImage}
                    className={classes.bottomLeftImage}
                />
            </div>}
            <div
                className={classes.carouselSlideItems}
                data-cy={`carousel-items-secondary-${idx}`}
            >
                <CarouselItems
                    title={title}
                    description={description}
                    lineImage={lineImage}
                    buttonText={buttonText}
                    buttonType={buttonType}
                    buttonRedirectUrl={buttonRedirectUrl}
                    idx={idx}
                    currSlide={currSlide}
                    setOpen={setOpen}
                />
            </div>
        </div>
    );
};

export default CarouselSlideBottomLeftImageStyle;
