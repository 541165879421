import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getPriceString } from '../helpers/EcommerceHelpers';
import type {
    IPackage,
    IProductPriceOptions,
} from '../interfaces/ecommerce';
import { EcommerceCurrencyDropdown } from './EcommerceCurrencyDropdown';
import { EcommerceProductQuantityInput } from './EcommerceProductQuantityInput';
import EcommerceProductTableCell from './EcommerceProductTableCell';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        productsTable: { height: 'min-content' },
        productsTableTitle: {
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            textAlign: 'left',
            paddingBottom: '30px',
        },
        columnsTitle: {
            fontSize: '16px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            textAlign: 'left',
        },
        headerRow: {
            height: '50px',
            alignContent: 'center',
        },
        headerColumn: { display: 'flex' },
        productDescriptionCell: {
            display: 'flex',
            paddingBottom: '30px',
            paddingRight: '4px',
        },
        priceCell: {
            fontSize: '16px',
            fontWeight: '600',
        },
        circleIcon: {
            width: '28px',
            height: '28px',
            minWidth: '28px',
            minHeight: '28px',
            backgroundColor: theme.palette.semantic.colorPrimary,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '7px',
            paddingTop: '3px',
        },
        circleNumber: {
            color: theme.palette.semantic.colorForegroundInverse,
            fontWeight: '600',
            fontSize: '12px',
            textAlign: 'center',
        },
        qtyText: {
            paddingLeft: '10px',
            paddingBottom: '30px',
        },
    }),
}));

export const EcommerceProductsTable: React.FC<{
    productsToRender: IProductPriceOptions[];
    tableTitleTranslationId: string;
    useFormMethods: UseFormReturn<any>;
    updatePlanSummary: () => void;
    customPlanPackage: IPackage;
    selectedCurrency: string;
    setSelectedCurrency: (value: (((prevState: string) => string) | string)) => void;
    checkPlusInput: ((productCode: string) => boolean);
    isMonthlyPlanType: boolean;
    showCurrencyDropdown?: boolean;
    showNumbers?: boolean;
    planType: string;
}> = ({
    productsToRender,
    tableTitleTranslationId,
    useFormMethods,
    updatePlanSummary,
    customPlanPackage,
    selectedCurrency,
    setSelectedCurrency,
    checkPlusInput,
    isMonthlyPlanType,
    showCurrencyDropdown,
    showNumbers,
    planType,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();

    return (<table className={classes.productsTable}>
        <colgroup>
            <col
                span={1}
                width="70%"
            />
            <col
                span={1}
                width="15%"
            />
            <col
                span={1}
                width="15%"
            />
        </colgroup>
        <tbody>
            <tr className={classes.headerRow}>
                <th
                    id="header_product_details"
                    scope="col"
                    data-cy="ecommerce-product-table-title"
                >
                    <UiText
                        className={classes.productsTableTitle}
                        variant={FontVariantToken.fontSizeH2}
                    >
                        {translate({ id: tableTitleTranslationId })}
                    </UiText>
                </th>
                <th
                    id="header_product_price"
                    scope="col"
                    className={classes.headerColumn}>
                    <UiText className={classes.columnsTitle}>
                        {translate({ id: isMonthlyPlanType ? 'CLIENT_ECOMMERCE_TABLE_PRICE_MO' : 'CLIENT_ECOMMERCE_TABLE_PRICE_YEAR' })}
                    </UiText>
                    {showCurrencyDropdown && <EcommerceCurrencyDropdown
                        selectedCurrency={selectedCurrency}
                        setSelectedCurrency={setSelectedCurrency}
                        productsPricesInAllCurrencies={productsToRender} />}
                </th>
                <th
                    className={classes.qtyText}
                    id="header_product_quantity"
                    scope="col">
                    <UiText className={classes.columnsTitle}>
                        {translate({ id: 'CLIENT_ECOMMERCE_TABLE_QTY' })}
                    </UiText>
                </th>
            </tr>

            {productsToRender.map((product, index) =>
                (
                    <tr key={`ROW_${product.code}`}>
                        <td
                            className={classes.productDescriptionCell}
                            headers="header_product_details"
                            id={`header_product_${product.code}`}>
                            {showNumbers && (<div className={classes.circleIcon}>
                                <UiText className={classes.circleNumber}>
                                    {index + 1}
                                </UiText>
                            </div>)}
                            <EcommerceProductTableCell
                                productCode={product.code}
                                planType={planType} />
                        </td>
                        <td
                            className={classes.priceCell}
                            headers={`header_product_${product.code} header_product_price`} >
                            {getPriceString(product.prices[selectedCurrency], selectedCurrency, language)}
                            <UiText>
                                {translate({
                                    id: product.code !== 'RU' ?
                                        'CLIENT_ECOMMERCE_MEASURE_PRORATED' :
                                        'CLIENT_ECOMMERCE_MEASURE_NON_PRORATED',
                                })}
                            </UiText>
                        </td>
                        <td headers={`header_product_${product.code} header_product_quantity`}>
                            <EcommerceProductQuantityInput
                                productCode={product.code}
                                customSkuPackage={customPlanPackage ?? {} as IPackage}
                                loading={false}
                                useFormMethods={useFormMethods}
                                onChangeCallback={updatePlanSummary}
                                isBasicInput={checkPlusInput(product.code)}
                            />
                        </td>
                    </tr>
                ))}
        </tbody>
    </table>
    );
};
