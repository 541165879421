import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { useNavigateWithParams } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import AllocateLicenseIcon from '../../../images/icons/AllocateLicenseIcon';
import {
    getLicenseInheritance,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import { UiDrawer } from '../../common/UiDrawer';
import type { IGroupInheritance } from '../../users/interfaces/userLicense';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        drawer: {
            margin: '0px 24px',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 100%',
        },
        title: {
            fontWeight: 'bold',
            marginTop: '16px',
            marginBottom: '4px',
            color: theme.palette.semantic.colorForeground,
        },
        userBundleNames: {
            display: 'flex',
            marginTop: '8px',
            alignItems: 'start',
        },
        subLabel: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '2px',
        },
        iconAdjusted: {
            marginRight: '8px',
            height: 'auto',
            width: '20px',
        },
        backButton: {
            marginTop: '40px',
            alignSelf: 'flex-end',
        },
    }),
}));

const InheritedLicenseComponent: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const { formatMessage: translate } = useIntl();
    const { state } = useLocation();

    const userId = useMemo(() => state?.userId!, [ state ]);

    const { data: licenseInheritance } = useSWR(
        {
            url: `${userLicenseUrl}/licenseInheritance/${userId}`,
            userId,
        },
        getLicenseInheritance,
    );

    const [ currentLicenses, allAllocationRules ] = useMemo(
        () => [ licenseInheritance?.currentlyUsedLicense, licenseInheritance?.allGroupLicenseAllocationRules ],
        [ licenseInheritance ],
    );

    useEffect(() => {
        if (!userId) {
            console.warn('Missing data. Closing...');
            navigate('/invalidurl');
        }
    }, [ navigate, userId ]);

    const close = useCallback(() => {
        navigate(-1);
    }, [ navigate ]);

    const licenseAllocations = useCallback(
        (licenses: IGroupInheritance[]) => licenses.map((groupAllocation, i) => (
            <div
                className={classes.userBundleNames}
                key={i}>
                <AllocateLicenseIcon
                    className={clsx(classes.icon, classes.iconAdjusted)}
                    withCustomStyle />
                <div>
                    <UiText data-cy="license-group-allocation">
                        {groupAllocation.useExternalLicense
                            ? translate({ id: `CLIENT_GROUPS_EXTERNAL_LICENSE` })
                            : groupAllocation.userBundleCodes
                                .map(userBundleCode => translate({ id: `CLIENT_${userBundleCode}` }))
                                .join(', ')}
                    </UiText>
                    <UiText className={classes.subLabel}>
                        {translate({ id: 'CLIENT_FROM_GROUP' }, { 0: groupAllocation.group })}
                    </UiText>
                </div>
            </div>
        )),
        [ classes, translate ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_LICENSE_INHERITANCE' })}
            drawerProps={{
                anchor: 'right',
                open: true,
                onClose: () => close(),
                transitionDuration: 0,
            }}
            loading={!licenseInheritance}
        >
            <div className={classes.drawer}>
                <UiText className={classes.title}>
                    {translate({ id: 'CLIENT_CURRENT' })}
                </UiText>
                {currentLicenses && currentLicenses.length > 0 ? (
                    licenseAllocations(currentLicenses)
                ) : (
                    <UiText className={classes.subLabel}>
                        {translate({ id: 'CLIENT_NO_CURRENT_LICENSE' })}
                    </UiText>
                )}
                <UiText className={classes.title}>
                    {translate({ id: 'CLIENT_ALL_LICENSE_ALLOCATION_RULES' })}
                </UiText>
                {allAllocationRules && allAllocationRules.length > 0 ? (
                    licenseAllocations(allAllocationRules)
                ) : (
                    <UiText className={classes.subLabel}>
                        {translate({ id: 'CLIENT_NO_ALLOCATION_RULES' })}
                    </UiText>
                )}
                <Button
                    className={classes.backButton}
                    onClick={() => {
                        close();
                    }}
                    variant="outlined"
                    data-cy="back-button"
                >
                    {translate({ id: 'CLIENT_BACK' })}
                </Button>
            </div>
        </UiDrawer>
    );
};

export default InheritedLicenseComponent;
