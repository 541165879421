import type { Card } from '@experiences/interfaces';
import {
    BorderLinearProgress,
    UiText,
} from '@experiences/ui-common';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

export interface UiAllocationCardProps<T> {
    card: Card<T>;
    id: string;
    cardIndex: number;
}

export const UiAllocationCard = <T extends {}>({
    card, id, cardIndex,
}: UiAllocationCardProps<T>) => {
    const {
        formatNumber, formatMessage: translate,
    } = useIntl();
    const theme = useTheme();
    const isOverallocated = useCallback((c: Card<T>): boolean => {
        if (c.allocated !== undefined && c.total !== undefined) {
            return c.allocated > c.total;
        }

        return false;
    }, []);

    if (card.allocated === undefined || card.total === undefined || card.subtitle === undefined) {
        return null;
    }

    return (
        <Box
            data-testid={`${id}-alloc-card-${cardIndex}`}
            sx={{
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: '80px',
                padding: '8px',
                borderColor: 'semantic.colorBorderDeEmp',
            }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                height: '100%',
            }}>
                <UiText
                    id={`${id}-alloc-card-header-${cardIndex}`}
                    style={{ fontSize: '16px' }}
                    data-testid={`${id}-alloc-card-header-${cardIndex}`}
                    data-cy="usage-chart-headers">
                    {translate({ id: `CLIENT_USAGE_HEADER_${card.subtitle}` })}
                </UiText>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Box
                        sx={{ flex: '1 0 75%' }}
                        data-testid={`${id}-alloc-card-progressbar-${cardIndex}`}
                        data-cy="usage-chart-linear-progress-bar">
                        <BorderLinearProgress
                            consumed={card.allocated}
                            total={card.total}
                            ariaLabelledBy={`${id}-alloc-card-header-${cardIndex}`}
                            ariaDescribedBy={isOverallocated(card) ? `usage-chart-over-allocation-${card.subtitle.toLowerCase()}` : undefined} />
                        <UiText
                            style={{ color: theme.palette.semantic.colorForegroundDeEmp }}
                            data-testid={`${id}-large-card-subtitle-${cardIndex}`} >
                            {card.subtitle === 'CV' &&
                                translate({ id: `CLIENT_USAGE_DESCRIPTION_${card.subtitle}` })}
                        </UiText>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        flex: '1 0 25%',
                        marginLeft: '17px',
                    }}>
                        <UiText
                            style={{
                                fontSize: '16px',
                                verticalAlign: 'bottom',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            data-testid={`${id}-alloc-card-allocated-${cardIndex}`}
                            data-cy="usage-chart-allocated-of-total">
                            <span>
                                {translate(
                                    { id: 'CLIENT_ALLOCATED_OF_TOTAL' },
                                    {
                                        0: formatNumber(card.allocated),
                                        1: formatNumber(card.total, { notation: 'compact' }),
                                    },
                                )}
                            </span>
                            {isOverallocated(card) && (
                                <Tooltip
                                    title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                    aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                >
                                    <WarningIcon
                                        sx={{
                                            color: 'semantic.colorWarningIcon',
                                            marginLeft: '8px',
                                            fontSize: '24px',
                                        }}
                                        id={`usage-chart-over-allocation-${card.subtitle.toLowerCase()}`}
                                        data-testid={`${id}-alloc-card-warn-${cardIndex}`}
                                        data-cy="usage-chart-over-allocation"
                                        tabIndex={0}
                                    />
                                </Tooltip>
                            )}
                        </UiText>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
};
