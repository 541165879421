export interface EncryptionFormData {
    AADTenantId: string;
    clientId: string;
    clientSecret: string;
    keyIdentifier: string;
}

export enum EncryptionType {
    UIPATH = 'uipath',
    CUSTOMER = 'customer',
}
