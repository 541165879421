import type {
    IOrchestratorUser,
    Runs,
} from '@experiences/interfaces';
import { get } from '@experiences/util';

import { getCurrentOrchestratorUser } from './getCurrentOrchestratorUser';

export const processesUrl = `/api/orchestrator/processes`;

export async function getProcessesRecentRuns(
    accessToken: string,
    tenantName: string,
    accountLogicalName: string,
) {
    const user: IOrchestratorUser = await getCurrentOrchestratorUser(accessToken, tenantName);
    return get<Runs[]>(`${processesUrl}/recentRuns`, {
        accessToken,
        urlParams: {
            accountLogicalName,
            tenantName,
            currentOrchestratorId: user.Id,
        },
    });
}
