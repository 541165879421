import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        dialog: { minWidth: '550px' },
        button: { marginLeft: '10px' },
        buttonContainer: {
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

const TenantTagsSaveWarningDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.dialog}>
            <UiText>
                {translate({ id: 'CLIENT_TENANT_TAGS_OBJECTS_AFFECTED_BY_EDIT_BODY' })}
            </UiText>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    color="primary"
                    data-cy="tenant-tags-save-warning-cancel-button"
                    onClick={() => closeDialog(false)}
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <Button
                    className={classes.button}
                    data-cy="tenant-tags-save-warning-confirmation-button"
                    onClick={() => closeDialog(true)}
                    variant="contained"
                >
                    {translate({ id: 'CLIENT_EDIT' })}
                </Button>
            </div>
        </div>
    );
};

export default TenantTagsSaveWarningDialogBody;
