import { getFullName } from '@experiences/util';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    firstNameSelector,
    isAdminSelector,
    isHostModeSelector,
    lastNameSelector,
} from '../../store/selectors';

export const useGetFullName = () => {
    const { formatMessage: translate } = useIntl();

    const isHost = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);
    const firstName = useSelector(firstNameSelector);
    const lastName = useSelector(lastNameSelector);

    const name = getFullName(firstName, lastName);
    if (name) {
        return name;
    }

    if (isHost) {
        return translate({ id: 'CLIENT_SYSTEM_ADMINISTRATOR' });
    }

    if (isAdmin) {
        return translate({ id: 'CLIENT_ADMIN' });
    }

    return translate({ id: 'CLIENT_USER' });
};
