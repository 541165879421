import type {
    IGridResource,
    IResource,
} from '@experiences/interfaces';
import { ExternalApiResourceType } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useGetTranslatedScopeDescription } from '../../../util/ScopeDescriptionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        groupTextSmall: {
            display: 'flex',
            fontSize: '12px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '40px',
        },
        selectAllText: { marginTop: '-5px' },
        scopesCheckbox: {
            alignSelf: 'flex-start',
            marginTop: '-9px',
        },
    }),
);

export interface IResourceForm {
    name: IResource | IGridResource | undefined;
    userScopes: string[];
    applicationScopes: string[];
}

const ScopeController: React.FC<{
    name: 'userScopes' | 'applicationScopes';
    resource?: IGridResource;
    selectedResource?: IResource;
    scopeSearch: string;
}> = ({
    name, resource, selectedResource, scopeSearch,
}) => {
    const classes = useStyles();

    const {
        control, watch,
    } = useFormContext<IResourceForm>();
    const { formatMessage: translate } = useIntl();

    const allScopes: string[] = useMemo(() => selectedResource?.scopes.map(scope => scope.name) ?? [], [ selectedResource?.scopes ]);

    const nameValue = watch(name);

    const getTranslatedScopeDescription = useGetTranslatedScopeDescription();

    const handleCheck = useCallback(
        (checkedId: string) => {
            const ids = nameValue;
            return ids.indexOf(checkedId) > -1 ? ids.filter(id => id !== checkedId) : [ ...(ids ?? []), checkedId ];
        },
        [ nameValue ],
    );

    const handleSelectAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked ? allScopes : [],
        [ allScopes ],
    );

    const getSelectAllComponent = useCallback(
        (onChange: Function) => (
            <FormControlLabel
                key="selectAll"
                control={
                    <Checkbox
                        className={classes.scopesCheckbox}
                        data-cy="resource-select-option"
                        size="small"
                        checked={nameValue.length > 0 && allScopes.length === nameValue.length}
                        indeterminate={nameValue.length > 0 && allScopes.length !== nameValue.length}
                        onChange={e => onChange(handleSelectAll(e))}
                    />
                }
                label={
                    <div>
                        <UiText className={clsx(classes.groupText, classes.selectAllText)}>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPES_SELECTALL_DISPLAYNAME' })}
                        </UiText>
                    </div>
                }
                data-cy="resource-form-control"
            />
        ),
        [
            classes,
            nameValue.length,
            allScopes.length,
            translate,
            handleSelectAll,
        ],
    );

    const getScopesComponentList = useCallback(
        (selectedResource: IResource, onChange: Function, value: string[]) => {
            const allScopesControl = selectedResource.scopes
                .filter(scope =>
                    name === 'userScopes'
                        ? scope.type === ExternalApiResourceType.User || scope.type === ExternalApiResourceType.UserAndApplication
                        : scope.type === ExternalApiResourceType.Application || scope.type === ExternalApiResourceType.UserAndApplication,
                )
                .filter(scope =>
                    scope.name.toLowerCase().includes(scopeSearch.toLowerCase()) ||
                    scope.description?.toLowerCase().includes(scopeSearch.toLowerCase()),
                )
                .map((scope, i) => (
                    <FormControlLabel
                        key={name === 'userScopes' ? `user-scope-${i}` : `application-scope-${i}`}
                        control={
                            <Checkbox
                                className={classes.scopesCheckbox}
                                data-cy="resource-select-option"
                                size="small"
                                checked={value?.indexOf(scope.name) > -1}
                                onChange={() => onChange(handleCheck(scope.name))}
                                defaultChecked={!!resource?.userScopes?.find(editScope => editScope === scope.name)}
                            />
                        }
                        label={
                            <div>
                                <UiText className={classes.groupText}>
                                    {scope.name}
                                </UiText>
                                <Tooltip title={getTranslatedScopeDescription(scope) || ''}>
                                    <UiText
                                        className={clsx(classes.groupText, classes.groupTextSmall)}
                                        data-cy="scope-description">
                                        {getTranslatedScopeDescription(scope)}
                                    </UiText>
                                </Tooltip>
                            </div>
                        }
                        data-cy="resource-form-control"
                    />
                ));

            // Add selectAll component, if atleast one scope available & search key is empty
            return allScopesControl.length > 0 && !scopeSearch.length
                ? [ getSelectAllComponent(onChange), ...allScopesControl ]
                : allScopesControl;
        },
        [
            scopeSearch,
            getSelectAllComponent,
            name,
            classes,
            resource?.userScopes,
            getTranslatedScopeDescription,
            handleCheck,
        ],
    );

    return (
        <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
                <>
                    {selectedResource?.scopes &&
                    selectedResource.scopes.length > 0 &&
                    getScopesComponentList(selectedResource, field.onChange, field.value)}
                </>
            )}
        />
    );
};

export default ScopeController;
