import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApProgressSpinner } from '@uipath/portal-shell-react';
import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import {
    daysCategory,
    LicenseType,
    UsageAggregationType,
} from '../../../common/constants/Constant';
import { DurationDropDown } from './DurationDropDown';
import {
    aggregateUsageData,
    getInitialUsageData,
} from './RobotUsageRetriever';

const UsageChart = lazy(() => import('./RobotUsageChart'));

const useStyles = makeStyles(theme =>
    createStyles({
        robotUsage: { minHeight: '312px' },
        checkBox: { marginRight: '40px' },
        divider: {
            backgroundColor: theme.palette.semantic.colorBorderDeEmp,
            width: '20px',
            height: '4px',
            margin: '2px 0 0 8px',
        },
        headerBar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '12px 0',
        },
        chartLegend: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

interface ISelectedProduct {
    selected: boolean;
    display: boolean;
}
interface IRobotUsageProps {
    tenantIds?: string[];
    isServiceMode: boolean;
    productsToDisplay: string[];
}

const RobotUsage = ({
    tenantIds, isServiceMode, productsToDisplay,
}: IRobotUsageProps) => {
    const classes = useStyles();
    const [ robotToIdMap ] = React.useState<Map<string, string>>(
        new Map([
            [ 'AI_DOCUMENT_UNDERSTANDING', 'CLIENT_AI_DOCUMENT_UNDERSTANDING' ],
            [ 'ROBOT_UNITS', 'CLIENT_ROBOT_UNITS' ],
            [ 'AI_UNITS', 'CLIENT_AI_UNITS' ],
        ]),
    );

    const [ selectedServices, setSelectedServices ] = React.useState<Record<string, ISelectedProduct>>({
        AI_DOCUMENT_UNDERSTANDING: {
            selected: true,
            display: productsToDisplay.some(e => e === 'TIE'),
        },
        ROBOT_UNITS: {
            selected: true,
            display: productsToDisplay.some(e => e === 'RU'),
        },
        AI_UNITS: {
            selected: true,
            display: productsToDisplay.some(e => e === 'AIU'),
        },
    });

    const language = useLocalization();
    const [ duration, setDuration ] = useState(daysCategory.defaultDurationDays);
    const [ usage, setUsage ] = useState(getInitialUsageData(duration, language));

    const { formatMessage: translate } = useIntl();
    const { data } = useSWR(
        {
            url: '/api/profile/getUsageData',
            language,
            duration,
            usageAggregationType: isServiceMode ? UsageAggregationType.TENANT : UsageAggregationType.ORGANIZATION,
            tenantIds,
        },
        aggregateUsageData,
    );

    useEffect(() => {
        if (data) {
            setUsage(data);
        }
    }, [ data, language ]);

    const setDurationCallback = useCallback(
        (days: number) => {
            setDuration(days);
        },
        [ setDuration ],
    );

    const handleChange = useCallback(
        () => (event: any) => {
            setSelectedServices({
                ...selectedServices,
                [event.target.id]: {
                    ...selectedServices[event.target.id],
                    selected: event.target.checked,
                },
            });
        },
        [ setSelectedServices, selectedServices ],
    );

    const ChartCheckBox = useCallback(
        (robotType: string) => {
            const robotText = translate({ id: robotToIdMap.get(robotType) });
            const key = `${robotType}_LEGEND_COLOR`;

            return (
                <FormControlLabel
                    key={`FormControlLabel_${robotType}`}
                    className={classes.checkBox}
                    control={<Checkbox
                        id={robotType}
                        checked={selectedServices[robotType].selected}
                        onChange={handleChange} />}
                    label={
                        <div className={classes.chartLegend}>
                            <UiText display="inline">
                                {robotText}
                            </UiText>
                            <Divider
                                className={classes.divider}
                                style={{ backgroundColor: LicenseType[key] }} />
                        </div>
                    }
                    data-cy="robot-usage-checkbox"
                />
            );
        },
        [ classes, selectedServices, handleChange, robotToIdMap, translate ],
    );

    const CheckBoxFormGroup = useMemo(() => {
        const chartCheckBoxes = [];
        for (const product in selectedServices) {
            if (selectedServices[product].display) {
                chartCheckBoxes.push(ChartCheckBox(product));
            }
        }
        return <FormGroup row>
            {chartCheckBoxes}
        </FormGroup>;
    }, [ selectedServices, ChartCheckBox ]);

    return productsToDisplay.length ? (
        <div className={classes.robotUsage}>
            <div className={classes.headerBar}>
                {CheckBoxFormGroup}
                <DurationDropDown callback={setDurationCallback} />
            </div>
            <Suspense fallback={<ApProgressSpinner />}>
                <UsageChart
                    usage={usage.data}
                    isVisible={selectedServices} />
            </Suspense>
        </div>
    ) : null;
};

export default RobotUsage;
