import type { WidgetContext } from '@experiences/interfaces';
import React from 'react';

export const WidgetFrameContext = React.createContext<WidgetContext>({
    tenant: '',
    companyName: '',
    accountLogicalName: '',
    accessToken: '',
    userId: '',
    isAdmin: false,
    organizationGuid: '',
    language: '',
    accountType: {} as any,
    persona: 'UNKNOWN',
});
