import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import {
    concurrentProductCodes,
    CustomScopes,
    notificationType,
} from '../../../common/constants/Constant';
import { EnablingDisablingServicesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    getFallbackConfiguration,
    getTenantServiceLicenses,
    saveFallbackConfiguration,
    saveServiceLicenses,
} from '../../../services/licensing/LicenseManagementService';
import {
    getTenantById,
    tenantByIdUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountType,
    concurrentLicensesOpted,
    userGlobalId,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import UiLicenseAllocationPerServiceComponent from '../../common/UiLicenseAllocationComponent/UiLicenseAllocationComponent';
import {
    generateConsumablesFetchKey,
    generateRobotsAndServicesLicenesFetchKey,
} from '../../licensing/subcomponents/LicensingDataFetchKeyGenerators';
import type {
    ILicensingFallbackConfiguration,
    IServiceLicenseAllocationRequest,
    ITenantServiceLicense,
} from '../interfaces/service';
import { TenantStatusConstants } from '../TenantConstants';
import NeedMoreLicensesComponent from './forms/NeedMoreLicensesComponent';
import {
    isProductUnavailableInAllocationSection,
    mapServiceLicenseToTenantCustomAllocationSections,
    mapTenantAllocationSectionToServiceLicense,
} from './helpers/AllocationSectionsHelper';
import { getProductsFromServices } from './helpers/getProductsForServices';
import {
    sortedProductCodes,
    sortedServiceTypes,
} from './helpers/ManageLicensesHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        groups: {
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        accordion: {
            width: '100%',
            boxShadow: 'none',
            border: `1px ${theme.palette.semantic.colorBorderDeEmp} solid`,
        },
        inputLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        labelGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        inputMargin: { marginBottom: '12px' },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        formButtons: { display: 'flex' },
        overAllocatedWarning: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginBottom: '6px',
        },
        statusMessage: {
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '24px',
            marginBottom: '24px',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
        },
        serviceLicenseHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '34px',
        },
        serviceIcon: {
            margin: '0px',
            width: '18px',
        },
        serviceHeader: { marginLeft: '12px' },
        circularLoader: { marginRight: '16px' },
    }),
);

const operationCode = 'Serverless.PersonalAutomation';

interface IConfigureTenantLicenses {
    products: { [code: string]: string | number | undefined };
    fallbackConfiguration: ILicensingFallbackConfiguration | undefined;
}

export const ConfigureTenantLicensesFormComponent: React.FC<{
    name: string;
    type: 'configure' | 'addLicenses' | 'enable';
    tenantServiceLicenses: ITenantServiceLicense[] | undefined;
    serviceType?: string;
}> = ({
    name, type, tenantServiceLicenses, serviceType,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    const getLocalizedLink = useDocumentationLinks();

    const currentAccountType = useSelector(accountType);
    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const { setValue } = useFormContext();

    const {
        tenantHasServiceLicenses, tenantHasLicenseProductUnavailable,
    } = useMemo(
        () => ({
            tenantHasServiceLicenses: !!tenantServiceLicenses?.some(serviceLicense => serviceLicense.products.length > 0),
            tenantHasLicenseProductUnavailable: !!tenantServiceLicenses?.some(serviceLicense =>
                serviceLicense.products.some(product => product.available === 0),
            ),
        }),
        [ tenantServiceLicenses ],
    );

    const products = useMemo(() => getProductsFromServices(tenantServiceLicenses, concurrentLicenseEnabled),
        [ tenantServiceLicenses, concurrentLicenseEnabled ]);

    useEffect(() => {
        if (type === 'addLicenses' && products) {
            setValue(name, products);
        }
    }, [ name, products, setValue, type ]);

    const statusMessage = useMemo(() => {
        if (type === 'enable' && serviceType) {
            return translate({ id: 'CLIENT_ENABLING_SERVICE' }, { serviceType: getFriendlyName(serviceType) });
        }

        return '';
    }, [ serviceType, translate, type ]);

    return <>
        {
            type !== 'configure'
            && (
                <>
                    {statusMessage && <UiText
                        className={classes.statusMessage}
                        data-cy="licenses-adding-services">
                        <CircularProgress
                            className={classes.circularLoader}
                            size="18px" />
                        {statusMessage}
                    </UiText>}
                    <UiText
                        style={{ marginTop: !statusMessage ? '32px' : '' }}
                        data-cy="licenses-adding-services-description">
                        {translate({ id: 'CLIENT_ALLOCATE_LICENSES_DESCRIPTION' })}
                        <Link
                            style={{
                                marginLeft: '4px',
                                color: theme.palette.semantic.colorForegroundLink,
                            }}
                            href={getLocalizedLink({ articleSlug: EnablingDisablingServicesLink })}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </UiText>
                </>
            )
        }
        <div>
            {tenantHasServiceLicenses ? (
                tenantServiceLicenses
                    ?.filter(serviceLicense => serviceLicense.products.length > 0)
                    .map((serviceLicense, i) => (
                        <React.Fragment key={i}>
                            <UiLicenseAllocationPerServiceComponent
                                serviceLicense={serviceLicense}
                                name={name} />
                        </React.Fragment>
                    ))
            ) : (
                <div className={clsx(classes.groups, classes.groupColumn)}>
                    <UiText
                        className={classes.noLicensesAvailable}
                        data-cy="case-no-license-available">
                        {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                    </UiText>
                </div>
            )}
            <div style={{ marginBottom: '36px' }} />
            {tenantHasLicenseProductUnavailable && AccountLicense[currentAccountType] === AccountLicense.COMMUNITY &&
            <NeedMoreLicensesComponent />}
        </div>
    </>;
};

const ConfigureTenantLicensesComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const location = useLocation();

    const { getErrorMessage } = useGetErrorInfo();

    const partitionGlobalId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const setErrorMessage = useCentralErrorSetter();

    const {
        tenantId, serviceType, type,
    } = useParams() as {
        tenantId: string;
        serviceType: string;
        type: 'enable' | 'configure' | 'addLicenses';
    };

    const {
        addedServices, previousLocation,
    } = useMemo(() => ({
        addedServices: location?.state?.services,
        previousLocation: location?.state?.previousLocation,
    }), [
        location?.state?.previousLocation,
        location?.state?.services,
    ]);

    const { mutate } = useSWRConfig();

    const consumablesFetchKey = useMemo(() => generateConsumablesFetchKey(tenantId), [ tenantId ]);
    const robotsAndServicesLicenesFetchKey = useMemo(
        () => generateRobotsAndServicesLicenesFetchKey(true, tenantId), [ tenantId ]);

    const previousRoute = useMemo(() => {
        if (previousLocation) {
            return previousLocation;
        }
        return `${RouteNames.TenantServices.replace(':tenantId', tenantId)}`;
    }, [ previousLocation, tenantId ]);

    const {
        close, open,
    } = useModalState(previousRoute);

    const { data: tenant } = useSWR(
        tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const services = useMemo(() => {
        if (!tenant?.tenantServiceInstances) {
            return [];
        }

        if (addedServices) {
            return addedServices;
        }

        if (serviceType) {
            return [ serviceType ];
        }

        const tenantServices: string[] = tenant.tenantServiceInstances
            .filter(service => service.status.toUpperCase() === TenantStatusConstants.ENABLED)
            .map(service => service.serviceType);

        if (getFeatureFlagValue(Features.EnableAiuAllocationOnTenant.name)) {
            tenantServices.push(CustomScopes.Tenant);
        }

        return tenantServices;
    }, [ addedServices, serviceType, tenant ]);

    const { data: tenantServiceLicenseData } = useSWR(
        tenantId && services
            ? {
                url: `/api/manageLicense/${partitionGlobalId}/service-licenses/${tenantId}`,
                accountId: partitionGlobalId,
                tenantId,
                services,
            }
            : null,
        getTenantServiceLicenses,
    );

    const tenantServiceLicenses = useMemo(() => tenantServiceLicenseData
        ?.filter(tenantServiceLicense => services.includes(tenantServiceLicense.serviceType)
            && (!process.buildConfigs.unlicensedServices?.includes(tenantServiceLicense.serviceType) ?? true))
        .flatMap(mapServiceLicenseToTenantCustomAllocationSections), [ services, tenantServiceLicenseData ]);

    const products = useMemo(() => {
        const initialLicenses: { [code: string]: string | number | undefined } = {};
        tenantServiceLicenses
            ?.sort((l1, l2) => sortedServiceTypes.indexOf(l1.serviceType) - sortedServiceTypes.indexOf(l2.serviceType))
            .forEach(serviceLicense => {
                serviceLicense.products = serviceLicense.products.filter(
                    product => !isProductUnavailableInAllocationSection(product, serviceLicense.serviceType, services));
                serviceLicense.products.sort((p1, p2) => sortedProductCodes.indexOf(p1.code) - sortedProductCodes.indexOf(p2.code));
                serviceLicense.products.forEach(product => {
                    initialLicenses[product.code] = product.quantity ?? 0;
                });
                if (serviceLicense.serviceType === 'orchestrator' && !concurrentLicenseEnabled) {
                    serviceLicense.products = serviceLicense.products.filter(
                        product => concurrentProductCodes.indexOf(product.code) === -1,
                    );
                }
            });
        return initialLicenses;
    }, [ tenantServiceLicenses, services, concurrentLicenseEnabled ]);

    const containsRuLicenseType = (p: Record<string, string | number | undefined>) => p['RU'] !== undefined;
    const enableRuFallbackConfiguration = getFeatureFlagValue(Features.EnableRuFallbackConfiguration.name);

    const { data: fallbackConfiguration } = useSWR(
        tenantId && products && enableRuFallbackConfiguration && containsRuLicenseType(products)
            ? {
                url: `/api/manageLicense/api/account/fallback/configuration/${partitionGlobalId}/${tenantId}/${operationCode}`,
                organizationId: partitionGlobalId,
                tenantId,
                operationCode,
            }
            : null,
        getFallbackConfiguration,
    );

    const methods = useForm<IConfigureTenantLicenses>({
        mode: 'onChange',
        defaultValues: {
            products,
            fallbackConfiguration,
        },
    });

    const {
        handleSubmit, reset, formState,
    } = methods;
    const {
        errors, isSubmitting, isDirty,
    } = formState;

    useEffect(() => {
        if (products || fallbackConfiguration) {
            reset({
                products,
                fallbackConfiguration,
            });
        }
    }, [ products, fallbackConfiguration, reset ]);

    useEffect(() => {
        if (errors.products) {
            document.querySelector<HTMLInputElement>(`[name="products.${Object.keys(errors.products)[0]}"]`)?.focus();
        }
    }, [ errors ]);

    const drawerTitle = useMemo(() => {
        if (type === 'enable') {
            return translate({ id: 'CLIENT_ALLOCATE_LICENSES' });
        }

        return translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' });
    }, [ translate, type ]);

    const tenantHasServiceLicenses = useMemo(
        () => !!tenantServiceLicenses?.some(serviceLicense => serviceLicense.products.length > 0),
        [ tenantServiceLicenses ],
    );

    const saveLicenseFallbackConfiguration = useCallback(async (fallback: ILicensingFallbackConfiguration) => {
        try {
            await saveFallbackConfiguration(
                fallback.organizationId, fallback.tenantId, fallback);
        } catch (error) {
            return await getErrorMessage(error);
        }
        return '';
    }, [ getErrorMessage ]);

    const saveLicensePerService = useCallback(async (serviceLicense: ITenantServiceLicense) => {
        const serviceProducts = serviceLicense.products.map(p => ({
            code: p.code,
            quantity: p.quantity,
        }));
        const request: IServiceLicenseAllocationRequest = { products: serviceProducts };
        try {
            await saveServiceLicenses(partitionGlobalId, tenantId, serviceLicense.serviceType, request);
        } catch (error) {
            return await getErrorMessage(error);
        }

        return '';
    }, [ getErrorMessage, partitionGlobalId, tenantId ]);

    const onSubmit = useCallback(
        async (data: IConfigureTenantLicenses) => {
            try {
                const changedLicenses = new Set<ITenantServiceLicense>();
                tenantServiceLicenses
                    ?.reduce((alreadyMappedLicenses: ITenantServiceLicense[], serviceLicense: ITenantServiceLicense) => {
                        const mappedServiceLicense = mapTenantAllocationSectionToServiceLicense(serviceLicense);
                        const idx = alreadyMappedLicenses.findIndex(s => s.serviceType === mappedServiceLicense.serviceType);

                        if (idx === -1) {
                            alreadyMappedLicenses.push(mappedServiceLicense);
                        } else {
                            alreadyMappedLicenses[idx].products.push(...mappedServiceLicense.products);
                        }
                        return alreadyMappedLicenses;
                    },
                    [])
                    .forEach(serviceLicense => {
                        serviceLicense.products.forEach(product => {
                            if (product.quantity !== Number(data.products[product.code])) {
                                product.quantity = Number(data.products[product.code]);
                                if (!changedLicenses.has(serviceLicense)) {
                                    changedLicenses.add(serviceLicense);
                                }
                            }
                        });
                    });
                let error = '';
                for (const changedLicense of changedLicenses) {
                    const serviceError = await saveLicensePerService(changedLicense);
                    error += serviceError as string;
                }
                if (fallbackConfiguration &&
                    data.fallbackConfiguration &&
                    fallbackConfiguration.enabled !== data.fallbackConfiguration?.enabled) {

                    fallbackConfiguration.enabled = data.fallbackConfiguration.enabled;
                    await saveLicenseFallbackConfiguration(fallbackConfiguration);
                }
                if (error) {
                    setErrorMessage(error);
                } else {
                    mutate(consumablesFetchKey);
                    mutate({
                        url: robotsAndServicesLicenesFetchKey,
                        userGlobalId: userId,
                        accountGlobalId: partitionGlobalId,
                        tenantGlobalId: tenantId,
                    });
                    createNotification(translate({ id: 'CLIENT_TENANT_EDITED' }, { 0: tenant?.name }), notificationType.SUCCESS);
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            } finally {
                close(true);
            }
        },
        [
            tenantServiceLicenses,
            fallbackConfiguration,
            saveLicensePerService,
            saveLicenseFallbackConfiguration,
            setErrorMessage,
            mutate,
            consumablesFetchKey,
            robotsAndServicesLicenesFetchKey,
            userId,
            partitionGlobalId,
            tenantId,
            createNotification,
            translate,
            tenant?.name,
            getErrorMessage,
            close,
        ],
    );

    return (
        <UiDrawer
            title={drawerTitle}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={!tenant || !tenantServiceLicenses}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => close()}
                                data-cy="configure-licenses-cancel"
                                color="primary"
                            >
                                {translate({ id: type === 'configure' ? 'CLIENT_CANCEL' : 'CLIENT_SKIP' })}
                            </Button>
                            <UiProgressButton
                                loading={isSubmitting}
                                disabled={!tenantHasServiceLicenses || !isDirty}
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                data-cy="configure-licenses-save"
                            >
                                {translate({ id: type === 'configure' ? 'CLIENT_SAVE' : 'CLIENT_ALLOCATE' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                >
                    <ConfigureTenantLicensesFormComponent
                        name="products"
                        type={type}
                        tenantServiceLicenses={tenantServiceLicenses}
                        serviceType={serviceType} />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default ConfigureTenantLicensesComponent;
