import {
    AccountLicense,
    ContactUsLinks,
} from '@experiences/constants';
import { UiText } from '@experiences/ui-common';
import {
    useLocalizedLinks,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../common/constants/RouteNames';
import detectiveRobot from '../../images/detectiveRobot.png';
import flyingRobot from '../../images/flyingRobot.png';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70%',
        },
        link: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': { textDecoration: 'underline' },
        },
        header: {
            fontFamily: Tokens.FontFamily.FontTitle,
            fontWeight: 600,
            fontSize: '20px',
            textAlign: 'center',
        },
        description: {
            fontFamily: Tokens.FontFamily.FontTitle,
            fontWeight: 400,
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '16px',
        },
        spacer: { marginTop: '16px' },
        '@media all and (max-width: 560px)': {
            oldAdminSpacing: {
                position: 'static',
                marginTop: '60px',
            },
        },
    }),
);

const UpgradeForFeature: React.FC<{
    upgradeTitle: string;
    upgradeMessage: string;
    documentationLink?: string;
    level: AccountLicense.PRO | AccountLicense.ENTERPRISE;
    telemetryTitle: string;
}> = ({
    upgradeTitle, upgradeMessage, documentationLink, level, telemetryTitle,
}) => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const getLocalizedLink = useLocalizedLinks();

    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();
    const upgradeToEnterprise = useMemo(() => level === AccountLicense.ENTERPRISE, [ level ]);

    useEffect(() => {
        // Call once
        logEvent(telemetryTitle, { UpgradePageLicense: AccountLicense[level] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const upgradeButton = useCallback(() => {

        // Sets the logEvent title to event name (from prop) + contact or upgrade depending on the license tier
        logEvent(`${telemetryTitle}${upgradeToEnterprise ? 'Contact' : 'Upgrade'}`, { UpgradePageLicense: AccountLicense[level] });

        upgradeToEnterprise ? window.open(getLocalizedLink(ContactUsLinks), '_blank', 'noopener,noreferrer')
            : navigate(getRoute(RouteNames.BuyProPresets));
    }, [ getLocalizedLink, getRoute, level, logEvent, navigate, telemetryTitle, upgradeToEnterprise ]);

    return (
        <div className={classes.mainContainer}>
            <div
                className={classes.mainContainer}
                style={{ maxWidth: '500px' }}>
                <UiText
                    className={classes.header}
                    data-cy="upgrade-title">
                    {upgradeTitle}
                </UiText>
                <UiText
                    className={clsx(classes.description, classes.spacer)}
                    data-cy="upgrade-message">
                    {upgradeMessage}
                </UiText>
                <img
                    src={upgradeToEnterprise ? detectiveRobot : flyingRobot}
                    alt='UiPath robot'
                    className={classes.spacer}
                />
                <Button
                    onClick={upgradeButton}
                    className={classes.spacer}
                    variant='contained'
                    style={{ width: '166px' }}
                    data-cy='upgrade-button'>
                    {translate({ id: level === AccountLicense.ENTERPRISE ? 'CLIENT_CONTACT_SALES' : 'CLIENT_UPGRADE' })}
                </Button>
                {documentationLink && <Link
                    target='_blank'
                    href={documentationLink}
                    onClick={() => logEvent(`${telemetryTitle}Docs`)}
                    data-cy='upgrade-link'>
                    <UiText className={clsx(classes.link, classes.spacer)}>
                        {translate({ id: 'CLIENT_VIEW_DOCUMENTATION' })}
                    </UiText>
                </Link>}
            </div>
        </div>
    );
};

export default UpgradeForFeature;
