import type { IApiKey } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import {
    consumableCodeToApiKeyIdentifier,
    notificationType,
} from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import { generateMLServiceKey } from '../../../../services/licensing/LicenseService';
import { isAdminSelector } from '../../../../store/selectors';
import { generateConsumablesFetchKey } from '../../subcomponents/LicensingDataFetchKeyGenerators';

const useStyles = makeStyles(theme =>
    createStyles({
        iconButton: { color: theme.palette.semantic.colorPrimary },
        apiKeyWithAction: {
            display: 'flex',
            marginTop: '8px',
        },
        apiKey: {
            display: 'flex',
            marginTop: '8px',
            overflow: 'hidden',
        },
        apiKeyText: { whiteSpace: 'nowrap' },
        apiKeyLine: {
            textOverflow: 'ellipsis',
            maxWidth: '480px',
        },
        apiKeyActions: {
            marginLeft: '5px',
            display: 'flex',

            alignSelf: 'flex-end',
        },
        noWrap: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
);

export const UiApiKey: React.FC<{ consumableCode: string; apiKey?: IApiKey; tenantId?: string }> = ({
    consumableCode, apiKey, tenantId,
}) => {

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const [ loading, setLoading ] = useState(false);
    const createNotification = useUiSnackBar();

    const createDialog = useShowDialog();
    const isAdmin = useSelector(isAdminSelector);

    const { mutate } = useSWRConfig();

    const consumablesFetchKey = useMemo(() => generateConsumablesFetchKey(tenantId), [ tenantId ]);

    const generateNewMlKey = useCallback(async (keyType: string) => {
        await generateMLServiceKey(keyType);
        await mutate(consumablesFetchKey);
        setLoading(false);
    }, [ consumablesFetchKey, mutate ]);
    const showConfirmationDialog = useCallback(
        async (keyType?: string) => {
            if (!keyType) {
                return;
            }

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_CONFIRM_GENERATION_REQUEST' }),
                body: translate({ id: 'CLIENT_CONFIRM_GENERATION_TEXT' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_GENERATE' }),
            });
            if (proceed) {
                setLoading(true);
                await generateNewMlKey(keyType);
            }
        },
        [ createDialog, generateNewMlKey, translate ],
    );

    return (
        <div className={classes.apiKeyWithAction}>
            <div className={classes.apiKey}>
                <UiText
                    className={classes.apiKeyText}
                    data-cy={`licenses-api-key-label-${consumableCode.toLowerCase()}`}>
                    {translate({ id: 'CLIENT_API_KEY' })}
                  &nbsp;
                </UiText>
                {loading && <CircularProgress
                    size={20}
                    style={{ marginLeft: '8px' }} />}
                {!loading && <Tooltip
                    arrow
                    title={apiKey?.value ?? ''}>
                    <UiText
                        className={clsx({
                            [classes.apiKeyLine]: true,
                            [classes.noWrap]: true,
                        })}
                        data-cy={`licenses-api-key-value-${consumableCode.toLowerCase()}`}
                    >
                        {apiKey?.value ?? translate({ id: 'CLIENT_NOT_GENERATED_YET' })}
                    </UiText>
                </Tooltip>}
            </div>
            <div className={classes.apiKeyActions}>
                {apiKey?.value && (
                    <Tooltip
                        title={translate({ id: 'CLIENT_COPY_API_KEY' })}
                        placement="top">
                        <IconButton onClick={() => createNotification(translate({ id: 'CLIENT_COPIED_TO_CLIPBOARD' }), notificationType.SUCCESS)}>
                            <CopyToClipboard text={apiKey.value}>
                                <FileCopyOutlinedIcon
                                    data-cy={`licenses-copy-key-${consumableCode.toLowerCase()}`}
                                    color="primary"
                                    fontSize="small"
                                />
                            </CopyToClipboard>
                        </IconButton>
                    </Tooltip>
                )}
                {isAdmin && consumableCodeToApiKeyIdentifier[consumableCode] && (
                    <Tooltip
                        title={translate({ id: 'CLIENT_GENERATE_NEW' })}
                        placement="top">
                        <IconButton
                            className={classes.iconButton}
                            style={{ marginLeft: apiKey ? '12px' : '0' }}
                            onClick={() => showConfirmationDialog(consumableCodeToApiKeyIdentifier[consumableCode])}
                            data-cy={`licenses-generate-key-${consumableCode.toLowerCase()}`}
                        >
                            <AutorenewIcon
                                color="primary"
                                fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
