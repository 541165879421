import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    accountLogicalName,
    companyName,
    isHostModeSelector,
} from '../../store/selectors';

export const useOrganizationName = () => {
    const { formatMessage: translate } = useIntl();

    const isHost = useSelector(isHostModeSelector);
    const organizationName = useSelector(companyName);
    const logicalName = useSelector(accountLogicalName);

    if (isHost) {
        return translate({ id: 'CLIENT_HOST' });
    }

    return organizationName ?? logicalName;
};

