import type {
    IChangePasswordDto,
    IResetPasswordData,
} from '../common/interfaces/cis/account';

export function mapResetPasswordDataToChangePasswordDto(data: IResetPasswordData, userId: string): IChangePasswordDto {
    return {
        newPassword: data.password,
        oldPassword: data.currentPassword,
        userId,
    };
}
