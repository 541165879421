import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
    getTenantById,
    tenantByIdUri,
} from '../../../services/organization/TenantService';

const useTenantTagsViewModel = () => {
    const { tenantId } = useParams() as { tenantId: string };

    // SWR fetch
    const { data: tenant } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI)
            ? {
                url: tenantByIdUri,
                id: tenantId,
            }
            : null,
        getTenantById);

    return {
        tenantId,
        tenant,
    };
};

export default useTenantTagsViewModel;
