import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { useModalState } from '@experiences/util';
import {
    useCallback,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { EmailSettings } from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IEmailSettingsData } from '../../../common/interfaces/emailSetting';
import { verifySmtpSettings } from '../../../services/identity/SettingService';
import { mapEmailSettingsDataToVerifySmtpSettingsData } from '../../../util/setting/EmailSettingUtil';

export interface IVerifyEmailSettingsData {
    recipient: string;
}

const useVerifyMailSettingsDialogBodyViewModel = (emailSettingsData: IEmailSettingsData, closeDialog?: (proceed?: boolean) => void) => {
    const { formatMessage: translate } = useIntl();

    const {
        open, close,
    } = useModalState(EmailSettings);

    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const methods = useForm<IVerifyEmailSettingsData>({
        mode: 'onSubmit',
        defaultValues: { recipient: '' },
    });

    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const createNotification = useUiSnackBar();

    const onSubmit = useCallback(
        async ({ recipient }: IVerifyEmailSettingsData) => {
            try {
                setLoading(true);
                await verifySmtpSettings(recipient, mapEmailSettingsDataToVerifySmtpSettingsData(emailSettingsData));
                createNotification(translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS_SUCCESS' }, { 0: recipient }));
                if (closeDialog) {
                    closeDialog(true);
                } else {
                    close(true, { verifiedSettings: true });
                }
            } catch (error) {
                const message = await getErrorMessage(error);
                setErrorMessage(message);
                setShowDrawerError(true);
            } finally {
                setLoading(false);
            }
        },
        [ close, closeDialog, createNotification, emailSettingsData, getErrorMessage, setErrorMessage, translate ],
    );

    return {
        methods,
        onSubmit,
        loading,
        showDrawerError,
        open,
        close,
    };
};

export default useVerifyMailSettingsDialogBodyViewModel;
