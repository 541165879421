import './audit.css';

import { accountGlobalId } from '@experiences/ecommerce';
import {
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import type { DataManager } from '@uipath/apollo-angular-elements';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridColumnDropdownFilter,
    ApDataGridColumnSearchFilter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import type { IColumn } from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { UiDatePicker } from '../common/UiDatePicker/UiDatePicker';
import {
    type AuditEventDto,
    AuditEventStatusMap,
    type IAuditDetailsContext,
} from './interfaces/auditLog';
import {
    getSearchSourceFactory,
    getUserFilterOptions,
} from './util/AuditGridUtils';
import { useAuditGridViewModel } from './util/AuditGridViewModel';

const gridStyles = new CSSStyleSheet();

gridStyles.replaceSync(`
    .ui-grid-action-buttons {
        display: contents !important;
    }

    [slot="header__slot__timeFilter"] {
        order: -1;
    }
`);

const useStyles = makeStyles((theme) => ({
    divider: {
        width: '.5px',
        height: '24px',
        margin: '0px 12px 0px 8px',
    },
    timeFilterButton: { '&:focus, &:hover': { backgroundColor: `${theme.palette.semantic.colorBackgroundHover} !important` } },
    closeIcon: {
        strokeWidth: 1,
        stroke: theme.palette.semantic.colorIconDefault,
        '&:hover': {
            color: theme.palette.semantic.colorErrorIcon,
            stroke: theme.palette.semantic.colorErrorIcon,
        },
    },
}));

export const AuditGridComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ dataManager, setDataManager ] = useState<DataManager<AuditEventDto> | null>(null);

    const {
        data, setData,
    } = useUiDataContext<IAuditDetailsContext>();

    const {
        events, sources, categories, loading, validating,
        filters, sourceFilter, categoryFilter, actionFilter, timeFilter, userFilter, statusFilter,
        refresh, clearFilters,
    } = useAuditGridViewModel();

    const columns: Array<IColumn<any>> = useMemo(() => [
        {
            property: 'createdOn',
            title: translate({ id: 'CLIENT_DATE_CREATED_UTC' }),
            sortable: false,
            visible: true,
            primary: true,
        },
        {
            property: 'actorName',
            title: translate({ id: 'CLIENT_USER' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                value: userFilter.suggestValue,
                searchSourceFactory: async (search, fetchSize, skip) => getUserFilterOptions(partitionGlobalId, search, fetchSize, skip),
            },
        },
        {
            property: 'eventSource',
            title: translate({ id: 'CLIENT_SOURCE' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                value: sourceFilter.suggestValue?.map(s => ({
                    ...s,
                    text: sources?.find(source => source.id === s.id)?.name ?? '',
                })),
                searchSourceFactory: (search) => getSearchSourceFactory(
                    search,
                    sources
                ),
            },
        },
        {
            property: 'eventTarget',
            title: translate({ id: 'CLIENT_CATEGORY' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                disabled: !sourceFilter.value,
                value: categoryFilter.suggestValue?.map(c => ({
                    ...c,
                    text: categories?.find(category => category.id === c.id)?.name ?? '',
                })),
                searchSourceFactory: (search) => getSearchSourceFactory(
                    search,
                    sources?.filter(s => sourceFilter.value?.includes(s.id))
                        .flatMap(s => s.eventTargets)
                ),
            },
        },
        {
            property: 'eventType',
            title: translate({ id: 'CLIENT_ACTION' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                disabled: !categoryFilter.value,
                value: actionFilter.suggestValue?.map(a => ({
                    ...a,
                    text: categories?.flatMap(t => t.eventTypes)
                        .find(e => e.id === a.id)?.name ?? '',
                })),
                searchSourceFactory: (search) => getSearchSourceFactory(
                    search,
                    categories?.filter(s => categoryFilter.value?.includes(s.id))
                        .flatMap(s => s.eventTypes)
                ),
            },
        },
        {
            property: 'status',
            title: translate({ id: 'CLIENT_STATUS' }),
            sortable: false,
            visible: true,
            render: (event: AuditEventDto) => event.status !== undefined && translate({ id: AuditEventStatusMap[event.status] }),
            dropdownFilterOpts: {
                value: statusFilter.suggestValue,
                items: Object.entries(AuditEventStatusMap).map(([ key, value ]) => ({
                    value: key,
                    label: translate({ id: value }),
                })),
            },
        },

    ], [ translate, userFilter, sourceFilter, categoryFilter, actionFilter, statusFilter, partitionGlobalId, sources, categories ]);

    const handleDetailsChange = useCallback((rowId: number) => (incrementer = 1) => {
        const id = rowId + incrementer;

        const apDataGrid = document.querySelector('web-ap-grid-new')?.shadowRoot;

        // scroll to nearby row prior to selecting element
        data.virtualScrollManager?.scrollTo(id - 2);

        // find the row to click based off the incrementer
        const row: HTMLDivElement | null | undefined = apDataGrid?.querySelector(`[data-row-index="${id}"]`);

        // if the row exists, click it - this will trigger the rowClick handler on the grid
        if (row) {
            row.click();
        }
    }, [ data.virtualScrollManager ]);

    const handleRowClick = useCallback(({ row }: { row: AuditEventDto }) => {
        // if the infinite scroll gets stuck for some reason, the user can click
        // on the last row to force a range load of the next set of data.
        // this is a safety net to ensure a user never gets into a broken state.
        if (+row.id === -1) {
            const length = (dataManager?.length ?? 0) - 1;

            data.virtualScrollManager?.rangeLoad({
                start: length,
                end: length,
            });

            return;
        }

        setData(prev => ({
            ...prev,
            row: {
                ...row,
                next: handleDetailsChange(row.rowId),
            },
        }));
    }, [ data.virtualScrollManager, dataManager?.length, handleDetailsChange, setData ]);

    const apDataGrid = document.querySelector('web-ap-grid-new')?.shadowRoot;

    if (apDataGrid) {
        const hasPushedGridStyles = apDataGrid.adoptedStyleSheets.includes(gridStyles);

        if (!hasPushedGridStyles) {
            apDataGrid.adoptedStyleSheets.push(gridStyles);
        }
    }

    return <ApDataGridWrapper<AuditEventDto>
        data={events}
        loading={loading || validating}
        allowHighlight
        virtualScroll
        refreshable
        refresh={() => refresh()}
        selectable={false}
        rowClick={handleRowClick}
        collapseFiltersCount={5}
        onGridApi={(managers) => {
            setDataManager(managers.dataManager);
            setData(prev => ({
                ...prev,
                filterManager: managers.filterManager as any,
                virtualScrollManager: managers.virtualScrollManager,
            }));
        }}>

        <ApDataGridHeader search={false}>
            <ApDataGridHeaderButton
                type='inline'
                id="timeFilter"
                render={() =>
                    <>
                        <Button
                            variant='text'
                            color='inherit'
                            aria-controls={timeFilter.open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={timeFilter.open ? 'true' : undefined}
                            className={classes.timeFilterButton}
                            onClick={timeFilter.toggle}
                            endIcon={timeFilter.hasValue ?
                                <ClearIcon
                                    className={classes.closeIcon}
                                    onClick={timeFilter.clear} /> :
                                <KeyboardArrowDownIcon />}
                        >
                            <UiText variant={FontVariantToken.fontSizeMBold}>
                                {timeFilter.label.title}
                                &nbsp;
                            </UiText>
                            <UiText>
                                {timeFilter.label.value}
                            </UiText>
                        </Button>
                        {timeFilter.open &&
                            <UiDatePicker
                                anchorEl={timeFilter.anchorEl}
                                open={timeFilter.open}
                                value={timeFilter.value}
                                setValue={timeFilter.set}
                                onClose={timeFilter.toggle}
                                calendar={{ disableFuture: true }} />}
                    </>}
            />
            <ApDataGridHeaderButton
                id="resetFilters"
                type="inline"
                render={() => <div style={{
                    display: (filters.length > 0 || timeFilter.hasValue) ? 'flex' : 'none',
                    alignItems: 'center',
                }}>
                    <Divider className={classes.divider} />
                    <Button
                        color="primary"
                        variant="text"
                        onClick={() => clearFilters()}
                    >
                        {translate({ id: 'CLIENT_NOTIFICATIONS_FILTER_RESET_DEFAULT' })}
                    </Button>
                </div>}
            />
        </ApDataGridHeader>

        {columns.map((column) => (
            <ApDataGridColumn
                key={column.property}
                property={column.property}
                title={column.title}
                searchable={column.searchable}
                render={(column as any).render}
                {...column.sortable !== undefined ? { sortable: column.sortable } : {}}
            >
                {column.searchFilterOpts ? <ApDataGridColumnSearchFilter {...column.searchFilterOpts} /> : null}
                {column.dropdownFilterOpts ? <ApDataGridColumnDropdownFilter {...column.dropdownFilterOpts} /> : null}
            </ApDataGridColumn>
        ))}

    </ApDataGridWrapper>;
};
