import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import useIsServicesPageEnabled from '../../../common/hooks/useIsServicesPageEnabled';
import { useLicenseExpired } from '../../../common/hooks/useLicenseExpired';
import type { IUserCIS } from '../../../common/interfaces/cis/user';
import SuccessMessageActionsComponent, { useSuccessMessageActionsStyles } from './SuccessMessageActionsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        body: { flex: '1 1 100%' },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
        content: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: 'calc(100vh - 54px)',
        },
        header: {
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '16px',
        },
        divider: {
            marginTop: '20px',
            marginBottom: '20px',
            color: theme.palette.semantic.colorBorderDeEmp,
            backgroundColor: theme.palette.semantic.colorBorderDeEmp,
            width: '250',
        },
        subHeader: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '10px',
        },
        subHeaderText: {
            fontWeight: 600,
            marginLeft: '10px',
        },
    }),
);

export const ConfirmInviteSuccessComponent: React.FC<{ close: (_: boolean) => void }> = ({ close }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.body}>
            <UiText>
                {translate({ id: 'CLIENT_NEW_USER_INVITED' })}
.
            </UiText>
            <br />
            <UiText>
                {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_ONE' })}
            </UiText>
            <br />
            <UiText>
                {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_TWO' })}
            </UiText>
            <br />
            <UiText>
                {translate(
                    { id: 'CLIENT_INVITE_SUCCESS_TEXT_THREE_REVAMP' },
                    { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                )}
            </UiText>
            <SuccessMessageActionsComponent close={close} />
        </div>
    );
};

export const ConfirmInviteSuccessLicensingComponent: React.FC<{
    close: (_: boolean) => void;
    users?: IUserCIS[];
}> = ({
    close, users,
}) => {
    const classes = useStyles();
    const successMessageActionsClasses = useSuccessMessageActionsStyles();
    const { formatMessage: translate } = useIntl();
    const isLicenseExpired = useLicenseExpired();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    return (
        <div className={classes.content}>
            <div className={classes.body}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_USERS_HAVE_BEEN_INVITED_SUCCESSFULLY' })}
                </UiText>
                <Divider className={classes.divider} />
                <div className={classes.subHeader}>
                    <ErrorOutlineIcon />
                    <UiText className={classes.subHeaderText}>
                        {translate({ id: 'CLIENT_PERMISSIONS' })}
                    </UiText>
                </div>
                <UiText>
                    {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_ONE' })}
                </UiText>
                <br />
                <UiText>
                    {translate(
                        { id: 'CLIENT_INVITE_SUCCESS_LICENSING_TEXT_REVAMP' },
                        { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                    )}
                </UiText>
                <Divider className={classes.divider} />
                <div className={classes.subHeader}>
                    <ErrorOutlineIcon />
                    <UiText className={classes.subHeaderText}>
                        {translate({ id: 'CLIENT_LICENSES' })}
                    </UiText>
                </div>
                <UiText>
                    {translate({ id: 'CLIENT_LICENSE_USE_GROUP_ALLOCATION_RULE' })}
                </UiText>
                <br />
                <UiText>
                    {translate({ id: 'CLIENT_LICENSE_ALLOCATE_EXPLICITLY' })}
                </UiText>
            </div>
            <div className={successMessageActionsClasses.actionButtons}>
                <Button
                    color="primary"
                    onClick={() => close(true)}>
                    {translate({ id: 'CLIENT_CLOSE' })}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className={successMessageActionsClasses.secondaryButton}
                    onClick={() =>
                        navigate(getRoute(RouteNames.UsersUserAddLicenseAllocation),
                            {
                                state: {
                                    users,
                                    previousLocation: getRoute(RouteNames.Users),
                                },
                            })}
                    data-cy="add-edit-create-allocation-button"
                    disabled={isLicenseExpired}
                >
                    {translate({ id: 'CLIENT_ALLOCATE_LICENSES' })}
                </Button>
            </div>
        </div>
    );
};

export const ConfirmCreateEditGroupSuccessComponent: React.FC<{
    close: (_: boolean) => void;
}> = ({ close }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isServicesPageEnabled = useIsServicesPageEnabled();

    return (
        <div className={classes.content}>
            <div className={classes.body}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_GROUPS_NEW_GROUP' })}
                </UiText>
                {isServicesPageEnabled && (
                    <>
                        <br />
                        <UiText>
                            {translate({ id: 'CLIENT_GROUPS_NEW_DESCRIPTION' })}
                        </UiText>
                        <br />
                        <UiText display="inline">
                            <>
                                {translate({ id: 'CLIENT_GROUPS_MANAGE_USERS' }).split('{ICON}')[0]}
                                <PeopleAltOutlinedIcon className={classes.inlineIcon} />
                            </>
                        </UiText>
                        <UiText display="inline">
                            {translate({ id: 'CLIENT_GROUPS_MANAGE_USERS' }).split('{ICON}')[1]}
                        </UiText>
                    </>
                )}
            </div>
            <SuccessMessageActionsComponent close={close} />
        </div>
    );
};

export const ConfirmAddEditRobotSuccessComponent: React.FC<{
    close: (_: boolean) => void;
}> = ({ close }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isServicesPageEnabled = useIsServicesPageEnabled();

    return (
        <div className={classes.content}>
            <div className={classes.body}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_NEW_ROBOT_ADDED' })}
                </UiText>
                {isServicesPageEnabled && (
                    <>
                        <Divider className={classes.divider} />
                        <div className={classes.subHeader}>
                            <ErrorOutlineIcon />
                            <UiText className={classes.subHeaderText}>
                                {translate({ id: 'CLIENT_PERMISSIONS' })}
                            </UiText>
                        </div>
                        <UiText>
                            {translate({ id: 'CLIENT_CREATE_SUCCESS_TEXT' })}
                        </UiText>
                        <br />
                        <UiText>
                            {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_TWO' })}
                        </UiText>
                        <br />
                        <UiText>
                            {translate(
                                { id: 'CLIENT_INVITE_SUCCESS_TEXT_THREE_REVAMP' },
                                { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                            )}
                        </UiText>
                    </>
                )}
            </div>
            <SuccessMessageActionsComponent close={close} />
        </div>
    );
};
