import { licenseManagementAccountUrl } from '../../../services/licensing/management/AccountService';

export function generateConsumablesFetchKey(tenantId?: string) {
    return tenantId != null ?
        'api/licensing/tenantConsumables'
        : `${licenseManagementAccountUrl}/consumables`;
}

export function generateRobotsAndServicesLicenesFetchKey(
    isServiceMode?: boolean,
    tenantId?: string,
) {
    return isServiceMode
        ? (tenantId
            ? `/api/licensing/tenantProductAllocation`
            : null)
        : `${licenseManagementAccountUrl}/available`;
}
