export enum EmailSettingKey {
    Host = 'Email.Smtp.Host',
    Port = 'Email.Smtp.Port',
    UserName = 'Email.Smtp.UserName',
    Password = 'Email.Smtp.Password',
    Domain = 'Email.Smtp.Domain',
    FromEmail = 'Email.Smtp.FromEmail',
    FromDisplayName = 'Email.Smtp.FromDisplayName',
    EnableSsl = 'Email.Smtp.EnableSsl',
    UseDefaultCredentials = 'Email.Smtp.UseDefaultCredentials',
    ConnectionTimeout = 'Email.Smtp.ConnectionTimeout',
}
