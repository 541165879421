import { getFeatureFlagConfig } from '@experiences/feature-flags';
import type {
    IPortalUser,
    Persona,
    Widget,
} from '@experiences/interfaces';
import { useLocalization } from '@experiences/locales';
import {
    HomePageEvent,
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { UiStorage } from '@experiences/util';
import {
    WidgetFactory,
    WidgetFrameContext,
} from '@experiences/widget-framework';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import { HomePages } from '../homepages';
import { DefaultFallBack } from '../homepages/DefaultFallback';
import type { HomePage } from '../model/HomePage';

export interface HomePageSelectorProps {
    profile: IPortalUser;
    accessToken: string;
}

type HomePageWidgetMap = {
    [key in Persona]: Widget[];
};

export const HomePageSelector = ({
    profile,
    accessToken,
}: HomePageSelectorProps) => {
    const language = useLocalization();
    const [ selectedHomePage, setSelectedHomePage ] = useState<HomePage>(DefaultFallBack);

    const initialTenant = UiStorage.getItem('PORTAL_CURRENT_TENANT');
    const [ tenant, setTenant ] = useState(initialTenant);
    const [ companyName, setCompanyName ] = useState<string>('');
    const [ widgetMap, setWidgetMap ] = useState<HomePageWidgetMap>();
    const [ accountLogicalName, setAccountLogicalName ] = useState<string | undefined>(undefined);
    const [ isAdmin, setIsAdmin ] = useState<boolean>(false);
    const homePageWidgetMap = getFeatureFlagConfig('HomePageWidgetMap');

    useEffect(() => {
        try {
            setWidgetMap(JSON.parse(homePageWidgetMap));
        } catch (error) {
            portalTelemetry.trackTrace({
                message: 'SelectedHomePage - Error parsing HomePageWidgetMap',
                severityLevel: SeverityLevel.Major,
                properties: {
                    name: 'SelectedHomePage',
                    code: '[IN-HP-22]',
                    Error: error,
                    WidgetMap: homePageWidgetMap,
                },
            });
        }
    }, [ homePageWidgetMap ]);

    useEffect(() => {
        const tenantChangedHandler = (event: any) => {
            setTenant(event.detail.selectedTenantId);
        };

        window.addEventListener('tenantChanged', tenantChangedHandler);
        return () => window.removeEventListener('tenantChanged', tenantChangedHandler);
    }, [ setTenant ]);

    useEffect(() => {
        const selected: HomePage = HomePages.find((homePage) =>
            homePage.persona === profile.persona,
        ) ?? DefaultFallBack;

        portalTelemetry.trackEvent({
            name: HomePageEvent.SelectedHomePage,
            properties: {
                Message: '[IN-HP-05] - Selected home page',
                Code: '[IN-HP-05]',
                SelectedHomePage: selected?.name,
                Persona: selected?.persona,
            },
        });

        setSelectedHomePage(selected ?? DefaultFallBack);
        setCompanyName(profile.accountUserDto.companyName as string);
        setAccountLogicalName(profile.accountUserDto.accountLogicalName as string);
        setIsAdmin(profile.accountRoleType !== 'DEFAULT_USER');
    }, [ profile, accessToken ]);

    const selectedWidgets = useMemo(() => {
        if (widgetMap) {
            const widgets = widgetMap[selectedHomePage.persona];
            if (widgets) {
                return widgets;
            }
        }
        portalTelemetry.trackTrace({
            message: 'SelectedHomePage - [Fallback] : Error fetching feature flag for persona',
            severityLevel: SeverityLevel.Major,
            properties: {
                name: 'SelectedHomePage',
                code: '[IN-HP-22]',
                WidgetMap: widgetMap,
                Persona: selectedHomePage.persona,
            },
        });

        return selectedHomePage.widgets;
    }, [ widgetMap, selectedHomePage.widgets, selectedHomePage.persona ]);

    return (
        <div data-testid={selectedHomePage.name}>
            { tenant && accountLogicalName && <WidgetFrameContext.Provider value={{
                tenant,
                companyName,
                accountLogicalName,
                accessToken,
                userId: profile.userGlobalId,
                isAdmin,
                organizationGuid: profile.accountUserDto.accountGlobalId,
                language,
                accountType: profile.accountUserDto.accountType,
                persona: profile.persona,
            }}>
                <WidgetFactory
                    data-testid={selectedHomePage.name}
                    widgets={selectedWidgets} />
            </WidgetFrameContext.Provider>}
        </div>
    );
};
