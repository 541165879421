import { GlobalStyles } from '@experiences/theme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import useEcommerceContactSalesLinkViewModel from './useEcommerceContactSalesLinkViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        salesLink: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        openInNewIcon: {
            width: '16px',
            height: '16px',
            marginLeft: '2px',
        },
    }),
}));

export const EcommerceContactSalesLinkComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        navigateToContactSales, onKeyDown,
    } = useEcommerceContactSalesLinkViewModel();

    return (<Link
        className={classes.salesLink}
        data-cy="ecommerce-select-plan-contact-sales-link"
        role='link'
        tabIndex={0}
        onClick={navigateToContactSales}
        onKeyDown={onKeyDown}
    >
        {translate({ id: 'CLIENT_CONTACT_SALES' })}
        <OpenInNewIcon className={classes.openInNewIcon} />
    </Link>);
};
