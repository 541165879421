import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiText } from '@experiences/ui-common';
import {
    isBeforeGivenDate,
    timestampToDate,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
    useUserReadableTime,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import type { IReferenceToken } from '../../common/interfaces/cis/profile';
import {
    deleteReferenceToken,
    getReferenceToken,
    referenceTokenUrl,
    regenerateReferenceToken,
} from '../../services/identity/ReferenceTokenService';
import { UiGrid } from '../common/UiGrid';
import type { IActionHeader } from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import DisplayAccessToken from './subcomponents/DisplayAccessToken';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
        searchBar: { width: '100%' },
        searchIcon: { marginRight: '4px' },
        scopesText: {
            minWidth: 0,
            maxWidth: 20,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        expiration: {
            display: 'flex',
            flexDirection: 'row',
        },
        expirationText: { marginTop: '2px' },
        expirationIcon: { marginLeft: '4px' },
        redIcon: { color: theme.palette.semantic.colorErrorIcon },
    }),
);

const TOKEN_TABLE_HEIGHT_PX = '500px';

const PersonalAccessTokenSettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const { userReadableTime } = useUserReadableTime();

    const {
        data: tokenData, mutate, isValidating,
    } = useSWR<IReferenceToken[], Error>(referenceTokenUrl, getReferenceToken);

    const openTokenDrawer = useCallback(() => {
        navigate(getRoute(RouteNames.AddPersonalAccessToken));
        mutate();
    }, [ getRoute, mutate, navigate ],
    );

    const onRevokeToken = useCallback(
        async (data: { id: string }) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REVOKE_TOKEN' }),
                body: <div data-cy="revoke-token-message">
                    {translate({ id: 'CLIENT_REVOKE_TOKEN_MESSAGE' })}
                </div>,
                icon: 'error',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });

            if (proceed) {
                try {
                    await deleteReferenceToken(data.id);
                    mutate();
                } catch (error) {
                    const response = await getErrorMessage(error);
                    setErrorMessage(response);
                }
            }
        }, [ createDialog, getErrorMessage, mutate, setErrorMessage, translate ],
    );

    const onRegenerateToken = useCallback(
        async (data: { expiration: string; id: string }) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REGENERATE_TOKEN' }),
                body: <div data-cy="regenerate-token-message">
                    {translate({ id: 'CLIENT_REGENERATE_TOKEN_MESSAGE' })}
                </div>,
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
                showCancel: true,
            });

            if (proceed) {
                try {
                    const payload = { expiration: data.expiration };
                    const returnPayload = await regenerateReferenceToken(data.id, payload);

                    await createDialog({
                        title: translate({ id: 'CLIENT_TOKEN_GENERATED' }),
                        body: <DisplayAccessToken token={returnPayload} />,
                        icon: 'success',
                        primaryButtonText: translate({ id: 'CLIENT_CLOSE' }),
                        showCancel: false,
                    });
                    mutate();
                } catch (error) {
                    const response = await getErrorMessage(error);
                    setErrorMessage(response);
                }
            }
        }, [ createDialog, getErrorMessage, mutate, setErrorMessage, translate ],
    );

    const actions: IActionHeader[] = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_GENERATE_NEW_TOKEN_BUTTON' }),
            icon: <AddIcon />,
            click: row => {
                openTokenDrawer();
            },
            variant: 'contained',
            dataCy: 'ui-grid-add-token-button',
        },
    ], [ openTokenDrawer, translate ]);

    return (
        <div>
            <UiText
                className={clsx(classes.heading, classes.mainHeading)}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN' })}
            </UiText>
            <UiGrid<IReferenceToken>
                dataCy="token-ui-grid"
                tableHeight={TOKEN_TABLE_HEIGHT_PX}
                pagination
                search
                extraActionButtons={actions}
                initialSort={[ { id: 'expiration' }, { id: 'lastUsed' } ]}
                loading={isValidating}
                columns={[
                    {
                        accessor: 'description',
                        Header: translate({ id: 'CLIENT_NAME' }),
                        disableSortBy: true,
                        width: 25,
                    },
                    {
                        accessor: 'expiration',
                        Header: translate({ id: 'CLIENT_EXPIRATION_DATE' }),
                        width: 25,
                        Cell: ({ row }) => <div>
                            {
                                isBeforeGivenDate(row.original.expiration, new Date) ? (
                                    <div className={classes.expiration}>
                                        <div className={classes.expirationText}>
                                            {timestampToDate(row.original.expiration)}
                                        </div>
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_TOKEN_EXPIRED' })}
                                            placement="bottom">
                                            <ErrorIcon className={clsx(classes.redIcon, classes.expirationIcon)} />
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div>
                                        {timestampToDate(row.original.expiration)}
                                    </div>
                                )
                            }
                        </div>,
                    },
                    {
                        accessor: 'scopes',
                        Header: translate({ id: 'CLIENT_SCOPES' }),
                        width: 30,
                        disableSortBy: true,
                        Cell: ({ row }) => (
                            <div
                                className={classes.scopesText}
                                style={{ maxWidth: '80%' }}>
                                {row.original.scopes.join(', ')}
                            </div>
                        ),
                    },
                    {
                        accessor: 'lastUsed',
                        Header: translate({ id: 'CLIENT_LAST_IN_USE' }),
                        width: 20,
                        Cell: ({ row }) => <div>
                            {
                                !row.original.lastUsed ? (
                                    <div>
                                        {translate({ id: 'CLIENT_NEVER' })}
                                    </div>
                                ) : (
                                    <div>
                                        {userReadableTime(row.original.lastUsed)}
                                    </div>
                                )
                            }
                        </div>,
                    },
                ]}
                data={tokenData ?? []}
                rowActions={[
                    {
                        type: ButtonType.Button,
                        label: translate({ id: 'CLIENT_REGENERATE' }),
                        tooltip: translate({ id: 'CLIENT_REGENERATE' }),
                        actionType: GridActionType.Row,
                        click: row => onRegenerateToken(row.original),
                        dataCy: 'action-regenerate-token',
                    },
                    {
                        type: ButtonType.Button,
                        label: (
                            <UiText className={classes.redIcon}>
                                {translate({ id: 'CLIENT_REVOKE' })}
                            </UiText>
                        ),
                        tooltip: translate({ id: 'CLIENT_REVOKE' }),
                        actionType: GridActionType.Row,
                        click: row => onRevokeToken(row.original),
                        dataCy: 'action-revoke-token',
                    },
                ]}
            />
        </div>
    );
};

export default PersonalAccessTokenSettingsComponent;
