import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import GeneralSettingsComponent from './GeneralSettingsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        generalSettings: {
            width: '100%',
            height: 'calc(100% - 40px)',
        },
    }),
);

const GeneralSettingsTabContent: React.FC = () => {
    const classes = useStyles();

    return (
        <div
            className={classes.generalSettings}
            data-cy="general-settings-page">
            <GeneralSettingsComponent />
        </div>
    );
};

export default GeneralSettingsTabContent;
