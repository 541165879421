import { UiProgressButton } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => createStyles({
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttonWidth: { width: '160px' },
    cancelButton: { marginRight: '10px' },
}));

const ExternalIdentityProvidersFormButtonsComponent: FC<{
    cancel: () => void;
    loading: boolean;
    disabled: boolean;
}> = ({
    cancel, loading, disabled,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return (
        <div className={classes.actions}>
            <Button
                className={classes.cancelButton}
                onClick={cancel}
                color="primary"
                data-cy="azure-cancel-button">
                {translate({ id: 'CLIENT_CANCEL' })}
            </Button>
            <UiProgressButton
                innerButtonClass={classes.buttonWidth}
                loading={loading}
                disabled={disabled}
                variant="contained"
                type="submit"
                data-cy="azure-save-button"
            >
                {translate({ id: 'CLIENT_SAVE' })}
            </UiProgressButton>
        </div>
    );
};

export default ExternalIdentityProvidersFormButtonsComponent;
