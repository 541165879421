import { UiLoader } from '@experiences/ui-common';
import React, { useEffect } from 'react';

import { clearAuthStorage } from '../auth/utils/ClearStorage';

export const ClearAuthStorageComponent = () => {
    useEffect(() => {
        clearAuthStorage();
    }, []);

    return <UiLoader type="full" />;
};
