import type { IFolderTreeDTO } from '@experiences/ui-common';

import type { ActionTypes } from '../reducers/actionTypes';

export enum NotificationMode {
    InApp = 'InApp',
    Slack = 'Slack',
    Teams = 'Teams',
    Email = 'Email',
}
export enum NotificationSettingsMode {
    MyNotification = 'My Notification',
    ManageNotification = 'Manage Notification',
}
export enum ProfileType {
    UserGroup = 'UserGroup',
    NewUserGroup = 'NewUserGroup',
    User = 'User',
}
export interface INotificationSettingsReducer {
    tabIndex: number;
    publishers: INotificationPublisherDto[];
    publishersWithGroupedTopics: INotificationTopicPublisher[];
    publishersWithSeverity: INotificationTopicPublisher[];
}

export type INotificationSettingsAction =
    | { type: ActionTypes.NS_INITIALIZE_NOTIFICATION_SETTINGS; data: INotificationPublisherDto[] }
    | { type: ActionTypes.NS_SET_TAB_INDEX; data: INotificationSettingsActionUpdateTabIndexData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_RESET; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_CHANNEL_MODE; data: INotificationSettingsActionUpdateModeData }
    | { type: ActionTypes.NS_TOGGLE_CHANNEL_MODE_UPDATED; data: INotificationSettingsActionUpdateModeData }
    | { type: ActionTypes.NS_TOGGLE_CHANNEL_MODE_UPDATE_FAILED; data: INotificationSettingsActionUpdateModeData }
    | { type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS; data: INotificationSettingsActionRestoreDefaultData }
    | { type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED; data: INotificationSettingsActionRestoreDefaultData }
    | { type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED; data: INotificationSettingsActionRestoreDefaultData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED; data: INotificationSeverityModeData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATED; data: INotificationSettingsActionUpdateTopicData }
    | { type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATE_FAILED; data: INotificationSettingsActionUpdateTopicData };

export interface IFolderEntityDto {
    id: string;
    name: string;
    parentName: string;
    type: string;
    isSubscribed: boolean;
}
export interface IEntityTypeDto {
    type: string;
    urlTemplate: string;
    projectionProperty: string;
    publicationPayloadProperty: string;
    requestType: string;
    payload: string;
}
export interface INotificationTopicPublisher {
    name: string;
    displayName: string;
    modes: INotificationMode[];
    topicGroups: INotificationTopicGroup[];
    publisherIndex: number;
    publisherId: string;
    isUpdating?: boolean;
    isMandatory: boolean;
    isMandatoryInDeterminate: boolean;
    isVisible: boolean;
    isUserOptIn: boolean;
    entityTypes: IEntityTypeDto[];
    folders?: IFolderTreeDTO[];
    entities: IFolderEntityDto[];
    publisherSubscriptionProfiles: IPublisherProfileSubscriptionPayload[];
}

export interface INotificationPublisherDto {
    name: string;
    displayName: string;
    modes: INotificationMode[];
    topics: INotificationTopicDto[];
    id: string;
    entityTypes: IEntityTypeDto[];
    folders?: IFolderNavigationDTO[];
    entities: IFolderEntityDto[];
    isUserOptin: boolean;
    publisherSubscriptionProfiles: IPublisherProfileSubscriptionPayload[];
}

export interface INotificationMode {
    name: NotificationMode;
    isSubscribed?: boolean;
    isSubscribedByDefault?: boolean;
    isIndeterminate?: boolean;
    isUpdating?: boolean;
    isActive?: boolean;
}

export interface INotificationTopicGroup {
    groupName: string;
    topics: INotificationTopic[];
    modes: INotificationMode[];
    groupIndex: number;
    isUpdating?: boolean;
    isMandatoryChecked: boolean;
    isMandatoryIndeterminate: boolean;
    isVisible: boolean;
}

export interface INotificationTopicDto {
    displayName: string;
    group: string | null;
    id: string;
    isSubscribed: boolean;
    name: string;
    parentGroup: string | null;
    topicFilters?: string;
    modes?: INotificationMode[];
    description: string;
    category: string;
    isMandatory: boolean;
    isVisible: boolean;
}

export interface INotificationTopic {
    displayName: string;
    topicId: string;
    name: string;
    isSubscribed: boolean;
    topicFiltersUrl?: string;
    topicIndex: number;
    isUpdating?: boolean;
    modes?: INotificationMode[];
    description: string;
    category: string;
    isMandatory: boolean;
    isVisible: boolean;
}

export interface IUpdateUserSubscriptionsRequestData {
    topicId: string;
    isSubscribed: boolean;
    notificationMode?: NotificationMode;
}

export interface IUpdateCategorySubscriptionModeRequestData {
    publisherId: string;
    notificationMode: NotificationMode;
    isSubscribed: boolean;
    category: string;
}

export interface INotificationSettingsActionUpdateTabIndexData {
    tabIndex: number;
}

export interface INotificationSettingsActionUpdateTopicData {
    publisherIndex: number;
    groupIndex?: number;
    topicIndex?: number;
    isSubscribed?: boolean;
    mode?: NotificationMode;
}

export interface INotificationSettingsActionUpdateModeData {
    publisherIndex: number;
    modeIndex: number;
    newModeStatus?: boolean;
}

export interface INotificationSettingsActionRestoreDefaultData {
    publisherIndex: number;
    resetData?: INotificationPublisherDto[];
}

export interface INotificationSeverityModeData {
    publisherid: string;
    severity: string;
    isSubscribed: boolean;
    mode: NotificationMode;
    groupIndex?: number;
}
export interface INotificationProfileResponse {
    value: INotificationProfile[];
}
export interface INotificationProfile {
    name: string;
    displayName: string;
    description: string;
    modifiedByUser: string;
    modifiedTime: string;
    externalUserGroupId: string;
    profileType: string;
    hasEmail: string;
    isLocalGroup: string;
}
export interface IProfileUpdateSubscriptionPayload {
    name: string;
    description: string;
    displayName: string;
    externalUserGroupId: string;
    profileType: string;
    tenantId: string;
    profileSubscriptions: IProfileSubscriptionPayload[];
    publisherSubscriptionProfiles: IPublisherProfileSubscriptionPayload[];
    hasEmail: boolean;
    isLocalGroup: boolean;
}

export interface IProfileSubscriptionPayload {
    topicId: string;
    isSubscribed: boolean;
    notificationMode: string;
    isMandatory: boolean;
    isVisible: boolean;
}
export interface IPublisherProfileSubscriptionPayload {
    channelName: string;
    channelKey: string;
    notificationMode: string;
    publisherId: string;
    teamName: string;
    teamId: string;
}
interface IFolderNavigationDTO {
    Description: string;
    Key: string;
    ParentKey: string;
    HasChildren: boolean;
    DisplayName: string;
    ParentId: number;
    Level: number;
    FolderType: string;
}

export interface IFolderUpdatePayloadEntity {
    id: string;
    name: string;
    type: string;
    parentName: string;
    isSubscribed: boolean;
}

export interface IFolderUpdatePayload {
    publisherID: string;
    isUserOptIn: boolean;
    entities: IFolderUpdatePayloadEntity[];
}

export interface IOrchestratorFolderEntity {
    HasChildren: boolean;
    IsSelectable: boolean;
    IsPersonal: boolean;
    Key: string;
    DisplayName: string;
    FullyQualifiedName: string;
    FolderType: string;
    ProvisionType: string;
    PermissionModel: string;
    ParentId: string;
    ParentKey: string;
    entities: IFolderUpdatePayloadEntity[];
    Id: number;
}

export interface IEmailConfiguration {
    senderName: string;
    senderId: string;
    digestSchedule: number;
    senderLogo: string;
    isAccountLevel: boolean;
    emailFooter: string;
    senderLogoPrefix: string;
    isEnabled: boolean;
}
export enum NotificationSeverity {
    Info = 'Info',
    Success = 'Success',
    Warn = 'Warn',
    Error = 'Error',
    Fatal = 'Fatal',
}

export interface IConnectionsResponse {
    connections: IConnectionDetail[];
}
export interface IConnectionDetail {
    id: string;
    name: string;
    connectorName: string;
}

export interface ISlackConnectionConfiguration {
    name: string;
    connectorType: string;
    supplementConnectionId: string;
    isScopedToAccount: boolean;
    isEnabled: boolean;
    channelKey: string;
    channelName: string;
}
export interface ITeamsConnectionConfiguration {
    name: string;
    connectorType: string;
    supplementConnectionId: string;
    isScopedToAccount: boolean;
    isEnabled: boolean;
    channelKey: string;
    channelName: string;
    teamId: string;
    teamName: string;
}

export interface ISlackChannel {
    id: string;
    name: string;
}

export interface ITeamsChannel {
    id: string;
    displayName: string;
}

export interface ITeamsTeam {
    id: string;
    displayName: string;
}
