import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { DeleteOrgEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { LeavingOrganizationLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import {
    getTenants,
    getTenantsV2,
    organizationManagementTenantUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    companyName,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import OrganizationDeleteReasonsDialogBody from './OrganizationDeleteReasonsDialogBody';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteOrg: { marginTop: '24px' },
        deleteOrgBox: {
            borderRadius: '3px',
            border: `1px solid ${theme.palette.semantic.colorErrorIcon}`,
            marginTop: '24px',
            maxWidth: '518px',
            paddingLeft: '16px',
            paddingTop: '16px',
        },
        deleteOrgTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        deleteOrgDetails: {
            fontSize: '14px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        deleteOrgDetailsIcon: {
            marginBottom: '-7px',
            paddingRight: '2px',
            marginRight: '2px',
        },
        deleteOrgDialogIcon: { marginTop: '3.5px' },
        deleteOrgButton: {
            marginTop: '16px',
            marginBottom: '16px',
            color: `${theme.palette.semantic.colorErrorText} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
                borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            },
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            width: '120px',
        },
        deleteOrgActions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        leaveOrgLink: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
    }),
);

const OrganizationDelete: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });
    const createDialog = useShowDialog();
    const theme = useTheme();

    const orgName = useSelector(companyName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);
    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);

    const { logEvent } = useTelemetryHelper();

    const { data } = useSWR(
        !EnableGetTenantsRedesign
            ? {
                url: organizationManagementTenantUri,
                organizationGuid: partitionGlobalId,
                accountName,
                includeTenantServices: true,
            }
            : {
                url: organizationManagementTenantUri,
                organizationGuid: partitionGlobalId,
                accountName,
                tenantStatus: 'All',
                includeTenantServices: true,
            },
        !EnableGetTenantsRedesign ? getTenants : getTenantsV2,
    );

    const openDeleteOrgReasonsDialog = useCallback(async () => {
        logEvent(DeleteOrgEvent.DialogOpened);
        await createDialog({
            customDialogContent: OrganizationDeleteReasonsDialogBody,
            customDialogContentProps: {
                orgName,
                tenantList: data,
            },
            title: translate({ id: 'CLIENT_DELETE_ORGANIZATION' }),
            showCancel: false,
            icon: (
                <WarningIcon
                    className={classes.deleteOrgDialogIcon}
                    style={{ color: theme.palette.semantic.colorWarningIcon }}
                />
            ),
        });
    }, [
        logEvent,
        createDialog,
        orgName,
        data,
        translate,
        classes.deleteOrgDialogIcon,
        theme.palette.semantic.colorWarningIcon,
    ]);

    return (
        <div
            className={classes.deleteOrg}
            data-cy="delete-org-section">
            <UiText
                className={classes.deleteOrgTitle}
                data-cy="delete-organization-title">
                {translate({ id: 'CLIENT_DELETE_ORGANIZATION' })}
            </UiText>
            <UiText
                className={classes.deleteOrgDetails}
                data-cy="delete-organization-details">
                <FormattedMessage
                    id="CLIENT_DELETE_ORGANIZATION_DETAILS"
                    values={{
                        b: (chunk: React.ReactNode[]) => <b>
                            {chunk}
                        </b>,
                    }}
                />
            </UiText>
            <div className={classes.deleteOrgActions}>
                <Button
                    className={classes.deleteOrgButton}
                    onClick={async () => {
                        logEvent(DeleteOrgEvent.ButtonClicked);
                        await openDeleteOrgReasonsDialog();
                    }}
                    data-cy="delete-organization-button"
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </Button>
                <Link
                    href={getLocalizedLink({ articleSlug: LeavingOrganizationLink })}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    className={classes.leaveOrgLink}
                >
                    {translate({ id: 'CLIENT_LEAVE_ORG_INSTEAD' })}
                </Link>
            </div>
        </div>
    );
};

export default OrganizationDelete;
