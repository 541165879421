import { useTheme } from '@mui/material/styles';
import Tokens from '@uipath/apollo-core';
import type { MotionValue } from 'framer-motion';
import {
    animate,
    useMotionValue,
} from 'framer-motion';
import { useEffect } from 'react';

export function useCustomDrag(value: MotionValue<number>) {
    const theme = useTheme();

    const inactiveShadow = 'none';
    const activeShadow = Tokens.Shadow.ShadowDp4;
    const boxShadow = useMotionValue(inactiveShadow);

    const inactiveBackground = theme.palette.semantic.colorBackground;
    const activeBackground = theme.palette.semantic.colorHover;
    const backgroundColor = useMotionValue(inactiveBackground);

    const inactiveCursor = 'grab';
    const activeCursor = 'grabbing';
    const cursor = useMotionValue(inactiveCursor);

    useEffect(() => {
        let isActive = false;

        const unsubscribe = value.onChange((latest) => {
            const wasActive = isActive;

            if (latest !== 0) {
                isActive = true;

                if (isActive !== wasActive) {
                    animate(boxShadow, activeShadow);
                    animate(backgroundColor, activeBackground);
                    animate(cursor, activeCursor);
                }
            } else {
                isActive = false;

                if (isActive !== wasActive) {
                    animate(boxShadow, inactiveShadow);
                    animate(backgroundColor, inactiveBackground);
                    animate(cursor, inactiveCursor);
                }
            }
        });

        return unsubscribe;
    }, [
        value,
        boxShadow,
        backgroundColor,
        activeShadow,
        inactiveShadow,
        activeBackground,
        inactiveBackground,
        cursor,
    ]);

    return {
        boxShadow,
        backgroundColor,
        cursor,
    };
}
