import type {
    Recommendation,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getRecommendations } from '../services/getRecommendations';
import {
    openResourceUrl,
    processRecommendation,
    selection,
    transform,
} from './util/RecommendationsUtil';

export const RecommendationTemplatesWidget: WidgetTemplate<Recommendation> = {
    name: 'RecommendationTemplates',
    titleKey: 'CLIENT_TEMPLATE_WIDGET_TITLE',
    emptyMsgKey: () => 'CLIENT_TEMPLATE_WIDGET_EMPTY',
    selection,
    preview: true,
    fetch: async (ctx: WidgetContext) => {
        const catalogResponse = await getRecommendations(
            ctx.accessToken,
            ctx.persona,
            ctx.language,
            'ZN-01');

        return processRecommendation(catalogResponse);
    },
    type: 'Card',
    cardRows: 2,
    transform,
    clickHandler: openResourceUrl,
    actionHandles: [
        {
            id: 'try-it-rec',
            handle: openResourceUrl,
        },
    ],
};
