import { GlobalStyles } from '@experiences/theme';
import {
    UiLoader,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountLogicalName } from './helpers/EcommerceSelectors';
import useEcommerceOrderLoadingViewModel from './useEcommerceOrderLoadingViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        orderLoadingBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
        },
        paymentIsProcessingTitle: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            marginBottom: '12px',
            color: theme.palette.semantic.colorForeground,
        },
        paymentIsProcessingSubtitle: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '12px',
            color: theme.palette.semantic.colorForeground,
        },
    }),
}));

export const EcommerceOrderLoadingComponent: React.FC = () => {
    const accountNameFromSelector = useSelector(accountLogicalName);
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    useEcommerceOrderLoadingViewModel(accountNameFromSelector);

    return (
        <div className={classes.orderLoadingBody}>
            <UiText className={classes.paymentIsProcessingTitle}>
                {translate({ id: 'CLIENT_ECOMMERCE_PAYMENT_UNDER_PROCESSING_LOADING_TITLE' })}
            </UiText>
            <UiText className={classes.paymentIsProcessingSubtitle}>
                {translate({ id: 'CLIENT_ECOMMERCE_PAYMENT_UNDER_PROCESSING_LOADING_SUBTITLE' })}
            </UiText>
            <UiLoader />
        </div>
    );
};

const EcommerceOrderLoading = () => (
    <>
        <EcommerceOrderLoadingComponent />
        <UiSuspensefulOutlet />
    </>
);
EcommerceOrderLoading.displayName = 'EcommerceOrderLoadingComponent';
export default EcommerceOrderLoading;
