import { UiText } from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ShouldChangePassword } from '../../../common/constants/AuthSettingConstant';
import type { ISecurityAuthenticationSettingsData } from '../../../common/interfaces/authSetting';

const useStyles = makeStyles(theme =>
    createStyles({
        formWrapper: {
            flex: 1,
            height: '100%',
        },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        inputLabel: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        inputAdornment: { width: 'auto' },
        outlinedInput: {
            display: 'flex',
            marginTop: '8px',
        },
        outlinedInputWrapper: { marginBottom: '20px' },
        indentedSection: { marginLeft: '8px' },
        singleColumn: { overflowX: 'hidden' },
    }),
);

const PasswordPolicyForm: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        control,
        formState: { errors },
    } = useFormContext<ISecurityAuthenticationSettingsData>();

    const passwordComplexity = useMemo(() => (
        <>
            <FormControl component="fieldset">
                <legend>
                    <UiText
                        role="heading"
                        className={classes.subHeading}>
                        {translate({ id: 'CLIENT_PASSWORD_COMPLEXITY' })}
                    </UiText>
                </legend>

                <div className={classes.indentedSection}>
                    <Controller
                        name="password.passwordComplexity.hasSpecialCharacter"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="security-settings-has-special-character-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_SPECIAL_CHARACTERS' })}
                                    </div>
                                }
                            />
                        )}
                    />
                    <Controller
                        name="password.passwordComplexity.hasLowercase"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="security-settings-has-lowercase-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_HAS_LOWERCASE_CHARACTERS' })}
                                    </div>
                                }
                            />
                        )}
                    />
                    <Controller
                        name="password.passwordComplexity.hasUppercase"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="security-settings-has-uppercase-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_HAS_UPPERCASE_CHARACTERS' })}
                                    </div>
                                }
                            />
                        )}
                    />

                    <Controller
                        name="password.passwordComplexity.hasDigit"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="security-settings-has-digit-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_HAS_DIGITS' })}
                                    </div>
                                }
                            />
                        )}
                    />
                </div>
            </FormControl>
            <div className={classes.outlinedInputWrapper}>
                <UiText
                    className={classes.inputLabel}
                    id="minimumPasswordLengthLabel">
                    {translate({ id: 'CLIENT_MINIMUM_PASSWORD_LENGTH' })}
                </UiText>
                <Controller
                    name="password.passwordComplexity.Length"
                    control={control}
                    rules={{
                        required: true,
                        min: 1,
                        max: 256,
                    }}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            className={classes.outlinedInput}
                            data-cy="security-settings-min-password-length-input"
                            type="number"
                            inputProps={{
                                'aria-labelledby': 'minimumPasswordLengthLabel',
                                min: 1,
                                max: 256,
                            }}
                            error={!!errors.password?.passwordComplexity?.Length}
                            helperText={
                                !!errors.password?.passwordComplexity?.Length
                                && translate({ id: 'CLIENT_PASSWORD_POLICY_MIN_LENGTH_ERROR' })
                            }
                        />}
                />
            </div>
            <div className={classes.outlinedInputWrapper}>
                <UiText
                    className={classes.inputLabel}
                    id="passwordExpirationLabel">
                    {translate({ id: 'CLIENT_DAYS_BEFORE_PASSWORD_EXPIRATION' })}
                </UiText>
                <Controller
                    name="password.defaultExpirationDays"
                    control={control}
                    rules={{
                        required: true,
                        min: 0,
                        max: 1000,
                    }}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            className={classes.outlinedInput}
                            data-cy="security-settings-default-expiration-days-input"
                            type="number"
                            inputProps={{
                                'aria-labelledby': 'passwordExpirationLabel',
                                min: 0,
                                max: 1000,
                            }}
                            error={!!errors.password?.defaultExpirationDays}
                            helperText={
                                !!errors.password?.defaultExpirationDays && translate({ id: 'CLIENT_PASSWORD_POLICY_EXPIRATION_ERROR' })
                            }
                        />}
                />
            </div>
            <div className={classes.outlinedInputWrapper}>
                <UiText
                    className={classes.inputLabel}
                    id="previousPasswordLimitLabel">
                    {translate({ id: 'CLIENT_PREVIOUS_PASSWORD_USE_LIMIT' })}
                </UiText>
                <Controller
                    name="password.previousUseLimit"
                    control={control}
                    rules={{
                        required: true,
                        min: 0,
                        max: 25,
                    }}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            className={classes.outlinedInput}
                            data-cy="security-settings-previous-use-limit-input"
                            type="number"
                            inputProps={{
                                'aria-labelledby': 'previousPasswordLimitLabel',
                                min: 0,
                                max: 25,
                            }}
                            error={!!errors.password?.previousUseLimit}
                            helperText={
                                !!errors.password?.previousUseLimit && translate({ id: 'CLIENT_PASSWORD_POLICY_PREVIOUS_USE_LIMIT_ERROR' })
                            }
                        />}
                />
            </div>
            <div>
                <UiText className={classes.inputLabel}>
                    {translate({ id: 'CLIENT_CHANGE_PASSWORD_ON_LOGIN' })}
                </UiText>
                <Controller
                    name="password.shouldChangePasswordAfterFirstLogin"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            {...field}
                            className={classes.indentedSection}>
                            <FormControlLabel
                                value={ShouldChangePassword.NotRequired}
                                control={
                                    <Radio
                                        data-cy="security-settings-should-not-change-password-radio"
                                        color="primary"
                                    />
                                }
                                label={translate({ id: 'CLIENT_NOT_REQUIRED' })}
                            />
                            <FormControlLabel
                                value={ShouldChangePassword.Required}
                                control={
                                    <Radio
                                        data-cy="security-settings-should-change-password-radio"
                                        color="primary"
                                    />
                                }
                                label={translate({ id: 'CLIENT_REQUIRED' })}
                            />
                        </RadioGroup>
                    )}
                />
            </div>
        </>
    ), [ classes, control, errors, translate ]);

    const accountLockout = useMemo(() => (
        <>
            <FormControl
                component='fieldset'>
                <legend>
                    <UiText
                        className={classes.subHeading}
                        id="clientAccountLockoutLabel">
                        {translate({ id: 'CLIENT_ACCOUNT_LOCKOUT' })}
                    </UiText>
                </legend>

                <Controller
                    name="userLockOut.isEnabled"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            className={classes.switchFormControl}
                            control={
                                <Switch
                                    className={classes.indentedSection}
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                    data-cy="security-settings-user-lock-out-enabled-switch"
                                    inputProps={{ 'aria-labelledby': 'clientAccountLockoutLabel' }}
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: field.value === true ? 'CLIENT_ENABLED' : 'CLIENT_DISABLED' })}
                                </div>
                            }
                        />
                    )}
                />
            </FormControl>
            <div className={classes.outlinedInputWrapper}>
                <UiText
                    className={classes.inputLabel}
                    id="accountLockoutLabel">
                    {translate({ id: 'CLIENT_ACCOUNT_LOCKOUT_DURATION' })}
                </UiText>
                <Controller
                    name="userLockOut.defaultAccountLockoutSeconds"
                    control={control}
                    rules={{
                        required: true,
                        min: 0,
                        max: 2592000,
                    }}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            className={classes.outlinedInput}
                            data-cy="security-settings-account-lockout-duration-input"
                            type="number"
                            inputProps={{
                                'aria-labelledby': 'accountLockoutLabel',
                                min: 0,
                                max: 2592000,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={classes.inputAdornment}>
                                        {translate({ id: 'CLIENT_SECONDS' })}
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.userLockOut?.defaultAccountLockoutSeconds}
                            helperText={
                                !!errors.userLockOut?.defaultAccountLockoutSeconds &&
                                translate({ id: 'CLIENT_ACCOUNT_LOCKOUT_DURATION_ERROR' })
                            }
                        />}
                />
            </div>
            <div className={classes.outlinedInputWrapper}>
                <UiText
                    className={classes.inputLabel}
                    id="consecutiveLoginLabel">
                    {translate({ id: 'CLIENT_CONSECUTIVE_LOGIN_ATTEMPTS' })}
                </UiText>
                <Controller
                    name="userLockOut.maxFailedAccessAttemptsBeforeLockout"
                    control={control}
                    rules={{
                        required: true,
                        min: 2,
                        max: 10,
                    }}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            className={classes.outlinedInput}
                            data-cy="security-settings-max-logout-attempts-input"
                            type="number"
                            inputProps={{
                                'aria-labelledby': 'consecutiveLoginLabel',
                                min: 2,
                                max: 10,
                            }}
                            error={!!errors.userLockOut?.maxFailedAccessAttemptsBeforeLockout}
                            helperText={
                                !!errors.userLockOut?.maxFailedAccessAttemptsBeforeLockout
                                && translate({ id: 'CLIENT_CONSECUTIVE_LOGIN_ATTEMPTS_ERROR' })
                            }
                        />}
                />
            </div>
        </>
    ), [ classes, control, errors, translate ]);

    return (
        <div className={classes.singleColumn}>
            {passwordComplexity}
            {accountLockout}
        </div>
    );
};

export default PasswordPolicyForm;
