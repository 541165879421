import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type {
    ICustomPlanForm,
    IPackage,
} from '../interfaces/ecommerce';
import useEcommerceProductQuantityViewModel from './useEcommerceProductQuantityViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        quantityInput: {
            backgroundColor: theme.palette.semantic.colorBackgroundEdit,
            maxWidth: '84px',
            '&[type=number]': { '-moz-appearance': 'textfield' },
            '& input::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input': {
                paddingTop: '11.38px',
                paddingRight: '16px',
                paddingBottom: '12px',
                paddingLeft: '16px',
            },
        },
        plusButton: {
            display: 'flex',
            fontSize: 'large',
            paddingLeft: '15%',
        },
    }),
}));

export const EcommerceProductQuantityInput:
React.FC<{
    productCode: string;
    customSkuPackage: IPackage;
    loading: boolean;
    useFormMethods: UseFormReturn<ICustomPlanForm>;
    onChangeCallback?: (...event: any[]) => void;
    // TODO//remove minQuantity as part of PLT-11099
    minQuantity?: number;
    isBasicInput?: boolean;
}> = ({
    productCode, customSkuPackage, loading, useFormMethods, onChangeCallback, minQuantity, isBasicInput,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const EnableEcommercePlanSelectionRevampM0 = useFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);

    const {
        control,
        errors,
        setValue,

        handleSubmit,
        productQuantity,
        preventOutOfBoundValues,
        preventAlphaNumericValues,
        onChangeCustomSkuPackage,
        preventEmptyValues,
    } = useEcommerceProductQuantityViewModel(productCode, customSkuPackage, useFormMethods, minQuantity);

    const renderButton = useMemo(() => !isBasicInput && EnableEcommercePlanSelectionRevampM0 && Number(productQuantity?.quantity) === 0,
        [ isBasicInput, EnableEcommercePlanSelectionRevampM0, productQuantity?.quantity ]);

    return (
        <Controller
            control={control}
            rules={{
                required: true,
                min: productQuantity!.minQuantity,
                max: productQuantity!.maxQuantity,
            }}
            name={`productQuantities.${productCode}`}
            disabled={loading}
            render={({
                field: {
                    value, onChange, name,
                },
            }) => (
                <div>
                    {renderButton && (
                        <Button
                            data-cy={`${productCode}-initial-quantity`}
                            aria-label={translate({ id: `CLIENT_PRODUCT_QUANTITY_ARIA_LABEL_${productCode}` })}
                            className={classes.plusButton}
                            startIcon={<AddIcon fontSize="large" />}
                            onClick={() => {
                                setValue(`productQuantities.${productCode}`, 1);
                                if (onChangeCallback) {
                                    onChangeCallback();
                                }
                                handleSubmit(onChangeCustomSkuPackage)();
                            }}
                        />)}
                    {!renderButton && (
                        <TextField
                            name={name}
                            data-cy={`${productCode}-quantity`}
                            InputProps={{
                                type: 'number',
                                className: classes.quantityInput,
                                inputProps: {
                                    min: productQuantity!.minQuantity,
                                    max: productQuantity!.maxQuantity,
                                    'aria-label': translate({ id: `CLIENT_PRODUCT_QUANTITY_ARIA_LABEL_${productCode}` }),
                                },
                            }}
                            defaultValue="0"
                            variant="outlined"
                            size="small"
                            onInput={e => preventOutOfBoundValues(e)}
                            onKeyPress={e => preventAlphaNumericValues(e)}
                            onBlur={e => preventEmptyValues(name, e)}
                            onChange={(event) => {
                                onChange(event.target.value);
                                if (onChangeCallback) {
                                    onChangeCallback(event);
                                }
                                handleSubmit(onChangeCustomSkuPackage)();
                            }}
                            value={value}
                            error={errors.productQuantities && !!errors.productQuantities[`${productCode}`]}
                            helperText={
                                (errors.productQuantities &&
                                    errors.productQuantities![`${productCode}`]?.type === 'required' &&
                                    translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                (errors.productQuantities &&
                                    errors.productQuantities![`${productCode}`]?.type === 'min' &&
                                    translate(
                                        { id: 'CLIENT_ECOMMERCE_MIN_ALLOWED_PRODUCT_QUANTITY' },
                                        { minQuantity: productQuantity!.minQuantity },
                                    )) ||
                                (errors.productQuantities &&
                                    errors.productQuantities![`${productCode}`]?.type === 'max' &&
                                    translate(
                                        { id: 'CLIENT_ECOMMERCE_MAX_ALLOWED_PRODUCT_QUANTITY' },
                                        { maxQuantity: productQuantity!.maxQuantity },
                                    ))
                            }
                        />)}
                </div>
            )}

        />
    );
};
