import { UiText } from '@experiences/ui-common';
import type { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';

const Header: React.FC<{
    label: string;
    variant?: FontVariantToken;
    fontSize?: string;
    fontWeight?: number;
    icon?: any;
    lineHeight?: string;
    color?: string;
}> = ({
    label, variant, fontSize, fontWeight = 600, icon, lineHeight, color,
}) => (
    <UiText
        variant={variant}
        style={{
            fontSize,
            lineHeight,
            color,
            fontWeight,
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
        }}
    >
        {icon && <span style={{ marginRight: '8px' }}>
            {icon}
        </span>}
        <span
            role='heading'
            aria-level={2}
        >
            {label}
        </span>
    </UiText>
);

export default Header;
