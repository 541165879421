import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
    Controller,
    useForm
    ,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { DirectoryIntegrationComponent } from './DirectoryIntegrationComponent';
import ExternalIdentityProvidersFormButtonsComponent from './ExternalIdentityProvidersFormButtonsComponent';
import type { IAuthSettingsData } from './ExternalIdentityProvidersFormViewModel.onprem';
import useExternalIdentityProvidersForm, { initialData } from './ExternalIdentityProvidersFormViewModel.onprem';
import InstallationKeyComponent from './InstallationKeyComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        radioPrimary: {
            fontWeight: 600,
            fontSize: '14px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px',
        },
        button: {
            width: 'fit-content',
            marginBottom: '8px',
        },
        headerRevamp: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        switchLabel: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        switchMargin: { marginLeft: '12px' },
        switchDescription: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '4px',
        },
        switchDescriptionPadding: { marginLeft: '46px' },
        signInContainer: { marginBottom: '24px' },
        hostEnforced: { fontWeight: 400 },
    }),
}));

export const ExternalIdentityProvidersFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const isHostMode = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);

    const methods = useForm<IAuthSettingsData>({
        mode: 'onSubmit',
        defaultValues: initialData,
    });

    const {
        control, formState, reset, handleSubmit, setValue, watch,
    } = methods;

    const { isDirty } = formState;

    const {
        loading, onSubmit,
    } = useExternalIdentityProvidersForm(reset);

    const handleCancel = () => reset();

    const fetchedExternalIdentityGoogle = useExternalIdentity(ExternalAuthenticationScheme.Google);
    const fetchedExternalIdentityAzureAD = useExternalIdentity(ExternalAuthenticationScheme.AzureAD);
    const fetchedExternalIdentitySaml = useExternalIdentity(ExternalAuthenticationScheme.Saml2);
    const fetchedExternalIdentityAD = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);

    const handleEditPasswordPolicy = useCallback(() => {
        navigate(getRoute(RouteNames.AuthSettingsPasswordPolicy));
    }, [ getRoute, navigate ]);

    const handleConfigureGoogle = useCallback(() => {
        navigate(getRoute(RouteNames.AuthSettingsConfigureGoogle));
    }, [ getRoute, navigate ]);

    const handleConfigureAAD = useCallback(() => {
        navigate(getRoute(RouteNames.AuthSettingsConfigureAAD));
    }, [ getRoute, navigate ]);

    const handleConfigureSaml = useCallback(() => {
        navigate(getRoute(RouteNames.AuthSettingsConfigureSaml));
    }, [ getRoute, navigate ]);

    const handleConfigureAD = useCallback(() => {
        navigate(getRoute(RouteNames.AuthSettingsConfigureAD));
    }, [ getRoute, navigate ]);

    return <UiForm
        centerChild
        onSubmit={handleSubmit(onSubmit)}
        actions={
            isDirty && <ExternalIdentityProvidersFormButtonsComponent
                cancel={handleCancel}
                loading={loading}
                disabled={!isAdmin} />
        }>
        {process.buildConfigs.enableInstallationKey && isHostMode && <InstallationKeyComponent />}
        <FormControl
            component='fieldset'
            className={classes.signInContainer}>
            <legend>
                <UiText
                    role='heading'
                    aria-level={2}
                    className={classes.headerRevamp}>
                    {translate({ id: 'CLIENT_SIGN_IN_OPTIONS_LOCAL' })}
                </UiText>
            </legend>

            <FormGroup>
                <Controller
                    name="enableBasicAuthentication"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    className={classes.switchMargin}
                                    data-cy='auth-settings-basic-sign-in-switch'
                                    checked={field.value}
                                    onChange={e => {
                                        field.onChange(e.target.checked);
                                        if (e.target.checked === true) {
                                            setValue('enableBasicAuthenticationForHostTenant', true);
                                        }
                                    }}
                                />
                            }
                            label={
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>
                                    <UiText className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_BASIC_SIGN_IN' })}
                                    </UiText>
                                </div>
                            }
                        />
                    )}
                />
                <UiText className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                    {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_BASIC' })}
                </UiText>
                <Button
                    className={clsx(classes.button, classes.switchDescriptionPadding)}
                    variant='outlined'
                    onClick={handleEditPasswordPolicy}
                    data-cy="authsettings-edit-password-policy-button">
                    {translate({ id: 'CLIENT_EDIT_PASSWORD_POLICY' })}
                </Button>
                {isHostMode && <Controller
                    name="enableBasicAuthenticationForHostTenant"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            className={classes.switchDescriptionPadding}
                            control={
                                <Checkbox
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    disabled={watch('enableBasicAuthentication') !== false}
                                    color="primary"
                                    data-cy="authsettings-restrictions-enable-basic-authentication-for-host-tenant"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_ENABLE_BASIC_AUTHENTICATION_FOR_HOST_TENANT' })}
                                </div>
                            }
                        />
                    )}
                />}
                {(isHostMode || fetchedExternalIdentityGoogle?.isActive) && <>
                    <Controller
                        name="google"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-google-switch'
                                        disabled={!isHostMode || !fetchedExternalIdentityGoogle}
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                    />
                                }
                                label={
                                    <>
                                        <UiText className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_GOOGLE_SSO' })}
                                            {!isHostMode
                                                && fetchedExternalIdentityGoogle?.isActive
                                                && <span className={classes.hostEnforced}>
                                                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                    &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                                </span>}
                                        </UiText>
                                    </>
                                }
                            />
                        )}
                    />
                    <UiText className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'Google' })}
                    </UiText>
                    {isHostMode && <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureGoogle}
                        data-cy="authsettings-configure-google-button">
                        {translate({ id: fetchedExternalIdentityGoogle ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>}
                </>}
                {isHostMode && <>
                    <Controller
                        name="aad"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-azure-ad-switch'
                                        disabled={!isHostMode || !fetchedExternalIdentityAzureAD}
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                    />
                                }
                                label={
                                    <>
                                        <UiText className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_AZURE_AD_SSO' })}
                                            {!isHostMode && fetchedExternalIdentityAD?.isActive && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </UiText>
                                    </>
                                }
                            />
                        )}
                    />
                    <UiText className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'Azure AD' })}
                    </UiText>
                    {isHostMode && <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureAAD}
                        data-cy="authsettings-configure-aad-button">
                        {translate({ id: fetchedExternalIdentityAzureAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>}
                </>}
                {(isHostMode || fetchedExternalIdentityAD?.isActive) && <>
                    <Controller
                        name="ad"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-ad-switch'
                                        disabled={!isHostMode || !fetchedExternalIdentityAD}
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                    />
                                }
                                label={
                                    <>
                                        <UiText className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_AD_INTEGRATION' })}
                                            {!isHostMode && fetchedExternalIdentityAD?.isActive && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </UiText>
                                    </>
                                }
                            />
                        )}
                    />
                    <UiText className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'Active Directory' })}
                    </UiText>
                    {isHostMode && <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureAD}
                        data-cy="authsettings-configure-ad-button">
                        {translate({ id: fetchedExternalIdentityAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>}
                </>}
                {isHostMode && <>
                    <Controller
                        name="saml2"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-saml2-switch'
                                        disabled={!isHostMode || !fetchedExternalIdentitySaml}
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                    />
                                }
                                label={
                                    <>
                                        <UiText className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_SAML_SSO' })}
                                            {!isHostMode && fetchedExternalIdentitySaml?.isActive && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </UiText>
                                    </>
                                }
                            />
                        )}
                    />
                    <UiText className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'SAML' })}
                    </UiText>
                    {isHostMode && <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureSaml}
                        data-cy='auth-settings-configure-saml-button'>
                        {translate({ id: fetchedExternalIdentitySaml ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>}
                </>}
            </FormGroup>
        </FormControl>
        <DirectoryIntegrationComponent />
    </UiForm>;
};
