import React from 'react';
import { useLocation } from 'react-router-dom';

import { EcommerceCheckoutComponent } from './EcommerceCheckoutComponent';
import { EcommerceProvider } from './helpers/useEcommerce';

export const EcommerceCheckoutWrapperComponent: React.FC = () => {
    const location = useLocation();
    return <EcommerceProvider>
        <EcommerceCheckoutComponent
            location={location} />
    </EcommerceProvider>;
};
