import { AccountLicense } from '@experiences/constants';
import type { AccountType } from '@experiences/interfaces';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { accountType } from '../../store/selectors';
import useIsOnPrem from './useIsOnPrem';

const useCheckLicense = () => {

    const licensePlan = useSelector(accountType);
    const isOnPrem = useIsOnPrem();

    const includesLicense = useCallback((licenses: AccountLicense[]) => licenses.includes(AccountLicense[licensePlan]), [ licensePlan ]);

    const notIncludesLicense = useCallback((licenses: AccountLicense[]) => !licenses.includes(AccountLicense[licensePlan])
        , [ licensePlan ]);

    const isOffersRevampAndCommunity = useMemo(() =>
        AccountLicense[licensePlan] <= AccountLicense.FREEKIT && AccountLicense[licensePlan] >= AccountLicense['PRO-TRIAL']
    , [ licensePlan ]);

    const isFreeOrCommunityRevamp = useMemo(() => AccountLicense[licensePlan] >= AccountLicense.COMMUNITY
        , [ licensePlan ]);

    const isFreeOrCommunity = useCallback((accountLicense?: AccountType) => accountLicense
        ? AccountLicense[accountLicense] >= AccountLicense.COMMUNITY
        : AccountLicense[licensePlan] >= AccountLicense.COMMUNITY, [ licensePlan ]);

    const isEnterprise = useMemo(() => AccountLicense[licensePlan] === AccountLicense.ENTERPRISE, [ licensePlan ]);

    const isCommunity = useMemo(() => AccountLicense[licensePlan] === AccountLicense.COMMUNITY, [ licensePlan ]);

    const isAccountLicenseEnterpriseOrPro = useCallback((accountLicense?: AccountType) => accountLicense
        ? AccountLicense[accountLicense] === AccountLicense.ENTERPRISE || AccountLicense[accountLicense] === AccountLicense.PRO
        : includesLicense([ AccountLicense.ENTERPRISE, AccountLicense.PRO ]), [ includesLicense ]);

    const isUserEnterprise = useMemo(() => AccountLicense[licensePlan] <= AccountLicense.TRIAL, [ licensePlan ]);

    const checkLicense = useCallback((licenses: AccountLicense[], licenseToCheck?: AccountType) =>
        licenseToCheck ? licenses.includes(AccountLicense[licenseToCheck]) : false, []);

    const isUnpaidLicense = useMemo(() =>
        includesLicense([ AccountLicense.TRIAL, AccountLicense.COMMUNITY, AccountLicense.FREEKIT, AccountLicense['PRO-TRIAL'] ])
    , [ includesLicense ]);

    const isUnlicensed = useMemo(() => includesLicense([ AccountLicense.UNLICENSED ]), [ includesLicense ]);

    const isPro = useMemo(() => includesLicense([ AccountLicense.PRO ]), [ includesLicense ]);

    const canHaveOnlineEnterpriseActivation = useMemo(() => {
        const validAccountTypes = [ AccountLicense.COMMUNITY, AccountLicense.FREEKIT, AccountLicense.TRIAL, AccountLicense['PRO-TRIAL'] ];
        const hasValidLicenseType = checkLicense(validAccountTypes, licensePlan);
        return !isOnPrem && (hasValidLicenseType || isPro);
    }, [ isOnPrem, licensePlan, checkLicense, isPro ]);

    return {
        checkLicense,
        includesLicense,
        isAccountLicenseEnterpriseOrPro,
        isEnterprise,
        isFreeOrCommunity,
        isFreeOrCommunityRevamp,
        isOffersRevampAndCommunity,
        isUserEnterprise,
        notIncludesLicense,
        isUnpaidLicense,
        isCommunity,
        isUnlicensed,
        isPro,
        canHaveOnlineEnterpriseActivation,
    };
};

export default useCheckLicense;
