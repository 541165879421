import {
    UiProgressButton,
    UiSuspensefulOutlet,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import * as RouteNames from '../../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../../common/providers/BreadcrumbProvider';
import UiForm from '../../../common/UiForm';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../../organizationsettings/AdminBreadCrumbs';
import useAddEditTenantTagsLabelsViewModel from './AddEditTenantTagsLabelsViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        input: { marginBottom: '26px' },
    }),
);

const AddEditTenantTagsLabelsComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        cancel,
        loading,
        methods: {
            register,
            formState: {
                errors, isSubmitting, isDirty,
            },
            handleSubmit,
        },
    } = useAddEditTenantTagsLabelsViewModel({ type });

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            maxWidth='900px'
            loading={loading}
        >
            <UiForm
                onSubmit={handleSubmit}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => cancel()}
                            color="primary"
                            data-cy="add-edit-tenant-tag-label-cancel"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            variant="contained"
                            data-cy="tenant-tag-label-add-edit-button"
                            disabled={!isDirty || Object.keys(errors).length > 0}
                        >
                            {translate({ id: type === 'add' ? 'CLIENT_ADD' : 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                centerChild
                dataCy="tenant-settings-component"
            >
                <TextField
                    className={classes.input}
                    helperText={errors?.name?.message}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_NAME' })}
                    required
                    error={!!errors.name}
                    variant="outlined"
                    label={translate({ id: 'CLIENT_NAME' })}
                    fullWidth
                    data-cy="tenant-tags-label-name"
                    inputProps={{
                        'aria-label': translate({ id: 'CLIENT_NAME' }),
                        ...register('name', {
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_NAME' }) },
                            ),
                            validate: v => !!v?.trim(),
                        }),
                    }}
                />
                <TextField
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_DESCRIPTION_OPTIONAL' })}
                    multiline
                    rows={3}
                    error={!!errors.description}
                    variant="outlined"
                    label={translate({ id: 'CLIENT_DESCRIPTION' })}
                    fullWidth
                    data-cy="tenant-tags-label-description"
                    inputProps={{
                        'aria-label': translate({ id: 'CLIENT_DESCRIPTION' }),
                        ...register('description'),
                    }}
                />
            </UiForm>

        </UiPageContainer>
    );
};

export const AddEditTenantTagsLabelsComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const { type } = useParams<{ type: 'add' | 'edit' }>();

    const {
        tenant,
        tenantId,
        label,
    } = useAddEditTenantTagsLabelsViewModel({ type: type ?? 'add' });

    const breadCrumbLinks = useMemo(() =>
        process.buildConfigs.showForMSI
            ? [
                {
                    index: 0,
                    link: RouteNames.OrganizationAdminHome,
                    name: organizationName,
                },
                {
                    index: 1,
                    link: RouteNames.TenantTagsLabelsMsi,
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    index: 2,
                    link: RouteNames.TenantTagsLabelsMsi,
                    name: translate({ id: 'CLIENT_LABELS' }),
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsAddMsi,
                    name: type === 'add'
                        ? translate({ id: 'CLIENT_ADD_LABEL' })
                        : translate({ id: 'CLIENT_EDIT_LABEL' }, { label: label?.name ?? '' }),
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsEditMsi,
                    name: type === 'add'
                        ? translate({ id: 'CLIENT_ADD_LABEL' })
                        : translate({ id: 'CLIENT_EDIT_LABEL' }, { label: label?.name ?? '' }),
                },
            ]
            : [
                {
                    index: 0,
                    link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                    name: tenant?.name ?? '',
                },
                {
                    index: 1,
                    link: RouteNames.TenantTagsLabels.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    index: 2,
                    link: RouteNames.TenantTagsLabels.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_LABELS' }),
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsAdd.replace(':tenantId', tenantId),
                    name: type === 'add'
                        ? translate({ id: 'CLIENT_ADD_LABEL' })
                        : translate({ id: 'CLIENT_EDIT_LABEL' }, { label: label?.name ?? '' }),
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsEdit.replace(':tenantId', tenantId),
                    name: type === 'add'
                        ? translate({ id: 'CLIENT_ADD_LABEL' })
                        : translate({ id: 'CLIENT_EDIT_LABEL' }, { label: label?.name ?? '' }),
                },
            ],
    [ label?.name, organizationName, tenant?.name, tenantId, translate, type ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <AddEditTenantTagsLabelsComponent type={type ?? 'add'} />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default AddEditTenantTagsLabelsComponent;
