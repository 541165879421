import { post } from '../utility/Requests.default';

interface ITestConnection {
    client_id: string;
    code: string;
    redirect_uri: string;
    client_secret: string;
    tenant_id: string;
}

export interface ITestConnectionResult {
    email: string;
}

export function testConnection(payload: ITestConnection) {
    return post<ITestConnectionResult>('/api/identity/TestConnection', {
        body: {
            ...payload,
            grant_type: 'authorization_code',
        },
    });
}
