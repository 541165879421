import * as RouteNames from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import type { ISettingRoute } from '../../common/interfaces/route';

export const SettingsRoutes: ISettingRoute[] = [
    {
        text: 'CLIENT_CREATE_TENANT',
        path: RouteNames.TenantCreate,
    },
    {
        text: 'CLIENT_PAGE_TENANTS',
        path: RouteNames.Services,
        subRoutes: [ RouteNames.ServicesUsage ],
    },
    {
        text: 'CLIENT_ACCOUNTS_AND_GROUPS',
        path: RouteNames.Users,
        subRoutes: [
            RouteNames.Groups,
            RouteNames.Robots,
            RouteNames.RobotsAddEdit,
            RouteNames.GroupsAdd,
            RouteNames.GroupsEdit,
            RouteNames.UserAddEdit,
            RouteNames.UserInviteEdit,
        ],
    },
    {
        text: 'CLIENT_EXTERNAL_APPLICATIONS',
        path: RouteNames.ExternalApplications,
        subRoutes: [
            RouteNames.ExternalApplicationAdd,
            RouteNames.ExternalApplicationEdit,
            RouteNames.ExternalApplicationsPAT,
            RouteNames.ExternalAppRegistrations,
        ],
    },
    {
        text: 'CLIENT_LICENSES',
        path: RouteNames.Licensing,
        subRoutes: [ RouteNames.ManagePlan, RouteNames.LicensingTenantConfigure ],
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        path: RouteNames.AuditLogs,
        subRoutes: [ RouteNames.AuditLogDialog ],
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        path: RouteNames.Audit,
        hide: true,
    },
    {
        text: 'CLIENT_ACCOUNT_SETTINGS_ACTION',
        path: RouteNames.OrganizationAdminHome,
        hide: true,
    },
    {
        text: 'CLIENT_PAGE_TENANTS',
        path: RouteNames.TenantHome,
        hide: true,
    },
    {
        text: 'CLIENT_SECURITY_SETTINGS',
        path: RouteNames.SecuritySettings,
        subRoutes: [
            RouteNames.ConfigureSSO,
            RouteNames.SecuritySettingsConfigure,
            RouteNames.SecuritySettingsSAMLProvisioningRules,
            RouteNames.SecuritySettingsSAMLProvisioningRulesAdd,
            RouteNames.SecuritySettingsSAMLProvisioningRulesEdit,
            RouteNames.SecuritySettingsAttributeMapping,
            RouteNames.SessionPolicy,
            RouteNames.IPRestriction,
            RouteNames.Encryption,
            RouteNames.OrganizationAccessPolicy,
        ],
    },
    ...(process.buildConfigs.isOnPrem
        ? [
            {
                text: 'CLIENT_SECURITY_SETTINGS_ONPREM',
                path: RouteNames.AuthSettings,
                subRoutes: [
                    RouteNames.SecuritySettings,
                    RouteNames.AuthSettingsConfigureAD,
                    RouteNames.AuthSettingsConfigureAAD,
                    RouteNames.AuthSettingsConfigureGoogle,
                    RouteNames.AuthSettingsConfigureSaml,
                    RouteNames.SecuritySettingsConfigure,
                    RouteNames.AuthSettingsPasswordPolicy,
                    RouteNames.SessionPolicy,
                    RouteNames.OrganizationAccessPolicy,
                ],
            },
        ]
        : []),
    {
        text: 'CLIENT_ACCOUNT_SETTINGS',
        path: RouteNames.OrganizationSettings,
        subRoutes: [
            ...(process.buildConfigs.enableEmailSettings ? [ RouteNames.EmailSettings ] : []),
            ...(process.buildConfigs.enableLoginCustomization ? [ RouteNames.LoginCustomization ] : []),
            RouteNames.OrganizationSettingsAdvanced,
            RouteNames.OrganizationMigration,
        ],
    },
    {
        text: 'CLIENT_TENANT_SETTINGS',
        path: RouteNames.TenantSettings,
        subRoutes: [
            RouteNames.TenantMigration,
            RouteNames.EditTenantMigration,
        ],
        hide: true,
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        path: RouteNames.TenantAudit,
        hide: true,
    },
    {
        text: 'CLIENT_TAGS',
        path: RouteNames.TenantTags,
        subRoutes: [
            RouteNames.TenantTagsLabels,
            RouteNames.TenantTagsProperties,
            RouteNames.TenantTagsLabelsAdd,
            RouteNames.TenantTagsLabelsEdit,
            RouteNames.TenantTagsPropertiesEdit,
            RouteNames.TenantTagsPropertiesDrawerEdit,
            RouteNames.TenantTagsPropertiesAdd,
            RouteNames.TenantTagsPropertiesDrawerAdd,
        ],
        hide: true,
    },
    {
        text: 'CLIENT_GATEWAY',
        path: RouteNames.VpnGateway,
        subRoutes: [ RouteNames.VpnGatewayCreateEdit ],
        hide: true,
    },
    {
        text: 'CLIENT_GATEWAY',
        path: RouteNames.VpnGatewayConnectionsCreateEdit,
        hide: true,
    },
    {
        text: 'CLIENT_GATEWAY',
        path: RouteNames.VpnGatewayConnections,
        hide: true,
    },
    {
        text: 'CLIENT_SERVICES',
        path: RouteNames.TenantServices,
        subRoutes: [
            RouteNames.TenantServicesAdd,
            RouteNames.TenantConfigureService,
            RouteNames.TenantServiceLogExport,
        ],
        hide: true,
    },
    {
        text: 'CLIENT_MANAGE_ACCESS',
        path: RouteNames.ManageAccess,
        hide: true,
    },
    {
        text: 'CLIENT_AI_TRUST_LAYER',
        path: RouteNames.AiTrustLayerSetting,
    },
];

// TO-DO refactor logic. Tracking ticket - PLT-45152

export const HideTenantShellRoutes = [
    RouteNames.TenantCreate,
    RouteNames.TenantMigration,
    RouteNames.SecuritySettingsConfigure,
    RouteNames.OrganizationMigration,
    RouteNames.UserConsentCreate,
    RouteNames.UserConsentEdit,
    RouteNames.CheckAccess,

    routePaths.adminTenantCreate,
    routePaths.adminTenantSettingsMigration,
    routePaths.adminSecurityAuthenticationConfigureSAML,
    routePaths.adminSecurityAuthenticationConfigureAAD,
    routePaths.adminSettingsAdvancedMigration,

    routePaths.adminIdentitiesProfileGroupMemberships,
    routePaths.adminIdentitiesProfileGroupMembershipsAdd,
    routePaths.adminIdentitiesProfileMembers,
    routePaths.adminIdentitiesProfileMembersAdd,
    routePaths.adminIdentitiesProfileLicenses,
    routePaths.adminIdentitiesProfileLicensesEdit,
    routePaths.adminIdentitiesProfileLicensesInheritance,
    routePaths.adminIdentitiesProfileAccess,
    routePaths.adminIdentitiesProfileInfo,
];
