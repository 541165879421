import type { ITenant } from '@experiences/interfaces';
import type {
    FilterManager,
    VirtualScrollManager,
} from '@uipath/apollo-angular-elements';

export interface IAuditLog {
    message: string;
    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;
    accountUser: any;
    category: string;
    userName: string;
    action: string;
    email: string;
    operation: string;
    auditLogDetails: any;
}

export interface IAuditDetailsContext {
    row?: AuditEventDto;
    tenant?: ITenant;
    filterManager?: FilterManager<AuditEventSourceDto>;
    virtualScrollManager?: VirtualScrollManager<AuditEventDto>;
}

export enum AuditEventStatus {
    Success = 0,
    Failure = 1,
}

export const AuditEventStatusMap = {
    [AuditEventStatus.Failure]: 'CLIENT_FAILURE_TEXT',
    [AuditEventStatus.Success]: 'CLIENT_SUCCESS_TEXT',
};

/**
 *
 *
 * @export
 * @interface AuditEventDto
 */
export interface AuditEventDto {

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    id: string;

    /**
     * @type {number}
     * @memberof AuditEventDto
     */
    rowId: number;

    /**
     * @type {Date}
     * @memberof AuditEventDto
     */
    createdOn: Date | string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    organizationId: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    organizationName: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    tenantId: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    tenantName: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    actorId: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    actorName: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    actorEmail: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    eventType: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    eventSource: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    eventTarget: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    eventDetails: string;

    /**
     * @type {string}
     * @memberof AuditEventDto
     */
    eventSummary: string;

    /**
     * @type {AuditEventStatus}
     * @memberof AuditEventDto
     */
    status?: AuditEventStatus;

    /**
     * @type {function}
     * @memberof AuditEventDto
     */
    next: (incrementer?: number) => void;
}

/**
 *
 *
 * @interface AuditEventTypeDto
 */
interface AuditEventTypeDto {

    /**
     * @type {string}
     * @memberof AuditEventTypeDto
     */
    id: string;

    /**
     * @type {string}
     * @memberof AuditEventTypeDto
     */
    name: string;
}

export interface AuditEventTargetDto {

    /**
     * @type {string}
     * @memberof AuditEventTargetDto
     */
    id: string;

    /**
     * @type {string}
     * @memberof AuditEventTargetDto
     */
    name: string;

    /**
     * @type {Array<AuditEventTypeDto>}
     * @memberof AuditEventTargetDto
     */
    eventTypes: AuditEventTypeDto[];
}

/**
 *
 *
 * @export
 * @interface AuditEventSourceDto
 */
export interface AuditEventSourceDto {

    /**
     * @type {string}
     * @memberof AuditEventSourceDto
     */
    id: string;

    /**
     * @type {string}
     * @memberof AuditEventSourceDto
     */
    name: string;

    /**
     * @type {Array<AuditEventTargetDto>}
     * @memberof AuditEventSourceDto
     */
    eventTargets: AuditEventTargetDto[];
}

export interface AuditEventQueryResultDto {

    /**
     * @type {Array<AuditEventDto>}
     */
    auditEvents?: AuditEventDto[];

    /**
     * @type {string}
     * @memberof AuditEventQueryResultDto
     */
    next?: string | null;

    /**
     * @type {string}
     * @memberof AuditEventQueryResultDto
     */
    previous?: string | null;
}

export interface IFilter {
    id: string | number;
    text: string;
}
