import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useMemo,
} from 'react';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import type { ILicenseAndKey } from '../../../common/interfaces/licenses';
import {
    billingUrl,
    fetchPlanDetails,
} from '../../../services/licensing/BillingService';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../../services/licensing/management/AccountService';
import { useShouldShowManageButton } from '../../licensing/useShouldShowManageButton';

const useEcommerceLicensePendingChangeBannerViewModel = (isAdmin: boolean, accountName: string) => {
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();

    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );
    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);
    const shouldShowManageButton = useShouldShowManageButton(accountName, license?.accountType);

    const { data: planDetails } = useSWR(
        shouldShowManageButton ? {
            accountName,
            url: `${billingUrl}/planDetails/licensePendingChangeBanner`,
        } : null,
        fetchPlanDetails,
    );
    const [ nextPayment, subscriptionScheduleId, hasLicenceUpdateSubscriptionSchedule ] = useMemo(
        () => [
            planDetails?.nextPayment,
            planDetails?.subscriptionScheduleId,
            planDetails?.hasLicenceUpdateSubscriptionSchedule,
        ],
        [ planDetails ],
    );

    const handleManageLicensePendingChangeClick = useCallback(() => {
        navigate(getRoute(RouteNames.ViewCancelLicensePendingChange), { state: { subscriptionScheduleId } });
    }, [ navigate, getRoute, subscriptionScheduleId ]);

    const handleManageLicensePendingChangeKeyboardEvent =
        useCallback((e: { key: string }) => {
            if (e.key === 'Enter') {
                handleManageLicensePendingChangeClick();
            }
        }, [ handleManageLicensePendingChangeClick ]);

    const shouldDisplayLicensePendingChangeBanner = useMemo(
        () => isAdmin && hasLicenceUpdateSubscriptionSchedule,
        [ isAdmin, hasLicenceUpdateSubscriptionSchedule ]
    );

    return {
        nextPayment,
        handleManageLicensePendingChangeClick,
        handleManageLicensePendingChangeKeyboardEvent,
        shouldDisplayLicensePendingChangeBanner,
    };
};

export default useEcommerceLicensePendingChangeBannerViewModel;
