import { UiText } from '@experiences/ui-common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        licenseToggle: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        licenseToggleText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        licenseToggleContainer: {
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginLeft: '0.75em',
        },
    }),
);

const UiLicenseToggleComponent: React.FC<{
    name: string;
    toggleLabelValue: string;
    tooltipValue: string;
    switchName: string;
}> = ({
    name,
    toggleLabelValue,
    tooltipValue,
    switchName,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    const { control } = useFormContext();

    return (
        <div className={classes.licenseToggleContainer}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <div>
                                <Switch
                                    aria-describedby='toggleLabel'
                                    checked={field.value}
                                    onChange={e => field.onChange(e)}
                                    data-cy="license-toggle"
                                />
                            </div>
                        }
                        label={
                            <div className={classes.licenseToggleText}>
                                <UiText id="toggleLabel">
                                    {translate({ id: toggleLabelValue })}
                                </UiText>
                                <Tooltip
                                    data-cy="license-tooltip"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title={translate(
                                        { id: tooltipValue },
                                    )}
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        tabIndex={0}
                                        aria-label={translate({ id: tooltipValue })}
                                        style={{
                                            color: theme.palette.semantic.colorForegroundLight,
                                            paddingLeft: '3px',
                                            fontSize: 'x-large',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        labelPlacement='end'
                    />
                )}
            />
        </div>
    );
};

export default UiLicenseToggleComponent;
