export function hasDigit(password: string): boolean {
    return /\d/.test(password);
}

export function hasLowercase(password: string): boolean {
    return password.toUpperCase() !== password;
}

export function hasSpecialCharacter(password: string): boolean {
    // NOTE: Taken from back-end
    return /(?=.*[^a-zA-Z\d])/.test(password);
}

export function hasUppercase(password: string): boolean {
    return password.toLowerCase() !== password;
}
