import { useLocalization } from '@experiences/locales';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getPriceString } from '../helpers/EcommerceHelpers';
import { accountLogicalName } from '../helpers/EcommerceSelectors';
import type {
    IEcommerceCheckoutFormState,
    IPackage,
    IProduct,
    IProductPriceOptions,
    ITaxResponse,
} from '../interfaces/ecommerce';
import { EcommercePlanTypeRadioGroup } from '../subcomponents/EcommercePlanTypeRadioGroup';
import EcommerceEuLegalText from './EcommerceEuLegalText';
import EcommerceOrderSummaryFooter from './EcommerceOrderSummaryFooter';
import useEcommercePlanSummaryViewModel from './useEcommercePlanSummaryViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        summaryContainer: {
            borderStyle: 'round',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.semantic.colorChipInfoBackground,
            fontFamily: 'Noto Sans',
        },
        borderContainer: {
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.semantic.colorBorderDeEmp,
            borderBottomWidth: '2px',
            marginLeft: '20px',
            marginRight: '20px',
        },
        topContainer: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            padding: '20px',
        },
        bottomContainer: {
            minHeight: '85px',
            padding: '15px 20px',
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        title: {
            marginTop: '2px',
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        packRow: {
            fontWeight: 600,
            lineHeight: '20px',
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        detailsRow: {
            marginTop: '4px',
            marginBottom: '10px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 400,
            lineHeight: '16px',
            fontSize: '12px',
        },
        alignRight: { marginLeft: 'auto' },
        circularLoading: { borderRadius: '30px' },
        planType: {
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '20px',
            marginBottom: '3px',
            color: theme.palette.semantic.colorForeground,
        },
        orderSummarySubtitle: {
            marginTop: '42px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        orderSummaryText: {
            color: theme.palette.semantic.colorForeground,
            marginBottom: '70px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        automationExpressContainer: {
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
        },
        automationExpressTitle: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        centered: { alignSelf: 'center' },
        percentageOff: {
            color: theme.palette.semantic.colorSuccessText,
            whiteSpace: 'pre',
        },
        chip: {
            fontWeight: 600,
            lineHeight: '16px',
            fontSize: '10px',
            color: theme.palette.semantic.colorLogo,
            pointerEvents: 'none',
            backgroundColor: theme.palette.semantic.colorChipErrorBackground,
            borderColor: theme.palette.semantic.colorChipErrorBackground,
        },
        payNowButton: { marginTop: '40px' },
    }),
);

export const EcommercePlanSummary: React.FC<{
    type: string;
    products: IProduct[];
    currency: string;
    price?: number;
    tax?: ITaxResponse;
    taxLoading?: boolean;
    productPricesLoading?: boolean;
    isFirstSubscription: boolean;
    checkoutFormState?: IEcommerceCheckoutFormState;
    width?: string;
    productsPricesInAllCurrencies: IProductPriceOptions[] | undefined;
    disablePlanTypeSelection?: boolean;
    annualSavings?: number;
    planPackage?: IPackage;
    isFormValid: boolean;
    isDirectBuyPagesSource: boolean;
}> = ({
    products,
    currency,
    price,
    tax,
    taxLoading,
    productPricesLoading,
    isFirstSubscription,
    checkoutFormState,
    width,
    productsPricesInAllCurrencies,
    disablePlanTypeSelection,
    annualSavings,
    planPackage,
    isFormValid,
    isDirectBuyPagesSource = false,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();
    const currentAccountName = useSelector(accountLogicalName);
    const {
        currentSkuPackageDetails, setSelectedPlanType, emptyData, areQuantitiesRequirementsMet, getUnitPrice,
        getProductPriceConsideringQuantity, subscriptionPrice, isPayButtonDisabled,
        isPayButtonLoading, buySkuPackage, submitCheckoutFormRef, continueToDirectBuy,
    } = useEcommercePlanSummaryViewModel(
        currentAccountName, products, isFormValid, planPackage, currency, price, productsPricesInAllCurrencies, checkoutFormState
    );

    return (
        <div
            className={classes.summaryContainer}
            style={{ width: width ?? '100%' }}
            data-cy="summary-container">
            <div className={classes.topContainer}>
                <UiText className={classes.title}>
                    {translate({ id: 'CLIENT_PLAN_SUMMARY' })}
                </UiText>
                <EcommercePlanTypeRadioGroup
                    setPlanType={setSelectedPlanType}
                    planType={currentSkuPackageDetails.planType}
                    disabled={disablePlanTypeSelection}
                />
            </div>
            <div className={classes.borderContainer} />
            <div className={classes.topContainer}>
                {emptyData ? (
                    <>
                        <UiText className={clsx(classes.orderSummarySubtitle, classes.centered)}>
                            {translate({ id: `CLIENT_THERES_NO_CHANGES_IN_YOUR_PLAN` })}
                        </UiText>
                        <UiText className={clsx(classes.orderSummaryText, classes.centered)}>
                            {translate({ id: `CLIENT_CHANGE_THE_LICENSE_QUANTITY` })}
                        </UiText>
                    </>
                ) : (<div>
                    {products.filter(p => p.quantity > 0).map((product, index) => (
                        <div key={product.code}>
                            <div className={classes.row}>
                                <UiText
                                    className={classes.packRow}
                                    key={index}
                                    data-cy={`monthly-product-name-${product.code}`}>
                                    {`${
                                        product.quantity > 0 ? `${product.quantity} ` : ''
                                    }${translate({ id: `CLIENT_PRODUCT_${product.code}` })}`}
                                </UiText>
                                <UiText
                                    className={classes.alignRight}
                                    data-cy={`monthly-product-price-${product.code}`}>
                                    {currency && productsPricesInAllCurrencies ?
                                        getPriceString(getProductPriceConsideringQuantity(product,
                                            productsPricesInAllCurrencies), currency, language) :
                                        (<CircularProgress
                                            size={16}
                                            className={clsx(classes.circularLoading, classes.alignRight)} />
                                        )}
                                </UiText>
                            </div>
                            <div className={classes.row}>
                                {currency && productsPricesInAllCurrencies ?
                                    <UiText className={classes.detailsRow}>
                                        { getPriceString(getUnitPrice(product, productsPricesInAllCurrencies),
                                            currency, language) + ' * ' + product.quantity }
                                    </UiText> :
                                    (<CircularProgress
                                        size={16}
                                        className={clsx(classes.circularLoading, classes.alignRight)} />
                                    )}
                            </div>
                        </div>
                    ))}
                    <div className={classes.automationExpressContainer}>
                        <UiText
                            className={classes.automationExpressTitle}
                            data-cy="plan-summary-automation-express-title">
                            {translate({ id: `CLIENT_PRODUCT_25_AUTOMATION_EXPRESS` })}
                        </UiText>
                        <Chip
                            clickable={false}
                            className={classes.chip}
                            variant="outlined"
                            size="small"
                            label={translate({ id: 'CLIENT_FREE_WITH_PRO_PLAN' })} />
                    </div>
                    <UiText
                        className={classes.detailsRow}
                        data-cy="plan-summary-automation-express-title">
                        {translate({ id: `CLIENT_AUTOMATION_EXPRESS_SHOW_MORE` })}
                    </UiText>
                </div>)}
            </div>
            <div className={classes.borderContainer} />
            {!emptyData && isFirstSubscription && (
                <div className={classes.bottomContainer}>
                    <EcommerceOrderSummaryFooter
                        tax={tax}
                        subscriptionPriceToDisplay={subscriptionPrice}
                        annualSavingsToDisplay={isFirstSubscription ? annualSavings : undefined}
                        productPricesLoading={productPricesLoading}
                        taxLoading={taxLoading}
                        planType={currentSkuPackageDetails.planType}
                        currency={currency}
                    />
                    <div style={{
                        marginTop: '20px',
                        marginBottom: '20px',
                        marginLeft: '5px',
                        marginRight: '5px',
                    }}>
                        {
                            checkoutFormState ?
                                <UiProgressButton
                                    className={classes.payNowButton}
                                    style={{ width: '100%' }}
                                    loading={isPayButtonLoading}
                                    disabled={isPayButtonDisabled}
                                    onClick={submitCheckoutFormRef}
                                    variant="contained"
                                    data-cy="ecommerce-pay-button"
                                >
                                    {translate({ id: 'CLIENT_PAY_NOW' })}
                                </UiProgressButton>
                                :
                                <UiProgressButton
                                    className={classes.payNowButton}
                                    style={{ width: '100%' }}
                                    loading={false}
                                    disabled={!areQuantitiesRequirementsMet}
                                    variant="contained"
                                    onClick={() =>
                                        isDirectBuyPagesSource ? continueToDirectBuy() : buySkuPackage(planPackage?.type ?? 'CUSTOM')}
                                    data-cy="ecommerce-check-out-button"
                                >
                                    {translate({ id: 'CLIENT_CHECK_OUT' })}
                                </UiProgressButton>
                        }
                        <EcommerceEuLegalText />
                    </div>
                </div>
            )}
        </div>
    );
};
