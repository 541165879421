import type { WidgetType } from '@experiences/interfaces';
import { Spacing } from '@uipath/apollo-core';
import {
    ApCardContainer,
    ApGridLayout,
    ApSkeleton,
} from '@uipath/portal-shell-react';
import React from 'react';

export interface UiLoadingWidgetProps {
    type: WidgetType;
}

export const Repeat = (count: number) => Array.from({ length: count }, ((_, i) => i + 1));

const CARD_HEIGHT = 186;
export const UiLoadingWidget = ({ type }: UiLoadingWidgetProps) => {
    if (type === 'Card' || type === 'Presentation') {
        return (
            <ApGridLayout
                fixed
                rows={1}
                data-testid="card-skeleton"
                rowHeightInPixel={CARD_HEIGHT}>
                {Repeat(6).map((value) =>
                    <ApCardContainer
                        style={styles.card}
                        key={value}>
                        <ApSkeleton
                            style={styles.cardSkeleton}
                            data-testid={`card-skeleton-item-${value}`} />
                    </ApCardContainer>)}
            </ApGridLayout>
        );
    }

    if (type === 'Table') {
        return (
            <div
                data-testid="table-skeleton"
                style={styles.tableContainer}>
                <ApSkeleton style={styles.tableHeaderRow} />
                {Repeat(5).map((e) => <ApSkeleton
                    key={e}
                    data-testid={`table-row-skeleton-${e}`}
                    style={styles.tableRow} />)}
            </div>
        );
    }

    return null;
};

const styles = {
    card: {
        display: 'flex',
        height: `${CARD_HEIGHT}px`,
        boxSizing: 'border-box',
        alignItems: 'center',
    },
    cardSkeleton: { height: '120px' },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: Spacing.SpacingBase,
    } as React.CSSProperties,
    tableHeaderRow: {
        display: 'block',
        height: Spacing.SpacingXxl,
    },
    tableRow: {
        display: 'block',
        height: Spacing.SpacingXl,
    },
};
