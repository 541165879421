import { GlobalStyles } from '@experiences/theme';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

import { ReactComponent as AllocateLicense } from '../allocate_license.svg';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        defaultContainer: {
            width: '24px',
            height: '24px',
            display: 'flex',
        },
        defaultIcon: {
            height: '22px',
            width: '22px',
        },
    }),
}));

export default function AllocateLicenseIcon(props: any) {
    const classes = useStyles();
    const {
        withCustomStyle, className, ...rest
    } = props;

    return (
        <div className={clsx(classes.svgIcon, withCustomStyle ? '' : classes.defaultContainer, className)}>
            <AllocateLicense
                className={clsx(classes.svgIcon, withCustomStyle ? '' : classes.defaultIcon, className)}
                {...rest} />
        </div>
    );
}
