export function rotateArray<T>(arr: T[], steps: number): T[] {
    for (let i = 0; i < steps; i++) {
        const firstElement = arr.shift();

        if (firstElement) {
            arr.push(firstElement);
        }
    }

    return arr;
}

