import { portalTelemetry } from '@experiences/telemetry';
import { useCallback } from 'react';

export const useTelemetryHelper = () => {

    const logEvent = useCallback((name: string, details: any = {}) => {
        portalTelemetry.trackEvent({
            name: name as any,
            properties: details,
        });
    }, [ ]);

    return { logEvent };
};
