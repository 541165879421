import { useAuthContext } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import type {
    IErrorWithStatus,
    INonUsTaxes,
    IProductPriceOptions,
    ISkuPackage,
    ISkuPackageDetails,
    IStripeIntegrationConfig,
    ITaxResponse,
    IVatValidation,
} from '../interfaces/ecommerce';
import {
    billingUrl,
    getNonUsTaxValue,
    getPackagesPricesPublic,
    getStripeIntegrationConfiguration,
    getUsTaxValue,
    validateEuVat,
} from '../services/BillingService';
import {
    ANNUAL_PLAN_TYPE,
    getStoredCountryCode,
    getStoredPlanType,
    MONTHLY_PLAN_TYPE,
    storePlanType,
    useEcommerceEnabledCountries,
} from './EcommerceHelpers';
import { accountLogicalName } from './EcommerceSelectors';

const europeanUnionCountryCodes = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
];

export const EcommerceContext = React.createContext<{
    countryCode: string;
    setEcommerceCountry: (countryCode: string) => void;
    setEcommerceCurrencyValue: (currencyValue: string) => void;
    setEcommerceTaxIdValue: (taxIdValue?: string) => void;
    setEcommerceZipCodeValue: (zipCodeValue?: string) => void;
    setSkuPackage: (skuPackage?: ISkuPackage) => void;
    setSelectedPlanType: (planType: string) => void;
    setEcommerceCountryChangeDialogClosed: (dialogClosed: boolean) => void;
    countryChangeDialogClosed: boolean | undefined;
    setIsAddressValidationUsed: (billingValidationUsed: boolean) => void;
    isAddressValidationUsed: boolean;
    useDocumentTitle: (defaultTitle?: string) => void;
    error?: Error;
    currentSkuPackageDetails: ISkuPackageDetails;
    isValidating?: boolean;
    isTaxLoading?: boolean;
    arePricesLoading?: boolean;
    isEuropeanUnionCountry: boolean;
    publicKey?: string;
    paymentIntentClientSecret?: string;
    stripeSubscriptionId?: string;
    organizationId?: string;
    setPaymentIntentClientSecret: (paymentIntentClientSecret?: string) => void;
    setStripeSubscriptionId: (stripeSubscriptionId?: string) => void;
    setOrganizationId: (organizationId?: string) => void;
    allowedSelectedCurrencyOrDefault: (selectedCurrency?: any) => any;
    productsPricesInAllCurrencies?: IProductPriceOptions[];
}>({
            countryCode: '',
            setEcommerceCountry: () => {},
            setEcommerceCurrencyValue: () => {},
            setEcommerceTaxIdValue: () => {},
            setEcommerceZipCodeValue: () => {},
            setSkuPackage: () => {},
            setSelectedPlanType: () => {},
            setPaymentIntentClientSecret: () => {},
            setStripeSubscriptionId: () => {},
            setOrganizationId: () => {},
            setEcommerceCountryChangeDialogClosed: () => {},
            countryChangeDialogClosed: false,
            setIsAddressValidationUsed: () => {},
            isAddressValidationUsed: false,
            useDocumentTitle: () => {},
            error: undefined,
            currentSkuPackageDetails: {
                type: '',
                planType: '',
                products: [],
                defaultCurrency: '',
                prices: {},
                taxError: undefined,
                isEuCountry: false,
            },
            isValidating: true,
            isTaxLoading: false,
            arePricesLoading: false,
            isEuropeanUnionCountry: false,
            publicKey: '',
            allowedSelectedCurrencyOrDefault: () => 'USD',
            productsPricesInAllCurrencies: [],
        });

export const useEcommerce = (skuPackage?: ISkuPackage, selectedCurrency?: string) => {
    const {
        setEcommerceCountry,
        setEcommerceTaxIdValue,
        setEcommerceCurrencyValue,
        setSkuPackage,
        setSelectedPlanType,
        setEcommerceZipCodeValue,
        setPaymentIntentClientSecret,
        setStripeSubscriptionId,
        setOrganizationId,
        setEcommerceCountryChangeDialogClosed,
        countryChangeDialogClosed,
        setIsAddressValidationUsed,
        isAddressValidationUsed,
        useDocumentTitle,
        error,
        currentSkuPackageDetails,
        isValidating,
        isTaxLoading,
        arePricesLoading,
        countryCode,
        isEuropeanUnionCountry,
        publicKey,
        paymentIntentClientSecret,
        stripeSubscriptionId,
        organizationId,
        allowedSelectedCurrencyOrDefault,
        productsPricesInAllCurrencies,
    } = React.useContext(EcommerceContext);

    useDocumentTitle();

    useEffect(() => {
        if (skuPackage) {
            setSkuPackage(skuPackage);
        }
        if (selectedCurrency) {
            setEcommerceCurrencyValue(selectedCurrency);
        }
    }, [ selectedCurrency, setEcommerceCurrencyValue, setSkuPackage, skuPackage ]);

    return {
        countryCode,
        setEcommerceCountry,
        setEcommerceTaxIdValue,
        setEcommerceCurrencyValue,
        setSelectedPlanType,
        setEcommerceZipCodeValue,
        setPaymentIntentClientSecret,
        setStripeSubscriptionId,
        setOrganizationId,
        setEcommerceCountryChangeDialogClosed,
        countryChangeDialogClosed,
        setIsAddressValidationUsed,
        isAddressValidationUsed,
        error,
        currentSkuPackageDetails,
        isValidating,
        isTaxLoading,
        arePricesLoading,
        isEuropeanUnionCountry,
        publicKey,
        paymentIntentClientSecret,
        stripeSubscriptionId,
        organizationId,
        allowedSelectedCurrencyOrDefault,
        productsPricesInAllCurrencies,
    };
};

export const EcommerceProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const currentAccountName = useSelector(accountLogicalName);
    const { accountCountryCode } = useEcommerceEnabledCountries();

    const location = useLocation();
    const { formatMessage: translate } = useIntl();

    const [ countryCode, setCountryCode ] = useState<string>(getStoredCountryCode() || accountCountryCode || 'US');
    const [ currencyValue, setCurrencyValue ] = useState<string>('');
    const [ planType, setPlanType ] = useState<string>(getStoredPlanType());
    const [ zipCodeValue, setZipCodeValue ] = useState<string>('');
    const [ taxIdValue, setTaxIdValue ] = useState<string>('');
    const [ isTaxLoading, setIsTaxLoading ] = useState<boolean>(false);
    const [ arePricesLoading, setArePricesLoading ] = useState<boolean>(false);
    const [ isEuropeanUnionCountry, setIsEuropeanUnionCountry ] = useState<boolean>(false);
    const [ isAddressValidationUsed, setIsAddressValidationUsed ] = useState<boolean>(false);
    const [ publicKey, setPublicKey ] = useState<string | undefined>();
    const { token } = useAuthContext();
    const planTypes = useMemo(() => [ ANNUAL_PLAN_TYPE, MONTHLY_PLAN_TYPE ], []);

    const [ currentSkuPackage, setCurrentSkuPackage ] = useState<ISkuPackage | null>(null);
    const [ paymentIntentClientSecret, setPaymentIntentClientSecret ] = useState<string | undefined>();
    const [ stripeSubscriptionId, setStripeSubscriptionId ] = useState<string>();
    const [ organizationId, setOrganizationId ] = useState<string>();
    const [ countryChangeDialogClosed, setCountryChangeDialogClosed ] = useState<boolean>(false);

    const productsPricesInAllCurrenciesUrl = `${billingUrl}/productsPricesInAllCurrencies`;
    const {
        data: productsPricesInAllCurrencies,
        isValidating,
        error,
        mutate,
    } = useSWR(
        {
            accountName: currentAccountName,
            url: productsPricesInAllCurrenciesUrl,
            accessToken: token,
        },
        getPackagesPricesPublic,
    );

    const stripeIntegrationConfigurationUrl = `${billingUrl}/stripeIntegrationConfig`;
    const { data: publicKeyMapping } = useSWR<IStripeIntegrationConfig, IErrorWithStatus>(
        {
            accountName: currentAccountName,
            url: stripeIntegrationConfigurationUrl,
            accessToken: token,
        },
        getStripeIntegrationConfiguration, {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        });

    const currentSkuPackageCurrencies = useMemo(() => {
        // TODO: PLT-30057: remove this hard-coded mapping and get currencies by country from LPS endpoint
        if (countryCode === 'JP') {
            return [ 'JPY' ];
        }
        if ([ 'US', 'MX', 'CA' ].includes(countryCode)) {
            return [ 'USD' ];
        }
        return [ 'EUR', 'USD' ];
    }, [ countryCode ]);

    const currentSkuPackagePrice = useMemo(
        () => {
            const packagePrices: Record<string, Record<string, number>> = {};
            planTypes.forEach(possiblePlanType => {
                currentSkuPackageCurrencies?.forEach(currency => {
                    let price = 0;
                    currentSkuPackage?.products.forEach(productQuantity => {
                        if (productsPricesInAllCurrencies) {
                            const foundProductPrice = productsPricesInAllCurrencies.find(pp => pp.code === productQuantity.code
                                && pp.planType === possiblePlanType)?.prices[currency];
                            if (foundProductPrice) {
                                price += productQuantity.quantity * foundProductPrice;
                            }
                        }
                    });
                    if (packagePrices[possiblePlanType] === undefined) {
                        packagePrices[possiblePlanType] = {};
                    }
                    packagePrices[possiblePlanType][currency] = price;
                });

            });

            return packagePrices;
        },
        [ planTypes, currentSkuPackageCurrencies, currentSkuPackage?.products, productsPricesInAllCurrencies ],
    );

    const mainPriceToDisplay = useMemo(() => currentSkuPackagePrice[planType], [ planType, currentSkuPackagePrice ]);

    const nonUsTaxesParams = useMemo(() => {
        let parameters = null;
        if (countryCode && countryCode !== 'US' && mainPriceToDisplay?.[currencyValue] && currencyValue) {
            const amount = mainPriceToDisplay[currencyValue] / 100;
            parameters = {
                url: `${billingUrl}/getNonUsTaxes`,
                countryCode,
                amount,
                accountName: currentAccountName,
                accessToken: token,
            };
        }
        return parameters;
    }, [ countryCode, mainPriceToDisplay, currencyValue, token, currentAccountName ]);
    const {
        data: taxResponseNonUs,
        isValidating: isLoadingNonUsTax,
        error: taxErrorNonUs,
    } = useSWR<INonUsTaxes, IErrorWithStatus>(nonUsTaxesParams, getNonUsTaxValue, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    const usTaxesParams = useMemo(() => {
        let parameters = null;
        if (productsPricesInAllCurrencies && countryCode && countryCode === 'US' && zipCodeValue && currencyValue) {
            const amount = mainPriceToDisplay[currencyValue] / 100;
            parameters = {
                url: `${billingUrl}/getUsTaxes`,
                countryCode,
                zipcode: zipCodeValue,
                amount,
                accountName: currentAccountName,
                accessToken: token,
            };
        }

        return parameters;
    }, [ productsPricesInAllCurrencies, countryCode, zipCodeValue, mainPriceToDisplay, currencyValue, currentAccountName, token ]);

    const {
        data: taxResponseUs,
        isValidating: isLoadingUsTax,
        error: taxErrorUs,
    } = useSWR<ITaxResponse, IErrorWithStatus>(usTaxesParams, getUsTaxValue, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    const {
        data: vatValidation,
        isValidating: isLoadingVat,
    } = useSWR<IVatValidation, IErrorWithStatus>(
        countryCode && countryCode !== 'US' && taxIdValue && europeanUnionCountryCodes.includes(countryCode)
            ? {
                url: `${billingUrl}/validateEuVat`,
                countryCode,
                vatId: taxIdValue,
                accountName: currentAccountName,
                accessToken: token,
            }
            : null,
        validateEuVat,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    const getTaxValueAndRate = useCallback(
        (country: string, vatValid: IVatValidation | undefined): ITaxResponse | undefined => {
            if (country === 'US') {
                return taxResponseUs;
            }
            if (vatValid?.validationStatus === 'ServiceDown') {
                const serviceDownResponse = taxResponseNonUs?.invalidVatTaxResponse;
                if (serviceDownResponse !== undefined) {
                    serviceDownResponse.serviceDown = true;
                }
                return serviceDownResponse;
            }
            return vatValid?.validationStatus?.toLowerCase() === 'valid'
                ? taxResponseNonUs?.validVatTaxResponse
                : taxResponseNonUs?.invalidVatTaxResponse;

        },
        [ taxResponseNonUs, taxResponseUs ],
    );

    useEffect(() => {
        setIsEuropeanUnionCountry(europeanUnionCountryCodes.includes(countryCode));
    }, [ countryCode ]);

    const allowedSelectedCurrencyOrDefault = useCallback(
        (selectedCurrencyValue: string) =>
            currentSkuPackageCurrencies.includes(selectedCurrencyValue)
                ? selectedCurrencyValue
                : currentSkuPackageCurrencies[0],
        [ currentSkuPackageCurrencies ]);

    const currentSkuPackageDetails = useMemo(
        () => (
            {
                type: currentSkuPackage?.type ?? '',
                planType,
                products: currentSkuPackage?.products ?? [],
                defaultCurrency: currencyValue,
                prices: currentSkuPackage?.products ? currentSkuPackagePrice : {},
                tax: getTaxValueAndRate(countryCode, vatValidation),
                taxError: countryCode === 'US' ? taxErrorUs : taxErrorNonUs,
                isEuCountry: europeanUnionCountryCodes.includes(countryCode),
            }),
        [
            currentSkuPackage,
            currentSkuPackagePrice,
            planType,
            taxErrorUs,
            taxErrorNonUs,
            vatValidation,
            countryCode,
            getTaxValueAndRate,
            currencyValue,
        ],
    );

    const useDocumentTitle = (defaultTitle?: string) => {
        useEffect(() => {
            let title = defaultTitle;
            const pathname = location.pathname;

            if (pathname.match(/buyPresets$/)) {
                title = translate({ id: 'CLIENT_CLOUDRPA_TITLE_PRESETS' });
            } else if (pathname.match(/buyIndividualProducts$/)) {
                title = translate({ id: 'CLIENT_CLOUDRPA_TITLE_INDIVIDUAL_PRODUCTS' });
            }

            if (title) {
                document.title = title;
            }
        }, [ defaultTitle ]);
    };

    useEffect(() => {
        setIsTaxLoading(isLoadingUsTax || isLoadingNonUsTax || isLoadingVat);
    }, [ isLoadingUsTax, isLoadingNonUsTax, isLoadingVat ]);

    useEffect(() => {
        setArePricesLoading(isValidating);
    }, [ isValidating ]);

    useEffect(() => {
        if (countryCode && currentSkuPackage) {
            mutate();
        }
    }, [ countryCode, currentSkuPackage, currentAccountName, productsPricesInAllCurrenciesUrl, mutate ]);

    useEffect(() => {
        if (publicKeyMapping?.countryCodeToPublicKeyMapping[countryCode]
            && publicKey !== publicKeyMapping?.countryCodeToPublicKeyMapping[countryCode]) {
            setPublicKey(publicKeyMapping?.countryCodeToPublicKeyMapping[countryCode]);
        }
    }, [ publicKeyMapping, publicKey, countryCode ]);

    const setEcommerceCurrencyValue = useCallback((currency?: string) => {
        if (currency) {
            setCurrencyValue(allowedSelectedCurrencyOrDefault(currency));
        }
    }, [ allowedSelectedCurrencyOrDefault ]);

    // Callback to change the Stripe public key based on the selected country
    const setEcommerceCountry = useCallback((country?: string) => {
        if (country) {
            setCountryCode(country);
        }
    }, [ ]);

    const setEcommerceCountryChangeDialogClosed = useCallback((dialogClosed: boolean) => {
        setCountryChangeDialogClosed(dialogClosed);
    }, []);

    const setEcommerceZipCodeValue = useCallback((zipCode?: string) => {
        setZipCodeValue(zipCode ? zipCode : '');
    }, []);

    const setEcommerceTaxIdValue = useCallback((taxId?: string) => {
        setTaxIdValue(taxId ? taxId : '');
    }, []);

    const setSkuPackage = useCallback((skuPackage?: ISkuPackage) => {
        if (skuPackage) {
            setCurrentSkuPackage(skuPackage);
        }
    }, [ setCurrentSkuPackage ]);

    const setSelectedPlanType = useCallback((selectedPlanType: string) => {
        if (selectedPlanType) {
            storePlanType(selectedPlanType);
            setPlanType(selectedPlanType);
        }
    }, []);
    return (
        <EcommerceContext.Provider
            value={{
                countryCode,
                setEcommerceCountry,
                setEcommerceCurrencyValue,
                setEcommerceTaxIdValue,
                setEcommerceZipCodeValue,
                setSkuPackage,
                setSelectedPlanType,
                setPaymentIntentClientSecret,
                setStripeSubscriptionId,
                setOrganizationId,
                setEcommerceCountryChangeDialogClosed,
                countryChangeDialogClosed,
                setIsAddressValidationUsed,
                isAddressValidationUsed,
                useDocumentTitle,
                error,
                currentSkuPackageDetails,
                isValidating,
                isTaxLoading,
                arePricesLoading,
                isEuropeanUnionCountry,
                publicKey,
                paymentIntentClientSecret,
                stripeSubscriptionId,
                organizationId,
                allowedSelectedCurrencyOrDefault,
                productsPricesInAllCurrencies,
            }}
        >
            {children}
        </EcommerceContext.Provider>
    );
};
