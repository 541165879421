import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
    }),
);

const EcommerceBusinessFirstAndLastNames: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    return (
        <div className={classes.row}>
            <Controller
                render={({ field }) => (
                    <TextField
                        {...field}
                        style={{ marginRight: '12px' }}
                        variant="outlined"
                        required
                        id="firstName"
                        label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_FIRSTNAME' })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.type === 'required' && translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_FIRSTNAME' }) },
                        )}
                        InputProps={{ className: 'Tall' }}
                        disabled={disabled}
                        data-cy="ecommerce-business-first-name"
                        fullWidth
                    />
                )}
                control={control}
                rules={{ required: true }}
                name="firstName"
            />
            <Controller
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        required
                        id="lastName"
                        label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_LASTNAME' })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.type === 'required' && translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_LASTNAME' }) },
                        )}
                        InputProps={{ className: 'Tall' }}
                        disabled={disabled}
                        data-cy="ecommerce-business-last-name"
                        fullWidth
                    />
                )}
                control={control}
                rules={{ required: true }}
                name="lastName"
            />
        </div>
    );
};

export default EcommerceBusinessFirstAndLastNames;
