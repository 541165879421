// Spaces , special characters are not allowed in the name and must start with alphabet
function validateName(name: string) {
    if (!name) {
        return false;
    }
    const regex = /^(?!.*_$)[a-zA-Z][A-Za-z0-9_]{0,31}$/;
    return regex.test(name);
}

export default validateName;
