import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type {
    IExternalAppDataContext,
    IExternalClient,
    IGridResource,
    IResource,
    ISecret,
} from '@experiences/interfaces';
import { ExternalApiResourceType } from '@experiences/interfaces';
import {
    UiCopyButton,
    UiDataContextProvider,
    UiProgressButton,
    UiSuspensefulOutlet,
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    timestampToDate,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
    CharacterLimitForNames,
    notificationType,
    QUOTA_REACHED_MESSAGE,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import {
    externalApiResourceUrl,
    getExternalApiResources,
} from '../../../services/identity/ExternalApiResourceService';
import {
    createClientSecret,
    createExternalClient,
    deleteClientSecret,
    editExternalClient,
    externalClientUrl,
    getExternalClientById,
} from '../../../services/identity/ExternalClientService';
import { accountGlobalId } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { UiGrid } from '../../common/UiGrid';
import {
    ButtonType,
    GridActionType,
} from '../../common/UiGrid/grid';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import { useGetExternalApp } from '../providers/ExternalAppsProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        headerLayout: {
            display: 'flex',
            flexDirection: 'column',
        },
        breadcrumbText: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
            marginTop: '8px',
        },
        input: { marginTop: '24px' },
        inlineEditorWrapper: {
            maxWidth: '100%',
            paddingTop: '5px',
            paddingRight: '5px',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputHeader: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        secretsHeader: {
            display: 'flex',
            marginBottom: '12px',
        },
        secretsLabel: { marginTop: '12px' },
        rowGroup: {
            display: 'flex',
            flexDirection: 'row',
        },
        body: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        scopeCell: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
        },
        tooltipCell: {
            flex: 1,
            minWidth: 0,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        secretCell: {
            flex: 'auto',
            minWidth: 0,
            textAlign: 'left',
        },
    }),
);

interface IResourceForm {
    name: string;
    isConfidential: boolean;
    redirectUri: string;
    scopes: IGridResource[];
}

const SECRETS_TABLE_HEIGHT_PX = '330px';

const CreateEditExternalApplicationsFormComponent: React.FC<{
    type: 'add' | 'edit';
    id: string;
    clientData: IExternalClient | undefined;
    externalResources: IResource[] | undefined;
}> = ({
    type, id, clientData, externalResources,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();

    const partitionGlobalId = useSelector(accountGlobalId);

    const createNotification = useUiSnackBar();

    const [ loading, setLoading ] = useState(false);
    const [ generatedSecrets, setGeneratedSecrets ] = useState<ISecret[]>([]);

    const secretsData = useMemo(() =>
        clientData?.secrets?.map(data => generatedSecrets.find(s => s.id === data.id) ?? data) ?? [], [
        clientData?.secrets,
        generatedSecrets,
    ]);

    const {
        error,
        mutate,
    } = useSWR(
        type === 'edit' && id ? {
            url: `${externalClientUrl}/${id}`,
            partitionGlobalId,
            id,
        } : null,
        getExternalClientById,
    );

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: {
            isDirty,
            isValid,
            errors,
        },
        setValue,
        clearErrors,
        watch,
        trigger,
    } = useForm<IResourceForm>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            isConfidential: true,
            redirectUri: '',
            scopes: [],
        },
        shouldUnregister: false,
    });

    const {
        scopes, isConfidential: confidential,
    } = watch();

    const {
        data: gridResourceData, setData,
    } = useUiDataContext<IExternalAppDataContext>();

    useEffect(() => {
        if (type === 'edit' && clientData) {
            const gridScopes = clientData.resources?.map(resource => ({
                name: resource.name,
                userScopes: resource.scopes
                    ?.filter(
                        scope => scope.type === ExternalApiResourceType.User
                                || scope.type === ExternalApiResourceType.UserAndApplication,
                    )
                    .map(scope => scope.name),
                applicationScopes: resource.scopes
                    ?.filter(
                        scope => scope.type === ExternalApiResourceType.Application
                            || scope.type === ExternalApiResourceType.UserAndApplication,
                    )
                    .map(scope => scope.name),
            }));

            reset({
                name: clientData.name,
                isConfidential: clientData.isConfidential,
                redirectUri: clientData.redirectUri ?? '',
                scopes: gridScopes,
            });

            setData({
                resources: gridScopes,
                confidential: clientData.isConfidential,
            });
        }
    }, [ clientData, reset, setData, type ]);

    useEffect(() => {
        if (gridResourceData.resources) {
            setValue('scopes', gridResourceData.resources, { shouldDirty: true });
            if (gridResourceData.resources.filter(scopeIterator => scopeIterator.userScopes.length).length <= 0) {
                clearErrors('redirectUri');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ gridResourceData.resources ]);

    const openScopesDrawer = useCallback(
        (resource?: IGridResource) => {
            const ScopeDrawerRoute = (
                type === 'add'
                    ? RouteNames.ExternalApplicationAddScopesWithName
                    : RouteNames.ExternalApplicationEditScopesWithName
            )
                .replace(':type', type)
                .replace('/:id', id ? `/${id}` : '')
                .replace('/:name', resource ? `/${resource.name}` : '');
            setData({
                resources: scopes,
                confidential,
            });
            navigate(getRoute(ScopeDrawerRoute));
        },
        [ confidential, getRoute, id, navigate, scopes, setData, type ],
    );

    const onGenerateSecret = useCallback(
        async () => {
            const payload = {
                clientId: id,
                partitionGlobalId,
            };
            const returnPayload = await createClientSecret(payload);
            const creationTime = (new Date()).toUTCString();
            setGeneratedSecrets(data => [
                {
                    ...returnPayload,
                    creationTime,
                }, ...data,
            ]);
            createNotification(translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_GENERATED_NOTIFICATION' }), notificationType.SUCCESS);
            mutate();
        }, [ id, mutate, partitionGlobalId, createNotification, translate ],
    );

    const onDeleteSecret = useCallback(
        async (data: ISecret) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SECRET_TITLE' }),
                body: <UiText>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SECRET' }, { 0: data.id })}
                </UiText>,
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });

            if (proceed) {
                await deleteClientSecret(`${data.id}`, partitionGlobalId);
                setGeneratedSecrets(
                    generatedSecrets.filter(secrets => secrets.secret !== data.secret),
                );
                createNotification(translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_REMOVED_NOTIFICATION' }), notificationType.SUCCESS);
                mutate();
            }
        }, [ createDialog, generatedSecrets, mutate, partitionGlobalId, createNotification, translate ],
    );

    const onSubmit = useCallback(
        async (data: IResourceForm) => {
            const payload = {
                name: data.name.trim(),
                redirectUri: data.redirectUri.trim() || '',
                isConfidential: data.isConfidential,
                partitionGlobalId,
                scopes: scopes.flatMap(scope =>
                    scope.applicationScopes
                        .map(val => ({
                            name: val,
                            type: ExternalApiResourceType.Application,
                        }))
                        .concat(
                            scope.userScopes.map(val => ({
                                name: val,
                                type: ExternalApiResourceType.User,
                            })),
                        ),
                ),
            };

            let returnPayload: IExternalClient | undefined = undefined;
            try {
                setLoading(true);

                if (type === 'add') {
                    returnPayload = await createExternalClient(payload);
                } else if (clientData) {
                    await editExternalClient(clientData.id!, payload);
                }
            } catch (error) {
                if ((error as any)?.response?.data?.Message === QUOTA_REACHED_MESSAGE) {
                    setErrorMessage(translate({ id: 'CLIENT_QUOTA_REACHED' }));
                } else if ((error as any)?.response?.status !== 409) {
                    let errorMessage = await getErrorMessage(error);
                    if (errorMessage.includes('RedirectUri=')) {
                        errorMessage = translate({ id: 'CLIENT_REDIRECT_URI_INVALID' });
                    }
                    setErrorMessage(errorMessage);
                }
                setLoading(false);
                return;
            }

            setLoading(false);
            navigate(getRoute(RouteNames.ExternalApplications),
                { state: returnPayload ? { externalClient: returnPayload } : undefined });
        },
        [
            partitionGlobalId,
            scopes,
            navigate,
            getRoute,
            type,
            clientData,
            getErrorMessage,
            setErrorMessage,
            translate,
        ],
    );

    const validateApplicationName = useCallback((value: string) => {
        if (!value.trim()) {
            return translate({ id: 'CLIENT_EXTERNAL_APP_WARN_NAME' }, {
                0: CharacterLimitForNames.maxExternalAppNameLength,
                1: 1,
            });
        }
        return true;
    }, [ translate ]);

    return (error || (type === 'edit' && !id)) ? (
        <div className={classes.body}>
            <UiText>
                {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
            </UiText>
            <Button
                variant="outlined"
                size="small"
                onClick={() => {
                    mutate();
                }}
                style={{ marginTop: '12px' }}
            >
                {translate({ id: 'CLIENT_RETRY' })}
            </Button>
        </div>
    ) : (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            id="editResource"
            actions={
                <div className={classes.actions}>
                    <Button
                        className={classes.cancelButton}
                        color="primary"
                        onClick={() => navigate(getRoute(RouteNames.ExternalApplications))}
                        data-cy="add-edit-cancel-button"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        loading={loading}
                        disabled={
                            !isDirty
                            || !isValid
                        }
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        data-cy="add-edit-submit-button"
                    >
                        {type === 'add' ? translate({ id: 'CLIENT_ADD' }) : translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            centerChild
        >
            <div className={classes.input}>
                <UiText
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="applicationNameLabel">
                    {translate({ id: 'CLIENT_EXTERNAL_APPLICATION_NAME' })}
                </UiText>
                <TextField
                    required
                    error={!!errors.name}
                    helperText={errors.name?.message as string}
                    variant="outlined"
                    style={{ width: '75%' }}
                    size="small"
                    data-cy="external-applications-name-field"
                    inputProps={{
                        'aria-labelledby': 'applicationNameLabel',
                        ...register('name', {
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_EXTERNAL_APPLICATION_NAME' }) },
                            ),
                            maxLength: {
                                value: CharacterLimitForNames.maxExternalAppNameLength,
                                message: translate({ id: 'CLIENT_EXTERNAL_APP_WARN_NAME' }, { 0: CharacterLimitForNames.maxExternalAppNameLength }),
                            },
                            validate: (value: string) => validateApplicationName(value),
                        }),
                    }}
                />
            </div>
            {type === 'edit' && clientData && (
                <div>
                    <div className={classes.input}>
                        <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_APP_ID' })}
                        </UiText>
                        <UiText>
                            {clientData.id}
                        </UiText>
                    </div>
                    <div className={classes.secretsHeader}>
                        <UiText className={clsx(classes.inputLabel, classes.secretsLabel)}>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_APP_SECRETS' })}
                        </UiText>
                        <Button
                            style={{ marginLeft: 'auto' }}
                            variant="outlined"
                            data-cy="generate-secrets-button"
                            onClick={() => onGenerateSecret()}>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_GENERATE_SECRET' })}
                        </Button>
                    </div>
                    <UiGrid<ISecret>
                        dataCy="secrets-ui-grid"
                        tableId="secrets-grid-id"
                        tableHeight={
                            secretsData.length === 0 ? ('120px') : ((secretsData.length <= 4) ? `${60 * secretsData.length + 60}px` : SECRETS_TABLE_HEIGHT_PX)
                        }
                        columns={[
                            {
                                accessor: 'id',
                                Header: translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_ID' }),
                                disableSortBy: true,
                                width: 25,
                            },
                            {
                                accessor: 'secret',
                                Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_VALUE' }),
                                width: 30,
                                disableSortBy: true,
                                Cell: ({ row }) => (
                                    <div>
                                        {
                                            !row.original.secret ? (
                                                <UiText>
                                                                    ******
                                                </UiText>
                                            ) : (
                                                <div className={classes.scopeCell}>
                                                    <UiText className={classes.secretCell}>
                                                        {row.original.secret}
                                                    </UiText>
                                                    <div>
                                                        <UiCopyButton
                                                            textToCopy={row.original.secret}
                                                            data-cy="secret-copy"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ),
                            },
                            {
                                accessor: 'creationTime',
                                Header: translate({ id: 'CLIENT_CREATION_DATE' }),
                                width: 30,
                                disableSortBy: true,
                                Cell: ({ row }) => (
                                    <div>
                                        {timestampToDate(row.original.creationTime)}
                                    </div>
                                ),
                            },
                        ]}
                        data={secretsData}
                        rowActions={[
                            {
                                type: ButtonType.Icon,
                                label: translate({ id: 'CLIENT_DELETE' }),
                                tooltip: translate({ id: 'CLIENT_DELETE' }),
                                actionType: GridActionType.Row,
                                icon: <DeleteForeverOutlinedIcon />,
                                click: row => {
                                    onDeleteSecret(row.original);
                                },
                                dataCy: 'action-delete-secrets',
                            },
                        ]}
                    />
                </div>
            )}
            <div className={classes.input}>
                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_TYPE' })}
                </UiText>
                <Controller
                    control={control}
                    name="isConfidential"
                    defaultValue
                    render={({ field }) => (
                        <RadioGroup
                            className={classes.rowGroup}
                            value={field.value}
                            onChange={event => field.onChange(event.target.value === 'true')}>
                            <FormControlLabel
                                style={{ marginRight: '44px' }}
                                control={
                                    <Radio
                                        value
                                        disabled={type === 'edit'}
                                        checked={field.value === true}
                                        color="primary"
                                    />
                                }
                                label={translate({ id: 'CLIENT_EXTERNAL_APP_CONFIDENTIAL_APPLICATION' })}
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        value={false}
                                        disabled={type === 'edit'}
                                        checked={field.value === false}
                                        color="primary"
                                    />
                                }
                                label={translate({ id: 'CLIENT_EXTERNAL_APP_NON_CONFIDENTIAL_APPLICATION' })}
                            />
                        </RadioGroup>
                    )}
                />
            </div>
            <div className={classes.input}>
                <UiText className={clsx(classes.inputHeader, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_RESOURCES' })}
                </UiText>
                <Controller
                    control={control}
                    name="scopes"
                    render={({ field }) => (
                        <UiGrid<IGridResource>
                            dataCy="scopes-ui-grid"
                            tableId="scopes-grid-id"
                            tableHeight={`${60 * field.value?.length + 60}px`}
                            columns={[
                                {
                                    accessor: 'name',
                                    Header: translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_NAME' }),
                                    disableSortBy: true,
                                    width: 30,
                                },
                                {
                                    accessor: 'userScopes',
                                    Header: translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE' }),
                                    width: 35,
                                    disableSortBy: true,
                                    Cell: ({ row }) => (
                                        <div className={classes.scopeCell}>
                                            <Tooltip
                                                className={classes.tooltipCell}
                                                arrow
                                                placement="bottom-start"
                                                title={row.original.userScopes.join(', ')}
                                            >
                                                <UiText>
                                                    {row.original.userScopes.join(', ')}
                                                </UiText>
                                            </Tooltip>
                                            {!!row.original.userScopes.length && (
                                                <CopyToClipboard text={row.original.userScopes.join(' ')}>
                                                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                                                        <IconButton>
                                                            <FileCopyOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    accessor: 'applicationScopes',
                                    Header: translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_SCOPE' }),
                                    width: 35,
                                    disableSortBy: true,
                                    Cell: ({ row }) => (
                                        <div className={classes.scopeCell}>
                                            <Tooltip
                                                className={classes.tooltipCell}
                                                arrow
                                                placement="bottom-start"
                                                title={row.original.applicationScopes.join(', ')}
                                            >
                                                <UiText>
                                                    {row.original.applicationScopes.join(', ')}
                                                </UiText>
                                            </Tooltip>
                                            {!!row.original.applicationScopes.length && (
                                                <CopyToClipboard text={row.original.applicationScopes.join(' ')}>
                                                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                                                        <IconButton>
                                                            <FileCopyOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            )}
                                        </div>
                                    ),
                                },
                            ]}
                            data={field.value ?? []}
                            tableActions={
                                [
                                    {
                                        type: ButtonType.ButtonWithIcon,
                                        label: translate({ id: 'CLIENT_EXTERNAL_APP_ADD_SCOPES' }),
                                        tooltip: translate({ id: 'CLIENT_EXTERNAL_APP_ADD_SCOPES' }),
                                        actionType: GridActionType.Main,
                                        icon: <AddIcon />,
                                        click: () => openScopesDrawer(),
                                        dataCy: 'add-scope-button',
                                    },
                                ]
                            }
                            rowActions={[
                                {
                                    type: ButtonType.Icon,
                                    label: translate({ id: 'CLIENT_EDIT' }),
                                    tooltip: translate({ id: 'CLIENT_EDIT' }),
                                    actionType: GridActionType.Row,
                                    icon: <EditOutlinedIcon />,
                                    click: row => openScopesDrawer(row.original),
                                    dataCy: 'action-edit-scopes',
                                },
                                {
                                    type: ButtonType.Icon,
                                    label: translate({ id: 'CLIENT_DELETE' }),
                                    tooltip: translate({ id: 'CLIENT_DELETE' }),
                                    actionType: GridActionType.Row,
                                    icon: <DeleteForeverOutlinedIcon />,
                                    click: async row => {
                                        const proceed = await createDialog({
                                            title: translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SCOPE_TITLE' }),
                                            body: <UiText>
                                                {translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SCOPE' })}
                                            </UiText>,
                                            icon: 'warning',
                                            primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
                                            showCancel: true,
                                        });

                                        if (proceed) {
                                            const filteredScopes = field.value.filter(scope => scope.name !== row.original.name);
                                            setValue('scopes', filteredScopes, { shouldDirty: true });
                                            clearErrors('redirectUri');
                                            await trigger();
                                        }
                                    },
                                    dataCy: 'action-delete-scopes',
                                },
                            ]}
                        />
                    )}
                />
            </div>
            <div className={classes.input}>
                <TextField
                    label={translate({ id: 'CLIENT_EXTERNAL_APP_REDIRECT_URL' })}
                    error={!!errors.redirectUri}
                    helperText={errors.redirectUri?.message ?? translate({ id: 'CLIENT_EXTERNAL_APP_REDIRECT_URI_HELPER' })}
                    required={(scopes?.filter(scope => scope.userScopes.length).length > 0)}
                    variant="outlined"
                    style={{ width: '75%' }}
                    size="small"
                    data-cy="external-applications-redirectUri-field"
                    inputProps={register('redirectUri', {
                        required: (scopes?.filter(scope => scope.userScopes.length).length > 0)
                            && translate({ id: 'CLIENT_REQUIRED_REDIRECT_URL_FIELD' }),
                    })}
                />
            </div>
            {type === 'add' && (
                <div className={classes.input}>
                    <UiText>
                        {translate({ id: 'CLIENT_EXTERNAL_APP_REGISTER_NEW' })}
                    </UiText>
                </div>
            )}
        </UiForm>
    );
};

const CreateEditExternalApplicationsPageComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const { id } = useParams<{ id: string }>();

    const partitionGlobalId = useSelector(accountGlobalId);

    const appData = useGetExternalApp();

    const {
        data: clientData, isLoading: clientLoading,
    } = useSWR(
        type === 'edit' && id && !appData ? {
            url: `${externalClientUrl}/${id}`,
            partitionGlobalId,
            id,
        } : null,
        getExternalClientById,
    );

    const {
        data: externalResources, isLoading: resourcesLoading,
    } = useSWR<IResource[], Error>(
        externalApiResourceUrl,
        getExternalApiResources,
    );

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            loading={(type === 'edit' && clientLoading) || resourcesLoading}
            maxWidth="900px"
            data-cy="external-apps-panel"
        >
            <CreateEditExternalApplicationsFormComponent
                type={type}
                id={id ?? ''}
                clientData={appData ?? clientData}
                externalResources={externalResources} />
        </UiPageContainer>
    );
};

export const CreateEditExternalApplicationsWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const { type } = useParams() as { type: 'add' | 'edit'; id: string };

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.ExternalApplications,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
        {
            index: 2,
            link: type === 'add' ? RouteNames.ExternalApplicationAdd : RouteNames.ExternalApplicationEdit,
            name: translate({ id: type === 'add' ? 'CLIENT_ADD_APPLICATION' : 'CLIENT_PAGE_EDIT_EXTERNAL_APPLICATION' }),
        },
    ], [ organizationName, translate, type ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <UiDataContextProvider<IExternalAppDataContext> initialState={{
            resources: undefined,
            confidential: true,
        }}>
            <CreateEditExternalApplicationsPageComponent type={type} />
            <UiSuspensefulOutlet />
        </UiDataContextProvider>
    </BreadcrumbProvider>;
};

export default CreateEditExternalApplicationsPageComponent;
