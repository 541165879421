import { HomePageEvent } from '@experiences/telemetry';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';

const useStyles = makeStyles(theme =>
    ({
        card: {
            backgroundColor: theme.palette.semantic.colorBackground,
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        button: { alignSelf: 'flex-end' },
    })
);

type SubmitIdeaProps = {
    orgName: string;
    tenantName: string;
};

const SubmitIdea = ({
    orgName, tenantName,
}: SubmitIdeaProps) => {
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();
    const { formatMessage: translate } = useIntl();

    const onClick = useCallback(() => {
        logEvent(HomePageEvent.SubmitIdeaToAutomationHubClicked);
        window.location.assign(`/${orgName}/${tenantName}/automationhub_/submit/idea`);
    }, [ logEvent, orgName, tenantName ]);

    return (
        <UiStack
            direction="column"
            gap={SpacingToken.XS}
            p={SpacingToken.Base}
            mb={SpacingToken.L}
            className={classes.card}>
            <UiText variant={FontVariantToken.fontSizeH4Bold}>
                {translate({ id: 'CLIENT_SUBMIT_IDEA_TITLE' })}
            </UiText>
            <UiText>
                {translate({ id: 'CLIENT_SUBMIT_IDEA_SUBTITLE' })}
            </UiText>
            <Button
                data-cy="submit-idea-button"
                className={classes.button}
                startIcon={<LightbulbOutlinedIcon />}
                onClick={onClick}>
                {translate({ id: 'CLIENT_SUBMIT_IDEA_BUTTON' })}
            </Button>
        </UiStack>
    );
};

const SubmitIdeaGuard = () => {
    const [ tenantName, setTenantName ] = useState<string>();
    const [ supported, setSupported ] = useState<boolean>();
    const orgName = window.PortalShell?.AccountAndTenants.getCurrentAccountAndTenantsInfo().organization.name;

    useEffect(() => {
        const tenant = window.PortalShell?.AccountAndTenants.getTenant();

        setTenantName(tenant?.name ?? '');
        setSupported(tenant?.enabledServices?.includes('automationhub') ?? false);
    }, []);

    const onTenantChanged = useCallback((e: any) => {
        setTenantName(e.detail.name);
        setSupported(window.PortalShell?.AccountAndTenants.getTenant(e.detail.name)?.enabledServices?.includes('automationhub') ?? false);
    }, []);

    useEffect(() => {
        document.addEventListener('tenantChanged', onTenantChanged);
        return () => document.removeEventListener('tenantChanged', onTenantChanged);
    }, [ onTenantChanged ]);

    if (supported && tenantName && orgName) {
        return <SubmitIdea
            orgName={orgName}
            tenantName={tenantName} />;
    }

    return null;
};

export default SubmitIdeaGuard;
