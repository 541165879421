export enum SecuritySettingEnum {
    Authentication = 'authentication',
    AttributeMapping = 'attribute-mapping',
    IpRestriction = 'ip-restriction',
    SessionPolicy = 'session-policy',
    Encryption = 'encryption',
    AccessRestriction = 'access-restriction',
}

export type SecuritySettingType = typeof SecuritySettingEnum[keyof typeof SecuritySettingEnum];
