/* eslint-disable max-len */
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { FontVariantToken } from '@uipath/apollo-core';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { notificationType } from '../../../common/constants/Constant';
import { AllowedDomainsAndAttributeMappingAzureActiveDirectory } from '../../../common/constants/documentation/AzureADIntegrationDocumentationLinks.default';
import { AllowedDomainsAndAttributeMappingSaml } from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type {
    AttributeType,
    IFieldAttribute,
} from '../../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import type { IAuthenticationSettingPayload } from '../../../services/identity/AuthenticationSettingService';
import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
    updateDirectoryIntegrationSetting,
    updateDirectoryIntegrationSettingSaml2,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import { mapSamlFormDataToAuthSettingPayload } from '../../../util/setting/AuthSettingUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import AttributeMapping from '../subcomponents/AttributeMapping';

const classes = {
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    cancelButton: {
        marginRight: '10px',
        width: '102px',
    },
    container: { maxWidth: '480px' },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    description: {
        marginTop: '8px',
        marginBottom: '10px',
        display: 'inline',
    },
    title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 600,
    },
};

interface IAttributeMappingForm {
    attributes: IFieldAttribute[];
}

const AttributeMappingPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { type } = useParams() as { type: string };
    const partitionGlobalId = useSelector(accountGlobalId);
    const createNotification = useUiSnackBar();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const getLocalizedLink = useDocumentationLinks();

    const handleError = useCallback(
        async (error: any) => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const {
        AAD, Saml,
    } = useMemo(
        () => ({
            AAD: bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.AAD],
            Saml: bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML],
        }),
        [ bulkAuthenticationSetting ],
    );

    const methods = useForm<IAttributeMappingForm>({
        mode: 'onChange',
        defaultValues: { attributes: [] },
    });

    const {
        formState: {
            isValid, isDirty, isSubmitting, errors,
        },
        handleSubmit,
        reset,
    } = methods;

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        if (!Saml?.externalIdentityProviderDto?.settings) {
            return undefined;
        }

        try {
            return JSON.parse(Saml.externalIdentityProviderDto.settings) as ISaml2ProviderSettings;
        } catch (error) {
            handleError(error);
        }
    }, [ Saml, handleError ]);

    useEffect(() => {
        if (bulkAuthenticationSetting && !!AAD) {
            // populate fields for aad
            const configuration = AAD?.directoryConnectionDto.configuration;
            const configurationJson = JSON.parse(configuration ?? '');
            if (configurationJson?.AADProvisioningAttributeMapper?.ExtensionUserAttributeMappings) {
                const initial: IFieldAttribute[] = [];
                for (const [ key, value ] of Object.entries(configurationJson?.AADProvisioningAttributeMapper?.ExtensionUserAttributeMappings)) {
                    initial.push({
                        attributeType: key as AttributeType,
                        value: value as string,
                    });
                }
                reset({ attributes: initial });
            }
        } else if (bulkAuthenticationSetting && !!Saml) {
            // populate fields for saml
            const settings = Saml?.externalIdentityProviderDto?.settings;
            const settingsJson = JSON.parse(settings ?? '');
            if (settingsJson.ProvisioningSetting.AttributeMapper.ExtensionUserAttributeMappings) {
                const initial: IFieldAttribute[] = [];
                for (const [ key, value ] of Object.entries(settingsJson.ProvisioningSetting.AttributeMapper.ExtensionUserAttributeMappings)) {
                    initial.push({
                        attributeType: key as AttributeType,
                        value: value as string,
                    });
                }
                reset({ attributes: initial });
            }
        }
    }, [
        AAD?.directoryConnectionDto.configuration,
        bulkAuthenticationSetting,
        AAD,
        Saml,
        partitionGlobalId,
        reset,
        Saml?.externalIdentityProviderDto?.settings,
    ]);

    const getSamlAuthenticationPayload = useCallback((data: IAttributeMappingForm) => {
        const configurationPayload: { [key: string]: string } = {};
        if (data.attributes) {
            for (const attribute of data.attributes) {
                configurationPayload[attribute.attributeType] = attribute.value;
            }
        }
        return mapSamlFormDataToAuthSettingPayload(
            {
                ...externalIdentityProviderDtoSettings,
                ProvisioningSetting: {
                    AccountLinkConfirmation:
                        externalIdentityProviderDtoSettings?.ProvisioningSetting?.AccountLinkConfirmation,
                    AllowedDomains: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AllowedDomains?.join(', ') ?? '',
                    CustomEmailClaimName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.CustomEmailClaimName ?? '',
                    UniqueIdClaimName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.UniqueIdClaimName ?? '',
                    AttributeMapper: {
                        ...externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper,
                        ExtensionUserAttributeMappings: configurationPayload,
                    },
                },
            } as ISamlFormData,
            partitionGlobalId,
            bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML]?.externalIdentityProviderDto?.id,
        );
    }, [
        bulkAuthenticationSetting,
        externalIdentityProviderDtoSettings,
        partitionGlobalId,
    ]);

    const getAADAuthenticationPayload = useCallback((data: IAttributeMappingForm) => {
        const authority = AAD?.externalIdentityProviderDto?.authority;
        const matchedValues = authority?.match(/.com\/(.*)\/v/);
        const authenticationSettingData = {
            tenantID: (matchedValues && matchedValues.length > 1 && matchedValues[1]) || '',
            applicationID: AAD?.externalIdentityProviderDto.clientId,
            applicationSecret: AAD?.externalIdentityProviderDto.clientSecret,
        };
        const payload: IAuthenticationSettingPayload = {
            type: AuthenticationSettingType.AAD,
            displayName: AuthenticationSettingType.AAD,
            partitionGlobalId,
            clientId: authenticationSettingData.applicationID?.trim(),
            clientSecret: authenticationSettingData.applicationSecret?.trim(),
            tenantId: authenticationSettingData.tenantID?.trim(),
        };
        const configurationPayload: { [key: string]: string } = {};
        if (data.attributes) {
            for (const attribute of data.attributes) {
                configurationPayload[attribute.attributeType] = attribute.value;
            }
        }
        payload.extensionUserAttributeMappings = configurationPayload;
        return payload;
    }, [
        AAD,
        partitionGlobalId,
    ]);

    const onSubmit = useCallback(async (data: IAttributeMappingForm) => {
        try {
            let payload;
            if (AAD) {
                payload = getAADAuthenticationPayload(data);
                await updateDirectoryIntegrationSetting(payload);
            } else {
                payload = getSamlAuthenticationPayload(data);
                await updateDirectoryIntegrationSettingSaml2(payload);
            }
            navigate(getRoute(RouteNames.SecuritySettings));
            createNotification(translate({ id: 'CLIENT_SAVE_SUCCESSFUL' }), notificationType.SUCCESS);
        } catch (error) {
            await handleError(error);
        }
    }, [
        AAD,
        createNotification,
        getAADAuthenticationPayload,
        getRoute,
        getSamlAuthenticationPayload,
        handleError,
        navigate,
        translate,
    ]);

    const goBack = useCallback(() => {
        navigate(getRoute(RouteNames.SecuritySettings));
    }, [ navigate, getRoute ]);

    const { breadcrumbs } = useBreadcrumbs();

    return <UiPageContainer
        breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
        disableGutter={[ 'bottom', 'left', 'right' ]}
    >
        <FormProvider {...methods}>
            <UiForm
                bodyMaxWidth='536px'
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <Box sx={classes.actions}>
                        <Button
                            sx={classes.cancelButton}
                            onClick={goBack}
                            color="primary"
                            data-cy='attribute-mapping-cancel-button'>
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type='submit'
                            sx={{
                                width: '120px',
                                marginRight: '24px',
                            }}
                            loading={isSubmitting}
                            disabled={!isValid
                                || !isDirty
                                || Object.keys(errors).length > 0}
                            variant='contained'
                            data-cy='attribute-mapping-save-button'
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </Box>
                }
                centerChild
            >
                <UiText
                    variant={FontVariantToken.fontSizeH3}
                    style={classes.title}
                >
                    {translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_2' })}
                </UiText>
                <Box sx={classes.description}>
                    <UiText data-cy="attribute-mapping-description">
                        {translate({ id: type === 'aad' ? 'CLIENT_ATTRIBUTE_DESCRIPTION_AZURE_AD' : 'CLIENT_ATTRIBUTE_DESCRIPTION_SAML' })}
                    </UiText>
                    <UiText>
                        <Link
                            href={getLocalizedLink({ articleSlug: AAD ? AllowedDomainsAndAttributeMappingAzureActiveDirectory : AllowedDomainsAndAttributeMappingSaml })}
                            target="_blank"
                            underline='none'
                            data-cy="learn-more-attribute-mapping-link"
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </UiText>
                </Box>
                <AttributeMapping
                    type={type as 'aad' | 'saml'}
                    edit />
            </UiForm>
        </FormProvider>
    </UiPageContainer>;
};

export const AttributeMappingPageComponentWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 2,
            link: RouteNames.SecuritySettingsAttributeMapping,
            name: translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_2' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <AttributeMappingPageComponent />
    </BreadcrumbProvider>;
};

export default AttributeMappingPageComponent;
