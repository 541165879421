import { ContactUsLinks } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useApolloTheme } from '@experiences/theme';
import {
    UiSessionId,
    UiText,
} from '@experiences/ui-common';
import {
    getEnvVariableValue,
    useFilteredParams,
    useLocalizedLinks,
} from '@experiences/util';
import { PortalAlertErrorPage } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';

import useStaticLogout from '../../auth/hooks/StaticLogout';
import { getFriendlyName } from '../../common/constants/ServicesMapping';
import useNavigateToHome from '../../common/hooks/useNavigateToHome';

/**
 * Display page when user tries to login but account does not exist
 */
const Unregistered: React.FC = () => {

    const portalAlertErrorPageRef = useRef<HTMLPortalAlertErrorPageElement>(null);
    const logout = useStaticLogout();
    const getLocalizedLink = useLocalizedLinks();

    const [ serviceType, tenantName, organizationName ] = useFilteredParams([
        'serviceType',
        'tenantName',
        'organizationName',
    ]);
    const { themeId } = useApolloTheme();
    const { formatMessage: translate } = useIntl();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const navigateToHome = useNavigateToHome(organizationName);

    const handleLogin = useCallback(
        (e: any) => {
            e.preventDefault();
            logout();
        },
        [ logout ],
    );

    useEffect(() => {
        const { current } = portalAlertErrorPageRef;
        current?.addEventListener('primaryButtonClicked', (serviceType && tenantName) ? navigateToHome : handleLogin);
        current?.addEventListener('secondaryButtonClicked', handleLogin);
        return () => {
            current?.removeEventListener('primaryButtonClicked', (serviceType && tenantName) ? navigateToHome : handleLogin);
            current?.removeEventListener('secondaryButtonClicked', handleLogin);
        };
    }, [ handleLogin, navigateToHome, serviceType, tenantName ]);

    const getErrorMessage = useCallback(() => {
        if (serviceType === 'portal' && organizationName) {
            return (<UiText>
                {translate(
                    { id: 'CLIENT_UNREGISTERED_ORGANIZATION_DESCRIPTION' },
                    { serviceName: getFriendlyName(serviceType, false) },
                )}
            </UiText>);
        } else if (serviceType && tenantName) {
            return (
                <>
                    <UiText>
                        {translate(
                            { id: 'CLIENT_UNREGISTERED_SERVICE_DESCRIPTION' },
                            {
                                serviceName: getFriendlyName(serviceType, false),
                                tenantName,
                            },
                        )}
                    </UiText>
                    <UiText style={{ marginTop: '8px' }}>
                        {process.buildConfigs.simpleUnregisteredMessage
                            ? translate({ id: 'CLIENT_UNREGISTERED_SERVICE_SIMPLE' })
                            : translate(
                                { id: 'CLIENT_UNREGISTERED_SERVICE_DESCRIPTION2' },
                                {
                                    serviceName: getFriendlyName(serviceType, false),
                                    titleProductName: getEnvVariableValue('TITLE_PRODUCT_NAME'),
                                    tenantName,
                                },
                            )}
                    </UiText>
                </>
            );
        }
        return <UiText>
            {translate({ id: 'CLIENT_UNREGISTERED_DESCRIPTION' })}
        </UiText>;

    }, [ organizationName, serviceType, tenantName, translate ]);

    return (
        <PortalAlertErrorPage
            ref={portalAlertErrorPageRef}
            type={!serviceType ? 'error' : 'warning'}
            heading={!serviceType ? translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG_ELLIPSES' }) : undefined}
            primaryActionText={translate({ id: (serviceType && tenantName) ? 'CLIENT_GO_TO_HOME_PAGE' : 'CLIENT_BACK_TO_SIGN_IN' })}
            secondaryActionText={
                !process.buildConfigs.hideSignUpLink && (!serviceType || (serviceType === 'portal' && organizationName))
                    ? translate({ id: 'CLIENT_DONT_HAVE_ACCOUNT_SIGN_UP' })
                    : undefined
            }
            theme={themeId}
            data-cy="unregistered-logout-button"
            footerLeftButtonUrl={
                !process.buildConfigs.disableServiceStatusLink && !DisableFeatureFedRamp
                    ? 'https://status.uipath.com'
                    : undefined
            }
            footerLeftButtonText={!process.buildConfigs.disableServiceStatusLink && !DisableFeatureFedRamp
                ? translate({ id: 'CLIENT_SERVICE_STATUS' })
                : undefined}
            footerRightButtonUrl={getLocalizedLink(ContactUsLinks)}
            footerRightButtonText={translate({ id: 'CLIENT_CONTACT_US' })}
            onPrimaryButtonClicked={(serviceType && tenantName) ? navigateToHome : handleLogin}
            onSecondaryButtonClicked={handleLogin}
        >
            <>
                {getErrorMessage()}
                <UiSessionId style={{ margin: '12px' }} />
            </>
        </PortalAlertErrorPage>
    );
};

export default Unregistered;
