import type {
    Event,
    WidgetContext,
} from '@experiences/interfaces';
import { post } from '@experiences/util';

const eventsCollectUrl = '/srs_/api/v2/events/collect';

export function postEventsCollect(
    events: Event[],
    ctx: WidgetContext) {

    const body = {
        cloudOrganizationId: ctx.organizationGuid,
        applicationName: 'Portal',
        events,
    };

    return post(eventsCollectUrl, {
        body,
        accessToken: ctx.accessToken,
        stripPortalPath: true,
    });
}
