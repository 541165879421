import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        message: {
            whiteSpace: 'pre-line',
            maxWidth: '365px',
        },
        textToConfirm: { color: theme.palette.semantic.colorErrorText },
        buttonsContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
        },
        confirmationMessageContainer: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
    }),
);

type Props = IUiDialogHookCustomContent & {
    cancelButtonLabel?: string;
    actionButtonLabel?: string;
    message: string;
    textToConfirm: string;
    textInputPlaceholder?: string;
    onDestructiveActionAsync?: () => Promise<any>;
};

function renderConfirmationText(text: React.ReactNode[]) {
    return (
        <strong data-cy="destructive-action-confirmation-text">
            {text}
        </strong>
    );
}

const DestructiveActionConfirmationDialog: React.FC<Props> = (props) => {
    const {
        closeDialog,
        message,
        textToConfirm,
        textInputPlaceholder,
        cancelButtonLabel,
        actionButtonLabel,
        onDestructiveActionAsync,
    } = props;

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ textFieldValue, setTextFieldValue ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
    }, []);

    const doActionAndClose = useCallback(() => {
        (async () => {
            setIsLoading(true);

            try {
                if (onDestructiveActionAsync) {
                    await onDestructiveActionAsync();
                }
                closeDialog(true);
            } catch (error) {
                const errorObject = await getErrorObject(error);
                const data = errorObject.response?.data;
                const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
                setIsLoading(false);
            }
        })();

    }, [ onDestructiveActionAsync, closeDialog, getErrorObject, getErrorMessage, setErrorMessage ]);

    const onCancel = useCallback(() => {
        closeDialog(false);
    }, [ closeDialog ]);

    return (
        <>
            <UiText
                className={classes.message}
                data-cy="destructive-action-confirmation-message">
                {message}
            </UiText>

            <div className={classes.confirmationMessageContainer}>
                <UiText data-cy="destructive-action-confirmation-reminder">
                    <FormattedMessage
                        id="CLIENT_SERVICE_DELETE"
                        values={{
                            strong: renderConfirmationText,
                            0: <span className={classes.textToConfirm}>
                                {textToConfirm}
                            </span>,
                        }}
                    />
                </UiText>

                <TextField
                    className={classes.textField}
                    autoComplete="off"
                    variant="outlined"
                    id="standard-name"
                    onChange={handleChange}
                    value={textFieldValue}
                    placeholder={textInputPlaceholder}
                    data-cy="destructive-action-confirmation-textfield"
                />
            </div>

            <div className={classes.buttonsContainer}>
                <Button
                    key="cancelButton"
                    onClick={onCancel}
                    color="primary"
                    data-cy="secondary-button-confirmation"
                >
                    {cancelButtonLabel ?? translate({ id: 'CLIENT_CANCEL' })}
                </Button>

                <UiProgressButton
                    loading={isLoading}
                    key="primaryButton"
                    variant="contained"
                    data-cy="primary-button-confirmation"
                    onClick={doActionAndClose}
                    disabled={textFieldValue !== textToConfirm}
                    innerButtonClass="warning"
                >
                    {actionButtonLabel ?? translate({ id: 'CLIENT_DELETE' })}
                </UiProgressButton>
            </div>
        </>
    );
};

export default DestructiveActionConfirmationDialog;
