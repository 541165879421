import { useMemo } from 'react';

import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
} from '../../services/identity/AuthenticationSettingService';
import { useCheckAuthenticationSetting } from './useCheckAuthenticationSetting';

export const useLoginProvider = () => {
    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const loginProvider = useMemo(() => {
        if (bulkAuthenticationSetting?.[AuthenticationSettingType.NoSetting]) {
            return undefined;
        }

        return bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.Google]?.authenticationSettingType?.toLowerCase()
            ?? bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.Microsoft]?.authenticationSettingType?.toLowerCase();
    }, [ bulkAuthenticationSetting ]);

    return loginProvider;
};
