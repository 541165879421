import type { IProcessesApp } from '@experiences/interfaces';
import { get } from '@experiences/util';

export const processesUrl = `/api/orchestrator/processes`;

export function getProcesses(accessToken: string, tenantName: string, accountLogicalName: string) {
    return get<IProcessesApp[]>(processesUrl, {
        accessToken,
        urlParams: {
            accountLogicalName,
            tenantName,
        },
    });
}
