import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';

import { revokeAuth0RefreshTokenForUser } from '../../services/UserServices';

export default async function revokeRefreshToken(
    refreshToken: string,
    successCallback: () => Promise<void>,
    errorCallback: () => Promise<void>,
) {
    try {
        await revokeAuth0RefreshTokenForUser(refreshToken);
        portalTelemetry.trackTrace({
            message: 'Successfully revoked token',
            severityLevel: SeverityLevel.Info,
        });

        await successCallback();
    } catch (error) {
        portalTelemetry.trackTrace({
            message: `Error occurred while revoking refresh token: ${error}`,
            severityLevel: SeverityLevel.Major,
        });

        await errorCallback();
    }
}
