import { useIsEcommerceEnabled } from '@experiences/ecommerce';
import type { AccountType } from '@experiences/interfaces';
import {
    useEffect,
    useState,
} from 'react';
import useSWR from 'swr';

import useCheckLicense from '../../common/hooks/useCheckLicense';
import {
    billingUrl,
    fetchHistoryStatus,
} from '../../services/licensing/BillingService';

export function useShouldShowManageButton(
    currentAccountName: string,
    accountType: AccountType | undefined,
): boolean | undefined {

    const isEcommerceEnabled = useIsEcommerceEnabled();
    const [ historyStatus, setHistoryStatus ] = useState<boolean | undefined>(undefined);
    const { data: ecommerceHistoryStatus } = useSWR(
        isEcommerceEnabled ? {
            url: `${billingUrl}/historyStatus`,
            accountName: currentAccountName,
        } : null,
        fetchHistoryStatus,
    );
    const { isPro } = useCheckLicense();

    useEffect(() => {
        if (ecommerceHistoryStatus?.hasHistoricalData != null) {
            setHistoryStatus(ecommerceHistoryStatus?.hasHistoricalData);
        }
    }, [ ecommerceHistoryStatus ]);

    return isEcommerceEnabled && accountType && isPro && historyStatus;
}
