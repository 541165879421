import {
    UiDialog,
    UiSessionId,
} from '@experiences/ui-common';
import ErrorIcon from '@mui/icons-material/Error';
import React, {
    useCallback,
    useContext,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

export const CentralErrorHandlingContext = React.createContext<{
    showError: (error: any) => void;
}>({ showError: () => {} });

export const useCentralErrorSetter = () => {
    const { showError } = useContext(CentralErrorHandlingContext);
    return showError;
};

export const CentralErrorHandlingProvider: React.FC<{ children?: React.ReactNode }> = props => {
    const { formatMessage: translate } = useIntl();

    const [ error, setError ] = useState(null);
    const showError = useCallback(
        (e: any) => {
            setError(e);
        },
        [ setError ],
    );

    return (
        <>
            {error && (
                <UiDialog
                    close={() => {
                        setError(null);
                    }}
                    icon={<ErrorIcon
                        style={{
                            width: 32,
                            height: 32,
                        }}
                        color="error" />}
                    title={translate({ id: 'CLIENT_ERROR' })}
                >
                    {error}
                    <UiSessionId style={{
                        marginTop: '20px',
                        alignItems: 'left',
                    }} />
                </UiDialog>
            )}
            <CentralErrorHandlingContext.Provider
                value={{ showError }}
            >
                {props.children}
            </CentralErrorHandlingContext.Provider>
        </>
    );
};
