/* eslint-disable max-len */
import { useLocalization } from '@experiences/locales';
import {
    UiErrorBoundary,
    UiText,
} from '@experiences/ui-common';
import { getRelativeTime } from '@experiences/util';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import type {
    SxProps,
    Theme,
} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { ReactElement } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

interface UiCardThemeProps {
    disabled: boolean;
}

const useStyles = makeStyles<Theme, UiCardThemeProps>(theme =>
    createStyles({
        card: props => ({
            '&:hover': {
                boxShadow: `0px 1px 10px ${theme.palette.semantic.colorBackgroundGray}, 0px 4px 5px ${theme.palette.semantic.colorBackgroundGray}, 0px 2px 4px -1px ${theme.palette.semantic.colorBackgroundGray}`,
                cursor: 'pointer',
            },
            position: 'relative',
            border: `1px solid ${theme.palette.semantic.colorBorderDisabled}`,
            backgroundColor: props.disabled ? theme.palette.semantic.colorBackgroundDisabled : '',
            height: '216px',
            width: '320px',
        }),
        cardContent: { padding: '16px' },
        chip: {
            height: '16px',
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            marginBottom: '8px',
            '&:hover': {
                color: theme.palette.semantic.colorForegroundDeEmp,
                backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '12px',
        },
        titleContainer: { marginLeft: '8px' },
        titleText: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
            lineHeight: '20px',
        },
        subTitleText: {
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        descriptionText: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '24px',
            maxHeight: '34px',
            overflow: 'hidden',
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
        cardAction: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: '5px',
            right: '0px',
        },
        errorIcon: {
            height: '16px',
            width: '16px',
            marginRight: '4px',
        },
        needsAttention: {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorErrorText,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            marginLeft: '8px',
        },
    }),
);

export const UiApplicationCard: React.FC<{
    title: string;
    titleIcon?: React.ReactNode;
    description: string;
    registeredTime?: Date | string;
    lastUpdatedTime?: Date | string;
    publisher: string;
    dataCy: string;
    actions?: ReactElement<any, any>;
    sx?: SxProps;
    style?: React.CSSProperties;
    registered: boolean;
    disabled?: boolean;
    updatedScopes?: boolean;
}> = ({
    title,
    titleIcon,
    description,
    publisher,
    lastUpdatedTime,
    registeredTime,
    dataCy,
    actions,
    sx,
    style,
    registered,
    disabled = false,
    updatedScopes = false,
}) => {
    const classes = useStyles({ disabled });
    const { formatMessage: translate } = useIntl();

    const locale = useLocalization();

    return (
        <Card
            className={classes.card}
            role="gridcell"
            tabIndex={0}
            sx={sx}
            style={style}
            variant="outlined"
            elevation={0}
            data-cy={dataCy}
            aria-label={title}
        >
            <CardContent classes={{ root: classes.cardContent }}>
                {lastUpdatedTime && <Chip
                    className={classes.chip}
                    size="small"
                    clickable={false}
                    label={translate({
                        id: registered
                            ? 'CLIENT_REGISTERED_ON'
                            : 'CLIENT_LAST_UPDATED_ON',
                    }, { date: getRelativeTime(locale)(+new Date(registeredTime ?? lastUpdatedTime)) })}
                />}
                <Box className={classes.titleSection}>
                    <UiErrorBoundary>
                        {titleIcon}
                    </UiErrorBoundary>
                    <Box className={classes.titleContainer}>
                        <UiText className={classes.titleText}>
                            {title}
                        </UiText>
                        <UiText className={classes.subTitleText}>
                            {translate({ id: 'CLIENT_BY' }, { user: publisher })}
                        </UiText>
                    </Box>
                </Box>
                <UiText className={classes.descriptionText}>
                    {description}
                </UiText>
            </CardContent>
            {actions &&
                <CardActions className={classes.cardAction}>
                    {updatedScopes ? <Tooltip
                        tabIndex={0}
                        title={translate({ id: 'CLIENT_UPDATE_SCOPES_TOOLTIP' })}>
                        <UiText className={classes.needsAttention}>
                            <span>
                                <ErrorIcon
                                    className={classes.errorIcon}
                                    color="error" />
                            </span>
                            {translate({ id: 'CLIENT_NEEDS_ATTENTION' })}
                        </UiText>
                    </Tooltip> : <div />}
                    {actions}
                </CardActions>}
        </Card>
    );
};
