import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import forceLogout from '../../../auth/utils/ForceLogout';
import { notificationType } from '../../../common/constants/Constant';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    checkIfOnlyUserInGroup,
    groupUrl,
} from '../../../services/identity/GroupService';
import { deleteUsers } from '../../../services/identity/UserService';
import {
    accountGlobalId,
    companyName,
    isAdminSelector,
    userGlobalId,
} from '../../../store/selectors';
import { UserGroup } from '../../common/UserGroups';
import LeaveOrganizationConfirmationDialogBody from './LeaveOrganizationConfirmationDialog';

const useStyles = makeStyles(theme =>
    createStyles({
        formContainer: { maxWidth: '482px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        radio: {
            marginTop: '14px',
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        rowGroup: {
            marginLeft: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        section: { paddingBottom: '20px' },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
            fontWeight: 600,
        },
        infoText: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
        },
        leaveOrgButton: {
            marginTop: '12px',
            marginBottom: '16px',
        },
    }),
);

const ProfileOrganizationComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();

    const userId = useSelector(userGlobalId);
    const orgName = useSelector(companyName);
    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data: lastAdminInOrg, isValidating,
    } = useSWR(
        isAdmin ? {
            url: `${groupUrl}/:id/checkIfOnlyUserInGroup`,
            partitionGlobalId,
            userGlobalId: userId,
            groupId: UserGroup.Administrators,
        } : null,
        checkIfOnlyUserInGroup,
    );

    const onLeaveOrgAsync = useCallback(async () => {
        try {
            await deleteUsers(partitionGlobalId, [ userId ], true);
            forceLogout();
        } catch (error) {
            createNotification(translate({ id: 'CLIENT_USER_LEAVE_FAILED' }), notificationType.ERROR);
        }
    }, [ createNotification, partitionGlobalId, translate, userId ]);

    const openLeaveOrgDialog = useCallback(async () => {
        await createDialog({
            title: translate({ id: 'CLIENT_LEAVE_ORG' }),
            icon: 'error',
            customDialogContent: LeaveOrganizationConfirmationDialogBody,
            customDialogContentProps: {
                orgName,
                onLeaveOrgAsync,
            } as React.ComponentProps<typeof LeaveOrganizationConfirmationDialogBody>,
        });
    }, [ createDialog, translate, orgName, onLeaveOrgAsync ]);

    return (
        <div className={classes.formContainer}>
            <div className={classes.section}>
                <UiText
                    className={classes.heading}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_ORGANIZATION' })}
                </UiText>
            </div>
            <div className={classes.section}>
                <UiText className={classes.subHeading}>
                    {translate({ id: 'CLIENT_NAME' })}
                </UiText>
                <UiText data-cy="organization-info-name">
                    {orgName}
                </UiText>
                <Button
                    className={clsx('warning', classes.leaveOrgButton)}
                    onClick={openLeaveOrgDialog}
                    data-cy="leave-organization-button"
                    disabled={!!lastAdminInOrg || isValidating}
                >
                    {translate({ id: 'CLIENT_LEAVE_ORG' })}
                </Button>
                {lastAdminInOrg && (
                    <UiText className={classes.infoText}>
                        {translate({ id: 'CLIENT_LEAVE_ORG_MIN_ONE_ADMIN' })}
                    </UiText>
                )}
            </div>
        </div>
    );
};

export default ProfileOrganizationComponent;
