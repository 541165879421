import { Entitlements } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import { updateIpConfigurationStatus } from '../../../../services/access-policy/IPConfigurationStatusService';
import { consumeEntitlement } from '../../../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        confirmButton: { color: theme.palette.semantic.colorForegroundInverse },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmMessageSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const IPRestrictionConfirmationDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, inIpRange, ipStatus, refreshCallback,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const [ loading, setLoading ] = useState(false);

    const createNotification = useUiSnackBar();

    const saveAndClose = useCallback(async () => {
        setLoading(true);
        try {
            if (ipStatus === EnforcementType.ENABLED) {
                await consumeEntitlement(Entitlements.IpRestrictions);
            }

            await updateIpConfigurationStatus(partitionGlobalId, { status: ipStatus });
            createNotification(
                translate({ id: 'CLIENT_IP_RESTRICTION_UPDATE_SUCCESS_NOTIFICATION' }),
                notificationType.SUCCESS,
            );
            refreshCallback(ipStatus);
            closeDialog(true);
        } catch (error) {
            createNotification(
                translate({ id: 'CLIENT_IP_RESTRICTION_UPDATE_FAILURE_NOTIFICATION' }),
                notificationType.ERROR,
            );
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        }
    }, [
        partitionGlobalId,
        ipStatus,
        createNotification,
        translate,
        refreshCallback,
        closeDialog,
        getErrorObject,
        getErrorMessage,
        setErrorMessage,
    ]);

    const dialogMessage = useMemo(() => ({
        [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_CONFIRMATION_DISABLE_WARNING',
        [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_CONFIRMATION_ENABLE_WARNING',
        [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_CONFIRMATION_AUDIT_WARNING',
    }), []);

    return (
        <>
            {!inIpRange && ipStatus === EnforcementType.ENABLED ?
                <UiText data-cy="enforce-confirmation-blocked">
                    {translate({ id: 'CLIENT_IP_RESTRICTION_CONFIRMATION_BLOCKED' })}
                </UiText>
                :
                <div className={classes.confirmMessageSection}>
                    <UiText data-cy="enforce-confirmation-type-warning">
                        {
                            translate({ id: dialogMessage[ipStatus as EnforcementType] })
                        }
                    </UiText>
                    <br />
                    <UiText data-cy="enforce-confirmation-ask">
                        {translate({ id: 'CLIENT_ASK_CONFIRMATION' })}
                    </UiText>
                </div>}

            <div className={classes.buttonContainer}>
                <Button
                    key="cancelButton"
                    className={classes.cancelButton}
                    onClick={() => closeDialog()}
                    color="primary"
                    data-cy="close-button-confirmation"
                >
                    {translate({ id: !inIpRange && ipStatus === EnforcementType.ENABLED ? 'CLIENT_OK' : 'CLIENT_CANCEL' })}
                </Button>
                {(inIpRange || ipStatus !== EnforcementType.ENABLED) && <UiProgressButton
                    loading={loading}
                    key="primaryButton"
                    variant="contained"
                    color="inherit"
                    data-cy="confirm-button-confirmation"
                    onClick={() => saveAndClose()}
                >
                    {translate({ id: 'CLIENT_CONFIRM' })}
                </UiProgressButton>}
            </div>
        </>
    );
};

export default IPRestrictionConfirmationDialogBody;
