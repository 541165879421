import type {
    IMigrateUserLicensingResponse,
    IUserLicenseAllocation,
    IUserLicenseAllocationRequest,
} from '../../component/users/interfaces/userLicense';
import {
    get,
    post,
    put,
} from '../utility/Requests.default';

export const userLicenseUri = '/api/userLicense';

export async function getUserLicenseAllocation({ accountGlobalId }: { url: string; accountGlobalId: string }) {
    return await get<IUserLicenseAllocation[]>(`${userLicenseUri}/api/account/${accountGlobalId}/user-license`);
}

export async function allocateUserLicenses(accountGlobalId: string, requestBody: IUserLicenseAllocationRequest) {
    return await put<any>(`${userLicenseUri}/api/account/${accountGlobalId}/user-license`, { body: requestBody });
}

export function updateUserLicenseManagementStatus(enabled: boolean) {
    return put(`${userLicenseUri}/UserLicense/status`, { body: { enabled } });
}

export function getUserLicenseManagementStatus() {
    return get<{ enabled: boolean }>(`${userLicenseUri}/UserLicense/status`);
}

export async function startMigrateUserLicensing() {
    return await post<any>(`${userLicenseUri}/UserLicense/migrate`, { body: {} });
}

export async function getMigrateUserLicensingResult(jobId: string): Promise<IMigrateUserLicensingResponse> {
    return await get<IMigrateUserLicensingResponse>(`${userLicenseUri}/UserLicense/migrate/result/${jobId}`);
}

export async function getMigrateUserLicensingPendingJobId(): Promise<string> {
    return await get<any>(`${userLicenseUri}/UserLicense/migrate/pending`);
}
