import { useAuthContext } from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import { profile } from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import type {
    IBusinessInfoPayload,
    ISubscriptionForm,
} from '../interfaces/ecommerce';
import { setUnifiedFormDefaultValues } from './EcommerceCheckoutUtils';

const EcommerceCheckoutPaymentFormProvider: React.FC<PropsWithChildren<{
    savedBillingInfo?: ISubscriptionForm;
    existingMarketoData: IBusinessInfoPayload | undefined;
}>> = ({
    savedBillingInfo,
    existingMarketoData,
    children,
}) => {
    const profileState = useSelector(profile);

    const {
        currentSkuPackageDetails,
        countryCode,
    } = useEcommerce();

    const { claims } = useAuthContext();

    const formDefaultValues = useMemo(() => setUnifiedFormDefaultValues({
        savedBillingInfo,
        countryCode,
        defaultCurrency: currentSkuPackageDetails.defaultCurrency,
        profileState,
        claims,
        existingMarketoData,
    }), [
        profileState,
        savedBillingInfo,
        claims,
        countryCode,
        currentSkuPackageDetails.defaultCurrency,
        existingMarketoData,
    ]);

    const formMethods = useForm<ISubscriptionForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formDefaultValues,
    });

    return (
        <FormProvider {...formMethods}>
            {children}
        </FormProvider>
    );
};

export default EcommerceCheckoutPaymentFormProvider;
