import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAvatar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import { useGetFullName } from '../../common/hooks/useGetFullName';
import {
    accountPictureUrlSelector,
    emailSelector,
    isAdminSelector,
} from '../../store/selectors';
import ProfileOrganizationComponent from './subcomponents/ProfileOrganizationComponent';
import ProfilePreferencesComponent from './subcomponents/ProfilePreferencesComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        divider: {
            marginBottom: '20px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
        },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
        subHeadingArea: { paddingBottom: '20px' },
        infoLabel: { width: '110px' },
        infoRow: {
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            paddingBottom: '20px',
        },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
            fontWeight: 600,
        },
    }),
);

const ProfileGeneralSettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const accountPictureUrl = useSelector(accountPictureUrlSelector);
    const email = useSelector(emailSelector);
    const isAdmin = useSelector(isAdminSelector);

    const { checkTokenTypeIsAuth0 } = useCheckAuthTypes();

    const fullName = useGetFullName();

    const isGov = window.env?.ENVIRONMENT?.includes('gov');
    const highContrastLightThemeFlag = useFeatureFlagValue(Features.EnableHighContrastLightTheme.name);

    return (
        <div>
            <UiText
                className={clsx(classes.heading, classes.mainHeading)}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_GENERAL' })}
            </UiText>
            <div className={classes.heading}>
                <UiText
                    className={classes.heading}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_PROFILE' })}
                </UiText>
                {!checkTokenTypeIsAuth0 && !isAdmin && (
                    <UiText data-cy="profile-page-managed-profile-description">
                        {translate({ id: 'CLIENT_PROFILE_DESCRIPTION' })}
                    </UiText>
                )}
            </div>
            <div className={classes.infoRow}>
                <UiText className={classes.infoLabel}>
                    {translate({ id: 'CLIENT_IMAGE' })}
                </UiText>
                <PortalAvatar
                    data-cy="profile-page-picture"
                    size="40px"
                    fullName={fullName}
                    imageUrl={accountPictureUrl}
                    isButton={false} />
            </div>
            <div className={classes.infoRow}>
                <UiText className={classes.infoLabel}>
                    {translate({ id: 'CLIENT_NAME' })}
                </UiText>
                <UiText data-cy="profile-page-name">
                    {fullName}
                </UiText>
            </div>
            {!!email && (
                <div className={classes.infoRow}>
                    <UiText className={classes.infoLabel}>
                        {translate({ id: 'CLIENT_EMAIL' })}
                    </UiText>
                    <UiText data-cy="profile-page-email">
                        {email}
                    </UiText>
                </div>
            )}
            {!(isGov && highContrastLightThemeFlag) && <>
                <Divider className={classes.divider} />
                <ProfilePreferencesComponent />
            </>}
            {!process.buildConfigs.disableUsersLeaveOrg && checkTokenTypeIsAuth0 &&
                <>
                    <Divider className={classes.divider} />
                    <ProfileOrganizationComponent />
                </>}
        </div>
    );
};

export default ProfileGeneralSettingsComponent;
