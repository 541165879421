import { ContactUsLinks } from '@experiences/constants';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../../../common/constants/RouteNames';
import {
    kmsUrl,
    saveKmsConfig,
} from '../../../../services/identity/KeyManagementService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        footer: {
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    }),
);

const EncryptionDowngradeWarningDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const getLocalizedLink = useLocalizedLinks();

    const downgradeKmsConfig = useCallback(async () => {
        await saveKmsConfig(kmsUrl, {
            partitionGlobalId,
            keyType: 0,
        });
    }, [ partitionGlobalId ]);

    useEffect(() => {
        downgradeKmsConfig();
    }, [ downgradeKmsConfig ]);

    return (
        <>
            <UiText data-cy="encryption-downgrade-warning">
                {translate({ id: 'CLIENT_ENCRYPTION_DOWNGRADE_WARNING_DESCRIPTION' })}
            </UiText>

            <div className={classes.footer}>
                <Button
                    className={classes.button}
                    component={Link}
                    data-cy='encryption-downgrade-warning-contact-button'
                    href={getLocalizedLink(ContactUsLinks)}
                    target="_blank"
                >
                    {translate({ id: 'CLIENT_CONTACT_SALES' })}
                </Button>
                <Button
                    data-cy='encryption-downgrade-warning-navigate-button'
                    href={RouteNames.Encryption.replace(':accountName', accountName)}
                    variant='contained'
                >
                    {translate({ id: 'CLIENT_GO_TO_ENCRYPTION_SETTINGS' })}
                </Button>
            </div>
        </>
    );
};

export default EncryptionDowngradeWarningDialogBody;
