import {
    UiCopyButton,
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApButton } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    accountGlobalId,
    companyName,
} from '../../store/selectors';
import type { IAuditDetailsContext } from './interfaces/auditLog';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 56px)',
        overflowY: 'auto',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeIcon: {
        height: 16,
        width: 16,
    },
    section: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'middle',
    },
    details: {
        textAlign: 'left',
        padding: '8px 4px',
        overflowX: 'auto',
        backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
    },
    spacer: { margin: '8px 0' },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'space-around',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '8px',
        height: '56px',
        backgroundColor: theme.palette.semantic.colorBackground,
        borderTop: `1px solid ${theme.palette.semantic.colorBorder}`,
    },
    button: { marginRight: '8px' },
}));

export const AuditDetailsComponent: React.FC = () => {
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();

    const organizationId = useSelector(accountGlobalId);
    const organizationName = useSelector(companyName);

    const {
        data, setData,
    } = useUiDataContext<IAuditDetailsContext>();

    const close = useCallback(() => {
        setData({
            ...data,
            row: undefined,
        });
    }, [ data, setData ]);

    const details = useMemo(() =>
        data.row && JSON.stringify(JSON.parse(data.row.eventDetails), null, 2),
    [ data ]);

    const fields = useMemo(() => (
        data.tenant ? {
            firstHeader: 'CLIENT_AUDITLOG_TENANTID',
            firstHeaderValue: data.tenant.id,
            secondHeader: 'CLIENT_TENANT_NAME',
            secondHeaderValue: data.tenant.name,

        } : {
            firstHeader: 'CLIENT_ACCOUNT_LOGICAL_NAME',
            firstHeaderValue: organizationId,
            secondHeader: 'CLIENT_ORGANIZATION_NAME',
            secondHeaderValue: organizationName,
        }
    ), [ data.tenant, organizationId, organizationName ]);

    return <>
        <div className={classes.container}>
            <div className={classes.header}>
                <UiText variant={FontVariantToken.fontSizeH4Bold}>
                    {translate({ id: 'CLIENT_AUDIT_DETAILS_HEADER' })}
                </UiText>
                <IconButton
                    onClick={() => close()}
                    aria-label={translate({ id: 'CLIENT_CLOSE' })}
                    data-cy="close-button"
                >
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </div>
            <div className={classes.spacer} />
            <div className={classes.section}>
                <div>
                    <UiText variant={FontVariantToken.fontSizeMBold}>
                        {translate({ id: fields.firstHeader }) + ':'}
                    </UiText>
                    <UiText variant={FontVariantToken.fontSizeM}>
                        {fields.firstHeaderValue}
                    </UiText >
                </div>
                <UiCopyButton textToCopy={organizationId} />
            </div>
            <Divider className={classes.spacer} />
            <div>
                <div>
                    <UiText
                        variant={FontVariantToken.fontSizeMBold}
                        display='inline'>
                        {translate({ id: fields.secondHeader }) + ':'}
                    </UiText>
                &nbsp;
                    <UiText display='inline'>
                        {fields.secondHeaderValue}
                    </UiText>
                </div>
                <div className={classes.spacer} />
                <div>
                    <UiText
                        variant={FontVariantToken.fontSizeMBold}
                        display='inline'>
                        {translate({ id: 'CLIENT_SUMMARY' }) + ':'}
                    </UiText>
                &nbsp;
                    <UiText display='inline'>
                        {data.row?.eventSummary ?? ''}
                    </UiText>
                </div>
            </div>
            <Divider className={classes.spacer} />
            <pre className={classes.details}>
                <UiText variant={FontVariantToken.fontMonoMBold} >
                    {details}
                </UiText>
            </pre>
        </div>
        <div className={classes.footer}>
            <ApButton
                label={translate({ id: 'CLIENT_PREVIOUS' })}
                variant='tertiary'
                className={classes.button}
                onClick={() => data.row?.next(-1)} />
            <ApButton
                label={translate({ id: 'CLIENT_NEXT' })}
                onClick={() => data.row?.next()} />
        </div>
    </>;
};
