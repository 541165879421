import type { Region } from '@experiences/constants';
import { TranslatedRegionName } from '@experiences/constants';
import {
    get,
    useAuthContext,
} from '@experiences/util';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useSWR from 'swr';

import type { ISubscriptionForm } from '../interfaces/ecommerce';

const useEcommerceDataStorageRegionViewModel = () => {
    const { token } = useAuthContext();
    const {
        control,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const { data: currentRegions } = useSWR(
        {
            url: '/api/organization/supportedregions',
            accessToken: token,
        },
        async () => await get<Region[]>('/api/organization/supportedregions', { accessToken: token }),
    );
    const regions = useMemo(() => {

        if (currentRegions) {
            return Object.fromEntries(Object.entries(TranslatedRegionName)
                .filter(([ k ]) => currentRegions.toString().includes(k)));
        }
    }, [ currentRegions ]);

    return {
        regions,
        control,
        errors,
    };
};

export default useEcommerceDataStorageRegionViewModel;
