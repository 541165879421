import { UiDialog } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import * as RouteNames from '../../../common/constants/RouteNames';

const useStyles = makeStyles(theme =>
    createStyles({
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            height: '32px',
            width: '32px',
        },
    }),
);

const SkipServiceConfirmationDialogComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { state } = useLocation();
    const type = useMemo(() => state?.type, [ state ]);

    const close = useCallback(() => navigate(getRoute(`${RouteNames.Services}/${type}`)), [ getRoute, navigate, type ]);

    const callbackAndClose = useCallback(
        () => navigate(getRoute(`${RouteNames.Services}/${type}`),
            { state: { skip: true } }),
        [ getRoute, navigate, type ],
    );

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_SKIP_SERVICE_CONFIRMATION_TITLE' })}
            icon={<InfoIcon className={classes.infoIcon} />}
            actions={[
                <Button
                    key="cancel"
                    onClick={close}
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    key="skip"
                    variant="contained"
                    onClick={callbackAndClose}>
                    {translate({ id: 'CLIENT_SKIP' })}
                </Button>,
            ]}
            close={close}
        >
            {translate({ id: 'CLIENT_SKIP_SERVICE_CONFIRMATION_MESSAGE' })}
        </UiDialog>
    );
};

export default SkipServiceConfirmationDialogComponent;
