import React, {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
    generatePath,
    Navigate,
    useParams,
} from 'react-router-dom';

import ProtectedRoute from '../../auth/ProtectedRoute';
import * as RouteNames from '../../common/constants/RouteNames';
import {
    accountLogicalName,
    isHostModeSelector,
} from '../../store/selectors';

export const AccountAdmin = () => {
    const isHostMode = useSelector(isHostModeSelector);
    const currentAccountName = useSelector(accountLogicalName);
    const { accountName: paramAccountName } = useParams() as { accountName?: string };
    const hostPath = useMemo(() => generatePath(RouteNames.OrganizationAdminHome, { accountName: 'host' }), []);

    const getOrgPath = useCallback((accountName: string) => generatePath(RouteNames.Home, { accountName }), []);

    const path = useMemo(() => {
        if (paramAccountName) {
            return paramAccountName === 'host' ? hostPath : getOrgPath(paramAccountName);
        }
        return isHostMode ? hostPath : getOrgPath(currentAccountName);
    }, [ getOrgPath, currentAccountName, hostPath, isHostMode, paramAccountName ]);

    return <Navigate to={path} />;
};

export default (props: any) => (
    <ProtectedRoute>
        <AccountAdmin {...props} />
    </ProtectedRoute>
);
