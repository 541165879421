import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { useGetSetting } from '../../../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import { saveSetting } from '../../../../services/identity/SettingService';
import { accountGlobalId } from '../../../../store/selectors';

export const AccessPolicyType = {
    Everyone: false,
    Restricted: true,
};

export const AccessPolicySettingKey = [ 'Auth.PartitionAccessPolicy.Enabled' ];

const useStyles = makeStyles(() => createStyles({ removeDialogBody: { marginBottom: '20px' } }));

const useOrganizationAccessPolicySettingViewModel = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();

    const {
        data: fetchedSettings, isValidating: loading, mutate,
    } = useGetSetting(AccessPolicySettingKey);

    const [ accessPolicySettingState, setAccessPolicySettingState ] = useState(false);

    useEffect(() => {
        const accessPolicySetting = fetchedSettings?.find(setting => setting.key === AccessPolicySettingKey[0]);
        setAccessPolicySettingState(accessPolicySetting?.value === 'true');
    }, [ fetchedSettings ]);

    const saveAccessPolicySetting = useCallback(async (setting: boolean) => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_CHANGE_ORGANIZATION_ACCESS_SETTINGS' }),
            body: <div>
                <UiText className={classes.removeDialogBody}>
                    {translate({
                        id: setting
                            ? 'CLIENT_CHANGE_ORGANIZATION_ACCESS_SETTINGS_RESTRICT'
                            : 'CLIENT_CHANGE_ORGANIZATION_ACCESS_SETTINGS_EVERYONE',
                    })}
                </UiText>
                {setting && <UiText>
                    {translate({ id: 'CLIENT_ASK_CONFIRMATION' })}
                </UiText>}
            </div>,
            icon: 'info',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
        });

        if (!proceed) {
            setAccessPolicySettingState(!setting);
            return;
        }

        try {
            await saveSetting('', {
                settings: [
                    {
                        key: AccessPolicySettingKey[0],
                        value: setting.toString(),
                    },
                ],
                partitionGlobalId,
            });
            createNotification(translate({ id: 'CLIENT_ACCESS_POLICY_SUCCESSFULLY_CHANGED_SETTING' }), notificationType.SUCCESS);
            await mutate();
        } catch (e) {
            console.error(e);
        }
    }, [ classes.removeDialogBody, createDialog, createNotification, mutate, partitionGlobalId, translate ]);

    return {
        loading,
        accessPolicySettingState,
        setAccessPolicySettingState,
        saveAccessPolicySetting,
    };
};

export default useOrganizationAccessPolicySettingViewModel;
