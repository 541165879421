import {
    Features,
    getFeatureFlagConfig,
} from '@experiences/feature-flags';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
    getTenantById,
    tenantByIdUri,
} from '../../../services/organization/TenantService';

const useTenantServicesViewModel = () => {
    const { tenantId } = useParams() as { tenantId: string };

    const EnableInsightsExportConfig = getFeatureFlagConfig(Features.EnableInsightsExportConfig.name);

    const { data: tenant } = useSWR(
        {
            url: tenantByIdUri,
            id: tenantId,
        },
        getTenantById,
    );

    const enableInsightsExport: boolean = useMemo(() =>
        (process.buildConfigs.name === 'k8s' && String(window.env?.ENABLE_REALTIME_MONITORING) === 'true')
        || (tenant?.region ? EnableInsightsExportConfig.split(',').includes(tenant.region) : false)
    , [ EnableInsightsExportConfig, tenant?.region ]);

    return {
        EnableInsightsExportConfig,
        tenantId,
        enableInsightsExport,
    };

};

export default useTenantServicesViewModel;
