import { GlobalStyles } from '@experiences/theme';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

import { ReactComponent as ExternalLicense } from '../external_license.svg';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({ icon: { marginTop: '2px' } }),
}));

export default function ExternalLicenseIcon(props: any) {
    const classes = useStyles();

    const {
        style, ...rest
    } = props;
    return <ExternalLicense
        className={clsx(classes.icon, classes.svgIcon)}
        style={{ ...style }}
        {...rest} />;
}
