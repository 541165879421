import { AuthenticationSettingType } from '../../services/identity/AuthenticationSettingService';
import { useCheckAuthenticationSetting } from './useCheckAuthenticationSetting';

const useCheckIfADEnabledOnHost = () => {
    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    if (!bulkAuthenticationSetting) {
        return false;
    }
    return !!Object.entries(bulkAuthenticationSetting)
        .find(([ _key, value ]) => value.hostConnectionType?.toLowerCase() === AuthenticationSettingType.AD.toLowerCase()
            || value.hostConnectionType?.toLowerCase() === AuthenticationSettingType.LDAPAD.toLowerCase());
};

export default useCheckIfADEnabledOnHost;
