import { useLocalization } from '@experiences/locales';
import {
    NotificationSettingsEvent,
    portalTelemetry,
} from '@experiences/telemetry';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    getUserNotificationSubscription,
    resetUserNotificationSubscription,
    userNotificationSubscriptionUri,
} from '../../services/notification-preferences';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import { hasAnyChangeInPublisherPreferences } from './helpers/topicsGrouper';
import NotificationTabsComponent from './NotificationTabsComponent';
import {
    initialNotificationSettingsReducerState,
    notificationSettingsReducer,
} from './reducers';
import { ActionTypes } from './reducers/actionTypes';
import NotificationModePreferencesComponent from './v2/NotificationModePreferencesComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        heading: {
            fontSize: Tokens.FontFamily.FontLSize,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            paddingBottom: Tokens.Padding.PadXxxl,
        },
        previewHeadingSuffix: {
            fontSize: Tokens.FontFamily.FontXsSize,
            color: theme.palette.semantic.colorForegroundLight,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            lineHeight: Tokens.FontFamily.FontSLineHeight,
            paddingLeft: Tokens.Padding.PadM,
        },
        mainContentPlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
        },
        restoreDefaultSubscriptionBlock: { margin: '20px 0' },
        restoreDefaultSubscriptionLink: {
            marginLeft: Tokens.Padding.PadXxl,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            cursor: 'pointer',
            textDecoration: 'none',
        },
    }),
);

const NotificationSettingsComponentUser: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const currentAccountLanguage = useLocalization();
    const [ , setcurrentLang ] = React.useState<string>(currentAccountLanguage);
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const createNotification = useUiSnackBar();

    useEffect(() => {
        portalTelemetry.trackEvent({ name: NotificationSettingsEvent.LoadPreferences });
    }, [ ]);

    const {
        data: publishersData,
        isValidating,
        error,
        mutate: mutate,
    } = useSWR(
        tenantId ? {
            requestUri: userNotificationSubscriptionUri,
            selectedTenantId: tenantId,
        } : null,
        getUserNotificationSubscription, { revalidateOnFocus: false }
    );

    document.addEventListener('languageChanged', (event: any) => {
        setcurrentLang((oldvalue) => {
            if (event.detail.selectedLanguageId !== oldvalue) {
                mutate();
                return event.detail.selectedLanguageId;
            }
            return oldvalue;
        });
    });

    const [ notificationSettings, dispatch ] = React.useReducer(
        notificationSettingsReducer,
        initialNotificationSettingsReducerState,
    );

    useEffect(() => {
        if (publishersData && tenantId) {
            dispatch({
                type: ActionTypes.NS_INITIALIZE_NOTIFICATION_SETTINGS,
                data: publishersData.publishers,
            });
        }
    }, [ publishersData, tenantId ]);

    const createErrorNotification = useCallback(
        (message: string) => {
            createNotification(message, notificationType.ERROR);
        },
        [ createNotification ],
    );

    const handleError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createErrorNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`);
        },
        [ createErrorNotification, translate ],
    );

    const tabsList = useMemo(
        () =>
            notificationSettings
                .publishersWithGroupedTopics
                ?.map((publisher: any) => publisher.displayName),
        [ notificationSettings.publishersWithGroupedTopics ],
    );

    const isContentReady = useMemo(() => tabsList.length && !isValidating, [ tabsList.length, isValidating ]);
    const noPublisherDataFound = useMemo(
        () => publishersData && notificationSettings.publishers.length === 0,
        [ publishersData, notificationSettings.publishers.length ]);

    const { currentPublisher } = useMemo(
        () => ({ currentPublisher: notificationSettings.publishersWithGroupedTopics[notificationSettings.tabIndex] }),
        [ notificationSettings.publishersWithGroupedTopics, notificationSettings.tabIndex ],
    );

    const restoreDefaultSubscriptions = useCallback(async () => {
        dispatch({
            type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS,
            data: { publisherIndex: currentPublisher.publisherIndex },
        });

        try {
            dispatch({
                type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED,
                data: {
                    publisherIndex: currentPublisher.publisherIndex,
                    resetData: (await resetUserNotificationSubscription(currentPublisher.publisherId, tenantId))
                        .publishers,
                },
            });
        } catch (e) {
            handleError(e as Error);
            dispatch({
                type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED,
                data: { publisherIndex: currentPublisher.publisherIndex },
            });
        }
    }, [ dispatch, currentPublisher?.publisherId, currentPublisher?.publisherIndex, tenantId, handleError ]);
    const showRestoreDefault = useMemo(() => {
        if (currentPublisher?.topicGroups !== undefined) {
            return hasAnyChangeInPublisherPreferences(currentPublisher.topicGroups, false);
        }
    }
    , [ currentPublisher?.topicGroups ]);

    const getNotificationSettingsUiV2 = () => <>
        {showRestoreDefault && (
            <div className={classes.restoreDefaultSubscriptionBlock}>
                <PortalAlertBar
                    status="warning"
                    cancelable={false}>
                    <div>
                        {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOT_SUBSCRIBED_TO_ANY_TEXT' })}
                        <Link
                            className={classes.restoreDefaultSubscriptionLink}
                            onClick={restoreDefaultSubscriptions}>
                            {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_RESTORE_DEFAULT_SUBSCRIPTIONS' })}
                        </Link>
                    </div>
                </PortalAlertBar>
            </div>
        )}
        <NotificationModePreferencesComponent
            {...{
                notificationSettings,
                dispatch,
                handleError,
                isManagedMode: false,
                isUserGroup: false,
                hasEmail: true,
                isLocalGroup: false,
            }} />
    </>;
    const [ isManageActionmode, setManageActionmode ] = React.useState<boolean>(false);
    useEffect(() => {
        const severitymodeState = sessionStorage.getItem('isNSManageMode');
        setManageActionmode(severitymodeState === 'true');
    }, [ isManageActionmode ]);

    return (
        <>
            <div>

                {isContentReady ? (
                    <>
                        {!isManageActionmode &&
                    <>
                        <NotificationTabsComponent
                            {...{
                                notificationSettings,
                                tabsList,
                                dispatch,
                            }}
                        />
                        {
                            getNotificationSettingsUiV2()
                        }
                    </>}

                    </>
                ) : null}
                {!isContentReady && error ? (
                    <div className={classes.mainContentPlaceholder}>
                        <div>
                            {translate({ id: 'CLIENT_FAILED_TO_FETCH_DATA' }) +
                            '. ' +
                            translate({ id: 'CLIENT_TRIAL_REQUESTED_FAILED' })}
                        </div>
                    </div>
                ) : null}
                {(!isContentReady && !error) && noPublisherDataFound ? (
                    <div className={classes.mainContentPlaceholder}>
                        <div>
                            {translate({ id: 'CLIENT_NO_USAGE_DATA_FOUND' })}
                        </div>
                    </div>
                ) : null}
                {(!isContentReady && !error) && !noPublisherDataFound && (
                    <div className={classes.mainContentPlaceholder}>
                        <CircularProgress
                            thickness={2}
                            size={36} />
                    </div>
                )}
            </div>
        </>
    );
};

export default NotificationSettingsComponentUser;
