import Box from '@mui/material/Box';
import { sanitize } from 'dompurify';
import React from 'react';

export const ServiceIcon = ({ svg }: { svg?: string }) => (
    svg ? (
        <Box sx={{
            height: '24px',
            marginRight: '13px',
        }}>
            <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(sanitize(svg))}`}
                alt="service" />
        </Box>
    ) : null
);
