import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    mapAutomationCloudLanguageToStripe,
    useLocalization,
} from '@experiences/locales';
import { Elements } from '@stripe/react-stripe-js';
import type {
    Stripe,
    StripeElementsOptions,
} from '@stripe/stripe-js';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useEcommerce } from './helpers/useEcommerce';

export const EcommerceElementsWrapperComponent: React.FC<{
    paymentIntentClientSecret?: string;
    children?: React.ReactNode;
}> = ({
    paymentIntentClientSecret, children,
}) => {
    const [ stripePromise, setStripePromise ] = useState<Promise<Stripe | null> | null>(null);
    const { publicKey } = useEcommerce();
    const locale = useLocalization();
    const stripeLocale = useMemo(() => mapAutomationCloudLanguageToStripe(locale), [ locale ]);
    const EnableEcommerceIntegration = getFeatureFlagValue(Features.EnableEcommerceIntegration.name);

    useEffect(() => {
        if (publicKey) {
            const { loadStripe } = EnableEcommerceIntegration ? require('@stripe/stripe-js') : require('@stripe/stripe-js/pure');
            setStripePromise(
                loadStripe(publicKey, { locale: stripeLocale }),
            );
        }
    }, [ publicKey, stripeLocale, EnableEcommerceIntegration ]);
    const elementOptions = useMemo(() => {
        const options = { locale: stripeLocale } as StripeElementsOptions;
        if (paymentIntentClientSecret) {
            options.clientSecret = paymentIntentClientSecret;
        }
        return options;
    }, [ paymentIntentClientSecret, stripeLocale ]);

    return (
        <Elements
            stripe={stripePromise}
            options={elementOptions}
            key={locale} >
            {children}
        </Elements>
    );
};
