import Grid from '@mui/material/Grid';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { PropsWithChildren } from 'react';
import React from 'react';

interface UiCardGridThemeProps {
    position: 'center' | 'left';
}

const useStyles = makeStyles<Theme, UiCardGridThemeProps>(() =>
    createStyles({
        grid: ({ position }) => ({
            maxWidth: '1100px',
            justifyContent: position,
        }),
    }),
);

const UiCardGrid: React.FC<PropsWithChildren<{
    maxCardWidth: string;
    style?: React.CSSProperties;
    gap?: string;
    position?: 'center' | 'left';
}>> = ({
    children, maxCardWidth, style, gap = '24px', position = 'center',
}) => {
    const classes = useStyles({ position });

    return <Grid
        className={classes.grid}
        role="grid"
        sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(200px, ${maxCardWidth}))`,
            gridAutoFlow: 'row',
            gap,
        }}
        style={style}>
        {children}
    </Grid>;
};

export default UiCardGrid;
