import type { IUiDialogHookParams } from '@experiences/interfaces';
import type { en } from '@experiences/locales';
import { enCountries } from '@experiences/locales';
import type React from 'react';
import type {
    NavigateFunction,
    useLocation,
} from 'react-router-dom';

import {
    MARKETO_DATA,
    storeCountryCode,
} from '../helpers/EcommerceHelpers';
import type {
    IBusinessInfoPayload,
    ISubscriptionForm,
} from '../interfaces/ecommerce';
import { sendUserMarketoData } from '../services/BillingService';

export type enTranslationKeys = keyof typeof en;

export const changeCountryHandlerCallback = async (unified: boolean,
    currentCountry: string,
    countryCode: string,
    setWaitingForUser: React.Dispatch<React.SetStateAction<boolean>>,
    createDialog: (parameters: IUiDialogHookParams) => Promise<boolean>,
    translate: any,
    watch: any,
    setValue: any,
    setEcommerceCountry: (countryCode: string) => void,
    setEcommerceCountryChangeDialogClosed: (countryChange: boolean) => void,
    navigate: NavigateFunction,
    location: ReturnType<typeof useLocation>,
    clearErrors: () => void,
) => {
    setWaitingForUser(true);
    const proceed = await createDialog({
        title: translate({ id: 'CLIENT_COUNTRY_WARNING' }),
        body: translate({ id: 'CLIENT_COUNTRY_WARNING_BODY' }),
        icon: 'warning',
        showCancel: true,
    });
    if (proceed) {
        clearErrors();
        setEcommerceCountry(currentCountry);
        if (!unified) {
            storeCountryCode(currentCountry);
        }
        navigate(
            location.pathname,
            {
                replace: true,
                state: {
                    ...(location.state as any),
                    billingInfo: watch(),
                },
            });
        window.location.reload();
    } else {
        setValue('country', countryCode || '');
        setEcommerceCountryChangeDialogClosed(true); // dialog closed
    }
    setWaitingForUser(false);
};

const countryCodeToEnCountryName: { [key: string]: string } = enCountries;
export const sendDataToMarketo = async (data: ISubscriptionForm,
    existingMarketoData: IBusinessInfoPayload | undefined,
    currentAccountLanguage: string,
    token: string,
    currentAccountName: string | undefined,
    isSignupDirectBuyFlow: boolean = false) => {
    try {
        if ('businessEmail' in data && 'companyName' in data) {
            const marketoData = {
                firstName: data.firstName ?? existingMarketoData?.firstName ?? '',
                lastName: data.lastName ?? existingMarketoData?.lastName ?? '',
                country: data.country ? countryCodeToEnCountryName[data.country] : existingMarketoData?.country ?? '',
                state: data.state ?? existingMarketoData?.state ?? '',
                businessEmail: data.businessEmail ?? '',
                companyName: data.companyName ?? '',
                jobTitle: existingMarketoData?.jobTitle ?? '',
                userLanguage: currentAccountLanguage ?? '',
                ...(isSignupDirectBuyFlow && { marketingConsent: data.marketingConditionsAccepted ?? false }),
            };
            await sendUserMarketoData(marketoData, token, currentAccountName);
            localStorage.setItem(MARKETO_DATA, JSON.stringify(marketoData));
        }
    } catch (error) {
        console.error(JSON.stringify(error));
    }
};
