import type { Token } from '@experiences/interfaces';

import type { IUser } from '../helpers/EcommerceSelectors';
import type {
    IBusinessInfoPayload,
    ISubscriptionForm,
} from '../interfaces/ecommerce';

export const setUnifiedFormDefaultValues = ({
    savedBillingInfo,
    countryCode,
    defaultCurrency,
    profileState,
    claims,
    existingMarketoData,
}: {
    savedBillingInfo: ISubscriptionForm | undefined;
    countryCode: string;
    defaultCurrency: string;
    profileState: IUser | undefined;
    claims: Token | undefined;
    existingMarketoData: IBusinessInfoPayload | undefined;
}) => ({
    firstName: savedBillingInfo?.firstName ?? existingMarketoData?.firstName ?? claims?.given_name ?? '',
    lastName: savedBillingInfo?.lastName ?? existingMarketoData?.lastName ?? claims?.family_name ?? '',
    addressLine1: savedBillingInfo?.addressLine1 ?? '',
    addressLine2: savedBillingInfo?.addressLine2 ?? '',
    country: savedBillingInfo?.country ?? countryCode ?? '',
    currency: savedBillingInfo?.currency ?? defaultCurrency,
    city: savedBillingInfo?.city ?? '',
    state: savedBillingInfo?.state ?? existingMarketoData?.state ?? '',
    zipcode: savedBillingInfo?.zipcode ?? '',
    taxId: savedBillingInfo?.taxId ?? '',
    nameOnCard: savedBillingInfo?.nameOnCard ?? '',
    termsAndConditionsAccepted: savedBillingInfo?.termsAndConditionsAccepted ?? false,
    marketingConditionsAccepted: savedBillingInfo?.marketingConditionsAccepted ?? false,
    businessEmail: profileState?.emailId ?? claims?.email ?? '',
    companyName: profileState?.accountUserDto?.companyName ?? existingMarketoData?.companyName ?? '',
    jobTitle: existingMarketoData?.jobTitle ?? '',
    logicalName: savedBillingInfo?.logicalName ?? '',
});
