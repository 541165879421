import {
    ApButton,
    PortalIcon,
} from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

export const UiRefreshButton: React.FC<{
    loading: boolean;
    onClick: () => void;
}> = ({
    loading, onClick,
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        <ApButton
            size="small"
            variant="tertiary"
            loading={loading}
            onClick={loading ? undefined : onClick}
            data-cy="ui-grid-refresh-button"
            label={translate({ id: 'CLIENT_REFRESH' })}
        >
            <PortalIcon
                slot="start-icon"
                name="refresh" />
        </ApButton>
    );
};
