import type {
    ITask,
    StatusType,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getTask } from '../services/getTask';

export const ActionsWidget: WidgetTemplate<ITask> = {
    name: 'Actions',
    titleKey: 'CLIENT_ACTIONS_WIDGET_TITLE',
    subtitleKey: 'CLIENT_ACTIONS_WIDGET_SUBTITLE',
    emptyMsgKey: () => 'CLIENT_ACTIONS_WIDGET_EMPTY',
    seeAllTextKey: 'CLIENT_SEE_ALL_ACTIONS',
    tableColumns: 3,
    tableHeaders: [
        {
            nameKey: 'CLIENT_NAME',
            colStyle: 'headerClickable',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_ASSIGNED',
            colStyle: 'subDetail',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_PRIORITY',
            colStyle: 'statusChip',
        },
    ],
    type: 'Table',
    getSeeAllUrl: (ctx: WidgetContext) => `${window.location.origin}/${ctx.accountLogicalName}/${ctx.tenant}/actions_/tasks?status=Pending`,
    fetch: async (ctx: WidgetContext) => {
        const tasks = await getTask(ctx.accessToken, ctx.tenant, `Pending`);
        return Promise.resolve(tasks.value);
    },
    transform: (data: ITask[]) => data.map((item: ITask) => {
        const {
            statusKey, statusType,
        } = resolveStatus(item);

        return {
            data: item,
            category: 'Action',
            header: item.Title,
            description: '',
            isSubtitleTime: true,
            subtitle: item.CreationTime.toString(),
            statusKey,
            statusType,
        };
    }),
    clickHandler: (data: ITask, ctx: WidgetContext) => {
        window.location.assign(getActionUrl(ctx, data));
    },
};

export function getActionUrl(ctx: WidgetContext, data: ITask): string {
    return `${window.location.origin}/${ctx.accountLogicalName}/${ctx.tenant}/actions_/tasks/${data.Id}`;
}

export function resolveStatus(item: ITask) {
    const statusMap: { [key: string]: StatusType } = {
        'High': 'warning',
        'Critical': 'error',
        'Medium': 'warning',
        'Low': 'success',
    };

    return {
        statusKey: `PRIORITY_${item.Priority.toUpperCase()}`,
        statusType: statusMap[item.Priority] ?? 'info',
    };
}

