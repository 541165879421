import type {
    FREData,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getFRE } from '../services/getFRE';

export const FREWidget: WidgetTemplate<FREData> = {
    name: 'FRE',
    titleKey: '',
    subtitleKey: '',
    emptyMsgKey: () => '',
    seeAllTextKey: '',
    type: 'Presentation',
    cardRows: 2,
    cardHeight: 310,
    selection: (_ctx: WidgetContext) => Promise.resolve(_ctx.accountType === 'PRO-TRIAL'),
    fetch: async (ctx: WidgetContext) => {
        const { freCardList } = await getFRE(ctx.accessToken, ctx.accountLogicalName, ctx.language);
        return Promise.resolve(freCardList.cardsCollection.items);
    },
    transform: (data: FREData[]) => data.map((item: FREData) => ({
        data: item,
        category: 'Action',
        header: item.header,
        description: item.footer,
        subtitle: item.timeToExecute,
        icon: item.lightIcon.url,
    })),
    clickHandler: (data: FREData, _ctx: WidgetContext) => {
        if (data.navOut) {
            window.open(data.link, '_blank');
            return;
        }

        if (data.redirectUrl) {
            window.location.assign(`${window.location.origin}/portal_/cloudrpa?redirectPath=${data.redirectUrl}`);
            return;
        }

        if (data.link) {
            window.location.assign(data.link);
        }
    },
};
