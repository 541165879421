import { GlobalStyles } from '@experiences/theme';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { KeyboardEvent } from 'react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        backToLicensesText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'none' },
        },
    }),
}));

const EcommerceBackToLicense: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const navigateBackToLicenses = useCallback(() => {
        navigate(getRoute(RouteNames.Licensing));
    }, [ navigate, getRoute ]);

    return (<Link
        className={classes.backToLicensesText}
        onClick={navigateBackToLicenses}
        onKeyDown={(e: KeyboardEvent<HTMLAnchorElement> | KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
                navigateBackToLicenses();
            }
        }}
        data-cy="ecommerce-go-to-licensing"
        role='navigation'
        tabIndex={0}>
        <KeyboardArrowLeftIcon fontSize="small" />
        {translate({ id: 'CLIENT_BACK_TO_LICENSES' })}
    </Link>)
    ;
};

export default EcommerceBackToLicense;
