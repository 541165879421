import type {
    IPagination,
    ITagModelObject,
} from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    getKeyValues,
    getPermissions,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTagsDeleteDialog } from '../../common/useTagsDeleteDialog';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const useTenantTagPropertiesViewModel = () => {
    const { tenantId } = useParams() as { tenantId: string };
    const navigate = useNavigateWithParams();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();

    // Redux state
    const organizationName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);

    // Component state
    const [ pagination, setPagination ] = useState<IPagination>({
        searchTerm: '',
        top: DEFAULT_PAGE_SIZE,
        skip: DEFAULT_PAGE_INDEX * DEFAULT_PAGE_SIZE,
    });

    const { mutate } = useSWRConfig();

    const { data: tenant } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ?
            {
                url: organizationManagementTenantUri,
                id: tenantId,
            } : null,
        getTenantById,
    );

    const { data: permissions } = useSWR(
        (organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant) ?
            {
                url: `${tagsUrl}/permissions`,
                accountLogicalName: organizationName,
                tenantName: tenant?.name,
                selectedAccountId: accountId,
            } : null,
        getPermissions,
    );

    const {
        data: propertiesData, isValidating: loading, mutate: propertiesMutate,
    } = useSWR(
        (organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant) ?
            {
                url: `${tagsUrl}/keyValue`,
                pagination,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            } : null,
        getKeyValues,
    );

    const displayValues = useCallback((values: string[] | undefined) => {
        if (!values) {
            return values;
        }
        if (values.length > 4) {
            return values.slice(0, 4).concat([ ' ' + translate({ id: 'CLIENT_AND_MORE' }) ]);
        }
        return values;
    }, [ translate ]);

    useEffect(() => {
        propertiesData?.results.map((iterator) => {
            iterator.values = displayValues(iterator.values);
        });
    }, [ displayValues, propertiesData ]);

    const openDeleteDialog = useTagsDeleteDialog('Property', organizationName, tenantId, () => {
        propertiesMutate();
        createNotification(translate({ id: 'CLIENT_TENANT_TAGS_DELETE_SUCCESS' }), notificationType.SUCCESS);
    });

    const handleAddProperty = useCallback(() => {
        navigate(getRoute(process.buildConfigs.showForMSI
            ? `${RouteNames.TenantTagsPropertiesMsi}/add/key`
            : `${RouteNames.TenantTagsProperties}/add/key`.replace(':tenantId', tenantId)));
    }, [ getRoute, navigate, tenantId ]);

    const handleDeleteProperty = useCallback((rows: ITagModelObject[] | undefined) => openDeleteDialog(rows ?? []), [ openDeleteDialog ]);

    const onChangePagination = useCallback(({
        pageIndex, pageSize, searchTerm,
    }: any /* REACT_18_TODO: should be IGridFilters, but its not exported */) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
        };

        setPagination(newPaginationState);

        if (tenant) {
            mutate({
                url: `${tagsUrl}/keyValue`,
                pagination: newPaginationState,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            });
        }
    }, [ accountId, mutate, organizationName, tenant ]);

    return {
        organizationName,
        tenantId,
        tenantName: tenant?.name,
        isAdmin,
        openDeleteDialog,
        displayValues,
        handleAddProperty,
        permissions,
        loading,
        refresh: propertiesMutate,
        handleDeleteProperty,
        propertiesData,
        onChangePagination,
    };
};

export default useTenantTagPropertiesViewModel;
