import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { ApButton } from '@uipath/portal-shell-react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../common/constants/Constant';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { deleteReferenceTokenByValue } from '../../../services/identity/ReferenceTokenService';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        marginTop: '32px',
        justifyContent: 'flex-end',
    },
}));

export const RevokePATByValueDialogBody = ({
    closeDialog, partitionGlobalId, mutateReferences,
}: IUiDialogHookCustomContent) => {
    const { formatMessage: translate } = useIntl();
    const [ tokenValue, setTokenValue ] = useState('');
    const [ revokeButtonDisabled, setRevokeButtonDisabled ] = useState(true);
    const [ revokeButtonLoading, setRevokeButtonLoading ] = useState(false);
    const classes = useStyles();
    const createNotification = useUiSnackBar();

    const handleTokenFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenValue(event.target.value);
        setRevokeButtonDisabled(!event.target.value);
    };

    const onRevoke = React.useCallback(async () => {
        try {
            setRevokeButtonLoading(true);
            await deleteReferenceTokenByValue(partitionGlobalId, tokenValue);
            mutateReferences();
            createNotification(translate({ id: 'CLIENT_SUCCESSFULLY_REVOKED_TOKENS' }), notificationType.SUCCESS);
            closeDialog();
        } catch {
            createNotification(translate({ id: 'CLIENT_FAILED_TO_REVOKE_TOKENS' }), notificationType.ERROR);
        } finally {
            setRevokeButtonLoading(false);
        }
    }, [ partitionGlobalId, tokenValue, mutateReferences, createNotification, translate, closeDialog ]);

    return <>
        <Box>
            <UiText style={{ marginBottom: '20px' }}>
                {translate(
                    { id: 'CLIENT_SHOULD_REVOKE_TOKEN' },
                )}
            </UiText>
            <TextField
                variant='outlined'
                label={translate({ id: 'CLIENT_ENTER_TOKEN_TO_BE_REVOKED' })}
                value={tokenValue}
                onChange={handleTokenFieldChange}
                fullWidth
            />
        </Box>
        <UiStack
            className={classes.buttonContainer}
            gap={SpacingToken.XS}
        >
            <ApButton
                key="cancelButton"
                variant="tertiary"
                onClick={() => closeDialog()}
                data-cy="secondary-button-confirmation"
                data-testid="ui-dialog-cancel-button"
                label={translate({ id: 'CLIENT_CANCEL' })}
            />
            <ApButton
                key="primaryButton"
                variant="primary"
                disabled={revokeButtonDisabled || revokeButtonLoading}
                data-cy="primary-button-confirmation"
                data-testid="ui-dialog-revoke-button"
                onClick={() => onRevoke()}
                label={translate({ id: 'CLIENT_REVOKE' })}
                loading={revokeButtonLoading}
            />
        </UiStack>
    </>
    ;
};
