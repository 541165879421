import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { UiError } from './UiError';

export const useGetErrorInfo = () => {
    const { formatMessage: translate } = useIntl();

    const findErrorMessage = useCallback(
        async (error: any) => {
            let err = error;
            if (!err) {
                return '';
            }

            if (err instanceof Response && !!err.body) {
                try {
                    err = await err.json();
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error('Failed to parse JSON:', e);
                }
            }

            let errMessageResult = translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' }) as any;
            const stack = err?.stack;
            const status = err?.status;
            const code = err?.errorCode || err?.data?.errorCode;
            const message = err?.message || err?.Message;
            const errData = err?.data?.errorMessage || err?.data?.Message || err?.data;
            const errDataIsStringType = !!err?.data?.data && typeof err?.data.data === 'string';

            if (status === 500 || status === 401 || status === 403 || err?.headers?.['content-type'].includes('text/html')) {
                errMessageResult = translate({
                    id: status === 500 || err?.headers?.['content-type'].includes('text/html')
                        ? 'CLIENT_GENERIC_ERROR_MESSAGE'
                        : 'CLIENT_FORBIDDEN_ERROR_MESSAGE',
                });
            } else if (code) {
                const uiError = new UiError(code);
                errMessageResult = uiError.getMessage();
            } else if (err.errors?.length) {
                errMessageResult = err.errors.find((errObj: { code: string; description: string }) =>
                    errObj.code === 'DuplicateEmail')?.code;
            } else if (err.errorMessage) {
                ({ errMessageResult } = err);
            } else if (message === 'Failed to fetch') {
                errMessageResult = translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' });
            } else if (typeof errData === 'string' && errData.includes('Given tenant display name')) {
                errMessageResult = translate({ id: 'CLIENT_TENANT_DUPLICATE_NAME' });
            } else if (message || errDataIsStringType || errData) {
                errMessageResult = message || (errDataIsStringType && err.data.data) || errData;
                if (stack) {
                    errMessageResult += stack;
                }
            }
            return errMessageResult;
        },
        [ translate ],
    );

    const getErrorObject = useCallback(
        async (error: any) => {
            let err = error;

            if (error instanceof Promise) {
                try {
                    err = await error;
                } catch (awaitError) {
                    const errorFetchingError = { error_message: translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' }) };
                    return errorFetchingError;
                }
            }

            return err;
        },
        [ translate ],
    );

    const getErrorMessage = useCallback(
        async (error: any) => {
            const err = await getErrorObject(error);
            return findErrorMessage(err.response ?? err);
        },
        [ findErrorMessage, getErrorObject ],
    );

    const parseError = useCallback(
        async (error: any) => {
            const errorObject = await getErrorObject(error);
            const data = await errorObject.response?.data;
            const errorResponse = typeof data === 'string' ? data : await getErrorMessage(errorObject);
            throw errorResponse;
        },
        [ getErrorMessage, getErrorObject ],
    );

    const parseErrorObject = useCallback(
        async (error: any) => {
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            try {
                return typeof data === 'string' ? JSON.parse(errorObject.response?.data) : data;
            } catch (err) {
                // JSON.parse failure means data is not an object, getErrorMessage and return
                return getErrorMessage(error);
            }
        },
        [ getErrorMessage, getErrorObject ],
    );

    const safeParse = useCallback((error: string) => {
        try {
            return JSON.parse(error);
        } catch (err) {
            return undefined;
        }
    }, []);

    return {
        findErrorMessage,
        getErrorObject,
        getErrorMessage,
        parseError,
        parseErrorObject,
        safeParse,
    };
};
