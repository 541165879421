import { LogExportInsStorageType } from '@experiences/constants';

export const logExportInsStorageTypeSelectItems = [
    {
        id: LogExportInsStorageType.AzureEventHub,
        displayId: 'CLIENT_AZURE_EVENT_HUBS',
        hidden: false,
    },
    {
        id: LogExportInsStorageType.AwsSqs,
        displayId: 'CLIENT_AWS_SQS',
        hidden: false,
    },
];

export const logExportInsStorageTypeSelectItemsOnPrem = [
    {
        id: LogExportInsStorageType.Splunk,
        displayId: 'CLIENT_SPLUNK',
        hidden: false,
    },
];

export const AzEventHubsConnStrPattern = /Endpoint=sb:\/\/.*\.servicebus.windows.net\/;SharedAccessKeyName=.*;SharedAccessKey=.*/;
