import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import * as RouteNames from '../../common/constants/RouteNames';
import useIsBasicAuthEnabled from '../../common/hooks/useIsBasicAuthEnabled';
import { isHostModeSelector } from '../../store/selectors';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: { maxWidth: '482px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
        subHeadingArea: { paddingBottom: '20px' },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
    }),
);

const PrivacyAndSecuritySettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const isBasicAuthEnabled = useIsBasicAuthEnabled();
    const isHostMode = useSelector(isHostModeSelector);
    const {
        checkTokenAuthIsLocal, checkTokenAuthIsSAML,
    } = useCheckAuthTypes();
    const isDirectoryUser = useMemo(
        () => !checkTokenAuthIsLocal && !checkTokenAuthIsSAML,
        [ checkTokenAuthIsLocal, checkTokenAuthIsSAML ],
    );

    const handleResetPassword = useCallback(() => {
        navigate(getRoute(RouteNames.ChangePassword));
    }, [ getRoute, navigate ]);

    return (
        <div className={classes.root}>
            <UiText
                className={clsx(classes.heading, classes.mainHeading)}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_PRIVACY_AND_SECURITY' })}
            </UiText>
            {(isHostMode || (!isDirectoryUser && isBasicAuthEnabled)) && (
                <>
                    <div className={classes.subHeadingArea}>
                        <UiText className={classes.subHeading}>
                            {translate({ id: 'CLIENT_PASSWORD' })}
                        </UiText>
                        <UiText>
                            {translate({ id: 'CLIENT_CHANGE_PASSWORD_DESCRIPTION' })}
                        </UiText>
                    </div>
                    <Button
                        data-cy="privacy-and-security-settings-change-password-button"
                        variant="outlined"
                        onClick={handleResetPassword}
                    >
                        {translate({ id: 'CLIENT_CHANGE_PASSWORD' })}
                    </Button>
                </>
            )}
        </div>
    );
};

export default PrivacyAndSecuritySettingsComponent;
