export enum AttributeType {
    BUSINESS = 'BusinessUnit',
    JOB = 'JobTitle',
    LOCATION = 'Location',
    DEPARTMENT = 'Department',
    DISPLAYNAME = 'DisplayName',
    FIRSTNAME = 'FirstName',
    LASTNAME = 'LastName',
    EMAIL = 'Email',
    CITY = 'City',
    IDENTIFIER = 'UniqueId',
}

export interface IFieldAttribute {
    attributeType: AttributeType;
    value: string;
    disableRemove?: boolean;
    disableEdit?: boolean;
}
