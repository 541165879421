import type {
    CatalogResponse,
    Persona,
} from '@experiences/interfaces';
import { post } from '@experiences/util';

const recommendationUrl = '/srs_/api/v2/recommendations';

export async function getRecommendations(
    accessToken: string,
    persona: Persona,
    locale: string,
    zoneId: string) {

    const body = {
        persona,
        locale,
        services: [ 'Portal' ],
        route: 'UiPath.AutomationCloud',
        zones: [
            {
                'id': zoneId,
                'limit': 8,
            },
        ],
    };

    return post<CatalogResponse>(recommendationUrl, {
        accessToken,
        body,
        stripPortalPath: true,
    });
}
