import {
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import {
    Button,
    MenuItem,
    Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, { useCallback } from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getTeamsConnections } from '../../../services/notification-preferences';
import { accountGlobalId } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import type {
    IConnectionDetail,
    ITeamsChannel,
    ITeamsConnectionConfiguration,
    ITeamsTeam,
} from '../interfaces/notificationSettings';
import { UiTeamsChannelPicker } from './TeamsConfiguration/UiTeamsChannelPicker';
import { UiTeamsPicker } from './TeamsConfiguration/UITeamsPicker';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: Tokens.Padding.PadXxxl,
        minWidth: '480px',
        minHeight: '280px',
    },
    inputLabel: {
        fontWeight: Tokens.FontFamily.FontBrandH4Weight,
        fontSize: Tokens.FontFamily.FontMSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
        marginBottom: Tokens.Padding.PadXxl,
    },
    selectinput: { width: '100%' },
}));

export const TeamsPublisherSettings: React.FC<{
    connectionId: string;
    defaultChannelName: string;
    defaultChannelId: string;
    publisherChannelName?: string;
    publisherChannelId?: string;
    defaultTeamName: string;
    defaultTeamId: string;
    publisherTeamName?: string;
    publisherTeamId?: string;
    publisherName: string;
    onChange: (value: ITeamsChannel, team: ITeamsTeam) => void;
    close: () => void;
}> = ({
    connectionId, defaultChannelId, defaultChannelName,
    publisherChannelId, publisherChannelName, defaultTeamName,
    defaultTeamId, publisherTeamName, publisherTeamId, publisherName, onChange, close,
}) => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ selectTeam, setSelectTeam ] = React.useState<ITeamsTeam | undefined>(
        publisherTeamId && publisherTeamName ? {
            id: publisherTeamId,
            displayName: publisherTeamName,
        } : {
            id: defaultTeamId,
            displayName: defaultTeamName,
        },
    );

    const {
        control, handleSubmit, register, getValues,
        setValue,
    } = useForm<ITeamsConnectionConfiguration>({
        mode: 'onChange',
        defaultValues: {
            supplementConnectionId: connectionId,
            name: '',
            connectorType: '',
            isScopedToAccount: false,
            isEnabled: false,
            channelKey: publisherChannelId ?? defaultChannelId,
            channelName: publisherChannelName ?? defaultChannelName,
            teamId: publisherTeamId ?? defaultTeamId,
            teamName: publisherTeamName ?? defaultTeamName,
        },
    });
    const [ selectedTeamsConnector ] = React.useState<string>(connectionId);
    const onSubmit = useCallback(async (data: ITeamsConnectionConfiguration) => {
        onChange?.({
            id: data.channelKey,
            displayName: data.channelName,
        }, {
            id: data.teamId,
            displayName: data.teamName,
        });
        close();
    }, [ close, onChange ]);

    const accountId = useSelector(accountGlobalId);
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const { data: connections } = useSWR(
        tenantId ? {
            selectedTenantId: tenantId,
            selectedAccontId: accountId,
        } : null,
        getTeamsConnections,
    );

    return (
        <UiDialog
            close={() => close()}
            dataCy='teams-publishers-dialog'
            actions={[
                <Button
                    key="cancel"
                    data-cy="button-cancel"
                    onClick={close}
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    key="configure"
                    data-cy="button-configure"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    color="primary">
                    {translate({ id: 'CLIENT_CONFIRM' })}
                </Button>,
            ]}
            dialogProps={{
                open: true,
                maxWidth: 'xl',
            }}
            title={translate({ id: 'CLIENT_TEAMS_CHANNEL_DIALOG_TITLE' }, { 0: publisherName })}
        >
            <UiForm
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
                id="teamspublisherConfigurationForm"
            >
                <UiText
                    className={classes.inputLabel}
                    id="senderLogoLabel">
                    {translate({ id: 'CLIENT_TEAMS_CONNECTION_DIALOG_INPUT_LABEL' })}
                </UiText>
                <Select
                    value={selectedTeamsConnector}
                    inputProps={{
                        id: 'currency',
                        name: 'currency',
                        style: {
                            height: '100%',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                        },
                    }}
                    disabled
                    className={classes.selectinput}
                    variant='outlined'
                >
                    {connections?.connections.map((connection: IConnectionDetail, i: number) => (
                        <MenuItem
                            key={i}
                            value={connection.id}
                            aria-label={connection.connectorName}
                        >
                            {connection.name}
                        </MenuItem>
                    ))}
                </Select>

                <Controller
                    {...register('teamId', { required: true })}
                    control={control}
                    render={({ field }) => (
                        <UiTeamsPicker
                            searchOnly
                            showTooltip
                            labelText={translate({ id: 'CLIENT_TEAMS_TEAM_INPUT_LABEL' })}
                            connectionId={getValues('supplementConnectionId')}
                            value={selectTeam ?? {
                                id: '',
                                displayName: '',
                            }}
                            onChange={(msteamsEntries: ITeamsTeam[]) => {
                                if (msteamsEntries.length > 0 && msteamsEntries[0]) {
                                    field.onChange(msteamsEntries[0].id);
                                    setSelectTeam(msteamsEntries[0]);
                                    setValue('teamId', msteamsEntries[0].id);
                                    setValue('teamName', msteamsEntries[0].displayName);
                                } else {
                                    setValue('teamName', '');
                                    field.onChange('');
                                    setValue('teamId', '');
                                    setSelectTeam(undefined);
                                }
                                setValue('channelName', '');
                                setValue('channelKey', '');
                            }}
                        />
                    )}
                />

                <Controller
                    {...register('channelKey', { required: true })}
                    control={control}
                    render={({ field }) => (
                        <UiTeamsChannelPicker
                            searchOnly
                            showTooltip
                            labelText={translate({ id: 'CLIENT_TEAMS_CHANNEL_INPUT_LABEL' })}
                            connectionId={getValues('supplementConnectionId') ?? ''}
                            teamId={getValues('teamId')}
                            value={{
                                id: getValues('channelKey'),
                                displayName: getValues('channelName'),
                            }}
                            onChange={(msteamChannelEntries: ITeamsChannel[]) => {
                                if (msteamChannelEntries.length > 0 && msteamChannelEntries[0]) {
                                    field.onChange(msteamChannelEntries[0].id);
                                    setValue('channelKey', msteamChannelEntries[0].id);
                                    setValue('channelName', msteamChannelEntries[0].displayName);
                                } else {
                                    field.onChange('');
                                    setValue('channelName', '');
                                    setValue('channelKey', '');
                                }
                            }}
                        />
                    )}
                />
            </UiForm>
        </UiDialog>
    );
};

