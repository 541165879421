import { UiSelect } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import useEcommerceCountryAndCurrencyViewModel from './useEcommerceCountryAndCurrencyViewModel';

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
    }),
);

const EcommerceCountryAndCurrency: React.FC<{
    currencies: string[];
    disabled: boolean;
}> = ({
    currencies, disabled,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control,
        errors,
        countryCodeCountries,
        defaultCountryCode,
        countrySelectRef,
        fixedCurrency,
    } = useEcommerceCountryAndCurrencyViewModel(disabled);

    return (
        <div className={classes.row}>
            <UiSelect
                ref={countrySelectRef}
                style={{ marginRight: '12px' }}
                control={control}
                name="country"
                isTranslated
                inputLabel={translate({ id: 'CLIENT_FORM_COUNTRY_OR_REGION' })}
                options={countryCodeCountries}
                defaultValue={defaultCountryCode}
                required
                fullWidth
                dataCy="ecommerce-form-country"
                error={!!errors.country}
                disabled={disabled}
                helperText={
                    errors.country?.type === 'required' &&
          translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
            />
            <UiSelect
                control={control}
                name="currency"
                inputLabel={translate({ id: 'CLIENT_CURRENCY' })}
                options={currencies}
                disabled={currencies.length === 1 || disabled || fixedCurrency}
                required
                fullWidth
                dataCy="ecommerce-form-currency"
                error={!!errors.country}
                helperText={
                    errors.country?.type === 'required' &&
          translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
            />
        </div>
    );
};

export default EcommerceCountryAndCurrency;
