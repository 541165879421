import {
    Features,
    setLocalFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { ApToggleSwitch } from '@uipath/portal-shell-react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import { routePaths } from '../../../common/constants/routePaths';

export const AccountAndLocalGroupsPreviewToggle = () => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const EnableNewAccountAndGroupsUX = useFeatureFlagValue(Features.EnableNewAccountAndGroupsUX.name);
    const EnableNewAccountAndGroupsUXToggle = useFeatureFlagValue(Features.EnableNewAccountAndGroupsUXToggle.name);

    const toggleNewAccountAndLocalGroupsUX = useCallback(() => {
        const nextValue = !EnableNewAccountAndGroupsUX;

        setLocalFeatureFlagValue(Features.EnableNewAccountAndGroupsUX.name, String(nextValue));

        navigate(nextValue ? routePaths.adminIdentitiesUsers : getRoute(RouteNames.Users), { replace: true });
    }, [ EnableNewAccountAndGroupsUX, getRoute, navigate ]);

    return EnableNewAccountAndGroupsUXToggle ? <ApToggleSwitch
        label={translate({ id: 'CLIENT_ACCOUNTS_AND_LOCAL_GROUPS_TOGGLE_LABEL' })}
        checked={EnableNewAccountAndGroupsUX}
        onClick={() => toggleNewAccountAndLocalGroupsUX()} /> : undefined;
};
