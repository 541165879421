import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

export const getResourceToHiddenScopes = () => {
    const ShowPlatformManagementLicensingScopes = getFeatureFlagValue(Features.ShowPlatformManagementLicensingScopes.name);

    return new Map<string, string[]>([
        [
            'PlatformManagement',
            !ShowPlatformManagementLicensingScopes ? [ 'PM.License', 'PM.License.Write', 'PM.License.Read' ] : [],
        ],
    ]);
};
