import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ITagModelObject } from '@experiences/interfaces';
import {
    OrchestratorObjectType,
    PermissionType,
} from '@experiences/interfaces';
import { PortalTagManagementEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import ArrowRight from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../../../common/constants/RouteNames';
import { accountLogicalName } from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import UiMenu from '../../../common/UiMenu/UiMenu';
import UiNestedMenuItem from '../../../common/UiMenu/UiNestedMenuItem';
import { hasPermission } from '../../addedit/properties/TenantTagsUtil';
import { redirectToOrchestrator } from '../../common/redirectToOrchestrator.default';
import { useTagsDisabled } from '../../common/useTagsDisabled';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        deleteButton: { color: theme.palette.semantic.colorErrorText },
        menuItem: { '&:focus-visible': { backgroundColor: theme.palette.semantic.colorHover } },
    }),
}));

const TenantTagsLabelsMoreActions: React.FC<{
    row: ITagModelObject;
    tenantId: string;
    tenantName?: string;
    permissions?: PermissionType[];
    onDelete: (rows: ITagModelObject) => Promise<void>;
}> = ({
    row,
    tenantId,
    tenantName,
    permissions,
    onDelete,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { disabledToolTipMessage } = useTagsDisabled();
    const { logEvent } = useTelemetryHelper();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const organizationName = useSelector(accountLogicalName);
    const EnablePortalTagActionCenterNavigation = useFeatureFlagValue(Features.EnablePortalTagActionCenterNavigation.name);

    const orchestratorCount = row.entityReferencesCount?.reduce((acc, curr) => {
        if (OrchestratorObjectType.includes(curr.entityType)) {
            return acc + curr.count;
        }
        return acc;
    }, 0) ?? 0;
    const actionCount = row.entityReferencesCount?.reduce((acc, curr) => {
        if (!OrchestratorObjectType.includes(curr.entityType)) {
            return acc + curr.count;
        }
        return acc;
    }, 0) ?? 0;

    const nestedMenu = useMemo(() => {
        const options = [
            <Tooltip
                tabIndex={0}
                placement='right'
                key='listTaggedOrchestratorObjects'
                title={translate({
                    id: orchestratorCount === 0
                        ? 'CLIENT_NO_TAGGED_OBJECTS_IN_ORCHESTRATOR'
                        : 'CLIENT_LIST_TAGGED_OBJECTS_IN_ORCHESTRATOR',
                })}>
                <div className={classes.menuItem}>
                    <MenuItem
                        tabIndex={0}
                        component={Link}
                        underline='none'
                        disabled={orchestratorCount === 0}
                        href={redirectToOrchestrator(
                            organizationName,
                            tenantName,
                            {
                                tags: {
                                    data: row,
                                    expandable: false,
                                    id: row?.name?.toLowerCase() ?? '',
                                    text: row?.name,
                                },
                            })}
                        target='_blank'
                        onClick={() => {
                            logEvent(PortalTagManagementEvent.ListTaggedObjectsLabels);
                        }}
                        data-cy='ap-grid-row-list-tagged-orchestrator-objects'
                    >
                        {translate({ id: 'CLIENT_ORCHESTRATOR' })}
                    </MenuItem>
                </div>
            </Tooltip>,
        ];
        if (EnablePortalTagActionCenterNavigation) {
            options.push(
                <Tooltip
                    tabIndex={0}
                    placement='right'
                    key='listTaggedActionObjects'
                    title={translate({
                        id: actionCount === 0
                            ? 'CLIENT_NO_TAGGED_OBJECTS_IN_ACTION'
                            : 'CLIENT_LIST_TAGGED_OBJECTS_IN_ACTION',
                    })}>
                    <div className={classes.menuItem}>
                        <MenuItem
                            tabIndex={0}
                            component={Link}
                            underline='none'
                            disabled={actionCount === 0}
                            href={`${window.location.origin}/${organizationName}/${tenantName}/actions_`}
                            target='_blank'
                            data-cy='ap-grid-row-list-tagged-action-objects'
                        >
                            {translate({ id: 'CLIENT_ECOMMERCE_ACTION_CENTER' })}
                        </MenuItem>
                    </div>
                </Tooltip>
            );
        }
        return options;
    }, [
        EnablePortalTagActionCenterNavigation,
        actionCount,
        classes.menuItem,
        logEvent,
        orchestratorCount,
        organizationName,
        row,
        tenantName,
        translate,
    ]);

    return (<UiMenu
        trigger={<Tooltip
            title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
            placement='right'>
            <IconButton
                aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                data-cy="ap-data-grid-show-more-actions-button"
            >
                <MoreVertIcon className={classes.icon} />
            </IconButton>
        </Tooltip>}
        menu={[
            <UiNestedMenuItem
                leftAnchorMenus
                key='listTaggedObjects'
                label={translate({ id: 'CLIENT_LIST_TAGGED_OBJECTS_SIMPLE' })}
                righticon={<ArrowRight />}
                data-cy='ap-grid-row-list-tagged-objects'
                menu={nestedMenu} />,
            <Tooltip
                tabIndex={0}
                placement='right'
                key="editLabel-tooltip"
                title={!hasPermission(permissions, [ PermissionType.View, PermissionType.Edit ])
                    ? disabledToolTipMessage(permissions, [ PermissionType.View, PermissionType.Edit ])
                    : undefined}
            >
                <div className={classes.menuItem}>
                    <MenuItem
                        key='editLabel'
                        disabled={!hasPermission(permissions, [ PermissionType.View, PermissionType.Edit ])}
                        onClick={() => navigate(getRoute(process.buildConfigs.showForMSI
                            ? `${RouteNames.TenantTagsLabelsMsi}/edit/${row?.key}`
                            : `${RouteNames.TenantTagsLabels}/edit/${row?.key}`.replace(':tenantId', tenantId)))}
                        data-cy='ap-grid-row-edit'
                    >
                        {translate({ id: 'CLIENT_EDIT' })}
                    </MenuItem>
                </div>
            </Tooltip>,
            <Tooltip
                tabIndex={0}
                placement='right'
                key="editLabel-tooltip"
                title={!hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ])
                    ? disabledToolTipMessage(permissions, [ PermissionType.View, PermissionType.Delete ])
                    : undefined}
            >
                <div className={classes.menuItem}>
                    <MenuItem
                        className={classes.deleteButton}
                        key='deleteLabel'
                        disabled={!hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ])}
                        onClick={() => onDelete(row)}
                        data-cy='ap-grid-row-delete'
                    >
                        {translate({ id: 'CLIENT_DELETE' })}
                    </MenuItem>
                </div>
            </Tooltip>,
        ]} />);
};
export default TenantTagsLabelsMoreActions;
