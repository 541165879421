import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UserRole } from '../../common/constants/Constant';
import type { UserRoleType } from '../../store/selectors';
import {
    accountRoleType,
    accountType,
} from '../../store/selectors';

const RoutesRequireAuthorizationList = [ '/auditLog', '/settings', '/roboticsops_/' ];

export default function useAuthorization() {
    const location = useLocation();
    const role = useSelector(accountRoleType);
    const accountPlan = useSelector(accountType);

    const isRouteAccessible = useCallback((route: string, role: UserRoleType) => {
        if (role === UserRole.DEFAULT_USER) {
            return !RoutesRequireAuthorizationList.some((r) => route.endsWith(r));
        }

        return [ UserRole.ACCOUNT_ADMIN, UserRole.TENANT_ADMIN, UserRole.ACCOUNT_OWNER ].indexOf(role) > -1;
    }, []);

    const isAuthorizedOnCurrentRoute = useCallback(() => {
        if (!role) {
            return false;
        }

        return isRouteAccessible(location.pathname, role);
    }, [ location.pathname, role, isRouteAccessible ]);

    const isFreeSkuAuthorization = useCallback(() => accountPlan === 'FREEKIT', [ accountPlan ]);

    return {
        isAuthorizedOnCurrentRoute,
        isRouteAccessible,
        isFreeSkuAuthorization,
    };
}
