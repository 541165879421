import {
    type PermissionType,
    TagPermissions,
} from '@experiences/interfaces';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { hasPermission } from '../addedit/properties/TenantTagsUtil';

export const useTagsDisabled = () => {
    const { formatMessage: translate } = useIntl();

    const disabledToolTipMessage = useCallback((permissions: PermissionType[] | undefined, type: PermissionType[]) => {
        if (!permissions) {
            return '';
        }
        const missingPermissions = type.filter((required) => !permissions.includes(required));
        if (missingPermissions.length === 0) {
            return '';
        }
        const transformedArray = missingPermissions.map(missing => TagPermissions[missing]);
        return translate({ id: 'CLIENT_TENANT_TAGS_PERMISSIONS_RESTRICTION' }, { permissions: transformedArray.join(', ') });
    }
    , [ translate ]);

    const checkDisabled = (current: PermissionType[] | undefined, required: PermissionType[]) =>
        !hasPermission(current, required) && disabledToolTipMessage(current, required);

    return {
        disabledToolTipMessage,
        checkDisabled,
    };
};
