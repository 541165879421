import React from 'react';

import CannotUpgradeOrganizationDialog from '../CannotUpgradeOrganizationDialog';
import OrganizationMismatchDialog from './OrganizationMismatchDialog';
import useOrganizationSwitcherDialogViewModel from './OrganizationSwitcherDialogViewModel';

const OrganizationSwitcherDialogComponent: React.FC = () => {
    const {
        isOrganizationMismatch, isDirectBuyUpgradeError, isAdmin,
    } = useOrganizationSwitcherDialogViewModel();

    return isOrganizationMismatch ? (
        <OrganizationMismatchDialog />
    ) : isDirectBuyUpgradeError ? (
        <CannotUpgradeOrganizationDialog isAdmin={isAdmin} />
    ) : null;
};

export default OrganizationSwitcherDialogComponent;
