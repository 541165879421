import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useShowDialog } from '@experiences/util';
import {
    ApDropdown,
    ApDropdownItem,
} from '@uipath/portal-shell-react';
import { produce } from 'immer';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import urljoin from 'url-join';

import {
    Saml2BindingType,
    Usage,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import type { IFieldAttribute } from '../../../common/interfaces/cis/attribute';
import { AttributeType } from '../../../common/interfaces/cis/attribute';
import type {
    ISamlFormData,
    ISAMLProvisioningSetting,
} from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    BulkAuthenticationSettingKey,
    getTemplateData,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import validateCertificate from '../helpers/ValidateSAMLCert';
import { useSAMLFormContext } from './SAMLFormContextProvider';

const useSAMLConfigureIDPViewModel = () => {
    const { formatMessage: translate } = useIntl();

    const createDialog = useShowDialog();

    const EnableSAMLUXRefactor = useFeatureFlagValue(Features.EnableSAMLUXRefactor.name);

    const partitionGlobalId = useSelector(accountGlobalId);

    const createdOrgEntityId = urljoin(
        window.location.origin,
        partitionGlobalId,
        process.buildConfigs.identityBaseRoute,
    );

    const [ authEntityId, setAuthEntityId ] = useState<string>(createdOrgEntityId);
    const {
        metadataStatus, setMetadataStatus,
    } = useSAMLFormContext();

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const authenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML];

    const methods = useFormContext<ISamlFormData>();

    const {
        watch, setValue, reset, setError, formState: { errors },
    } = methods;

    const currentEntityId: string | undefined = watch('ServiceProviderEntityId');

    useEffect(() => {
        const authSettings = authenticationSetting?.externalIdentityProviderDto?.settings;
        const ServiceProviderEntityId: string | undefined = authSettings
            ? JSON.parse(authSettings).ServiceProviderEntityId
            : undefined;
        const fetchedValue = ServiceProviderEntityId ?? createdOrgEntityId;

        setValue('ServiceProviderEntityId', fetchedValue);
        setAuthEntityId(fetchedValue);
    }, [ authenticationSetting, createdOrgEntityId, partitionGlobalId, setValue ]);

    const entityIds = useMemo(() => ({
        [createdOrgEntityId]: translate({ id: 'CLIENT_SAML_ORG_ENTITY_ID' }),
        [urljoin(
            window.location.origin,
            process.buildConfigs.identityBaseRoute
        )]: translate({ id: 'CLIENT_SAML_GLOBAL_ENTITY_ID' }),
    }), [ createdOrgEntityId, translate ]);

    const handleChangeAuthId = useCallback(async () => {
        let tempAuthId = authEntityId;

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_CHANGE_ENTITY_ID_FORMAT' }),
            width: '500px',
            body: (
                <ApDropdown
                    label={translate({ id: 'CLIENT_ENTITY_ID_FORMAT' })}
                    selectedValue={tempAuthId}
                    onSelectedValueChanged={e => tempAuthId = e.detail as string}
                    helperText={translate({ id: 'CLIENT_ENTITY_ID_DESCRIPTION' })}>
                    {Object.entries(entityIds).map(([ key, value ]) => (
                        <ApDropdownItem
                            key={key}
                            value={key}
                            label={value}
                        />))}
                </ApDropdown>
            ),
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
        });

        if (proceed) {
            setAuthEntityId(tempAuthId);
            setValue('ServiceProviderEntityId', tempAuthId);
        }
    }, [ authEntityId, createDialog, entityIds, setValue, translate ]);

    const metadataUrls = {
        metadataUrl: urljoin(
            window.location.origin,
            process.buildConfigs.showMetadataLink ? partitionGlobalId : '',
            process.buildConfigs.identityBaseRoute,
            'Saml2',
        ),
        entityId: authEntityId ?? '',
        assertionConsumerUrl: urljoin(
            window.location.origin,
            process.buildConfigs.showMetadataLink ? partitionGlobalId : '',
            process.buildConfigs.identityBaseRoute,
            '/Saml2/Acs',
            !process.buildConfigs.showMetadataLink ? `?PartitionId=${partitionGlobalId}` : '',
        ),
    };

    const fetchData = useCallback(async () => {
        setMetadataStatus({
            loading: true,
            success: false,
            error: '',
        });
        try {
            const fetchedMetadata = await getTemplateData({
                type: 'Saml2',
                displayName: 'Saml2 Provider',
                partitionGlobalId,
                settings: JSON.stringify({
                    ServiceProviderEntityId: metadataUrls.entityId,
                    IdentityProviderMetadataUrl: watch('IdentityProviderMetadataUrl'),
                }),
            });
            const settings: ISaml2ProviderSettings = JSON.parse(fetchedMetadata?.externalIdentityProviderDto?.settings ?? '');

            if (!settings) {
                throw new Error();
            }

            const baseFormSettings: ISamlFormData = {
                ServiceProviderEntityId: currentEntityId,
                SingleSignOnServiceUrl: settings.SingleSignOnServiceUrl ?? '',
                IdentityProviderEntityId: settings.IdentityProviderEntityId ?? '',
                SigningCertificateLocation: { CertificateText: settings.SigningCertificateLocation?.CertificateText ?? '' },
                AllowUnsolicitedAuthnResponse: settings.AllowUnsolicitedAuthnResponse ?? false,
                ExternalAuthUserMappingStrategy: settings.ExternalAuthUserMappingStrategy ?? 0,
                Saml2BindingType: settings.Saml2BindingType === Saml2BindingType.Artifact ? Saml2BindingType.HttpRedirect : settings.Saml2BindingType,
                ServiceCertificateUsage: settings.ServiceCertificateUsage ?? Usage.SigningAndEncryption,
                IdentityProviderMetadataUrl: settings.IdentityProviderMetadataUrl ?? '',
                ProvisioningSetting: {} as ISAMLProvisioningSetting,
                AdvancedSettings: settings.AdvancedSettings,
                attributes: [],
            };

            const formSettings = produce(baseFormSettings, draftState => {
                if (settings.ProvisioningSetting) {
                    draftState.ProvisioningSetting.AllowedDomains = settings.ProvisioningSetting.AllowedDomains?.join(', ')
                        ?? watch('ProvisioningSetting.AllowedDomains') ?? '';
                    draftState.ProvisioningSetting.AccountLinkConfirmation = settings.ProvisioningSetting.AccountLinkConfirmation
                        ?? watch('ProvisioningSetting.AccountLinkConfirmation') ?? false;

                    if (EnableSAMLUXRefactor) {
                        const attributeMapper = watch('attributes');
                        const attributesToArray = Object.entries(settings.ProvisioningSetting?.AttributeMapper ?? {})
                            .filter(([ key, value ]) => !!value && key !== 'ExtensionUserAttributeMappings')
                            .map(([ key, value ]) => ({
                                attributeType: key as AttributeType,
                                value: key === AttributeType.EMAIL && !value
                                    ? 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
                                    : value,
                                disableRemove: key === AttributeType.DISPLAYNAME
                                        || key === AttributeType.EMAIL
                                        || key === AttributeType.IDENTIFIER,
                            } satisfies IFieldAttribute));
                        draftState.attributes = attributesToArray.length
                            ? attributesToArray
                            : attributeMapper;
                    } else {
                        const attributeMapper = watch('ProvisioningSetting.AttributeMapper');
                        draftState.ProvisioningSetting.AttributeMapper =
                                settings.ProvisioningSetting?.AttributeMapper
                                    && Object.values(settings.ProvisioningSetting?.AttributeMapper).some(attribute => !!attribute)
                                    ? settings.ProvisioningSetting?.AttributeMapper
                                    : (attributeMapper
                                        && Object.values(attributeMapper).some(attribute => !!attribute)
                                        ? attributeMapper
                                        : {});
                    }
                }
            });

            reset(formSettings, { keepDirty: true });
            if (!(await validateCertificate(formSettings.SigningCertificateLocation.CertificateText))) {
                setError('SigningCertificateLocation.CertificateText', { type: 'invalid' });
            }

            setMetadataStatus({
                loading: false,
                success: true,
                error: '',
            });
        } catch (error) {
            setMetadataStatus({
                loading: false,
                success: false,
                error: translate({ id: 'CLIENT_FAILED_TO_FETCH_DATA' }),
            });
            setError('IdentityProviderMetadataUrl', { type: 'invalid' });
        }
    }, [
        setMetadataStatus,
        partitionGlobalId,
        metadataUrls.entityId,
        watch,
        currentEntityId,
        reset,
        EnableSAMLUXRefactor,
        setError,
        translate,
    ]);

    const clearMetadata = useCallback(() => {
        setMetadataStatus({
            loading: false,
            success: false,
            error: '',
        });

        setValue('IdentityProviderMetadataUrl', '');
        setValue('SingleSignOnServiceUrl', '');
        setValue('IdentityProviderEntityId', '');
        setValue('SigningCertificateLocation.CertificateText', '');
    }, [ setMetadataStatus, setValue ]);

    const getFetchHelperText = useMemo(() => {
        const defaultText = translate({ id: 'CLIENT_SAML_METADATA_URL_HELPER_TEXT' });
        if (metadataStatus.error) {
            return metadataStatus.error;
        } else if (errors.IdentityProviderMetadataUrl) {
            return errors.IdentityProviderMetadataUrl.message;
        }
        return defaultText;

    }, [ errors.IdentityProviderMetadataUrl, metadataStatus.error, translate ]);

    return {
        loading: !bulkAuthenticationSetting,
        partitionGlobalId,
        methods,
        metadataStatus,
        entityIds,
        currentEntityId,
        metadataUrls,
        fetchData,
        clearMetadata,
        getFetchHelperText,
        handleChangeAuthId,
    };
};

export default useSAMLConfigureIDPViewModel;
