import { NotificationSettingsEvent } from '@experiences/telemetry';
import {
    UiImageSelector,
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { flushSync } from 'react-dom';
import type { ControllerRenderProps } from 'react-hook-form';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    emailConfigurationUrl,
    getEmailConfiguration,
    resetEmailConfiguration,
    updateEmailConfiguration,
} from '../../../../services/notification-preferences';
import { accountGlobalId } from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import UiForm from '../../../common/UiForm';
import { useTenantsContext } from '../../../tenants/TenantsContextProvider';
import type { IEmailConfiguration } from '../../interfaces/notificationSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: '32px' },
        inputSender: { marginTop: '32px' },
        infoIcon: {
            marginLeft: Tokens.Padding.PadM,
            height: Tokens.FontFamily.FontXsLineHeight,
            width: Tokens.Padding.PadXxxl,
            marginTop: '3px',
        },
        inputLabel: {
            fontWeight: Tokens.FontFamily.FontBrandH4Weight,
            fontSize: Tokens.FontFamily.FontMSize,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: Tokens.Padding.PadXxl },
        rowGroup: {
            display: 'flex',
            flexDirection: 'row',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '32px',
        },
        cancelButton: { marginRight: Tokens.Padding.PadXl },
        container: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
            paddingLeft: '20px',
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '50%',
            flex: 1,
            marginRight: '24px',
        },
        digestRadio: { marginRight: '25px' },
    }),
);

const maxImageSize = 524288;
const EmailSettingsFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountId = useSelector(accountGlobalId);
    const createNotification = useUiSnackBar();
    const imageByteSeparator = ';base64,';
    const [ isSaveLoading, setIsSaveLoading ] = React.useState<boolean>(false);
    const [ isResetLoading, setIsResetLoading ] = React.useState<boolean>(false);
    const [ isEmailEnabled, setIsEmailEnabled ] = React.useState<boolean>(false);
    const {
        selectedTenant: {
            id: tenantId, name: tenantName,
        },
    } = useTenantsContext();
    const { logEvent } = useTelemetryHelper();
    const {
        data: configurationData, mutate,
    } = useSWR(
        tenantId ? {
            requestUri: emailConfigurationUrl,
            selectedTenantId: tenantId,
            selectedAccountId: accountId,
        } : null,
        getEmailConfiguration,
    );

    const {
        control,
        handleSubmit,
        reset,
        formState: {
            errors, isDirty,
        },
    } =
    useForm<IEmailConfiguration>({
        mode: 'onChange',
        defaultValues: {
            senderId: '',
            senderName: '',
            digestSchedule: 1,
            senderLogo: '',
            isAccountLevel: false,
            emailFooter: '',
            senderLogoPrefix: '',
            isEnabled: false,
        },
    });

    const onSubmit = useCallback(async (data: IEmailConfiguration) => {
        try {
            flushSync(() => {
                setIsSaveLoading(true);
            });
            if (data.senderLogo) {
                const logoArray: string[] = data.senderLogo.split(imageByteSeparator);
                data.senderLogoPrefix = logoArray[0] + imageByteSeparator;
                data.senderLogo = logoArray[1];
            }
            data.isEnabled = isEmailEnabled;
            logEvent(NotificationSettingsEvent.SetEmailConfig);
            await updateEmailConfiguration(emailConfigurationUrl, data, tenantId);
            createNotification(
                translate({ id: 'CLIENT_EMAIL_CONFIG_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS,
            );
            setIsSaveLoading(false);
            mutate();
        } catch (error) {
            setIsSaveLoading(false);
            const errorMessage = (error as Error)?.message;
            createNotification(
                errorMessage,
                notificationType.ERROR,
            );
        }

    }, [ createNotification, isEmailEnabled, logEvent, mutate, tenantId, translate ]);

    const resetForm = useCallback(async () => {
        try {
            flushSync(() => {
                setIsResetLoading(true);
            });
            const configData: IEmailConfiguration = await resetEmailConfiguration(emailConfigurationUrl, tenantId);
            reset({
                senderId: configData.senderId,
                senderName: configData.senderName,
                digestSchedule: configData.digestSchedule,
                senderLogo: configData.senderLogoPrefix + configData.senderLogo,
                isAccountLevel: configData.isAccountLevel ?? true,
                emailFooter: configData.emailFooter,
                senderLogoPrefix: configData.senderLogoPrefix,
                isEnabled: configData.isEnabled,
            });
            setIsEmailEnabled(configData.isEnabled);
            createNotification(
                translate({ id: 'CLIENT_EMAIL_CONFIG_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS,
            );
            setIsResetLoading(false);
        } catch (error) {
            setIsResetLoading(false);
            const errorMessage = (error as Error)?.message;
            createNotification(
                errorMessage,
                notificationType.ERROR,
            );
        }
    }, [ createNotification, reset, tenantId, translate ]);

    const handleChange = useCallback(async (checked: boolean, field: ControllerRenderProps<IEmailConfiguration, 'isEnabled'>) => {
        setIsEmailEnabled(checked);
        field.onChange(checked);
    }, [ ]);

    useEffect(() => {
        if (configurationData) {
            setIsEmailEnabled(configurationData.isEnabled);
            reset({
                senderId: configurationData.senderId,
                senderName: configurationData.senderName,
                digestSchedule: configurationData.digestSchedule,
                senderLogo: configurationData.senderLogoPrefix + configurationData.senderLogo,
                isAccountLevel: configurationData.isAccountLevel ?? true,
                emailFooter: configurationData.emailFooter,
                senderLogoPrefix: configurationData.senderLogoPrefix,
                isEnabled: configurationData.isEnabled,
            });
        }
    }, [ reset, configurationData ]);
    return (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            disableActionsGutters
            id="emailConfigurationForm"
            actions={
                <div className={classes.actions}>
                    <UiProgressButton
                        loading={isResetLoading}
                        className={classes.cancelButton}
                        onClick={resetForm}
                        color="primary"
                        data-cy="add-edit-reset-button"
                    >
                        {translate({ id: 'CLIENT_NOTIFICATIONS_FILTER_RESET_DEFAULT' })}
                    </UiProgressButton>
                    <UiProgressButton
                        loading={isSaveLoading}
                        disabled={!isDirty}
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        data-cy="add-edit-submit-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
        >
            <div className={classes.container}>
                <div className={classes.leftContainer}>
                    <div className={classes.input}>
                        <UiText
                            className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_EMAIL_ENABLE_NOTIFICATION' })}
                        </UiText>
                    </div>
                    <div>
                        <Controller
                            control={control}
                            name="isEnabled"
                            render={({ field }) => (
                                <Switch
                                    data-cy="email-config-switch"
                                    checked={isEmailEnabled}
                                    onChange={(e, checked) => handleChange(checked, field)}
                                    color="primary"
                                />
                            )}
                        />
                    </div>
                    {isEmailEnabled && <>
                        <div className={classes.input}>
                            <Controller
                                control={control}
                                name="senderName"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERNAME' })}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.senderName}
                                        data-cy="sender-name-text-field"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                                {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERID' })}
                                <Tooltip
                                    arrow
                                    title={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERID_MESSAGE' })}>
                                    <InfoOutlined
                                        className={classes.infoIcon}
                                        tabIndex={0}
                                        aria-label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERID_MESSAGE' })}
                                    />
                                </Tooltip>
                            </UiText>
                            <Controller
                                control={control}
                                name="senderId"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        error={!!errors.senderId}
                                        fullWidth
                                        data-cy="senderid-text-field"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.inputSender}>
                            <UiText
                                className={clsx(classes.inputLabel, classes.inputMargin)}
                                id="senderLogoLabel">
                                {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERLOGO' })}
                            </UiText>
                            <Controller
                                control={control}
                                name="senderLogo"
                                render={({ field }) => (
                                    <UiImageSelector
                                        handleFileChange={field.onChange}
                                        allowedTypes={[ 'image/png' ]}
                                        preview
                                        maxSize={maxImageSize}
                                        defaultImage={field.value}
                                        defaultImageName={tenantName + '_logo.png'}
                                        data-cy="emailconfig-senderlogo"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                                {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTFREQ' })}
                                <Tooltip
                                    arrow
                                    title={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTFREQ_TOOLTIP' })}>
                                    <InfoOutlined
                                        className={classes.infoIcon}
                                        tabIndex={0}
                                        aria-label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTFREQ_TOOLTIP' })}
                                    />
                                </Tooltip>
                            </UiText>
                            <Controller
                                control={control}
                                name="digestSchedule"
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        className={classes.rowGroup}>
                                        <FormControlLabel
                                            className={classes.digestRadio}
                                            value="0"
                                            control={<Radio color="primary" />}
                                            label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGEST10MIN' })}
                                        />
                                        <FormControlLabel
                                            className={classes.digestRadio}
                                            value="1"
                                            control={<Radio color="primary" />}
                                            label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGEST30MIN' })}
                                        />
                                        <FormControlLabel
                                            className={classes.digestRadio}
                                            value="3"
                                            control={<Radio color="primary" />}
                                            label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTWICE' })}
                                        />
                                        <FormControlLabel
                                            className={classes.digestRadio}
                                            value="2"
                                            control={<Radio color="primary" />}
                                            label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGEST1DAY' })}
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </div>
                    </>}
                    <div className={classes.input}>
                        <Controller
                            control={control}
                            name="isAccountLevel"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            data-cy="accountlevelbox"
                                            checked={field.value}
                                            onChange={e => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label={
                                        <div className={classes.rowGroup}>
                                            <div>
                                                <span>
                                                    {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_ACCOUNTLEVEL' })}
                                                </span>
                                            </div>
                                            <div>
                                                <Tooltip
                                                    arrow
                                                    title={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_ACCOUNTLEVEL_TOOLTIP' })}>
                                                    <InfoOutlined
                                                        className={classes.infoIcon}
                                                        tabIndex={0}
                                                        aria-label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_ACCOUNTLEVEL_TOOLTIP' })}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }
                                    data-cy="emailconfig-accountlevel-check"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </UiForm>
    );
};
export default EmailSettingsFormComponent;
