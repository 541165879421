import type { ITenant } from '@experiences/interfaces';
import { UiAlertBanner } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import TenantStatusDialogBody from '../../tenants/subcomponents/TenantStatusDialogBody';
import { TenantStatusConstants } from '../../tenants/TenantConstants';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';

const useStyles = makeStyles(() =>
    createStyles({
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignContent: 'center',
            alignItems: 'center',
        },
        link: { marginLeft: '8px' },
    }),
);

export const UiTenantStatusBanner: React.FC<{
    canEnableTenant: boolean;
    status: string | undefined;
    tenantName: string | undefined;
    dataCyMaxTenants?: string;
    dataCyDisabledTenant?: string;
}> = ({
    canEnableTenant,
    status,
    tenantName,
    dataCyMaxTenants = 'tenant-settings-max-tenants-banner',
    dataCyDisabledTenant = 'tenant-settings-disabled-tenant-banner',
}) => {

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const createDialog = useShowDialog();
    const tenantOperations = useTenantOperations();

    const { tenantId } = useParams() as { tenantId: string };
    const { getTenantData } = useTenantsContext();

    const enableTenant = useCallback(
        async (data?: ITenant) => {
            if (!data) {
                return;
            }

            await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' }),
                icon: 'warning',
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    status: TenantStatusConstants.ENABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                },
            });
        },
        [ createDialog, translate, tenantOperations.tenantStatusModify ],
    );

    const bannerText = useMemo(() => {
        switch (status?.toLocaleLowerCase()) {
            case TenantStatusConstants.UPDATING.toLowerCase(): return translate({ id: 'CLIENT_TENANT_STATUS_UPDATING' });
            case TenantStatusConstants.DISABLED.toLowerCase():
                return translate({ id: 'CLIENT_TENANT_STATUS_DISABLED' }, { tenant: tenantName });
            default: return '';
        }
    }, [ status, tenantName, translate ]);

    return (
        <>
            {!canEnableTenant && status?.toUpperCase() === TenantStatusConstants.DISABLED && <UiAlertBanner
                dataCy={dataCyMaxTenants}
                type="info"
                closeable={false}>
                {translate({ id: 'CLIENT_MAX_ENABLED_SERVICE_INSTANCE_COUNT_REACHED_REVAMP' })}
            </UiAlertBanner>}
            {canEnableTenant && bannerText &&
                <UiAlertBanner
                    dataCy={dataCyDisabledTenant}
                    type="warning"
                    closeable={false}
                    enterpriseTrialAlertBar>
                    <div className={classes.banner}>
                        {bannerText}
                        {status?.toLocaleLowerCase() === TenantStatusConstants.DISABLED.toLowerCase() &&
                        <Button
                            size='small'
                            onClick={() => enableTenant(getTenantData(tenantId))}>
                            {translate({ id: 'CLIENT_ENABLE' })}
                        </Button>}
                    </div>
                </UiAlertBanner>}
        </>
    );
};
