interface IServiceDetail {
    id: string;
    name: string;
    path?: string;
    manageAccessPath?: string;
    orgService?: boolean;
    customIcon?: string;
}

const services: IServiceDetail[] = [
    {
        id: 'academy',
        name: 'Academy',
        orgService: true,
        path: '/:accountName/academy_',
    },
    {
        id: 'actions',
        name: 'Actions',
    },
    {
        id: 'admin',
        name: 'Admin',
        path: '/:accountName/portal_/admin',
        manageAccessPath: '/:accountName/portal_/admin/tenant/:tenantId/manage-access',
    },
    {
        id: 'aifabric',
        name: 'AI Center',
        path: '/:accountName/:tenantName/aifabric_',
    },
    {
        id: 'aiservices',
        name: 'AI Services',
        customIcon: 'aifabric',
        path: '/:accountName/:tenantName/aiservices_',
    },
    {
        id: 'apps',
        name: 'Apps',
        orgService: true,
        path: '/:accountName/apps_',
        manageAccessPath: '/:accountName/apps_/default/edit/manage-access',
    },
    {
        id: 'automationhub',
        name: 'Automation Hub',
        path: '/:accountName/:tenantName/automationhub_',
        manageAccessPath: '/:accountName/:tenantName/automationhub_/admin/manage-users',
    },
    {
        id: 'automationstore',
        name: 'Automation Store',
        path: '/:accountName/:tenantName/automationstore_',
    },
    {
        id: 'chatbots',
        name: 'Chatbots',
    },
    {
        id: 'connections',
        name: 'Integration Service',
        customIcon: 'integrations',
        path: '/:accountName/:tenantName/connections_',
    },
    {
        id: 'dataservice',
        name: 'Data Service',
        path: '/:accountName/:tenantName/dataservice_',
        manageAccessPath: '/:accountName/:tenantName/dataservice_/users',
    },
    {
        id: 'docs',
        name: 'Documentations',
        orgService: true,
        path: '/:accountName/docs_',
    },
    {
        id: 'du',
        name: 'Document Understanding',
        path: '/:accountName/:tenantName/du_',
        manageAccessPath: '/:accountName/:tenantName/du_/manage-access',
    },
    {
        id: 'insights',
        name: 'Insights',
        path: '/:accountName/:tenantName/insights_',
        manageAccessPath: '/:accountName/:tenantName/insights_/admin/manage-access',
    },
    {
        id: 'marketplace',
        name: 'Marketplace',
        orgService: true,
        path: '/:accountName/marketplace_',
    },
    {
        id: 'orchestrator',
        name: 'Orchestrator',
        path: '/:accountName/:tenantName/orchestrator_',
        manageAccessPath: '/:accountName/:tenantName/orchestrator_/manage-access/assign-roles',
    },
    {
        id: 'portal',
        name: 'Cloud Portal',
        path: '/:accountName/:tenantName/portal_',
    },
    {
        id: 'processes',
        name: 'Process Launcher',
        path: '/:accountName/:tenantName/processes_',
        manageAccessPath: '/:accountName/:tenantName/processes_/admin/assign-roles',
    },
    {
        id: 'processmining',
        name: 'Process Mining',
        path: '/:accountName/:tenantName/processmining_',
        manageAccessPath: '/:accountName/:tenantName/processmining_/admin/assign-roles',
    },
    {
        id: 'reinfer',
        name: 'Communications Mining',
        path: '/:accountName/:tenantName/reinfer_',
        manageAccessPath: '/:accountName/:tenantName/reinfer_/admin?admin_tab=ManageAccess',
    },
    {
        id: 'roboticsops',
        name: 'Automation Ops',
        orgService: true,
        path: '/:accountName/roboticsops_',
        manageAccessPath: '/:accountName/roboticsops_/manage-access',
    },
    {
        id: 'taskmining',
        name: 'Task Mining',
        path: '/:accountName/:tenantName/taskmining_',
        manageAccessPath: '/:accountName/:tenantName/taskmining_/app/users',
    },
    {
        id: 'testmanager',
        name: 'Test Manager',
        path: '/:accountName/:tenantName/testmanager_',
        manageAccessPath: '/:accountName/:tenantName/testmanager_/general-settings',
    },
    {
        id: 'studio',
        name: 'Studio Web',
        orgService: true,
        path: '/:accountName/studio_',
        manageAccessPath: '/:accountName/studio_/manage-access',
    },
];

export function getOrganizationServiceIds() {
    return services.filter(service => service.orgService).map(service => service.id);
}

export function getFriendlyName(id: string, useIdDefault = true) {
    const friendlyName = services.find(service => service.id === id)?.name;
    if (friendlyName) {
        return friendlyName;
    }

    return useIdDefault ? id : 'Service';
}

export function getIconName(id: string) {
    const customIcon = services.find(service => service.id === id)?.customIcon;
    if (customIcon) {
        return customIcon;
    }
    return id;
}

export function getServiceDetails(id: string) {
    return services.find(service => service.id === id);
}
