import { ensureZSuffix } from '@experiences/util';

import type {
    IRobot,
    IRobotResponse,
} from '../../common/interfaces/cis/robot';

export function mapRobotCollection(originalResponse: IRobotResponse): IRobotResponse {
    const results: IRobot[] = originalResponse.results.map(mapRobot);
    return {
        totalCount: originalResponse.totalCount,
        results,
    };
}

export function mapRobot(robot: IRobot): IRobot {
    return {
        ...robot,
        creationTime: ensureZSuffix(robot.creationTime),
        lastModificationTime: ensureZSuffix(robot.lastModificationTime),
        lastLoginTime: ensureZSuffix(robot.lastLoginTime),
    };
}
