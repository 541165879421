import type { OrganizationSettingTabType } from '@experiences/interfaces';
import { OrganizationSettingTab } from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../../common/constants/RouteNames';
import { isHostModeSelector } from '../../../store/selectors';
import { AdvancedSettingsTabComponent } from './AdvancedSettingsTabContent';
import EmailSettingsComponent from './EmailSettingsComponent';
import GeneralSettingsTabContent from './GeneralSettingsTabContent';
import LoginCustomizationComponent from './LoginCustomizationComponent';

const TabPanel: React.FC<{ value: OrganizationSettingTabType }> = ({ value }) => {
    switch (value) {
        case OrganizationSettingTab.GENERAL:
            return <GeneralSettingsTabContent />;
        case OrganizationSettingTab.ADVANCED:
            return <AdvancedSettingsTabComponent />;
        case OrganizationSettingTab.EMAIL:
            return <EmailSettingsComponent />;
        case OrganizationSettingTab.LOGIN_CUSTOMIZATION:
            return <LoginCustomizationComponent />;
    }

    return null;
};

const OrganizationSettingsTabs: React.FC<{ type: OrganizationSettingTabType }> = ({ type }) => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const [ tabValue, setTabValue ] = useState<OrganizationSettingTabType>(type ?? OrganizationSettingTab.GENERAL);

    const hostMode = useSelector(isHostModeSelector);

    const handleChange = useCallback((_: any, newValue: OrganizationSettingTabType) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={{
                    width: '100%',
                    alignItems: 'center',
                }}>
                {!hostMode && !process.buildConfigs.disableGeneralOrganizationSettings && (
                    <Tab
                        value={OrganizationSettingTab.GENERAL}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_GENERAL_TAB' })}
                        onClick={() => navigate(getRoute(RouteNames.OrganizationSettings))}
                        data-cy="organization-settings-general-tab"
                        className="default"
                    />
                )}
                {!hostMode && !process.buildConfigs.showForMSI && (
                    <Tab
                        value={OrganizationSettingTab.ADVANCED}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_ADVANCED_TAB' })}
                        onClick={() => navigate(getRoute(RouteNames.OrganizationSettingsAdvanced))}
                        data-cy="organization-settings-advanced-tab"
                        className="default"
                    />
                )}
                {process.buildConfigs.enableLoginCustomization && hostMode && (
                    <Tab
                        value={OrganizationSettingTab.LOGIN_CUSTOMIZATION}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_LOGIN_CUSTOMIZATION_TAB' })}
                        onClick={() => navigate(getRoute(RouteNames.LoginCustomization))}
                        data-cy="organization-settings-login-customization-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default OrganizationSettingsTabs;
