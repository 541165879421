import { CanaryTenantLinks } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CharacterLimitForNames } from '../../../common/constants/Constant';
import { DataResidencyCloudLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import UiColorPicker from '../../common/UiColorPicker';
import ChangeRegion from './ChangeRegion';
import useTenantGeneralFormViewModel from './TenantGeneralFormViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        colorPicker: { marginLeft: '8px' },
        tenant: {
            display: 'flex',
            margin: '16px 0 16px 0',
            flexDirection: 'row',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        tenantSettingsForm: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        smallText: { fontSize: '12px' },
        mediumText: { fontSize: '14px' },
        canaryRegionHelperText: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '8px',
        },
        redAsterisk: {
            color: theme.palette.semantic.colorErrorText,
            fontSize: '12px',
        },
    }),
}));

const regex = /^(?!.*_$)[a-zA-Z][A-Za-z0-9_]+$/;

const TenantGeneralForm: React.FC<{
    type?: 'add' | 'edit';
    tenant?: ITenant;
    disableRegion?: boolean;
    disableForm?: boolean;
    width?: string; }> = ({
    type, tenant, disableRegion, disableForm, width,
}) => {
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableScheduledTenantMigration = useFeatureFlagValue(Features.EnabledScheduledTenantMigration.name);

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { isUserEnterprise } = useCheckLicense();

    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'pt-BR', 'tr', 'zh-CN', 'zh-TW' ] });
    const getLocalizedLinkConstant = useLocalizedLinks();

    const {
        tenantsWithoutServices,
        validationErrorHandlers,
        isCanaryTenant,
        isCanaryChecked,
        canaryTenantExists,
        isRegionEnabled,
        DefaultRegions,
        translatedRegion,
        methods: {
            register,
            control,
            formState: { errors },
        },
        canaryTenantFormEnabled,
    } = useTenantGeneralFormViewModel(tenant, type);

    const [ validationErrors ] = validationErrorHandlers;

    const validateTenantName = useCallback((value: string) => {
        if (!(type === 'edit' && value && tenant?.name.toLowerCase() === value.toLowerCase())
        && (tenantsWithoutServices?.map(tenantIterator => tenantIterator.name.toLowerCase()).includes(value.toLowerCase()) ?? false)) {
            return translate({ id: 'CLIENT_TENANT_DUPLICATE_NAME' });
        }
        if (!value.match(regex)) {
            return translate({ id: 'CLIENT_TENANT_WARN_NAME' }, {
                0: CharacterLimitForNames.maxNameLength,
                1: 2,
            });
        }
        return true;
    }, [ tenant?.name, tenantsWithoutServices, translate, type ]);

    const regionToRender = useMemo(() => {
        if (!isRegionEnabled || DisableFeatureFedRamp) {
            return 'none';
        }
        if (EnableScheduledTenantMigration && isUserEnterprise && type === 'edit' && !isCanaryTenant) {
            return 'change-region';
        }
        if (DefaultRegions && DefaultRegions.length > 0) {
            return 'select-region';
        }
    }, [ DefaultRegions, DisableFeatureFedRamp, EnableScheduledTenantMigration, isCanaryTenant, isRegionEnabled, isUserEnterprise, type ]);

    if (isRegionEnabled && !DefaultRegions?.length && !DisableFeatureFedRamp) {
        return <CircularProgress />;
    }

    return (
        <div
            className={classes.tenantSettingsForm}
            data-cy="tenant-settings-component"
            style={{
                width,
                padding: type === 'add' ? '0 16px' : '',
            }}>
            <div className={classes.tenant}>
                <TextField
                    disabled={disableForm}
                    label={translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' })}
                    InputLabelProps={{ id: 'tenantNameLabel' }}
                    error={!!errors.name}
                    helperText={errors.name?.message as string}
                    fullWidth
                    variant="outlined"
                    InputProps={{ className: 'Tall' }}
                    inputProps={{
                        'aria-labelledby': 'tenantNameLabel',
                        ...register('name', {
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' }) },
                            ),
                            maxLength: {
                                value: CharacterLimitForNames.maxNameLength,
                                message: translate({ id: 'CLIENT_TENANT_WARN_NAME' }, {
                                    0: CharacterLimitForNames.maxNameLength,
                                    1: 2,
                                }),
                            },
                            minLength: {
                                value: 2,
                                message: translate({ id: 'CLIENT_TENANT_WARN_NAME' }, {
                                    0: CharacterLimitForNames.maxNameLength,
                                    1: 2,
                                }),
                            },
                            validate: (value: string) => validateTenantName(value),
                        }),
                    }}
                    data-cy="create-edit-tenant-name"
                    required
                />
                <div className={classes.colorPicker}>
                    <Controller
                        name="color"
                        control={control}
                        render={({ field }) =>
                            <UiColorPicker
                                value={field.value}
                                onChange={field.onChange}
                                disabled={disableForm}
                            />}
                    />
                </div>
            </div>
            {canaryTenantFormEnabled && <div style={{ marginBottom: '24px' }}>
                <Controller
                    name="customProperties.isCanaryTenant"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    disabled={isCanaryTenant || canaryTenantExists}
                                    defaultChecked={isCanaryChecked || isCanaryTenant}
                                    data-cy="canary-tenant-checkbox"
                                />
                            }
                            label={
                                <UiText className={classes.mediumText}>
                                    {translate({ id: 'CLIENT_USE_AS_CANARY' })}
                                </UiText>
                            }
                            style={{ marginTop: '8px' }}
                        />
                    )}
                />
                <UiText className={classes.smallText}>
                    {translate({
                        id: canaryTenantExists && type === 'add'
                            ? 'CLIENT_CANARY_TENANT_ONLY_ONE'
                            : 'CLIENT_CANARY_DESCRIPTION',
                    })}
                </UiText>
                <a
                    className={clsx(classes.a, classes.smallText)}
                    target="_blank"
                    rel="noreferrer"
                    href={getLocalizedLinkConstant(CanaryTenantLinks)}
                >
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </a>
            </div> }

            {regionToRender === 'change-region' && <ChangeRegion />}
            {regionToRender === 'select-region' &&
                <>
                    <UiSelect
                        dataCy="create-edit-tenant-region"
                        name="region"
                        inputLabel={translate({ id: 'CLIENT_TENANT_REGION' })}
                        error={!!errors.region}
                        options={translatedRegion}
                        disabled={disableRegion || disableForm || isCanaryChecked}
                        required
                        control={control}
                        helperText={
                            (errors.region?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                            ((validationErrors.unsupported.length > 0 || validationErrors.altRegion.length > 0) &&
                                translate(
                                    { id: 'CLIENT_TENANT_REGION_HELPER_TEXT' },
                                    {
                                        LEARNMORE: (
                                            <a
                                                className={classes.a}
                                                href={getLocalizedLink({ articleSlug: DataResidencyCloudLink })}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                {translate({ id: 'CLIENT_LEARN_MORE' })}
                                            </a>
                                        ),
                                    })
                            )
                        }
                    />
                    {isCanaryChecked &&
                        <div className={classes.canaryRegionHelperText}>
                            <UiText className={classes.redAsterisk}>
                                *
                            </UiText>
                            <UiText className={classes.smallText}>
                                {translate({ id: 'CLIENT_CANARY_REGION_RESTRICTION' })}
                            </UiText>
                        </div>}
                </>}
        </div>
    );
};

export default TenantGeneralForm;
