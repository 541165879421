import { GlobalStyles } from '@experiences/theme';
import { UiAlertBanner } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { hyperLink } from '../../../common/constants/Constant';
import { getTrialPerSkuLicensesInGrace } from '../../../services/licensing/TrialPerSku';
import {
    isAdminSelector,
    isUnlicensedSelector,
} from '../../../store/selectors';

const useStyles = makeStyles(theme => GlobalStyles(theme));

export const UiTrialPerSkuLicenseInGracePeriodBanners: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const {
        data, isValidating,
    } = useSWR<string[], Error>(
        isAdmin && !isUnlicensedMode ? 'trial-per-sku-licenses-in-grace' : null,
        getTrialPerSkuLicensesInGrace,
    );

    const trialPerSkuLicensesInGrace = useMemo(() => !isValidating ? data : [], [ data, isValidating ]) ;

    return trialPerSkuLicensesInGrace && trialPerSkuLicensesInGrace.length > 0 ? (
        <>
            { trialPerSkuLicensesInGrace.map((subscriptionCode, i) => (
                <div
                    key={i}
                    data-cy="ui-trial-per-sku-license-in-grace-period-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        {
                            translate(
                                { id: `CLIENT_TRIAL_PER_SKU_LICENSE_IN_GRACE_PERIOD_WARNING_BANNER_${subscriptionCode}` },
                                {
                                    PURCHASE_NOW: (
                                        <a
                                            className={classes.a}
                                            href={hyperLink.CONTACT}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {translate({ id: 'CLIENT_PURCHASE_NOW' })}
                                        </a>
                                    ),
                                },
                            )
                        }
                    </UiAlertBanner>
                </div>
            ))}
        </>
    ) : null;
};
export default UiTrialPerSkuLicenseInGracePeriodBanners;
