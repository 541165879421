import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { useFeatureFlagValue } from '@experiences/feature-flags';
import { RobotAccountsEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { Row } from 'react-table';

import { notificationType } from '../../common/constants/Constant';
import { AboutAccountsLink } from '../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../common/constants/RouteNames';
import useSimpleGroup from '../../common/hooks/SimpleGroup';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type {
    IRobot,
    IRobotResponse,
} from '../../common/interfaces/cis/robot';
import {
    deleteRobots,
    getRobots,
    robotUrl,
} from '../../services/identity/RobotAccountService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../store/selectors';
import type {
    IAction,
    IActionHeader,
} from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiPaginatedGrid } from '../common/UiPaginatedGrid';
import UpgradeForFeature from '../common/UpgradeForFeature';

const useStyles = makeStyles(theme =>
    createStyles({
        nameCell: { display: 'flex' },
        userRoleLabel: {
            fontSize: '11px',
            fontWeight: 'bold',
            color: theme.palette.semantic.colorForegroundHigh,
            marginLeft: '8px',
            backgroundColor: theme.palette.semantic.colorBackgroundHigh,
            height: '18px',
            marginTop: '1px',
        },
        servicesListCell: { maxWidth: '200px' },
        azureDescription: {
            marginTop: '8px',
            marginBottom: '8px',
        },
        addRobotAccount: { marginLeft: '5px' },
    }),
);

export const RobotNameCell = ({
    row, enableRobotDisplayName, classes,
}: {
    row: Row<IRobot>;
    enableRobotDisplayName: boolean;
    classes: Record<string, string>;
}) => {
    const robotName = enableRobotDisplayName ? row.original.displayName : row.original.name;
    return <div className={classes.nameCell}>
        <Tooltip
            arrow
            title={robotName}>
            <span>
                {robotName}
            </span>
        </Tooltip>
    </div>;
};

const RobotAccountPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const { includesLicense } = useCheckLicense();
    const enableRobotDisplayName = useFeatureFlagValue('EnableRobotDisplayName');

    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks();

    const isAdmin = useSelector(isAdminSelector);
    const currentAccountGlobalId = useSelector(accountGlobalId);

    const {
        loading: groupLoading, getGroupsForUser,
    } = useSimpleGroup(true);

    const [ refresh, setRefresh ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const setErrorMessage = useCentralErrorSetter();

    useEffect(() => {
        setLoading(groupLoading);
    }, [ groupLoading ]);

    useEffect(() => {
        if (location.state && (location.state as any)['refresh']) {
            setRefresh(true);
        }
    }, [ location ]);

    const deleteRobotsAsync = useCallback(
        async (robots: IRobot[]) => {
            try {
                if (robots.length > 0) {
                    const robotIds = robots.map(robot => robot.id);
                    await deleteRobots(currentAccountGlobalId, robotIds);

                    setRefresh(true);
                    createNotification(translate({ id: 'CLIENT_ROBOT_DELETED' }), notificationType.INPROGRESS);
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        },
        [ createNotification, translate, setErrorMessage, currentAccountGlobalId, getErrorMessage ],
    );

    const openDeleteDialog = useCallback(
        async (rows: Array<Row<IRobot>> | Row<IRobot>) => {
            const robots: IRobot[] = Array.isArray(rows) ? rows.map(row => row.original) : [ rows.original ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_ROBOT' }),
                body: (
                    <div>
                        <UiText>
                            {translate({ id: 'CLIENT_DELETE_ROBOT_ACCOUNT_WARNING' })}
                        </UiText>
                        <br />
                        <UiText>
                            {translate({ id: 'CLIENT_DELETE_ROBOT_ACCOUNT_CONFIRM_MESSAGE' })}
                        </UiText>
                    </div>
                ),
                icon: 'error',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                deleteRobotsAsync(robots);
            }
        },
        [ translate, createDialog, deleteRobotsAsync ],
    );

    const getRowActions = useCallback(() => {
        const actions: Array<IAction<IRobot>> = [];
        if (isAdmin) {
            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_EDIT' }),
                tooltip: translate({ id: 'CLIENT_EDIT' }),
                actionType: GridActionType.Row,
                icon: <EditIcon />,
                click: row => {
                    navigate(`${getRoute(RouteNames.Robots)}/edit`,
                        { state: { robot: row.original } });
                },
            });
            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_DELETE' }),
                tooltip: translate({ id: 'CLIENT_DELETE' }),
                actionType: GridActionType.Row,
                icon: <DeleteForeverOutlinedIcon />,
                click: openDeleteDialog,
            });
        }
        return actions;
    }, [ getRoute, isAdmin, navigate, openDeleteDialog, translate ]);

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const actionList: IActionHeader[] = [];

        const addGroups: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_ROBOT_ACCOUNT' }),
            icon: <AddIcon />,
            click: () => navigate(`${getRoute(RouteNames.Robots)}/add`),
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.addRobotAccount,
            dataCy: 'ui-grid-add-robot-button',
        };
        actionList.push(addGroups);
        return actionList;
    }, [ translate, isAdmin, classes.addRobotAccount, navigate, getRoute ]);

    return (
        <>
            {includesLicense([ AccountLicense.FREEKIT ]) ?
                <UpgradeForFeature
                    upgradeTitle={translate({ id: 'CLIENT_UPGRADE_ROBOT_TITLE' })}
                    upgradeMessage={translate({ id: 'CLIENT_UPGRADE_ROBOT_DESCRIPTION' })}
                    documentationLink={getLocalizedLink({ articleSlug: AboutAccountsLink })}
                    level={AccountLicense.PRO}
                    telemetryTitle={RobotAccountsEvent.ClickedRobotAccounts} />
                :
                <UiPaginatedGrid<IRobot, IRobotResponse, { partitionGlobalId: string }>
                    url={robotUrl}
                    fetcher={getRobots}
                    fetcherArgs={{ partitionGlobalId: currentAccountGlobalId }}
                    data-cy="robots-ui-grid"
                    loading={loading}
                    search
                    searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                    extraActionButtons={extraActionHeaderButtons}
                    refreshData={{
                        current: refresh,
                        set: setRefresh,
                    }}
                    filters
                    checkbox={isAdmin}
                    columns={[
                        {
                            accessor: 'name',
                            sortName: enableRobotDisplayName ? 'DisplayName' : 'Name',
                            Header: translate({ id: 'CLIENT_NAME' }),
                            width: 20,
                            Cell: ({ row }) => <RobotNameCell
                                row={row}
                                enableRobotDisplayName={enableRobotDisplayName}
                                classes={classes} />,
                        },
                        {
                            accessor: 'groups',
                            Header: translate({ id: 'CLIENT_GROUPS' }),
                            width: 30,
                            disableSortBy: true,
                            Cell: ({ row }) => {
                                const groups = getGroupsForUser(row.original.groupIds)?.join(', ');
                                return groups ? (
                                    <div className={classes.servicesListCell}>
                                        <Tooltip
                                            arrow
                                            placement="bottom-start"
                                            title={groups}>
                                            <span>
                                                {groups}
                                            </span>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <span>
                                        {translate({ id: 'CLIENT_NO_GROUPS_FOUND' })}
                                    </span>
                                );
                            },
                        },
                        {
                            accessor: 'lastLoginTime',
                            sortName: 'LastLoginTime',
                            Header: translate({ id: 'CLIENT_LAST_ACTIVE' }),
                            width: 15,
                            Cell: ({ row }) => {
                                const date =
                (row.original.lastLoginTime && new Date(row.original.lastLoginTime)) ||
                new Date(row.original.creationTime);
                                return <FormattedDate
                                    value={date}
                                    year="numeric"
                                    month="short"
                                    day="numeric" />;
                            },
                        },
                    ]}
                    tableActions={
                        isAdmin
                            ? [
                                {
                                    type: ButtonType.Button,
                                    label: translate({ id: 'CLIENT_DELETE' }),
                                    icon: <DeleteForeverOutlinedIcon />,
                                    actionType: GridActionType.Selected,
                                    click: openDeleteDialog,
                                },
                            ]
                            : []
                    }
                    rowActions={getRowActions()}
                />}
        </>
    );
};

export default RobotAccountPageComponent;
