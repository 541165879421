import { stateInfo } from '@experiences/constants';
import { validateEmailBlacklist } from '@experiences/ecommerce';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useLocalization,
    useTranslatedCountries,
} from '@experiences/locales';
import {
    LicensesEvent,
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    UiDialog,
    UiProgressButton,
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import { useNavigateWithParams } from '@experiences/util';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import * as emailValidator from 'email-validator';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UserRole } from '../../common/constants/Constant';
import { routePaths } from '../../common/constants/routePaths';
import { triggerPortalShellRefresh } from '../../common/hooks/triggerPortalShellRefresh';
import useProfileStateName from '../../common/hooks/useProfileStateName';
import type { IEnterpriseUpgradeDto } from '../../common/interfaces/licenses';
import { ReactComponent as UpgradeOrangeIcon } from '../../images/upgradeOrange.svg';
import { upgradeToTrial } from '../../services/licensing/LicenseService';
import { setUserProfile } from '../../store/action/UserProfileAction';
import {
    accountCreatedOn,
    accountGlobalId,
    accountLogicalName,
    profile,
    userGlobalId,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { EnterpriseBenefitStringIds } from '../tenants/subcomponents/constant/EnterpriseTrailConstant';
import { useTenantOperationTrackerContext } from '../tenants/TenantOperationTrackerContextProvider';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        groupFields: {
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        groupInputField: { flex: 1 },
        subText: {
            fontSize: '13px',
            lineHeight: 1.25,
            color: theme.palette.semantic.colorForeground,
        },
        footerText: { marginTop: '16px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '24px',
            paddingBottom: '12px',
        },
        cancelButton: { marginRight: '10px' },
        successText: {
            width: 410,
            marginBottom: '20px',
        },
        successIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            height: '32px',
            width: '32px',
        },
        iconAdjustments: {
            width: '32px',
            height: '32px',
        },
        trialNudgeBox: {
            borderRadius: '3px',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            marginTop: '10px',
            marginBottom: '20px',
            padding: '14px',
        },
        trialNudgeBoxTitle: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
            marginBottom: '10px',
        },
        sixtyDaysFreeTrial: { color: theme.palette.semantic.colorForegroundLink },
        checkIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            marginRight: '10px',
        },
        benefitList: { display: 'flex' },
        checkIconWithBenefits: {
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
        },
        leftBenefits: { width: '50%' },
        rightBenefits: {
            marginLeft: '13px',
            width: '45%',
        },
    }),
}));

interface IEnterpriseUpgrade {
    organizationName: string;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    country: string;
    state?: string;
}

const UpgradeToEnterpriseDialogComponent: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const setErrorMessage = useCentralErrorSetter();
    const location = useLocation();

    const { getErrorMessage } = useGetErrorInfo();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();
    const { formatMessage: translate } = useIntl();
    const { translatedCountries: countries } = useTranslatedCountries();
    const [ firstName, lastName ] = useProfileStateName();
    const { logEvent } = useTelemetryHelper();

    const profileState = useSelector(profile);
    const currentAccountLanguage = useLocalization();
    const logicalName = useSelector(accountLogicalName);
    const accountCreatedDate = useSelector(accountCreatedOn).substring(0, 10);
    const userId = useSelector(userGlobalId);
    const accountGUID = useSelector(accountGlobalId);
    const EnableShowEnterpriseBenefitsPanel = getFeatureFlagValue(Features.EnableShowEnterpriseBenefitsPanel.name);

    const [ showRequesting, setShowRequesting ] = useState(false);
    const [ showSuccess, setShowSuccess ] = useState(false);

    const organizationNameRef = useRef<HTMLInputElement | null>(null);

    const {
        control,
        handleSubmit,
        setError,
        watch,
        resetField,
        formState: { errors },
    } = useForm<IEnterpriseUpgrade>({
        mode: 'onSubmit',
        defaultValues: {
            organizationName: profileState.accountUserDto.companyName ?? '',
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            email: profileState.emailId ?? '',
            jobTitle: '',
            country: profileState.accountUserDto.country ?? '',
            state: '',
        },
    });

    const [ country, state ] = watch([ 'country', 'state' ]);

    const states = useMemo(() => (stateInfo as Record<string, string[]>)[country], [ country ]);

    useEffect(() => {
        if (!states || states && state && !states.includes(state)) {
            resetField('state');
        }
    }, [ states, state, resetField ]);

    const closeDirectRoute = useMemo(() => {
        if (location.pathname.includes('resourceCenter')) {
            return routePaths.resourceCenter;
        }
        return location.pathname.includes('licensing') || location.pathname.includes('licenses')
            ? routePaths.adminLicenses
            : routePaths.admin;
    }, [ location.pathname ]);

    const close = useCallback(() => navigate(closeDirectRoute), [ navigate, closeDirectRoute ]);

    const submit = useCallback(
        async (data: IEnterpriseUpgrade) => {
            setShowRequesting(true);

            const organizationName = organizationNameRef.current?.value.trim() || data.organizationName.trim();

            const payload: IEnterpriseUpgradeDto = {
                organizationName,
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
                businessEmail: data.email.trim(),
                jobTitle: data.jobTitle.trim(),
                country: data.country?.trim(),
                state: states && data.state && states.indexOf(data.state?.trim()) > -1 ? data.state?.trim() : undefined,
                userId: profileState.userGlobalId,
                email: profileState.emailId ?? '',
                accountUrl: `${window.location.origin}/${logicalName}`,
                userLanguage: currentAccountLanguage,
                accountCreatedDate,
                companyName: organizationName,
            };

            if (!(await validateEmailBlacklist(payload.businessEmail))) {
                setError('email', { type: 'invalidDomain' });
                setShowRequesting(false);
                return;
            }

            try {
                if (profileState.accountRoleType === UserRole.ACCOUNT_ADMIN) {
                    await upgradeToTrial(userId, accountGUID, payload);
                    logEvent(LicensesEvent.ClickedStartTrialClickedStartTrial);
                    triggerPortalShellRefresh();
                    setUserProfile({
                        ...profileState,
                        accountUserDto: {
                            ...profileState.accountUserDto,
                            accountType: 'PRO-TRIAL',
                        },
                    });
                    refreshAfterComplete('', true);
                    setShowSuccess(true);
                } else {
                    logEvent(LicensesEvent.ClickedStartTrialClickedStartTrialError);
                    setErrorMessage(translate({ id: 'CLIENT_FORBIDDEN_ERROR_MESSAGE' }));
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            } finally {
                setShowRequesting(false);
            }
        },
        [
            states,
            profileState,
            logicalName,
            currentAccountLanguage,
            accountCreatedDate,
            setError,
            userId,
            accountGUID,
            logEvent,
            refreshAfterComplete,
            setErrorMessage,
            translate,
            getErrorMessage,
        ],
    );

    return (
        <UiDialog
            title={showSuccess
                ? translate({ id: 'CLIENT_SUCCESS' })
                : translate({ id: 'CLIENT_UPGRADE_PRO_TRIAL' })}
            icon={
                showSuccess ? (
                    <CheckIcon className={classes.successIcon} />
                ) : EnableShowEnterpriseBenefitsPanel ? (
                    <UpgradeOrangeIcon className={classes.iconAdjustments} />
                ) : null
            }
            bodyActions
            close={close}
            width={showSuccess ? undefined : '590px'}
        >
            {showSuccess ? (
                <UiText
                    className={classes.successText}
                    data-cy="upgrade-to-enterprise-success-text">
                    {translate({ id: 'CLIENT_SUCCESS_MESSAGE_UPGRADE_TO_PRO_TRIAL' })}
                </UiText>
            ) : (
                <>
                    {EnableShowEnterpriseBenefitsPanel ? (
                        <div className={classes.trialNudgeBox}>
                            <UiText
                                className={classes.trialNudgeBoxTitle}
                                data-cy="sign-up-for-free-trial-title">
                                <FormattedMessage
                                    id="CLIENT_SIGN_UP_FOR_PRO_TRIAL"
                                    values={{
                                        red: (chunk: React.ReactNode[]) => <strong className={classes.sixtyDaysFreeTrial}>
                                            {chunk}
                                        </strong>,
                                    }}
                                />
                            </UiText>
                            <div
                                className={classes.benefitList}
                                data-cy="enterprise-trial-benefits">
                                {Object.entries(EnterpriseBenefitStringIds).map(([ part, benefits ], index) => (
                                    <div
                                        key={index}
                                        className={part === 'leftPart' ? classes.leftBenefits : classes.rightBenefits}>
                                        {benefits.map(benefit => (
                                            <UiText
                                                key={benefit}
                                                className={classes.checkIconWithBenefits}>
                                                <CheckIcon className={classes.checkIcon} />
                                                {translate({ id: benefit })}
                                            </UiText>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <UiText
                            className={classes.subText}
                            data-cy="sign-up-for-free-trial-title">
                            {translate({ id: 'CLIENT_UPGRADE_TO_PRO_SUBHEADING' })}
                        </UiText>
                    )}
                    <form
                        id="upgrade-to-enterprise-form"
                        onSubmit={handleSubmit(submit)}>
                        <div className={classes.groupFields}>
                            <Controller
                                control={control}
                                name="firstName"
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    validate: p => !!p.trim(),
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        className={classes.groupInputField}
                                        style={{ marginRight: '8px' }}
                                        variant="outlined"
                                        id="firstName"
                                        required
                                        label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_FIRSTNAME' })}
                                        disabled={showRequesting}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                        InputProps={{ className: 'Tall' }}
                                        data-cy="upgrade-to-enterprise-modal-first-name"
                                    />}
                            />
                            <Controller
                                control={control}
                                name="lastName"
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    validate: p => !!p.trim(),
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        className={classes.groupInputField}
                                        variant="outlined"
                                        id="lastName"
                                        required
                                        label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_LASTNAME' })}
                                        disabled={showRequesting}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                        InputProps={{ className: 'Tall' }}
                                        data-cy="upgrade-to-enterprise-modal-last-name"
                                    />}
                            />
                        </div>
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                required: true,
                                validate: { invalid: value => emailValidator.validate(value) },
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    id="email"
                                    required
                                    label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_EMAIL' })}
                                    disabled={showRequesting}
                                    error={!!errors.email}
                                    helperText={
                                        (errors.email?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                        || (errors.email?.type === 'invalid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' }))
                                        || (errors.email?.type === 'invalidDomain' && translate({ id: 'CLIENT_INVALID_EMAIL_DOMAIN_ERROR' }))
                                    }
                                    InputProps={{
                                        className: 'Tall',
                                        endAdornment: errors.email?.type === 'invalidDomain' && (
                                            <InputAdornment position="end">
                                                <ErrorIcon color="error" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    data-cy="upgrade-to-enterprise-modal-email"
                                />}
                        />
                        <Controller
                            control={control}
                            name="jobTitle"
                            rules={{
                                required: true,
                                minLength: 1,
                                validate: p => !!p.trim(),
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    className={classes.groupInputField}
                                    variant="outlined"
                                    id="jobTitle"
                                    required
                                    label={translate({ id: 'CLIENT_JOB_TITLE' })}
                                    disabled={showRequesting}
                                    error={!!errors.jobTitle}
                                    helperText={errors.jobTitle?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    InputProps={{ className: 'Tall' }}
                                    fullWidth
                                    data-cy="upgrade-to-enterprise-modal-job-title"
                                />}
                        />
                        <UiSelect
                            control={control}
                            name="country"
                            isTranslated
                            inputLabel={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_COUNTRY' })}
                            options={countries}
                            defaultValue={profileState.accountUserDto.country}
                            required
                            fullWidth
                            dataCy="upgrade-to-enterprise-modal-country"
                            error={!!errors.country}
                            helperText={errors.country?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            disabled={showRequesting}
                        />
                        {states && (
                            <UiSelect
                                control={control}
                                name="state"
                                inputLabel={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_STATE' })}
                                options={states}
                                required
                                fullWidth
                                dataCy="upgrade-to-enterprise-modal-state"
                                error={!!errors.state}
                                helperText={errors.state?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                disabled={showRequesting}
                            />
                        )}
                        <Controller
                            control={control}
                            name="organizationName"
                            rules={{
                                required: true,
                                minLength: 1,
                                validate: p => !!p.trim(),
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    id="organizationName"
                                    required
                                    label={translate({ id: 'CLIENT_ORGANIZATION_NAME' })}
                                    disabled={showRequesting}
                                    error={!!errors.organizationName}
                                    helperText={errors.organizationName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    fullWidth
                                    autoComplete='off'
                                    InputProps={{ className: 'Tall' }}
                                    data-cy="upgrade-to-enterprise-modal-company"
                                />}
                        />

                        <div className={clsx(classes.subText, classes.footerText)}>
                            <FormattedMessage
                                id="CLIENT_UPGRADE_TO_ENTERPRISE_TERMS_AND_CONDITIONS"
                                values={{
                                    a: (msg: any) =>
                                        (
                                            <a
                                                className={classes.a}
                                                href={
                                                    currentAccountLanguage === 'ja'
                                                        ? 'https://www.uipath.com/hubfs/legalspot/UiPath_Evaluation_Agreement.pdf'
                                                        : 'https://www.uipath.com/hubfs/legalspot/UiPath_Evaluation_Agreement.pdf'
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {msg}
                                            </a>
                                        ) as any,
                                }}
                            />
                        </div>
                        <div className={classes.actions}>
                            <Button
                                key="cancel"
                                className={classes.cancelButton}
                                onClick={close}
                                data-cy="upgrade-to-enterprise-cancel"
                                color="primary"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                key="request"
                                data-cy="upgrade-to-enterprise-submit"
                                loading={showRequesting}
                                onClick={() => {
                                    portalTelemetry.trackTrace({
                                        message: `submit request enterprise trial form with account name ${logicalName}`,
                                        severityLevel: SeverityLevel.Info,
                                    });
                                }}
                                type="submit"
                                variant="contained"
                            >
                                {translate({ id: 'CLIENT_SEND_REQUEST_TO_PRO_TRIAL' })}
                            </UiProgressButton>
                        </div>
                    </form>
                </>
            )}
        </UiDialog>
    );
};

export default UpgradeToEnterpriseDialogComponent;
