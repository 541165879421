import type { IPortalUser } from '@experiences/interfaces';
import React from 'react';

import { HomePageSelector } from './HomePageSelector';

export interface UiHomePageProps {
    profile: IPortalUser;
    token: string;
}

export const UiHomePage = ({
    token, profile,
}: UiHomePageProps) => (
    <HomePageSelector
        profile={profile}
        accessToken={token} />
);

export default UiHomePage;
