import React, { useMemo } from 'react';
import {
    useParams,
    useSearchParams,
} from 'react-router-dom';

import InsightsLogExportComponent from './InsightsLogExportComponent/InsightsLogExportComponent';
import OrchLogExportComponent from './OrchLogExportComponent';

const LogExportComponent: React.FC = () => {
    const [ searchParams, _ ] = useSearchParams();
    const { tenantId } = useParams() as { tenantId: string };

    const {
        serviceType, tenantName,
    } = useMemo(() => ({
        serviceType: searchParams.get('serviceType')!,
        tenantName: searchParams.get('tenantName')!,
    }), [ searchParams ]);

    const props = {
        tenantId,
        tenantName,
    };

    return serviceType === 'orchestrator'
        ? <OrchLogExportComponent {...props} />
        : <InsightsLogExportComponent {...props} />;
};

export default LogExportComponent;
