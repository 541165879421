import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ErrorCodeMapping } from './ErrorCodeMapping';

export function useErrorMapper() {
    const { formatMessage: translate } = useIntl();

    const getErrorMapping = useCallback(
        (
            code: number | string,
            customProps?: { params?: any; defaultMessage?: string }
        ) => {
            let errorMessage = undefined;
            const mapping = ErrorCodeMapping[code?.toString()];
            if (mapping) {
                const {
                    message, action,
                } = mapping;
                const errorMessageParams = customProps?.params;
                const translationParams = errorMessageParams && Object.fromEntries(
                    Object.entries(errorMessageParams).map(([ k, v ]) => [ k, translate({ id: v as string }) ])
                );
                errorMessage = translate(
                    {
                        id: message,
                        defaultMessage: customProps?.defaultMessage,
                    },
                    translationParams ?? {}
                );
                action?.();
            }

            return errorMessage ?? translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG_ELLIPSES' });
        },
        [ translate ]
    );

    return getErrorMapping;
}
