import {
    useAuthContext,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';

import {
    ADDON_PRODUCT_CODES,
    DEFAULT_PRODUCT_CODES,
    useEcommerceEnabledCountries,
} from './helpers/EcommerceHelpers';
import {
    BuyProPresets,
    DirectBuyPresets,
} from './helpers/EcommerceRoutes';
import { useEcommerce } from './helpers/useEcommerce';
import type {
    ICustomPlanForm,
    IPackage,
    IProductPriceOptions,
} from './interfaces/ecommerce';
import {
    billingUrl,
    getPackagesConfigPublic,
    getPackagesPricesPublic,
} from './services/BillingService';

const useEcommerceIndividualProductsViewModel = (currentAccountName: string, isDirectBuyFlow: boolean) => {
    const productsPricesInAllCurrenciesUrl = `${billingUrl}/productsPricesInAllCurrencies`;
    const { token } = useAuthContext();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const { accountCountryCode } = useEcommerceEnabledCountries();
    const { data: productsPricesInAllCurrencies } = useSWR(
        {
            accountName: currentAccountName,
            url: productsPricesInAllCurrenciesUrl,
            accessToken: token,
        },
        getPackagesPricesPublic,
    );
    const [ loading, setLoading ] = useState<boolean>(true);
    const {
        currentSkuPackageDetails,
        isTaxLoading,
    } = useEcommerce();

    const {
        defaultProductsToRender, addonProductsToRender,
    } = useMemo<{
        defaultProductsToRender: IProductPriceOptions[];
        addonProductsToRender: IProductPriceOptions[];
    }>(() => {
        const defaultProducts: IProductPriceOptions[] = [];
        const addonProducts: IProductPriceOptions[] = [];
        if (productsPricesInAllCurrencies) {
            const productsGroupedByCode: { [key: string]: IProductPriceOptions } = {};
            productsPricesInAllCurrencies.forEach(product => {
                if (product.planType === currentSkuPackageDetails.planType) {
                    productsGroupedByCode[product.code] = product;
                }
            });
            DEFAULT_PRODUCT_CODES.forEach((productCode) => {
                const product = productsGroupedByCode[productCode];
                if (product) {
                    defaultProducts.push(product);
                }
            });
            ADDON_PRODUCT_CODES.forEach(productCode => {
                const product = productsGroupedByCode[productCode];
                if (product) {
                    addonProducts.push(product);
                }
            });
        }
        return {
            defaultProductsToRender: defaultProducts,
            addonProductsToRender: addonProducts,
        };
    }, [ productsPricesInAllCurrencies, currentSkuPackageDetails ]);

    const [ customPlanPackage, setCustomPlanPackage ] = useState<IPackage>();
    const [ selectedCurrency, setSelectedCurrency ] = useState<string>('USD');

    const packagesConfigUrl = `${billingUrl}/packagesConfig`;
    const { data: packagesData } = useSWR(
        {
            accountName: currentAccountName,
            currency: selectedCurrency,
            countryCode: accountCountryCode,
            accessToken: token,
            url: packagesConfigUrl,
        },
        getPackagesConfigPublic,
    );

    useEffect(() => {
        if (!customPlanPackage) {
            setCustomPlanPackage(packagesData?.packages.find(p => p.isCustomizable));
        }
    }, [ packagesData?.packages, customPlanPackage ]);

    const productQuantitiesForCustomPlan = useMemo(() => {
        const productQuantities: { [code: string]: number } = {};

        if (customPlanPackage) {
            customPlanPackage?.productQuantities?.forEach((pq) => productQuantities[pq.code] = pq.quantity);
            setLoading(false);
        }

        return productQuantities;
    }, [ customPlanPackage ]);

    const useFormMethods = useForm<ICustomPlanForm>({
        mode: 'onChange',
        defaultValues: { productQuantities: productQuantitiesForCustomPlan },
    });

    const {
        getValues, formState: { isValid: isFormValid }, reset,
    } = useFormMethods;

    useEffect(() => {
        reset({ productQuantities: productQuantitiesForCustomPlan });
    }, [ productQuantitiesForCustomPlan, reset ]);

    const backToPresets = useCallback(() => {
        isDirectBuyFlow ?
            navigate(getRoute(DirectBuyPresets), { state: { selectedCurrency } }) :
            navigate(getRoute(BuyProPresets), { state: { selectedCurrency } });
    }, [ isDirectBuyFlow, navigate, getRoute, selectedCurrency ]);

    const updatePlanSummary = useCallback(() => {
        const { productQuantities } = getValues();
        const updatedCustomPlanPackage = customPlanPackage;

        updatedCustomPlanPackage?.productQuantities.forEach(p => {
            p.quantity = Number(productQuantities[p.code] ?? 0);
        });

        setCustomPlanPackage(updatedCustomPlanPackage);

    }, [ customPlanPackage, setCustomPlanPackage, getValues ]);

    const checkPlusInput = useCallback((productCode: string) => {
        if (customPlanPackage) {
            const productQuantity = customPlanPackage?.productQuantities.find(pq => pq.code === productCode);
            if (productQuantity?.minQuantity) {
                return productQuantity.minQuantity > 0;
            }
        }
        return false;
    }, [ customPlanPackage ]);

    const isMonthlyPlanType = useMemo(() => currentSkuPackageDetails.planType === 'ANNUAL_SUB_MONTHLY_PAY', [ currentSkuPackageDetails ]);
    const selectedPlanType = useMemo(() => currentSkuPackageDetails.planType, [ currentSkuPackageDetails ]);

    return {
        defaultProductsToRender,
        addonProductsToRender,
        productsPricesInAllCurrencies,
        customPlanPackage,
        useFormMethods,
        productQuantitiesForCustomPlan,
        loading,
        isTaxLoading,
        currentSkuPackageDetails,
        isMonthlyPlanType,
        selectedCurrency,
        setSelectedCurrency,
        checkPlusInput,
        updatePlanSummary,
        backToPresets,
        isFormValid,
        selectedPlanType,
    };
};

export default useEcommerceIndividualProductsViewModel;
