import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { ButtonType } from '../UiGrid/grid';
import type { ApAction } from './apdatagrid';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
        },
        iconRight: { paddingLeft: '12px' },
    }),
}));

interface IMoreRowActionsProps<T extends object> {
    row: T;
    actions: Array<ApAction<T>>;
}

export const ApDataGridMoreRowActionsComponent = <T extends object>({
    row, actions,
}: IMoreRowActionsProps<T>) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const clickMenuItem = useCallback((click: (_: any) => void, rowObj: any) => {
        setAnchorEl(null);
        click(rowObj);
    }, []);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const showActions = useMemo(() => actions.map((action, p) => {
        if (
            (action.invisible !== undefined && typeof action.invisible === 'function' && !action.invisible(row))
                || !action.invisible
        ) {
            return <Tooltip
                placement='right'
                key={`${action.id}-tooltip-${p}`}
                title={action.tooltip && typeof action.tooltip === 'function' ? action.tooltip(row) : ''}>
                <div>
                    <MenuItem
                        component={action.href !== undefined ? Link : undefined as any}
                        href={action.href && typeof action.href === 'function' ? action.href(row) : action.href}
                        target={(action.href && action.target) ? action.target : undefined}
                        key={`${action.id}-${p}`}
                        disabled={action.disable && typeof action.disable === 'function' ? action.disable(row) : action.disable}
                        onClick={() => (action.click ? clickMenuItem(action.click, row) : undefined)}
                        data-cy={action.dataCy ?? 'ap-data-grid-show-more-actions-item'}
                        className={classes.menuItem}
                    >
                        {(action.type === ButtonType.ButtonWithIcon && action.icon && action.iconPosition !== 'right') ? (
                            <ListItemIcon className={clsx(classes.icon, classes.svgIcon)}>
                                {typeof action.icon !== 'function' ? action.icon : action.icon(row)}
                            </ListItemIcon>
                        ) : null}

                        <ListItemText primary={typeof action.label === 'function' ? action.label(row) : action.label} />

                        {(action.type === ButtonType.ButtonWithIcon && action.icon && action.iconPosition === 'right') ? (
                            <ListItemIcon className={clsx(classes.icon, classes.svgIcon, classes.iconRight)}>
                                {typeof action.icon !== 'function' ? action.icon : action.icon(row)}
                            </ListItemIcon>
                        ) : null}
                    </MenuItem>
                </div>
            </Tooltip>;
        }
    }), [ actions, classes.icon, classes.iconRight, classes.menuItem, classes.svgIcon, clickMenuItem, row ]);

    return (
        <>
            <Tooltip
                title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                placement='right'>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    className={classes.showMoreButton}
                    onClick={handleClick}
                    data-cy="ap-data-grid-show-more-actions-button"
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                data-cy="ap-data-grid-show-more-actions-menu"
            >
                {showActions}
            </Menu>
        </>
    );
};
