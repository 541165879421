import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalErrorPage } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => createStyles({ errorPage: { marginTop: '118px' } }));

const RestrictedPolicyError: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return <div className={classes.errorPage}>
        <PortalErrorPage
            type="ip-restricted"
            header={translate({ id: 'CLIENT_OOPS_YOURE_NOT_ON_THE_LIST' })}
            description={translate({ id: 'CLIENT_ACCESS_POLICY_RESTRICTION_DESCRIPTION' })}
        />
    </div>;
};

export default RestrictedPolicyError;
