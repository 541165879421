import {
    ACCOUNT_PREFIX,
    PORTAL_PREFIX,
} from '@experiences/constants';

export const AccountPrefix = ACCOUNT_PREFIX;
export const PortalPrefix = PORTAL_PREFIX;
export const PortalAdminPrefix = `${PORTAL_PREFIX}/admin`;

export const Root = `${PortalPrefix}/`;
export const RootRedirect = `${PortalPrefix}/rootRedirect`;
export const Home = `${PortalPrefix}/home`;
export const CloudRPA = `${PortalPrefix}/cloudrpa`;
export const LoginSuccess = `${PortalPrefix}/loginsuccess`;
export const Services = `${PortalPrefix}/serviceinstances`;
export const TenantDelete = `${Services}/delete`;
export const TenantStatus = `${Services}/status`;
export const TenantLicensingUpgradeToEnterprise = `${Services}/upgrade`;
export const TenantCreateEdit = `${Services}/:type/:tenantId`;
export const TenantCreate = `${PortalPrefix}/createTenant`;
export const TenantConfigure = `${Services}/configure/:tenantId`;
export const TenantConfigureSkip = `${TenantCreateEdit}/skip`;
export const OrchLogExport = `${Services}/logsexport/:tenantId`;
export const Users = `${PortalPrefix}/users`;
export const Groups = `${PortalPrefix}/groups`;
export const Robots = `${PortalPrefix}/robots`;
export const AuthSettingsDeprecated = `${PortalPrefix}/azure`; // Deprecated; prefer `AuthSettings` route
export const AuthSettingsDeprecatedConfigure = `${AuthSettingsDeprecated}/configure`; // Deprecated; prefer `AuthSettings` route
export const AuthSettings = `${PortalPrefix}/authsettings`;
export const AuthSettingsConfigureAD = `${AuthSettings}/configureAd`;
export const AuthSettingsConfigureADDirectory = `${AuthSettings}/configureAdDirectory`;
export const AuthSettingsConfigureAAD = `${AuthSettings}/configureAad`;
export const AuthSettingsConfigureGoogle = `${AuthSettings}/configureGoogle`;
export const AuthSettingsConfigureSaml = `${AuthSettings}/configureSaml`;
export const AuthSettingsConfigureSamlOrg = `${AuthSettings}/configure/:type`;
export const UserInviteEdit = `${Users}/:type`;
export const UserAddEdit = `${Users}/:type`;
export const DirectoryUser = `${Users}/directoryUser`;
export const DirectoryUserAddEdit = `${DirectoryUser}/:type`;
export const BulkUserInvite = `${Users}/bulkinvite`;
export const UsersUserAddLicenseAllocation = `${Users}/allocations/add`;
export const UsersUserEditLicenseAllocation = `${Users}/allocations/edit`;
export const UsersUserLicenseInheritance = `${Users}/allocations/inheritance`;
export const RobotsAddEdit = `${Robots}/:type`;
export const GroupsAdd = `${Groups}/:type`;
export const GroupsEdit = `${Groups}/:type/:id`;
export const GroupAllocationRule = `${Groups}/allocations/:type`;
export const Licensing = `${PortalPrefix}/licensing`;
export const LicensingTenantConfigure = `${PortalPrefix}/licensing/:type/:tenantId`;
export const LicensingUpgradeToEnterprise = `${Licensing}/upgrade`;
export const ActivateOffline = `${Licensing}/activate/offline`;
export const DeactivateOffline = `${Licensing}/deactivate/offline`;
export const ManagePlan = `${PortalPrefix}/manageplan`;
export const BuyEnterprise = `${PortalPrefix}/buyenterprise`;
export const BuyPro = `${PortalPrefix}/buypro`;
export const BuyProPresets = `${PortalPrefix}/buyPresets`;
export const UpdateLicenseQuantity = `${PortalPrefix}/updateLicenseQuantity`;
export const ViewCancelLicensePendingChange = `${Licensing}/manageLicensePendingChange`;
export const BuyProSuccess = `${Licensing}/success`;
export const LicensingGroupAllocation = `${Licensing}/group`;
export const LicensingGroupAllocationAddEdit = `${LicensingGroupAllocation}/:type`;
export const LicensingGroupAllocationView = `${LicensingGroupAllocation}/view`;
export const LicensingUserAllocation = `${Licensing}/user`;
export const LicensingUserAddLicenseAllocation = `${LicensingUserAllocation}/add`;
export const LicensingUserEditLicenseAllocation = `${LicensingUserAllocation}/edit`;
export const LicensingUserLicenseInheritance = `${LicensingUserAllocation}/inheritance`;
export const LicensingActivateLicenseOnline = `${Licensing}/activate/online`;
export const ResourceCenter = `${PortalPrefix}/resourceCenter`;
export const ResourceCenterCMSUpgradeToEnterprise = `${ResourceCenter}/upgrade`;
export const AuditLogs = `${PortalPrefix}/auditLog`;
export const AuditLogDialog = `${AuditLogs}/dialog`;
export const Audit = `${PortalAdminPrefix}/audit`;
export const ServicesUsage = `${PortalPrefix}/service/:tenantId/usage`;
export const TenantServicesUsageAllocation = `${ServicesUsage}/:type`;
export const OrganizationSettings = `${PortalPrefix}/settings`;
export const OrganizationSettingsAdvanced = `${PortalPrefix}/advancedSettings`;
export const OrganizationSettingsWarning = `${OrganizationSettings}/warning`;
export const OrganizationMigration = `${OrganizationSettingsAdvanced}/migration`;
export const EditOrganizationMigration = `${OrganizationSettingsAdvanced}/editMigration`;
export const EmailSettings = `${PortalPrefix}/emailsettings`;
export const LoginCustomization = `${PortalPrefix}/loginCustomization`;
export const RequestUserTokenAuth = `${PortalPrefix}/requestusertokenauth`;
export const RedirectToService = `${PortalPrefix}/redirectToService`;
export const ExternalApplications = `${PortalPrefix}/externalApps`;
export const ExternalApplicationsPAT = `${PortalPrefix}/externalAppsPAT`;
export const ExternalApplicationsPATSettings = `${ExternalApplicationsPAT}/settings`;
export const ExternalApplicationAddEdit = `${ExternalApplications}/:type/:id`;
export const ExternalApplicationAdd = `${ExternalApplications}/:type`;
export const ExternalApplicationEdit = `${ExternalApplications}/:type/:id`;
export const ExternalApplicationAddScopes = `${ExternalApplicationAdd}/scopes`;
export const ExternalApplicationAddScopesWithName = `${ExternalApplicationAdd}/scopes/:name`;
export const ExternalApplicationEditScopes = `${ExternalApplicationEdit}/scopes`;
export const ExternalApplicationEditScopesWithName = `${ExternalApplicationEdit}/scopes/:name`;
export const ExternalAppRegistrations = `${PortalPrefix}/appRegistrations`;
export const ExternalAppRegistrationsDetails = `${ExternalAppRegistrations}/details/:publisherId/:id/:type`;
export const Profile = `${PortalPrefix}/profile`;
export const PrivacyAndSecuritySettings = `${PortalPrefix}/privacyAndSecuritySettings`;
export const NavigationSettings = `${PortalPrefix}/navigationSettings`;
export const ThemeSettings = `${PortalPrefix}/themeSettings`;
export const AccessibilitySettings = `${PortalPrefix}/accessibilitySettings`;
export const PersonalAccessToken = `${PortalPrefix}/personalAccessToken`;
export const AddPersonalAccessToken = `${PersonalAccessToken}/add`;
export const ChangePassword = `${PrivacyAndSecuritySettings}/changePassword`;
export const Organizations = `${PortalPrefix}/organizations`;
export const OrganizationsAdd = `${Organizations}/add`;
export const OrganizationsLicense = `${Organizations}/license`;
export const OrganizationAdminHome = PortalAdminPrefix;
export const TenantHome = `${PortalPrefix}/tenant/:tenantId`;
export const OrganizationHome = `${PortalPrefix}/organization/:organizationId`;
export const HostOrganizationLicense = `${OrganizationHome}/licenses`;
export const HostOrganizationSettings = `${OrganizationHome}/settings`;
export const HostOrganizationAdd = `${PortalPrefix}/organization/add`;
export const TenantServices = `${TenantHome}/services`;
export const TenantServiceLogExport = `${TenantServices}/logsexport`;
export const TenantServicesAdd = `${TenantServices}/add`;
export const TenantConfigureService = `${TenantServices}/:type`;
export const TenantSettings = `${TenantHome}/settings`;
export const TenantMigration = `${TenantHome}/migration`;
export const EditTenantMigration = `${TenantSettings}/editMigration`;
export const TenantAudit = `${TenantHome}/audit`;
export const VpnGateway = `${TenantHome}/vpn-gateway`;
export const VpnGatewayCreateEdit = `${VpnGateway}/:type`;
export const VpnGatewayConnections = `${TenantHome}/vpn-gateway-connections/:vpnGatewayKey`;
export const VpnGatewayConnectionsCreateEdit = `${VpnGatewayConnections}/:type`;

export const SecuritySettings = `${PortalPrefix}/authenticationsettings`;
export const ConfigureSSO = `${SecuritySettings}/configuresso`;
export const SecuritySettingsConfigure = `${SecuritySettings}/configure/:type`;
export const SecuritySettingsSAMLProvisioningRules = `${SecuritySettings}/configure/saml/provisioningRules`;
export const SecuritySettingsSAMLProvisioningRulesAdd = `${SecuritySettings}/configure/saml/provisioningRules/:type`;
export const SecuritySettingsSAMLProvisioningRulesEdit = `${SecuritySettings}/configure/saml/provisioningRules/:type/:id`;
export const SecuritySettingsAttributeMapping = `${SecuritySettings}/attributes/:type`;
export const SessionPolicy = `${PortalPrefix}/sessionpolicy`;
export const IPRestriction = `${PortalPrefix}/iprestriction`;
export const IPRestrictionAdd = `${IPRestriction}/:type`;
export const IPRestrictionEdit = `${IPRestriction}/:type/:id`;
export const IPRestrictionBulkImport = `${IPRestriction}/bulkimport`;
export const Encryption = `${PortalPrefix}/encryption`;
export const EncryptionConfigure = `${Encryption}/configure/:type`;
export const NotificationSettings = `${PortalPrefix}/notification-settings`;
export const Notifications = `${PortalPrefix}/notifications`;
export const AddNotificationSettingsProfile = `${NotificationSettings}/editprofile/:id/:name`;
export const AddUserGroupProfile = `${NotificationSettings}/addusergroup`;
export const AuthSettingsPasswordPolicy = `${SecuritySettings}/configure/passwordPolicy`;

export const TenantTags = `${TenantHome}/tags`;
export const TenantTagsLabels = `${TenantTags}/labels`;
export const TenantTagsLabelsAdd = `${TenantTagsLabels}/:type`;
export const TenantTagsLabelsEdit = `${TenantTagsLabels}/:type/:id`;
export const TenantTagsProperties = `${TenantTags}/properties`;
export const TenantTagsPropertiesAdd = `${TenantTagsProperties}/:type/:editType`;
export const TenantTagsPropertiesEdit = `${TenantTagsProperties}/:type/:editType/:id`;
export const TenantTagsPropertiesDrawerAdd = `${TenantTagsPropertiesAdd}/values`;
export const TenantTagsPropertiesDrawerEdit = `${TenantTagsPropertiesEdit}/values`;
export const OrganizationAccessPolicy = `${PortalPrefix}/accessrestriction`;
export const OrganizationAccessPolicyAdd = `${OrganizationAccessPolicy}/add`;

export const TenantTagsMsi = `${OrganizationAdminHome}/tags`;
export const TenantTagsLabelsMsi = `${TenantTagsMsi}/labels`;
export const TenantTagsLabelsAddMsi = `${TenantTagsLabelsMsi}/:type`;
export const TenantTagsLabelsEditMsi = `${TenantTagsLabelsMsi}/:type/:id`;
export const TenantTagsPropertiesMsi = `${TenantTagsMsi}/properties`;
export const TenantTagsPropertiesAddMsi = `${TenantTagsPropertiesMsi}/:type/:editType`;
export const TenantTagsPropertiesEditMsi = `${TenantTagsPropertiesMsi}/:type/:editType/:id`;
export const TenantTagsPropertiesDrawerAddMsi = `${TenantTagsPropertiesAddMsi}/values`;
export const TenantTagsPropertiesDrawerEditMsi = `${TenantTagsPropertiesEditMsi}/values`;

export const AiTrustLayerSetting = `${PortalPrefix}/admin/ai-trust-layer/setting`;

export const ManageAccess = `${PortalAdminPrefix}/tenant/:tenantId/manage-access`;
export const CheckAccess = `${ManageAccess}/check-access`;

export const UserConsent = `${OrganizationAdminHome}/user-consent`;
export const UserConsentCreate = `${UserConsent}/create`;
export const UserConsentEdit = `${UserConsent}/edit/:id`;

export const AutomationOpsGovernance = `${AccountPrefix}/roboticsops_/governance_/`;
