import type {
    IGlobalExternalClient,
    IScope,
} from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Box from '@mui/material/Box';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAppRegistrationsDetails } from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    getAllAvailableApplications,
    getRegisteredApplication,
    globalOauthUrl,
    registerApplication,
    unregisterApplication,
} from '../../../services/identity/GlobalExternalClientService';
import { accountGlobalId } from '../../../store/selectors';

const useExternalApplicationsAppRegistrationsViewModel = () => {
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();

    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data: availableApps, mutate: updateAvailableApps, error: availableAppsError,
    } = useSWR<IGlobalExternalClient[]>(
        globalOauthUrl,
        getAllAvailableApplications,
    );

    const {
        data: registeredApps, mutate: updateRegisteredApps, error: registeredAppsError,
    } = useSWR(
        {
            url: globalOauthUrl,
            partitionGlobalId,
        },
        getRegisteredApplication,
    );

    const registerApplicationCallback = useCallback(async (clientId: string, scopes: IScope[]) => {
        try {
            await registerApplication(partitionGlobalId, clientId, scopes);
            createNotification(translate({ id: 'CLIENT_APPLICATION_REGISTERED_SUCCESSFULLY' }), notificationType.SUCCESS);
            await updateRegisteredApps();
            await updateAvailableApps();
        } catch (e) {
            createNotification(translate({ id: 'CLIENT_APPLICATION_FAILED_TO_REGISTER' }), notificationType.ERROR);
        }
    }, [ createNotification, partitionGlobalId, translate, updateAvailableApps, updateRegisteredApps ]);

    const unregisterApplicationCallback = useCallback(async (clientId: string, appName: string) => {
        try {
            const proceed = await createDialog({
                icon: 'warning',
                title: translate({ id: 'CLIENT_UNREGISTER_APP_DIALOG_TITLE' }, { appName }),
                body: <Box>
                    <UiText>
                        {translate({ id: 'CLIENT_UNREGISTER_APP_DIALOG_DESCRIPTION' })}
                    </UiText>
                    <UiText style={{ marginTop: '20px' }}>
                        {translate({ id: 'CLIENT_UNREGISTER_APP_DIALOG_DESCRIPTION_2' })}
                    </UiText>
                </Box>,
                showCancel: true,
                primaryButtonText: translate({ id: 'Unregister' }),
            });

            if (!proceed) {
                return;
            }

            await unregisterApplication(partitionGlobalId, clientId);
            createNotification(translate({ id: 'CLIENT_APPLICATION_UNREGISTERED_SUCCESSFULLY' }), notificationType.SUCCESS);
            await updateRegisteredApps();
            await updateAvailableApps();
        } catch (e) {
            createNotification(translate({ id: 'CLIENT_APPLICATION_FAILED_TO_UNREGISTER' }), notificationType.ERROR);
        }
    }, [ createDialog, createNotification, partitionGlobalId, translate, updateAvailableApps, updateRegisteredApps ]);

    const filteredRegisteredApps = useMemo(() => registeredApps
        ?.map(app => {
            const availableAppDetails = availableApps?.find(available => available.clientId === app.clientId);
            const registeredAppDetails = registeredApps?.find(registered => registered.clientId === app.clientId);
            if (!registeredAppDetails || !availableAppDetails) {
                return null;
            }
            return {
                ...availableAppDetails,
                registrationTime: registeredAppDetails?.registrationTime,
                unRegisteredScopes: registeredAppDetails?.unRegisteredScopes,
            };
        })
        .filter(app => app != null) as IGlobalExternalClient[] ?? [], [ availableApps, registeredApps ]);

    const moreActions = useCallback((
        registered: boolean,
        appName: string,
        publisherId: string,
        clientId: string,
        scopes: IScope[],
        updatedScopes: boolean,
    ) => ([
        {
            label: translate({ id: 'CLIENT_UPDATE_REGISTRATION' }),
            click: () => navigate(getRoute(ExternalAppRegistrationsDetails)
                .replace(':publisherId', publisherId)
                .replace(':id', clientId)
                .replace(':type', 'update')),
            dataCy: 'update-registration',
            ariaLabel: translate({ id: 'CLIENT_UPDATE_REGISTRATION' }),
            invisible: !updatedScopes,
        },
        {
            label: translate({ id: 'CLIENT_VIEW_DETAILS' }),
            click: () => navigate(getRoute(ExternalAppRegistrationsDetails)
                .replace(':publisherId', publisherId)
                .replace(':id', clientId)
                .replace(':type', registered ? 'unregister' : 'register')),
            dataCy: 'launch-service',
            ariaLabel: translate({ id: 'CLIENT_LAUNCH' }),
        },
        {
            label: translate({ id: 'CLIENT_REGISTER' }),
            click: () => registerApplicationCallback(clientId, scopes),
            dataCy: 'register-global-application',
            ariaLabel: translate({ id: 'CLIENT_REGISTER' }),
            invisible: registered,
        },
        {
            label: translate({ id: 'CLIENT_UNREGISTER' }),
            click: () => unregisterApplicationCallback(clientId, appName),
            dataCy: 'unregister-global-application',
            ariaLabel: translate({ id: 'CLIENT_UNREGISTER' }),
            invisible: !registered,
        },
    ]), [
        getRoute,
        navigate,
        registerApplicationCallback,
        translate,
        unregisterApplicationCallback,
    ]);

    return {
        loading: !availableApps && !registeredApps && !availableAppsError && !registeredAppsError,
        availableApps: availableApps
            ?.filter(app => !registeredApps?.find(registered => registered.clientId === app.clientId))
                ?? [],
        registeredApps: filteredRegisteredApps,
        moreActions,
        updatedScopes: filteredRegisteredApps?.some(app => app.unRegisteredScopes?.length ?? 0 > 0) ?? false,
    };
};

export default useExternalApplicationsAppRegistrationsViewModel;
