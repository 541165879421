import type { IFolderTreeDTO } from './TreeComponentInterfaces';

export const hasFolderAsExpectedValue = (folders: IFolderTreeDTO[], expectedValue: boolean) => {
    const hasFolder = folders?.some(x => x.isChecked === expectedValue || hasFolderAsExpectedValue(x.children, expectedValue));
    return hasFolder;
};

export const resetTree = (tree: IFolderTreeDTO[]) => {
    tree?.forEach((node) => {
        node.isHidden = false;
        if (node.children.length > 0) {
            resetTree(node.children);
        }
    });
};

export const filterTreeByValue = (filtervalue: string, tree?: IFolderTreeDTO[]) => {
    filtervalue = filtervalue.toUpperCase();
    let foundVisible = false;

    tree?.forEach((childTree) => {
        const isVisible = childTree.DisplayName.toUpperCase().includes(filtervalue) ||
            (childTree.children.length > 0 && filterTreeByValue(filtervalue, childTree.children));

        childTree.isHidden = !isVisible;
        foundVisible = foundVisible || isVisible;
    });

    return foundVisible;
};
