import type {
    DeleteType,
    IUiDialogHookCustomContent,
} from '@experiences/interfaces';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { ApButton } from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';

const TenantTagsDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, deleteType, taggedObjectsCount,
}) => {
    const { formatMessage: translate } = useIntl();

    const warningContent = useMemo(() => {
        switch (deleteType as DeleteType) {
            case 'Label': {
                return <UiText>
                    {translate(
                        { id: 'CLIENT_TENANT_TAGS_DELETE_LABEL_WARNING' },
                        { number: taggedObjectsCount },
                    )}
                </UiText>;
            }
            case 'Property': {
                return <UiText>
                    {translate(
                        { id: 'CLIENT_TENANT_TAGS_DELETE_PROPERTY_WARNING' },
                        { number: taggedObjectsCount },
                    )}
                </UiText>;

            }
            case 'Value': {
                return <UiText>
                    {translate(
                        { id: 'CLIENT_TENANT_TAGS_DELETE_VALUE_WARNING' },
                    )}
                </UiText>;
            }
            default: {
                return <></>;
            }
        }
    }, [ taggedObjectsCount, translate, deleteType ]);

    return (
        <UiStack
            direction="column"
            gap={SpacingToken.M}>
            {warningContent}
            <UiStack
                gap={SpacingToken.XS}
                justify="end">
                <ApButton
                    data-cy="tenant-tags-dialog-cancel-button"
                    onClick={() => closeDialog(false)}
                    variant="tertiary"
                    label={translate({ id: 'CLIENT_CANCEL' })}
                />
                <UiDeleteButton
                    data-cy="tenant-tags-dialog-delete-button"
                    loading={false}
                    onClick={() => closeDialog(true)}
                />
            </UiStack>
        </UiStack>
    );
};

export default TenantTagsDeleteDialogBody;
