import type { Region } from '@experiences/constants';

import type { IServiceMetadata } from '../../interfaces/service';

export const getDefaultRegionForTenant = (availableServices: IServiceMetadata[] | undefined) => {
    let serviceWithRegion = availableServices?.find(service => service.id === 'orchestrator');
    if (serviceWithRegion) {
        return serviceWithRegion?.defaultRegion;
    }
    serviceWithRegion = availableServices?.find(service => !!service.defaultRegion);
    return serviceWithRegion?.defaultRegion;
};

export const getSupportedRegionForTenant = (availableServices: IServiceMetadata[] | undefined) => {
    const serviceWithRegion = availableServices?.find(service => service.id === 'orchestrator');
    if (serviceWithRegion) {
        return serviceWithRegion?.supportedRegions;
    }
    const supportedRegions: Region[] = [];
    const temp = availableServices?.reduce((acc, currentValue) => {
        if (!currentValue.supportedRegions) {
            return acc;
        }
        return acc.concat(currentValue.supportedRegions);
    }, supportedRegions);
    return [ ... new Set(temp) ];
};
