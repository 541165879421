import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    getFeatureFlagConfig,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import type { Row } from 'react-table';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { ISAMLRule } from '../../../common/interfaces/cis/saml';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import {
    deleteRule,
    getRules,
    ruleUrl,
    updateRule,
} from '../../../services/identity/RuleService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import { decodeSanitizedHtml } from '../../../util/DecodeSanitizedHtml';
import { UiGrid } from '../../common/UiGrid';
import type { IActionHeader } from '../../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../../common/UiGrid/grid';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';

const MAXIMUM_PROVISIONING_RULES_FALLBACK = 1000;

const SAMLProvisioningRulesComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const createDialog = useShowDialog();
    const maxProvisioningRules = getFeatureFlagConfig<number>(Features.SamlMaximumProvisioningRules.name)
        || MAXIMUM_PROVISIONING_RULES_FALLBACK;

    const {
        data: rules, isValidating: loading, error, mutate,
    } = useSWR(
        {
            url: ruleUrl,
            partitionGlobalId,
        },
        getRules,
    );

    const updateRuleStatus = useCallback(async (row: Row<ISAMLRule>, status: boolean) => {
        const payload = {
            ...row.original,
            ruleId: row.original.id,
            partitionGlobalId,
            enabled: status,
        };

        try {
            await updateRule(payload);
            createNotification(
                translate({
                    id: status
                        ? 'CLIENT_SAML_PROVISIONING_RULES_ENALBED_SUCCESSFULLY'
                        : 'CLIENT_SAML_PROVISIONING_RULES_DISABLED_SUCCESSFULLY',
                }),
                notificationType.SUCCESS
            );
            mutate();
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
    }, [ createNotification, getErrorMessage, mutate, partitionGlobalId, setErrorMessage, translate ]);

    const openDeleteDialog = useCallback(
        async (rows: Array<Row<ISAMLRule>> | Row<ISAMLRule>) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DELETE_DIALOG_TITLE' }),
                body: (
                    <UiText>
                        {translate(
                            { id: 'CLIENT_SAML_PROVISIONING_RULES_DELETE_DIALOG_BODY' },
                            {
                                ruleName: <b>
                                    {Array.isArray(rows)
                                        ? rows.map(row => row.original.name).join(', ')
                                        : rows.original.name}
                                </b>,
                            },
                        )}
                    </UiText>
                ),
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });

            if (proceed) {
                try {
                    if (!Array.isArray(rows)) {
                        await deleteRule(partitionGlobalId, rows.original.id!);
                    }
                    createNotification(
                        translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DELETED_SUCCESSFULLY' }),
                        notificationType.SUCCESS
                    );
                    mutate();
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                }
            }
        }, [ createDialog, createNotification, getErrorMessage, mutate, partitionGlobalId, setErrorMessage, translate ]);

    const actions: IActionHeader[] = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_ADD_RULE' }),
            icon: <AddIcon />,
            click: () => navigate(`${getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules)}/add`),
            disable: (rules?.length ?? 0) >= Number(maxProvisioningRules),
            toolTip: (rules?.length ?? 0) >= Number(maxProvisioningRules)
                ? translate({ id: 'CLIENT_SAML_JIT_PROVISIONING_RULES_MAXIMUM' }, { max: maxProvisioningRules })
                : '',
            invisible: !isAdmin,
            variant: 'contained',
            dataCy: 'ui-grid-add-provisioning-rule-button',
        },
    ], [ getRoute, isAdmin, maxProvisioningRules, navigate, rules?.length, translate ]);

    const mainContent = useMemo(() => (
        <UiGrid<ISAMLRule>
            dataCy="saml-provisioning-rules-ui-grid"
            search
            loading={loading}
            error={error}
            searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
            extraActionButtons={actions}
            pagination
            columns={[
                {
                    accessor: 'name',
                    sortName: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_NAME' }),
                    Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_NAME' }),
                    width: 40,
                    sortType: 'alphanumeric',
                    Cell: ({ row }) => <>
                        {decodeSanitizedHtml(row.original.name)}
                    </>,
                },
                {
                    accessor: 'description',
                    sortName: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DESCRIPTION' }),
                    Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DESCRIPTION' }),
                    width: 40,
                    sortType: 'alphanumeric',
                    Cell: ({ row }) => <>
                        {decodeSanitizedHtml(row.original.description)}
                    </>,
                },
                {
                    accessor: 'enabled',
                    sortName: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_STATUS' }),
                    Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_STATUS' }),
                    width: 20,
                    sortType: 'alphanumeric',
                    Cell: ({ row }) => <>
                        {translate({ id: row.original.enabled ? 'CLIENT_ENABLED' : 'CLIENT_DISABLED' })}
                    </>,
                },
            ]}
            data={rules ?? []}
            rowActions={
                isAdmin
                    ? [
                        {
                            type: ButtonType.Button,
                            label: translate({ id: 'CLIENT_EDIT' }),
                            tooltip: translate({ id: 'CLIENT_EDIT' }),
                            actionType: GridActionType.Row,
                            click: row => navigate(`${getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules)}/edit/${row.original.id}`),
                            dataCy: 'jit-rule-edit',
                        },
                        {
                            type: ButtonType.Button,
                            label: translate({ id: 'CLIENT_ENABLE' }),
                            tooltip: translate({ id: 'CLIENT_ENABLE' }),
                            actionType: GridActionType.Row,
                            invisible: row => row!.original.enabled,
                            click: row => updateRuleStatus(row, !row!.original.enabled),
                            dataCy: 'jit-rule-enable',
                        },
                        {
                            type: ButtonType.Button,
                            label: translate({ id: 'CLIENT_DISABLE' }),
                            tooltip: translate({ id: 'CLIENT_DISABLE' }),
                            actionType: GridActionType.Row,
                            invisible: row => !row!.original.enabled,
                            click: row => updateRuleStatus(row, !row!.original.enabled),
                            dataCy: 'jit-rule-disable',
                        },
                        {
                            type: ButtonType.Button,
                            label: translate({ id: 'CLIENT_DELETE' }),
                            tooltip: translate({ id: 'CLIENT_DELETE' }),
                            actionType: GridActionType.Row,
                            click: openDeleteDialog,
                            dataCy: 'jit-rule-delete',
                        },
                    ]
                    : []
            }
        />
    ), [ actions, error, getRoute, isAdmin, loading, navigate, openDeleteDialog, rules, translate, updateRuleStatus ]);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
        >
            {mainContent}
        </UiPageContainer>
    );
};

export const SAMLProvisioningRulesComponentWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() =>
        [
            {
                index: 0,
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                index: 1,
                link: RouteNames.SecuritySettings,
                name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
            },
            {
                index: 2,
                link: RouteNames.SecuritySettingsSAMLProvisioningRules,
                name: translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' }),
            },
        ],
    [ organizationName, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <SAMLProvisioningRulesComponent />
    </BreadcrumbProvider>;
};

export default SAMLProvisioningRulesComponent;
