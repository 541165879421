import { useLocalization } from '@experiences/locales';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { formatDate } from '@experiences/util';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { goToStripeCustomerPortalSession } from '../../services/licensing/BillingService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../store/selectors';
import { UiDrawer } from '../common/UiDrawer';
import UiForm from '../common/UiForm';
import useEcommerceMonthlyUpsellViewModel from './EcommerceMonthlyUpsellViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '28px',
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
        whatsNextTitle: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginTop: '32px',
            marginBottom: '8px',
        },
        whatsNextContentLine: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        cancelPlan: {
            marginTop: '16px',
            maxWidth: '120px',
        },
    }),
);

const EcommerceMonthlyUpsellComponent: React.FC = () => {
    const classes = useStyles();

    const accountId = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);
    const language = useLocalization();

    const { formatMessage: translate } = useIntl();

    const {
        licenseStartDate,
        hasActivationValidationError,
        shouldActivationButtonBeLoading,
        shouldActivationButtonBeDisabled,
        openDrawer,
        closeDrawer,
        methods: {
            control, handleSubmit,
        },
        hasFormErrors,
        formErrorMessage,
    } = useEcommerceMonthlyUpsellViewModel(
        accountName
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ACTIVATE_ENTERPRISE_LICENSE' })}
            drawerProps={{
                anchor: 'right',
                open: openDrawer,
                onClose: () => closeDrawer(),
            }}
        >
            <UiForm
                onSubmit={handleSubmit}
                actions={
                    <>
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => closeDrawer()}
                                color="primary">
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                loading={shouldActivationButtonBeLoading}
                                disabled={shouldActivationButtonBeDisabled}
                                type="submit"
                                variant="contained"
                                data-cy="monthly-upsell-activate-submit-button"
                            >
                                {translate({ id: 'CLIENT_ACTIVATE_ACTION' })}
                            </UiProgressButton>
                        </div>
                    </>
                }
                isDrawer
            >
                <UiText className={classes.subTitle}>
                    {translate({ id: 'CLIENT_ACCOUNT_ID' })}
                </UiText>
                <TextField
                    id="accountId"
                    disabled
                    value={accountId}
                    variant="outlined"
                    InputProps={{ className: 'Tall' }}
                    data-cy="monthly-upsell-account-id"
                />
                <UiText className={classes.subTitle}>
                    {translate({ id: 'CLIENT_LICENSE_CODE' })}
                </UiText>
                <Controller
                    control={control}
                    name="licenseCode"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="outlined"
                            error={hasFormErrors}
                            helperText={formErrorMessage}
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            inputProps={{ 'aria-labelledby': 'licenseCode' }}
                            data-cy="monthly-upsell-license-code"
                        />
                    )}
                />
                {hasActivationValidationError && (
                    <div data-cy="monthly-upsell-activation-validation-error-message">
                        <UiText className={classes.whatsNextTitle}>
                            {translate({ id: 'CLIENT_ECOMMERCE_MONTHLY_UPSELL_WHATS_NEXT_TITLE' })}
                        </UiText>
                        <UiText className={classes.whatsNextContentLine}>
                            <FormattedMessage
                                id="CLIENT_ECOMMERCE_MONTHLY_UPSELL_WHATS_NEXT_LINE_1"
                                values={{
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    b: (msg: React.ReactNode[]) => <b>
                                        {msg}
                                    </b>,
                                    licenseStartDate: formatDate(licenseStartDate, language),
                                }}
                            />
                        </UiText>
                        <br />
                        <UiText className={classes.whatsNextContentLine}>
                            <FormattedMessage
                                id="CLIENT_ECOMMERCE_MONTHLY_UPSELL_WHATS_NEXT_LINE_2"
                                values={{
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    b: (msg: React.ReactNode[]) => <b>
                                        {msg}
                                    </b>,
                                }}
                            />
                        </UiText>
                        <Button
                            variant="outlined"
                            onClick={() => goToStripeCustomerPortalSession(accountName)}
                            className={classes.cancelPlan}
                            data-cy="monthly-upsell-cancel-plan">
                            {translate({ id: 'CLIENT_ECOMMERCE_CANCEL_PLAN' })}
                        </Button>
                    </div>
                )}
            </UiForm>
        </UiDrawer>
    );
};

export default EcommerceMonthlyUpsellComponent;
