import {
    AccountLicense,
    getRegionTranslationId,
    Region,
    TranslatedRegionName,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import {
    getAvailableServices,
    tenantAvailableServicesUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    organizationRegion,
} from '../../../store/selectors';
import { getSupportedRegionForTenant } from '../subcomponents/helpers/TenantRegionHelper';
import type { ServiceErrorType } from '../subcomponents/helpers/TenantServiceErrorMessage';
import { useTenantsContext } from '../TenantsContextProvider';

const useTenantGeneralFormViewModel = (tenant?: ITenant, type?: 'add' | 'edit') => {
    // Redux state
    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgRegion = useSelector(organizationRegion);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    // SWR fetch
    const { data: availableServices } = useSWR(
        {
            url: tenantAvailableServicesUri,
            organizationGuid: partitionGlobalId,
            accountName,
        },
        getAvailableServices,
    );

    const isOnPrem = useIsOnPrem();

    // React-hook-form initialization
    const {
        register, control, formState, setValue, watch,
    } = useFormContext<ICreateEditTenantPayload>();

    const isCanaryChecked = watch('customProperties.isCanaryTenant');

    // Component state
    const { tenantsWithoutServices } = useTenantsContext();

    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });
    const isCanaryTenant = !!tenant?.isCanaryTenant;

    const canaryTenantExists = useMemo(
        () => tenantsWithoutServices.filter(tenantIterator => tenantIterator.isCanaryTenant).length > 0,
        [ tenantsWithoutServices ]);

    const isRegionEnabled = useMemo(
        () => !!process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ]);

    const DefaultRegions = useMemo(
        () => getSupportedRegionForTenant(availableServices),
        [ availableServices ]);

    const translatedRegion = useMemo(() =>
        Object.keys(TranslatedRegionName)
            .filter((key) => DefaultRegions?.includes(key as Region))
            .reduce((obj, key) => Object.assign(obj, { [key]: getRegionTranslationId(key) }), {})
    , [ DefaultRegions ]);

    const canaryTenantFormEnabled = useMemo(() =>
        AccountLicense[accountPlan] === AccountLicense.ENTERPRISE
        && orgRegion !== Region.GxPUnitedStates
        && (type === 'add' || isCanaryTenant)
        && !isOnPrem
        && !DisableFeatureFedRamp
    , [ DisableFeatureFedRamp, accountPlan, isCanaryTenant, isOnPrem, orgRegion, type ]);

    // Side-effects via useEffect
    useEffect(() => {
        if (isCanaryChecked) {
            setValue('region', 'Europe' as Region);
        }
    }, [ isCanaryChecked, setValue ]);

    return {
        tenantsWithoutServices,
        validationErrorHandlers,
        isCanaryTenant,
        isCanaryChecked: !!isCanaryChecked,
        canaryTenantExists,
        isRegionEnabled,
        DefaultRegions,
        translatedRegion,
        methods: {
            register,
            control,
            formState,
        },
        canaryTenantFormEnabled,
    };
};

export default useTenantGeneralFormViewModel;
