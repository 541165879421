import { isStringEmpty } from '@experiences/util';

import type { BulkInviteIpRangeData } from '../../../../common/interfaces/iprestriction';

const Address6 = require('ip-address').Address6;
const Address4 = require('ip-address').Address4;

export const validateIPv4Range = (rangeStart: string, rangeEnd: string) => {
    if (!Address4.isValid(rangeStart) || !Address4.isValid(rangeEnd)) {
        return false;
    }
    const start = rangeStart.split('.');
    const end = rangeEnd.split('.');
    const [ s1, s2, s3, s4 ] = start.map(i => parseInt(i));
    const [ e1, e2, e3, e4 ] = end.map(i => parseInt(i));
    return (
        ([ s1, s2, s3, s4 ].some(o => o > 255 || o < 0) ||
        [ e1, e2, e3, e4 ].some(o => o > 255 || o < 0)) ?
            false : // range contains invalid IP
            (s1 > e1 || s2 > e2 || s3 > e3 || s4 > e4) ?
                false : // range start is greater than range end
                true // range is valid
    );
};

export const validateIPv6Range = (rangeStart: string, rangeEnd: string) => {
    if (!Address6.isValid(rangeStart) || !Address6.isValid(rangeEnd)) {
        return false;
    }
    const startv6 = new Address6(rangeStart);
    const endv6 = new Address6(rangeEnd);
    let startIp = startv6.canonicalForm();
    let endIp = endv6.canonicalForm();
    startIp = startIp.replace(/:/g, '').toLowerCase();
    endIp = endIp.replace(/:/g, '').toLowerCase();
    return startIp <= endIp;
};

export const checkIPv4InRange = (currentIp: string, rangeStart: string, rangeEnd: string) => {
    if (!Address4.isValid(currentIp) || !Address4.isValid(rangeStart) || !Address4.isValid(rangeEnd)) {
        return false;
    }
    const current = currentIp.split('.');
    const start = rangeStart.split('.');
    const end = rangeEnd.split('.');
    const [ c1, c2, c3, c4 ] = current.map(i => parseInt(i));
    const [ s1, s2, s3, s4 ] = start.map(i => parseInt(i));
    const [ e1, e2, e3, e4 ] = end.map(i => parseInt(i));
    return (
        s1 <= c1 && c1 <= e1 &&
        s2 <= c2 && c2 <= e2 &&
        s3 <= c3 && c3 <= e3 &&
        s4 <= c4 && c4 <= e4
    );
};

export const checkIPv6InRange = (currentIp: string, rangeStart: string, rangeEnd: string) => {
    if (!Address6.isValid(currentIp) || !Address6.isValid(rangeStart) || !Address6.isValid(rangeEnd)) {
        return false;
    }
    const currentv6 = new Address6(currentIp);
    const startv6 = new Address6(rangeStart);
    const endv6 = new Address6(rangeEnd);
    let current = currentv6.canonicalForm();
    let startIp = startv6.canonicalForm();
    let endIp = endv6.canonicalForm();
    current = current.replace(/:/g, '').toLowerCase();
    startIp = startIp.replace(/:/g, '').toLowerCase();
    endIp = endIp.replace(/:/g, '').toLowerCase();
    return startIp <= current && current <= endIp;
};

export const isCIDR = (currentIp: string) => {
    const trimmed = currentIp.trim();
    if (!trimmed.includes('/')) {
        return false;
    }
    if (Address4.isValid(trimmed)) {
        return true;
    }
    if (Address6.isValid(trimmed)) {
        return true;
    }
    return false;
};

export const validateIp = (currentIp: string, ipRanges: string[]) => {
    if (Address4.isValid(currentIp)) {
        const current = new Address4(currentIp);
        for (const ipRange of ipRanges) {
            if (Address4.isValid(ipRange)) {
                const address = new Address4(ipRange);
                if (current.isInSubnet(address)) {
                    return true;
                }
            }

        }
    } else if (Address6.isValid(currentIp)) {
        const current = new Address6(currentIp);
        for (const ipRange of ipRanges) {
            if (Address6.isValid(ipRange)) {
                const address = new Address6(ipRange);
                if (current.isInSubnet(address)) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const parseLine = (line: string, ipList: string[]) => {
    const errors = [];

    // check for invalid CIDR format
    if (!line || isStringEmpty(line) || !isCIDR(line)) {
        errors.push('CLIENT_IP_RESTRICTION_CIDR_ERROR');
    }

    // check for duplicate addresses
    if (ipList.includes(line)) {
        errors.push('CLIENT_IP_RESTRICTION_DUPLICATE_ERROR');
    }

    return {
        ip: line,
        errors,
    };
};

export const parseFile = async (file: File, ipList: string[]) => new Promise<BulkInviteIpRangeData[] | undefined>(resolve => {
    const reader = new FileReader();

    reader.onload = event => {
        const content = event.target?.result as string;
        const allLines = content?.split(/\r\n|\n/).filter(line => !!line.trim());
        const ipRanges: BulkInviteIpRangeData[] = [];
        allLines.forEach((line: string, index: number) => {
            if (index !== 0 || !line.toLowerCase().includes('ip range')) {
                ipRanges.push(parseLine(line, ipList));
            }
        });

        resolve(ipRanges);
    };

    reader.onerror = () => {
        reader.abort();
        resolve(undefined);
    };

    reader.readAsText(file);
});
