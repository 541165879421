import TextField from '@mui/material/TextField';
import { ApProgressSpinner } from '@uipath/portal-shell-react';
import type {
    FC,
    ForwardedRef,
} from 'react';
import React, { Suspense } from 'react';

import type { ICustomEditorProps } from './subcomponents/CustomEditor';

const CustomEditor = React.lazy(() => import('./subcomponents/CustomEditor'));

const CustomEditorWrapper = React.forwardRef((props, ref: ForwardedRef<any>) => (
    <Suspense fallback={<ApProgressSpinner />}>
        <CustomEditor
            ref={ref}
            {...props} />
    </Suspense>
));

export const UiCodeEditor: FC<ICustomEditorProps> = ({
    value, onValueChange, dataCy,
}) => (
    <TextField
        variant="outlined"
        fullWidth
        InputProps={{
            inputComponent: CustomEditorWrapper as any,
            inputProps: {
                dataCy,
                value,
                onValueChange,
            },
        }}
    />
);
