import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useEcommerce } from '../helpers/useEcommerce';
import type { ISubscriptionForm } from '../interfaces/ecommerce';

const EcommerceZipCode: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const { formatMessage: translate } = useIntl();
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const {
        country: currentCountry,
        zipcode: currentZipCode,
    } = watch();

    const { setEcommerceZipCodeValue } = useEcommerce();

    const [ debouncedZipCode ] = useDebounce(currentZipCode, 1000);
    useEffect(() => {
        if (debouncedZipCode) {
            setEcommerceZipCodeValue(debouncedZipCode);
        }
    }, [ setEcommerceZipCodeValue, errors.zipcode, debouncedZipCode ]);

    return (
        <Controller
            name="zipcode"
            rules={{
                required: true,
                minLength: 5,
                maxLength: 8,
            }}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    variant="outlined"
                    required
                    id="zipcode"
                    label={translate({ id: 'CLIENT_ZIP_CODE' })}
                    error={!!errors.zipcode}
                    helperText={
                        (errors.zipcode?.type === 'required' && translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_ZIP_CODE' }) },
                        )) ||
                        ((errors.zipcode?.type === 'taxError' ||
                            errors.zipcode?.type === 'maxLength' ||
                            errors.zipcode?.type === 'minLength') &&
                            translate({ id: 'CLIENT_BAD_ZIP_CODE' })) ||
                        (errors.zipcode?.type === 'addressError' &&
                            translate({ id: 'CLIENT_MAKE_SURE_THE_ZIP_CODE_MATCHES_BILLING_ADDRESS' })) ||
                        (errors.zipcode?.type === 'avalaraError' &&
                            translate({ id: 'CLIENT_UNABLE_TO_CALCULATE_TAX_AVALARA_IS_DOWN' }))
                    }
                    InputProps={{ className: 'Tall' }}
                    data-cy="ecommerce-form-zipcode"
                    disabled={disabled}
                    fullWidth={currentCountry === 'US' || currentCountry === 'CA' || currentCountry === 'JP'}
                />
            )}

        />
    );
};

export default EcommerceZipCode;
