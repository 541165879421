import { UiSelect } from '@experiences/ui-common';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import {
    useFieldArray,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { ISAMLProvisioningCondition } from '../../../common/interfaces/cis/saml';
import { SAMLConditionType } from '../../../common/interfaces/cis/saml';

const useStyles = makeStyles(theme =>
    createStyles({
        condition: {
            width: '100%',
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        labelText: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputControl: {
            marginBottom: '16px',
            marginRight: '16px',
            minWidth: '230px',
        },
        deleteButton: { marginTop: '32px' },
    }),
);

const samlConditionTypeOptions = {
    [SAMLConditionType.Is]: 'CLIENT_SAML_PROVISIONING_CONDITION_TYPE_IS',
    [SAMLConditionType.IsNot]: 'CLIENT_SAML_PROVISIONING_CONDITION_TYPE_IS_NOT',
    [SAMLConditionType.Contains]: 'CLIENT_SAML_PROVISIONING_CONDITION_TYPE_CONTAINS',
    [SAMLConditionType.NotContains]: 'CLIENT_SAML_PROVISIONING_CONDITION_TYPE_NOT_CONTAINS',
    [SAMLConditionType.IsCaseInsensitive]: 'CLIENT_SAML_PROVISIONING_CONDITION_TYPE_IS_CASE_INSENSITIVE',
    [SAMLConditionType.ContainsCaseInsensitive]: 'CLIENT_SAML_PROVISIONING_CONDITION_TYPE_CONTAINS_CASE_INSENSITIVE',
};

const SAMLProvisioningConditionGenerator: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        control, formState: { errors }, register,
    } = useFormContext<{ conditions: ISAMLProvisioningCondition[] }>();

    const {
        fields, append, remove,
    } = useFieldArray({
        control,
        name: 'conditions',
    });

    const addCondition = useCallback(() => {
        append({
            ClaimName: '',
            ConditionType: SAMLConditionType.Is,
            Value: '',
        });
    }, [ append ]);

    const removeCondition = useCallback(
        (index: number) => {
            remove(index);
        },
        [ remove ],
    );

    return (
        <>
            {fields.map((field, i) => (
                <li
                    key={field.id}
                    className={classes.condition}>
                    <div className={classes.inputControl}>
                        <InputLabel className={classes.labelText}>
                            {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_CLAIM' })}
                        </InputLabel>
                        <TextField
                            inputProps={register(`conditions.${i}.ClaimName`, { required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                            placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_CLAIM_PLACEHOLDER' })}
                            defaultValue={field.ClaimName}
                            variant="outlined"
                            fullWidth
                            error={!!errors.conditions?.[i]?.ClaimName}
                            helperText={errors.conditions?.[i]?.ClaimName?.message}
                            aria-label={field.ClaimName || translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_CLAIM_PLACEHOLDER' })}
                            data-cy="saml-condition-claim"
                        />
                    </div>
                    <div className={classes.inputControl}>
                        <UiSelect
                            control={control}
                            name={`conditions.${i}.ConditionType`}
                            inputLabel={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RELATIONSHIP' })}
                            placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RELATIONSHIP_PLACEHOLDER' })}
                            options={samlConditionTypeOptions}
                            defaultValue={field.ConditionType}
                            fullWidth
                            required
                            error={!!errors.conditions?.[i]?.ConditionType}
                            helperText={errors.conditions?.[i]?.ConditionType?.message}
                            dataCy="ui-select-condition-type"
                        />
                    </div>
                    <div className={classes.inputControl}>
                        <InputLabel className={classes.labelText}>
                            {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_VALUE' })}
                        </InputLabel>
                        <TextField
                            inputProps={register(`conditions.${i}.Value`, { required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                            placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_VALUE_PLACEHOLDER' })}
                            defaultValue={field.Value}
                            variant="outlined"
                            fullWidth
                            error={!!errors.conditions?.[i]?.Value}
                            helperText={errors.conditions?.[i]?.Value?.message}
                            aria-label={field.ClaimName || translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_VALUE_PLACEHOLDER' })}
                            data-cy="saml-condition-value"
                        />
                    </div>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => removeCondition(i)}
                        data-cy="saml-condition-remove">
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                </li>
            ))}
            <Button
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => addCondition()}
                data-cy="add-rule-condition-button">
                {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_ADD_RULE_BUTTON' })}
            </Button>
        </>
    );
};

export default SAMLProvisioningConditionGenerator;
