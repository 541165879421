import { useCentralErrorSetter } from '@experiences/error';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IGoogleConfigData } from '../../../common/interfaces/externalIdentity';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultEditIdentityProviderData,
    mapExternalIdentityPayloadToGoogleConfigData,
    mapGoogleConfigDataToExternalIdentityPayload,
} from '../../../util/ExternalIdentityProviderUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import EditClientIdAndSecretFormComponent, { defaultEditClientIdAndSecretData } from './EditClientIdAndSecretFormComponent';
import EditIdentityProviderFormComponent from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(() =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const ConfigureGoogleComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);

    const { close } = useModalState(RouteNames.SecuritySettings);

    const classes = useStyles();
    const setErrorDialog = useCentralErrorSetter();
    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const [ showClientSecretPlaceholder, setShowClientSecretPlaceholder ] = useState(false);
    const methods = useForm<IGoogleConfigData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditIdentityProviderData,
            ...defaultEditClientIdAndSecretData,
        },
    });

    const {
        handleSubmit, reset, formState: {
            isDirty, isSubmitting,
        },
    } = methods;

    const fetchedExternalIdentity = useExternalIdentity(ExternalAuthenticationScheme.Google);

    const onSubmit = useCallback(
        async (data: IGoogleConfigData) => {
            const payload = mapGoogleConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            try {
                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_IDP_SAVED_SUCCESSFULLY' }, { idp: 'Google' }),
                    body: <UiText>
                        {translate({ id: 'CLIENT_SUCCESSFULLY_SAVED' }, { idp: 'Google' })}
                    </UiText>,
                    icon: 'info',
                    showCancel: true,
                    primaryButtonText: translate({ id: 'CLIENT_ENABLE_NOW' }),
                    cancelButtonText: translate({ id: 'CLIENT_ENABLE_LATER' }),
                });
                payload.isActive = proceed;
                if (fetchedExternalIdentity) {
                    await updateExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });
                } else {
                    await createExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });
                }
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }), notificationType.SUCCESS);
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_GOOGLE_GENERIC_ERROR' }));
            }
        },
        [
            fetchedExternalIdentity,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            createDialog,
            partitionGlobalId,
            setErrorDialog,
        ],
    );

    useEffect(() => {
        if (fetchedExternalIdentity) {
            reset(mapExternalIdentityPayloadToGoogleConfigData(fetchedExternalIdentity));
            setShowClientSecretPlaceholder(true);
        }
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_GOOGLE_SSO_CONFIGURATION' }),
        },
    ], [ organizationName, translate ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            maxWidth="900px"
            position='center'>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="configure-google-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                centerChild
            >
                <FormProvider {...methods}>
                    <EditIdentityProviderFormComponent />
                    <EditClientIdAndSecretFormComponent
                        showClientSecretPlaceholder={showClientSecretPlaceholder}
                        clientSecretRequired
                    />
                </FormProvider>
            </UiForm>
        </UiPageContainer>
    );
};

export default ConfigureGoogleComponent;
