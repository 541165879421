import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';

import store from '../store';

async function resolvePromise(promiseObject: any) {
    let resolvedPromise = promiseObject;
    if (promiseObject instanceof Promise) {
        resolvedPromise = await promiseObject;
    }
    return resolvedPromise;
}

export default async function createAndPushPromiseLog(
    logType: 'error' | 'info',
    errorPromiseObject: any,
    message: string,
) {
    let accountId;
    const error = await resolvePromise(errorPromiseObject);
    if (store.getState().profile?.userGlobalId) {
        accountId = store.getState().profile.userGlobalId;
    }

    portalTelemetry.trackTrace(
        {
            message,
            severityLevel: logType === 'error' ? SeverityLevel.Major : SeverityLevel.Info,
            properties: {
                accountId,
                error,
            },
        },
    );
}
