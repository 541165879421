import { useRouteResolver } from '@experiences/util';

import * as RouteNames from '../../common/constants/RouteNames';

export const useAdminNavigationRoute = () => {
    const getRoute = useRouteResolver();

    const route = getRoute(RouteNames.OrganizationAdminHome);

    return route;
};
