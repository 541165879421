import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSWRConfig } from 'swr';

import { OrganizationAdminHome } from '../../../common/constants/RouteNames';
import {
    deleteOnPremOrganization,
    onPremOrganizationManagementUri,
} from '../../../services/organization/OnPremOrganizationService';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteOrgConfirmationDialog: {
            width: '465px',
            maxHeight: '408px',
        },
        orgDeleteName: { color: theme.palette.semantic.colorErrorText },
        deleteButton: {
            color: 'white',
            marginLeft: '0.5em',
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        confirmDeleteSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
    }),
);

const OnPremOrganizationDeleteDialogBody: FC<IUiDialogHookCustomContent> = ({
    closeDialog, orgName, orgId,
}) => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const {
        handleSubmit, formState: { isSubmitting }, watch, register,
    } = useForm<{ confirmText: string }>({
        mode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: { confirmText: '' },
        shouldUnregister: false,
    });

    const { mutate } = useSWRConfig();

    const onSubmit = useCallback(async () => {
        try {
            await deleteOnPremOrganization(orgId);
            await mutate({ url: `${onPremOrganizationManagementUri}/getAllOrganizations` });
            navigate(getRoute(OrganizationAdminHome));
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
        closeDialog(true);
    }, [ closeDialog, orgId, mutate, navigate, getRoute, setErrorMessage, getErrorMessage ]);

    return (
        <form
            className={classes.deleteOrgConfirmationDialog}
            data-cy="delete-org-confirmation-dialog"
            onSubmit={handleSubmit(onSubmit)}
        >
            <UiText>
                {translate({
                    id: process.buildConfigs.orgDeleteMessageForCloud
                        ? 'CLIENT_DELETE_ON_PREM_ORGANIZATION_DETAILS'
                        : 'CLIENT_DELETE_ON_PREM_ORGANIZATION_DETAILS_K8S',
                })}
            </UiText>
            <div className={classes.confirmDeleteSection}>
                <UiText>
                    {translate(
                        { id: 'CLIENT_DELETE_ORGANIZATION_CONFIRM_TEXT' },
                        {
                            0: <strong className={classes.orgDeleteName}>
                                {orgName}
                            </strong>,
                        },
                    )}
                </UiText>
                <TextField
                    className={classes.textField}
                    inputProps={register('confirmText')}
                    autoComplete="off"
                    variant="outlined"
                    id="standard-name"
                    data-cy="delete-organization-confirmation-textfield"
                />
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    key="cancelButton"
                    color="primary"
                    data-cy="delete-organization-confirmation-cancel-button"
                    onClick={() => closeDialog(false)}
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    type="submit"
                    color="inherit"
                    classes={{ colorInherit: classes.deleteButton }}
                    loading={isSubmitting}
                    key="primaryButton"
                    variant="contained"
                    data-cy="delete-organization-confirmation-delete-button"
                    disabled={watch('confirmText') !== orgName}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiProgressButton>
            </div>
        </form>
    );
};

export default OnPremOrganizationDeleteDialogBody;
