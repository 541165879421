import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { isValidDomain } from '../../../util/DomainUtil';
import validateEmail from '../../../util/validators/EmailValidator';
import UiForm from '../../common/UiForm';
import useOrganizationEmailSettingsRevampViewModel from '../email/OrganizationEmailSettingsRevampViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        emailSettingsPage: {
            width: '100%',
            height: 'calc(100% - 40px)',
        },
        accordionContentDivided: {
            padding: '21px 16px 0px 0px',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '52px',
        },
        section: { marginBottom: '14px' },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        leftColumn: {
            flex: 1.5,
            minWidth: '200px',
            width: '900px',
        },
        rightColumn: { flex: 1 },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        outlinedInput: {
            display: 'flex',
            marginTop: '8px',
        },
        outlinedInputWrapper: { marginBottom: '20px' },
        indentedSection: { marginLeft: '8px' },
    }),
);

const EmailSettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        methods: {
            control,
            watch,
            handleSubmit,
            formState: {
                isDirty, isValid, isSubmitting, errors,
            },
        },
        onSubmit,
        handleTestEmailSettings,
        handleCancel,
    } = useOrganizationEmailSettingsRevampViewModel();

    return (
        <div
            className={classes.emailSettingsPage}
            data-cy="email-settings-page">
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        {isDirty && (
                            <Button
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}
                                color="primary"
                                data-cy="email-settings-cancel-button"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                        )}
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty || !isValid}
                            variant="contained"
                            data-cy="email-settings-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
            >
                <div className={classes.accordionContentDivided}>
                    <div className={classes.leftColumn}>
                        <div className={classes.section}>
                            <UiText
                                className={classes.subHeading}
                                role="heading"
                                aria-level={2}>
                                {translate({ id: 'CLIENT_SMTP_EMAIL_SETUP' })}
                            </UiText>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="host"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_HOST' })}
                                            variant="outlined"
                                            autoComplete="off"
                                            fullWidth
                                            InputProps={{ className: 'Tall' }}
                                            InputLabelProps={{ id: 'smtpHostLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpHostLabel' }}
                                            error={!!errors.host}
                                            helperText={
                                                errors.host?.type === 'required' &&
                                                translate(
                                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                    { 0: translate({ id: 'CLIENT_SMTP_HOST' }) },
                                                )
                                            }
                                            data-cy="email-settings-host-input"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="domain"
                                    rules={{ validate: { validDomain: value => !value || isValidDomain(value) } }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_DOMAIN' })}
                                            variant="outlined"
                                            autoComplete="off"
                                            fullWidth
                                            InputProps={{ className: 'Tall' }}
                                            InputLabelProps={{ id: 'smtpDomainLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpDomainLabel' }}
                                            error={!!errors.domain}
                                            helperText={
                                                (errors.domain?.type === 'validDomain' && translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' })) ||
                                                (errors.domain?.type === 'required' && translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' }))
                                            }
                                            data-cy="email-settings-domain-input"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="port"
                                    rules={{
                                        required: true,
                                        min: 1,
                                        max: 65535,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_PORT' })}
                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{ id: 'smtpPortLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpPortLabel' }}
                                            error={!!errors.port}
                                            helperText={
                                                errors.port?.type === 'required' &&
                                                translate(
                                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                    { 0: translate({ id: 'CLIENT_SMTP_PORT' }) },
                                                )
                                            }
                                            data-cy="email-settings-port-input"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="userName"
                                    rules={{ required: !watch('useDefaultCredentials') }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_USERNAME' })}
                                            variant="outlined"
                                            InputLabelProps={{ id: 'smtpUsernameLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpUsernameLabel' }}
                                            error={!!errors.userName}
                                            helperText={errors.userName?.type === 'required' && translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' })}
                                            className={classes.outlinedInput}
                                            data-cy="email-settings-username-input"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{ required: !watch('useDefaultCredentials') }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_PASSWORD' })}
                                            variant="outlined"
                                            type="password"
                                            autoComplete="new-password"
                                            fullWidth
                                            InputProps={{ className: 'Tall' }}
                                            InputLabelProps={{ id: 'smtpPasswordLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpPasswordLabel' }}
                                            error={!!errors.password}
                                            helperText={errors.password?.type === 'required' && translate({ id: 'CLIENT_PASSWORD_MUST_BE_UPDATED_ERROR' })}
                                            className={classes.outlinedInput}
                                            data-cy="email-settings-password-input"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="fromAddress"
                                    rules={{
                                        required: true,
                                        validate: { valid: value => !value || validateEmail(value) },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_FROM_ADDRESS' })}
                                            variant="outlined"
                                            type="email"
                                            autoComplete="off"
                                            fullWidth
                                            InputProps={{ className: 'Tall' }}
                                            InputLabelProps={{ id: 'smtpFromAddressLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpFromAddressLabel' }}
                                            error={!!errors.fromAddress}
                                            helperText={
                                                (errors.fromAddress?.type === 'required' &&
                                                    translate(
                                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                        { 0: translate({ id: 'CLIENT_SMTP_FROM_ADDRESS' }) },
                                                    )) ||
                                                (errors.fromAddress?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' }))
                                            }
                                            className={classes.outlinedInput}
                                            data-cy="email-settings-from-email-input"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    control={control}
                                    name="fromDisplayName"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={translate({ id: 'CLIENT_SMTP_FROM_DISPLAY_NAME' })}
                                            variant="outlined"
                                            InputLabelProps={{ id: 'smtpFromDisplayNameLabel' }}
                                            inputProps={{ 'aria-labelledby': 'smtpFromDisplayNameLabel' }}
                                            error={!!errors.fromDisplayName}
                                            className={classes.outlinedInput}
                                            data-cy="email-settings-from-display-name-input"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <Button
                            data-cy="email-settings-test-button"
                            variant="outlined"
                            onClick={handleSubmit(handleTestEmailSettings)}
                            disabled={
                                !watch('useDefaultCredentials') && (errors.password?.type === 'required' || errors.userName?.type === 'required')
                            }
                        >
                            {translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS' })}
                        </Button>
                    </div>
                    <div className={classes.rightColumn}>
                        <div className={classes.section}>
                            <UiText className={classes.subHeading}>
                                {translate({ id: 'CLIENT_SMTP_EMAIL_PREFERENCE' })}
                            </UiText>
                            <Controller
                                name="enableSsl"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                            <Switch
                                                className={classes.indentedSection}
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                                color="primary"
                                                data-cy="email-settings-enable-ssl-switch"
                                            />
                                        }
                                        label={
                                            <div className={classes.switchLabel}>
                                                {translate({ id: 'CLIENT_SMTP_ENABLE_SSL' })}
                                            </div>
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="useDefaultCredentials"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                            <Switch
                                                className={classes.indentedSection}
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                                color="primary"
                                                data-cy="email-settings-use-default-credentials-switch"
                                            />
                                        }
                                        label={
                                            <div className={classes.switchLabel}>
                                                {translate({ id: 'CLIENT_SMTP_USE_DEFAULT_CREDENTIALS' })}
                                            </div>
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </UiForm>
        </div>
    );
};

export default EmailSettingsComponent;
