import {
    AccountLicense,
    Region,
} from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { produce } from 'immer';
import isString from 'lodash/isString';
import xor from 'lodash/xor';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import { getTenantServiceLicenses } from '../../../services/licensing/LicenseManagementService';
import {
    getAvailableServices,
    getTenantById,
    tenantAvailableServicesUri,
    tenantByIdUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { ICreateEditTenantForm } from '../create/TenantCreateViewModel';
import type {
    IProductRequested,
    IServiceRegionMap,
} from '../interfaces/service';
import { ConfigureTenantLicensesFormComponent } from '../subcomponents/ConfigureTenantLicenseComponent';
import CreateEditTenantFormComponent from '../subcomponents/CreateEditTenantFormComponent';
import { addHiddenDependenciesHelper } from '../subcomponents/helpers/AddHiddenDependenciesHelper';
import { getServiceFromProduct } from '../subcomponents/helpers/ManageLicensesHelper';
import { getHiddenDependencyParent } from '../subcomponents/helpers/ServiceDependencyGraph';
import type { ServiceErrorType } from '../subcomponents/helpers/TenantServiceErrorMessage';
import { TenantStatusConstants } from '../TenantConstants';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        errorMessage: { alignSelf: 'center' },
        spacer: { marginTop: '12px' },
        button: { width: '100px' },
        cancelButton: { marginRight: '10px' },
    }),
);

export const TenantServicesAddComponent: React.FC = () => {
    const classes = useStyles();
    const tenantOperations = useTenantOperations();

    const { formatMessage: translate } = useIntl();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountPlan = useSelector(accountType);

    const [ skip, setSkip ] = useState(false);
    const [ activeStep, setActiveStep ] = useState(0);
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);
    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });

    const { tenantId } = useParams() as { tenantId: string };

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] < AccountLicense.COMMUNITY),
        [ accountPlan ],
    );

    const {
        open, close,
    } = useModalState(RouteNames.TenantServices.replace(':tenantId', tenantId));

    const {
        data: tenant, mutate,
    } = useSWR(
        {
            url: tenantByIdUri,
            id: tenantId,
        },
        getTenantById,
    );
    const {
        data: availableServices, isLoading,
    } = useSWR(
        tenant ? {
            url: tenantAvailableServicesUri,
            organizationGuid: partitionGlobalId,
            accountName,
            isCanaryTenant: tenant.isCanaryTenant,
        } : null,
        getAvailableServices,
    );

    const methods = useForm<ICreateEditTenantForm>({
        mode: 'onSubmit',
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: {
            name: tenant?.name ?? '',
            color: tenant?.color ?? '',
            region: (tenant?.region as Region) ?? '',
            services: [],
            customProperties: {
                isCanaryTenant: false,
                allocatedProductLicenses: {},
            },
        },
    });

    const {
        handleSubmit, formState, watch,
    } = methods;

    const {
        isDirty, isSubmitting,
    } = formState;

    const {
        services, servicesAltRegion,
    } = useMemo(() => {
        const tenantServiceInstances = tenant?.tenantServiceInstances ?? [];
        const tenantServiceInstancesAlt = tenantServiceInstances
            .filter(service => service.region !== tenant?.region)
            .reduce((map, service) => {
                map[service.serviceType] = service.region;
                return map;
            }, {} as IServiceRegionMap);
        return {
            services: tenantServiceInstances,
            servicesAltRegion: tenantServiceInstancesAlt,
        };
    }, [ tenant?.region, tenant?.tenantServiceInstances ]);

    const watchServices = watch('services');

    const checkedServices = useMemo(() => watchServices?.filter(checkedService => !services.map(serviceIterator =>
        serviceIterator.serviceType).includes(checkedService)),
    [ services, watchServices ]);

    const { data: tenantServiceLicenseData } = useSWR(
        tenantId && availableServices
            ? {
                url: `/api/manageLicense/${partitionGlobalId}/service-licenses/${tenantId}`,
                accountId: partitionGlobalId,
                tenantId,
                services: availableServices.map(service => service.id),
            }
            : null,
        getTenantServiceLicenses,
    );

    const tenantHasServiceLicenses = useCallback((service: string) => {
        const tenantServiceLicensesList = tenantServiceLicenseData?.filter(
            tenantServiceLicense => tenantServiceLicense.serviceType === service
            && !process.buildConfigs.unlicensedServices?.includes(tenantServiceLicense.serviceType));
        return !!tenantServiceLicensesList?.some(serviceLicense => serviceLicense.products.length > 0);
    }, [ tenantServiceLicenseData ]);

    const onSubmit = useCallback(
        async (data: ICreateEditTenantForm) => {
            data.name = tenant!.name;

            try {
                // send only changed services
                let changedServices = xor(
                    services.map(service => service.serviceType),
                    data.services,
                );
                changedServices = addHiddenDependenciesHelper(changedServices, availableServices);

                if (activeStep === 0 && changedServices.some(service => tenantHasServiceLicenses(service))) {
                    setActiveStep(prevStep => prevStep + 1);
                    return;
                }

                const changedServicesObj = Object.assign(
                    {},
                    ...changedServices.map(s => {
                        const implicitParent = getHiddenDependencyParent(s);
                        return {
                            [s]: data.services.indexOf(s) > -1
                                || (!!implicitParent && data.services.indexOf(implicitParent) > -1),
                        };
                    }),
                );
                const {
                    customProperties, ...payload
                } = data;

                // TODO: Fix any type - Portal Server expects CustomProperties, client forms use customProperties.
                const editPayload: any = {
                    ...payload,
                    CustomProperties: {
                        isCanaryTenant: false,
                        allocatedProductLicenses: {},
                    },
                };
                if (!skip && customProperties?.allocatedProductLicenses) {
                    const serviceProductMap: { [service: string]: IProductRequested[] } = {};
                    Object.entries(customProperties.allocatedProductLicenses).forEach(([ product, quantity ]) => {
                        const service = getServiceFromProduct(product);
                        if (!service) {
                            return;
                        }
                        if (!serviceProductMap[service]) {
                            serviceProductMap[service] = [];
                        }

                        serviceProductMap[service].push({
                            code: product,
                            quantity: typeof quantity === 'string' ? parseInt(quantity) : (quantity ?? 0),
                        });
                    });
                    editPayload.CustomProperties.allocatedProductLicenses = serviceProductMap;
                }

                mutate(
                    async () => {
                        await tenantOperations.tenantEdit(editPayload, tenant!, changedServicesObj);
                        return produce(tenant, (draftState: ITenant | undefined) => {
                            draftState!.status = TenantStatusConstants.UPDATING;
                            Object.entries(changedServicesObj).forEach(([ service, _added ]) => {
                                const foundService = availableServices?.find(serviceIterator => serviceIterator.id === service);
                                draftState?.tenantServiceInstances.push({
                                    friendlyUrl: '',
                                    id: '',
                                    region: foundService?.defaultRegion || tenant?.region || Region.None,
                                    serviceType: service,
                                    status: TenantStatusConstants.UPDATING,
                                    url: '',
                                    isVisible: foundService?.isVisible ?? true,
                                });
                            });
                        });
                    },
                    { revalidate: false },
                );

                close();
            } catch (error) {
                const errorObject = await getErrorObject(error);

                if (errorObject.response?.status === 504) {
                    setErrorMessage(translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' }));
                } else {
                    const errorData = errorObject.response?.data;
                    const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                    setErrorMessage(errorResponse);
                }
            }
        },
        [
            tenant,
            services,
            availableServices,
            activeStep,
            skip,
            mutate,
            close,
            tenantHasServiceLicenses,
            tenantOperations,
            getErrorObject,
            translate,
            getErrorMessage,
        ],
    );

    const tenantServiceLicenses = useMemo (() => tenantServiceLicenseData?.filter(serviceLicense =>
        checkedServices?.includes(serviceLicense.serviceType)), [ checkedServices, tenantServiceLicenseData ]);

    return (
        <UiDrawer
            title={translate({ id: activeStep === 0 ? 'CLIENT_ADD_SERVICES' : 'CLIENT_ALLOCATE_LICENSES_CONDITIONAL' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
            width="medium"
            loading={isLoading}
        >
            <>
                {!!errorMessage && (
                    <div
                        data-cy="add-edit-tenant-error"
                        className={clsx(classes.errorMessage, classes.spacer)}>
                        <UiText>
                            {errorMessage}
                        </UiText>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setErrorMessage('');
                            }}
                            className={classes.spacer}
                            data-cy="add-edit-tenant-retry"
                        >
                            {translate({ id: 'CLIENT_RETRY' })}
                        </Button>
                    </div>
                )}
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={clsx(classes.cancelButton, classes.button)}
                                onClick={() => close()}
                                color="primary"
                                data-cy="add-edit-tenant-discard"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            {activeStep === 1 && <Button
                                className={clsx(classes.cancelButton, classes.button)}
                                onClick={() => {
                                    setSkip(true);
                                    onSubmit(watch());
                                }}
                                color="primary"
                                data-cy="add-edit-tenant-skip">
                                {translate({ id: 'CLIENT_SKIP' })}
                            </Button>}
                            <UiProgressButton
                                innerButtonClass={classes.button}
                                disabled={!isDirty || checkedServices.length <= 0}
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                                data-cy="tenant-services-add-button"
                            >
                                {translate({ id: 'CLIENT_ADD' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                    addScrollPadding
                    heightOffset="12px">
                    <FormProvider {...methods}>
                        {activeStep === 0 && <CreateEditTenantFormComponent
                            type='edit'
                            services={services}
                            isRegionEnabled={isRegionEnabled}
                            validationErrorHandler={validationErrorHandlers}
                            availableServices={availableServices}
                            defaultRegion={tenant?.region}
                            servicesAltRegion={servicesAltRegion} />}
                        {activeStep === 1 && <ConfigureTenantLicensesFormComponent
                            name="customProperties.allocatedProductLicenses"
                            type="addLicenses"
                            tenantServiceLicenses={tenantServiceLicenses} />}
                    </FormProvider>
                </UiForm>
            </>
        </UiDrawer>
    );
};

export default TenantServicesAddComponent;
