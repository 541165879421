import { useFirstRender } from '@experiences/util';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect } from 'react';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles(theme =>
    createStyles({
        searchBox: {
            backgroundColor: theme.palette.semantic.colorBackground,
            marginRight: '16px',
        },
        searchIcon: {
            marginRight: '4px',
            color: theme.palette.semantic.colorIconDefault,
        },
    }),
);

interface IGridSearchProps {
    query: string;
    setGlobalFilter: (_: any) => any;
    placeholder?: string;
    onSearch?: (query?: string) => any;
    SearchComponent?: JSX.Element;
    ariaLabel?: string;
}

export const UiGridSearchComponent = ({
    placeholder,
    ariaLabel,
    query,
    setGlobalFilter,
    onSearch,
    SearchComponent,
}: IGridSearchProps) => {
    const classes = useStyles();

    const isFirstRender = useFirstRender();
    const [ debouncedSearchTerm ] = useDebounce(query, 500);

    useEffect(() => {
    // required to avoid calling onSearch on first render
        if (isFirstRender) {
            return;
        }

        if (onSearch) {
            onSearch(debouncedSearchTerm ?? undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ debouncedSearchTerm, onSearch ]);

    return SearchComponent ? (
        React.cloneElement(SearchComponent as React.ReactElement, {
            setGlobalFilter,
            InputProps: {
                startAdornment: <SearchIcon className={classes.searchIcon} />,
                className: 'Tall',
            },
            className: classes.searchBox,
        })
    ) : (
        <TextField
            className={classes.searchBox}
            variant="outlined"
            size="small"
            placeholder={placeholder ?? ''}
            InputProps={{
                startAdornment: <SearchIcon className={classes.searchIcon} />,
                className: 'Tall',
            }}
            inputProps={{ 'aria-label': ariaLabel ?? placeholder ?? 'searchBar' }}
            value={query || ''}
            onChange={e => {
                setGlobalFilter(e.target.value || undefined);
            }}
            data-cy="ui-grid-search-bar"
        />
    );
};
