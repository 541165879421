import {
    type ITagModelObject,
    PermissionType,
} from '@experiences/interfaces';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isAdminSelector } from '../../../../store/selectors';
import { hasPermission } from '../../addedit/properties/TenantTagsUtil';
import { useTagsDisabled } from '../../common/useTagsDisabled';
import TenantTagsLabelsMoreActions from './TenantTagsLabelsMoreActions';
import useTenantTagsLabelsViewModel from './TenantTagsLabelsViewModel';

const TenantTagsLabelsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const { checkDisabled } = useTagsDisabled();
    const {
        handleDeleteLabel, handleAddLabel, permissions, labelData, loading, refresh: refreshLabels, openDeleteDialog, tenantId, tenantName, onChangePagination,
    } = useTenantTagsLabelsViewModel();
    const isAdmin = useSelector(isAdminSelector);

    return (
        <div
            style={{ width: '100%' }}
            data-cy='tenant-tags-labels-grid-container'>
            <ApDataGridWrapper<ITagModelObject>
                data={labelData?.results ?? []}
                toggleColumns
                initialFilters={{
                    sort: [
                        {
                            field: 'name',
                            direction: 'asc',
                            title: translate({ id: 'CLIENT_LABEL' }),
                        },
                    ],
                }}
                loading={loading}
                refreshable
                refresh={refreshLabels}
                selectable={hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ])}
                onChange={onChangePagination}
                dataCy='tenant-tags-labels-grid'
            >
                <ApDataGridHeader<ITagModelObject> search>
                    <ApDataGridHeaderButton<ITagModelObject>
                        id='deleteProperties'
                        key='deleteProperties'
                        type='action'
                        buttonType='mat-flat-button'
                        color="warn"
                        text={translate({ id: 'CLIENT_DELETE' })}
                        label={translate({ id: 'CLIENT_DELETE' })}
                        icon='delete'
                        onClick={handleDeleteLabel}
                        dataCy='delete-label-button'
                        disabled={checkDisabled(permissions, [ PermissionType.View, PermissionType.Delete ])}
                        visible={isAdmin}
                    />
                    <ApDataGridHeaderButton<ITagModelObject>
                        id='addLabel'
                        key='addLabel'
                        type='main'
                        buttonType='mat-flat-button'
                        color='primary'
                        text={translate({ id: 'CLIENT_ADD_LABEL' })}
                        label={translate({ id: 'CLIENT_ADD_LABEL' })}
                        onClick={handleAddLabel}
                        dataCy='add-label-button'
                        disabled={checkDisabled(permissions, [ PermissionType.View, PermissionType.Create ])}
                        visible={isAdmin}
                    />
                </ApDataGridHeader>
                <ApDataGridColumn<ITagModelObject>
                    property='name'
                    sort='asc'
                    title={translate({ id: 'CLIENT_LABEL' })}
                    searchable
                />
                <ApDataGridColumn<ITagModelObject>
                    property='description'
                    title={translate({ id: 'CLIENT_DESCRIPTION' })}
                    searchable
                />
                <ApDataGridColumn<ITagModelObject>
                    property='referencesCount'
                    title={translate({ id: 'CLIENT_TAGGED_OBJECTS' })}
                    searchable
                />
                {isAdmin && (
                    <ApDataGridRowActions>
                        <ApDataGridRowButton<ITagModelObject>
                            id='showMoreActions'
                            label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                            icon='more_vert'
                            render={(row) => <TenantTagsLabelsMoreActions
                                row={row}
                                tenantId={tenantId}
                                tenantName={tenantName}
                                permissions={permissions}
                                onDelete={openDeleteDialog} />}
                        />
                    </ApDataGridRowActions>
                )}
                <ApDataGridFooter
                    length={labelData?.totalCount ?? 0}
                    pageSizes={[ 5, 10, 25, 50 ]}
                />
            </ApDataGridWrapper>
        </div>
    );
};

export default TenantTagsLabelsComponent;
