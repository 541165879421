import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({ spacing: { marginBottom: '16px' } }),
);

const EcommerceAddressLine1NonAutocomplete: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control, formState: { errors },
    } =
        useFormContext<ISubscriptionForm>();

    return (
        <Controller
            name="addressLine1"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
                <TextField
                    {...field}
                    className={classes.spacing}
                    variant="outlined"
                    required
                    id="addressLine1"
                    label={translate({ id: 'CLIENT_STREET_ADDRESS' })}
                    style={{ marginRight: '12px' }}
                    error={!!errors.addressLine1}
                    helperText={
                        (errors.addressLine1?.type === 'required' &&
                            translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_STREET_ADDRESS' }) },
                            )) ||
                        (errors.addressLine1?.type === 'addressError' &&
                            translate({ id: 'CLIENT_CHECK_YOUR_BILLING_ADDRESS' }))
                    }
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                    disabled={disabled}
                    data-cy="ecommerce-form-address-line-1"
                />
            )}
        />
    );
};

export default EcommerceAddressLine1NonAutocomplete;
