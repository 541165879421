import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useAuthContext } from '@experiences/util';
import {
    useEffect,
    useState,
} from 'react';

import {
    CART_ABANDONMENT_SOURCE_KEY,
    useIsSignupDirectBuyFlow,
} from '../lib/helpers/EcommerceHelpers';
import { startCartAbandonmentCampaign } from '../lib/services/BillingService';

function useEcommerceCheckoutViewModel() {
    const { token } = useAuthContext();

    const EnableEcommerceIntegration = getFeatureFlagValue(Features.EnableEcommerceIntegration.name);
    const EnableEcommerceRemoveFocusProps = getFeatureFlagValue(Features.EnableEcommerceRemoveFocusProps.name);
    const EnableEcommercePlanSelectionRevampM0 = getFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);
    const EnableEcommerceCartAbandonmentCampaign = getFeatureFlagValue(Features.EnableEcommerceCartAbandonmentCampaign.name);
    const [ loading, setLoading ] = useState(false);
    const [ paymentElementFormCompleted, setPaymentElementFormCompleted ] = useState(false);
    const [ termsAndConditionsAccepted, setTermsAndConditionsAccepted ] = useState(false);
    const [ checkoutFormRef, setCheckoutFormRef ] = useState();
    const isDirectBuy = useIsSignupDirectBuyFlow();

    useEffect(() => {
        if (EnableEcommerceCartAbandonmentCampaign && !isDirectBuy) {
            startCartAbandonmentCampaign(sessionStorage.getItem(CART_ABANDONMENT_SOURCE_KEY) ?? 'SELECT_PLAN', token);
            sessionStorage.removeItem(CART_ABANDONMENT_SOURCE_KEY);
        }
    }, [ EnableEcommerceCartAbandonmentCampaign, isDirectBuy, token ]);

    return {
        enableEcommerceIntegration: EnableEcommerceIntegration,
        enableEcommerceRemoveFocusProps: EnableEcommerceRemoveFocusProps,
        enableEcommercePlanSelectionRevampM0: EnableEcommercePlanSelectionRevampM0,
        loading,
        setLoading,
        paymentElementFormCompleted,
        setPaymentElementFormCompleted,
        termsAndConditionsAccepted,
        setTermsAndConditionsAccepted,
        checkoutFormRef,
        setCheckoutFormRef,
    };
}

export default useEcommerceCheckoutViewModel;
