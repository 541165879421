import {
    UiCopyButton,
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { getEnvVariableValue } from '@experiences/util';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    accountUrl,
    getClientAccessToken,
} from '../../../services/identity/AccountService';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            marginBottom: '24px',
            width: '100%',
        },
        installationKeyContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        installationKeyInput: { marginLeft: '16px' },
        generateNewButton: { color: theme.palette.semantic.colorPrimary },
    }),
);

const InstallationKeyComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const [ loading, setLoading ] = useState(false);
    const classes = useStyles();
    const createNotification = useUiSnackBar();
    const {
        data: installationKey, error, mutate,
    } = useSWR<string, Error>(
        `${accountUrl}/ClientAccessToken`,
        getClientAccessToken,
    );

    const handleGenerateInstallationKey = useCallback(async () => {
        try {
            setLoading(true);
            await mutate();
            createNotification(
                translate({ id: 'CLIENT_GENERATE_INSTALLATION_KEY_SUCCESS' }),
                notificationType.SUCCESS,
            );
        } catch {
            createNotification(
                translate({ id: 'CLIENT_GENERATE_INSTALLATION_KEY_FAILURE' }),
                notificationType.ERROR
            );
        } finally {
            setLoading(false);
        }
    }, [ createNotification, mutate, translate ]);

    return (
        <div className={classes.root}>
            <div className={classes.installationKeyContainer}>
                <UiText className={classes.label}>
                    {translate({ id: 'CLIENT_INSTALLATION_KEY_LABEL' })}
                </UiText>
                <TextField
                    data-cy="installation-key-field"
                    className={classes.installationKeyInput}
                    value={installationKey}
                    error={!!error}
                    helperText={error && translate({ id: 'CLIENT_GET_INSTALLATION_KEY_FAILURE' })}
                    InputProps={{
                        readOnly: true,
                        className: 'Tall',
                        endAdornment: (
                            <InputAdornment position="end">
                                <UiCopyButton
                                    disabled={!installationKey}
                                    textToCopy={installationKey ?? ''}
                                    aria-label={translate({ id: 'CLIENT_COPY_INSTALLATION_KEY' })}
                                    data-cy="installation-key-copy-button"
                                />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <UiProgressButton
                    data-cy="installation-key-generate-new-button"
                    loading={loading}
                    onClick={handleGenerateInstallationKey}
                    color="inherit"
                    classes={{ colorInherit: classes.generateNewButton }}
                >
                    {translate({ id: 'CLIENT_GENERATE_NEW' })}
                </UiProgressButton>
            </div>
            <UiText>
                {translate(
                    { id: 'CLIENT_INSTALLATION_KEY_DESCRIPTION' },
                    { titleProductName: getEnvVariableValue('TITLE_PRODUCT_NAME') },
                )}
            </UiText>
        </div>
    );
};

export default InstallationKeyComponent;
