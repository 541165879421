import type {
    IAddIPNetworkPayload,
    IEditIPNetworkPayload,
    IIPNetwork,
} from '../../common/interfaces/iprestriction';
import {
    axiosDelete,
    get,
    patch,
    post,
} from '../utility/Requests.default';

export const ipNetworkUrl = '/api/accesspolicy';

export async function getCurrentIp() {
    return await get<string>(`${ipNetworkUrl}/ip`);
}

export async function getIpNetworkByOrganizationId({ organizationId }: { url: string; organizationId: string }) {
    return await get<IIPNetwork[]>(`${ipNetworkUrl}/organization/${organizationId}/ipnetworks`);
}

export async function addIpNetwork(organizationId: string, payload: IAddIPNetworkPayload) {
    return await post<IIPNetwork>(`${ipNetworkUrl}/organization/${organizationId}/ipnetworks`, { body: payload });
}

export async function updateIpNetwork(organizationId: string, ipNetworkId: string, payload: IEditIPNetworkPayload) {
    return await patch<IIPNetwork>(`${ipNetworkUrl}/organization/${organizationId}/ipnetworks/${ipNetworkId}`, { body: payload });
}

export async function deleteIpNetwork(organizationId: string, ipNetworkId: string) {
    return await axiosDelete(`${ipNetworkUrl}/organization/${organizationId}/ipnetworks/${ipNetworkId}`);
}

export async function deleteBulkIpNetworks(organizationId: string, ipNetworkIds: string[]) {
    return await axiosDelete(`${ipNetworkUrl}/organization/${organizationId}/ipnetworks`, { body: { IpNetworks: ipNetworkIds } });
}
