import { NavCustomizationSetting } from '@experiences/constants';
import { getSetting } from '@uipath/portal-shell-util';
import merge from 'lodash/merge';

import type { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import {
    defaultNavCustomizationData,
    type INavCustomizationData,
} from '../../component/organizationsettings/subcomponents/types/nav-customization';
import type { ISetting } from '../../services/identity/SettingService';

export const mapSettingArrayToNavCustomizationData = (
    fetchedSettings: ISetting[],
    defaults: INavCustomizationData = defaultNavCustomizationData,
): INavCustomizationData => {
    const appsHidden = getSetting(fetchedSettings, NavCustomizationSetting.AppsHidden);
    const marketplaceHidden = getSetting(fetchedSettings, NavCustomizationSetting.MarketplaceHidden);
    const resourceCenterHidden = getSetting(fetchedSettings, NavCustomizationSetting.ResourceCenterHidden);
    const studioHidden = getSetting(fetchedSettings, NavCustomizationSetting.StudioWebHidden);
    const integrationServiceHidden = getSetting(fetchedSettings, NavCustomizationSetting.IntegrationServiceHidden);
    const insightsHidden = getSetting(fetchedSettings, NavCustomizationSetting.InsightsHidden);
    const academyHidden = getSetting(fetchedSettings, NavCustomizationSetting.AcademyHidden);
    const customerportalHidden = getSetting(fetchedSettings, NavCustomizationSetting.CustomerPortalHidden);
    const aifabricHidden = getSetting(fetchedSettings, NavCustomizationSetting.AiCenterHidden);
    const automationhubHidden = getSetting(fetchedSettings, NavCustomizationSetting.AutomationHubHidden);
    const reinferHidden = getSetting(fetchedSettings, NavCustomizationSetting.CommunicationsMiningHidden);
    const submitIdeaHidden = getSetting(fetchedSettings, NavCustomizationSetting.SubmitIdeaHidden);
    const processminingHidden = getSetting(fetchedSettings, NavCustomizationSetting.ProcessMiningHidden);

    return merge({}, defaults, {
        appsHidden: appsHidden === undefined ? undefined : appsHidden === 'true',
        marketplaceHidden: marketplaceHidden === undefined ? undefined : marketplaceHidden === 'true',
        resourceCenterHidden: resourceCenterHidden === undefined ? undefined : resourceCenterHidden === 'true',
        studioHidden: studioHidden === undefined ? undefined : studioHidden === 'true',
        integrationServiceHidden: integrationServiceHidden === undefined ? undefined : integrationServiceHidden === 'true',
        insightsHidden: insightsHidden === undefined ? undefined : insightsHidden === 'true',
        academyHidden: academyHidden === undefined ? undefined : academyHidden === 'true',
        customerportalHidden: customerportalHidden === undefined ? undefined : customerportalHidden === 'true',
        aifabricHidden: aifabricHidden === undefined ? undefined : aifabricHidden === 'true',
        automationhubHidden: automationhubHidden === undefined ? undefined : automationhubHidden === 'true',
        reinferHidden: reinferHidden === undefined ? undefined : reinferHidden === 'true',
        submitIdeaHidden: submitIdeaHidden === undefined ? true : submitIdeaHidden === 'true',
        processminingHidden: processminingHidden === undefined ? undefined : processminingHidden === 'true',
    });
};

export function mapNavCustomizationDataToKeyValuePairs(data: INavCustomizationData): IKeyValuePair[] {
    return [
        {
            key: NavCustomizationSetting.AppsHidden,
            value: data.appsHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.MarketplaceHidden,
            value: data.marketplaceHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.ResourceCenterHidden,
            value: data.resourceCenterHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.StudioWebHidden,
            value: data.studioHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.IntegrationServiceHidden,
            value: data.integrationServiceHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.InsightsHidden,
            value: data.insightsHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.AcademyHidden,
            value: data.academyHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.CustomerPortalHidden,
            value: data.customerportalHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.AiCenterHidden,
            value: data.aifabricHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.AutomationHubHidden,
            value: data.automationhubHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.CommunicationsMiningHidden,
            value: data.reinferHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.SubmitIdeaHidden,
            value: data.submitIdeaHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.ProcessMiningHidden,
            value: data.processminingHidden?.toString() ?? 'false',
        },
    ];
}
