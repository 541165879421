import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useStaticLogout() {
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();

    const logout = useCallback((returnUrl?: string) => {
        const returnUrlRoute = returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '';
        const logoutUrl = `/portal_/logout${returnUrlRoute}`;
        navigate(logoutUrl);
    }, []);

    return logout;
}
