import {
    UiSafeImg,
    UiText,
} from '@experiences/ui-common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

import UiCardGrid from '../../common/UiCardGrid/UiCardGrid';
import useExternalApplicationsAppRegistrationsViewModel from './ExternalApplicationAppRegistrationsViewModel';
import { UiApplicationCard } from './UiApplicationCard';
import { UiApplicationMoreActionsComponent } from './UiApplicationMoreActionsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        loading: { margin: 'auto' },
        accordion: { '&::before': { backgroundColor: 'transparent' } },
        accordionSummary: { padding: '0px' },
        accordionDetails: { padding: '16px 0px' },
        icon: {
            height: '32px',
            width: '32px',
        },
        description: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            marginBottom: '32px',
        },
        emptyText: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDisable,
        },
        sectionHeader: { borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}` },
    })
);

const ExternalApplicationsAppRegistrationsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        loading, availableApps, registeredApps, moreActions, updatedScopes,
    } = useExternalApplicationsAppRegistrationsViewModel();

    if (loading) {
        return <CircularProgress
            className={classes.loading}
            data-cy="loading-circle" />;
    }

    return <Box>
        <UiText className={classes.description}>
            {translate({ id: 'CLIENT_EXTERNAL_APP_REGISTRATION_DESCRIPTION' })}
        </UiText>
        {updatedScopes && <PortalAlertBar
            status="error"
            cancelable>
            <UiText>
                <b>
                    {`${translate({ id: 'CLIENT_IMPORTANT' })}: `}
                </b>
                {translate({ id: 'CLIENT_UPDATED_SCOPES_MESSAGE' })}
            </UiText>
        </PortalAlertBar>}
        <Accordion
            defaultExpanded
            elevation={0}
            square
            style={{ marginBottom: '32px' }}
            data-cy="registered-applications-accordion">
            <AccordionSummary
                className={classes.sectionHeader}
                classes={{ root: classes.accordionSummary }}
                expandIcon={<ExpandMoreIcon />}>
                <UiText>
                    {translate({ id: 'CLIENT_REGISTERED_APPLICATIONS' }, { count: registeredApps?.length ?? 0 })}
                </UiText>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {registeredApps.length > 0
                    ? <UiCardGrid
                        maxCardWidth='320px'
                        position="left">
                        {registeredApps.map(app => (
                            <UiApplicationCard
                                key={`app-reg-available-${app.clientId}`}
                                lastUpdatedTime={app.lastUpdatedTime}
                                registeredTime={app.registrationTime}
                                publisher={app.publisherName}
                                title={app.name}
                                titleIcon={<UiSafeImg
                                    className={classes.icon}
                                    src={app.logoUri}
                                    alt={app.name}
                                />}
                                description={app.description}
                                actions={<UiApplicationMoreActionsComponent
                                    actions={moreActions(
                                        true,
                                        app.name,
                                        app.publisherPartitionId,
                                        app.clientId,
                                        app.scopes,
                                        (app.unRegisteredScopes?.length ?? 0) > 0,
                                    )}
                                />}
                                registered
                                dataCy={`app-reg-available-${app.clientId}`}
                                updatedScopes={(app.unRegisteredScopes?.length ?? 0) > 0}
                            />
                        ))}
                    </UiCardGrid>
                    : <UiText className={classes.emptyText}>
                        {translate({ id: 'CLIENT_NO_REGISTERED_APPLICATIONS' })}
                    </UiText> }
            </AccordionDetails>
        </Accordion>
        <Accordion
            className={classes.accordion}
            defaultExpanded
            elevation={0}
            square
            data-cy="available-applications-accordion">
            <AccordionSummary
                className={classes.sectionHeader}
                classes={{ root: classes.accordionSummary }}
                expandIcon={<ExpandMoreIcon />}>
                <UiText>
                    {translate({ id: 'CLIENT_AVAILABLE_APPLICATIONS' }, { count: availableApps?.length ?? 0 })}
                </UiText>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {availableApps.length > 0
                    ? <UiCardGrid
                        maxCardWidth='320px'
                        position="left">
                        {availableApps.map(app => (
                            <UiApplicationCard
                                key={`app-reg-available-${app.clientId}`}
                                lastUpdatedTime={app.lastUpdatedTime}
                                publisher={app.publisherName}
                                title={app.name}
                                titleIcon={<UiSafeImg
                                    className={classes.icon}
                                    src={app.logoUri}
                                    alt={app.name}
                                />}
                                description={app.description}
                                actions={<UiApplicationMoreActionsComponent
                                    actions={moreActions(
                                        false,
                                        app.name,
                                        app.publisherPartitionId,
                                        app.clientId,
                                        app.scopes,
                                        false,
                                    )}
                                />}
                                registered={false}
                                dataCy={`app-reg-available-${app.clientId}`}
                            />
                        ))}
                    </UiCardGrid>
                    : <UiText className={classes.emptyText}>
                        {translate({ id: 'CLIENT_NO_AVAILABLE_APPLICATIONS' })}
                    </UiText>}
            </AccordionDetails>
        </Accordion>
    </Box>;
};

export default ExternalApplicationsAppRegistrationsPageComponent;
