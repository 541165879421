import type { IDirectoryConnectionDto } from '../../common/interfaces/directoryConnection';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const directoryConnectionUrl = `${process.buildConfigs.identityApiBaseRoute}/DirectoryConnection`;

export enum DirectoryConnectionType {
    AAD = 'aad',
    AD = 'ad',
    LDAPAD = 'ldapad',
    SAML2 = 'Saml2',
}

export interface IDirectoryConnectionPayload {
    id: number;
    partitionId: number;
    type: DirectoryConnectionType;
    configuration: string;
}

export function getDirectoryConnection({
    url: _url, partitionGlobalId,
}: { url: string; partitionGlobalId: string }) {
    return get<IDirectoryConnectionPayload[]>(`${directoryConnectionUrl}/connections/${partitionGlobalId}`);
}

export function updateDirectoryConnection(
    partitionGlobalId: string,
    connectionId: number,
    directoryConnectionType: DirectoryConnectionType,
    directoryConnectionDto: IDirectoryConnectionDto,
) {
    return put(`${directoryConnectionUrl}/${connectionId}`, {
        body: {
            partitionGlobalId,
            type: directoryConnectionType,
            configuration: JSON.stringify(directoryConnectionDto),
        },
    });
}

export function createLDAPDirectoryConnection(
    partitionGlobalId: string,
    directoryConnectionDto: IDirectoryConnectionDto,
) {
    return post(directoryConnectionUrl, {
        body: {
            partitionGlobalId,
            type: DirectoryConnectionType.LDAPAD,
            configuration: JSON.stringify(directoryConnectionDto),
        },
    });
}

export function deleteDirectoryConnection(connectionId: number) {
    return axiosDelete(`${directoryConnectionUrl}/${connectionId}`);
}

export function testConnectionConfiguration(directoryConnectionDto: IDirectoryConnectionDto) {
    return post(`${directoryConnectionUrl}/TestConfig`, {
        body: {
            type: DirectoryConnectionType.LDAPAD,
            configuration: JSON.stringify(directoryConnectionDto),
        },
    });
}
