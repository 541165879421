import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import type { ButtonType } from '../../common/interfaces/slide';
import type { CarouselSlideProps } from './types/CarouselSlideProps';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '40px',
    wordBreak: 'break-all',
}));

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontFamily: Tokens.FontFamily.FontTitle,
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 'bold',
            marginBottom: '12px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            height: '32px',
            color: theme.palette.semantic.colorForeground,
            wordBreak: 'break-all',
        },
        description: {
            fontSize: '14px',
            lineHeight: '20px',
            maxWidth: '600px',
            color: theme.palette.semantic.colorForeground,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'keep-all',
        },
        lineImage: {
            marginBottom: '10px',
            marginTop: '16px',
            height: '32px',
            maxWidth: '600px',
        },
        lineImageInner: {
            height: '32px',
            overflow: 'hidden',
            maxWidth: '100%',
        },
        slideButton: {
            marginTop: 'auto',
            overflow: 'hidden',
            maxWidth: '600px',
        },
    }),
);

const CarouselItems = ({
    title,
    description,
    lineImage,
    buttonText,
    buttonType,
    buttonRedirectUrl,
    idx,
    currSlide,
}: CarouselSlideProps) => {
    const classes = useStyles();
    const carouselTitleRef = useRef<HTMLDivElement>();
    const [ hoverStatus, setHover ] = useState(false);
    const getStyle = () => ({
        WebkitLineClamp: lineImage ? 1 : 3,
        marginTop: lineImage ? '0' : '4px',
        marginBottom: lineImage ? '0' : '24px',
    });

    const { formatMessage: translate } = useIntl();
    const buttonTypeMap = new Map<ButtonType, JSX.Element | undefined>([
        [ 'Arrow', <ArrowForwardIcon key={'carouselSlideButton ' + idx} /> ],
        [
            'Download',
            <DownloadOutlinedIcon key={'carouselSlideButton ' + idx} />,
        ],
        [ 'No Icon', undefined ],
    ]);

    // Handle tooltip during overflow
    const compareSize = () => {
        if (carouselTitleRef.current) {
            const { current } = carouselTitleRef;
            const compare = (current.offsetHeight + 1) < current.scrollHeight;
            setHover(compare);
        }
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        return () => window.removeEventListener('resize', compareSize);
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        }}>
            <div>
                {title && (
                    <Tooltip
                        title={title}
                        disableHoverListener={!hoverStatus}>
                        <div
                            ref={carouselTitleRef as React.RefObject<HTMLDivElement>}
                            className={classes.title}
                            data-cy={`title-slide-${idx}`}>
                            {title}
                        </div>
                    </Tooltip>
                )}
                {description && (
                    <div
                        className={classes.description}
                        data-cy={`description-slide-${idx}`}
                        style={getStyle()}
                    >
                        {description}
                    </div>
                )}
                {lineImage && (
                    <div
                        className={classes.lineImage}
                        data-cy={`lineImage-slide-${idx}`}
                    >
                        <img
                            src={lineImage}
                            className={classes.lineImageInner}
                            alt={translate({ id: 'CLIENT_CAROUSEL_LINE_IMAGE' })}
                        />
                    </div>
                )}
            </div>
            {buttonText && (
                <div
                    className={classes.slideButton}
                    style={{
                        marginTop: lineImage ? 'auto' : '0',
                        // marginBottom: lineImage ? '0' : '20px',
                    }}
                    data-cy={`button-slide-${idx}`}
                >
                    <CustomButton
                        tabIndex={idx === currSlide ? 0 : -1}
                        variant="contained"
                        disableElevation
                        color="secondary"
                        endIcon={
                            buttonType
                                ? buttonTypeMap.get(buttonType)
                                : undefined
                        }
                        data-cy={`carousel-slide-button-${idx}`}
                        onClick={() => {
                            window.open(buttonRedirectUrl, '_blank')?.focus();
                        }}
                        style={{ fontSize: '14px' }}
                    >
                        {buttonText}
                    </CustomButton>
                </div>
            )}
        </div>
    );
};

export default CarouselItems;
