import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { dashboardLicenses } from '../../common/constants/Constant';
import { TabTelemetryMap } from '../../common/constants/TelemetryEvents';
import { useTrialServiceTab } from '../../services/licensing/TrialPerSku';
import {
    EnableUserLicensingSelector,
    isAdminSelector,
    isHostModeSelector,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { AllocationToTenants } from './AllocationToTenants';
import { ConsumablePanel } from './ConsumablePanel';
import PaginatedExplicitAllocationGridComponent from './PaginatedExplicitAllocationGridComponent';
import PaginatedGroupAllocationGridComponent from './PaginatedGroupAllocationGridComponent';
import { TrialPerSkuPanel } from './trialPerSku/TrialPerSkuPanel';
import { UiAccordion } from './UiAccordion';
import { ViewLicensesPanel } from './ViewLicensesPanel';

const useStyles = makeStyles(() =>
    createStyles({
        licensesContent: {
            display: 'flex',
            minHeight: '650px',
            width: '100%',
        },
    }),
);

export const UserLicensingTabs = () => {
    const enableConsumablesTab = useFeatureFlagValue(Features.EnableConsumablesTab.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const isAdmin = useSelector(isAdminSelector);
    const [ value, setValue ] = useState(dashboardLicenses.USERS);
    const isHostMode = useSelector(isHostModeSelector);
    const { logEvent } = useTelemetryHelper();
    const {
        shouldSeeTrialServicesTab, trialServicesResponse,
    } = useTrialServiceTab();
    const handleChange = useCallback(
        (_: any, newValue: string) => {
            setValue(newValue);

            if (TabTelemetryMap.has(newValue)) {
                logEvent(TabTelemetryMap.get(newValue)!);
            }
        },
        [ setValue, logEvent ],
    );

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                className="default"
            >
                <Tab
                    value={dashboardLicenses.USERS}
                    label={translate({ id: 'CLIENT_USERS' })}
                    className="default"
                    data-cy="users-tab"
                    disableRipple
                />
                <Tab
                    value={dashboardLicenses.ROBOTS_AND_SERVICES}
                    label={translate({ id: 'CLIENT_ROBOTS_AND_SERVICES' })}
                    className="default"
                    data-cy="robots-and-services-tab"
                    disableRipple
                />
                { enableConsumablesTab && !isHostMode && <Tab
                    value={dashboardLicenses.CONSUMABLES}
                    label={translate({ id: 'CONSUMABLES' })}
                    className="default"
                    data-cy="consumables-tab"
                    disableRipple
                />}
                {shouldSeeTrialServicesTab && (
                    <Tab
                        value={dashboardLicenses.TRIAL_SERVICES}
                        label={translate({ id: 'CLIENT_FREE_TRIALS_SERVICES' })}
                        className="default"
                        data-cy="trial-services-tab"
                        disableRipple
                    />
                )}
            </Tabs>
            {isHostMode &&
              <ViewLicensesPanel
                  selectedTab={value}
                  useLegacyProducts={false} />}
            {!isHostMode &&
                (EnableUserLicensing && isAdmin ? (
                    <>
                        {value === dashboardLicenses.USERS && (
                            <>
                                <UiAccordion titleTranslationCode="CLIENT_VIEW_LICENSES">
                                    <ViewLicensesPanel selectedTab={value} />
                                </UiAccordion>
                                <UiAccordion
                                    expandedByDefault={false}
                                    titleTranslationCode="CLIENT_ALLOCATE_LICENSES_TO_GROUPS"
                                >
                                    <div className={classes.licensesContent}>
                                        <PaginatedGroupAllocationGridComponent />
                                    </div>
                                </UiAccordion>
                                <UiAccordion
                                    expandedByDefault={false}
                                    titleTranslationCode="CLIENT_LICENSE_ALLOCATIONS_TO_USERS"
                                >
                                    <div className={classes.licensesContent}>
                                        <PaginatedExplicitAllocationGridComponent />
                                    </div>
                                </UiAccordion>
                            </>
                        )}
                        {value === dashboardLicenses.ROBOTS_AND_SERVICES && (
                            <>
                                <UiAccordion titleTranslationCode="CLIENT_VIEW_LICENSES">
                                    <ViewLicensesPanel
                                        selectedTab={value}
                                        enabledConsumablesTab={enableConsumablesTab} />
                                </UiAccordion>
                                <UiAccordion titleTranslationCode="CLIENT_TENANT_ALLOCATION">
                                    <div className={classes.licensesContent}>
                                        <AllocationToTenants />
                                    </div>
                                </UiAccordion>
                            </>
                        )}
                        {value === dashboardLicenses.CONSUMABLES && enableConsumablesTab && (
                            <ConsumablePanel isServiceMode={false} />
                        )}
                    </>
                ) : (
                    value !== dashboardLicenses.TRIAL_SERVICES && (
                        value === dashboardLicenses.CONSUMABLES && enableConsumablesTab ?
                            <ConsumablePanel isServiceMode={false} /> :
                            <ViewLicensesPanel
                                selectedTab={value}
                                useLegacyProducts={value === dashboardLicenses.USERS && !EnableUserLicensing}
                                enabledConsumablesTab={enableConsumablesTab}
                            />
                    )
                ))}
            {value === dashboardLicenses.TRIAL_SERVICES && (
                <TrialPerSkuPanel trialServices={trialServicesResponse} />
            )}
        </div>
    );
};
