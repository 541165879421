import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import type { ILogger } from '@uipath/auth-react';

export class AuthLogger implements ILogger {
    private static instance: AuthLogger;

    private constructor() {}

    static getInstance() {
        if (!AuthLogger.instance) {
            AuthLogger.instance = new AuthLogger();
        }

        return AuthLogger.instance;
    }

    error(message?: any, ...optionalParams: any[]): void {
        portalTelemetry.trackTrace({
            message,
            severityLevel: SeverityLevel.Critical,
            ...optionalParams,
        });
    }

    info(message?: any, ...optionalParams: any[]): void {
        portalTelemetry.trackTrace({
            message,
            severityLevel: SeverityLevel.Normal,
            ...optionalParams,
        });
    }

    debug(message?: any, ...optionalParams: any[]): void {
        portalTelemetry.trackTrace({
            message,
            severityLevel: SeverityLevel.Info,
            ...optionalParams,
        });
    }

    warn(message?: any, ...optionalParams: any[]): void {
        portalTelemetry.trackTrace({
            message,
            severityLevel: SeverityLevel.Major,
            ...optionalParams,
        });
    }
}
