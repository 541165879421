import type {
    IPersonaFit,
    Token,
} from '@experiences/interfaces';
import { jwtDecode } from 'jwt-decode';

import { get } from '../utility/Requests.default';

export const personaUri = '/api/persona/persona/';

export function getPersona(token: string) {
    const profile = jwtDecode<Token>(token);
    const userGlobalId = profile.sub;
    return get<IPersonaFit>(personaUri + userGlobalId, { accessToken: token });
}
