import {
    useNavigateWithParams,
    useShowDialog,
} from '@experiences/util';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import TenantTagsWarningDialogBody from './TenantTagsWarningDialogBody';

export const useTagsCancel = (isDirty: boolean, showDialog: boolean) => {
    const createDialog = useShowDialog();
    const navigate = useNavigateWithParams();
    const { formatMessage: translate } = useIntl();

    const cancel = useCallback(async () => {
        let proceed = true;
        if (isDirty && showDialog) {
            proceed = await createDialog({
                title: translate({ id: 'CLIENT_UNSAVED_CHANGES' }),
                customDialogContent: TenantTagsWarningDialogBody,
                icon: 'warning',
            });
        }

        if (!proceed) {
            return;
        }
        navigate(-1);

    }, [ createDialog, isDirty, navigate, showDialog, translate ]);
    return cancel;
};
