const downloadResources = {
    ENTERPRISE_STUDIO_DOWNLOAD_LINK: 'https://download.uipath.com/UiPathStudio.msi',
    ENTERPRISE_CLOUD_STUDIO_DOWNLOAD_LINK: 'https://download.uipath.com/UiPathStudioCloud.msi',
    ENTERPRISE_TRIAL_STUDIO_DOWNLOAD_LINK: 'https://download.uipath.com/UiPathStudioCloudTrial.msi',
    COMMUNITY_STUDIO_DOWNLOAD_LINK: 'https://download.uipath.com/UiPathStudioCommunity.msi',
    COMMUNITY_STUDIO_PREVIEW_DOWNLOAD_LINK: 'https://download.uipath.com/beta/UiPathStudioCommunity.msi',
    UIPATH_REMOTE_RUNTIME_DOWNLOAD: 'https://download.uipath.com/UiPathRemoteRuntime.msi',
    STUDIO_LATEST_VERSION: 'https://download.uipath.com/contents.json',

    // Allow download url override for testing purposes
    getDownloadURL(downloadUrl: string) {
        const productionUrl = new URL(downloadUrl);
        return productionUrl.href;
    },
};

export default downloadResources;
