import { AccountLicense } from '@experiences/constants';
import type { SecuritySettingType } from '@experiences/interfaces';
import { SecuritySettingEnum } from '@experiences/interfaces';
import { useNavigateWithParams } from '@experiences/util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    Encryption,
    IPRestriction,
    OrganizationAccessPolicy,
    SecuritySettings,
    SessionPolicy,
} from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import {
    accountType as accountTypeSelector,
    isHostModeSelector,
} from '../../../store/selectors';
import ExternalProvidersComponentRevamp from '../../authsettings/subcomponents/ExternalProvidersComponentRevamp';
import OrganizationAccessPolicyComponent from '../accessPolicy/base/OrganizationAccessPolicyComponent';
import EncryptionComponent from './Encryption/EncryptionComponent';
import IPRestrictionComponent from './IPRestriction/IPRestrictionComponent';
import SessionPolicyComponent from './SessionPolicyComponent';

const TabPanel: React.FC<{ value: SecuritySettingType }> = ({ value }) => {
    switch (value) {
        case SecuritySettingEnum.Authentication:
            return <ExternalProvidersComponentRevamp />;
        case SecuritySettingEnum.SessionPolicy:
            return <SessionPolicyComponent />;
        case SecuritySettingEnum.IpRestriction:
            return <IPRestrictionComponent />;
        case SecuritySettingEnum.Encryption:
            return <EncryptionComponent />;
        case SecuritySettingEnum.AccessRestriction:
            return <OrganizationAccessPolicyComponent />;
        default:
            return <ExternalProvidersComponentRevamp />;
    }

    return null;
};

const SecuritySettingsTabs: React.FC<{ type: SecuritySettingType }> = ({ type }) => {

    const { formatMessage: translate } = useIntl();
    const {
        isOffersRevampAndCommunity, isUserEnterprise,
    } = useCheckLicense();

    const isHostMode = useSelector(isHostModeSelector);
    const accountType = useSelector(accountTypeSelector);
    const [ tabValue, setTabValue ] = useState<SecuritySettingType>(type);

    const navigate = useNavigateWithParams();
    const isOnPrem = useIsOnPrem();

    const handleChange = useCallback((_: any, newValue: SecuritySettingType) => {
        setTabValue(newValue);
    }, [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={{
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '32px',
                }}>
                <Tab
                    value={SecuritySettingEnum.Authentication}
                    label={translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS' })}
                    onClick={() => navigate(SecuritySettings)}
                    data-cy="auth-settings-tab"
                    className="default"
                />
                {!isHostMode && !isOnPrem && (isUserEnterprise || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingEnum.IpRestriction}
                        label={translate({ id: 'CLIENT_IP_RESTRICTION' })}
                        onClick={() => navigate(IPRestriction)}
                        data-cy="ip-restrictions-tab"
                        className="default"
                    />
                )}
                {(isUserEnterprise || isOnPrem || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingEnum.SessionPolicy}
                        label={translate({ id: 'CLIENT_SESSION_POLICY' })}
                        onClick={() => navigate(SessionPolicy)}
                        data-cy="session-policy-tab"
                        className="default"
                    />
                )}
                {!isHostMode && !isOnPrem && (AccountLicense[accountType] === AccountLicense.ENTERPRISE || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingEnum.Encryption}
                        label={translate({ id: 'CLIENT_ENCRYPTION_CERTIFICATE_LOCATION_USAGE' })}
                        onClick={() => navigate(Encryption)}
                        data-cy="encryption-tab"
                        className="default"
                    />
                )}
                {!isHostMode && (
                    <Tab
                        value={SecuritySettingEnum.AccessRestriction}
                        label={translate({ id: 'CLIENT_ACCESS_RESTRICTION' })}
                        onClick={() => navigate(OrganizationAccessPolicy)}
                        data-cy="access-policy-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default SecuritySettingsTabs;
