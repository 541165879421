import {
    useEffect,
    useState,
} from 'react';

import {
    decodeUriParams,
    encodeUriParams,
} from '../static/PathUtil';

function getSearchParams() {
    return new URLSearchParams(window.location.search);
}

export function useQueryState<T>(
    key: string,
    initialValue: T,
    reviver?: (k: string, value: string) => void,
    replace?: boolean,
): [T, React.Dispatch<React.SetStateAction<T>>] {

    const [ item, setItem ] = useState(() => {
        const queryStringValue = getSearchParams().get(key);

        if (queryStringValue) {
            try {
                const params = decodeUriParams<T>(queryStringValue, reviver);

                return params;
            } catch (e) {
                return initialValue;
            }
        }

        return initialValue;

    });

    useEffect(() => {
        const encodedValue = encodeUriParams(item as any);

        const searchParams = getSearchParams();

        if (encodedValue) {
            searchParams.set(key, encodedValue);
        } else {
            searchParams.delete(key);
        }

        const newQueryString = searchParams.toString();
        const newUrl = newQueryString ? `?${newQueryString}` : window.location.pathname;

        // mutate history instead of react-router to avoid re-rendering
        if (replace) {
            window.history.replaceState({}, '', newUrl);
        } else {
            window.history.pushState({}, '', newUrl);
        }
    }, [ item, key, replace ]);

    return [ item, setItem ];
}
