import { useCentralErrorSetter } from '@experiences/error';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IEditIdentityProviderData } from '../../../common/interfaces/externalIdentity';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultEditIdentityProviderData,
    mapADConfigDataToExternalIdentityPayload,
    mapExternalIdentityPayloadToADConfigData,
} from '../../../util/ExternalIdentityProviderUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import EditIdentityProviderFormComponent from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
}));

const ConfigureADComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);

    const { close } = useModalState(RouteNames.SecuritySettings);

    const classes = useStyles();
    const setErrorDialog = useCentralErrorSetter();
    const createNotification = useUiSnackBar();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const methods = useForm<IEditIdentityProviderData>({
        mode: 'onSubmit',
        defaultValues: {
            isActive: true,
            isExclusive: false,
            displayName: '',
        },
    });

    const {
        handleSubmit, reset, formState: {
            isDirty, isSubmitting,
        },
    } = methods;

    const fetchedExternalIdentity = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);

    const onSubmit = useCallback(
        async (data: IEditIdentityProviderData) => {
            const externalIdentityPayload = mapADConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            externalIdentityPayload.isActive = true;

            try {
                // TODO: This code does not look right -- need to validate
                (await fetchedExternalIdentity)
                    ? updateExternalIdentityProvider({
                        ...externalIdentityPayload,
                        partitionGlobalId,
                    })
                    : createExternalIdentityProvider({
                        ...externalIdentityPayload,
                        partitionGlobalId,
                    });
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }), notificationType.SUCCESS);
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
            }
        },
        [
            fetchedExternalIdentity,
            partitionGlobalId,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            setErrorDialog,
        ],
    );

    useEffect(() => {
        const mappedExternalIdentity = fetchedExternalIdentity
            ? mapExternalIdentityPayloadToADConfigData(fetchedExternalIdentity)
            : defaultEditIdentityProviderData;
        reset({ ...mappedExternalIdentity });
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_AD_SSO_CONFIGURATION' }),
        },
    ], [ organizationName, translate ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            maxWidth="900px"
            position='center'>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="configure-ad-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                centerChild
            >
                <FormProvider {...methods}>
                    <EditIdentityProviderFormComponent />
                </FormProvider>
            </UiForm>
        </UiPageContainer>
    );
};

export default ConfigureADComponent;
