import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { useIntl } from 'react-intl';

import { EcommerceCheckListItem } from './EcommerceCheckListItem';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        featuresTitle: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        featuresList: { paddingLeft: '0px' },
    }),
}));

export const EcommerceBusinessFeaturesForCloud: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return (<div>
        <UiText
            className={classes.featuresTitle}
            data-cy="business-features-title"
            variant={FontVariantToken.fontSizeH2}>
            {translate({ id: 'CLIENT_ECOMMERCE_BUSINESS_FEATURES_FOR_AUTOMATION_CLOUD_PRO' })}
        </UiText>
        <ul
            className={classes.featuresList}
            data-cy="business-feature-list">
            <EcommerceCheckListItem translationId="CLIENT_ECOMMERCE_TECH_SUPPORT_FROM_UI_PATH" />
            <EcommerceCheckListItem translationId="CLIENT_ECOMMERCE_ALWAYS_AVAILABLE" />
            <EcommerceCheckListItem translationId="CLIENT_ECOMMERCE_MULTI_TENANCY" />
            <EcommerceCheckListItem translationId="CLIENT_ECOMMERCE_DATA_SOVEREIGNTY" />
        </ul>
    </div>);
};
