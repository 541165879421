import { AccountLicense } from '@experiences/constants';
import { useIsDirectBuyInProgressFlow } from '@experiences/ecommerce';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';

import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    isAdminSelector,
} from '../../../store/selectors';

const useOrganizationSwitcherDialogViewModel = () => {
    const location = useLocation();
    const accountNameInStore = useSelector(accountLogicalName);
    const accountGuidInStore = useSelector(accountGlobalId);
    const { accountName } = useParams() as { accountName: string };
    const isAdmin = useSelector(isAdminSelector);
    const licensePlan = useSelector(accountType);
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();

    const isOrganizationMismatch = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const isOrganizationSettingsUpdate = params.get('isOrgNameUpdate') === 'true';
        return accountName?.toLowerCase() !== accountNameInStore?.toLowerCase()
            && accountName?.toLowerCase() !== accountGuidInStore?.toLowerCase()
            && !isOrganizationSettingsUpdate;
    }, [ location.search, accountName, accountNameInStore, accountGuidInStore ]);

    const isDirectBuyUpgradeError = useMemo(() => isDirectBuyInProgressFlow &&
            (!isAdmin || ![ AccountLicense.COMMUNITY, AccountLicense.TRIAL, AccountLicense.FREEKIT, AccountLicense['PRO-TRIAL'] ]
                .includes(AccountLicense[licensePlan])),
    [ isAdmin, licensePlan, isDirectBuyInProgressFlow ]);

    return {
        isOrganizationMismatch,
        isDirectBuyUpgradeError,
        isAdmin,
    };
};

export default useOrganizationSwitcherDialogViewModel;
