import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';

export function useEcommerceRegionFeatureFlags() {
    const EnableEcommerceInUs = useFeatureFlagValue(
        Features.EnableEcommerceInUs.name
    );
    const EnableEcommerceInEurope = useFeatureFlagValue(
        Features.EnableEcommerceInEurope.name
    );
    const EnableEcommerceInWorld = useFeatureFlagValue(
        Features.EnableEcommerceInWorld.name
    );
    const EnableEcommerceInJapan = useFeatureFlagValue(
        Features.EnableEcommerceInJapan.name
    );

    return {
        EnableEcommerceInUs,
        EnableEcommerceInEurope,
        EnableEcommerceInWorld,
        EnableEcommerceInJapan,
    };
}
