import {
    LegacyProductsToUserBundleLicenseMap,
    productCodeToTranslationCode,
} from './constants/Constant';

export function productNameTranslationCode(productCode: string) {
    const mappedProduct = LegacyProductsToUserBundleLicenseMap[productCode];
    const isMapped = !!mappedProduct;
    const productCodeForTranslation = isMapped ? mappedProduct.slice(0, -2) : productCode;

    return productCodeToTranslationCode.get(productCodeForTranslation) ?? productCodeForTranslation;
}

export function productSubLabelTranslationCode(productCode: string, productType?: string) {
    switch (productType) {
        case 'USER':
            return productCode.endsWith('CU') ? 'CU' : 'NU';
        case 'STANDARD':
            return 'RUNTIME';
        default:
            return 'RUNTIME';
    }
}
