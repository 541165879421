import { useShowDialog } from '@experiences/util';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type { KeyedMutator } from 'swr';

import { RevokePATByValueDialogBody } from './RevokePATByValueDialogBody';

export const useRevokePatByValueDialog = (partitionGlobalId: string, mutateReferences: KeyedMutator<any>) => {
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();

    const openRevokeDialog = useCallback(async () => {
        await createDialog({
            title: translate({ id: 'CLIENT_REVOKE_TOKEN' }),
            icon: 'warning',
            customDialogContent: RevokePATByValueDialogBody,
            customDialogContentProps: {
                partitionGlobalId,
                mutateReferences,
            },
        });
    }, [ createDialog, mutateReferences, partitionGlobalId, translate ]);

    return openRevokeDialog;
};

