import {
    EcommercePrice,
    getLicenseInfoUiState,
    INCREMENTAL_FLOW,
    storePlanType,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    formatDate,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { KeyboardEvent } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import {
    billingUrl,
    fetchHistoryStatus,
    fetchPlanDetails,
    goToStripeCustomerPortalSession,
} from '../../../services/licensing/BillingService';
import { accountLogicalName } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        infoSection: { display: 'flex' },
        supportSection: {
            display: 'flex',
            marginTop: '14px',
        },
        alignRight: { marginLeft: 'auto' },
        column: { marginLeft: '60px' },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        value: {
            marginTop: '4px',
            letterSpacing: '0em',
            textAlign: 'left',
        },
        fontSizeM: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
        },
        colorForeground: {
            color: theme.palette.semantic.colorForeground,
            marginTop: '4px',
        },
        fontSizeMBold: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
        },
        contactSupport: { marginLeft: '4px' },
        box: {
            border: `1px solid ${theme.palette.semantic.colorBorderDisabled}`,
            borderRadius: '3px',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '17px',
            paddingRight: '17px',
            marginBottom: '19px',
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
        },
        infoTooltipText: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundInverse,
        },
        tooltipBox: {
            maxWidth: '490px',
            padding: '8px 12px',
        },
        updatePaymentInfoLink: { cursor: 'pointer' },
        updateLicenseSpan: { display: 'inline-block' },
    }),
}));

const EcommerceLicenseInfo: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const currentAccountName = useSelector(accountLogicalName);
    const language = useLocalization();
    const logEcommerceEvent = useEcommerceTelemetry();

    const {
        data: planDetails, isValidating: loading,
    } = useSWR(
        {
            accountName: currentAccountName,
            url: `${billingUrl}/planDetails/licenseInfoSection`,
        },
        fetchPlanDetails,
    );

    const { data: ecommerceHistoryStatus } = useSWR(
        {
            accountName: currentAccountName,
            url: `${billingUrl}/historyStatus`,
        },
        fetchHistoryStatus,
    );

    const [
        endDate,
        nextPayment,
        upcomingBillValue,
        currency,
        subscriptionId,
        hasLicenceUpdateSubscriptionSchedule,
        hasPriceChangesSubscriptionSchedule,
    ] =
        useMemo(() => [
            planDetails?.endDate,
            planDetails?.nextPayment,
            planDetails?.upcomingBill,
            planDetails?.currency,
            planDetails?.subscriptionId,
            planDetails?.hasLicenceUpdateSubscriptionSchedule ?? false,
            planDetails?.hasPriceChangesSubscriptionSchedule ?? false,
        ],
        [ planDetails ],
        );

    const {
        shouldEnableUpdateLicenseButton,
        updateLicenseButtonTooltipTextId,
        paymentInfoLinkText,
    } = useMemo(() =>
        getLicenseInfoUiState(hasLicenceUpdateSubscriptionSchedule, hasPriceChangesSubscriptionSchedule,
            upcomingBillValue, ecommerceHistoryStatus),
    [
        upcomingBillValue,
        ecommerceHistoryStatus,
        hasLicenceUpdateSubscriptionSchedule,
        hasPriceChangesSubscriptionSchedule,
    ]);

    const getUpcomingBillValue = useCallback((upcomingBillCurrency: string) => (
        <EcommercePrice
            value={upcomingBillValue}
            loading={false}
            gap
            currency={upcomingBillCurrency}
            dataCy="ecommerce-upcoming-bill-price"
        />
    ), [ upcomingBillValue ]);

    const getUpcomingBillSection = useCallback(() => upcomingBillValue ? (
        <div className={clsx(classes.row, classes.colorForeground)}>
            {currency && getUpcomingBillValue(currency)}
            <UiText className={classes.fontSizeM}>
                {translate({ id: 'CLIENT_TAX' })}
            </UiText>
        </div>
    ) : (
        <UiText className={clsx(classes.fontSizeM, classes.colorForeground)}>
            {translate({ id: 'CLIENT_NONE' })}
        </UiText>
    ), [ classes, currency, getUpcomingBillValue, translate, upcomingBillValue ]);

    const [ licensePendingChangeInfoTooltipOpen, setLicensePendingChangeInfoTooltipOpen ] = React.useState(false);

    const handleLicensePendingChangeInfoTooltipClose = useCallback(() => {
        setLicensePendingChangeInfoTooltipOpen(false);
    }, [ setLicensePendingChangeInfoTooltipOpen ]);

    const handleLicensePendingChangeInfoTooltipOpen = useCallback(() => {
        setLicensePendingChangeInfoTooltipOpen(true);
    }, [ setLicensePendingChangeInfoTooltipOpen ]);

    useEffect(() => {
        if (planDetails?.planType) {
            storePlanType(planDetails?.planType);
        }
    },
    [ planDetails ]);
    return loading ? (
        <div className={classes.box}>
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        </div>
    ) : (planDetails ? (
        <div className={classes.box}>
            <div className={classes.infoSection}>
                <div>
                    <UiText className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_PLAN_TYPE' })}
                    </UiText>
                    <UiText
                        className={clsx(classes.fontSizeM, classes.colorForeground)}
                        data-cy="ecommerce-plan-type">
                        {translate({ id: `CLIENT_${planDetails?.planType}_PAYMENT` })}
                    </UiText>
                </div>
                <div className={classes.column}>
                    <UiText className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_NEXT_PAYMENT_DATE' })}
                    </UiText>
                    <div
                        className={classes.fontSizeM}
                        data-cy="ecommerce-next-payment-date">
                        {nextPayment ? (
                            <UiText className={clsx(classes.fontSizeM, classes.colorForeground)}>
                                {formatDate(endDate, language)}
                            </UiText>
                        ) : (
                            <UiText
                                className={clsx(classes.fontSizeM, classes.colorForeground)}
                                data-cy="plan-end-message">
                                {translate(
                                    { id: 'CLIENT_PLAN_END_MESSAGE' },
                                    { 0: formatDate(endDate, language) },
                                )}
                            </UiText>
                        )}
                    </div>
                </div>
                <div className={classes.column}>
                    <UiText className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_HEADER_UPCOMING_BILL' })}
                    </UiText>
                    <div
                        className={classes.fontSizeM}
                        data-cy="ecommerce-upcoming-bill">
                        {getUpcomingBillSection()}
                    </div>
                </div>
                (
                <div className={classes.alignRight}>
                    <Tooltip
                        classes={{ tooltip: classes.tooltipBox }}
                        open={licensePendingChangeInfoTooltipOpen
                                && !shouldEnableUpdateLicenseButton
                                && !hasPriceChangesSubscriptionSchedule}
                        onClose={handleLicensePendingChangeInfoTooltipClose}
                        onOpen={handleLicensePendingChangeInfoTooltipOpen}
                        title={
                            <UiText
                                data-cy="license-pending-change-tooltip"
                                className={classes.infoTooltipText}>
                                {translate({ id: updateLicenseButtonTooltipTextId })}
                            </UiText>
                        }
                    >
                        <span className={classes.updateLicenseSpan}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    logEcommerceEvent('Licenses.UpdateLicenseQuantity', { Flow: INCREMENTAL_FLOW });
                                    navigate(getRoute(RouteNames.UpdateLicenseQuantity), { state: { subscriptionId } });
                                }}
                                id="updateLicenseQuantity"
                                data-cy="ecommerce-license-update-license-quantity"
                                disabled={!shouldEnableUpdateLicenseButton}
                            >
                                {translate({ id: 'CLIENT_UPDATE_LICENSE_QUANTITY' })}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div className={classes.supportSection} >
                <UiText className={classes.fontSizeM}>
                    {translate({ id: 'CLIENT_HAVE_ANY_QUESTIONS' })}
                </UiText>
                <Link
                    className={clsx(classes.contactSupport, classes.fontSizeMBold)}
                    href="mailto:cloud.billing@uipath.com"
                    data-cy="ecommerce-questions"
                    onClick={() => {
                        logEcommerceEvent('ManagePlan.ContactSupport');
                    }}
                >
                    {translate({ id: 'CLIENT_CONTACT_SUPPORT' })}
                </Link>
                <Link
                    className={clsx(classes.alignRight, classes.fontSizeMBold, classes.updatePaymentInfoLink)}
                    onClick={() => {
                        goToStripeCustomerPortalSession(currentAccountName);
                    }}
                    onKeyDown={(e: KeyboardEvent<HTMLAnchorElement> | KeyboardEvent<HTMLSpanElement>) => {
                        if (e.key === 'Enter') {
                            goToStripeCustomerPortalSession(currentAccountName);
                        }
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-cy="ecommerce-update-payment-info"
                    role='link'
                    tabIndex={0}
                >
                    {translate({ id: paymentInfoLinkText })}
                </Link>
            </div>
        </div>) : (<div />)
    );
};

export default EcommerceLicenseInfo;
