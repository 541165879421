import { UiText } from '@experiences/ui-common';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, { useMemo } from 'react';

import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import type { IServerityClassType } from './interfaces/notifications';
import { NotificationSeverity } from './interfaces/notifications';

const useStyles = makeStyles((theme) =>
    createStyles({
        unreadMainMessage: {
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            cursor: 'pointer',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: `${Tokens.FontFamily.FontMSize } !important`,
        },
        readMainMessage: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            fontSize: `${Tokens.FontFamily.FontMSize } !important`,
        },
        unreadMMessage: {
            cursor: 'pointer',
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            fontSize: `${Tokens.FontFamily.FontMSize } !important`,
        },
        severityInfoMessage: {
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            color: theme.palette.semantic.colorErrorIcon,
            backgroundColor: '#E9F1FA',
            cursor: 'default',
        },
        severitySuccessMessage: {
            fontWeight: `600 !important`,
            color: '#038108',
            backgroundColor: '#EEFFE5',
            cursor: 'default',
        },
        severityWarningMessage: {
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            color: theme.palette.semantic.colorWarningIcon,
            cursor: 'default',
        },
        successchip: {
            background: `#EEFFE5 !important`,
            height: `${Tokens.Padding.PadXxxl}`,
            marginLeft: `${Tokens.Padding.PadS}`,
            color: '#038108',
            fontSize: `${Tokens.FontFamily.FontXsSize}`,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        infoChip: {
            background: `#E9F1FA !important`,
            height: `${Tokens.Padding.PadXxxl}`,
            marginLeft: `${Tokens.Padding.PadS}`,
            color: '#1665B3',
            fontSize: `${Tokens.FontFamily.FontXsSize}`,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        warnChip: {
            background: `#FFF3DB !important`,
            height: `${Tokens.Padding.PadXxxl}`,
            marginLeft: `${Tokens.Padding.PadS}`,
            color: '#9E6100',
            fontSize: `${Tokens.FontFamily.FontXsSize}`,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        fatalChip: {
            background: `#FFF0F1 !important`,
            height: `${Tokens.Padding.PadXxxl}`,
            marginLeft: `${Tokens.Padding.PadS}`,
            color: '#A6040A',
            fontSize: `${Tokens.FontFamily.FontXsSize}`,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
    }),
);
const NotificationCellComponent: React.FC<{ isRead: boolean;isMain: boolean; text: string; severity: boolean }> = ({
    isRead, isMain, text, severity,
}) => {
    const classes = useStyles();

    const { chipClassName } = useMemo(() => {
        const severityTypes: IServerityClassType[] = [
            {
                value: NotificationSeverity.Info,
                chip: classes.infoChip,
            },
            {
                value: NotificationSeverity.Success,
                chip: classes.successchip,
            },
            {
                value: NotificationSeverity.Warn,
                chip: classes.warnChip,
            },
            {
                value: NotificationSeverity.Error,
                chip: classes.fatalChip,
            },
            {
                value: NotificationSeverity.Fatal,
                chip: classes.fatalChip,
            },
        ];
        const severityType: IServerityClassType = severityTypes.find(x => x.value === text) ?? {
            value: NotificationSeverity.Info,
            chip: classes.infoChip,
        };
        return { chipClassName: severityType.chip };
    }, [ classes.fatalChip, classes.infoChip, classes.successchip, classes.warnChip, text ]);
    return (
        <div>
            { !severity &&
            <UiText className={isRead ? classes.readMainMessage : isMain ?
                classes.unreadMainMessage : classes.unreadMMessage}>
                {decodeSanitizedHtml(text)}
            </UiText>}

            { severity && <Chip
                size="small"
                style={{ marginRight: '8px' }}
                label={text}
                className={chipClassName}
            />}
        </div>
    );
};

export default NotificationCellComponent;
