import {
    useEffect,
    useRef,
} from 'react';

export const useStorageListener = (callback: () => void) => {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [ callback ]);

    useEffect(() => {
        if (savedCallback?.current) {
            window.addEventListener('storage', savedCallback.current, { once: true });
        }

        return () => {
            if (savedCallback?.current) {
                window.removeEventListener('storage', savedCallback.current);
            }
        };
    }, [ callback ]);
};
