import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import useShowLearnMoreEcommerceDialog from './useShowLearnMoreEcommerceDialog';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        productTableCellTitle: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            paddingTop: '6px',
            paddingBottom: '6px',
        },
        productTableCellName: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            paddingTop: '2px',
            paddingBottom: '2px',
        },
        productTableCellDescription: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            paddingTop: '2px',
            paddingBottom: '2px',
        },
        learnMoreLink: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '4px',
            marginRight: '4px',
            color: theme.palette.semantic.colorForegroundLink,
        },
    }),
}));

const EcommerceProductTableCell: React.FC<{
    productCode: string;
    planType: string;
}> = ({
    productCode, planType,
}) => {

    const classes = useStyles();
    const showUiCustomizationDialog = useShowLearnMoreEcommerceDialog();
    const showDialogCallback = useCallback(async () => {
        await showUiCustomizationDialog(productCode, `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_TITLE_${productCode}`, planType);
    }, [ productCode, showUiCustomizationDialog, planType ]);
    const { formatMessage: translate } = useIntl();
    return (
        <div>
            <UiText
                className={classes.productTableCellTitle}
                data-cy={`product-cell-title-${productCode}`}>
                {translate({ id: `CLIENT_ECOMMERCE_BUY_INDIVIDUAL_TITLE_${productCode}` })}
            </UiText>
            <UiText
                className={classes.productTableCellName}
                data-cy={`product-cell-name-${productCode}`}>
                {translate({ id: `CLIENT_ECOMMERCE_BUY_INDIVIDUAL_NAME_${productCode}` })}
            </UiText>
            <UiText
                className={classes.productTableCellDescription}
                data-cy={`product-cell-description-${productCode}`}>
                {translate({ id: `CLIENT_ECOMMERCE_BUY_INDIVIDUAL_DESCRIPTION_${productCode}` })}
                <Link
                    component='button'
                    type='button'
                    data-cy={`product-cell-learn-more-${productCode}`}
                    className={classes.learnMoreLink}
                    onClick={showDialogCallback}
                    aria-label={translate({ id: `CLIENT_LEARN_MORE_ARIA_LABEL_${productCode}` })}
                >
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </Link>
            </UiText>
        </div>
    );
};

export default EcommerceProductTableCell;
