import type {
    Runs,
    StatusType,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getProcessesRecentRuns } from '../services/getProcessesRecentRuns';

export const RunsWidget: WidgetTemplate<Runs> = {
    name: 'Runs',
    titleKey: 'CLIENT_RUNS_WIDGET_TITLE',
    emptyMsgKey: () => 'CLIENT_RUNS_WIDGET_EMPTY',
    tableColumns: 4,
    tableHeaders: [
        {
            nameKey: 'CLIENT_TABLE_HEADER_AUTOMATION_NAME',
            colStyle: 'headerClickable',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_FOLDER',
            colStyle: 'detail',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_LASTRUN',
            colStyle: 'subDetail',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_STATUS',
            colStyle: 'statusText',
        },
    ],
    type: 'Table',
    fetch: (ctx: WidgetContext) => getProcessesRecentRuns(ctx.accessToken, ctx.tenant, ctx.accountLogicalName),
    clickHandler: (data: Runs, ctx: WidgetContext) => {
        window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/${localStorage.getItem(
            'PORTAL_CURRENT_TENANT',
        )}/processes_/${data.ProcessType.toLowerCase()}/${data.ProcessId}`);
    },
    transform: (data: Runs[]) => data.map((item) => {
        const {
            statusKey, statusType,
        } = resolveStatus(item.Status);

        return {
            data: item,
            category: 'Run',
            header: item.Name,
            description: item.OrganizationUnitFullyQualifiedName,
            icon: 'run',
            isSubtitleTime: true,
            subtitleTimePrefixKey: 'CLIENT_TABLE_HEADER_LASTRUN',
            subtitle: item.StartDate.toString(),
            statusKey,
            statusType,
        };
    }),
};

export function resolveStatus(status: string) {
    const statusMap: { [key: string]: StatusType } = {
        'Retried': 'warning',
        'Pending': 'warning',
        'Resumed': 'success',
        'Running': 'success',
        'Successful': 'success',
        'Stopped': 'error',
        'Stopping': 'error',
        'Suspended': 'error',
        'Terminating': 'error',
    };

    return {
        statusKey: `JOBSTATUS_${status.toUpperCase()}`,
        statusType: statusMap[status] ?? 'info',
    };
}
