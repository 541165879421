import {
    ExternalAppsTab,
    type ExternalAppsTabType,
} from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import ExternalAppsTabs from './subcomponents/ExternalAppsTabs';

const ExternalApps: React.FC<{ type: ExternalAppsTabType }> = ({ type }) => {
    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            data-cy="external-apps-panel"
            position='left'
        >
            <ExternalAppsTabs type={type} />
        </UiPageContainer>
    );
};

export const ExternalAppsWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.ExternalApplications,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
    ], [ organizationName, translate ]);

    const isAppRegistrationTab = useMatch({
        path: RouteNames.ExternalAppRegistrations,
        end: false,
    });
    const isApplicationsPATTab = useMatch({
        path: RouteNames.ExternalApplicationsPAT,
        end: false,
    });

    const tabType = useMemo(() => {
        if (isAppRegistrationTab) {
            return ExternalAppsTab.REG;
        } else if (isApplicationsPATTab) {
            return ExternalAppsTab.PAT;
        }
        return ExternalAppsTab.OAUTH;
    }, [ isAppRegistrationTab, isApplicationsPATTab ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <ExternalApps type={tabType} />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default ExternalApps;
