import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { IUserCIS } from '../../../common/interfaces/cis/user';

const useStyles = makeStyles(theme =>
    createStyles({
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: {
            width: '95%',
            margin: '0px 2px',
            marginBottom: '24px',
        },
        input: { marginTop: '12px' },
    }),
);

interface IEditUserAuthSettingsData {
    bypassBasicAuthRestriction: boolean;
}

export const getDefaultEditUserAuthSettingsData = (user?: IUserCIS): IEditUserAuthSettingsData => (
    { bypassBasicAuthRestriction: user?.bypassBasicAuthRestriction ?? false }
);

const EditUserAuthSettingsFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { control } = useFormContext<IEditUserAuthSettingsData>();

    return (
        <div className={classes.input}>
            <Controller
                name="bypassBasicAuthRestriction"
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        className={classes.switchFormControl}
                        control={
                            <Switch
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                                color="primary"
                                data-cy="edit-user-auth-settings-bypass-basic-auth-restriction"
                            />
                        }
                        label={
                            <div className={classes.switchLabel}>
                                {translate({ id: 'CLIENT_ALLOW_BYPASSING_LOGIN_RESTRICTION' })}
                            </div>
                        }
                    />
                )}
            />
        </div>
    );
};

export default EditUserAuthSettingsFormComponent;
