import type {
    AccessPolicyType,
    IAccessPolicyEntity,
} from '@experiences/interfaces';

import {
    get,
    put,
} from '../utility/Requests.default';

export const accessPolicyUrl = `${process.buildConfigs.identityApiBaseRoute}/PartitionAccessPolicy`;

interface IAccessPolicyPutPayload {
    entitiesToAdd: string[];
    entitiesToRemove: string[];
    entityType: AccessPolicyType;
}

export function getAccessPolicy({ partitionGlobalId }: { url: string; partitionGlobalId: string }) {
    return get<IAccessPolicyEntity[]>(`${accessPolicyUrl}/${partitionGlobalId}`);
}

export function putAccessPolicy(partitionGlobalId: string, payload: IAccessPolicyPutPayload) {
    return put<IAccessPolicyEntity[]>(`${accessPolicyUrl}/${partitionGlobalId}`, { body: payload });
}
