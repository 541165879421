import React, { useMemo } from 'react';
import type { RouteObject } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { AuditDialogComponentLegacy } from '../../component/audit/AuditDialogComponentLegacy';
import { AuditLegacyRoutingRedirectWithProvider } from '../../component/audit/AuditRedirect';
import ConfigureAADComponent from '../../component/authsettings/subcomponents/ConfigureAADComponent';
import ConfigureADComponent from '../../component/authsettings/subcomponents/ConfigureADComponent';
import ConfigureGoogleComponent from '../../component/authsettings/subcomponents/ConfigureGoogleComponent';
import ConfigureSamlComponent from '../../component/authsettings/subcomponents/ConfigureSamlComponent';
import EditPasswordPolicyComponent from '../../component/authsettings/subcomponents/EditPasswordPolicyComponent';
import SecuritySettingsADForm from '../../component/authsettings/subcomponents/SecuritySettingsADForm';
import EcommerceMonthlyUpsellComponent from '../../component/ecommerce/EcommerceMonthlyUpsellComponent';
import { LicensesWithParams } from '../../component/licensing/Licenses';
import ActivateLicenseOfflineComponent from '../../component/licensing/subcomponents/ActivateLicenseOfflineComponent';
import ActivateLicenseOnlineComponent from '../../component/licensing/subcomponents/ActivateLicenseOnlineComponent';
import AddEditOrganizationLicenseComponent from '../../component/licensing/subcomponents/AddEditOrganizationLicenseComponent';
import DeactivateLicenseOfflineComponent from '../../component/licensing/subcomponents/DeactivateLicenseOfflineComponent';
import HostOrganizationAdd from '../../component/organization/HostOrganization/OrganizationAdd';
import HostOrganizationLicense from '../../component/organization/HostOrganization/OrganizationLicense';
import HostOrganizationSettings from '../../component/organization/HostOrganization/OrganizationSettings';
import OrganizationPageComponent from '../../component/organization/OrganizationPageComponent';
import AddOrganizationComponent from '../../component/organization/subcomponents/AddOrganizationComponent';
import { OrganizationEmailSettingsRevampComponentWithProvider } from '../../component/organizationsettings/email/OrganizationEmailSettingsRevampComponent';
import OrganizationAdminHomeComponent from '../../component/organizationsettings/OrganizationAdminHome';
import OrganizationHome from '../../component/organizationsettings/OrganizationHome.onprem';
import { OrganizationSettingsPageComponentWithProvider } from '../../component/organizationsettings/OrganizationSettingsPageComponent';
import { SecuritySettingsPageComponentWithParams } from '../../component/securitySettings/SecuritySettings';
import AddEditUserComponent from '../../component/users/subcomponents/AddEditUserComponent';
import { UsersPageComponentWithProvider } from '../../component/users/UsersPageComponent';

const useHostPrivateShellRoutes = () => {
    const { isPro } = useCheckLicense();

    return useMemo<RouteObject[]>(() => [
        {
            path: RouteNames.Organizations,
            element: <OrganizationPageComponent />,
            children: [
                {
                    path: RouteNames.OrganizationsAdd,
                    element: <AddOrganizationComponent />,
                },
                {
                    path: RouteNames.OrganizationsLicense,
                    element: <AddEditOrganizationLicenseComponent />,
                },
            ],
        },
        {
            path: RouteNames.OrganizationAdminHome,
            element: <OrganizationAdminHomeComponent />,
        },
        {
            path: RouteNames.HostOrganizationSettings,
            element: <HostOrganizationSettings />,
        },
        {
            path: RouteNames.HostOrganizationLicense,
            element: <HostOrganizationLicense />,
        },
        {
            path: RouteNames.HostOrganizationAdd,
            element: <HostOrganizationAdd />,
        },
        {
            path: RouteNames.OrganizationHome,
            element: <OrganizationHome />,
        },
        {
            path: RouteNames.Users,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.UserAddEdit,
                    element: <AddEditUserComponent />,
                },
            ],
        },
        {
            path: RouteNames.AuthSettingsConfigureAD,
            element: <ConfigureADComponent />,
        },
        {
            path: RouteNames.AuthSettingsConfigureAAD,
            element: <ConfigureAADComponent />,
        },
        {
            path: RouteNames.AuthSettingsConfigureGoogle,
            element: <ConfigureGoogleComponent />,
        },
        {
            path: RouteNames.AuthSettingsConfigureSaml,
            element: <ConfigureSamlComponent />,
        },
        {
            path: RouteNames.AuthSettingsConfigureADDirectory,
            element: <SecuritySettingsADForm />,
        },
        {
            path: RouteNames.Licensing,
            element: <LicensesWithParams />,
            children: [
                {
                    path: RouteNames.ActivateOffline,
                    element: <ActivateLicenseOfflineComponent />,
                },
                {
                    path: RouteNames.LicensingActivateLicenseOnline,
                    element: isPro ? <EcommerceMonthlyUpsellComponent /> : <ActivateLicenseOnlineComponent />,
                },
                {
                    path: RouteNames.DeactivateOffline,
                    element: <DeactivateLicenseOfflineComponent />,
                },
            ],
        },
        {
            path: RouteNames.EmailSettings,
            element: <OrganizationEmailSettingsRevampComponentWithProvider />,
        },
        {
            path: RouteNames.LoginCustomization,
            element: <OrganizationSettingsPageComponentWithProvider />,
        },
        {
            path: RouteNames.AuditLogs,
            element: <AuditLegacyRoutingRedirectWithProvider />,
            children: [
                {
                    path: RouteNames.AuditLogDialog,
                    element: <AuditDialogComponentLegacy />,
                },
            ],
        },
        {
            path: RouteNames.Audit,
            element: <AuditLegacyRoutingRedirectWithProvider unifiedAuditRoute />,
        },
        {
            path: RouteNames.SessionPolicy,
            element: <SecuritySettingsPageComponentWithParams />,
        },
        {
            path: RouteNames.SecuritySettings,
            element: <SecuritySettingsPageComponentWithParams />,
            children: [
                {
                    path: RouteNames.AuthSettingsPasswordPolicy,
                    element: <EditPasswordPolicyComponent />,
                },
            ],
        },
    ], [ isPro ]);
};

export default useHostPrivateShellRoutes;
