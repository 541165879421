import { useLocalization } from '@experiences/locales';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    validateAccountLogicalName,
} from '@experiences/util';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { accountSetting } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import type { IAddOrganizationData } from '../../../common/interfaces/organization';
import {
    addOnPremOrganization,
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../../services/organization/OnPremOrganizationService';
import { mapOnPremOrganizationDto } from '../../../util/OnPremOrganizationUtil';
import validateEmail from '../../../util/validators/EmailValidator';
import EditPasswordFormComponent, { defaultEditPasswordData } from '../../common/EditPasswordFormComponent';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';

const useStyles = makeStyles((theme) =>
    createStyles({
        input: { marginTop: 20 },
        inputLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: {
            marginLeft: '24px',
            marginBottom: '12px',
        },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        inputEndAdornment: { color: theme.palette.semantic.colorForegroundDisable },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        labelGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        standaloneInfo: {
            marginLeft: '24px',
            marginBottom: '12px',
            display: 'flex',
            backgroundColor: theme.palette.semantic.colorInfoBackground,
            borderColor: theme.palette.semantic.colorInfoForeground,
            borderWidth: '1px',
            borderStyle: 'solid',
        },
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            margin: '12px 9.5px 0px 9.5px',
        },
        textBox: {
            margin: '10px 10px 10px 0px',
            color: theme.palette.semantic.colorForeground,
        },
        removeLicenseAllocationButton: { margin: '0px 0px 12px 24px' },
    }),
);

const HostOrganizationAdd: React.FC = () => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const organizationName = useOrganizationName();

    const [ loading, setLoading ] = useState(false);
    const [ takenUsername, setTakenUsername ] = useState<string>();
    const methods = useForm<IAddOrganizationData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditPasswordData,
            organizationName: '',
            adminUserName: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
        },
    });
    const language = useLocalization();
    const {
        formState: {
            errors, isDirty,
        }, control, trigger, handleSubmit,
    } = methods;

    const {
        data: response, mutate,
    } = useSWR(
        { url: `${onPremOrganizationManagementUri}/getAllOrganizations` },
        getAllOnPremOrganizations,
    );

    const isOrganizationNameTaken = useCallback(async (orgName: string) =>
        response?.results.some(result => result.name?.toLowerCase() === orgName.toLowerCase()),
    [ response?.results ]);

    const onSubmit = useCallback(
        async (data: IAddOrganizationData) => {
            setLoading(true);
            try {
                if (await isOrganizationNameTaken(data.organizationName)) {
                    setTakenUsername(data.organizationName);
                    setLoading(false);
                    trigger('organizationName');
                    return;
                }
                await addOnPremOrganization(mapOnPremOrganizationDto(data, language));
                setLoading(false);
                mutate();
                navigate(getRoute(RouteNames.OrganizationAdminHome));
            } catch (error) {
                setLoading(false);
            }
        },
        [ getRoute, isOrganizationNameTaken, language, mutate, navigate, trigger ],
    );

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.HostOrganizationAdd,
            name: translate({ id: 'CLIENT_ADD_ORGANIZATION' }),
        },
    ], [ organizationName, translate ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            disableGutter={[ 'top' ]}
            maxWidth='1100px'>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => navigate(-1)}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="add-organization-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <>
                    <div className={classes.input}>
                        <Controller
                            name="organizationName"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: accountSetting.accountLogicalNameLength,
                                validate: {
                                    available: value => value !== takenUsername,
                                    valid: value => validateAccountLogicalName(value),
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus
                                    label={translate({ id: 'CLIENT_ORGANIZATION_NAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    required
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'organizationNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'organizationNameLabel' }}
                                    error={!!errors.organizationName}
                                    helperText={
                                        (errors.organizationName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                        || (errors.organizationName?.type === 'available' && translate({ id: 'CLIENT_ORGANIZATION_NAME_TAKEN_ERROR' }))
                                        || ((errors.organizationName?.type === 'valid' || errors.organizationName?.type === 'maxLength')
                                            && translate(
                                                { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME_ORG' },
                                                { 0: accountSetting.accountLogicalNameLength },
                                            ))
                                    }
                                    data-cy="add-organization-name"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            name="adminUserName"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_USERNAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    required
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'usernameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'usernameLabel' }}
                                    error={!!errors.adminUserName}
                                    helperText={errors.adminUserName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    data-cy="add-organization-admin-username"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            name="adminFirstName"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_FIRST_NAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    required
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'firstNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'firstNameLabel' }}
                                    error={!!errors.adminFirstName}
                                    helperText={errors.adminFirstName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    data-cy="add-organization-admin-first-name"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            name="adminLastName"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_LAST_NAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    required
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'lastNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'lastNameLabel' }}
                                    error={!!errors.adminLastName}
                                    helperText={errors.adminLastName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    data-cy="add-organization-admin-last-name"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            name="adminEmail"
                            control={control}
                            rules={{
                                required: true,
                                validate: { valid: value => validateEmail(value) },
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_EMAIL' })}
                                    required
                                    error={!!errors.adminEmail}
                                    helperText={
                                        errors.adminEmail
                                            ? (errors.adminEmail?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })) ||
                                              (errors.adminEmail?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                            : translate({ id: 'CLIENT_EMAIL_HELPER_TEXT' })
                                    }
                                    type="email"
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'adminEmailLabel' }}
                                    inputProps={{ 'aria-labelledby': 'adminEmailLabel' }}
                                    data-cy="add-organization-admin-email"
                                />}
                        />
                    </div>
                    <FormProvider {...methods}>
                        <EditPasswordFormComponent
                            respectPasswordRequirements
                            required
                            passwordLabel={translate({ id: 'CLIENT_ADMIN_PASSWORD' })}
                        />
                    </FormProvider>
                </>
            </UiForm>
        </UiPageContainer>
    );
};

export default HostOrganizationAdd;
