import { StatsigUserCookieKey } from '@experiences/ab-testing';
import { UiStorage } from '@experiences/util';
import Cookies from 'js-cookie';

import store from '../../store';
import { USER_LOGOUT_REQUEST } from '../../store/action/ActionTypes';

const clearOrganizationStorage = () => {
    localStorage.removeItem('organizationId');
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('CloudRPAAppState');

    Cookies.remove('organizationId');
    UiStorage.removeItem('lastUsedOrganizationId');
};

export const clearAuthStorage = () => {
    // Remove tokens and expiry time
    store.dispatch({ type: USER_LOGOUT_REQUEST });

    // Instead of clearing the local storage, selectivaly remove items that belongs to cloudrpa
    clearOrganizationStorage();

    localStorage.removeItem('auth0_state');
    Cookies.remove('auth0_state');

    localStorage.removeItem('token1');
    localStorage.removeItem('token2');
    localStorage.removeItem('guid');
    localStorage.removeItem('language');
    localStorage.removeItem('emailForUserinvite');

    Cookies.remove('guid');
    Cookies.remove(StatsigUserCookieKey);

    // Clear the keys from Insights so it is also logged out
    const insightsStoragePrefix = 'insights_';
    localStorage.removeItem(insightsStoragePrefix + 'token1');
    localStorage.removeItem(insightsStoragePrefix + 'token2');
    localStorage.removeItem(insightsStoragePrefix + 'idToken');
    localStorage.removeItem(insightsStoragePrefix + 'accessToken');
    localStorage.removeItem(insightsStoragePrefix + 'callbackurl');
    localStorage.removeItem(insightsStoragePrefix + 'auth0_state_queue');

    // Clear the keys from AI Fabric so it is also logged out
    localStorage.removeItem('APP_STATE_LOGGEDIN');

    const aifKeys = [];
    const oidcKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        // These keys are from the @uipath/auth-identity-js-client
        // file: lib/services/auth.security.common.ts
        // There is a GUID in the key and that is ignored

        if (key) {
            if (key.startsWith('_isAuthenticated')) {
                localStorage.setItem(key, 'false');
            } else if (key.startsWith('auth_id_token') ||
                        key.startsWith('auth_token') ||
                        key.startsWith('session_state') ||
                        key.startsWith('userData') ||
                        key.startsWith('code_verifier')) {
                aifKeys.push(key);
            } else if (key.indexOf('oidc.') > -1) {
                oidcKeys.push(key);
            }
        }
    }

    for (const key in aifKeys) {
        localStorage.removeItem(key);
    }

    oidcKeys.forEach(k => localStorage.removeItem(k));

    // Delete cookies created by webAuth library
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        if (cookie) {
            cookie = cookie.trim();
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.startsWith('com.auth0.auth')) {
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            }
        }
    }
};
