import { useCallback } from 'react';

import { useCheckAuthTypeFn } from './CheckAuthType';

export default function useGetAuthProvider() {
    const getAuthTypeFn = useCheckAuthTypeFn();

    const getAuthType = useCallback(async (token: string) => {
        const {
            checkTokenAuthIsBasic,
            checkTokenAuthIsMicrosoft,
            checkTokenAuthIsGoogle,
            checkTokenAuthIsLinkedIn,
            checkTokenAuthIsAzure,
            checkTokenAuthIsSAML,
            checkTokenAuthIsLocal,
        } = getAuthTypeFn(token);

        if (checkTokenAuthIsAzure || checkTokenAuthIsSAML) {
            return 'enterprisesso';
        } else if (checkTokenAuthIsMicrosoft) {
            return 'microsoft';
        } else if (checkTokenAuthIsGoogle) {
            return 'google';
        } else if (checkTokenAuthIsLinkedIn) {
            return 'linkedin';
        } else if (checkTokenAuthIsBasic || checkTokenAuthIsLocal) {
            return 'email';
        }

        return undefined;
    }, []);

    return getAuthType;
}
