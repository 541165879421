import { UiSelect } from '@experiences/ui-common';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useEcommerceEnabledCountries } from '../helpers/EcommerceHelpers';
import type { ISubscriptionForm } from '../interfaces/ecommerce';

const EcommerceState: React.FC<{
    disabled: boolean;
    states: string[];
    leftMargin?: boolean;
}> = ({
    disabled, states, leftMargin,
}) => {
    const { formatMessage: translate } = useIntl();
    const {
        control,
        watch,
        setValue,
        trigger,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const [ currentCountry, state ] = watch([ 'country', 'state' ]);
    const { englishCountryCodeCountries: englishCodes } = useEcommerceEnabledCountries();

    const currentCountryInEnglish = useMemo(
        () => (englishCodes as any)[currentCountry],
        [ currentCountry, englishCodes ],
    );

    const stateNameToStateTranslationKey = useMemo(
        () => {
            const translations = {} as { [k: string]: string };
            states.forEach(stateNameInEnglish => {
                translations[stateNameInEnglish] = ('CLIENT_STATE_' + currentCountryInEnglish + '_' + stateNameInEnglish)
                    .split(' ').join('_')
                    .toUpperCase();
            });
            return translations;
        }
        ,
        [ currentCountryInEnglish, states ],
    );

    useEffect(() => {
        const currentState = state;
        if (states && currentState && !states.includes(currentState)) {
            setValue('state', '');
            trigger('state');
        }
    }, [ state, states, setValue, trigger ]);

    return (
        <UiSelect
            control={control}
            name="state"
            style={{ marginLeft: leftMargin ? '12px' : '0px' }}
            inputLabel={translate({ id: 'CLIENT_STATE' })}
            options={stateNameToStateTranslationKey}
            required
            dataCy="ecommerce-form-state"
            error={!!errors.state}
            fullWidth
            disabled={disabled}
            helperText={
                (errors.state?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                (errors.state?.type === 'addressError' && translate({ id: 'CLIENT_CHECK_STATE' }))
            }
        />
    );
};

export default EcommerceState;
