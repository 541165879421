import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({ spacing: { marginBottom: '16px' } }),
);

const EcommerceAddressLine2: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { control } = useFormContext<ISubscriptionForm>();

    return (
        <Controller
            render={({ field }) => (
                <TextField
                    {...field}
                    className={classes.spacing}
                    variant="outlined"
                    id="addressLine2"
                    label={translate({ id: 'CLIENT_ADDRESS_LINE_2' })}
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                    disabled={disabled}
                    data-cy="ecommerce-form-address-line-2"
                />
            )}
            control={control}
            rules={{ required: false }}
            name="addressLine2"
        />
    );
};

export default EcommerceAddressLine2;
