import type { PermissionType } from '@experiences/interfaces';

export interface ObjectChip {
    data: any;
    icon: string;
    id: string;
    optionId?: string | null;
    tooltip: string;
    type: string;
    value: string;
}

export const validateString = (value: ObjectChip[]) => {
    const invalidChar = /[<>%&\\?/:]/;
    if (value.length > 0) {
        for (const chip of value) {
            if (invalidChar.test(chip.value)) {
                return false;
            }
        }
    }

    return true;
};

export const validateNumber = (value: ObjectChip[]) => {
    const numericOnly = /^0$|^-?[1-9]\d*$/;
    if (value.length > 0) {
        for (const chip of value) {
            if (!numericOnly.test(chip.value)) {
                return false;
            }
            if (Number(chip.value) < -2147483648 || Number(chip.value) > 2147483647) {
                return false;
            }
        }
    }
    return true;
};

export const validateRegex = (regex: RegExp, value: ObjectChip[]) => {
    if (value.length > 0) {
        for (const chip of value) {
            if (!regex.test(chip.value)) {
                return false;
            }
        }
    }

    return true;
};

export const validateName = (value: string) => {
    const invalidChar = /[<>%&\\?/:]/;
    if (invalidChar.test(value)) {
        return false;
    }
    return true;
};

export const hasPermission = (permissions: PermissionType[] | undefined, requiredPermissions: PermissionType[]) => {
    if (!permissions?.length) {
        return false;
    }
    for (const required of requiredPermissions) {
        if (!permissions.includes(required)) {
            return false;
        }
    }
    return true;
};

export const isValidRegex = (regex: string) => {
    try {
        new RegExp(regex);
        return true;
    } catch (e) {
        return false;
    }
};

