import { GlobalStyles } from '@experiences/theme';
import { UiAlertBanner } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isHostModeSelector } from '../../../store/selectors';

const useStyles = makeStyles(theme => (GlobalStyles(theme)));

export const UiHostDeprecationBanner: React.FC = () => {
    const classes = useStyles();
    const isHostMode = useSelector(isHostModeSelector);

    return (
        <>
            {isHostMode && (
                <div data-cy="ui-host-deprecation-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        <FormattedMessage
                            id="CLIENT_LICENSE_DEPRECATION_BANNER_FOR_HOST"
                            values={{
                                a: (chunk: React.ReactNode[]) => <a
                                    className={classes.a}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.uipath.com/overview/other/latest/overview/deprecation-timeline#automation-suite">
                                    {chunk}
                                </a>,
                                b: (chunk: React.ReactNode[]) => <a
                                    className={classes.a}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.uipath.com/company/contact-us/licensing-queries-activations">
                                    {chunk}
                                </a>,
                            }}
                        />
                    </UiAlertBanner>
                </div>
            )}
        </>
    );
};
