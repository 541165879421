import type {
    IExternalAppDataContext,
    IGridResource,
    IResource,
} from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useModalState,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { produce } from 'immer';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { getResourceToHiddenScopes } from '../../../common/constants/ResourceToHiddenScopes';
import * as RouteNames from '../../../common/constants/RouteNames';
import {
    externalApiResourceUrl,
    getExternalApiResources,
} from '../../../services/identity/ExternalApiResourceService';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IResourceForm } from './ScopesController';
import ScopeController from './ScopesController';

const useStyles = makeStyles(theme =>
    createStyles({
        body: {
            margin: '0px 24px',
            flex: '1 1 100%',
        },
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        searchBar: { width: '100%' },
        searchIcon: { marginRight: '4px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const ScopesTab = {
    USER: 'user',
    APPLICATION: 'application',
};

const TabPanel: React.FC<{
    value: string;
    resource?: IGridResource;
    selectedResource?: IResource;
    scopeSearch: string;
}> = ({
    value, resource, selectedResource, scopeSearch,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    switch (value) {
        case ScopesTab.USER:
            return (
                <div className={classes.input}>
                    {!selectedResource ? (
                        <UiText>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE_DESCRIPTION' })}
                        </UiText>
                    ) : (
                        <FormGroup>
                            <ScopeController
                                name="userScopes"
                                resource={resource}
                                selectedResource={selectedResource}
                                scopeSearch={scopeSearch}
                            />
                        </FormGroup>
                    )}
                </div>
            );
        case ScopesTab.APPLICATION:
            return (
                <div className={classes.input}>
                    {!selectedResource ? (
                        <UiText>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_APPLICATION_SCOPE_DESCRIPTION' })}
                        </UiText>
                    ) : (
                        <FormGroup>
                            <ScopeController
                                name="applicationScopes"
                                resource={resource}
                                selectedResource={selectedResource}
                                scopeSearch={scopeSearch}
                            />
                        </FormGroup>
                    )}
                </div>
            );
    }

    return null;
};

const ExternalApplicationsScopesDrawerComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        id, name,
    } = useParams<{ id: string; name: string }>();

    const {
        data: externalResources,
        error: resourcesError,
    } = useSWR<IResource[], Error>(externalApiResourceUrl, getExternalApiResources);

    const {
        data: contextData, setData,
    } = useUiDataContext<IExternalAppDataContext>();

    const {
        resources, confidential,
    } = contextData ?? {
        resources: undefined,
        confidential: undefined,
    };

    const editResource = useMemo(() => {
        if (!name) {
            return undefined;
        }

        const matchResource = resources?.find(resourceIterator => resourceIterator.name === name);
        if (!matchResource) {
            return undefined;
        }

        const result: IGridResource = {
            name: matchResource.name,
            userScopes: matchResource.userScopes,
            applicationScopes: matchResource.applicationScopes,
        };
        return result;
    }, [ name, resources ]);

    const filteredResources = useMemo(() => {
        const filteredExternalResources: IResource[] | undefined = !name
            ? externalResources?.filter(resourceIterator => !resources?.find(scope => scope.name === resourceIterator.name))
            : externalResources;

        return filteredExternalResources?.map(rsc => {
            const ResourceToHiddenScopes = getResourceToHiddenScopes();

            if (ResourceToHiddenScopes.has(rsc.name)) {
                const hiddenScopes = ResourceToHiddenScopes.get(rsc.name);
                const selectedUserScopes = resources?.find(resource => resource.name === name)?.userScopes ?? [];
                rsc.scopes = rsc.scopes.filter(scope => selectedUserScopes.includes(scope.name) || !hiddenScopes?.includes(scope.name));
            }
            return rsc;
        });
    }, [ externalResources, name, resources ]);

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { open } = useModalState(`${RouteNames.ExternalApplications}/${type}`);
    const close = useCallback(() => {
        navigate(getRoute(`${RouteNames.ExternalApplications}/${type}${type === 'edit' ? `/${id}` : ''}`));
    }, [ getRoute, id, navigate, type ]);

    const [ loading, setLoading ] = useState(false);
    const [ selectedResource, setSelectedResource ] = useState<IResource>();
    const [ scopeSearch, setScopeSearch ] = useState<string>('');
    const [ tabValue, setTabValue ] = useState<string>(ScopesTab.USER);

    const { mutate } = useSWRConfig();

    const methods = useForm<IResourceForm>({
        mode: 'onSubmit',
        shouldUnregister: false,
        defaultValues: {
            name: undefined,
            userScopes: [],
            applicationScopes: [],
        },
    });

    const {
        control, handleSubmit, reset, watch, formState: {
            isDirty, errors,
        },
    } = methods;

    const [ applicationScopes, userScopes ] = watch([ 'applicationScopes', 'userScopes' ]);

    useEffect(() => {
        if (name && editResource) {
            setSelectedResource(filteredResources?.find(external => external.name === editResource.name));
            reset({
                name: editResource,
                userScopes: editResource.userScopes,
                applicationScopes: editResource.applicationScopes,
            });
        }
    }, [ editResource, filteredResources, name, reset ]);

    const handleChangeSelect = useCallback(
        (event: SelectChangeEvent, onChange: any) => {
            const pickedResource = filteredResources?.find(resource => resource.name === (event.target.value as string));
            setSelectedResource(pickedResource);
            onChange(pickedResource);

            reset({
                name: pickedResource,
                userScopes: [],
                applicationScopes: [],
            });
        },
        [ filteredResources, reset ],
    );

    const onSubmit = useCallback(
        async (data: IResourceForm) => {
            setLoading(true);

            const resource: IGridResource = {
                name: data.name?.name ?? '',
                userScopes: data.userScopes ?? [],
                applicationScopes: data.applicationScopes ?? [],
            };

            setLoading(false);
            setData(produce(contextData, draftState => {
                if (!draftState.resources) {
                    draftState.resources = [];
                }

                const changedResource = draftState.resources.find(resourceIterator => resourceIterator.name === resource.name);
                if (!changedResource) {
                    draftState.resources.push(resource);
                    return;
                }

                changedResource.userScopes = resource.userScopes;
                changedResource.applicationScopes = resource.applicationScopes;
            }));
            close();
        },
        [ close, contextData, setData ],
    );

    return (
        <UiDrawer
            title={
                name
                    ? translate({ id: 'CLIENT_EXTERNAL_APP_EDIT_RESOURCE' })
                    : translate({ id: 'CLIENT_EXTERNAL_APP_ADD_RESOURCE' })
            }
            drawerProps={{
                anchor: 'right',
                open,
                onClose: (_e, reason) => reason !== 'backdropClick' && close(),
            }}
            loading={!externalResources}
            themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
        >
            {resourcesError ? (
                <div className={classes.body}>
                    <UiText>
                        {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
                    </UiText>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            mutate(externalApiResourceUrl);
                        }}
                        style={{ marginTop: '12px' }}
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                </div>
            ) : (
                <FormProvider {...methods}>
                    <UiForm
                        onSubmit={handleSubmit(onSubmit)}
                        actions={
                            <div className={classes.actions}>
                                <Button
                                    data-cy="scopes-add-edit-cancel-button"
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={
                                        !isDirty
                                            || Object.keys(errors).length > 0
                                            || (userScopes?.length ?? 0) + (applicationScopes?.length ?? 0) <= 0
                                    }
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="scopes-add-edit-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        }
                        isDrawer
                        addScrollPadding
                    >
                        <div className={classes.input}>
                            <UiText
                                id="scopes-resource-name-label"
                                className={clsx(classes.inputLabel, classes.inputMargin)}>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPES_RESOURCE' })}
                            </UiText>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    required: true,
                                    maxLength: 256,
                                }}
                                render={({ field }) => (
                                    <Select
                                        labelId="scopes-resource-name-label"
                                        value={field.value?.name ?? ''}
                                        onChange={event => handleChangeSelect(event, field.onChange)}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!!name}
                                        error={!!errors.name}
                                        data-cy="scope-resource-select"
                                    >
                                        {filteredResources?.map(resource => (
                                            <MenuItem
                                                key={`resource-select-${resource.name}`}
                                                value={resource.name}>
                                                {resource.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </div>
                        <Tabs
                            value={tabValue}
                            className={clsx('default', classes.input)}
                            onChange={(_: any, newValue: string) => setTabValue(newValue)}
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab
                                value={ScopesTab.USER}
                                label={translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE' })}
                                data-cy="scopes-user-tab"
                                className="default"
                            />
                            {confidential && (
                                <Tab
                                    value={ScopesTab.APPLICATION}
                                    label={translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_SCOPE' })}
                                    data-cy="scopes-application-tab"
                                    className="default"
                                />
                            )}
                        </Tabs>
                        <div className={classes.input}>
                            <TextField
                                className={classes.searchBar}
                                variant="outlined"
                                placeholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                                InputProps={{ startAdornment: <SearchIcon className={classes.searchIcon} /> }}
                                value={scopeSearch}
                                onChange={e => setScopeSearch(e.target.value)}
                                data-cy="scope-searchBar"
                            />
                        </div>
                        <TabPanel
                            value={tabValue}
                            resource={editResource}
                            selectedResource={selectedResource}
                            scopeSearch={scopeSearch}
                        />
                    </UiForm>
                </FormProvider>
            )}
        </UiDrawer>
    );
};

export const ExternalApplicationsScopesDrawerComponentWithParams: React.FC = () => {
    const { type } = useParams<{ type: 'add' | 'edit' }>();

    return <ExternalApplicationsScopesDrawerComponent type={type ?? 'add'} />;
};

export default ExternalApplicationsScopesDrawerComponent;
