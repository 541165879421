import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../common/constants/RouteNames';
import useIsDedicated from '../../common/hooks/useIsDedicated';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { isAdminSelector } from '../../store/selectors';
import { UiDropdownButton } from '../common/UiDropdownButton/UiDropdownButton';
import { UiHostDeprecationBanner } from '../common/UiHostDeprecationBanner/UiHostDeprecationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';

const useStyles = makeStyles(theme =>
    createStyles({
        unlicensedContainer: {
            background: theme.palette.semantic.colorBackground,
            flexDirection: 'column',
            flexGrow: 'inherit',
            display: 'flex',
        },
        unlicensedOptionsContainer: {
            display: 'flex',
            flexGrow: 'inherit',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '20%',
        },
        centered: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            marginBottom: '12px',
        },
        regularText: { marginBottom: '12px' },
        activateAction: { marginBottom: '12px' },
        button: {
            margin: '0px 8px',
            padding: '0px 16px',
            minWidth: '120px',
        },
    }),
);

export const Unlicensed = () => {

    const { formatMessage: translate } = useIntl();

    const isAdmin = useSelector(isAdminSelector);
    const isDedicated = useIsDedicated();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const classes = useStyles();

    return (
        <div className={classes.unlicensedContainer}>
            <div
                className={classes.unlicensedOptionsContainer}
                data-testid='unlicensed-container'
                data-cy='unlicensed-container'>
                <div className={classes.centered}>
                    <UiText
                        className={classes.headerText}
                        data-testid="unlicensed-header">
                        {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                    </UiText>
                    <UiText
                        className={classes.regularText}
                        data-testid="unlicensed-text">
                        {translate({ id: 'CLIENT_ACTIVATE_LICENSE_GET_STARTED' })}
                    </UiText>
                    <div>
                        {isDedicated ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    navigate(getRoute(RouteNames.LicensingActivateLicenseOnline));
                                }}
                                className={classes.button}
                                data-cy="license-header-activate-online-button"
                            >
                                {translate({ id: 'CLIENT_ACTIVATE_LICENSE' })}
                            </Button>
                        )
                            : (
                                <UiDropdownButton
                                    label={translate({ id: 'CLIENT_ACTIVATE_LICENSE' })}
                                    disabled={!isAdmin}
                                    tooltip={isAdmin ? undefined : translate({ id: 'CLIENT_ACTIVATE_LICENSE_ADMINS_ONLY' })}
                                    menuItems={[
                                        {
                                            onClick: () => {
                                                navigate(getRoute(RouteNames.LicensingActivateLicenseOnline));
                                            },
                                            label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                                        },
                                        {
                                            onClick: () => {
                                                navigate(getRoute(RouteNames.ActivateOffline));
                                            },
                                            label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                                        },
                                    ]}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UnlicensedComponent = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();
    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.Licensing,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ], [ organizationName, translate ]);
    return <UiPageContainer
        breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
        banner={
            <UiHostDeprecationBanner />
        }
        position='left'>
        <Unlicensed />
    </UiPageContainer>;
};
