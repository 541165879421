import { GlobalStyles } from '@experiences/theme';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import type { IActionHeader } from './grid';
import UiMoreRowActionsHeaderComponent from './UiMoreRowActionsHeaderComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        extraActionButtons: {
            display: 'flex',
            flexDirection: 'row',
        },
        extraActionIcon: { display: 'auto' },
        '@media all and (max-width: 1080px)': { extraActionButtons: { display: 'none' } },
        '@media all and (min-width: 1080px)': { extraActionIcon: { display: 'none' } },
    }),
}));

const HeaderButton: React.FC<{ action: IActionHeader }> = ({ action }) => (
    <>
        <Button
            className={action.className}
            startIcon={action.icon}
            endIcon={action.endIcon}
            variant={action?.variant}
            onClick={action.click}
            disabled={action.disable}
            data-cy={action.dataCy}
            id={action.id}
            aria-controls={action.ariaControls}
            aria-haspopup={action.ariaHaspopup}
            aria-expanded={action.ariaExpanded}
        >
            {action.label}
        </Button>
        {action.children}
    </>
);

const UiGridHeaderActionButtonsComponent: React.FC<{ extraActionButtons?: IActionHeader[] }> = ({ extraActionButtons }) => {
    const classes = useStyles();

    return extraActionButtons && extraActionButtons.filter(actionButton => !actionButton.invisible).length > 0 ? (
        <>
            <div
                className={classes.extraActionButtons}
                data-cy="extra-action-button-header">
                {extraActionButtons.map(
                    (action, index) =>
                        !action.invisible &&
            (action.toolTip ? (
                <Tooltip
                    key={index}
                    arrow
                    title={action.toolTip}
                    data-cy={action.label === 'Add Tenant' ? 'ui-grid-primary-tooltip' : null}
                >
                    <span>
                        <HeaderButton
                            key={index}
                            action={action} />
                    </span>
                </Tooltip>
            ) : (
                <HeaderButton
                    key={index}
                    action={action} />
            )),
                )}
            </div>
            <div
                className={classes.extraActionIcon}
                data-cy="minimized-more-icon-header">
                <UiMoreRowActionsHeaderComponent actions={extraActionButtons} />
            </div>
        </>
    ) : null;
};

export default UiGridHeaderActionButtonsComponent;
