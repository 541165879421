import type { IServiceUsageResponse } from '@experiences/interfaces';
import { roundTo2Decimals } from '@experiences/util';
import {
    ColorBlueSecondary500,
    ColorBrandAccessoryPurple,
    ColorCodeNumericLight,
    ColorGray500,
    ColorGreen500,
    ColorOrange500,
    ColorRed500,
} from '@uipath/apollo-core/lib/_generated/Colors';
import { Chart } from '@uipath/apollo-lab/react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { useConsumableToServicesMap } from '../../../common/constants/Constant';
import {
    getConsumptionData,
    licenseUsageUrl,
} from '../../../services/licensing/LicenseUsageService';
import { UiChartLoading } from '../helperComponents/UiConsumableCard/UiChartLoading';

const indexToColorMap: { [key: number]: string } = {
    0: ColorBlueSecondary500,
    1: ColorBrandAccessoryPurple,
    2: ColorCodeNumericLight,
    3: ColorOrange500,
    4: ColorRed500,
    5: ColorGreen500,
    6: ColorGray500,
};

const minNumberOfPointsInGraph = 5;
export const DailyConsumptionGraph: React.FC<{
    startDate: number;
    endDate: number;
    consumableCode: string;
    services?: string[];
    tenantId?: string;
}> = ({
    startDate,
    endDate,
    consumableCode,
    services,
    tenantId,
}) => {
    const { formatMessage: translate } = useIntl();
    const consumableTOServicesMap = useConsumableToServicesMap();
    const { data: consumptionData } = useSWR(
        {
            url: `${licenseUsageUrl}/services`,
            startDate,
            endDate,
            consumableCode,
            services: services ?? consumableTOServicesMap[consumableCode],
            aggregationPeriod: 'day',
            tenantId,
        },
        getConsumptionData,
    );

    const strToDate = useCallback((date: string) => {
        const [ day, month, year ] = date.split('/');
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }, []);

    const numberOfDaysBetweenCalendarDates = useCallback((date1: Date, date2: Date) => {
        const dateOnly1 = new Date(date1.getUTCFullYear(), date1.getUTCMonth(), date1. getUTCDate());
        const dateOnly2 = new Date(date2.getUTCFullYear(), date2.getUTCMonth(), date2. getUTCDate());
        const diffInMilliseconds = dateOnly2.getTime() - dateOnly1.getTime();
        return Math.ceil(diffInMilliseconds / (1000 * 3600 * 24));
    }, []);

    const dateToGraphIndex = useCallback((date: string) => {
        const beginningOfDayInUtc = new Date(strToDate(date).getTime() - strToDate(date).getTimezoneOffset() * 60 * 1000);

        return numberOfDaysBetweenCalendarDates(new Date(startDate * 1000), beginningOfDayInUtc);
    },
    [ numberOfDaysBetweenCalendarDates, startDate, strToDate ]);

    const numberOfDaysToShowInGraph = useMemo(() => {
        const currentDateIndex = numberOfDaysBetweenCalendarDates(new Date(startDate * 1000), new Date()) + 1;
        return Math.max(currentDateIndex, minNumberOfPointsInGraph);
    }
    , [ numberOfDaysBetweenCalendarDates, startDate ]);

    const consumptionDataWithDefaults: Array<{ service: string; data: number[]; colorIndex: number }> = useMemo(() => {
        if (!consumptionData) {
            return [];
        }

        return consumptionData.servicesUsage.map((serviceUsage: IServiceUsageResponse, index) => {
            const consumptionPerDay: number[] = new Array(numberOfDaysToShowInGraph).fill(0);
            serviceUsage.usages.forEach((usage) => {
                const graphIndex = dateToGraphIndex(usage.usageAt);
                if (index < numberOfDaysToShowInGraph) {
                    consumptionPerDay[graphIndex] = roundTo2Decimals(usage.consumedQuantity);
                }
            });

            return {
                service: serviceUsage.serviceName,
                data: consumptionPerDay,
                colorIndex: index,
            };
        }
        );
    }, [ consumptionData, dateToGraphIndex, numberOfDaysToShowInGraph ]);

    const labels = useMemo(() => {
        const labelsToReturn: string[] = [];
        for (let i = 0; i < numberOfDaysToShowInGraph; i++) {
            const date = new Date(startDate * 1000 + i * 3600 * 24 * 1000);
            labelsToReturn.push(`${date.getUTCDate()}`);
        }
        return labelsToReturn;
    }, [ numberOfDaysToShowInGraph, startDate ]);

    if (!consumptionData?.servicesUsage) {
        return <UiChartLoading
            width={150}
            height={36} />;
    }
    return <Chart
        className={`daily-consumption-graph-${consumableCode}`}
        data={consumptionDataWithDefaults.map((consumption) => ({
            data: consumption.data,
            renderer: 'line',
            color: indexToColorMap[consumption.colorIndex],
            title: translate({ id: `CLIENT_CONSUMABLE_GRAPH_${consumption.service}` }),
            isCompareData: false,
        })
        )}
        labels={labels}
        legendProperties={{ hasLegend: true }}
    />;
};
