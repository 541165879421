import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import validateEmail from '../../../util/validators/EmailValidator';
import UiForm from '../../common/UiForm';
import useVerifyMailSettingsDialogBodyViewModel from './VerifyMailSettingsDialogBodyViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '12px',
        },
    }),
);

const VerifyMailSettingsDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, emailSettingsData,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        methods: {
            register,
            handleSubmit,
            formState: {
                isDirty,
                isSubmitting,
                errors,
            },
        },
        onSubmit,
    } = useVerifyMailSettingsDialogBodyViewModel(emailSettingsData, closeDialog);

    return <UiForm
        style={{ width: '416px' }}
        onSubmit={handleSubmit(onSubmit)}
        disableActionsGutters
        actions={
            <div className={classes.actions}>
                <Button
                    className={classes.cancelButton}
                    onClick={() => closeDialog(false)}
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isDirty}
                    variant="contained"
                    data-cy="verify-email-settings-send-button"
                >
                    {translate({ id: 'CLIENT_SEND' })}
                </UiProgressButton>
            </div>
        }
    >
        <FormControl>
            <FormLabel
                id="sendToLabel"
                className={clsx(classes.inputLabel, classes.inputMargin)}
                required>
                {translate({ id: 'CLIENT_SEND_TO' })}
            </FormLabel>
            <TextField
                variant="outlined"
                type="email"
                error={!!errors.recipient}
                helperText={errors.recipient?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })}
                autoComplete="off"
                fullWidth
                data-cy="verify-email-settings-recipient"
                inputProps={{
                    'aria-labelledby': 'sendToLabel',
                    ...register('recipient', { validate: { valid: value => !value || validateEmail(value) } }),
                }}
            />
        </FormControl>
    </UiForm>;
};

export default VerifyMailSettingsDialogBody;
