import { ErrorCodeMapping } from './ErrorCodeMapping';

export class UiError {
    private code: string;

    constructor(code: number) {
        this.code = code.toString();
    }

    // use for static functions
    getMessage = () => ErrorCodeMapping[this.code]?.message;
}
