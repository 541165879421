import { productCodeToTranslationCode } from '../../../../common/constants/Constant';

export const sortedServiceTypes = [ 'orchestrator', 'aifabric', 'dataservice', 'aiservices' ];
export const sortedProductCodes = [
    'UNATT',
    'UNATT-HOSTING',
    'NONPR',
    'TAUNATT',
    'ACR',
    'ATTNU',
    'ATTCU',
    'STDNU',
    'STDCU',
    'STDXNU',
    'STDXCU',
    'AIW',
    'AIG',
    'STDPRONU',
    'STDPROCU',
    'DSU',
    'AIU',
];

export const concurrencyMapping: Record<string, string> = {
    ATTNU: 'ATTCU',
    ATTCU: 'ATTNU',
    STDNU: 'STDCU',
    STDCU: 'STDNU',
    STDXNU: 'STDXCU',
    STDXCU: 'STDXNU',
    STDPRONU: 'STDPROCU',
    STDPROCU: 'STDPRONU',
};

const serviceToProductMapping: Record<string, string[]> = {
    orchestrator: [
        'UNATT',
        'UNATT-HOSTING',
        'NONPR',
        'TAUNATT',
        'ACR',
        'ATTNU',
        'ATTCU',
        'STDNU',
        'STDCU',
        'STDXNU',
        'STDXCU',
        'STDPRONU',
        'STDPROCU',
        'RU',
    ],
    aifabric: [
        'AIW',
        'AIG',
    ],
    dataservice: [ 'DSU' ],
};
export const getServiceFromProduct = (product: string) => {
    const matchedKV = Object.entries(serviceToProductMapping).find(([ _service, productList ]) => productList.includes(product));

    return matchedKV?.[0];
};

export const productSubLabel = (licenseType: string) => {
    if (licenseType.toLowerCase().indexOf('named') > -1) {
        return 'NAMED_USER';
    } else if (licenseType.toLowerCase() === 'attendedrobots') {
        return 'NAMED_USER';
    } else if (licenseType.toLowerCase().includes('concurrent')) {
        return 'CONCURRENT_USER';
    } else if ([ 'dataserviceunit', 'ru', 'aiu' ].includes(licenseType.toLowerCase())) {
        return '';
    }
    return 'RUNTIME';
};

export const translationCode = (productCode: string) => productCodeToTranslationCode.get(productCode) ?? productCode;
