import { useGetErrorInfo } from '@experiences/error';
import type { TagType } from '@experiences/interfaces';
import { RestrictedTagLabel } from '@experiences/interfaces';
import { PortalTagManagementEvent } from '@experiences/telemetry';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { ITagLabelDto } from '../../../../services/orchestrator/TagsService.default';
import {
    createLabel,
    getLabel,
    tagsUrl,
    updateLabel,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    tenantByIdUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import TenantTagsSaveWarningDialogBody from '../../common/TenantTagsSaveWarningDialogBody';
import { useTagsCancel } from '../../common/useTagsCancel';
import { validateName } from '../properties/TenantTagsUtil';

const DEFAULT_VALUES = {
    name: '',
    description: '',
    type: 'Label' as TagType,
};

const useAddEditTenantTagsLabelsViewModel = ({ type }: { type: 'add' | 'edit' }) => {
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const {
        tenantId, id,
    } = useParams() as { tenantId: string; id: string };
    const getRoute = useRouteResolver();

    const organizationName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);

    const createNotification = useUiSnackBar();
    const { logEvent } = useTelemetryHelper();

    const { getErrorMessage } = useGetErrorInfo();
    const createDialog = useShowDialog();

    const {
        register, handleSubmit, reset, setError, formState, watch,
    } = useForm<ITagLabelDto>({
        mode: 'onSubmit',
        defaultValues: DEFAULT_VALUES,
    });

    const { isDirty } = formState;

    const cancel = useTagsCancel(isDirty, true);

    const {
        data: tenant, isValidating: tenantLoading,
    } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const {
        data: labelData, isValidating: labelLoading,
    } = useSWR(
        type === 'edit' && ((id && tenant) || (process.buildConfigs.showForMSI && accountId && id))
            ? {
                url: `${tagsUrl}/label`,
                accountLogicalName: organizationName,
                tenantName: tenant?.name,
                id,
                selectedAccountId: accountId,
            }
            : null,
        getLabel,
    );

    useEffect(() => {
        if (labelData) {
            reset({
                name: labelData.name ?? '',
                description: labelData.description ?? '',
                type: labelData.dataType as TagType,
            });
        }
    }, [ id, labelData, organizationName, reset, tenant ]);

    const onSubmit = useCallback(async (data: ITagLabelDto) => {
        try {
            if (data.name && !validateName(data.name)) {
                setError('name', {
                    type: 'invalid',
                    message: translate({ id: 'CLIENT_TENANT_TAGS_NAME_ERROR' }),
                });
                return;
            }
            if (data.name && RestrictedTagLabel.includes(data.name)) {
                setError('name', {
                    type: 'restricted',
                    message: translate({ id: 'ClIENT_TENANT_TAGS_NAME_RESTRICTED_ERROR' }, { label: data.name }),
                });
                return;
            }
            if (type === 'add') {
                await createLabel(
                    organizationName,
                    tenant?.name, {
                        ...data,
                        type: 'Label',
                    },
                    accountId,
                );
                logEvent(PortalTagManagementEvent.CreateLabel);
            } else {
                if (labelData?.entityReferencesCount) {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TENANT_TAGS_OBJECTS_AFFECTED_BY_EDIT_TITLE' }),
                        customDialogContent: TenantTagsSaveWarningDialogBody,
                        icon: 'info',
                    });
                    if (!proceed) {
                        return;
                    }
                }
                await updateLabel(
                    organizationName,
                    tenant?.name,
                    labelData?.key,
                    {
                        ...data,
                        type: 'Label',
                    },
                    accountId,
                );
                logEvent(PortalTagManagementEvent.EditLabel);
            }
            createNotification(translate({ id: 'CLIENT_YOUR_CHANGES_WERE_SAVED_SUCCESSFULLY' }), notificationType.SUCCESS);
            navigate(getRoute(process.buildConfigs.showForMSI
                ? RouteNames.TenantTagsLabelsMsi
                : RouteNames.TenantTagsLabels.replace(':tenantId', tenantId)));
        } catch (e) {
            const message = await getErrorMessage(e);
            if (message.includes('already exists')) {
                setError('name', {
                    type: 'duplicate',
                    message: translate({ id: 'CLIENT_DUPLICATE_NAME' }),
                });
            }
        }
    }, [
        accountId,
        createDialog,
        createNotification,
        getErrorMessage,
        getRoute,
        labelData?.entityReferencesCount,
        labelData?.key,
        logEvent,
        navigate,
        organizationName,
        setError,
        tenant?.name,
        tenantId,
        translate,
        type,
    ]);

    return {
        type,
        label: labelData,
        tenantId,
        tenant,
        cancel,
        loading: labelLoading || tenantLoading,
        methods: {
            watch,
            register,
            formState,
            handleSubmit: handleSubmit(onSubmit),
        },
        onSubmit,
    };
};

export default useAddEditTenantTagsLabelsViewModel;
