import type {
    AccountType,
    IPersonaFit,
    Persona,
} from '@experiences/interfaces';

export function isDominantPersona(personaFit: IPersonaFit | undefined, persona: Persona): boolean {
    if (personaFit === undefined || personaFit.fit.length === 0) {
        return false;
    }

    if (personaFit.fit[0].persona === 'UNKNOWN') {
        return false;
    }

    const highestWeight = personaFit.fit &&
            personaFit.fit.length > 0 &&
            personaFit.fit[0].weight;

    const dominantPersonas = personaFit?.fit.filter(p => p.weight === highestWeight);
    return dominantPersonas?.some(p => p.persona === persona);
}

export function isPersonaAutomationExpressUser(personaFit: IPersonaFit | undefined, accountType: AccountType, isAdmin: boolean): boolean {
    if (accountType === 'FREEKIT') {
        return true;
    }

    const isAutomationExpressDominant = isDominantPersona(personaFit, 'AUTOMATION_EXPRESS_USER');
    return isAutomationExpressDominant && !isAdmin;
}
