import { UiDialog } from '@experiences/ui-common';
import { ApButton } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

const RevokeRefreshTokenConfirmation: React.FC<{
    revokeTokenHandler: () => void;
    close: () => void;
}> = ({
    revokeTokenHandler, close,
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_API_ACCESS' })}
            close={close}
            width="650px"
            actions={[
                <ApButton
                    key="cancelButton"
                    onClick={close}
                    variant='tertiary'
                    data-cy="revoke-refresh-token-cancel"
                    label={translate({ id: 'CLIENT_CANCEL' })}
                />,
                <ApButton
                    key="primaryButton"
                    variant='destructive'
                    data-cy="revoke-refresh-token-submit"
                    onClick={revokeTokenHandler}
                    label={translate({ id: 'CLIENT_REVOKE' })}
                />,
            ]}
        >
            <div>
                <div>
                    {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE1' })}
                </div>
                <div>
                    {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE2' })}
                </div>
                <div>
                    {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE3' })}
                    <ul>
                        <li>
                            {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE4' })}
                        </li>
                        <li>
                            {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE5' })}
                        </li>
                        <li>
                            {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE6' })}
                        </li>
                    </ul>
                </div>
            </div>
        </UiDialog>
    );
};

export default RevokeRefreshTokenConfirmation;
