import { UiText } from '@experiences/ui-common';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { SplunkConnDetails } from './ConnectionDetailsType';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

export const SplunkInputs: React.FC = () => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control,
        formState: { errors },
    } = useFormContext<SplunkConnDetails>();

    return <>
        <div className={classes.input}>
            <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                {translate({ id: 'SPLUNK_ENDPOINT' })}
            </UiText>
            <Controller
                control={control}
                rules={{
                    required: true,
                    pattern: /http.*/,
                }}
                name="endpoint"
                render={({ field }) => (
                    <TextField
                        {...field}
                        type="text"
                        data-cy="logs-connection-endpoint"
                        variant="outlined"
                        error={!!errors.endpoint}
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                    />
                )}
            />
            <FormHelperText className={classes.breakAll}>
                {translate({ id: 'SPLUNK_PORT_REQUIREMENT' })}
            </FormHelperText>
        </div>

        <div className={classes.input}>
            <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                {translate({ id: 'SPLUNK_AUTH_TOKEN' })}
            </UiText>
            <Controller
                control={control}
                rules={{ required: true }}
                name="authToken"
                render={({ field }) => (
                    <TextField
                        {...field}
                        type="password"
                        data-cy="logs-connection-authToken"
                        variant="outlined"
                        error={!!errors.authToken}
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                    />
                )}
            />
        </div>
    </>;
};
