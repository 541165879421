import {
    SecuritySettingEnum,
    type SecuritySettingType,
} from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import SecuritySettingsTabs from './subcomponents/SecuritySettingsTabs';

const SecuritySettingsPageComponent: React.FC<{ type: SecuritySettingType; position?: 'left' | 'center' }> = ({
    type, position = 'center',
}) => {
    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            position={position}>
            <SecuritySettingsTabs type={type} />
        </UiPageContainer>
    );
};

export const SecuritySettingsPageComponentWithParams: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
    ], [ organizationName, translate ]);

    const isSessionPolicy = useMatch({
        path: RouteNames.SessionPolicy,
        end: false,
    });
    const isIPRestriction = useMatch({
        path: RouteNames.IPRestriction,
        end: false,
    });
    const isEncryption = useMatch({
        path: RouteNames.Encryption,
        end: false,
    });
    const isOrganizationAccessPolicy = useMatch({
        path: RouteNames.OrganizationAccessPolicy,
        end: false,
    });

    const type = useMemo(() => {
        if (isSessionPolicy) {
            return SecuritySettingEnum.SessionPolicy;
        } else if (isIPRestriction) {
            return SecuritySettingEnum.IpRestriction;
        } else if (isEncryption) {
            return SecuritySettingEnum.Encryption;
        } else if (isOrganizationAccessPolicy) {
            return SecuritySettingEnum.AccessRestriction;
        }
        return SecuritySettingEnum.Authentication;
    }, [ isSessionPolicy, isIPRestriction, isEncryption, isOrganizationAccessPolicy ]);

    return (
        <BreadcrumbProvider
            breadcrumbs={breadCrumbLinks}
            legacy>
            <SecuritySettingsPageComponent
                type={type}
                position={isIPRestriction || isOrganizationAccessPolicy ? 'left' : 'center'} />
            <UiSuspensefulOutlet />
        </BreadcrumbProvider>
    );
};

export default SecuritySettingsPageComponent;
