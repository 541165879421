import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        root: { width: '100%' },
        section: { marginTop: '20px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const EditIdentityProviderFormComponent: FC = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext<{ isExclusive?: boolean; displayName?: string }>();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return (
        <>
            <div className={clsx(classes.root, classes.section)}>
                <Controller
                    name="isExclusive"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                    data-cy="edit-identity-provider-is-exclusive-switch"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_FORCE_AUTOMATIC_LOGIN' })}
                                </div>
                            }
                        />
                    )}
                />
            </div>
            <div className={classes.section}>
                <Controller
                    name="displayName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={translate({ id: 'CLIENT_DISPLAY_NAME' })}
                            error={!!errors.displayName}
                            helperText={
                                errors.displayName?.type === 'required' && translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_DISPLAY_NAME' }) },
                                )
                            }
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            InputLabelProps={{ id: 'displayNameLabel' }}
                            inputProps={{ 'aria-labelledby': 'displayNameLabel' }}
                            data-cy="edit-identity-provider-display-name"
                        />
                    )}
                />
            </div>
        </>
    );
};

export default EditIdentityProviderFormComponent;
