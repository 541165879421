import type {
    IGlobalExternalClient,
    IScope,
} from '@experiences/interfaces';

import {
    get,
    post,
} from '../utility/Requests.default';

export const globalOauthUrl = `${process.buildConfigs.identityApiBaseRoute}/GlobalExternalClient`;

export const getAllAvailableApplications = (_url: string) => get<IGlobalExternalClient[]>(`${globalOauthUrl}/all`);

export const getRegisteredApplication = ({ partitionGlobalId }: { url: string; partitionGlobalId: string }) =>
    get<IGlobalExternalClient[]>(`${globalOauthUrl}/Registrations/${partitionGlobalId}`);

export const registerApplication = (partitionGlobalId: string, clientId: string, scopes: IScope[]) =>
    post(`${globalOauthUrl}/Register`, {
        body: {
            partitionGlobalId,
            clientId,
            scopes,
        },
    });

export const unregisterApplication = (partitionGlobalId: string, clientId: string) =>
    post(`${globalOauthUrl}/Unregister`, {
        body: {
            partitionGlobalId,
            clientId,
        },
    });
