import { ensureZSuffix } from '@experiences/util';

import { ReservedUsernames } from '../../common/constants/UsersConstant';
import type { IUserCIS } from '../../common/interfaces/cis/user';
import type { IUserCISResponse } from '../../component/users/interfaces/userLicense';

export function cleanUserCISResponse(originalResponse: IUserCISResponse): IUserCISResponse {
    const results: IUserCIS[] = originalResponse.results.filter(
        user => !ReservedUsernames.some(reservedUsername => reservedUsername === user.userName),
    ).map(user => ({
        ...user,
        creationTime: ensureZSuffix(user.creationTime),
        lastModificationTime: ensureZSuffix(user.lastModificationTime),
        lastLoginTime: ensureZSuffix(user.lastLoginTime),
    }));
    return {
        totalCount: originalResponse.totalCount,
        results,
    };
}
