import { ECOMMERCE_PAYMENT_FAILED_BANNER_KEY } from '@experiences/ecommerce';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSWR from 'swr';

import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../../services/identity/SettingService';
import {
    billingUrl,
    fetchHistoryStatus,
} from '../../../../services/licensing/BillingService';

// Ignore cognitive complexity rule for ViewModel since it's more like a constructor than a function
// eslint-disable-next-line
function useEcommercePaymentFailedBannerViewModel(
    currentAccountName: string,
    partitionGlobalId: string,
    userId: string,
    isAdmin: boolean,
) {
    const [ closed, setClosed ] = useState(true);

    const { data: ecommerceHistoryStatus } = useSWR(
        isAdmin && !closed ? {
            accountName: currentAccountName,
            url: `${billingUrl}/historyStatus`,
        } : null,
        fetchHistoryStatus,
    );
    const {
        bannerMessage, shouldShowBanner,
    } = useMemo(() => {
        let message, shouldShow ;
        if (closed) {
            shouldShow = false;
        } else {
            if (ecommerceHistoryStatus?.firstPaymentStatus === 'PAYMENT_FAILED') {
                message = 'CLIENT_ECOMMERCE_PAYMENT_FAILED_BANNER';
                shouldShow = true;
            }
            if (ecommerceHistoryStatus?.incrementalPaymentStatus === 'PAYMENT_FAILED') {
                message = 'CLIENT_ECOMMERCE_INCREMENTAL_PAYMENT_FAILED_BANNER';
                shouldShow = true;
            }
        }
        return {
            bannerMessage: message,
            shouldShowBanner: shouldShow,
        };
    }, [ closed, ecommerceHistoryStatus?.firstPaymentStatus, ecommerceHistoryStatus?.incrementalPaymentStatus ]);

    useEffect(() => {
        (async () => {
            const result = await getSetting({
                url: settingUrl,
                key: [ ECOMMERCE_PAYMENT_FAILED_BANNER_KEY ],
                partitionGlobalId,
                userId,
            });
            const displayPaymentFailedBannerSettings = !!result?.length &&
                result.find(setting => setting.key === ECOMMERCE_PAYMENT_FAILED_BANNER_KEY);

            if (displayPaymentFailedBannerSettings) {
                const newValue = displayPaymentFailedBannerSettings.value === 'false';
                if (newValue !== closed) {
                    setClosed(newValue);
                }
            } else {
                if (closed) {
                    setClosed(false);
                }
            }
        })();
    }, [ closed, partitionGlobalId, userId ]);

    const onClose = useCallback(async () => {
        await saveSetting(settingUrl, {
            settings: [
                {
                    key: ECOMMERCE_PAYMENT_FAILED_BANNER_KEY,
                    value: 'false',
                },
            ],
            partitionGlobalId,
            userId,
        });
        setClosed(true);
    },
    [ partitionGlobalId, userId ],
    );
    return {
        onClose,
        bannerMessage,
        shouldShowBanner,
    };
}

export default useEcommercePaymentFailedBannerViewModel;
