import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import Info from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { getPriceString } from '../helpers/EcommerceHelpers';
import type {
    IOrderedProductDetails,
    IProduct,
    IProductPrice,
    IProductQuantityPrice,
} from '../interfaces/ecommerce';

const useStyles = makeStyles(theme =>
    createStyles({
        productRow: {
            display: 'flex',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        circularLoading: { borderRadius: '30px' },
        alignRight: {
            marginLeft: 'auto',
            textAlign: 'end',
        },
        tableCellTop: { verticalAlign: 'top' },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        subtotalRow: { marginBottom: '12px' },
        subtotalText: { color: theme.palette.semantic.colorForeground },
        fontSizeLBold: {
            height: '24px',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        fontSizeM: {
            height: '24px',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        amountDueTodayPrice: { marginLeft: 'auto' },
        amountDueTodayTax: { alignSelf: 'end' },
        infoIcon: {
            width: '15px',
            height: '20px',
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '11px',
            verticalAlign: 'middle',
        },
        infoTooltipText: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundInverse,
        },
        tooltipBox: {
            maxWidth: '490px',
            padding: '8px 12px',
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '3px',
        },
    }),
);

const EcommerceAmountDueToday: React.FC<{
    productPrices?: IProductPrice[];
    currency: string;
    productsToAdd: IProduct[];
    upcomingInvoiceProductPrices?: IOrderedProductDetails[];
    // eslint-disable-next-line react/no-unused-prop-types
    upcomingInvoiceProducts?: IProduct[];
    price?: number;
}> = ({
    productPrices,
    currency,
    productsToAdd,
    upcomingInvoiceProductPrices,
    price,
}) => {
    const classes = useStyles();
    const language = useLocalization();
    const getProratedValue = useCallback((productCode: string) => {
        if (upcomingInvoiceProductPrices) {
            const foundProduct = upcomingInvoiceProductPrices.find(invoiceProduct => invoiceProduct.code === productCode);
            if (foundProduct) {
                return foundProduct.totalPrice;
            }
        }
        return undefined;
    }, [ upcomingInvoiceProductPrices ]);

    const [ hiddenBreakdown, setHiddenBreakdown ] = useState(false);

    const upcomingInvoiceProductPricesByCode = useMemo(() => {
        const dictionaryProrationByProductCodes: { [productCode: string]: IOrderedProductDetails } = {};
        upcomingInvoiceProductPrices?.forEach(function(product) {
            dictionaryProrationByProductCodes[product.code] = product;
        });

        return dictionaryProrationByProductCodes;
    }, [ upcomingInvoiceProductPrices ]);

    const productPricesToAdd = useMemo(() => productsToAdd
        .filter(p => p.quantity > 0)
        .map((product) => {
            const productQuantityPrice = {
                code: product.code,
                quantity: product.quantity,
                isProrated: upcomingInvoiceProductPricesByCode[product.code]?.prorated ?? true,
            } as IProductQuantityPrice;
            if (productPrices?.length) {
                productQuantityPrice.proratedPrice = getProratedValue(product.code);
            }
            return productQuantityPrice;
        }), [ productsToAdd, productPrices, getProratedValue, upcomingInvoiceProductPricesByCode ]);

    const { formatMessage: translate } = useIntl();

    const newProductsPrice = useMemo(() => {
        if (price) {
            return price;
        }
        let priceTotal = 0;
        if (productPrices && productPricesToAdd) {
            productPricesToAdd.forEach(product => {
                const proratedValue = getProratedValue(product.code);
                if (proratedValue) {
                    priceTotal += proratedValue;
                }
            });
        }
        return priceTotal;
    }, [ price, productPricesToAdd, getProratedValue, productPrices ]);

    function decideProrationLabel(product: IProductQuantityPrice) {
        if (upcomingInvoiceProductPricesByCode[product.code] != null) {
            if (product.isProrated) {
                return translate({ id: 'CLIENT_PRORATED' });
            }
            return translate({ id: 'CLIENT_NON_PRORATED' });
        }
        return '';
    }

    function getProductPaymentInfoTooltipText(product: IProductQuantityPrice) {
        if (product.isProrated) {
            return translate({ id: 'CLIENT_PRORATED_INFO_TOOLTIP' });
        }
        return translate({ id: 'CLIENT_FULL_CHARGE_INFO_TOOLTIP' });
    }

    return (
        <>
            <div className={clsx(classes.row, classes.subtotalRow, classes.subtotalText)}>
                <UiText className={classes.fontSizeLBold}>
                    {translate({ id: `CLIENT_AMOUNT_DUE_TODAY` })}
                </UiText>
                {newProductsPrice ? (
                    <>
                        <UiText
                            className={clsx(classes.fontSizeM, classes.amountDueTodayPrice)}
                            data-cy="new-product-price">
                            {getPriceString(newProductsPrice, currency, language)}
                        </UiText>
                        <UiText className={classes.amountDueTodayTax}>
                            {translate({ id: `CLIENT_PLUS_TAX` })}
                        </UiText>
                    </>
                ) : (<CircularProgress
                    size={24}
                    className={clsx(classes.circularLoading, classes.alignRight)} />)}
            </div>
            <Link
                onClick={() => setHiddenBreakdown(!hiddenBreakdown)}
                underline="none"
                data-cy='link-toggle-breakdown'
            >
                {hiddenBreakdown ? translate({ id: 'CLIENT_SHOW_BREAKDOWN' }) : translate({ id: 'CLIENT_HIDE_BREAKDOWN' })}
            </Link>
            {!hiddenBreakdown && productPricesToAdd && (
                <table style={{ width: '100%' }}>
                    {productPricesToAdd.filter(p => p.quantity > 0).map(product =>
                        (<tr key={product.code + product.quantity}>
                            <td style={{
                                display: 'flex',
                                maxWidth: '320px',
                            }}>
                                <UiText data-cy={`product-to-add-name-${product.code}`}>
                                    {`${
                                        product.quantity > 0 ? `${product.quantity} ` : ''
                                    }${translate({ id: `CLIENT_PRODUCT_${product.code}` })}`}
                                    {' ' + decideProrationLabel(product)}
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltipBox }}
                                        role="tooltip"
                                        title={
                                            <UiText data-cy="product-payment-info-tooltip">
                                                <span className={classes.infoTooltipText}>
                                                    {getProductPaymentInfoTooltipText(product)}
                                                </span>
                                            </UiText>
                                        }
                                    >
                                        <Info
                                            className={classes.infoIcon}
                                            data-cy={`${product.code}-payment-info-icon`}
                                            tabIndex={0}
                                            aria-hidden={false}
                                            aria-label={getProductPaymentInfoTooltipText(product)} />
                                    </Tooltip>
                                </UiText>
                            </td>
                            <td className={classes.tableCellTop}>
                                {(productPrices && product.proratedPrice) ? (
                                    <UiText
                                        className={classes.alignRight}
                                        key={`${product.code}-${product.quantity}-${product.proratedPrice}`}
                                        data-cy={`product-to-add-price-${product.code}`}>
                                        {getPriceString(product.proratedPrice, currency, language)}
                                    </UiText>) : (<CircularProgress
                                    size={16}
                                    className={clsx(classes.circularLoading, classes.alignRight)} />
                                )}
                            </td>
                        </tr>))}
                </table>
            )}
        </>
    );
};

export default EcommerceAmountDueToday;
