import { AuthGuard } from '@uipath/auth-react';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { TenantOperationTrackerContextProvider } from '../component/tenants/TenantOperationTrackerContextProvider';
import { TenantsContextProvider } from '../component/tenants/TenantsContextProvider';
import useAuthParams from './hooks/AuthParams.default';
import PortalInitializationProtector from './PortalInitializationProtector';
import IdentityAuthProvider from './token/IdentityAuthProvider';

const ProtectedRoute = ({ children }: PropsWithChildren<any>) => {
    const params = useAuthParams();

    return (
        <AuthGuard extraSigninRequestArgs={params}>
            <IdentityAuthProvider>
                <PortalInitializationProtector>
                    {
                        process.buildConfigs.disableTenantsContext ?
                            <>
                                {children}
                            </>
                            :
                            <TenantOperationTrackerContextProvider>
                                <TenantsContextProvider>
                                    {children}
                                </TenantsContextProvider>
                            </TenantOperationTrackerContextProvider>
                    }
                </PortalInitializationProtector>
            </IdentityAuthProvider>
        </AuthGuard>
    );
};

export default ProtectedRoute;
