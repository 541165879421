// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { useGetErrorInfo } from '@experiences/error';
import type { IDirectoryEntry } from '@experiences/interfaces';
import { DirectoryEntityType } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { useNavigateWithParams } from '@experiences/util';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import type { AxiosError } from 'axios';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, {
    useCallback,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    accessPolicyUrl,
    putAccessPolicy,
} from '../../../../services/identity/AccessPolicyService';
import { accountGlobalId } from '../../../../store/selectors';

interface IOrganizationAccessPolicyMembersForm {
    entitiesToAdd: IDirectoryEntry[];
}

const DEFAULT_FORM_VALUES: IOrganizationAccessPolicyMembersForm = { entitiesToAdd: [] };

const useAddOrganizationAccessPolicyMembersViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const navigate = useNavigateWithParams();

    const createNotification = useUiSnackBar();
    const [ errorMessage, setErrorMessage ] = useState<JSX.Element>();
    const { getErrorMessage } = useGetErrorInfo();

    const [ loading, setLoading ] = useState(false);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: DEFAULT_FORM_VALUES,
    });

    const {
        getValues, setValue, setError, clearErrors, watch,
    } = methods;

    const { mutate } = useSWRConfig();

    const peoplePickerChangedCallback = useCallback((event: any) => {
        const prevWithoutChip =
                    getValues('entitiesToAdd')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
        const curWithoutChip = event.detail.data.map((chip: IDirectoryEntry) => omit(chip, [ 'optionId', 'chipType' ]));
        const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
        setValue('entitiesToAdd', event.detail.data, {
            shouldDirty: valuesChanged,
            shouldValidate: valuesChanged,
        });
        setErrorMessage(undefined);
    }, [ getValues, setValue ]);

    const peoplePickerErrorCallback = useCallback((event: any) => {
        if (event.detail) {
            setError('entitiesToAdd', { type: 'invalid' });
        } else {
            clearErrors('entitiesToAdd');
        }
    }, [ clearErrors, setError ]);

    const peoplePickerLoadingCallback = useCallback((event: any) => setLoading(event.detail), []);

    const constructErrorMessage = useCallback((error: AxiosError) => {
        if (error.response?.status === 409) {
            const data: any = error.response?.data || {};
            let message = data.Message ?? '';
            let listOfFailed: string[] = [];
            if (message.includes('Could not add user')) {
                message = translate({ id: 'CLIENT_ORG_POLICY_DUPLICATE_USER_ERROR' });
                listOfFailed = data.ErrorDetails
                    .map((id: string) => watch('entitiesToAdd').
                        find(entity => entity.identifier === id && entity.type === DirectoryEntityType.user))
                    .filter((failed: IDirectoryEntry | undefined) => !!failed)
                    .map((failed: IDirectoryEntry) => `${failed.displayName} (${failed.email})`);
            } else if (message.includes('Could not add group')) {
                message = translate({ id: 'CLIENT_ORG_POLICY_DUPLICATE_GROUP_ERROR' });
                listOfFailed = data.ErrorDetails
                    .map((id: string) => watch('entitiesToAdd').
                        find(entity => entity.identifier === id && entity.type === DirectoryEntityType.group))
                    .filter((failed: IDirectoryEntry | undefined) => !!failed)
                    .map((failed: IDirectoryEntry) => failed.displayName);
            } else if (message.includes('Cannot add or remove Administrators')) {
                message = translate({ id: 'CLIENT_ORG_POLICY_ADMIN_ERROR' });
            }

            return (
                <div>
                    {message && (
                        <UiText>
                            {message}
                        </UiText>
                    )}
                    {listOfFailed.length > 0 && <List>
                        {listOfFailed.map((failed, i) => (
                            <ListItem key={`failed-item-${i}`}>
                                {failed}
                            </ListItem>
                        ))}
                    </List>}
                </div>
            );
        }
    }, [ translate, watch ]);

    const onSubmit = useCallback(async (payload: IOrganizationAccessPolicyMembersForm) => {
        try {
            setErrorMessage(undefined);

            const userEntities = payload.entitiesToAdd
                .filter(entity => entity.type === DirectoryEntityType.user)
                .map(entity => entity.identifier);
            const groupEntities = payload.entitiesToAdd
                .filter(entity => entity.type === DirectoryEntityType.group)
                .map(entity => entity.identifier);

            await Promise.all([
                // Put for users
                userEntities.length
                    ? putAccessPolicy(
                        partitionGlobalId,
                        {
                            entitiesToAdd: userEntities,
                            entitiesToRemove: [],
                            entityType: 'user',
                        }
                    )
                    : Promise.resolve(),
                // Put for groups
                groupEntities.length
                    ? putAccessPolicy(
                        partitionGlobalId,
                        {
                            entitiesToAdd: groupEntities,
                            entitiesToRemove: [],
                            entityType: 'group',
                        }
                    )
                    : Promise.resolve(),
            ]);
            createNotification(translate({ id: 'CLIENT_ACCESS_POLICY_SUCCESSFULLY_ADDED' }), notificationType.SUCCESS);
            await mutate({
                url: accessPolicyUrl,
                partitionGlobalId,
            });
            navigate(-1);
        } catch (error) {
            const awaitedError = await error;
            if (axios.isAxiosError(awaitedError)) {
                setErrorMessage(constructErrorMessage(awaitedError));
            } else {
                setErrorMessage(await getErrorMessage(error));
            }
        }

    }, [ constructErrorMessage, createNotification, getErrorMessage, mutate, navigate, partitionGlobalId, setErrorMessage, translate ]);

    return {
        loading,
        methods,
        peoplePickerChangedCallback,
        peoplePickerErrorCallback,
        peoplePickerLoadingCallback,
        onSubmit,
        errorMessage,
    };
};

export default useAddOrganizationAccessPolicyMembersViewModel;
