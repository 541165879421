import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
        },
    }),
}));

interface ICardAction {
    label: string;
    click: (...args: any) => void;
    disable?: boolean;
    invisible?: boolean;
    dataCy: string;
    ariaLabel: string;
}

interface IApplicationMoreRowActionsProps {
    actions: ICardAction[];
    disabled?: boolean;
}

export const UiApplicationMoreActionsComponent = ({
    actions, disabled = false,
}: IApplicationMoreRowActionsProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const clickMenuItem = useCallback((click: (...args: any) => void) => {
        setAnchorEl(null);
        click();
    }, []);

    return (
        <>
            <Tooltip title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    className={classes.showMoreButton}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    data-cy="ui-card-show-more-actions-button"
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                data-cy="ui-grid-show-more-actions-menu"
            >
                {actions.map((action, p) =>
                    (
                        action.invisible
                            ? null
                            : <MenuItem
                                key={p}
                                disabled={action.disable ?? disabled}
                                data-cy={action.dataCy}
                                className={classes.menuItem}
                                aria-labelledby={action.ariaLabel}
                                onClick={() => (action.click ? clickMenuItem(action.click) : undefined)}
                            >
                                <ListItemText
                                    primary={action.label}
                                />
                            </MenuItem>
                    ),
                )}
            </Menu>
        </>
    );
};
