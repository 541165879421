import type { IsEntitledMultiple } from '@experiences/interfaces';

import {
    get,
    post,
    put,
} from '../utility/Requests.default';

export const entitlementsUrl = '/api/license/accountant/Entitlement';

export async function isEntitled({ entitlement }: { url: string; entitlement: string }) {
    try {
        await get(`${entitlementsUrl}/${entitlement}`,
            {
                headers: {
                    extendRequestHeaders: 'true',
                    'cache-control': 'no-cache, no-store, must-revalidate',
                    'pragma': 'no-cache',
                },
            }
        );
        return true;
    } catch {
        return false;
    }
}

export async function isEntitledMultiple({ entitlements }: { url: string; entitlements: string[] }): Promise<IsEntitledMultiple | undefined> {
    try {
        return await post<IsEntitledMultiple>(`${entitlementsUrl}/multiple`, { body: { EntitlementNames: entitlements } });
    } catch {
        return undefined;
    }
}

export async function consumeEntitlement(entitlement: string) {
    await put(`${entitlementsUrl}/${entitlement}`, { body: { } });
    return true;
}
