import { ERROR_FIELD } from '@experiences/constants';
import type { WidgetTab } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { UiCardsWidgetProps } from './UiCardsWrapper';
import { UiCardsWrapper } from './UiCardsWrapper';

export interface UiCardsTabsWidgetProps<T> extends UiCardsWidgetProps<T> {
    tabs?: WidgetTab[];
    defaultTab?: string;
}

const CARD_HEIGHT = 186;

export const UiCardsWidget = <T extends {}>({
    cards, id, fixed = true, actionHandles, rows, defaultTab, tabs, clickHandler, cardHeight = CARD_HEIGHT,
}: UiCardsTabsWidgetProps<T>) => {
    const filteredTabs = useMemo(() => (
        tabs?.filter(tab => cards.find(card => card.tabCategory === tab.value && card.header !== ERROR_FIELD)) ?? []
    ), [ cards, tabs ]);
    const filteredCards = useMemo(() => cards.filter(card => card.header !== ERROR_FIELD) ?? [], [ cards ]);
    const [ value, setValue ] = React.useState(filteredTabs?.find(tab => tab.value === defaultTab)?.value ?? filteredTabs?.[0]?.value);
    const { formatMessage: translate } = useIntl();

    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        filteredTabs && filteredTabs.length > 0 && value ? <TabContext value={value}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
            }}>
                <TabList
                    onChange={handleChange}
                    aria-label={translate({ id: 'CLIENT_TAB_ARIA_LABEL' })}>
                    {
                        // eslint-disable-next-line max-len
                        filteredTabs.map(tab => <Tab
                            data-testid={`tab-${tab.value}`}
                            key={tab.label}
                            label={tab.labelKey ? translate({ id: tab.labelKey }) : tab.label}
                            value={tab.value} />)
                    }
                </TabList>
            </Box>
            {
                filteredTabs.length > 0 && filteredTabs.map(tab => {
                    const filterCardTabs = filteredCards.filter(card => card.tabCategory === tab.value) ?? [];
                    return <TabPanel
                        style={{ padding: '16px 0px 0px 0px' }}
                        key={tab.value}
                        value={tab.value}>
                        <>
                            {tab.descriptionKey && <UiText
                                style={{ marginBottom: '8px' }}
                                data-testid={`tab-description-${tab.value}`}>
                                {translate({ id: tab.descriptionKey })}
                            </UiText>}
                            <UiCardsWrapper
                                id={id}
                                cards={filterCardTabs}
                                fixed={fixed}
                                actionHandles={actionHandles}
                                rows={rows}
                                clickHandler={clickHandler}
                                cardHeight={cardHeight} />
                        </>
                    </TabPanel>;
                })
            }
        </TabContext> : <UiCardsWrapper
            id={id}
            cards={filteredCards}
            fixed={fixed}
            actionHandles={actionHandles}
            rows={rows}
            clickHandler={clickHandler}
            cardHeight={cardHeight} />
    );
};

export default UiCardsWidget;

