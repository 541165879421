import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

interface IUiDropdownButtonProps {
    tooltip?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
    menuItems: IUiDropdownButtonMenuItemProps[];
    width?: number;
}

interface IUiDropdownButtonMenuItemProps {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const UiDropdownButton: React.FC<IUiDropdownButtonProps> = ({
    tooltip,
    disabled,
    onClick,
    label,
    menuItems,
    width = 140,
}) => {
    const [ menuAnchorEl, setMenuAnchorEl ] = React.useState<null | HTMLElement>(null);

    return (
        <>
            <Tooltip
                arrow
                title={tooltip ?? ''}>
                <span>
                    <Button
                        disabled={disabled}
                        onClick={
                            onClick ?? (event => {
                                event.stopPropagation();
                                setMenuAnchorEl(event.currentTarget);
                            })
                        }
                        variant="contained"
                        data-testid="dropdown-btn-primary-action-button"
                        data-cy="dropdown-btn-primary-action-button"
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        {label}
                    </Button>
                </span>
            </Tooltip>
            <>
                {/* translate prop is required for Popper */}
                <Popper
                    open={!!menuAnchorEl}
                    anchorEl={menuAnchorEl}
                    placement="bottom-end"
                    translate={undefined}>
                    <ClickAwayListener onClickAway={() => setMenuAnchorEl(null)}>
                        <Paper>
                            <MenuList id="split-button-menu">
                                {menuItems.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        data-testid={`dropdown-btn-menu-item-${index}`}
                                        data-cy={`dropdown-btn-menu-item-${index}`}
                                        onClick={event => {
                                            setMenuAnchorEl(null);
                                            item.onClick?.(event);
                                        }}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </>
        </>
    );
};
