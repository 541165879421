import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    parseFile,
    useModalState,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { useUpdateUserProfilePersona } from '../../../common/hooks/useUpdateUserProfilePersona';
import type { IActivateOfflineData } from '../../../common/interfaces/license.activation';
import { licenseManagementAccountUrl } from '../../../services/licensing/management/AccountService';
import { activateOffline } from '../../../services/licensing/management/ActivationService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import {
    accountGlobalId,
    isHostModeSelector,
    profile,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { useTenantOperationTrackerContext } from '../../tenants/TenantOperationTrackerContextProvider';
import LicenseActivationErrorDialogBody from './LicenseActivationErrorDialogBody';
import LicenseActivationStepsComponent from './LicenseActivationStepsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

enum haveLicenseFile {
    YES = 'yes',
    NO = 'no',
}

const ActivateLicenseOfflineComponent: React.FC = () => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();
    const location = useLocation();

    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const updateUserProfilePersona = useUpdateUserProfilePersona();

    const accountGlobalGuid = useSelector(accountGlobalId);
    const accountProfile = useSelector(profile);
    const isHostMode = useSelector(isHostModeSelector);

    const [ isUpdateLicense, previousLocation, isTrialPerSkuActivation, customLicenseCode ] = useMemo(
        () => [
            location?.state?.isUpdateLicense,
            location?.state?.previousLocation,
            location?.state?.isTrialPerSkuActivation,
            location?.state?.customLicenseCode,
        ],
        [ location ],
    );
    const licenseCode = useMemo(
        () => customLicenseCode ? customLicenseCode : accountProfile.accountUserDto.licenseCode,
        [ accountProfile.accountUserDto.licenseCode, customLicenseCode ],
    );

    const [ loading, setLoading ] = useState(false);

    const { mutate } = useSWRConfig();

    const methods = useForm<IActivateOfflineData>({
        mode: 'onChange',
        defaultValues: {
            accountGlobalId: '',
            licenseCode: isUpdateLicense ? licenseCode : '',
            haveFile: haveLicenseFile.YES,
            file: undefined,
        },
    });

    const {
        handleSubmit, watch, control, formState: { errors }, register,
    } = methods;

    const {
        open, close,
    } = useModalState(getRoute(previousLocation ?? RouteNames.Licensing));

    const onSubmit = useCallback(
        async (data: any) => {
            setLoading(true);
            try {
                if (data.file?.[0]) {
                    const licenseActivationData = await parseFile(data.file[0]);
                    await activateOffline(licenseActivationData, isHostMode, !!isTrialPerSkuActivation);
                    createNotification(
                        translate({
                            id: isUpdateLicense
                                ? 'CLIENT_LICENSE_UPDATED_SUCCESSFULLY'
                                : 'CLIENT_ACTIVATION_PLATFORM_LICENSED',
                        }),
                        notificationType.SUCCESS,
                    );
                    updateUserProfilePersona();
                    await mutate(licenseManagementAccountUrl);
                    await mutate(`${licenseManagementAccountUrl}/available`);
                    await mutate(trialServicesSwrKey);
                    refreshAfterComplete('', true);
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                if (errorObject.response?.status === 422) {
                    setLoading(false);
                    await createDialog({
                        title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: LicenseActivationErrorDialogBody,
                        customDialogContentProps: { operationStatus: errorObject.response.data.operationStatus },
                    });
                    return;
                }
                setErrorMessage(await getErrorMessage(error));
            }
            close();
            setLoading(false);
            return;
        },
        [
            close,
            isHostMode,
            isTrialPerSkuActivation,
            createNotification,
            translate,
            isUpdateLicense,
            updateUserProfilePersona,
            refreshAfterComplete,
            getErrorObject,
            setErrorMessage,
            getErrorMessage,
            createDialog,
            mutate,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: isUpdateLicense ? 'CLIENT_UPDATE_LICENSE_OFFLINE' : 'CLIENT_ACTIVATE_OFFLINE' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={loading}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary"
                                    data-cy='cancel-button'>
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={loading}
                                    type="submit"
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: isUpdateLicense ? 'CLIENT_UPDATE' : 'CLIENT_ACTIVATE_BUTTON' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    <div className={classes.input}>
                        <UiText className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_ACCOUNT_ID' })}
                        </UiText>
                        <TextField
                            id="accountId"
                            disabled
                            fullWidth
                            value={accountGlobalGuid}
                            variant="outlined"
                            data-cy="activate-online-account-id"
                        />
                    </div>
                    {watch('haveFile') === haveLicenseFile.NO && (
                        <div className={classes.input}>
                            <UiText
                                id="licenseCode"
                                className={classes.inputLabel}>
                                {translate({ id: 'CLIENT_LICENSE_CODE' })}
                            </UiText>
                            <Controller
                                name="licenseCode"
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        inputProps={{ 'aria-labelledby': 'licenseCode' }}
                                        error={!!errors.licenseCode}
                                        helperText={
                                            errors.licenseCode?.type === 'license-format' &&
                                            translate({ id: 'CLIENT_ACTIVATION_INVALID_LICENSE_CODE' })
                                        }
                                        disabled={isUpdateLicense}
                                        fullWidth
                                        data-cy="licenseCode"
                                    />}
                            />
                        </div>
                    )}

                    <div className={classes.input}>
                        <UiText className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_LICENSE_FILE_QUESTION' })}
                        </UiText>
                        <Controller
                            name="haveFile"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    row>
                                    <FormControlLabel
                                        value={haveLicenseFile.YES}
                                        control={
                                            <Radio
                                                color="primary"
                                                data-cy='radio-yes'
                                            />
                                        }
                                        label={translate({ id: 'CLIENT_YES' })}
                                        data-cy="have-file"
                                    />
                                    <FormControlLabel
                                        value={haveLicenseFile.NO}
                                        control={
                                            <Radio
                                                color="primary"
                                                data-cy='radio-no'
                                            />
                                        }
                                        label={translate({ id: 'CLIENT_NO' })}
                                        data-cy="not-have-file"
                                    />
                                </RadioGroup>
                            )}
                        />
                    </div>
                    {watch('haveFile') === haveLicenseFile.NO &&
                        <LicenseActivationStepsComponent isTrialPerSkuActivation={!!isTrialPerSkuActivation} />}
                    {watch('haveFile') === haveLicenseFile.YES && (
                        <div className={classes.input}>
                            <UiText className={classes.inputLabel}>
                                {translate({ id: 'CLIENT_UPLOAD_LICENSE' })}
                            </UiText>
                            <input
                                {...register('file')}
                                type="file"
                                required
                                data-cy="file-input" />
                        </div>
                    )}
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default ActivateLicenseOfflineComponent;
