import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IResource } from '@experiences/interfaces';
import { ExternalApiResourceType } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    getDateFromNow,
    secondsToDays,
    timestampToDate,
    useModalState,
    useShowDialog,
} from '@experiences/util';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import useSWR, { useSWRConfig } from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useGetSetting } from '../../../common/hooks/useGetSetting';
import {
    externalApiResourceUrl,
    getExternalApiResources,
} from '../../../services/identity/ExternalApiResourceService';
import {
    generateReferenceToken,
    referenceTokenUrl,
} from '../../../services/identity/ReferenceTokenService';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IResourceForm } from '../../externalApps/subcomponents/ScopesController';
import ScopeController from '../../externalApps/subcomponents/ScopesController';
import DisplayAccessToken from './DisplayAccessToken';

const useStyles = makeStyles(theme =>
    createStyles({
        inputHeader: { display: 'flex' },
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
            marginTop: '28px',
        },
        mainHeading: { paddingBottom: '12px' },
        searchBar: { width: '100%' },
        searchIcon: { marginRight: '4px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),

);

interface IReferenceTokenForm extends IResourceForm {
    tokenName: string;
    expirationDate: number;
}

// https://github.com/UiPath/IdentityServer/blob/develop/src/Common/Constants.cs#L773
const LIFETIME_DEFAULT = 360;
const CreatePersonalAccessTokenDrawerComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();
    const {
        open, close,
    } = useModalState(RouteNames.PersonalAccessToken);

    const [ selectedResource, setSelectedResource ] = useState<IResource>();
    const [ scopeSearch, setScopeSearch ] = useState<string>('');
    const [ loading, setLoading ] = useState(false);
    const [ lifetimeMax, setLifetimeMax ] = useState(LIFETIME_DEFAULT);

    const { data: externalResources } = useSWR<IResource[], Error>(externalApiResourceUrl, getExternalApiResources);

    const filteredResources = useMemo(() => externalResources?.filter(resource => resource.scopes.some(scope =>
        scope.type === ExternalApiResourceType.User || scope.type === ExternalApiResourceType.UserAndApplication)), [ externalResources ]);

    const methods = useForm<IReferenceTokenForm>({
        mode: 'onChange',
        defaultValues: {
            tokenName: '',
            expirationDate: 0,
            name: undefined,
            userScopes: [],
            applicationScopes: [],
        },
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        setValue,
        formState: {
            dirtyFields, errors,
        },
    } = methods;

    const expirationValue = watch('expirationDate');

    const { mutate } = useSWRConfig();

    const { data: result } = useGetSetting([ 'ReferenceTokensLifetimeMax' ]);

    useEffect(() => {
        if (!result) {
            return;
        }
        const lifetimeMaxSetting = result.find(setting => setting.key === 'ReferenceTokensLifetimeMax');
        if (lifetimeMaxSetting?.value) {
            setLifetimeMax(parseInt(secondsToDays(parseInt(lifetimeMaxSetting.value))));
        }
    }, [ reset, result ]);

    const handleChangeSelect = useCallback(
        (event: SelectChangeEvent, onChange: any) => {
            const pickedResource = filteredResources?.find(resource => resource.name === (event.target.value as string));
            setSelectedResource(pickedResource);
            onChange(pickedResource);
            setValue('userScopes', [], { shouldDirty: true });
        },
        [ filteredResources, setValue ],
    );

    const onSubmit = useCallback(
        async (data: IReferenceTokenForm) => {
            setLoading(true);

            const days = Number(data.expirationDate);
            let latencyInSeconds = 0;
            if (days === 1) {
                // If the expiration is the minimum lifetime, we add 5 seconds to the expiration to account for network latency.
                latencyInSeconds = 5000;
            } else if (days === lifetimeMax) {
                // If the expiration is the maximum lifetime, we subtract 5 seconds from the expiration to account for network latency.
                latencyInSeconds = -5000;
            }
            const payload = {
                description: data.tokenName,
                expiration: new Date(getDateFromNow(days).getTime() + latencyInSeconds),
                scopes: data.userScopes,
            };

            try {
                const returnPayload = await generateReferenceToken(payload);

                setLoading(false);
                close();

                await createDialog({
                    title: translate({ id: 'CLIENT_TOKEN_GENERATED' }),
                    body: <DisplayAccessToken token={returnPayload} />,
                    icon: 'success',
                    primaryButtonText: translate({ id: 'CLIENT_CLOSE' }),
                    showCancel: false,
                });
            } catch (error) {
                const response = await getErrorMessage(error);
                setErrorMessage(response);
            } finally {
                setLoading(false);
                mutate(referenceTokenUrl);
            }
        },
        [
            lifetimeMax,
            close,
            createDialog,
            getErrorMessage,
            setErrorMessage,
            translate,
            mutate,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_GENERATE_PERSONAL_ACCESS_TOKEN' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width='medium'
            themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => close()}
                                color="primary">
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                loading={loading}
                                disabled={Object.keys(dirtyFields).length < 4 || expirationValue > lifetimeMax}
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                data-cy="token-add-submit-button"
                            >
                                {translate({ id: 'CLIENT_SAVE' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                    addScrollPadding
                >
                    <div style={{ paddingTop: '24px' }}>
                        <div className={classes.inputHeader}>
                            <TextField
                                label={translate({ id: 'CLIENT_NAME' })}
                                variant="outlined"
                                sx={{ width: '18ch' }}
                                inputProps={register('tokenName', { required: true })}
                                data-cy="generate-name"
                            />
                            <TextField
                                label={translate({ id: 'CLIENT_EXPIRATION_DATE' })}
                                type="number"
                                variant="outlined"
                                sx={{ width: '18ch' }}
                                style={{ marginLeft: 'auto' }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                            days
                                    </InputAdornment>,
                                    inputProps: {
                                        min: -10,
                                        max: lifetimeMax,
                                        ...register('expirationDate', { required: true }),
                                    },
                                }}
                                error={expirationValue > lifetimeMax}
                                helperText={expirationValue > lifetimeMax ? (
                                    translate({ id: 'CLIENT_LIFETIME_MESSAGE' }, { 0: lifetimeMax })
                                ) : (
                                    translate({ id: 'CLIENT_EXPIRES_ON' }, { 0: timestampToDate(getDateFromNow(expirationValue)) })
                                )}
                                data-cy="generate-expiration"
                            />
                        </div>
                        <UiText className={clsx(classes.heading, classes.mainHeading)}>
                            {translate({ id: 'CLIENT_SCOPES' })}
                        </UiText>
                        <div>
                            <UiText
                                id="scopes-resource-name-label"
                                className={clsx(classes.inputLabel, classes.inputMargin)}>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_RESOURCES' })}
                            </UiText>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    required: true,
                                    maxLength: 256,
                                }}
                                render={({ field }) => (
                                    <Select
                                        labelId="scopes-resource-name-label"
                                        value={field.value?.name ?? selectedResource?.name ?? ''}
                                        onChange={event => handleChangeSelect(event, field.onChange)}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.name}
                                        data-cy="scope-resource-select"
                                    >
                                        {filteredResources?.map(resource => (
                                            <MenuItem
                                                key={`resource-select-${resource.name}`}
                                                value={resource.name}>
                                                {resource.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <div className={classes.input}>
                                <TextField
                                    className={classes.searchBar}
                                    variant="outlined"
                                    placeholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                                    InputProps={{ startAdornment: <SearchIcon className={classes.searchIcon} /> }}
                                    value={scopeSearch}
                                    onChange={e => setScopeSearch(e.target.value)}
                                    data-cy="scope-search-bar"
                                />
                                <div className={classes.input}>
                                    {!selectedResource ? (
                                        <UiText>
                                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE_DESCRIPTION' })}
                                        </UiText>
                                    ) : (
                                        <FormGroup>
                                            <ScopeController
                                                name="userScopes"
                                                selectedResource={selectedResource}
                                                scopeSearch={scopeSearch}
                                            />
                                        </FormGroup>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default CreatePersonalAccessTokenDrawerComponent;
