import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    daysToSeconds,
    secondsToDays,
    useModalState,
} from '@experiences/util';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useGetSetting } from '../../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { referenceTokenUrl } from '../../../services/identity/ReferenceTokenService';
import {
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),

);

interface IExternalAppsPATData {
    enableRefToken: boolean;
    maxLifetime: string;
}

const ExternalApplicationsPATSettingsDrawerComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ loading, setLoading ] = useState(false);
    const [ loadSettings, setLoadSettings ] = useState(false);

    const createNotification = useUiSnackBar();

    const {
        open, close,
    } = useModalState(RouteNames.ExternalApplicationsPAT);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {
            isDirty, dirtyFields,
        },
        watch,
    } = useForm<IExternalAppsPATData>({
        mode: 'onChange',
        defaultValues: {
            enableRefToken: false,
            maxLifetime: '',
        },
    });

    const { mutate } = useSWRConfig();

    const {
        data: result, mutate: mutateSettings,
    } = useGetSetting([ 'ReferenceTokensEnabled', 'ReferenceTokensLifetimeMax' ]);

    useEffect(() => {
        setLoadSettings(true);
        const getSettingWithKeyEnabled = !!result?.length && result.find(setting => setting.key === 'ReferenceTokensEnabled');
        const getSettingWithKeyLifetimeMax = !!result?.length && result.find(setting => setting.key === 'ReferenceTokensLifetimeMax');

        reset({
            enableRefToken: getSettingWithKeyEnabled ? getSettingWithKeyEnabled.value.toLowerCase() === 'true' : false,
            maxLifetime: getSettingWithKeyLifetimeMax ? secondsToDays(parseInt(getSettingWithKeyLifetimeMax.value)) : '365',
        });

        setLoadSettings(false);
    }, [ reset, result ]);

    const onSubmit = useCallback(async (data: IExternalAppsPATData) => {
        setLoading(true);
        const enableToken = data.enableRefToken;

        try {
            await saveSetting(settingUrl, {
                settings: [
                    {
                        key: 'ReferenceTokensEnabled',
                        value: String(enableToken),
                    },
                    {
                        key: 'ReferenceTokensLifetimeMax',
                        value: daysToSeconds(parseInt(data.maxLifetime)),
                    },
                ],
                partitionGlobalId,
            });

            createNotification(translate({
                id:
                enableToken
                    ? 'CLIENT_SUCCESSFULLY_ENABLED_PAT'
                    : 'CLIENT_SUCCESSFULLY_DISABLED_PAT',
            }), notificationType.SUCCESS);

            mutate({
                url: referenceTokenUrl,
                partitionGlobalId,
            });
            mutateSettings();

            close();
        } catch (error) {
            createNotification(translate({ id: 'CLIENT_FAILED_TO_CHANGE_PAT' }), notificationType.ERROR);
        } finally {
            setLoading(false);
        }

    },
    [ close, createNotification, mutate, mutateSettings, partitionGlobalId, translate ]);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_SETTINGS' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="medium"
            themeProps={{ disableGutters: [ 'bottom' ] }}>
            {loadSettings
                ? <Box className={classes.loader}>
                    <CircularProgress />
                </Box>
                : <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => close()}
                                color="primary">
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                loading={loading}
                                onClick={handleSubmit(onSubmit)}
                                variant='contained'
                                disabled={!isDirty}
                                data-cy='token-settings-save-button'>
                                {translate({ id: 'CLIENT_SAVE' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                >
                    <FormControl>
                        <FormLabel className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_PAT_SUPPORT' })}
                        </FormLabel>
                        <Controller
                            name="enableRefToken"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={field.value}
                                            onChange={e => field.onChange(e.target.checked)}
                                            data-cy="enable-pat-switch"
                                        />
                                    }
                                    label={
                                        <UiText>
                                            {translate({ id: 'CLIENT_ENABLE_PERSONAL_ACCESS_TOKEN' })}
                                        </UiText>
                                    }
                                />
                            )}
                        />
                    </FormControl>
                    {dirtyFields.enableRefToken
                    && !watch('enableRefToken')
                    && <PortalAlertBar
                        cancelable={false}
                        status="warning"
                        data-cy="disable-token-message">
                        {translate({ id: 'CLIENT_PAT_DISABLE_WARNING' })}
                    </PortalAlertBar>}
                    <FormControl>
                        <FormLabel
                            style={{ marginTop: '12px' }}
                            className={clsx(classes.inputLabel, classes.inputMargin)}>
                            {translate({ id: 'CLIENT_MAXIMUM_LIFESPAN' })}
                        </FormLabel>
                        <TextField
                            type="number"
                            variant="outlined"
                            sx={{ width: '30ch' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {translate({ id: 'CLIENT_PAT_DAYS' })}
                                </InputAdornment>,
                                inputProps: {
                                    min: 1,
                                    max: 1800,
                                    ...register('maxLifetime', { required: true }),
                                },
                            }}
                            data-cy="token-maximum-lifetime"
                        />
                    </FormControl>
                </UiForm>}
        </UiDrawer>
    );

};

export default ExternalApplicationsPATSettingsDrawerComponent;
