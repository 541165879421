import {
    ConsumablesEvent,
    TrialServiceEvent,
} from '@experiences/telemetry';

import { dashboardLicenses } from './Constant';

export const TabTelemetryMap = new Map<string, string>([
    [ dashboardLicenses.TRIAL_SERVICES, TrialServiceEvent.NavigateTab ],
    [ dashboardLicenses.CONSUMABLES, ConsumablesEvent.NavigateTab ],
]);
