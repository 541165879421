/* eslint-disable linebreak-style */
import { GlobalStyles } from '@experiences/theme';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import {
    ANNUAL_PLAN_TYPE,
    MONTHLY_PLAN_TYPE,
} from '../../lib/helpers/EcommerceHelpers';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        columnGroup: {
            marginBottom: '14px',
            display: 'flex',
            flexDirection: 'row',
        },
        radio: {
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        chip: {
            paddingLeft: '8px',
            paddingRight: '8px',
            lineHeight: '16px',
            marginLeft: '4px',
            fontSize: '10px',
            fontWeight: 600,
            pointerEvents: 'none',
            backgroundColor: theme.palette.semantic.colorSuccessBackground,
            borderColor: theme.palette.semantic.colorSuccessIcon,
        },
    }),
}));

export const EcommercePlanTypeRadioGroup: React.FC<{
    setPlanType: (A: string) => void;
    planType: string;
    disabled?: boolean;
}> = ({
    setPlanType, planType,
    disabled,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (<RadioGroup
        data-cy="ecommerce-select-plan-type"
        className={classes.columnGroup}
        value={planType}
        onChange={event => {
            setPlanType(event.target.value);
        }}
    >
        <FormControlLabel
            disabled={disabled}
            key={1}
            className={classes.radio}
            data-cy="ecommerce-radio-annual-type"
            control={<Radio
                value={ANNUAL_PLAN_TYPE}
                checked={ANNUAL_PLAN_TYPE === planType}
                color="primary" />}
            label={<>
                {translate({ id: 'CLIENT_ANNUAL_LABEL' })}
                <Chip
                    clickable={false}
                    className={classes.chip}
                    variant="outlined"
                    size="small"
                    label={translate({ id: 'CLIENT_SAVE_WITH_ANNUAL' })} />
            </>}
        />
        <FormControlLabel
            disabled={disabled}
            key={2}
            data-cy="ecommerce-radio-monthly-type"
            className={classes.radio}
            control={<Radio
                value={MONTHLY_PLAN_TYPE}
                checked={MONTHLY_PLAN_TYPE === planType}
                color="primary" />}
            label={translate({ id: 'CLIENT_MONTHLY_LABEL' })}
        />
    </RadioGroup>);
};
