import {
    AccountLicense,
    Entitlements,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { SecuritySettingsEvent } from '@experiences/telemetry';
import {
    UiAlertBanner,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import type { Row } from 'react-table';
import useSWR from 'swr';

import { IPRestrictionLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import type { IIPNetwork } from '../../../../common/interfaces/iprestriction';
import { getIpConfigurationStatus } from '../../../../services/access-policy/IPConfigurationStatusService';
import {
    getCurrentIp,
    getIpNetworkByOrganizationId,
    ipNetworkUrl,
} from '../../../../services/access-policy/IPNetworkService';
import { isEntitled } from '../../../../services/licensing/EntitlementsService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../../store/selectors';
import { UiGrid } from '../../../common/UiGrid';
import type {
    IAction,
    IActionHeader,
} from '../../../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../../../common/UiGrid/grid';
import UpgradeForFeature from '../../../common/UpgradeForFeature';
import IPRestrictionConfirmationDialogBody from './IPRestrictionConfirmationDialogBody';
import IPRestrictionDeleteDialogBody from './IPRestrictionDeleteDialogBody';
import { validateIp } from './IPRestrictionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        headerButton: { marginLeft: '5px' },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        },
        centerLoader: { margin: 'auto' },
        container: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
        },
        enforcementTypeSelect: { width: '240px' },
        IPRestrictionDescription: { marginBottom: '8px' },
        label: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '8px',
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            marginRight: '24px',
        },
        link: { marginLeft: '8px' },
        mainContent: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '100%',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: '24px',
        },
    }),
);

const ipStatusKey = '/api/accessPolicy/ipRangeStatus';
export const ipCurrentKey = '/api/accessPolicy/ip';
const entitlementsKey = '/api/license/accountant/Entitlement/';

const IPRestrictionComponent: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const createDialog = useShowDialog();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks();
    const EnableIpRestrictionGridRefactor = useFeatureFlagValue(Features.EnableIpRestrictionGridRefactor.name);
    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);

    const { formatMessage: translate } = useIntl();

    const { data: hasEntitlement } = useSWR(
        {
            url: entitlementsKey,
            entitlement: Entitlements.IpRestrictions,
        },
        isEntitled,
    );

    const [ ipStatus, setIpStatus ] = useState<EnforcementType>(EnforcementType.DISABLED);

    const {
        data: ipRanges, isValidating: isValidatingIpRanges, error: ipRangesError, mutate,
    } = useSWR(
        {
            url: ipNetworkUrl,
            organizationId: partitionGlobalId,
        },
        getIpNetworkByOrganizationId,
    );

    const {
        data: ipStatusData, isValidating: isValidatingStatus, error: ipStatusError, mutate: mutateIpStatus,
    } = useSWR(
        {
            url: ipStatusKey,
            organizationId: partitionGlobalId,
        },
        getIpConfigurationStatus,
    );

    const { data: currentUserIp } = useSWR<string, Error>([ ipCurrentKey ], getCurrentIp);

    const inIpRange = useMemo(() => {
        if (!currentUserIp || !ipRanges) {
            return false;
        }
        return validateIp(currentUserIp, ipRanges?.map(val => val.ipNetwork));
    }, [ currentUserIp, ipRanges ]);

    const refreshGrid = useCallback(() => {
        mutate();
    }, [ mutate ]);

    const refreshRadioGroup = useCallback(() => {
        mutateIpStatus();
    }, [ mutateIpStatus ]);

    const ipConfigStatus = useMemo(
        () => ({
            [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_ENABLED',
            [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_DISABLED',
            [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_AUDIT',
        }),
        [],
    );

    const handleAddIPRange = useCallback(() => {
        navigate(`${getRoute(RouteNames.IPRestriction)}/add`,
            { state: { currentIp: currentUserIp } });
    }, [ currentUserIp, getRoute, navigate ]);

    const clickedDelete = useCallback(
        async (row: Row<IIPNetwork>) => {
            await createDialog({
                title: translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_CONFIRMATION' }),
                customDialogContent: IPRestrictionDeleteDialogBody,
                customDialogContentProps: {
                    currentIp: currentUserIp,
                    ipRange: row?.original,
                    ipStatus,
                    refreshCallback: () => {
                        refreshGrid();
                    },
                },
                icon: 'error',
            });
        },
        [ createDialog, currentUserIp, ipStatus, refreshGrid, translate ],
    );

    const openDeleteDialog = useCallback(
        async (row: IIPNetwork[] | IIPNetwork) => {
            await createDialog({
                title: translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_CONFIRMATION' }),
                customDialogContent: IPRestrictionDeleteDialogBody,
                customDialogContentProps: {
                    currentIp: currentUserIp,
                    ipRange: row,
                    ipStatus,
                    refreshCallback: () => {
                        refreshGrid();
                    },
                },
                icon: 'error',
            });
        },
        [ createDialog, translate, currentUserIp, ipStatus, refreshGrid ],
    );

    const handleImportCsv = useCallback(() => {
        navigate(getRoute(RouteNames.IPRestrictionBulkImport));
    }, [ getRoute, navigate ]);

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const importCsv: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_UPLOAD_CSV' }),
            icon: <UploadOutlinedIcon />,
            click: handleImportCsv,
            disable: false,
            invisible: !isAdmin,
            className: classes.headerButton,
            dataCy: 'import-csv-button',
        };
        const addIPRange: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_IP_RANGE' }),
            icon: <AddIcon />,
            click: handleAddIPRange,
            disable: false,
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.headerButton,
            dataCy: 'add-ip-restriction-button',
        };

        return [ importCsv, addIPRange ];
    }, [ translate, handleImportCsv, isAdmin, classes.headerButton, handleAddIPRange ]);

    useEffect(() => {
        if (ipStatusData) {
            setIpStatus(ipStatusData.status ?? EnforcementType.DISABLED);
        }
    }, [ ipStatusData, ipStatusError, setIpStatus ]);

    const gridData = useMemo(() => ipRanges?.filter((ipRange) => ipRange.type === 'Customer'), [ ipRanges ]);

    const rowActions = useMemo(() => {
        const editIPRange: IAction<IIPNetwork> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_EDIT' }),
            tooltip: translate({ id: 'CLIENT_EDIT' }),
            actionType: GridActionType.Row,
            icon: <EditIcon />,
            click: row => {
                const ipRange: IIPNetwork = row.original;
                navigate(`${getRoute(RouteNames.IPRestriction)}/edit/${row.original.id}`,
                    { state: { ipRange } });
            },
            dataCy: row => `edit-ip-restriction-button-${row?.index}`,
        };

        const deleteIPRange: IAction<IIPNetwork> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_DELETE' }),
            tooltip: translate({ id: 'CLIENT_DELETE' }),
            actionType: GridActionType.Row,
            icon: <DeleteIcon />,
            click: clickedDelete,
            dataCy: row => `delete-ip-restriction-button-${row?.index}`,
        };
        return isAdmin ? [ editIPRange, deleteIPRange ] : [];
    }, [ clickedDelete, getRoute, isAdmin, navigate, translate ]);

    const dialogTitle = useMemo(() => ({
        [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_DISABLE',
        [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_ENABLE',
        [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_AUDIT',
    }), []);

    const handleChange = useCallback(async (_: any, value: string) => {
        await createDialog({
            title: translate({ id: dialogTitle[value as EnforcementType] }),
            customDialogContent: IPRestrictionConfirmationDialogBody,
            customDialogContentProps: {
                inIpRange,
                ipStatus: value,
                refreshCallback: (newVal: React.SetStateAction<EnforcementType>) => {
                    setIpStatus(newVal);
                    refreshRadioGroup();
                },
            },
            icon: 'info',
        });
    }, [ createDialog, dialogTitle, inIpRange, refreshRadioGroup, translate ]);

    const options = useMemo(() => {
        const opt = [
            {
                label: translate({ id: ipConfigStatus.Disabled }),
                value: EnforcementType.DISABLED,
                dataCy: 'disable',
            },
            {
                label: translate({ id: ipConfigStatus.Enabled }),
                value: EnforcementType.ENABLED,
                dataCy: 'enable',
            },
        ];
        return opt;
    }, [ ipConfigStatus.Disabled, ipConfigStatus.Enabled, translate ]);

    const auditBanner = useMemo(() => (
        <UiAlertBanner
            type="info"
            closeable={false}
            enterpriseTrialAlertBar
            dataCy='ip-restriction-audit-banner'
        >
            <div className={classes.banner}>
                {translate({ id: 'CLIENT_IP_RESTRICTION_AUDIT_BANNER_MESSAGE' })}
                <Link
                    className={classes.link}
                    data-cy="ip-restriction-banner-audit-log-link"
                    underline="hover"
                    href={getRoute(RouteNames.AuditLogs)}
                >
                    {translate({ id: 'CLIENT_IP_RESTRICTION_AUDIT_LINK' })}
                </Link>
            </div>
        </UiAlertBanner>)
    , [ classes.banner, classes.link, getRoute, translate ]);

    const allowIpRestrictions = useMemo(() => hasEntitlement,
        [ hasEntitlement ]);

    const clickedBulkDelete = useCallback(
        async (rows: Array<Row<IIPNetwork>>) => {
            const networks: IIPNetwork[] = rows.map(row => row.original);
            await createDialog({
                title: translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_CONFIRMATION' }),
                customDialogContent: IPRestrictionDeleteDialogBody,
                customDialogContentProps: {
                    currentIp: currentUserIp,
                    ipRange: networks,
                    ipStatus,
                    refreshCallback: () => {
                        refreshGrid();
                    },
                },
                icon: 'error',
            });
        },
        [ createDialog, translate, currentUserIp, ipStatus, refreshGrid ],
    );

    if (hasEntitlement == null) {
        return <div className={classes.centerLoader}>
            <CircularProgress />
        </div>;
    }

    if (!allowIpRestrictions) {
        return <UpgradeForFeature
            upgradeTitle={translate({ id: 'CLIENT_UPGRADE_IP_TITLE' })}
            upgradeMessage={translate({ id: 'CLIENT_UPGRADE_IP_DESCRIPTION' })}
            documentationLink={getLocalizedLink({ articleSlug: IPRestrictionLink })}
            level={AccountLicense.ENTERPRISE}
            telemetryTitle={SecuritySettingsEvent.IPRestriction} />;
    }

    return (
        <div
            className={classes.mainContent}
            data-cy='ip-restriction-container'
        >
            {ipStatus === EnforcementType.AUDIT && auditBanner}
            <div className={classes.container}>
                <div className={classes.leftContainer}>
                    <UiText className={classes.IPRestrictionDescription}>
                        {translate({ id: 'CLIENT_IP_RESTRICTION_DESCRIPTION' })}
                    </UiText>
                    {EnableIpRestrictionGridRefactor ?
                        <ApDataGridWrapper<IIPNetwork>
                            data={gridData ?? []}
                            unpaginated
                            initialFilters={{
                                sort: [
                                    {
                                        field: 'type',
                                        direction: 'asc',
                                        title: translate({ id: 'CLIENT_IP_SET_NAME' }),
                                    },
                                ],
                            }}
                            loading={isValidatingIpRanges}
                            refreshable
                            refresh={mutate}
                            dataCy="ip-restriction-ap-data-grid"
                        >
                            <ApDataGridHeader<IIPNetwork> search>
                                <ApDataGridHeaderButton<IIPNetwork>
                                    id='importCsv'
                                    key='importCsv'
                                    type='main'
                                    buttonType='mat-stroked-button'
                                    color='primary'
                                    text={translate({ id: 'CLIENT_UPLOAD_CSV' })}
                                    label={translate({ id: 'CLIENT_UPLOAD_CSV' })}
                                    icon='upload'
                                    onClick={handleImportCsv}
                                    dataCy='import-csv-button'
                                    visible={isAdmin}
                                />
                                <ApDataGridHeaderButton<IIPNetwork>
                                    id='addIpRange'
                                    key='addIpRange'
                                    type='main'
                                    buttonType='mat-flat-button'
                                    color='primary'
                                    text={translate({ id: 'CLIENT_ADD_IP_RANGE' })}
                                    label={translate({ id: 'CLIENT_ADD_IP_RANGE' })}
                                    icon='add'
                                    onClick={handleAddIPRange}
                                    dataCy='add-ip-restriction-button'
                                    visible={isAdmin}
                                />
                                <ApDataGridHeaderButton<IIPNetwork>
                                    id='delete'
                                    key='delete'
                                    type='action'
                                    buttonType='mat-flat-button'
                                    visible={isAdmin}
                                    color="warn"
                                    text={translate({ id: 'CLIENT_DELETE' })}
                                    label={translate({ id: 'CLIENT_DELETE' })}
                                    icon='delete'
                                    onClick={(rows) => openDeleteDialog(rows ?? [])}
                                    dataCy='delete-ip-restrictions-button'
                                />
                            </ApDataGridHeader>
                            <ApDataGridColumn<IIPNetwork>
                                property="name"
                                sort="asc"
                                title={translate({ id: 'CLIENT_IP_SET_NAME' })}
                                width={30}
                                sortable
                                searchable
                            />
                            <ApDataGridColumn<IIPNetwork>
                                property="ipNetwork"
                                title={translate({ id: 'CLIENT_IP_RANGE' })}
                                width={60}
                                sortable
                                searchable
                            />
                            {isAdmin && (
                                <ApDataGridRowActions>
                                    <ApDataGridRowButton<IIPNetwork>
                                        id='edit'
                                        label={translate({ id: 'CLIENT_EDIT' })}
                                        icon='edit'
                                        onClick={row => {
                                            const ipRange: IIPNetwork = row;
                                            navigate(`${getRoute(RouteNames.IPRestriction)}/edit/${row.id}`,
                                                { state: { ipRange } });
                                        }}
                                    />

                                    <ApDataGridRowButton<IIPNetwork>
                                        id='delete'
                                        label={translate({ id: 'CLIENT_DELETE' })}
                                        icon='delete'
                                        onClick={openDeleteDialog}
                                    />
                                </ApDataGridRowActions>
                            )}
                            <ApDataGridFooter
                                pageSizes={[ 5, 10, 25, 50 ]}
                            />
                        </ApDataGridWrapper>
                        : <UiGrid<IIPNetwork>
                            data={gridData ?? []}
                            dataCy="ip-restriction-ui-grid"
                            error={ipRangesError}
                            loading={isValidatingIpRanges}
                            checkbox={isAdmin}
                            columns={[
                                {
                                    accessor: 'name',
                                    Header: translate({ id: 'CLIENT_IP_SET_NAME' }),
                                    sortName: translate({ id: 'CLIENT_IP_SET_NAME' }),
                                    width: 30,
                                    sortType: 'alphanumeric',
                                },
                                {
                                    accessor: 'ipNetwork',
                                    Header: translate({ id: 'CLIENT_IP_RANGE' }),
                                    sortName: translate({ id: 'CLIENT_IP_RANGE' }),
                                    width: 60,
                                    sortType: 'alphanumeric',
                                },
                            ]}
                            pagination
                            filters
                            search
                            searchPlaceholder={translate({ id: 'CLIENT_SEARCH_IP_RANGE_TEXT' })}
                            extraActionButtons={extraActionHeaderButtons}
                            rowActions={rowActions}
                            tableActions={
                                (isAdmin)
                                    ? [
                                        {
                                            type: ButtonType.Button,
                                            label: translate({ id: 'CLIENT_DELETE' }),
                                            icon: <DeleteForeverOutlinedIcon />,
                                            actionType: GridActionType.Selected,
                                            click: clickedBulkDelete,
                                        },
                                    ]
                                    : []
                            }
                            wrapHeader
                        />}

                </div>
                <Divider
                    orientation='vertical'
                    flexItem />

                <div className={classes.rightContainer}>
                    <UiText
                        id="enforcement-type"
                        className={classes.label}>
                        {translate({ id: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE' })}
                    </UiText>
                    {!isValidatingStatus ?
                        <RadioGroup
                            aria-labelledby="enforcement-type"
                            onChange={handleChange}
                            value={ipStatus}
                        >
                            {options.map((option, i) => (
                                <FormControlLabel
                                    key={i}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                    data-cy={`ip-restriction-radio-${option.dataCy}`}
                                />
                            ))}
                        </RadioGroup> :
                        <div className={classes.centerLoader}>
                            <CircularProgress />
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default IPRestrictionComponent;
