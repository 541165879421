import { updateMarketoWithDownloadEdition } from '../ResourceCenterService';

export async function studioDownloadMarketoUpdate(
    editionType: string,
    profileState: any,
    accountGUID: any,
    logicalName: any,
    currentAccountLanguage: any,
) {
    const payload = {
        userId: profileState.userGlobalId,
        firstName: profileState.accountUserDto.firstName,
        lastName: profileState.accountUserDto.lastName,
        email: profileState.emailId,
        userLanguage: currentAccountLanguage,
        country: profileState.accountUserDto.country,
        accountId: accountGUID,
        accountUrl: `${window.location.origin}/${logicalName}`,
        organizationName: profileState.accountUserDto.companyName,
        edition: editionType,
    };

    await updateMarketoWithDownloadEdition(payload);
}
