import type { Persona } from '@experiences/interfaces';

import type { sidebarData } from '../common/interfaces/sidebar';
import { get } from './utility/Requests.default';

export const sidebarURL = '/api/content/sidebar/getSidebarCMSData';

export async function getSidebarResource({
    language, dominantPersona,
}: { url: string; language: string; dominantPersona: Persona }) {
    return get<sidebarData>(sidebarURL, {
        urlParams: {
            language,
            dominantPersona,
        },
    });
}
