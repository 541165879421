import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { Row } from 'react-table';
import useSWR from 'swr';

import { CustomScopes } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import type { ITenantAllocation } from '../../common/interfaces/tenant/tenant';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import { getTenantAllocations } from '../../services/licensing/LicenseService';
import { accountGlobalId } from '../../store/selectors';
import { UiGrid } from '../common/UiGrid';
import type { UiColumn } from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';

export const AllocationToTenants: React.FC = () => {
    const accountId = useSelector(accountGlobalId);
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const location = useLocation();

    const {
        data: tenantAllocations,
        isValidating,
        error,
        mutate,
    } = useSWR(
        {
            url: `/api/licensing/tenantAllocations/${accountId}`,
            accountGlobalId: accountId,
        },
        getTenantAllocations,
    );

    useEffect(() => {
        if (location.state && (location.state as any)['refresh']) {
            mutate();
        }
    }, [ accountId, location, mutate ]);

    const isLicenseExpired = useLicenseExpired();
    const { formatMessage: translate } = useIntl();
    const columnsData: Array<UiColumn<ITenantAllocation>> = useMemo(() => {
        const columnsDataList: Array<UiColumn<ITenantAllocation>> = [];

        columnsDataList.push({
            accessor: 'tenant.name',
            sortName: 'Name',
            Header: translate({ id: 'CLIENT_TENANT' }),
            width: 20,
        });
        if (tenantAllocations != null && tenantAllocations.length > 0) {
            // If FF is true, push custom scope to services so user can configure AI Services license
            // If FF is false, override zero values with undefined so AI Units will not appear
            if (getFeatureFlagValue(Features.EnableAiuAllocationOnTenant.name)) {
                tenantAllocations.forEach(ta => {
                    ta.services.push(CustomScopes.Tenant);
                });
            } else {
                tenantAllocations.forEach(ta => {
                    ta.aiUnits = undefined;
                });
            }

            const productTypesAvailable = Object.keys(tenantAllocations[0]).filter(
                productKey =>
                    productKey !== 'services' &&
                    productKey !== 'tenant' &&
                    (!process.buildConfigs.unavailableProductNames?.includes(productKey) ?? true) &&
                    tenantAllocations.find(tenantAllocation => tenantAllocation[productKey] != null) != null,
            );
            productTypesAvailable.forEach(productType => {
                columnsDataList.push({
                    accessor: `${productType}`,
                    Header: translate({ id: `CLIENT_TENANT_ALLOCATION_${productType.toUpperCase()}` }),
                    Cell: ({ row }) => <>
                        {row.original[productType] ?? 0}
                    </>,
                    width: 20,
                    justifyContent: 'center',
                });
            });
        }

        return columnsDataList;
    }, [ tenantAllocations, translate ]);

    const clickedConfigureLicense = useCallback(
        (row: Row<ITenantAllocation>) => {
            navigate(`${getRoute(RouteNames.Licensing)}/configure/${row.original.tenant.id.toLowerCase()}`,
                {
                    state: {
                        tenant: row.original.tenant,
                        services: row.original.services,
                        previousLocation: getRoute(RouteNames.Licensing),
                    },
                });
        },
        [ getRoute, navigate ],
    );

    return (
        <UiGrid<ITenantAllocation>
            dataCy="tenant-allocations-table"
            loading={isValidating}
            pagination
            columns={columnsData}
            data={tenantAllocations ?? []}
            error={error}
            wrapHeader
            rowActions={[
                {
                    type: ButtonType.Icon,
                    label: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                    tooltip: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                    actionType: GridActionType.Row,
                    icon: <AllocateLicenseIcon />,
                    click: clickedConfigureLicense,
                    dataCy: 'data-allocate-licenses',
                    disable: () => isLicenseExpired,
                },
            ]}
        />
    );
};
