import type { IGridResource } from './client';

export enum ExternalAppsTab {
    OAUTH = 'OAUTH',
    PAT = 'PAT',
    REG = 'REG',
}

export type ExternalAppsTabType = typeof ExternalAppsTab[keyof typeof ExternalAppsTab];

export interface IExternalAppDataContext {
    resources: IGridResource[] | undefined;
    confidential: boolean;
}
