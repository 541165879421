import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'flex-end',
            width: '100%',
        },
    }),
);

export const EncryptionErrorDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return <Box>
        <UiText
            data-cy="encryption-error-description">
            {translate({ id: 'CLIENT_ENCRYPTION_AUTHENTICATION_FAILED_DESCRIPTION' }, { link: 'https://aka.ms/NewClientSecret' })}
        </UiText>
        <Box className={classes.buttonContainer}>
            <Button
                className={classes.button}
                color="primary"
                component={Link}
                href='https://aka.ms/NewClientSecret'
                target="_blank"
                data-cy="encryption-error-navigate-button"
                variant="outlined"
            >
                {translate({ id: 'CLIENT_VISIT_AZURE_PORTAL' })}
            </Button>
            <Button
                className={classes.button}
                color="primary"
                data-cy="encryption-error-confirm-button"
                onClick={() => closeDialog()}
                variant="contained"
            >
                {translate({ id: 'CLIENT_OK' })}
            </Button>
        </Box>
    </Box>;
};
