import { type IValueModelObject } from '@experiences/interfaces';
import { PortalTagManagementEvent } from '@experiences/telemetry';
import {
    UiDataContextProvider,
    UiProgressButton,
    UiSelect,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as RouteNames from '../../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../../common/providers/BreadcrumbProvider';
import {
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import UiForm from '../../../common/UiForm';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../../organizationsettings/AdminBreadCrumbs';
import { redirectToOrchestrator } from '../../common/redirectToOrchestrator.default';
import useAddEditTenantTagsPropertiesViewModel, { ValueDataTypes } from './AddEditTenantTagsPropertiesViewModel';
import type { ITenantTagsPropertiesContext } from './types';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        gridLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '14px',
            fontWeight: 600,
        },
        input: { marginBottom: '16px' },
        tooltipIcon: {
            width: '20px',
            height: '20px',
            marginLeft: '6px',
        },
    }),
);

const AddEditTenantTagsPropertiesComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();
    const isAdmin = useSelector(isAdminSelector);
    const organizationName = useSelector(accountLogicalName);

    const {
        isEditDetails,
        isEditKeyValues,
        isRegex,
        cancel,
        tenantLoading,
        methods: {
            register,
            control,
            formState: {
                errors, isSubmitting, isDirty,
            },
            handleSubmit,
            getValues,
        },
        changeDataType,
        handleOnBlurRegex,
        prevRegex,
        data,
        dataLength,
        loading,
        openValuesDrawer,
        disableAddValuesButton,
        disableDeleteValuesButton,
        disableDeleteValueRowButton,
        deleteValueRow,
        deleteValueRowBulk,
        onChangePagination,
        taggedObjectsTooltipTitle,
        getOrchestratorCount,
        isBooleanDataType,
        tenant,
        refresh: refreshValuesTable,
    } = useAddEditTenantTagsPropertiesViewModel({ type: type ?? 'add' });

    const showValuesGrid = useMemo(() => (
        <>
            <div>
                <UiText
                    id='gridLabel'
                    className={classes.gridLabel}>
                    {translate({ id: 'CLIENT_VALUES' })}
                </UiText>
            </div>
            <Controller
                control={control}
                name="values"
                render={() =>
                    <ApDataGridWrapper<IValueModelObject>
                        aria-labelledby='gridValues'
                        data={data}
                        initialFilters={{
                            sort: [
                                {
                                    field: 'displayValue',
                                    direction: 'asc',
                                    title: translate({ id: 'CLIENT_VALUES' }),
                                },
                            ],
                        }}
                        loading={loading}
                        refreshable
                        refresh={refreshValuesTable}
                        selectable={!isBooleanDataType}
                        onChange={onChangePagination}
                        dataCy='add-edit-tags-properties-ap-data-grid'
                    >
                        <ApDataGridHeader<IValueModelObject> search>
                            <ApDataGridHeaderButton<IValueModelObject>
                                id='deleteValues'
                                key='deleteValues'
                                type='action'
                                buttonType='mat-flat-button'
                                color="warn"
                                text={translate({ id: 'CLIENT_DELETE' })}
                                label={translate({ id: 'CLIENT_DELETE' })}
                                icon='delete'
                                onClick={deleteValueRowBulk}
                                dataCy='ap-grid-delete-values-button'
                                disabled={disableDeleteValuesButton}
                            />
                            <ApDataGridHeaderButton<IValueModelObject>
                                id='addValues'
                                key='addValues'
                                type='main'
                                buttonType='mat-flat-button'
                                color='primary'
                                text={translate({ id: 'CLIENT_ADD_VALUES' })}
                                label={translate({ id: 'CLIENT_ADD_VALUES' })}
                                onClick={openValuesDrawer}
                                dataCy='ap-grid-add-values-button'
                                disabled={disableAddValuesButton}
                            />
                        </ApDataGridHeader>
                        <ApDataGridColumn<IValueModelObject>
                            property='displayValue'
                            sort='asc'
                            title={translate({ id: 'CLIENT_VALUES' })}
                            searchable
                        />
                        {isAdmin && (
                            <ApDataGridRowActions>
                                <ApDataGridRowButton<IValueModelObject>
                                    id='listTaggedObjects'
                                    label={translate({ id: 'CLIENT_LIST_TAGGED_ORCHESTRATOR_OBJECTS' })}
                                    icon='list_alt'
                                    render={(row) => <Tooltip
                                        title={taggedObjectsTooltipTitle(row)}>
                                        <span>
                                            <IconButton
                                                target='_blank'
                                                href={redirectToOrchestrator(
                                                    organizationName,
                                                    tenant?.name,
                                                    {
                                                        kvp: {
                                                            data: row,
                                                            id: `${getValues('name')?.toLowerCase()}:${row.displayValue}`,
                                                            text: `${getValues('name')}:${row.displayValue}`,
                                                        },
                                                    },
                                                )}
                                                onClick={() => {
                                                    logEvent(PortalTagManagementEvent.ListTaggedObjectsProperty);
                                                }}
                                                disabled={getOrchestratorCount(row) === 0}
                                            >
                                                <ListAltOutlinedIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>}
                                />
                                <ApDataGridRowButton<IValueModelObject>
                                    id='delete'
                                    icon='delete'
                                    label={translate({ id: 'CLIENT_DELETE' })}
                                    onClick={deleteValueRow}
                                    disabled={disableDeleteValueRowButton}
                                />
                            </ApDataGridRowActions>
                        )}
                        <ApDataGridFooter
                            length={dataLength ?? 0}
                            pageSizes={[ 5, 10, 25, 50 ]}
                        />
                    </ApDataGridWrapper>}
            />
        </>), [
        classes.gridLabel,
        control,
        data,
        dataLength,
        deleteValueRow,
        deleteValueRowBulk,
        disableAddValuesButton,
        disableDeleteValueRowButton,
        disableDeleteValuesButton,
        getOrchestratorCount,
        getValues,
        isAdmin,
        isBooleanDataType,
        loading,
        logEvent,
        onChangePagination,
        openValuesDrawer,
        organizationName,
        refreshValuesTable,
        taggedObjectsTooltipTitle,
        tenant?.name,
        translate,
    ]);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            maxWidth='900px'
            loading={type === 'edit' && tenantLoading}
        >
            <UiForm
                className={classes.form}
                onSubmit={handleSubmit}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={cancel}
                            color="primary"
                            data-cy="add-edit-tenant-tag-properties-cancel"
                        >
                            {translate({ id: (type === 'add' || isEditDetails) ? 'CLIENT_CANCEL' : 'CLIENT_RETURN' })}
                        </Button>
                        {(type === 'add' || isEditDetails) && <UiProgressButton
                            type="submit"
                            disabled={!isDirty || Object.keys(errors).length > 0}
                            loading={isSubmitting}
                            variant="contained"
                            data-cy="tenant-tag-properties-add-button"
                        >
                            {translate({ id: type === 'add' ? 'CLIENT_ADD' : 'CLIENT_SAVE' })}
                        </UiProgressButton>}
                    </div>
                }
                centerChild
                dataCy="tenant-settings-component"
            >
                <TextField
                    className={classes.input}
                    helperText={errors?.name?.message}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_NAME' })}
                    required
                    error={!!errors.name}
                    variant="outlined"
                    label={translate({ id: 'CLIENT_KEY_NAME' })}
                    fullWidth
                    data-cy="tenant-tags-properties-name"
                    inputProps={{
                        'aria-label': translate({ id: 'CLIENT_KEY_NAME' }),
                        ...register('name', {
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_KEY_NAME' }) },
                            ),
                            validate: v => !!v?.trim(),
                        }),
                    }}
                    disabled={isEditKeyValues}
                />
                {!isEditKeyValues && <TextField
                    className={classes.input}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_DESCRIPTION_OPTIONAL' })}
                    multiline
                    rows={3}
                    error={!!errors.description}
                    variant="outlined"
                    label={translate({ id: 'CLIENT_DESCRIPTION' })}
                    fullWidth
                    data-cy="tenant-tags-properties-description"
                    inputProps={{
                        'aria-label': translate({ id: 'CLIENT_DESCRIPTION' }),
                        ...register('description'),
                    }}
                />}
                {!isEditDetails && <>
                    <UiSelect
                        className={classes.input}
                        control={control}
                        name='dataType'
                        error={!!errors.dataType}
                        inputLabel={translate({ id: 'CLIENT_INPUT_DATA_TYPE' })}
                        disabled={isEditKeyValues}
                        options={ValueDataTypes}
                        dataCy='tenant-tags-properties-data-type'
                        required
                        helperText={
                            (errors.dataType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                        }
                        tooltip={
                            <Tooltip title={translate({ id: 'CLIENT_VALUE_DATA_TYPE_WARNING' })}>
                                <InfoOutlinedIcon
                                    className={classes.tooltipIcon}
                                    data-cy='select-tooltip-icon'
                                />
                            </Tooltip>
                        }
                        onChange={changeDataType}
                    />
                    {isRegex && <TextField
                        className={classes.input}
                        placeholder={translate({ id: 'CLIENT_ADD_REGEX' })}
                        error={!!errors.regex}
                        variant="outlined"
                        label={translate({ id: 'CLIENT_REGEX_RULE' })}
                        fullWidth
                        data-cy="tenant-tags-property-regex-rule"
                        inputProps={{
                            'aria-label': translate({ id: 'CLIENT_REGEX_RULE' }),
                            ...register('regex', {
                                required: true,
                                validate: v => !!v?.trim(),
                            }),
                        }}
                        required
                        helperText={errors.regex ? errors.regex.message : translate({ id: 'CLIENT_REGEX_RULE_HELPER' })}
                        onBlur={e => handleOnBlurRegex(e, getValues('values'), prevRegex)}
                        disabled={isEditKeyValues}
                    />}
                    {showValuesGrid}
                </>}
            </UiForm>
        </UiPageContainer>
    );
};

export const AddEditTenantTagsPropertiesComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const { type } = useParams<{ type: 'add' | 'edit' }>();

    const orgName = useOrganizationName();

    const {
        tenant,
        tenantId,
        property,
        isEditDetails,
        isEditKeyValues,
    } = useAddEditTenantTagsPropertiesViewModel({ type: type ?? 'add' });

    const breadCrumbLinks = useMemo(() => {
        let propertiesBreadcrumbName = translate({ id: 'CLIENT_ADD_PROPERTY' });
        if (isEditDetails) {
            propertiesBreadcrumbName = translate({ id: 'CLIENT_EDIT_KEY_DETAILS' }, { key: property?.name });
        } else if (isEditKeyValues) {
            propertiesBreadcrumbName = translate({ id: 'CLIENT_EDIT_KEY_VALUES' }, { key: property?.name });
        }

        return process.buildConfigs.showForMSI
            ? [
                {
                    index: 0,
                    link: RouteNames.OrganizationAdminHome,
                    name: orgName,
                },
                {
                    index: 1,
                    link: RouteNames.TenantTagsLabelsMsi,
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    index: 2,
                    link: RouteNames.TenantTagsPropertiesMsi,
                    name: translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' }),
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsAddMsi,
                    name: propertiesBreadcrumbName,
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsEditMsi,
                    name: propertiesBreadcrumbName,
                },
            ]
            : [
                {
                    index: 0,
                    link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                    name: tenant?.name ?? '',
                },
                {
                    index: 1,
                    link: RouteNames.TenantTagsLabels.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    index: 2,
                    link: RouteNames.TenantTagsProperties.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' }),
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsAdd.replace(':tenantId', tenantId),
                    name: propertiesBreadcrumbName,
                },
                {
                    index: 3,
                    link: RouteNames.TenantTagsLabelsEdit.replace(':tenantId', tenantId),
                    name: propertiesBreadcrumbName,
                },
            ];
    }, [ isEditDetails, isEditKeyValues, orgName, property?.name, tenant?.name, tenantId, translate ]);

    return <UiDataContextProvider<ITenantTagsPropertiesContext> initialState={{
        Values: [],
        DataType: 'String',
    }}>
        <BreadcrumbProvider
            breadcrumbs={breadCrumbLinks}
            legacy>
            <AddEditTenantTagsPropertiesComponent type={type ?? 'add'} />
            <UiSuspensefulOutlet />
        </BreadcrumbProvider>
    </UiDataContextProvider>;
};

export default AddEditTenantTagsPropertiesComponent;
