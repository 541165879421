import { useSelector } from 'react-redux';

import { profile } from '../../store/selectors';
import useUserInfo from './UserInfo';

export default function useValidateStore() {
    const currentProfile = useSelector(profile);
    const { email } = useUserInfo();

    // return true if store is valid
    return Object.keys(currentProfile).length > 0 && currentProfile.emailId === email;
}
