import { produce } from 'immer';
import merge from 'lodash/merge';
import type { DeepPartial } from 'redux';

import {
    AuthSettingKey,
    AuthSettingPasswordKey,
    AuthSettingUserLockOutKey,
} from '../../common/constants/AuthSettingConstant';
import {
    type IAuthSettingsRestrictionsData,
    type ISecurityAuthenticationSettingsData,
} from '../../common/interfaces/authSetting';
import { AttributeType } from '../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../common/interfaces/cis/saml';
import type { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import type { IAuthenticationSettingPayload } from '../../services/identity/AuthenticationSettingService';
import type { ISetting } from '../../services/identity/SettingService';
import { removeUndefinedNullAndEmptyStringValues } from '../ObjectUtil';

export const defaultSecurityAuthenticationSettingsData: ISecurityAuthenticationSettingsData = {
    password: {
        defaultExpirationDays: 0,
        previousUseLimit: 0,
        shouldChangePasswordAfterFirstLogin: 'false',
        passwordComplexity: {
            hasSpecialCharacter: false,
            hasLowercase: false,
            hasUppercase: false,
            hasDigit: false,
            Length: 6,
        },
    },
    userLockOut: {
        isEnabled: false,
        defaultAccountLockoutSeconds: 0,
        maxFailedAccessAttemptsBeforeLockout: 2,
    },
};

const defaultAuthSettingsRestrictionsData: IAuthSettingsRestrictionsData = {
    restrictBasicAuthentication: false,
    enableBasicAuthenticationForHostTenant: true,
};

export function mapSettingArrayToSecurityAuthSettingsData(
    fetchedSettings: ISetting[],
): DeepPartial<ISecurityAuthenticationSettingsData> {
    const defaultExpirationDays = fetchedSettings.find(
        setting => setting.key === AuthSettingPasswordKey.DefaultExpirationDays,
    );
    const previousUseLimit = fetchedSettings.find(setting => setting.key === AuthSettingPasswordKey.PreviousUseLimit);
    const shouldChangePasswordAfterFirstLogin = fetchedSettings.find(
        setting => setting.key === AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
    );
    const userLockOut = fetchedSettings.find(setting => setting.key === AuthSettingUserLockOutKey.IsEnabled);
    const maxFailedAccessAttemptsBeforeLockout = fetchedSettings.find(
        setting => setting.key === AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
    );
    const defaultAccountLockoutSeconds = fetchedSettings.find(
        setting => setting.key === AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
    );
    const passwordComplexity = fetchedSettings.find(setting => setting.key === AuthSettingPasswordKey.PasswordComplexity);
    const parsedPasswordComplexity = passwordComplexity && JSON.parse(passwordComplexity.value);

    return merge({}, defaultSecurityAuthenticationSettingsData, {
        password: {
            defaultExpirationDays: defaultExpirationDays && parseInt(defaultExpirationDays.value),
            previousUseLimit: previousUseLimit && parseInt(previousUseLimit.value),
            shouldChangePasswordAfterFirstLogin: shouldChangePasswordAfterFirstLogin?.value === 'true' ? 'true' : 'false',
            passwordComplexity: {
                hasSpecialCharacter: parsedPasswordComplexity?.HasSpecialCharacter,
                hasLowercase: parsedPasswordComplexity?.HasLowercase,
                hasUppercase: parsedPasswordComplexity?.HasUppercase,
                hasDigit: parsedPasswordComplexity?.HasDigit,
                Length: parsedPasswordComplexity?.Length,
            },
        },
        userLockOut: {
            isEnabled: userLockOut?.value === undefined ? undefined : userLockOut.value === 'true',
            defaultAccountLockoutSeconds: defaultAccountLockoutSeconds && parseInt(defaultAccountLockoutSeconds.value),
            maxFailedAccessAttemptsBeforeLockout:
        maxFailedAccessAttemptsBeforeLockout && parseInt(maxFailedAccessAttemptsBeforeLockout.value),
        },
    });
}

export function mapSettingArrayToAuthSettingsRestrictionsData(
    fetchedSettings: ISetting[],
): DeepPartial<IAuthSettingsRestrictionsData> {
    const restrictBasicAuthentication = fetchedSettings.find(
        setting => setting.key === AuthSettingKey.RestrictBasicAuthentication,
    );
    const enableBasicAuthenticationForHostTenant = fetchedSettings.find(
        setting => setting.key === AuthSettingKey.EnableBasicAuthenticationForHostTenant,
    );
    return merge({}, defaultAuthSettingsRestrictionsData, {
        restrictBasicAuthentication:
      restrictBasicAuthentication?.value === undefined ? undefined : restrictBasicAuthentication.value === 'true',
        enableBasicAuthenticationForHostTenant:
      enableBasicAuthenticationForHostTenant?.value === undefined
          ? undefined
          : enableBasicAuthenticationForHostTenant.value === 'true',
    });
}

export function mapSecurityAuthSettingDataToKeyValuePairs(data: ISecurityAuthenticationSettingsData): IKeyValuePair[] {
    return [
        {
            key: AuthSettingPasswordKey.DefaultExpirationDays,
            value: data.password?.defaultExpirationDays?.toString(),
        },
        {
            key: AuthSettingPasswordKey.PasswordComplexity,
            value: JSON.stringify(data.password?.passwordComplexity),
        },
        {
            key: AuthSettingPasswordKey.PreviousUseLimit,
            value: data.password?.previousUseLimit?.toString(),
        },
        {
            key: AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
            value: data.password?.shouldChangePasswordAfterFirstLogin?.toString(),
        },
        {
            key: AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
            value: data.userLockOut?.defaultAccountLockoutSeconds?.toString(),
        },
        {
            key: AuthSettingUserLockOutKey.IsEnabled,
            value: data.userLockOut?.isEnabled?.toString(),
        },
        {
            key: AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
            value: data.userLockOut?.maxFailedAccessAttemptsBeforeLockout?.toString(),
        },
    ];
}

export function mapAuthSettingsRestrictionsDataToKeyValuePairs(data: IAuthSettingsRestrictionsData): IKeyValuePair[] {
    const { enableBasicAuthenticationForHostTenant } = data;
    const keyValuePairs = [
        {
            key: AuthSettingKey.RestrictBasicAuthentication,
            value: data.restrictBasicAuthentication?.toString(),
        },
    ];
    if (enableBasicAuthenticationForHostTenant !== undefined) {
        keyValuePairs.push({
            key: AuthSettingKey.EnableBasicAuthenticationForHostTenant,
            value: enableBasicAuthenticationForHostTenant.toString(),
        });
    }
    return keyValuePairs;
}

export function mapSamlFormDataToAuthSettingPayload(
    data: ISamlFormData,
    partitionGlobalId: string,
    externalIdentityProviderId?: number,
): IAuthenticationSettingPayload {
    const settings = produce(data as any, (draft: any) => {
        draft.ProvisioningSetting.AllowedDomains = draft.ProvisioningSetting.AllowedDomains
            ?.split(',')
            .map((domain: string) => domain.trim())
            .filter((domain: string) => !!domain) || null;
        if (draft.ProvisioningSetting.AttributeMapper[AttributeType.IDENTIFIER]) {
            draft.ProvisioningSetting.UniqueIdClaimName = draft.ProvisioningSetting.AttributeMapper[AttributeType.IDENTIFIER];
        }
        if (draft.ProvisioningSetting.AttributeMapper[AttributeType.EMAIL]) {
            draft.ProvisioningSetting.CustomEmailClaimName = draft.ProvisioningSetting.AttributeMapper[AttributeType.EMAIL];
        }

        draft.AdvancedSettings = removeUndefinedNullAndEmptyStringValues(draft.AdvancedSettings);
    });

    return {
        externalIdentityProviderId,
        clientId: data.IdentityProviderEntityId,
        type: 'Saml2',
        displayName: 'Saml2',
        partitionGlobalId,
        settings: JSON.stringify(settings),
    };
}

export function mapSettingArrayToRestrictBasicAuthentication(fetchedSettings: ISetting[] | undefined): boolean {
    const restrictBasicAuthentication = fetchedSettings?.find(
        setting => setting.key === AuthSettingKey.RestrictBasicAuthentication,
    );
    return restrictBasicAuthentication?.value === 'true';
}
