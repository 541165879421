import type { IConsumable } from '@experiences/interfaces';
import Grid from '@mui/material/Grid';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';

import { UiChartSection } from './UiChartSection';
import { UiConsumableCard } from './UiConsumableCard';

const useStyles = makeStyles(theme =>
    createStyles({
        chart: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '21px',
            paddingRight: '11px',
            paddingTop: '8px',
            paddingBottom: '22px',
            borderLeft: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderRight: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderBottom: `1px solid ${theme.palette.semantic.colorBorder}`,
            position: 'relative',
        },
        chartRightBorder: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '50%',
                borderTop: `1px solid ${theme.palette.semantic.colorBorder}`,
            },
        },
        chartLeftBorder: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '50%',
                borderTop: `1px solid ${theme.palette.semantic.colorBorder}`,
            },
        },
    }),
);

export const UiConsumableGroup: React.FC<{
    consumables: IConsumable[];
    handleOpenChart: (selected: string) => void;
    chartSection: { opened: boolean; selected: string };
    tenantId?: string;
}> = ({
    consumables, handleOpenChart, chartSection, tenantId,
}) => {
    const classes = useStyles();

    const containsElement = useMemo(() => consumables.filter((consumable) => consumable.code === chartSection.selected).length > 0, [ consumables, chartSection.selected ]);

    const selectedIndex = useMemo(() =>
        consumables.findIndex((consumable) => consumable.code === chartSection.selected), [ consumables, chartSection.selected ]);

    return (<>
        {consumables.map((consumable) => (
            <UiConsumableCard
                key={consumable.code}
                consumable={consumable}
                handleOpenChart={handleOpenChart}
                isSelected={chartSection.selected === consumable.code && chartSection.opened}
                tenantId={tenantId} />
        ))}
        {chartSection?.opened && containsElement &&
              <Grid
                  xs={12}
                  item
                  data-cy="licenses-grid-chart"
              >
                  <div className={`${classes.chart} ${ selectedIndex === 0 ? classes.chartRightBorder : classes.chartLeftBorder}`}>
                      <UiChartSection
                          product={chartSection.selected}
                          consumable={consumables[selectedIndex]}
                          tenantId={tenantId}
                      />
                  </div>
              </Grid>}
    </>
    );
};
