import type { PropsWithChildren } from 'react';
import React from 'react';
import type { Updater } from 'use-immer';
import { useImmer } from 'use-immer';

interface IMetadataStatus {
    loading: boolean;
    success: boolean;
    error?: string;
}

interface ISAMLFormContext {
    metadataStatus: IMetadataStatus;
    setMetadataStatus: Updater<IMetadataStatus>;
    isEdit: boolean;
}

const SAMLFormContext = React.createContext<ISAMLFormContext>({
    metadataStatus: {
        loading: false,
        success: false,
        error: undefined,
    },
    setMetadataStatus: () => {},
    isEdit: false,
});

export const useSAMLFormContext = () => React.useContext(SAMLFormContext);

export const SAMLFormContextProvider: React.FC<PropsWithChildren<{ initialStatus?: IMetadataStatus; isEdit: boolean }>> = ({
    children,
    initialStatus = {
        loading: false,
        success: false,
        error: '',
    },
    isEdit,
}) => {
    const [ metadataStatus, setMetadataStatus ] = useImmer<IMetadataStatus>(initialStatus);

    return <SAMLFormContext.Provider value={{
        metadataStatus,
        setMetadataStatus,
        isEdit,
    }}>
        {children}
    </SAMLFormContext.Provider>;
};
