import type {
    Dispatch,
    SetStateAction,
} from 'react';
import {
    useCallback,
    useState,
} from 'react';

export function usePersistentState<T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>, (v: T, useSaved?: boolean) => void] {
    const sessionStorageKey = `persistent-state-${key}`;

    const [ value, setValue ] = useState<T>(sessionStorage.getItem(sessionStorageKey) as T ?? initialValue);

    const setPersistentValue = useCallback((v: T, useSaved = false) => {
        const savedValue = sessionStorage.getItem(sessionStorageKey) as T;

        if (savedValue && useSaved) {
            setValue(savedValue);
            return;
        }

        sessionStorage.setItem(sessionStorageKey, v as string);
        setValue(v);
    }, [ sessionStorageKey ]);

    return [
        value,
        setValue,
        setPersistentValue,
    ];
}
