import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

export const UiChartLoading: React.FC<{ width: number; height: number }> = ({
    width, height,
}) => (
    <Grid
        item
        xs={12}>
        <div>
            <div style={{ flex: 0.9 }}>
                <Skeleton
                    variant="text"
                    width={width}
                    style={{ marginBottom: '8px' }} />
                <Skeleton
                    variant="rectangular"
                    height={height} />
            </div>
        </div>
    </Grid>
);
