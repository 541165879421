import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { NotificationAdminSettingsEvent } from '@experiences/telemetry';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApProgressSpinner } from '@uipath/portal-shell-react';
import React, {
    lazy,
    Suspense,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';

import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import EmailConfigComponent from './EmailConfiguration/EmailConfigComponent';
import NotificationsProfiles from './NotificationsProfiles';
import { TeamsSettingsFormComponent } from './TeamsConfiguration/TeamsSettingsComponent';

const useStyles = makeStyles(() =>
    createStyles({ tabsContainer: { marginBottom: '8px' } }),
);

const NotificationSettingsAdminPage: React.FC = () => {
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();
    const { formatMessage: translate } = useIntl();
    const [ tabSelected, settabSelected ] = React.useState<number>(0);

    useEffect(() => {
        logEvent(NotificationAdminSettingsEvent.Navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const EnableSlackNotification = useFeatureFlagValue(
        Features.EnableSlackNotification.name,
    );
    const EnableTeamsNotification = useFeatureFlagValue(
        Features.EnableTeamsNotification.name,
    );
    const SlackConfigComponent = lazy(() => import('./SlackConfiguration/SlackConfigComponent').
        then(module => ({ default: module.SlackConfigComponent })));
    return (
        <>
            <div
                data-cy="notification-settings-tabs"
                className={classes.tabsContainer}>
                <Tabs
                    value={tabSelected}
                    onChange={(__: any, newIndex) => settabSelected(newIndex)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        key={1}
                        label={translate({ id: 'CLIENT_ALERTING_GROUP_CONFIGURATION' })}
                        style={{ textTransform: 'capitalize' }} />
                    <Tab
                        key={2}
                        label={translate({ id: 'CLIENT_EMAIL_CONFIGURATION' })}
                        style={{ textTransform: 'capitalize' }} />
                    {EnableSlackNotification && <Tab
                        key={3}
                        label={translate({ id: 'CLIENT_SLACK_CONFIGURATION' })}
                        style={{ textTransform: 'capitalize' }} />}
                    {EnableTeamsNotification && <Tab
                        key={4}
                        label={translate({ id: 'CLIENT_TEAMS_CONFIGURATION' })}
                        style={{ textTransform: 'capitalize' }} />}
                </Tabs>
                { tabSelected === 0 && <NotificationsProfiles />}
                { tabSelected === 1 && <EmailConfigComponent />}
                { EnableSlackNotification && tabSelected === 2 && <Suspense fallback={<ApProgressSpinner />}>
                    <SlackConfigComponent />
                </Suspense>}
                { EnableTeamsNotification && tabSelected === 3 && <Suspense fallback={<ApProgressSpinner />}>
                    <TeamsSettingsFormComponent />
                </Suspense>}
            </div>
        </>
    );
};

export default NotificationSettingsAdminPage;
