import { UiProgressButton } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalObjectPicker } from '@uipath/portal-shell-react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import useAddEditTenantTagsPropertiesDrawerViewModel from './AddEditTenantTagsPropertiesDrawerViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        field: { marginBottom: '24px' },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
        },
    }),
);

const AddEditTenantTagsPropertiesDrawer: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        loading,
        propertyValues,
        open,
        close,
        portalObjectPickerCallback,
        methods: {
            control, handleSubmit, formState: {
                isDirty, isValid,
            },
        },
        errorMessage,
        hasError,
        setHasError,
        validateValue,
        regex,
        dataType,
    } = useAddEditTenantTagsPropertiesDrawerViewModel({ type });

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ADD_NEW_VALUES' })}
            loading={loading}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}>
            <UiForm
                onSubmit={handleSubmit}
                className={classes.form}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={close}
                            color="primary"
                            data-cy="add-edit-tenant-tag-label-discard"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={false}
                            variant="contained"
                            data-cy="tenant-tag-label-add-button"
                            disabled={!isValid || !isDirty || hasError}
                        >
                            {translate({ id: 'CLIENT_ADD' })}
                        </UiProgressButton>
                    </div>
                }>
                {dataType === 'Regex' && <TextField
                    id='displayRegex'
                    data-cy='add-edit-tenant-tag-regex'
                    className={classes.field}
                    label={translate({ id: 'CLIENT_REGEX_RULE' })}
                    value={regex}
                    disabled
                />}
                <Controller
                    control={control}
                    name="Values"
                    render={({ field }) => {
                        setHasError(!validateValue(field.value));
                        return <PortalObjectPicker
                            ref={portalObjectPickerCallback}
                            delimitCharacters='[ "Enter" ]'
                            value={JSON.stringify(field.value)}
                            options={JSON.stringify(propertyValues.map(valueIterator => ({
                                id: valueIterator.key,
                                primaryText: valueIterator.normalizedValue,
                                type: 'loading',
                                data: valueIterator,
                                optionId: `tenant-tag-property-value-${valueIterator.key}`,
                            })))}
                            labelText={translate({ id: 'CLIENT_VALUES' })}
                            errorMessage={hasError ? errorMessage : undefined}
                            placeholderText={translate({ id: 'CLIENT_TENANT_TAGS_VALUES_PLACEHOLDER' })}
                        />;
                    }}
                />
            </UiForm>
        </UiDrawer>
    );
};

export const AddEditTenantTagsPropertiesDrawerWithProvider: React.FC = () => {
    const { type } = useParams<{ type: 'add' | 'edit' }>();

    return <AddEditTenantTagsPropertiesDrawer type={type ?? 'add'} />;
};

export default AddEditTenantTagsPropertiesDrawer;
