import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { NotificationSettingsEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useMatch,
    useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import {
    updateDefaultSubscription,
    UpdateDefaultSubscriptionUrl,
} from '../../services/notification-preferences';
import { isAdminSelector } from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import NotificationSettingsAdminPage from './Admin/NotificationSettingsAdminPage';
import NotificationSettingsEditProfilePage from './Admin/NotificationSettingsEditProfilePage';
import NotificationSettingsComponentUser from './NotificationSettingsComponentUser';
import NotificationSettingSelectionMenu from './NotificationSettingSelectionMenu';

const useStyles = makeStyles((theme) =>
    createStyles({
        heading: {
            fontSize: '16px',
            fontWeight: 'bold',
            paddingBottom: '20px',
        },
        previewHeadingSuffix: {
            fontSize: '10px',
            color: theme.palette.semantic.colorForegroundLight,
            fontWeight: 400,
            lineHeight: '16px',
            paddingLeft: '8px',
        },
        mainContentPlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
        },
        restoreDefaultSubscriptionBlock: { margin: '20px 0' },
        restoreDefaultSubscriptionLink: {
            marginLeft: '14px',
            fontWeight: 600,
            cursor: 'pointer',
            textDecoration: 'none',
        },
    }),
);

const NotificationSettingsComponent: React.FC = () => {
    const isAdmin = useSelector(isAdminSelector);
    const { logEvent } = useTelemetryHelper();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const DisableNotificationPreview = useFeatureFlagValue(
        Features.DisableNotificationPreview.name,
    );

    const location = useLocation();

    const { name } = useParams();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const isNotificationSettings = useMatch({
        path: RouteNames.NotificationSettings,
        caseSensitive: true,
        end: true,
    });
    const isAddUserGroupProfile = useMatch({
        path: RouteNames.AddUserGroupProfile,
        caseSensitive: true,
        end: true,
    });

    useSWR(
        tenantId ? {
            requestUri: UpdateDefaultSubscriptionUrl,
            selectedTenantId: tenantId,
        } : null,
        updateDefaultSubscription,
    );

    const isAddNotificationSettingsProfile = useMatch({
        path: RouteNames.AddNotificationSettingsProfile,
        caseSensitive: true,
    });

    useEffect(() => {
        logEvent(NotificationSettingsEvent.LoadPreferences);
        return () => {};
    }, [ logEvent ]);

    const [ isManageActionmode, setManageActionmode ] = React.useState<boolean>(false);
    useEffect(() => {
        const severitymodeState = sessionStorage.getItem('isNSManageMode');
        setManageActionmode(severitymodeState === 'true');
    }, [ ]);

    const handleActionMenu = useCallback((manageMode: boolean) => {
        sessionStorage.setItem('isNSManageMode', manageMode.toString());
        setManageActionmode(manageMode);
    }, []);

    return (
        <>
            { (isNotificationSettings || isAddUserGroupProfile) && <div>
                {isAdmin ? (
                    <NotificationSettingSelectionMenu {...{ handleActionMenu }} />
                ) : (
                    <UiText
                        className={classes.heading}
                        role="heading"
                        aria-level={1}>
                        {translate({ id: 'CLIENT_NOTIFICATIONS' })}
                        {!DisableNotificationPreview && (
                            <span className={classes.previewHeadingSuffix}>
                                {translate({ id: 'CLIENT_PREVIEW' })}
                            </span>
                        )}
                    </UiText>
                )}

                {!isManageActionmode && <NotificationSettingsComponentUser />}
                {isManageActionmode && <NotificationSettingsAdminPage />}
            </div>}
            {isAddNotificationSettingsProfile &&
                <NotificationSettingsEditProfilePage
                    displayName={name ?? ''}
                    identifier={location.state.identifier ?? ''}
                    type={location.state.type ?? ''}
                    local={location.state.local}
                    hasEmail={location.state.hasEmail} />}
        </>
    );
};

export default NotificationSettingsComponent;
