import type { WidgetTemplate } from '@experiences/interfaces';
import React, {
    useContext,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import { WidgetFrameContext } from '../context/WidgetFrameContext';
import { WidgetHeader } from './WidgetHeader';

export interface UiWidgetHeaderProps<T> {
    template: WidgetTemplate<T>;
    isDataEmpty?: boolean;
}

export const UiWidgetHeader = <T extends {}>({
    template, isDataEmpty,
}: UiWidgetHeaderProps<T>) => {
    const { formatMessage: translate } = useIntl();
    const context = useContext(WidgetFrameContext);

    const subtitle = useMemo(() => {
        if (isDataEmpty) {
            return translate({ id: template.emptyMsgKey(context) });
        }

        if (template.tabs && template.tabs.length > 0 && template.defaultTab) {
            return undefined;
        }

        if (template.subtitleKey) {
            return translate({ id: template.subtitleKey });
        }

        return undefined;
    }, [ context, isDataEmpty, template, translate ]);

    return <WidgetHeader
        context={context}
        title={template.titleKey ? translate({ id: template.titleKey }, { 0: context.companyName }) : ''}
        subtitle={subtitle}
        preview={template.preview}
        seeAllText={template.seeAllTextKey ? translate({ id: template.seeAllTextKey }) : undefined}
        seeAllUrl={template.getSeeAllUrl ? template.getSeeAllUrl(context) : undefined}
        createUrl={template.getCreateUrl ? template.getCreateUrl(context) : undefined}
        id={template.name} />;
};

