import {
    isStringEmpty,
    lowercaseContains,
} from '@experiences/util';
import type { FilterOptionsState } from '@mui/material/useAutocomplete';
import {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import useCheckIfADEnabledOnHost from '../../../common/hooks/useCheckIfADEnabledOnHost';
import type { IDirectoryEntry } from '../../../common/interfaces/cis/directory';
import { convertToDownLevelLogonName } from '../../../util/DirectoryUtil';
import validateDownLevelLogonName from '../../../util/validators/DownLevelLogonNameValidator';
import validateEmail from '../../../util/validators/EmailValidator';

export interface IInputValidation {
    error: boolean;
    errorMessage?: string;
}

const useUiPeoplePickerValidation = () => {
    const isADEnabled = useCheckIfADEnabledOnHost();
    const { formatMessage: translate } = useIntl();
    const downLevelLogonNameSupported = useMemo(
        () => isADEnabled,
        [ isADEnabled ],
    );

    const validatePeoplePickerStringValue = useCallback(
        (value: string) =>
            downLevelLogonNameSupported ? validateEmail(value) || validateDownLevelLogonName(value) : validateEmail(value),
        [ downLevelLogonNameSupported ],
    );

    const validatePeoplePickerStringValues = useCallback(
        (values: string[]) =>
            values.every(value => !isStringEmpty(value) && validatePeoplePickerStringValue(value)),
        [ validatePeoplePickerStringValue ],
    );

    const validatePeoplePickerValues = useCallback(
        (values: Array<string | IDirectoryEntry>) => {
            const stringValues = values.filter((value): value is string => typeof value === 'string');
            return validatePeoplePickerStringValues(stringValues);
        },
        [ validatePeoplePickerStringValues ],
    );

    const getInputValidation = useCallback(
        (inputValue: string): IInputValidation => {
            if (isADEnabled) {
                const invalidCharacter = inputValue.match(/["/|=*<>()+;:[\]]/)?.[0];
                if (invalidCharacter) {
                    return {
                        error: true,
                        errorMessage: translate({ id: 'CLIENT_INVALID_INPUT_CHARACTER' }, { 0: invalidCharacter }),
                    };
                }
            }
            return { error: false };
        },
        [ isADEnabled, translate ],
    );

    const filterOptions = useCallback(
        (options: IDirectoryEntry[], state: FilterOptionsState<IDirectoryEntry>): IDirectoryEntry[] => {
            const { inputValue } = state;
            return options.filter(option => (
                lowercaseContains(option.email, inputValue) ||
          lowercaseContains(option.displayName, inputValue) ||
          lowercaseContains(option.identityName, inputValue) ||
          (downLevelLogonNameSupported && lowercaseContains(convertToDownLevelLogonName(option), inputValue))
            ));
        },
        [ downLevelLogonNameSupported ],
    );

    return {
        validatePeoplePickerStringValue,
        validatePeoplePickerStringValues,
        validatePeoplePickerValues,
        getInputValidation,
        filterOptions,
    };
};

export default useUiPeoplePickerValidation;
