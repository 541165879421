import {
    getShortNumber,
    roundTo2Decimals,
} from '@experiences/util';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { defaultMonths } from '../../common/constants/Constant';
import { getUsageDetails } from '../../services/licensing/LicenseUsageService';
import { BarChartContent } from '../common/UiBarChart/BarChartContent';
import { UiChartLoading } from './helperComponents/UiConsumableCard/UiChartLoading';

interface IUsageDetailsProps {
    entitlement: string;

}

interface IUsageDetailsPools {
    primary: string;
    secondary: string;
}

interface IUsageDetailsTranslate{
    primary: string;
    secondary: string;
}

type ChartData = {
    primary?: number[];
    secondary?: number[];
};

const defaultUsageDetails = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];

const UsageDetailsComponent = ({ entitlement }: IUsageDetailsProps) => {
    const { formatMessage: translate } = useIntl();
    const [ translateMap ] = useState<Map<string, IUsageDetailsTranslate>>(
        new Map([
            [
                'APICALLS',
                {
                    primary: 'CLIENT_USAGE_API_CALLS_BUNDLED_TITLE',
                    secondary: 'CLIENT_USAGE_API_CALLS_PURCHASED_TITLE',
                },
            ],
        ]),
    );
    const [ poolsMap ] = useState<Map<string, IUsageDetailsPools>>(
        new Map([
            [
                'APICALLS',
                {
                    primary: 'ApiCalls.Bundled',
                    secondary: 'ApiCalls.Purchased',
                },
            ],
        ]),
    );
    const entitlements = [ entitlement ];

    const [ usageDetailsData, setUsageDetailsData ] = useState<ChartData>(() =>
        ({ primary: undefined })
    );

    const { data: usageDetails } = useSWR(
        {
            url: `/api/usage/pools/details`,
            entitlements,
        },
        getUsageDetails,
    );

    const currentMonth = new Date().getUTCMonth();

    const translatedMonths = useMemo(() => defaultMonths.map((month) => translate({ id: `CLIENT_MONTH_${month}` })), [ translate ]);
    const months = useMemo(() => {
        const result = [ ...translatedMonths ];
        result.unshift(...result.splice(currentMonth - 11));
        return result;
    }, [ currentMonth, translatedMonths ]);

    useEffect(() => {

        if (usageDetails) {
            const usageDetail = usageDetails.find(value => value.entitlement === entitlement);
            const pools = poolsMap.get(entitlement);
            if (usageDetail && pools && usageDetail.poolsUsage[pools.primary] !== undefined) {

                const primaryUsageDetails = [ ...usageDetail.poolsUsage[pools.primary] ];

                primaryUsageDetails.unshift(...primaryUsageDetails.splice(currentMonth - 11));

                let secondaryUsageDetails = undefined;
                if (usageDetail.poolsUsage[pools.secondary] !== undefined) {

                    secondaryUsageDetails = [ ...usageDetail.poolsUsage[pools.secondary] ];
                    secondaryUsageDetails.unshift(...secondaryUsageDetails.splice(currentMonth - 11));
                }

                setUsageDetailsData({
                    primary: primaryUsageDetails.map((item) => roundTo2Decimals(item)),
                    secondary: secondaryUsageDetails?.map((item) => roundTo2Decimals(item)),
                });

                return;
            }

            setUsageDetailsData({ primary: defaultUsageDetails });
        }
    }, [ usageDetails, currentMonth, entitlement, poolsMap ]);

    return <>
        { usageDetailsData.primary ?
            (
                <BarChartContent dist={{
                    data: usageDetailsData.primary,
                    compareData: usageDetailsData.secondary,
                    legendProperties: { hasLegend: true },
                    legendTitle: translate({ id: translateMap.get(entitlement)?.primary }),
                    compareLegendTitle: translate({ id: translateMap.get(entitlement)?.secondary }),
                    labels: months,
                    scaleY: {
                        type: 'linear',
                        display: true,
                        tickFormatCallback: (domainValue: number) => getShortNumber(domainValue),
                    },
                    translateIdTooltipPrimary: translateMap.get(entitlement)?.primary,
                    translateIdTooltipSecondary: translateMap.get(entitlement)?.secondary,
                }} />
            ) :
            (
                <UiChartLoading
                    width={150}
                    height={36} />
            )}
    </>;
};

export default UsageDetailsComponent;
