import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiLegend } from './UiLegend';

const useStyles = makeStyles(theme =>
    createStyles({
        legend: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
    }),
);

export const UiConsumableCardLegend: React.FC = (
) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    return (
        <div className={classes.legend} >
            <UiLegend
                color={theme.palette.semantic.colorPrimary}
                text={translate(
                    { id: 'CLIENT_CONSUMABLE_CONSUMED' },
                )} />
            <UiLegend
                color={theme.palette.semantic.colorBackgroundSecondary}
                text={translate(
                    { id: `CLIENT_CONSUMABLE_AVAILABLE` },
                )} />
        </div>
    );
};
