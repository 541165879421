import type { TenantTagsTabType } from '@experiences/interfaces';
import { TenantTagsTab } from '@experiences/interfaces';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const useTenantTagsTabsViewModel = ({ type }: { type: TenantTagsTabType }) => {
    const { tenantId } = useParams() as { tenantId: string };
    const [ tabValueState, setTabValueState ] = useState<TenantTagsTabType>(type ?? TenantTagsTab.LABELS);

    return {
        tenantId,
        tabValueState: [ tabValueState, setTabValueState ] as const,
    };
};

export default useTenantTagsTabsViewModel;
