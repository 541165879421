import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    SpacingToken,
    UiProgressButton,
    UiStack,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    ApButton,
    ApLink,
} from '@uipath/portal-shell-react';
import { produce } from 'immer';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AllowedDomainsAndAttributeMappingSaml } from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import {
    ExternalUserMappingStrategy,
    Saml2BindingType,
    Usage,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import type { IFieldAttribute } from '../../../common/interfaces/cis/attribute';
import { AttributeType } from '../../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
    createDirectoryIntegrationSettingSaml2,
    updateDirectoryIntegrationSettingSaml2,
} from '../../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import { mapSamlFormDataToAuthSettingPayload } from '../../../util/setting/AuthSettingUtil';
import { useShowSuccessConfirmationDialog } from '../../authsettings/subcomponents/useShowSuccessConfirmationDialog';
import UiForm from '../../common/UiForm';
import SAMLAttributeMappingPageComponent from '../saml/SAMLAttributeMappingPageComponent';
import SAMLConfigureIDPPageComponent from '../saml/SAMLConfigureIDPPageComponent';
import { SAMLFormContextProvider } from '../saml/SAMLFormContextProvider';
import SecuritySettingsSAMLAdvancedDetailsForm from './SecuritySettingsSAMLAdvancedDetails';
import SecuritySettingsSAMLFormStepper from './SecuritySettingsSAMLFormStepper';
import SecuritySettingsSAMLGeneralForm from './SecuritySettingsSAMLGeneralForm';
import SecuritySettingsSAMLProvisioningSettings from './SecuritySettingsSAMLProvisioningSettings';

const SecuritySettingsSAMLForm: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const openShowSuccessConfirmationDialog = useShowSuccessConfirmationDialog(AuthenticationSettingType.SAML);

    const EnableSAMLUXRefactor = useFeatureFlagValue(Features.EnableSAMLUXRefactor.name);

    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);

    const disableForm = useMemo(() => !isAdmin, [ isAdmin ]);

    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const handleError = useCallback(
        async (error: any) => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();

    const authenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML];

    const [ activeStep, setActiveStep ] = useState(0);

    const getLocalizedLink = useDocumentationLinks();

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        if (!authenticationSetting?.externalIdentityProviderDto?.settings) {
            return undefined;
        }

        try {
            return JSON.parse(authenticationSetting.externalIdentityProviderDto.settings) as ISaml2ProviderSettings;
        } catch (error) {
            handleError(error);
        }
    }, [ authenticationSetting, handleError ]);

    const formMethods = useForm<ISamlFormData & { activeKeys: Array<keyof ISamlFormData> }>({
        mode: 'onChange',
        shouldUnregister: false,
        defaultValues: {
            activeKeys: [],
            SingleSignOnServiceUrl: '',
            ServiceProviderEntityId: '',
            IdentityProviderEntityId: '',
            SigningCertificateLocation: { CertificateText: '' },
            IdentityProviderMetadataUrl: '',
            AllowUnsolicitedAuthnResponse: true,
            ExternalAuthUserMappingStrategy: ExternalUserMappingStrategy.ByUserEmail,
            Saml2BindingType: Saml2BindingType.HttpRedirect,
            ServiceCertificateUsage: Usage.SigningAndEncryption,
            ProvisioningSetting: {
                AccountLinkConfirmation: false,
                AllowedDomains: '',
                CustomEmailClaimName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
                UniqueIdClaimName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
                AttributeMapper: {
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    JobTitle: '',
                    City: '',
                    Department: '',
                    DisplayName: '',
                    BusinessUnit: '',
                },
            },
            attributes: [
                {
                    attributeType: AttributeType.DISPLAYNAME,
                    value: '',
                    disableRemove: true,
                },
                {
                    attributeType: AttributeType.EMAIL,
                    value: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
                    disableRemove: true,
                    disableEdit: true,
                },
            ],
        },
    });

    const {
        handleSubmit, reset, trigger, formState: { errors }, watch, clearErrors,
    } = formMethods;

    useEffect(() => {
        if (!externalIdentityProviderDtoSettings) {
            return;
        }

        const customIdSet = externalIdentityProviderDtoSettings.ProvisioningSetting?.AttributeMapper?.UniqueId;
        const attributes = Object.entries(externalIdentityProviderDtoSettings.ProvisioningSetting?.AttributeMapper ?? {})
            .filter(([ key, value ]) => !!value && key !== 'ExtensionUserAttributeMappings')
            .map(([ key, value ]) => {
                const disableRemove = key === AttributeType.DISPLAYNAME || (customIdSet ? key === AttributeType.IDENTIFIER : key === AttributeType.EMAIL);
                const disableEdit = key === AttributeType.EMAIL;
                return {
                    attributeType: key,
                    value,
                    disableRemove,
                    disableEdit,
                } as IFieldAttribute;
            });

        Object.entries(externalIdentityProviderDtoSettings.ProvisioningSetting?.AttributeMapper?.ExtensionUserAttributeMappings ?? {})
            .forEach(([ key, value ]) => {
                if (key === AttributeType.BUSINESS) {
                    attributes.push({
                        attributeType: AttributeType.BUSINESS,
                        value: value as string,
                    });
                }
            });

        reset({
            activeKeys: [],
            SingleSignOnServiceUrl: externalIdentityProviderDtoSettings?.SingleSignOnServiceUrl ?? '',
            ServiceProviderEntityId: externalIdentityProviderDtoSettings?.ServiceProviderEntityId ?? '',
            IdentityProviderEntityId: externalIdentityProviderDtoSettings?.IdentityProviderEntityId ?? '',
            SigningCertificateLocation: {
                CertificateText: externalIdentityProviderDtoSettings?.SigningCertificateLocation?.CertificateText
                    ?? '',
            },
            IdentityProviderMetadataUrl: externalIdentityProviderDtoSettings?.IdentityProviderMetadataUrl ?? '',
            AllowUnsolicitedAuthnResponse:
                    externalIdentityProviderDtoSettings?.AllowUnsolicitedAuthnResponse != null
                        ? externalIdentityProviderDtoSettings?.AllowUnsolicitedAuthnResponse
                        : true,
            ExternalAuthUserMappingStrategy:
                    externalIdentityProviderDtoSettings?.ExternalAuthUserMappingStrategy ?? ExternalUserMappingStrategy.ByUserEmail,
            Saml2BindingType: externalIdentityProviderDtoSettings?.Saml2BindingType ?? Saml2BindingType.HttpRedirect,
            ServiceCertificateUsage:
                    externalIdentityProviderDtoSettings?.ServiceCertificateUsage ?? Usage.SigningAndEncryption,
            ProvisioningSetting: {
                AccountLinkConfirmation:
                        externalIdentityProviderDtoSettings?.ProvisioningSetting?.AccountLinkConfirmation ?? false,
                AllowedDomains: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AllowedDomains?.join(', ') ?? '',
                CustomEmailClaimName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.CustomEmailClaimName
                        ?? 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
                UniqueIdClaimName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.UniqueIdClaimName
                        ?? 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
                AttributeMapper: {
                    FirstName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.FirstName ?? '',
                    LastName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.LastName ?? '',
                    Email: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.Email ?? '',
                    JobTitle: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.JobTitle ?? '',
                    City: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.City ?? '',
                    Department: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.Department ?? '',
                    DisplayName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.DisplayName ?? '',
                    BusinessUnit: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.ExtensionUserAttributeMappings?.BusinessUnit ?? '',
                },
            },
            AdvancedSettings: externalIdentityProviderDtoSettings?.AdvancedSettings,
            attributes,
        });
    }, [ authenticationSetting, externalIdentityProviderDtoSettings, reset ]);

    const [ loading, setLoading ] = useState(false);

    const cancel = useCallback(() => {
        navigate(getRoute(RouteNames.SecuritySettings), { replace: true });
    }, [ navigate, getRoute ]);

    const navigateToStep = useCallback(
        async (step: number) => {
            await trigger(watch('activeKeys')); // TODO: review this...

            if (Object.keys(errors).length && step > activeStep) {
                return;
            }

            if (step < activeStep) {
                clearErrors();
            }

            setActiveStep(step);
        },
        [ activeStep, clearErrors, errors, trigger, watch ],
    );

    const onSubmit = useCallback(
        async (data: ISamlFormData & { activeKeys?: Array<keyof ISamlFormData> }) => {
            setLoading(true);
            await trigger();

            const configurationPayload: Record<string, string> = {};
            const extensionUserAttributeMappings: Record<string, string> = {};
            if (data.attributes) {
                for (const attribute of data.attributes) {
                    if (attribute.attributeType === AttributeType.BUSINESS) {
                        extensionUserAttributeMappings[attribute.attributeType] = attribute.value;
                    } else {
                        configurationPayload[attribute.attributeType] = attribute.value;
                    }
                }
            }
            delete data.attributes;
            delete data.activeKeys;
            if (data.AdvancedSettings == null) {
                delete data.AdvancedSettings;
            }
            const dataWithAttributes = produce(data, draft => {
                if (draft.ProvisioningSetting && EnableSAMLUXRefactor) {
                    draft.ProvisioningSetting.AttributeMapper = configurationPayload;
                    draft.ProvisioningSetting.AttributeMapper.ExtensionUserAttributeMappings = extensionUserAttributeMappings;
                    draft.ProvisioningSetting.AccountLinkConfirmation = true;
                }
            });
            const payload = mapSamlFormDataToAuthSettingPayload(
                dataWithAttributes,
                partitionGlobalId,
                authenticationSetting?.externalIdentityProviderDto?.id,
            );
            try {
                if (authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.SAML) {
                    await updateDirectoryIntegrationSettingSaml2(payload);
                } else {
                    await createDirectoryIntegrationSettingSaml2(payload);
                }

                const proceed = await openShowSuccessConfirmationDialog();

                if (proceed) {
                    navigate(getRoute(RouteNames.SecuritySettings), { replace: true });
                }
            } catch (error) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        },
        [
            trigger,
            partitionGlobalId,
            authenticationSetting?.externalIdentityProviderDto?.id,
            authenticationSetting?.authenticationSettingType,
            EnableSAMLUXRefactor,
            openShowSuccessConfirmationDialog,
            navigate,
            getRoute,
            handleError,
        ],
    );

    return (
        <SAMLFormContextProvider
            initialStatus={{
                error: '',
                loading: false,
                success: !!externalIdentityProviderDtoSettings?.IdentityProviderMetadataUrl,
            }}
            isEdit={!!externalIdentityProviderDtoSettings}>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <UiStack
                        justify="between"
                        align="baseline">
                        <ApLink
                            href={getLocalizedLink({ articleSlug: AllowedDomainsAndAttributeMappingSaml })}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}>
                            {translate({ id: 'CLIENT_READ_SAML_GUIDE' })}
                        </ApLink>

                        <UiStack gap={SpacingToken.XS}>
                            <ApButton
                                onClick={cancel}
                                data-cy="saml-cancel-button"
                                label={translate({ id: 'CLIENT_CANCEL' })}
                                variant="tertiary"
                            />
                            {activeStep > 0 && (
                                <ApButton
                                    onClick={async () => await navigateToStep(activeStep - 1)}
                                    variant="secondary"
                                    data-cy="saml-back-button"
                                    label={translate({ id: 'CLIENT_BACK' })}
                                />
                            )}
                            {activeStep < (EnableSAMLUXRefactor ? 1 : 2) && (
                                <ApButton
                                    disabled={Object.keys(errors).length > 0}
                                    onClick={async () => await navigateToStep(activeStep + 1)}
                                    variant="secondary"
                                    data-cy="saml-next-button"
                                    label={translate({ id: 'CLIENT_NEXT' })}
                                />
                            )}
                            {(!EnableSAMLUXRefactor || activeStep === 1) && <UiProgressButton
                                type="submit"
                                loading={loading}
                                disabled={disableForm || Object.keys(errors).length > 0}
                                variant="contained"
                                data-cy="saml-test-and-save-button">
                                {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                            </UiProgressButton>}
                        </UiStack>
                    </UiStack>
                }>
                <FormProvider {...formMethods}>
                    <SecuritySettingsSAMLFormStepper activeStep={activeStep} />
                    <UiStack
                        direction="column"
                        mt={SpacingToken.L}
                        style={{ overflowY: 'auto' }}>
                        {EnableSAMLUXRefactor ? (
                            <>
                                {activeStep === 0 && <SAMLConfigureIDPPageComponent />}
                                {activeStep === 1 && <SAMLAttributeMappingPageComponent />}
                            </>
                        ) : (
                            <>
                                {activeStep === 0 && <SecuritySettingsSAMLGeneralForm />}
                                {activeStep === 1 && <SecuritySettingsSAMLProvisioningSettings />}
                                {activeStep === 2 && <SecuritySettingsSAMLAdvancedDetailsForm />}
                            </>
                        )}
                    </UiStack>
                </FormProvider>
            </UiForm>
        </SAMLFormContextProvider>
    );
};

export default SecuritySettingsSAMLForm;
