import useEcommerceLearnMoreViewModel
    from '@experiences/ecommerce/src/lib/subcomponents/EcommerceLearnMoreComponent/EcommerceLearnMoreViewModel';
import {
    ActionCenterIcon,
    AppsIcon,
    AttendedRobotIcon,
    DataServiceIcon,
    IntegrationServiceIcon,
    StudioIcon,
    TaskCaptureIcon,
    TestManagerUserIcon,
} from '@experiences/images';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        productRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '8px',
        },
        productTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        infoTooltipText: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundInverse,
            paddingLeft: '20px',
        },
        tooltipBox: {
            maxWidth: '490px',
            padding: '8px 12px',
        },
        productInfoIcon: {
            fontSize: '1.2em',
            marginLeft: '4px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        productSubtitleRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        serviceIcon: { marginRight: '8px' },
        productSubtitle: {
            lineHeight: '20px',
            marginBottom: '2px',
            marginRight: '12px',
            fontWeight: 400,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
            display: 'inline',
        },
        moreServicesLink: {
            marginBottom: '2px',
            marginRight: '12px',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundLink,
            display: 'inline',
        },
        includedServiceItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '16px',
        },
        includedServicesTitle: {
            fontWeight: 400,
            lineHeight: '20px',
            fontSize: '14px',
            marginBottom: '13px',
            marginLeft: '11px',
            color: theme.palette.semantic.colorForeground,
        },
        includedServiceIcon: { marginBottom: '4px' },
        includedServiceText: {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        includedServicesContainer: {
            padding: '8px 8px 0px 8px',
            width: '492px',
            height: '100%',
        },
        includedServicesPopover: { marginTop: '7px' },
    }),
}));

const EcommerceProductInfo: React.FC<{
    productCode: string;
    planType: string;
}> = ({
    productCode, planType,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ servicesAnchorEl, setServicesAnchorEl ] = React.useState<HTMLApTypographyElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLApTypographyElement>) => {
        setServicesAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setServicesAnchorEl(null);
    };

    const getMoreIncludedServicesCount = useMemo(() => {
        switch (productCode) {
            case 'RPADEVPRONU':
                return 7;
            case 'CTZDEVNU':
                return 5;
            case 'ATTUNU':
                return 4;
            default:
                return -1;
        }
    }, [ productCode ]);

    const getServicesPopoverContent = useCallback(
        () => (
            <div className={classes.includedServicesContainer}>
                <UiText className={classes.includedServicesTitle}>
                    {translate({ id: 'CLIENT_PRODUCT_SERVICES_INCLUDED' })}
                </UiText>

                <Grid
                    container
                    data-cy="included-services-grid">
                    {[ 'RPADEVPRONU' ].includes(productCode) && (
                        <Grid
                            item
                            xs={2.4}
                            className={classes.includedServiceItem}>
                            <StudioIcon className={classes.includedServiceIcon} />
                            <UiText className={classes.includedServiceText}>
                                {translate({ id: 'CLIENT_STUDIO' })}
                            </UiText>
                        </Grid>
                    )}
                    {[ 'RPADEVPRONU', 'CTZDEVNU' ].includes(productCode) && (
                        <Grid
                            item
                            xs={2.4}
                            className={classes.includedServiceItem}>
                            <StudioIcon
                                className={classes.includedServiceIcon}
                                id={productCode} />
                            <UiText className={classes.includedServiceText}>
                                {translate({ id: 'CLIENT_STUDIO_X' })}
                            </UiText>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={2.4}
                        className={classes.includedServiceItem}>
                        <AttendedRobotIcon className={classes.includedServiceIcon} />
                        <UiText className={classes.includedServiceText}>
                            {translate({ id: 'CLIENT_ECOMMERCE_ATTENDED_ROBOT' })}
                        </UiText>
                    </Grid>
                    <Grid
                        item
                        xs={2.4}
                        className={classes.includedServiceItem}>
                        <AppsIcon className={classes.includedServiceIcon} />
                        <UiText className={classes.includedServiceText}>
                            {translate({ id: 'CLIENT_APPS' })}
                        </UiText>
                    </Grid>
                    <Grid
                        item
                        xs={2.4}
                        className={classes.includedServiceItem}>
                        <ActionCenterIcon className={classes.includedServiceIcon} />
                        <UiText className={classes.includedServiceText}>
                            {translate({ id: 'CLIENT_ECOMMERCE_ACTION_CENTER' })}
                        </UiText>
                    </Grid>
                    <Grid
                        item
                        xs={2.4}
                        className={classes.includedServiceItem}>
                        <IntegrationServiceIcon className={classes.includedServiceIcon} />
                        <UiText className={classes.includedServiceText}>
                            {translate({ id: 'CLIENT_ECOMMERCE_INTEGRATION_SERVICE' })}
                        </UiText>
                    </Grid>
                    <Grid
                        item
                        xs={2.4}
                        className={classes.includedServiceItem}>
                        <DataServiceIcon className={classes.includedServiceIcon} />
                        <UiText className={classes.includedServiceText}>
                            {translate({ id: 'CLIENT_DATA_SERVICE' })}
                        </UiText>
                    </Grid>
                    {[ 'RPADEVPRONU' ].includes(productCode) && (
                        <Grid
                            item
                            xs={2.4}
                            className={classes.includedServiceItem}>
                            <TestManagerUserIcon className={classes.includedServiceIcon} />
                            <UiText className={classes.includedServiceText}>
                                {translate({ id: 'CLIENT_ECOMMERCE_TEST_MANAGER_USER' })}
                            </UiText>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={2.4}
                        className={classes.includedServiceItem}>
                        <TaskCaptureIcon className={classes.includedServiceIcon} />
                        <UiText className={classes.includedServiceText}>
                            {translate({ id: 'CLIENT_TASK_CAPTURE' })}
                        </UiText>
                    </Grid>
                </Grid>
            </div>
        ),
        [ classes, productCode, translate ],
    );

    const {
        documentationLinksByProduct, getLearnMoreTooltipTranslationId,
    } = useEcommerceLearnMoreViewModel();

    return (
        <>
            <div className={classes.productRow}>
                <UiText className={classes.productTitle}>
                    {translate({ id: `CLIENT_PRODUCT_${productCode}` })}
                </UiText>
                <Tooltip
                    classes={{ tooltip: classes.tooltipBox }}
                    title={
                        <UiText data-cy="product-info-tooltip">
                            <ul className={classes.infoTooltipText}>
                                <FormattedMessage
                                    id={getLearnMoreTooltipTranslationId(productCode, planType)}
                                    values={{
                                        li: (chunk: React.ReactNode[]) => <li>
                                            {chunk}
                                        </li>,
                                        a: (msg: React.ReactNode[]) =>
                                            (
                                                <a
                                                    className={classes.a}
                                                    href={documentationLinksByProduct[productCode]}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {msg}
                                                </a>
                                            ),
                                    }}
                                />
                            </ul>
                        </UiText>
                    }
                    role="tooltip"
                >
                    <InfoOutlined
                        className={classes.productInfoIcon}
                        data-cy={`${productCode}-info-icon`}
                        aria-hidden={false}
                        tabIndex={0}
                    />
                </Tooltip>
            </div>

            <div className={classes.productSubtitleRow}>
                {[ 'UNATT', 'RU' ].includes(productCode) && (
                    <UiText className={classes.productSubtitle}>
                        {translate({ id: `CLIENT_PRODUCT_SUBTITLE_${productCode}_${planType}` })}
                    </UiText>
                )}

                {![ 'UNATT', 'RU' ].includes(productCode) && (
                    <UiText className={classes.productSubtitle}>
                        {translate({ id: `CLIENT_PRODUCT_ACCESS_TO` })}
                    </UiText>
                )}

                {productCode === 'RPADEVPRONU' && (
                    <>
                        <StudioIcon className={classes.serviceIcon} />
                        <UiText className={classes.productSubtitle}>
                            {translate({ id: 'CLIENT_STUDIO' })}
                        </UiText>
                        <StudioIcon
                            className={classes.serviceIcon}
                            id={productCode} />
                        <UiText className={classes.productSubtitle}>
                            {translate({ id: 'CLIENT_STUDIO_X' })}
                        </UiText>
                    </>
                )}
                {productCode === 'CTZDEVNU' && (
                    <>
                        <StudioIcon
                            className={classes.serviceIcon}
                            id={productCode} />
                        <UiText className={classes.productSubtitle}>
                            {translate({ id: 'CLIENT_STUDIO_X' })}
                        </UiText>
                        <AppsIcon className={classes.serviceIcon} />
                        <UiText className={classes.productSubtitle}>
                            {translate({ id: 'CLIENT_APPS' })}
                        </UiText>
                    </>
                )}
                {productCode === 'ATTUNU' && (
                    <>
                        <AppsIcon className={classes.serviceIcon} />
                        <UiText className={classes.productSubtitle}>
                            {translate({ id: 'CLIENT_APPS' })}
                        </UiText>
                        <ActionCenterIcon className={classes.serviceIcon} />
                        <UiText className={classes.productSubtitle}>
                            {translate({ id: 'CLIENT_ACTIONS' })}
                        </UiText>
                    </>
                )}

                {productCode !== 'UNATT' && productCode !== 'RU' && (
                    <>
                        <UiText
                            className={classes.moreServicesLink}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            data-cy={`${productCode}-included-services-more`}>
                            {translate({ id: `CLIENT_PRODUCT_ACCESS_TO_MORE` }, { productsCount: getMoreIncludedServicesCount })}
                        </UiText>
                        <Popover
                            PaperProps={{ className: classes.includedServicesPopover }}
                            anchorEl={servicesAnchorEl as unknown as Element}
                            open={Boolean(servicesAnchorEl)}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            sx={{ pointerEvents: 'none' }}
                        >
                            <UiText style={{ padding: '8px' }}>
                                {getServicesPopoverContent()}
                            </UiText>
                        </Popover>
                    </>
                )}
            </div>
        </>
    );
};

export default EcommerceProductInfo;
