import {
    en,
    enCountries,
    useLocalization,
} from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useAuthContext,
    useShowDialog,
    useSnackbar,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Cookies from 'js-cookie';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import {
    DIRECT_BUY_FLOW,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from '../helpers/EcommerceHelpers';
import { accountLogicalName } from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import type {
    IAddressDetails,
    ISubscriptionDetails,
    ISubscriptionForm,
} from '../interfaces/ecommerce';
import {
    createDirectBuyInactiveSubscription,
    createInactiveSubscription,
    validateUsTaxes,
} from '../services/BillingService';
import type { enTranslationKeys } from './EcommerceCheckoutCallbacks';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        continueToPayment: {
            marginTop: '36px',
            display: 'flex',
        },
    }),
}));

const EcommerceContinueToPaymentButton: React.FC<{
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    packageLoading: boolean;
    hasErrors: boolean;
}> = ({
    setLoading,
    loading,
    packageLoading,
    hasErrors,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const currentAccountNameFromSelector = useSelector(accountLogicalName);
    const { token } = useAuthContext();
    const {
        setPaymentIntentClientSecret,
        currentSkuPackageDetails,
        setStripeSubscriptionId,
        isTaxLoading,
        setOrganizationId,
        isAddressValidationUsed,
    } = useEcommerce();
    const currentAccountName = useSelector(accountLogicalName);
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const logEcommerceEvent = useEcommerceTelemetry();
    const { enqueueSnackbar } = useSnackbar();
    const sendEcommerceEvent = useCallback(
        (eventName: string, otherProperties?: any) => {
            logEcommerceEvent(eventName, {
                Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                ...otherProperties,
            });
        },
        [ isDirectBuyInProgressFlow, logEcommerceEvent ],
    );
    const createDialog = useShowDialog();

    const currentAccountLanguage = useLocalization();
    const {
        getValues, trigger, setError,
    } = useFormContext<ISubscriptionForm>();

    const onClick = useCallback(async () => {
        if (isTaxLoading) {
            return;
        }
        if (hasErrors) {
            return;
        }
        const validationResult = await trigger();
        if (!validationResult || hasErrors) {
            return;
        }
        const data = getValues();
        const {
            addressLine1: line1, addressLine2: line2, city: cityValue, country: countryCode, state, zipcode, currency,
            companyName, firstName, lastName, jobTitle, businessEmail, taxId, region,
        } = data;
        const addressDetails = {
            line1,
            line2,
            city: cityValue,
            country: countryCode,
            postalcode: zipcode,
            state,
        } as IAddressDetails;
        if (countryCode === 'US' && token) {
            const taxValidationResult = await validateUsTaxes(addressDetails, token, currentAccountName);

            sendEcommerceEvent('Billing.ValidateAddress', { ValidationResult: taxValidationResult.isValid });

            if (!taxValidationResult.isValid) {
                setError('addressLine1', { type: 'addressError' });
                setError('zipcode', { type: 'addressError' });
                setError('city', { type: 'addressError' });
                setError('state', { type: 'addressError' });

                enqueueSnackbar(translate({ id: 'CLIENT_UNABLE_TO_VALIDATE_YOUR_BILLING_ADDRESS' }), { variant: 'error' });

                setLoading(false);
                return;
            }
        }
        const countryCodeToEnCountryName: { [key: string]: string } = enCountries;
        const countryNameInEn = countryCodeToEnCountryName[countryCode];
        const subscriptionDetails = {
            countryCode,
            planType: currentSkuPackageDetails.planType,
            addressDetails: {
                city: cityValue,
                country: countryCode,
                line1,
                line2,
                state,
                postalcode: zipcode,
            },
            currency,
            leadDetails: {
                firstName,
                lastName,
                country: countryNameInEn,
                userLanguage: currentAccountLanguage,
                businessEmail,
                companyName,
                jobTitle,
                state: state ?? '',
            },
            organizationName: isSignupDirectBuyFlow ? data.companyName : currentAccountNameFromSelector,
            products: currentSkuPackageDetails.products,
            taxId,
            ...(isSignupDirectBuyFlow && { googleAnalyticsId: Cookies.get('_ga') }),
            ...(isSignupDirectBuyFlow && { region }),
        } as ISubscriptionDetails;
        setLoading(true);
        if (isAddressValidationUsed) {
            sendEcommerceEvent('Checkout.AddressSuggestions');
        }
        sendEcommerceEvent('Billing.ShowPaymentMethod', {
            Currency: data.currency,
            Country: data.country,
            State: data.state,
            City: data.city,
        });
        try {
            let createSubscriptionResponse;
            if (isSignupDirectBuyFlow) {
                createSubscriptionResponse = await createDirectBuyInactiveSubscription(subscriptionDetails, token);
            } else {
                createSubscriptionResponse = await createInactiveSubscription(subscriptionDetails, currentAccountNameFromSelector, token);
            }
            setPaymentIntentClientSecret(createSubscriptionResponse.paymentIntentClientSecret);
            setStripeSubscriptionId(createSubscriptionResponse.stripeSubscriptionId);
            setOrganizationId(createSubscriptionResponse.organizationId);
        } catch (error: any) {
            let errorBodyMessage, eventErrorMessage;
            if (error.message?.includes('The customer already has a subscription started')) {
                eventErrorMessage = en['CLIENT_STRIPE_SUBSCRIPTION_ALREADY_EXISTS'];
                errorBodyMessage = (
                    <div>
                        {translate({ id: 'CLIENT_STRIPE_SUBSCRIPTION_ALREADY_EXISTS' })}
                    </div>
                );
            } else {
                eventErrorMessage = en[error.translationId as enTranslationKeys];
                errorBodyMessage = (
                    <FormattedMessage
                        id={error.translationId}
                        values={{
                            // eslint-disable-next-line react/no-unstable-nested-components
                            p: (msg: React.ReactNode[]) => <p>
                                {msg}
                            </p>,
                            taxIdFormatHint: error.description,
                        }}
                    />
                );
            }

            sendEcommerceEvent('Billing.PaymentIntentFailed', {
                Currency: data.currency,
                Country: data.country,
                State: data.state,
                City: data.city,
                Error: eventErrorMessage,
            });

            setLoading(false);
            await createDialog({
                title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
                body: errorBodyMessage,
                icon: 'warning',
                showCancel: false,
                primaryButtonText: translate({ id: 'CLIENT_OK' }),
            });
        }
        setLoading(false);
    }, [
        isTaxLoading,
        hasErrors,
        trigger,
        getValues,
        token,
        currentSkuPackageDetails.planType,
        currentSkuPackageDetails.products,
        currentAccountLanguage,
        isSignupDirectBuyFlow,
        currentAccountNameFromSelector,
        setLoading,
        isAddressValidationUsed,
        sendEcommerceEvent,
        currentAccountName,
        setError,
        enqueueSnackbar,
        translate,
        setPaymentIntentClientSecret,
        setStripeSubscriptionId,
        setOrganizationId,
        createDialog,
    ]);

    return (
        <div className={classes.continueToPayment}>
            <UiProgressButton
                loading={loading}
                disabled={loading || packageLoading || hasErrors || isTaxLoading}
                variant="contained"
                data-cy="continue-to-payment"
                onClick={onClick}
            >
                {translate({ id: 'CLIENT_CONTINUE_TO_PAYMENT' })}
            </UiProgressButton>
        </div>
    );
};

export default EcommerceContinueToPaymentButton;
