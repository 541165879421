import {
    UiBackground,
    UiLogo,
    UiSessionId,
    UiText,
} from '@experiences/ui-common';
import { getEnvironment } from '@experiences/util';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 380,
            height: 350,
            overflow: 'auto',
            marginTop: '100px',
            padding: '0px 16px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        headerContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        headerSubText: {
            marginTop: '8px',
            textAlign: 'center',
        },
        button: {
            width: '280px',
            margin: '16px',
        },
    }),
);

export const SessionExpiry = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        const env = getEnvironment();
        if (env === 'local') {
            console.log(
                '____██████████████████████████████████████████████████████████____\r\n' +
          '__██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒██__\r\n' +
          '██░░░░░░██████░░░░░░░░░░░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒████████▒▒▒▒▒▒██\r\n' +
          '██░░░░██░░░░░░░░░░██████████░░░░████████▒▒▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░██░░░░░░░░░░░░░░██░░░░░░██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░██░░░░░░░░░░░░░░██░░░░░░██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░░░██████░░░░░░░░██░░▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░░░░░░░░░██░░░░░░██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██████████▒▒▒▒▒▒██\r\n' +
          '██░░░░░░░░░░░░██░░░░▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒██\r\n' +
          '██░░░░░░░░░░░░██░░▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒██\r\n' +
          '██░░░░████████░░░░▒▒▒▒██▒▒▒▒▒▒▒▒████████▒▒▒▒▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒██\r\n' +
          '__██░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒██__\r\n' +
          '____██████████████████████████████████████████████████████████____\r\n',
                '\r\n',
                'If you are running into this page in a loop, please refer to https://github.com/UiPath/Experiences/#running-locally-development-build',
            );
        }
    }, []);

    const login = useCallback(() => {
        window.location.assign('/');
    }, []);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div className={classes.headerContainer}>
                    <UiLogo />
                    <CancelIcon className={classes.headerIcon} />
                    <UiText className={classes.headerText}>
                        {translate({ id: 'CLIENT_SESSION_EXP_HEADER' })}
                    </UiText>
                    <UiText className={classes.headerSubText}>
                        {translate({ id: 'CLIENT_SESSION_EXP_MESSAGE' })}
                    </UiText>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={login}>
                        {translate({ id: 'CLIENT_SIGN_IN' })}
                    </Button>
                    <UiSessionId />
                </div>
            </Paper>
        </UiBackground>
    );
};
