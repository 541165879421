import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import CheckIcon from '@mui/icons-material/Check';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        fontSizeM: {
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            paddingTop: '6px',
            paddingBottom: '6px',
        },
        checkmarkIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            marginRight: '11px',
        },
    }),
}));

export const EcommerceCheckListItem: React.FC<{
    translationId: string;
}> = ({ translationId }) => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return (
        <ListItem
            disableGutters
            className={classes.fontSizeM}>
            <ListItemIcon>
                <CheckIcon
                    fontSize="small"
                    className={classes.checkmarkIcon} />
                <UiText>
                    {translate({ id: translationId })}
                </UiText>
            </ListItemIcon>
        </ListItem>
    );
};
