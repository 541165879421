import { UiText } from '@experiences/ui-common';
import DragIndicatorOutlined from '@mui/icons-material/DragIndicatorOutlined';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import type { Service } from '@uipath/portal-shell-types';
import {
    Reorder,
    useMotionValue,
} from 'framer-motion';
import React from 'react';
import { useIntl } from 'react-intl';

import { DividerBox } from './DividerBoxComponent';
import { useCustomDrag } from './hooks/use-custom-drag';
import { ServiceIcon } from './ServiceIconComponent';
import { MORE_DIVIDER } from './utils';

export const NavigationSettingsItem = ({
    item,
    dragEnd,
    keyNavigation,
}: {
    item: Service;
    dragEnd: () => void;
    keyNavigation: (event: React.KeyboardEvent, item: Service) => void;
}) => {
    const y = useMotionValue(0);
    const {
        boxShadow, backgroundColor, cursor,
    } = useCustomDrag(y);
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    const isMoreDivider = item.id === MORE_DIVIDER.id;

    return (
        <Reorder.Item
            value={item}
            id={item.id}
            onDragEnd={dragEnd}
            tabIndex={0}
            onKeyUp={e => keyNavigation(e, item)}
            whileFocus={{ backgroundColor: !isMoreDivider ? theme.palette.semantic.colorHover : 'none' }}
            whileHover={{ backgroundColor: !isMoreDivider ? theme.palette.semantic.colorHover : 'none' }}
            style={{
                backgroundColor: !isMoreDivider ? backgroundColor : 'none',
                boxShadow: !isMoreDivider ? boxShadow : 'none',
                cursor,
                y,
                margin: 0,
                width: '100%',
                height: '48px',
                position: 'relative',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'unset',
                padding: '2px',
                outlineOffset: '-2px',
            }}>
            <Box sx={{
                height: '24px',
                marginRight: !isMoreDivider ? '29px' : '13px',
            }}>
                <DragIndicatorOutlined sx={{ color: theme.palette.semantic.colorForegroundDeEmp }} />
            </Box>
            <Box sx={{
                marginTop: '5px',
                height: '24px',
                display: 'flex',
                flexDirection: isMoreDivider ? 'column' : 'row',
                flexGrow: 1,
            }}>
                { item.id === MORE_DIVIDER.id ? (
                    <DividerBox text={translate({ id: 'CLIENT_NAVIGATION_SETTINGS_HIDDEN_ITEMS' })} />
                ) : (
                    <>
                        <ServiceIcon svg={item.svg} />

                        <Box sx={{ height: '24px' }}>
                            <UiText style={{ color: theme.palette.semantic.colorForeground }}>
                                {item.name}
                            </UiText>
                        </Box>
                    </>
                )}
            </Box>
        </Reorder.Item>
    );
};
