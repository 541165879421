import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { dashboardLicenses } from '../../common/constants/Constant';
import { TabTelemetryMap } from '../../common/constants/TelemetryEvents';
import { useTrialServiceTab } from '../../services/licensing/TrialPerSku';
import {
    EnableUserLicensingSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { ConsumablePanel } from '../licensing/ConsumablePanel';
import { TrialPerSkuPanel } from '../licensing/trialPerSku/TrialPerSkuPanel';
import { Unlicensed } from '../licensing/Unlicensed';
import { ViewLicensesPanel } from '../licensing/ViewLicensesPanel';

const useStyles = makeStyles(theme =>
    createStyles({
        content: { marginTop: '8px' },
        tabIndicator: { height: '3px' },
        licenseLabel: { marginTop: '24px' },
        unlicensed: { marginTop: '32px' },
        newUiTabs: { marginBottom: '10px' },
        allocatedLabel: {
            margin: '12px 0',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const UsageChartsComponent: React.FC<{
    isServiceMode: boolean;
    tenantIds?: string[];
}> = ({
    tenantIds, isServiceMode,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const [ value, setValue ] = React.useState(dashboardLicenses.USERS);
    const enableConsumablesTab = useFeatureFlagValue(Features.EnableConsumablesTab.name);
    const {
        shouldSeeTrialServicesTab, trialServicesResponse,
    } = useTrialServiceTab();

    useEffect(() => {
        if (EnableUserLicensing && !isServiceMode) {
            setValue(dashboardLicenses.USERS);
        } else {
            setValue(EnableUserLicensing ? dashboardLicenses.ROBOTS_AND_SERVICES : dashboardLicenses.USERS);
        }
    }, [ EnableUserLicensing, isServiceMode ]);

    const { logEvent } = useTelemetryHelper();
    const handleChange = useCallback(
        (_: any, newValue: string) => {
            setValue(newValue);
            const telemetryEventName = TabTelemetryMap.get(newValue);
            if (telemetryEventName) {
                if (tenantIds?.length === 1) {
                    logEvent(telemetryEventName, { CloudTenantId: tenantIds[0] });
                } else {
                    logEvent(telemetryEventName);
                }
            }
        },
        [ logEvent, tenantIds ],
    );

    const shouldShowTabs = useMemo(
        () => !EnableUserLicensing || !isServiceMode || enableConsumablesTab,
        [ EnableUserLicensing, isServiceMode, enableConsumablesTab ]);

    const shoulShowUsersTab = useMemo(
        () => !EnableUserLicensing || !isServiceMode,
        [ EnableUserLicensing, isServiceMode ]);

    const shouldShowConsumablesTab = useMemo(
        () => enableConsumablesTab && isServiceMode,
        [ enableConsumablesTab, isServiceMode ]);

    const shouldShowTrialServicesTab = useMemo(
        () => !isServiceMode && shouldSeeTrialServicesTab,
        [ isServiceMode, shouldSeeTrialServicesTab ]);

    if (isUnlicensedMode) {
        return (
            <div className={classes.unlicensed}>
                <Unlicensed />
            </div>
        );
    }

    return (
        <div>
            <div>
                {shouldShowTabs && (
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className={clsx('default', classes.newUiTabs)}
                    >
                        {shoulShowUsersTab && <Tab
                            value={dashboardLicenses.USERS}
                            label={translate({ id: 'CLIENT_USERS' })}
                            className="default"
                            data-cy="usage-chart-users-tab"
                            disableRipple
                        />}
                        <Tab
                            value={dashboardLicenses.ROBOTS_AND_SERVICES}
                            label={translate({ id: 'CLIENT_ROBOTS_AND_SERVICES' })}
                            className="default"
                            data-cy="usage-chart-robots-and-services-tab"
                            disableRipple
                        />
                        { shouldShowConsumablesTab && <Tab
                            value={dashboardLicenses.CONSUMABLES}
                            label={translate({ id: 'CONSUMABLES' })}
                            className="default"
                            data-cy="consumables-tab"
                            disableRipple
                        />}
                        {shouldShowTrialServicesTab && (
                            <Tab
                                value={dashboardLicenses.TRIAL_SERVICES}
                                label={translate({ id: 'CLIENT_FREE_TRIALS_SERVICES' })}
                                className="default"
                                data-cy="trial-services-tab"
                                disableRipple
                            />
                        )}
                    </Tabs>
                )}
                {value === dashboardLicenses.TRIAL_SERVICES ? (
                    <TrialPerSkuPanel
                        trialServices={trialServicesResponse}
                        tenantIds={tenantIds}
                        isHome />
                ) : (value === dashboardLicenses.CONSUMABLES && enableConsumablesTab && isServiceMode ? (
                    <ConsumablePanel
                        tenantId={tenantIds?.length === 1 ? tenantIds[0] : undefined}
                        isServiceMode />
                ) : (
                    <ViewLicensesPanel
                        selectedTab={value}
                        enabledConsumablesTab={enableConsumablesTab}
                        isHome
                        useLegacyProducts={!EnableUserLicensing && value === dashboardLicenses.USERS}
                        isServiceMode={isServiceMode}
                        tenantId={tenantIds?.length === 1 ? tenantIds[0] : ''}
                        tenantIds={tenantIds}
                    />)
                )}

            </div>
        </div>
    );
};

export default UsageChartsComponent;
