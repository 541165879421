import type { FreContentfulResponse } from '@experiences/interfaces';
import { get } from '@experiences/util';

export async function getFRE(accessToken: string, accountLogicalName: string, language: string) {
    return await get<FreContentfulResponse>('/api/content/fre/getFREData', {
        accessToken,
        urlParams: {
            accountLogicalName,
            language,
        },
    });
}
