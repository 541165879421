import type {
    ILabelModel,
    ILabelModelPaginationResult,
    IPagination,
    ITagModelObject,
    IValueModelPaginatedResult,
    PermissionType,
} from '@experiences/interfaces';

import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';
import {
    cleanLabelTagResponse,
    cleanValueTagResponse,
    DEFAULT_SEARCH_FILTERS,
    mapObjectId,
    mapPagination,
} from './TagsUtil';

export interface ITagLabelDto extends Partial<ITagModelObject>{}

export const tagsUrl = '/api/orchestrator/tags';

export async function getPermissions({
    accountLogicalName, tenantName,
}: {
    url: string;
    accountLogicalName: string;
    tenantName: string | undefined;
    selectedAccountId: string;
}) {
    const res = await get<PermissionType[]>(`${tagsUrl}/${accountLogicalName}/${tenantName}/permissions`);
    return res.map(permission => permission.toLowerCase()) as PermissionType[];
}

export async function getLabels({
    pagination, accountLogicalName, tenantName,
}: {
    url: string;
    pagination: IPagination;
    accountLogicalName: string;
    tenantName: string | undefined;
    selectedAccountId: string;
}) {
    const response = await get<ILabelModelPaginationResult>(`${tagsUrl}/${accountLogicalName}/${tenantName}`, {
        urlParams: {
            ...DEFAULT_SEARCH_FILTERS,
            ...mapPagination(pagination),
            type: 'Label',
        },
    });

    return cleanLabelTagResponse(response);
}

export async function getKeyValues({
    pagination, accountLogicalName, tenantName,
}: {
    url: string;
    pagination: IPagination;
    accountLogicalName: string;
    tenantName: string | undefined;
    selectedAccountId: string;
}) {
    const response = await get<ILabelModelPaginationResult>(`${tagsUrl}/${accountLogicalName}/${tenantName}`, {
        urlParams: {
            ...DEFAULT_SEARCH_FILTERS,
            ...mapPagination(pagination),
            type: 'KeyValue',
        },
    });

    return cleanLabelTagResponse(response);
}

export async function getLabel({
    accountLogicalName, tenantName, id,
}: {
    url: string;
    accountLogicalName: string;
    tenantName: string | undefined;
    id: string;
    selectedAccountId: string;
}) {
    const response = await get<ILabelModel>(`${tagsUrl}/${accountLogicalName}/${tenantName}/${id}`);
    return mapObjectId(response);
}

export async function createLabel(
    accountLogicalName: string,
    tenantName: string | undefined,
    payload: ITagLabelDto,
    _selectedAccountId: string,
) {
    payload.type = 'Label';
    return post(`${tagsUrl}/${accountLogicalName}/${tenantName}`, { body: payload });
}

export async function createKeyValue(
    accountLogicalName: string,
    tenantName: string | undefined,
    payload: ITagLabelDto,
    _selectedAccountId: string,
) {
    payload.type = 'KeyValue';
    return post(`${tagsUrl}/${accountLogicalName}/${tenantName}`, { body: payload });
}

export async function updateLabel(
    accountLogicalName: string,
    tenantName: string | undefined,
    id: string | undefined,
    payload: ITagLabelDto,
    _selectedAccountId: string,
) {
    return put(`${tagsUrl}/${accountLogicalName}/${tenantName}/${id}`, { body: payload });
}

export async function deleteLabel(accountLogicalName: string, tenantName: string | undefined, keys: string[], _selectedAccountId: string) {
    return axiosDelete(`${tagsUrl}/${accountLogicalName}/${tenantName}`,
        {
            urlParams: {
                keys,
                type: 'Label',
            },
        },

    );
}

export async function deleteKeyValue(
    accountLogicalName: string,
    tenantName: string | undefined,
    keys: string[],
    _selectedAccountId: string,
) {
    return axiosDelete(`${tagsUrl}/${accountLogicalName}/${tenantName}`,
        {
            urlParams: {
                keys,
                type: 'KeyValue',
            },
        },
    );
}

export async function getValuesForKey({
    pagination, accountLogicalName, tenantName, key,
}: {
    url: string;
    pagination: IPagination;
    accountLogicalName: string;
    tenantName: string;
    key: string;
    selectedAccountId: string;
}) {
    const response = await get<IValueModelPaginatedResult>(`${tagsUrl}/${accountLogicalName}/${tenantName}/${key}/values`, {
        urlParams: {
            ...DEFAULT_SEARCH_FILTERS,
            ...mapPagination(pagination),
        },
    });
    return cleanValueTagResponse(response);
}

export async function createValuesForKey(
    accountLogicalName: string,
    tenantName: string | undefined,
    key: string,
    payload: { labelKey: string; values: string[]; accountKey: string; tenantKey: string },
    _selectedAccountId: string,
) {
    return post(`${tagsUrl}/${accountLogicalName}/${tenantName}/${key}/values`, { body: payload });
}

export async function deleteValueFromKey(
    accountLogicalName: string,
    tenantName: string | undefined,
    key: string,
    valueKey: string,
    selectedAccountId: string,
) {
    return axiosDelete(`${tagsUrl}/${accountLogicalName}/${tenantName}/${key}/values/${valueKey}`);
}

export async function deleteValuesFromKey(
    accountLogicalName: string,
    tenantName: string | undefined,
    key: string,
    valueKeys: string[],
    _selectedAccountId: string,
) {
    return axiosDelete(`${tagsUrl}/${accountLogicalName}/${tenantName}/${key}/values`,
        { urlParams: { valueKeys } },
    );
}
