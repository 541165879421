import type { sidebarData } from '../../common/interfaces/sidebar';

const studioPosition = 0;
export const SidebarBackup: sidebarData = {
    'sidebarContent': {
        'downloadStudioPosition': studioPosition,
        'sidebarLinkContainersCollection': {
            'items': [
                {
                    'subtitle': '',
                    'title': 'RESOURCES',
                    'sidebarLinksCollection': {
                        'items': [
                            {
                                'titleText': 'QUESTIONS',
                                'linkHref': 'https://www.uipath.com/rpa/academy/faq',
                                'linkText': 'VISIT',
                            }, {
                                'titleText': 'AUTOMATION',
                                'linkHref': 'https://forum.uipath.com/',
                                'linkText': 'COMMUNITY',
                            }, {
                                'titleText': 'TECHNICAL',
                                'linkHref': 'https://www.uipath.com/support',
                                'linkText': 'CONTACT',
                            },
                        ],
                    },
                }, {
                    'subtitle': '',
                    'title': 'NEW',
                    'sidebarLinksCollection': {
                        'items': [
                            {
                                'titleText': 'SALESFORCE',
                                'linkHref': 'https://www.uipath.com/resources/automation-demo/uipath-connector-salesforce',
                                'linkText': 'PIPELINE',
                            }, {
                                'titleText': 'WEBINAR',
                                'linkHref': 'https://www.uipath.com/resources/automation-webinars',
                                'linkText': 'RESERVE',
                            },
                        ],
                    },
                },
            ],
        },
    },
};
