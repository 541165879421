import { useCentralErrorSetter } from '@experiences/error';
import { useFeatureFlagValue } from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    getEnvVariableValue,
    useModalState,
} from '@experiences/util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import { mutate } from 'swr';

import {
    notificationType,
    QUOTA_REACHED_MESSAGE,
    UserPartition,
} from '../../../common/constants/Constant';
import { GroupsAndRolesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import useIsServicesPageEnabled from '../../../common/hooks/useIsServicesPageEnabled';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IRobot } from '../../../common/interfaces/cis/robot';
import {
    createRobot,
    editRobot,
    getRobots,
    robotUrl,
} from '../../../services/identity/RobotAccountService';
import { accountGlobalId } from '../../../store/selectors';
import reduceGroupCIS, { mapUpdateRobotDto } from '../../../util/UserGroupUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { AddEditRobotAccountData } from '../interfaces/user';
import { ConfirmAddEditRobotSuccessComponent } from './SuccessMessageComponents';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        input: { marginTop: 20 },
        groups: {
            marginTop: 40,
            display: 'flex',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        groupInfoIcon: {
            width: 18,
            height: 18,
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        tooltipIcon: {
            width: 16,
            height: 16,
            marginLeft: '4px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        editText: { margin: '48px 0px 24px 0px' },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
    }),
}));

const AddEditRobotAccountComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const isServicesPageEnabled = useIsServicesPageEnabled();
    const {
        open, close,
    } = useModalState(RouteNames.Robots);
    const enableRobotDisplayName = useFeatureFlagValue('EnableRobotDisplayName');

    const getLocalizedLink = useDocumentationLinks();
    const currentAccountGlobalId = useSelector(accountGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);

    const { state } = useLocation();

    const robot = useMemo(() => state?.robot || ({} as IRobot), [ state ]);

    const {
        loading: groupLoading, groups,
    } = useSimpleGroup(true);

    const [ loading, setLoading ] = useState(false);
    const [ showConfirmCreate, setShowConfirmCreate ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessageId, setErrorMessageId ] = useState('CLIENT_GROUP_CIS_ERROR');
    const setErrorMessage = useCentralErrorSetter();

    const initialFormData = useMemo(() => {
        if (type === 'add' && groups) {
            return {
                name: '',
                groupIds: reduceGroupCIS(groups),
            };
        }
        if (type === 'edit' && robot && groups) {
            return {
                name: robot.displayName,
                groupIds: reduceGroupCIS(groups, robot.groupIds),
            };
        }
    }, [ robot, groups, type ]);

    const {
        control,
        handleSubmit,
        formState: {
            isDirty,
            errors,
        },
        setError,
    } = useForm<AddEditRobotAccountData>({
        mode: 'onSubmit',
        defaultValues: initialFormData,
    });

    const defaultChecked = useMemo(
        () => Object.keys(initialFormData?.groupIds || {}).filter(g => initialFormData?.groupIds[g]),
        [ initialFormData ],
    );

    const isNameTaken = useCallback(
        async (name: string) => {
            if (name === initialFormData?.name) {
                return false;
            }
            const response = await getRobots({
                pagination: {
                    top: UserPartition.MAX_RETURNED_USERS,
                    skip: 0,
                    searchTerm: name,
                },
                partitionGlobalId,
            });
            return response.results.some(result => result.name?.toLowerCase() === name.toLowerCase());
        },
        [ initialFormData?.name, partitionGlobalId ],
    );

    const onSubmit = useCallback(
        async (data: AddEditRobotAccountData) => {
            setLoading(true);

            try {
                if (await isNameTaken(data.name)) {
                    setError('name', { type: 'available' });
                    setLoading(false);
                    return;
                }
                if (type === 'add') {
                    await createRobot(mapUpdateRobotDto(data, currentAccountGlobalId));
                } else {
                    await editRobot(robot.id, mapUpdateRobotDto(data, currentAccountGlobalId));
                }
            } catch (error) {
                setLoading(false);

                if ((error as any)?.response?.data?.Message === QUOTA_REACHED_MESSAGE) {
                    setErrorMessage(translate({ id: 'CLIENT_QUOTA_REACHED' }));
                    return;
                }

                setShowDrawerError(true);
                setErrorMessageId(`CLIENT_${type.toUpperCase()}_ROBOT_ERROR`);
                return;
            }

            setLoading(false);
            await mutate((key: any) => key?.url === robotUrl);
            if (type === 'add' && isServicesPageEnabled) {
                setShowConfirmCreate(true);
            } else {
                createNotification(
                    translate({ id: type === 'add' ? 'CLIENT_NEW_ROBOT_ADDED' : 'CLIENT_ROBOT_UPDATED' }),
                    notificationType.SUCCESS
                );
                close(true);
            }

        },
        [
            type,
            isServicesPageEnabled,
            isNameTaken,
            setError,
            currentAccountGlobalId,
            robot.id,
            createNotification,
            setErrorMessage,
            translate,
            close,
        ],
    );

    return (
        <UiDrawer
            title={
                type === 'add'
                    ? translate({ id: 'CLIENT_ADD_ROBOT_ACCOUNT' })
                    : translate({ id: 'CLIENT_EDIT_ROBOT_ACCOUNT' })
            }
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(true),
            }}
            loading={groupLoading}
            error={{
                showError: showDrawerError,
                message: translate({ id: errorMessageId }),
            }}
        >
            <>
                {showConfirmCreate ? (
                    <ConfirmAddEditRobotSuccessComponent close={close} />
                ) : (
                    <UiForm
                        onSubmit={handleSubmit(onSubmit)}
                        actions={
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary"
                                    data-cy='add-edit-cancel-button'>
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="add-edit-submit-button"
                                >
                                    {type === 'add'
                                        ? translate({ id: 'CLIENT_ADD' })
                                        : translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        }
                        isDrawer
                    >
                        {type === 'add' && (
                            <UiText style={{ marginTop: '24px' }}>
                                {translate({ id: 'CLIENT_ADD_ROBOT_DESC' })}
                            </UiText>
                        )}
                        <div className={classes.input}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label={translate({ id: 'CLIENT_NAME' })}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!enableRobotDisplayName && type === 'edit'}
                                        error={!!errors.name}
                                        helperText={
                                            (errors.name?.type === 'required' &&
                                                translate(
                                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                    { 0: translate({ id: 'CLIENT_NAME' }) },
                                                )) ||
                                            (errors.name?.type === 'available' && translate({ id: 'CLIENT_NAME_TAKEN_ERROR' }))
                                        }
                                        autoComplete="off"
                                        InputProps={{ className: 'Tall' }}
                                        InputLabelProps={{ id: 'requiredNameLabel' }}
                                        inputProps={{ 'aria-labelledby': 'requiredNameLabel' }}
                                        data-cy="add-edit-name"
                                    />}
                            />
                        </div>
                        <div className={classes.groups}>
                            <UiText
                                id="client-group-label"
                                className={classes.inputLabel}>
                                {translate({ id: 'CLIENT_GROUP_MEMBERSHIP' })}
                            </UiText>
                            <Tooltip
                                arrow
                                title={
                                    <div style={{ display: 'flex' }}>
                                        {translate(
                                            { id: 'CLIENT_GROUPS_GUIDE' },
                                            { titleProductName: getEnvVariableValue('TITLE_PRODUCT_NAME') },
                                        )}
                                        <OpenInNewIcon className={classes.tooltipIcon} />
                                    </div>
                                }
                            >
                                <a
                                    className={classes.a}
                                    href={getLocalizedLink({ articleSlug: GroupsAndRolesLink })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={translate({ id: 'CLIENT_GROUPS_GUIDE_LINK_ARIA' })}
                                >
                                    <HelpOutlineIcon className={classes.groupInfoIcon} />
                                </a>
                            </Tooltip>
                        </div>
                        <FormGroup
                            role="group"
                            aria-labelledby="client-group-label">
                            {groups?.map((group, i) => (
                                <Controller
                                    key={i}
                                    name={`groupIds.${group.id}`}
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    defaultChecked={defaultChecked.indexOf(group.id) > -1}
                                                    checked={field.value}
                                                    onChange={e => field.onChange(e.target.checked)}
                                                    name={`groupIds.${group.id}`}
                                                />
                                            }
                                            label={
                                                <UiText className={classes.groupText}>
                                                    {group.name}
                                                </UiText>
                                            }
                                            disabled={group.name === 'Everyone'}
                                            aria-label={`${group.name}-label`}
                                        />
                                    )}
                                />
                            ))}
                        </FormGroup>
                        {type === 'edit' && isServicesPageEnabled && (
                            <>
                                <UiText className={classes.editText}>
                                    {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_TWO' })}
                                </UiText>
                                <UiText>
                                    {translate(
                                        { id: 'CLIENT_INVITE_SUCCESS_TEXT_THREE_REVAMP' },
                                        { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                                    )}
                                </UiText>
                            </>
                        )}
                    </UiForm>
                )}
            </>
        </UiDrawer>
    );
};

export const AddEditRobotAccountComponentWithParams: React.FC = () => {
    const { type } = useParams<{ type: 'add' | 'edit' }>();

    return <AddEditRobotAccountComponent type={type ?? 'add'} />;
};

export default AddEditRobotAccountComponent;
