import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

export const replaceNullValuesWithUndefined = <T extends object | undefined | null>(obj: T): T => {
    if (obj == null) {
        return undefined as T;
    }
    return mapValues(obj, value => (value == null ? undefined : value)) as T;
};

export const removeUndefinedNullAndEmptyStringValues = <T extends object | undefined | null>(obj: T): T => {
    if (obj == null || isArray(obj)) {
        return obj as T;
    }
    return pickBy(obj, value => value != null && value !== '') as T;
};
