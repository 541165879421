import type { Apps } from '@experiences/interfaces';
import { get } from '@experiences/util';

export const appsUrl = '/apps_/default/api/v1/default/models/with-permissions?pageNumber=0';

const RENDERED_APPS_LIMIT = 5;

/**
 * sets the authentiation cookie for Apps
 * @param accessToken portal bearer token
 */
function getAppsAuthentication(accessToken: string) {
    const authUrl = `/apps_/default/api/v1/default/auth?provider=identity-server-auth` ;

    return get(authUrl, {
        headers: { 'x-id-token': accessToken },
        accessToken,
        stripPortalPath: true,
    });
}

export async function getApps(accessToken: string) {
    await getAppsAuthentication(accessToken);

    return get<Apps[]>(appsUrl, {
        headers: {}, // override default headers
        stripPortalPath: true,
        accessToken: '',
    }).then(data =>
        data.filter(app => app.latestPublishedVersion != null).slice(0, RENDERED_APPS_LIMIT)
    );
}
