import { useAuthContext } from '@experiences/util';
import {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
    useEcommerceEnabledCountries,
    useIsSignupDirectBuyFlow,
} from '../helpers/EcommerceHelpers';
import {
    accountLogicalName,
    profile,
} from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import type { ISubscriptionForm } from '../interfaces/ecommerce';
import { getFixedCurrencyIfPresent } from '../services/BillingService';

const useEcommerceCountryAndCurrencyViewModel = (disabled: boolean) => {
    const { filteredCountryCodeCountries: countryCodeCountries } = useEcommerceEnabledCountries();
    const {
        control,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const profileState = useSelector(profile);
    const {
        countryCode, countryChangeDialogClosed, allowedSelectedCurrencyOrDefault,
    } = useEcommerce();

    const defaultCountryCode = useMemo(() => {
        if (isSignupDirectBuyFlow) {
            return countryCode;
        }
        return profileState?.accountUserDto?.country;
    }, [
        profileState?.accountUserDto?.country,
        isSignupDirectBuyFlow,
        countryCode,
    ]);
    const { token } = useAuthContext();
    const { setValue } = useFormContext<ISubscriptionForm>();

    const countrySelectRef = useRef<any>();
    const currentAccountName = useSelector(accountLogicalName);
    const [ fixedCurrency, setFixedCurrency ] = useState(false);

    useLayoutEffect(() => {
        if (countryChangeDialogClosed && countrySelectRef?.current) {
            const inputElement = countrySelectRef.current.querySelector('input');
            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [ countryChangeDialogClosed, countrySelectRef, disabled ]);

    useEffect(() => {
        (async () => {
            if (countryCode && countryCode !== '' && token) {
                if (isSignupDirectBuyFlow) {
                    setFixedCurrency(false);
                    setValue('currency', allowedSelectedCurrencyOrDefault());
                } else {
                    const currency = await getFixedCurrencyIfPresent(
                        countryCode,
                        currentAccountName,
                        token,
                    );
                    if (currency) {
                        setFixedCurrency(true);
                        setValue('currency', currency);
                    }
                }
            }
        })();
    }, [
        countryCode,
        currentAccountName,
        setValue,
        token,
        setFixedCurrency,
        isSignupDirectBuyFlow,
        allowedSelectedCurrencyOrDefault,
    ]);

    return {
        control,
        errors,
        countryCodeCountries,
        defaultCountryCode,
        countrySelectRef,
        fixedCurrency,
    };
};

export default useEcommerceCountryAndCurrencyViewModel;
