import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { secretPlaceholder } from '../../../common/constants/SecretConstant';
import type { IEditClientIdAndSecretData } from '../../../common/interfaces/externalIdentity';

const useStyles = makeStyles(theme =>
    createStyles({
        section: { marginTop: 20 },
        cancelButton: { marginRight: '10px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
    }),
);

export const defaultEditClientIdAndSecretData: IEditClientIdAndSecretData = {
    clientId: '',
    clientSecret: '',
};

const EditClientIdAndSecretFormComponent: FC<{
    showClientSecretPlaceholder: boolean;
    clientSecretRequired?: boolean;
}> = ({
    showClientSecretPlaceholder = false, clientSecretRequired = false,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext<IEditClientIdAndSecretData>();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return (
        <>
            <div className={classes.section}>
                <Controller
                    name="clientId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={translate({ id: 'CLIENT_CLIENT_ID' })}
                            error={!!errors.clientId}
                            helperText={
                                errors.clientId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            }
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            InputLabelProps={{ id: 'clientIdLabel' }}
                            inputProps={{ 'aria-labelledby': 'clientIdLabel' }}
                            data-cy="edit-client-id-and-secret-client-id"
                        />
                    )}
                />
            </div>
            <div className={classes.section}>
                <Controller
                    name="clientSecret"
                    control={control}
                    rules={{ required: clientSecretRequired && !showClientSecretPlaceholder }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={translate({ id: 'CLIENT_CLIENT_SECRET' })}
                            error={!!errors.clientSecret}
                            helperText={
                                errors.clientSecret?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            }
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            placeholder={showClientSecretPlaceholder ? secretPlaceholder : undefined}
                            InputLabelProps={{ id: 'clientSecretLabel' }}
                            inputProps={{ 'aria-labelledby': 'clientSecretLabel' }}
                            data-cy="edit-client-id-and-secret-client-secret"
                        />
                    )}
                />
            </div>
        </>
    );
};

export default EditClientIdAndSecretFormComponent;
