import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    AuthSettingPasswordKey,
    AuthSettingUserLockOutKey,
} from '../../../common/constants/AuthSettingConstant';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { ISecurityAuthenticationSettingsData } from '../../../common/interfaces/authSetting';
import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultSecurityAuthenticationSettingsData,
    mapSecurityAuthSettingDataToKeyValuePairs,
    mapSettingArrayToSecurityAuthSettingsData,
} from '../../../util/setting/AuthSettingUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import PasswordPolicyForm from './PasswordPolicyForm';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
    }),
);

const EditPasswordPolicyComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const createNotification = useUiSnackBar();

    const partitionGlobalId = useSelector(accountGlobalId);

    const methods = useForm<ISecurityAuthenticationSettingsData>({
        mode: 'onChange',
        defaultValues: defaultSecurityAuthenticationSettingsData,
    });
    const {
        handleSubmit, reset, formState: {
            errors, isDirty,
        },
    } = methods;

    const keys = useMemo(
        () => [
            AuthSettingPasswordKey.DefaultExpirationDays,
            AuthSettingPasswordKey.PasswordComplexity,
            AuthSettingPasswordKey.PreviousUseLimit,
            AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
            AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
            AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
            AuthSettingUserLockOutKey.IsEnabled,
        ],
        [],
    );
    const {
        data: fetchedSettings, mutate,
    } = useSWR(
        {
            url: settingUrl,
            key: keys,
            partitionGlobalId,
        },
        getSetting,
        { shouldRetryOnError: false },
    );

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToSecurityAuthSettingsData(fetchedSettings));
        }
    }, [ reset, fetchedSettings ]);

    const {
        open, close,
    } = useModalState(RouteNames.SecuritySettings);

    const onSubmit = useCallback(
        async (data: ISecurityAuthenticationSettingsData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapSecurityAuthSettingDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }), notificationType.SUCCESS);
                close();
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ close, createNotification, mutate, partitionGlobalId, translate ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_EDIT_PASSWORD_POLICY' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="medium"
            loading={!fetchedSettings}
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            onClick={() => close()}
                            color="primary"
                            data-cy="password-policy-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            // className={classes.saveButton}
                            disabled={!!Object.keys(errors).length || !isDirty}
                            type="submit"
                            variant="contained"
                            loading={false}
                            data-cy="password-policy-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
                addScrollPadding
            >
                <FormProvider {...methods}>
                    <PasswordPolicyForm />
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default EditPasswordPolicyComponent;
