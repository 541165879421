import { SqsConnectionsString } from '@experiences/constants';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { AwsSqsConnDetails } from './ConnectionDetailsType';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

export const AwsSqsInputs: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();
    const {
        control,
        formState: { errors },
    } = useFormContext<AwsSqsConnDetails>();

    return (
        <>
            <div className={classes.input}>
                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_ACCESS_KEY_ID' })}
                </UiText>
                <Controller
                    control={control}
                    rules={{ required: true }}
                    name="awsAccessKeyId"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            type="text"
                            data-cy="logs-connection-awsAccessKeyId"
                            variant="outlined"
                            error={!!errors.awsAccessKeyId}
                            fullWidth
                            InputProps={{
                                className: 'Tall',
                                inputProps: { 'aria-label': translate({ id: 'CLIENT_AWS_ACCESS_KEY_ID' }) },
                            }}
                        />
                    )}
                />
            </div>

            <div className={classes.input}>
                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_SECRET_ACCESS_KEY' })}
                </UiText>
                <Controller
                    control={control}
                    rules={{ required: true }}
                    name="awsSecretAccessKey"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            type="password"
                            data-cy="logs-connection-awsSecretAccessKey"
                            variant="outlined"
                            error={!!errors.awsSecretAccessKey}
                            fullWidth
                            InputProps={{
                                className: 'Tall',
                                inputProps: { 'aria-label': translate({ id: 'CLIENT_AWS_SECRET_ACCESS_KEY' }) },
                            }}
                        />
                    )}
                />
                <FormHelperText className={errors.awsSecretAccessKey?.type === 'pattern' ? classes.breakAll : ''}>
                    {errors.awsSecretAccessKey?.type === 'required'
                        ? translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        : translate(
                            { id: 'CLIENT_INSIGHTS_AWS_CONNECTION_INFO' },
                            {
                                awsDocumentationLink: (
                                    <Link
                                        href={getLocalizedLink(SqsConnectionsString)}
                                        rel="noreferrer"
                                        target="_blank"
                                        underline='none'
                                    >
                                        {translate({ id: 'CLIENT_INSIGHTS_AWS_DOCUMENTATION' })}
                                    </Link>
                                ),
                            },
                        )}
                </FormHelperText>
            </div>

            <div className={classes.input}>
                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_SQS_QUEUE_URL' })}
                </UiText>
                <Controller
                    control={control}
                    rules={{ required: true }}
                    name="queueUrl"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            type="text"
                            data-cy="logs-connection-queueUrl"
                            variant="outlined"
                            error={!!errors.queueUrl}
                            fullWidth
                            InputProps={{
                                className: 'Tall',
                                inputProps: { 'aria-label': translate({ id: 'CLIENT_AWS_SQS_QUEUE_URL' }) },
                            }}
                        />
                    )}
                />
            </div>

            <div className={classes.input}>
                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_SQS_REGION' })}
                </UiText>
                <Controller
                    control={control}
                    rules={{ required: true }}
                    name="region"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            type="text"
                            data-cy="logs-connection-region"
                            variant="outlined"
                            error={!!errors.region}
                            fullWidth
                            InputProps={{
                                className: 'Tall',
                                inputProps: { 'aria-label': translate({ id: 'CLIENT_AWS_SQS_REGION' }) },
                            }}
                        />
                    )}
                />
            </div>
        </>
    );
};
