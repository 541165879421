import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import {
    UiCheckBoxLabel,
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    UiStorage,
    useAuthContext,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { EcommerceElementsWrapperComponent } from '../EcommerceElementsWrapperComponent';
import {
    ANNUAL_PLAN_TYPE,
    DIRECT_BUY_DATA,
    DIRECT_BUY_ORDER_CONFIRMATION,
    ECOMMERCE_PAYMENT_FAILED_POPUP_KEY,
    JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH,
    MONTHLY_PLAN_TYPE,
    useIsSignupDirectBuyFlow,
}
    from '../helpers/EcommerceHelpers';
import {
    BuyProConfirmation,
    DirectBuyConfirmation,
} from '../helpers/EcommerceRoutes';
import { accountLogicalName } from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import type {
    IBusinessInfoPayload,
    IEcommerceCheckoutFormState,
    IProductPriceOptions,
    ISubscriptionForm,
} from '../interfaces/ecommerce';
import {
    billingSubscriptionUri,
    getDirectBuyOrderConfirmationDetails,
    getOrderConfirmationDetails,
} from '../services/BillingService';
import { EcommerceCheckoutOrderSummary } from '../subcomponents/EcommerceCheckoutOrderSummary';
import EcommerceCheckoutBillingAddress from './EcommerceCheckoutBillingAddress';
import {
    changeCountryHandlerCallback,
    sendDataToMarketo,
} from './EcommerceCheckoutCallbacks';
import EcommerceContinueToPaymentButton from './EcommerceContinueToPaymentButton';
import EcommerceEuLegalText from './EcommerceEuLegalText';
import EcommercePaymentElementForm from './EcommercePaymentElementForm';
import { EcommercePlanSummary } from './EcommercePlanSummary';

const numberOfRetries = 24;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '528px',
        },
        summaryContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '387px',
        },
        infoText: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '18px',
        },
        legalFormControl: {
            alignItems: 'unset',
            marginRight: '6px',
        },
        checkbox: {
            height: '42px',
            marginTop: '13px',
        },
        checkboxLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '19px',
        },
        payNowButton: { marginTop: '40px' },
        japanPurchaseLegalText: {
            fontWeight: 400,
            lineHeight: '16px',
            fontSize: '12px',
            margin: '16px 0px -16px 0px',
        },
        content: {
            justifyContent: 'space-between',
            height: '100%',
            margin: '32px 0px',
            maxWidth: '1600px',
            display: 'flex',
            alignSelf: 'center',
            width: '100%',
            paddingLeft: '50px',
            paddingRight: '50px',
            minWidth: '481px',
            columnGap: '50px',
        },
    }),
}));

const EcommerceCheckoutForm: React.FC<{
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    paymentElementFormCompleted: boolean;
    setPaymentElementFormCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    termsAndConditionsAccepted: boolean;
    setTermsAndConditionsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
    existingMarketoData: IBusinessInfoPayload | undefined;
    productsPricesInAllCurrencies: IProductPriceOptions[] | undefined;
    checkoutFormRef: any;
    setCheckoutFormRef: any;
}> = ({
    setLoading,
    loading,
    paymentElementFormCompleted,
    setPaymentElementFormCompleted,
    termsAndConditionsAccepted,
    setTermsAndConditionsAccepted,
    existingMarketoData,
    productsPricesInAllCurrencies,
    checkoutFormRef,
    setCheckoutFormRef,
}) => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const { formatMessage: translate } = useIntl();
    const currentAccountNameFromSelector = useSelector(accountLogicalName);
    const EnableEcommercePlanSelectionRevampM0 = getFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);

    const [ waitingForUser, setWaitingForUser ] = useState(false);
    const createDialog = useShowDialog();

    const currentAccountName = useMemo(
        () => (isSignupDirectBuyFlow ? undefined : currentAccountNameFromSelector),
        [ isSignupDirectBuyFlow, currentAccountNameFromSelector ],
    );

    const {
        currentSkuPackageDetails,
        isTaxLoading: taxLoading,
        arePricesLoading: packageLoading,
        countryCode,
        setEcommerceCountry,
        setEcommerceCountryChangeDialogClosed,
        stripeSubscriptionId,
        organizationId,
        paymentIntentClientSecret,
    } = useEcommerce();

    const paymentElementsAreVisible = useMemo(
        () => !!paymentIntentClientSecret,
        [ paymentIntentClientSecret ]);

    const { token } = useAuthContext();

    const {
        watch, setValue, setError, clearErrors, getValues, register, formState: {
            errors, isValid,
        },
    } = useFormContext<ISubscriptionForm>();

    const showFailedPaymentPopup = useMemo(() => UiStorage.getItem(ECOMMERCE_PAYMENT_FAILED_POPUP_KEY), []);
    const currentCurrency = watch('currency');
    const currentAccountLanguage = useLocalization();

    const countryChangeHandler = useCallback((currentCountry: string) => changeCountryHandlerCallback(false,
        currentCountry,
        countryCode,
        setWaitingForUser,
        createDialog,
        translate,
        watch,
        setValue,
        setEcommerceCountry,
        setEcommerceCountryChangeDialogClosed,
        navigate,
        location,
        clearErrors),
    [
        countryCode,
        createDialog,
        translate,
        watch,
        setValue,
        setEcommerceCountry,
        setEcommerceCountryChangeDialogClosed,
        navigate,
        location,
        clearErrors,
    ],
    );
    const handlePaymentFailed = useCallback(async () => {
        UiStorage.removeItem(ECOMMERCE_PAYMENT_FAILED_POPUP_KEY);
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
            body: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY' }),
            icon: 'warning',
            showCancel: false,
            primaryButtonText: translate({ id: 'CLIENT_OK' }),
        });
        if (proceed) {
            setLoading(false);
        }
    }, [ createDialog, setLoading, translate ]);

    useEffect(() => {
        if (currentSkuPackageDetails.defaultCurrency) {
            setValue('currency', currentSkuPackageDetails.defaultCurrency);
        }
    }, [ currentSkuPackageDetails.defaultCurrency, setValue ]);

    useEffect(() => {
        window.addEventListener('load', async () => {
            if (existingMarketoData) {
                const data = getValues();
                await sendDataToMarketo(data, existingMarketoData, currentAccountLanguage,
                    token, currentAccountName, isSignupDirectBuyFlow);
            }
        });
    }, [ existingMarketoData, token, currentAccountName, currentAccountLanguage, getValues, isSignupDirectBuyFlow ]);

    useEffect(() => {
        if (countryCode === 'US') {
            if (currentSkuPackageDetails.taxError?.status === 400 || currentSkuPackageDetails.tax?.isValid === false) {
                setError('zipcode', {
                    type: 'taxError',
                    message: translate({ id: 'CLIENT_BAD_ZIP_CODE' }),
                });
            } else if (currentSkuPackageDetails.taxError?.status && currentSkuPackageDetails.taxError.status >= 500) {
                setError('zipcode', {
                    type: 'avalaraError',
                    message: translate({ id: 'CLIENT_UNABLE_TO_CALCULATE_TAX_AVALARA_IS_DOWN' }),
                });
            } else {
                clearErrors('zipcode');
            }
        } else {
            if (currentSkuPackageDetails.taxError?.status && currentSkuPackageDetails.taxError.status >= 500) {
                (async () => {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TAX_CALCULATION_ERROR_TITLE' }),
                        body: translate({ id: 'CLIENT_TAX_CALCULATION_ERROR_BODY' }),
                        primaryButtonText: translate({ id: 'CLIENT_REFRESH' }),
                        icon: 'warning',
                        showCancel: false,
                    });
                    if (proceed) {
                        window.location.reload();
                    }
                })();
            }
        }
    }, [
        setError,
        translate,
        currentSkuPackageDetails.taxError,
        currentSkuPackageDetails.tax,
        countryCode,
        createDialog,
        clearErrors,
    ]);

    const retriesRef = useRef(0);

    const {
        data: orderConfirmation, isValidating, mutate,
    } = useSWR(
        stripeSubscriptionId ? {
            accountName: currentAccountName,
            stripeSubscriptionId,
            url: billingSubscriptionUri,
            countryCode,
            accessToken: token,
        } : null,
        ({
            url, accountName, stripeSubscriptionId: id, countryCode: code, accessToken,
        }) => {
            if (stripeSubscriptionId && organizationId && isSignupDirectBuyFlow) {
                return getDirectBuyOrderConfirmationDetails({
                    organizationId,
                    countryCode: code,
                    stripeSubscriptionId: id,
                    accessToken,
                    url,
                });
            }
            if (stripeSubscriptionId && currentAccountName && organizationId && !isSignupDirectBuyFlow) {
                return getOrderConfirmationDetails({
                    url,
                    stripeSubscriptionId: id,
                    accountName: accountName ?? '',
                    accessToken,
                });
            }

            return undefined;
        },
    );
    useEffect(() => {
        if (showFailedPaymentPopup) {
            handlePaymentFailed();
        }
    }, [ handlePaymentFailed, showFailedPaymentPopup ]);
    useEffect(() => {
        if (isSignupDirectBuyFlow && orderConfirmation?.status === 'PAYMENT_PROCESSED') {
            UiStorage.setItem(DIRECT_BUY_ORDER_CONFIRMATION, JSON.stringify(orderConfirmation));
            navigate(DirectBuyConfirmation);
        }
    }, [ isSignupDirectBuyFlow, stripeSubscriptionId, orderConfirmation, navigate ]);

    useEffect(() => {
        if (stripeSubscriptionId && orderConfirmation) {
            if (
                !isValidating &&
                orderConfirmation.status === 'PAYMENT_INITIALIZED' &&
                retriesRef.current < numberOfRetries
            ) {
                setTimeout(() => {
                    retriesRef.current = retriesRef.current + 1;
                    mutate();
                }, 5000);
            } else if (orderConfirmation.status === 'PAYMENT_PROCESSED' || orderConfirmation.status === 'PAYMENT_OPEN') {
                setLoading(false);
                if (isSignupDirectBuyFlow) {
                    orderConfirmation.organizationId = organizationId;
                    UiStorage.setItem(DIRECT_BUY_ORDER_CONFIRMATION, JSON.stringify(orderConfirmation));
                    UiStorage.removeItem(DIRECT_BUY_DATA);
                    navigate(DirectBuyConfirmation);
                } else {
                    UiStorage.removeItem(DIRECT_BUY_DATA);
                    navigate(getRoute(BuyProConfirmation), { state: { orderConfirmation } });
                }
            } else if (orderConfirmation.status === 'PAYMENT_FAILED' || retriesRef.current >= numberOfRetries) {
                handlePaymentFailed();
            }
        }
    }, [
        orderConfirmation,
        isValidating,
        retriesRef,
        stripeSubscriptionId,
        currentAccountName,
        organizationId,
        getRoute,
        createDialog,
        translate,
        isSignupDirectBuyFlow,
        setLoading,
        handlePaymentFailed,
        mutate,
        navigate,
    ]);

    const submitCheckoutFormRef = useCallback(() => {
        checkoutFormRef.dispatchEvent(
            new Event('submit', {
                bubbles: true,
                cancelable: true,
            })
        );
    }, [ checkoutFormRef ]);

    const annualSavings = useMemo(() => {
        const annualizedMonthlyPrice = currentSkuPackageDetails.prices[MONTHLY_PLAN_TYPE]?.[currentCurrency] * 12;
        const annualPrice = currentSkuPackageDetails.prices[ANNUAL_PLAN_TYPE]?.[currentCurrency];
        return currentSkuPackageDetails.planType === ANNUAL_PLAN_TYPE ? annualizedMonthlyPrice - annualPrice : undefined;
    }, [ currentCurrency, currentSkuPackageDetails.planType, currentSkuPackageDetails.prices ]);

    return (
        <div
            className={classes.content}
            role="main"
        >
            <div className={classes.formContainer}>
                <form
                    id="ecommerce-checkout-form"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    ref={ref => setCheckoutFormRef(ref)}
                >
                    <EcommerceCheckoutBillingAddress
                        currencies={Object.keys(currentSkuPackageDetails.prices[currentSkuPackageDetails.planType] ?? {})}
                        disabled={loading || !!packageLoading || waitingForUser || paymentElementsAreVisible}
                        countryChangeHandler={countryChangeHandler}
                    />

                    {!paymentIntentClientSecret && (
                        <EcommerceContinueToPaymentButton
                            setLoading={setLoading}
                            loading={loading}
                            packageLoading={!!packageLoading}
                            hasErrors={Object.keys(errors).length > 0}
                        />
                    )}
                </form>
                {paymentIntentClientSecret && (
                    <EcommerceElementsWrapperComponent
                        paymentIntentClientSecret={paymentIntentClientSecret}
                    >
                        <EcommercePaymentElementForm
                            existingMarketoData={existingMarketoData}
                            setLoading={setLoading}
                            setPaymentElementFormCompleted={setPaymentElementFormCompleted}
                            setTermsAndConditionsAccepted={setTermsAndConditionsAccepted}
                            termsAndConditionsAccepted={termsAndConditionsAccepted}
                            setCheckoutFormRef={setCheckoutFormRef}
                        />
                    </EcommerceElementsWrapperComponent>
                )}
            </div>
            <div className={classes.summaryContainer}>
                {currentSkuPackageDetails && (EnableEcommercePlanSelectionRevampM0 ? (
                    <EcommercePlanSummary
                        {...currentSkuPackageDetails}
                        isDirectBuyPagesSource={false}
                        disablePlanTypeSelection={paymentElementsAreVisible}
                        taxLoading={taxLoading}
                        currency={currentCurrency}
                        price={currentSkuPackageDetails.prices[currentSkuPackageDetails.planType]?.[currentCurrency]}
                        productsPricesInAllCurrencies={productsPricesInAllCurrencies}
                        productPricesLoading={packageLoading ?? !currentSkuPackageDetails.prices[currentCurrency]}
                        isFirstSubscription
                        annualSavings={annualSavings}
                        checkoutFormState={{
                            loading,
                            paymentElementFormCompleted,
                            termsAndConditionsAccepted,
                            checkoutFormRef,
                        } as IEcommerceCheckoutFormState}
                        isFormValid={isValid}
                    />
                ) : (
                    <EcommerceCheckoutOrderSummary
                        {...currentSkuPackageDetails}
                        disablePlanTypeSelection={paymentElementsAreVisible}
                        taxLoading={taxLoading}
                        currency={currentCurrency}
                        price={currentSkuPackageDetails.prices[currentSkuPackageDetails.planType]?.[currentCurrency]}
                        productPricesLoading={packageLoading ?? !currentSkuPackageDetails.prices[currentCurrency]}
                        isFirstSubscription
                        annualSavings={annualSavings}
                    />
                ))}

                {countryCode === 'JP' && (
                    <Link
                        className={classes.japanPurchaseLegalText}
                        href={JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH}
                        data-cy="japan-purchase-legal-text"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {translate({ id: 'CLIENT_JP_COMMERCIAL_TRANSACTION_ACT' })}
                    </Link>
                )}

                { !EnableEcommercePlanSelectionRevampM0 &&
                    <UiText
                        className={classes.infoText}
                        data-cy="pay-now-info">
                        { translate({ id: `CLIENT_PAY_NOW_INFO_${currentSkuPackageDetails.planType}` }) }
                    </UiText>}

                <FormControlLabel
                    control={
                        <Checkbox
                            {...register('termsAndConditionsAccepted', { required: false })}
                            color="primary"
                            data-cy="complete-invite-terms-conditions"
                            className={classes.checkbox}
                        />
                    }
                    label={
                        countryCode === 'JP' ? (
                            <UiCheckBoxLabel
                                className={classes.checkboxLabel}
                                label="CLIENT_ECOMMERCE_TERMS_JAPAN"
                                href={[
                                    'https://www.uipath.com/assets/downloads/online-purchase-terms',
                                    'https://www.uipath.com/ja/legal/privacy-policy',
                                ]}
                                hrefText={[ 'CLIENT_TERMS_OF_USE_FOR_ONLINE_SOFTWARE', 'CLIENT_UIPATH_PRIVACY_POLICY' ]}
                                dataCy="japan-ecommerce-terms-label"
                            />
                        ) : (
                            <UiCheckBoxLabel
                                className={classes.checkboxLabel}
                                label="CLIENT_ECOMMERCE_TERMS"
                                href={[ 'https://www.uipath.com/assets/downloads/online-purchase-terms' ]}
                                hrefText={[ 'CLIENT_TERMS_OF_USE_FOR_ONLINE_SOFTWARE' ]}
                                dataCy="ecommerce-terms-label"
                            />
                        )
                    }
                    className={classes.legalFormControl}
                    disabled={loading || !!packageLoading}
                    data-cy="ecommerce-termsAndConditions"
                />

                { EnableEcommercePlanSelectionRevampM0 && (
                    <UiText
                        className={classes.infoText}
                        data-cy="pay-now-info">
                        {translate({ id: `CLIENT_PAY_NOW_INFO_${currentSkuPackageDetails.planType}` })}
                    </UiText>)}

                {isSignupDirectBuyFlow && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...register('marketingConditionsAccepted')}
                                className={classes.checkbox}
                                data-cy="ecommerce-marketing-consent"
                            />
                        }
                        label={
                            <UiCheckBoxLabel
                                className={classes.checkboxLabel}
                                label="CLIENT_MARKETING_CONDITION"
                                href={[ 'https://www.uipath.com/legal/privacy-policy' ]}
                                hrefText={[ 'CLIENT_SOCIAL_PRIVACY_POLICY' ]}
                            />
                        }
                        className={classes.legalFormControl}
                        disabled={loading || !!packageLoading}
                    />
                )}
                {!EnableEcommercePlanSelectionRevampM0 && <UiProgressButton
                    className={classes.payNowButton}
                    style={{ width: '100%' }}
                    loading={loading || !!packageLoading}
                    disabled={
                        !isValid ||
                        (!paymentElementFormCompleted || !termsAndConditionsAccepted)
                    }
                    onClick={submitCheckoutFormRef}
                    variant="contained"
                    data-cy="ecommerce-pay-button"
                >
                    {translate({ id: 'CLIENT_PAY_NOW' })}
                </UiProgressButton>}
                {!EnableEcommercePlanSelectionRevampM0 && <EcommerceEuLegalText />}
            </div>
        </div>
    );
};

export default EcommerceCheckoutForm;
