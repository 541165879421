import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiStorage,
    useNavigateWithParams,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import { useLoginProvider } from '../../../common/hooks/useLoginProvider';
import { useStorageListener } from '../../../common/hooks/useStorageListener';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { ICertMetaData } from '../../../common/interfaces/externalIdentity';
import type { IAuthenticationSettingPayload } from '../../../services/identity/AuthenticationSettingService';
import {
    AuthenticationSettingType,
    authenticationSettingUrl,
    createDirectoryIntegrationSetting,
    updateDirectoryIntegrationSetting,
} from '../../../services/identity/AuthenticationSettingService';
import type { ITestConnectionResult } from '../../../services/identity/TestConnectionService';
import { testConnection } from '../../../services/identity/TestConnectionService';
import {
    createUser,
    inviteUsers,
} from '../../../services/identity/UserService';
import {
    accountGlobalId,
    companyName,
    emailSelector,
    organizationLanguage,
} from '../../../store/selectors';
import { getNameFromEmail } from '../../../util/EmailUtil';
import {
    mapDirectoryUserDto,
    mapInviteUserDto,
} from '../../../util/UserGroupUtil';
import { UserGroup } from '../../common/UserGroups';

interface IAzureAADFormData {
    tenantID?: string;
    applicationID?: string;
    applicationSecret?: string;
    applicationCertificate?: any;
}

const DEFAULT_FORM: IAzureAADFormData = {
    tenantID: '',
    applicationID: '',
    applicationSecret: '',
    applicationCertificate: '',
};

type TestConnectionStatus = 'undefined' | 'clicked' | 'success' | 'error' | 'loading' | 'retry' | 'unknown';

const useExternalProvidersAADFormViewModel = (
    showButtonFooter: boolean,
    onError: (error: any) => Promise<void> | void,
    onClose?: () => void,
) => {
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const createNotification = useUiSnackBar();

    const loginProvider = useLoginProvider();
    const { getErrorMessage } = useGetErrorInfo();

    const currentEmail = useSelector(emailSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgLanguage = useSelector(organizationLanguage);
    const orgName = useSelector(companyName);

    const EnableEnforceTestConnectionforAAD = useFeatureFlagValue(Features.EnableEnforceTestConnectionforAAD.name);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableAADTestConnectionBrowserPost = useFeatureFlagValue(Features.EnableAADTestConnectionBrowserPost.name);

    const [ testConnectionState, setTestConnectionState ] = useState<TestConnectionStatus>(
        process.buildConfigs.disableTestConnection ? 'success' : 'undefined',
    );
    const [ testConnectionError, setTestConnectionError ] = useState('');
    const [ emailLinkingChecked, setEmailLinkingChecked ] = useState(false);
    const [ retryCount, setRetryCount ] = useState(() => 0);
    const [ certMetadata, setCertMetadata ] = useState<ICertMetaData>();

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();

    const { mutate } = useSWRConfig();

    useEffect(() => {
        setEmailLinkingChecked(!!bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]);
        try {
            if (typeof bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]?.externalIdentityProviderDto?.certMetaData === 'string') {
                setCertMetadata(
                    JSON.parse(bulkAuthenticationSetting[AuthenticationSettingType.AAD].externalIdentityProviderDto.certMetaData as string)
                );
            }
        } catch (error) {
            console.error(error);
        }
    }, [ bulkAuthenticationSetting ]);

    const methods = useForm<IAzureAADFormData>({
        mode: 'onChange',
        defaultValues: DEFAULT_FORM,
    });

    const {
        handleSubmit,
        formState: {
            isValid, isDirty,
        },
        reset,
        trigger,
        watch,
    } = methods;

    const [ tenantID, applicationID, applicationSecret ] = watch([ 'tenantID', 'applicationID', 'applicationSecret' ]);

    useEffect(() => {
        const authority = bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]?.externalIdentityProviderDto?.authority;
        if (
            bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]
            && authority
        ) {
            const matchedValues = authority.match(/.com\/(.*)\/v/);
            reset({
                tenantID: (matchedValues && matchedValues.length > 1 && matchedValues[1]) || '',
                applicationID: bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]?.externalIdentityProviderDto.clientId,
                applicationSecret: bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]?.externalIdentityProviderDto.clientSecret,
            });
        }
    }, [ bulkAuthenticationSetting, reset ]);

    const onSubmit = useCallback(
        async (data: IAzureAADFormData) => {
            const payload: IAuthenticationSettingPayload = {
                type: AuthenticationSettingType.AAD,
                displayName: AuthenticationSettingType.AAD,
                partitionGlobalId,
                clientId: data.applicationID?.trim(),
                clientSecret: data.applicationSecret?.trim(),
                tenantId: data.tenantID?.trim(),
            };

            try {
                if (bulkAuthenticationSetting?.[AuthenticationSettingType.AAD]) {
                    await updateDirectoryIntegrationSetting(payload);
                } else {
                    await createDirectoryIntegrationSetting(payload);
                }
                createNotification(
                    translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_SUCCESSFULLY_CHANGED' }),
                    notificationType.SUCCESS,
                );
                mutate({
                    url: authenticationSettingUrl,
                    partitionGlobalId,
                });
                onClose?.();
                navigate(RouteNames.SecuritySettings);
            } catch (error) {
                await onError(error);
            }
        },
        [
            partitionGlobalId,
            bulkAuthenticationSetting,
            createNotification,
            translate,
            mutate,
            onClose,
            navigate,
            onError,
        ],
    );

    const adminUserSeeding = useCallback(async (userShouldBeAdded: boolean, emailAddress: string) => {
        const userAction = !DisableFeatureFedRamp && process.buildConfigs.inviteUserOnTestConnection
            ? 'invite' : 'create';

        try {
            if (!DisableFeatureFedRamp && process.buildConfigs.inviteUserOnTestConnection) {
                await inviteUsers(
                    mapInviteUserDto(
                        {
                            userName: getNameFromEmail(emailAddress),
                            email: [ emailAddress ],
                            groupIds: { [UserGroup.Administrators]: true },
                        },
                    ),
                    loginProvider,
                    orgLanguage,
                    orgName,
                );
            } else {
                await createUser(
                    mapDirectoryUserDto(
                        {
                            email: emailAddress,
                            groupIds: { [UserGroup.Administrators]: true },
                        },
                        partitionGlobalId,
                    ),
                );
            }

            setTestConnectionState('success');
            await trigger(); // Forces the form to revalidate (so that the submit button becomes enabled)

            // Used for the new security settings page, when the Test Connection button and Save button are one
            if (showButtonFooter && EnableEnforceTestConnectionforAAD) {
                await onSubmit(watch());
            }

            createNotification(
                translate({ id: userShouldBeAdded ? 'CLIENT_TEST_CONNECTION_ADD_USER_SUCCESS' : 'CLIENT_TEST_CONNECTION_SUCCESS' }),
                notificationType.SUCCESS
            );
        } catch (error) {
            const inviteError = await getErrorMessage(error);
            if (inviteError === 'DuplicateEmail') {
                setTestConnectionState('success');
                return;
            }

            setTestConnectionState('error');
            setTestConnectionError(
                inviteError
                ?? translate(
                    { id: 'CLIENT_AAD_INVITE_FAILURE' },
                    {
                        userAction,
                        emailAddress,
                    }),
            );
        }
    }, [
        DisableFeatureFedRamp,
        EnableEnforceTestConnectionforAAD,
        createNotification,
        getErrorMessage,
        loginProvider,
        onSubmit,
        orgLanguage,
        orgName,
        partitionGlobalId,
        showButtonFooter,
        translate,
        trigger,
        watch,
    ]);

    const updateTestConnectionState = useCallback(async (code: string) => {
        let response: ITestConnectionResult | undefined = undefined;

        if (!code) {
            return;
        }

        try {
            setTestConnectionState('loading');
            response = await testConnection({
                client_id: applicationID?.trim() ?? '',
                code,
                redirect_uri: encodeURI(`${window.location.origin}/portal_/testconnection`),
                client_secret: applicationSecret?.trim() ?? '',
                tenant_id: tenantID?.trim() ?? '',
            });

            if (!response) {
                throw new Error();
            }
        } catch (error) {
            const errorDescription = (error as any)?.response?.data?.data?.error_description
                || translate({ id: 'CLIENT_TEST_CONNECTION_ERROR_TOKEN' });

            if (process.buildConfigs.enableTestConnectionBypass && (error as any).response?.status > 499) {
                setTestConnectionState('unknown');
                setTestConnectionError(translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG_ELLIPSES' }));
                return;
            }

            setTestConnectionState('error');
            setTestConnectionError(errorDescription);
            return;
        }

        const emailAddress = response?.email?.toLowerCase();
        const userShouldBeAdded = emailAddress != null && emailAddress !== currentEmail?.toLowerCase();

        if (!userShouldBeAdded) {
            setTestConnectionState('success');
            return;
        }

        await adminUserSeeding(userShouldBeAdded, emailAddress);
    }, [ currentEmail, adminUserSeeding, translate, applicationID, applicationSecret, tenantID ]);

    const handleTestConnectionBypass = useCallback(() => {
        setTestConnectionError('');
        setTestConnectionState('success');
    }, []);

    const legacyUpdateTestConnectionState = useCallback(async () => {
        if (EnableAADTestConnectionBrowserPost) {
            return;
        }

        // Limit the number of requests to 1.
        if (testConnectionState !== 'clicked' && testConnectionState !== 'retry') {
            return;
        }

        const code = UiStorage.getItem('AAD_code') ?? '';

        // Not combined with above if to prevent showing error messages when the request is pending.
        if (!code) {
            const errorIfOverRetry = retryCount >= 5;
            setTestConnectionState(errorIfOverRetry ? 'error' : 'retry');
            if (errorIfOverRetry) {
                setRetryCount(0);
            } else {
                setRetryCount(count => count + 1);
            }
            setTestConnectionError(translate({ id: 'CLIENT_TEST_CONNECTION_ERROR_TOKEN' }));
            return;
        }

        updateTestConnectionState(code);
    }, [ EnableAADTestConnectionBrowserPost, retryCount, testConnectionState, translate, updateTestConnectionState ]);

    useStorageListener(legacyUpdateTestConnectionState);

    const cleanupLocalStorage = useCallback(() => {
        UiStorage.removeItem('AAD_code');
    }, []);

    useEffect(() => () => cleanupLocalStorage(), [ cleanupLocalStorage ]);

    useEffect(() => {
        const callback = (event: any) => {
            if (event.origin !== window.origin || typeof event.data?.AAD_code !== 'string') {
                return;
            }

            updateTestConnectionState(event.data.AAD_code);
        };
        window.addEventListener('message', callback);

        return () => window.removeEventListener('message', callback);
    }, [ updateTestConnectionState ]);

    const disableSubmit = useMemo(() =>
        !emailLinkingChecked
        || !isDirty
        || !isValid
        || (EnableEnforceTestConnectionforAAD && testConnectionState !== 'success'),
    [ EnableEnforceTestConnectionforAAD, emailLinkingChecked, isDirty, isValid, testConnectionState ]);

    const disableTestConnection = useMemo(() =>
        !tenantID
        || !applicationID
        || !applicationSecret
        || applicationSecret?.includes('*****'),
    [ applicationID, applicationSecret, tenantID ]);

    const loginWithAAD = useMemo(() => {
        const returnUrl = encodeURI(`${window.location.origin}/portal_/testconnection`);

        const params = {
            prompt: 'select_account',
            response_type: 'code',
            redirect_uri: returnUrl,
            state: 'h0QLkxNM6fMx4sfY8LYOFYSdaXtoab0v',
            client_id: applicationID?.trim() ?? '',
        };

        const search = new URLSearchParams(params);

        const scopes = [ 'email', 'GroupMember.Read.All', 'openid', 'profile', 'User.Read', 'User.ReadBasic.All' ];

        return `https://login.microsoftonline.com/${tenantID?.trim()}/oauth2/v2.0/authorize?scope=${scopes.join(
            '+',
        )}+&${search.toString()}`;
    }, [ applicationID, tenantID ]);

    const cancel = () => {
        navigate(RouteNames.SecuritySettings);
    };

    const handleAADAuthPopup = () => {
        setTestConnectionState('clicked');
        window.open(loginWithAAD, 'popup', 'width=600, height=600');
    };

    return {
        bulkAuthenticationSetting,
        testConnectionState,
        setTestConnectionState,
        testConnectionError,
        emailLinkingChecked,
        setEmailLinkingChecked,
        methods: {
            ...methods,
            handleSubmit: handleSubmit(onSubmit),
        },
        disableSubmit,
        disableTestConnection,
        cancel,
        handleAADAuthPopup,
        onSubmit,
        updateTestConnectionState,
        legacyUpdateTestConnectionState,
        handleTestConnectionBypass,
        certMetadata,
    };
};

export default useExternalProvidersAADFormViewModel;
