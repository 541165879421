import {
    matchRoutes,
    useLocation,
} from 'react-router-dom';

import * as RouteNames from '../constants/RouteNames';

const useIsProfileRoute = () => {
    const currentLocation = useLocation();
    return !!matchRoutes([
        { path: RouteNames.Profile },
        { path: RouteNames.ThemeSettings },
        { path: RouteNames.AccessibilitySettings },
        { path: RouteNames.NavigationSettings },
        { path: RouteNames.NotificationSettings },
        { path: RouteNames.AddNotificationSettingsProfile },
        { path: RouteNames.PrivacyAndSecuritySettings },
        { path: RouteNames.PersonalAccessToken },
    ], currentLocation);
};

export default useIsProfileRoute;
