import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICard } from '@experiences/interfaces';
import { PermissionType } from '@experiences/interfaces';
import { PortalTagManagementEvent } from '@experiences/telemetry';
import { useRouteResolver } from '@experiences/util';
import { useTheme } from '@mui/material/styles';
import { PortalIcon } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import { ReactComponent as AuditLogsAdmin } from '../../../images/auditLogsAdmin.svg';
import { ReactComponent as LicensesAdmin } from '../../../images/licensesAdmin.svg';
import { ReactComponent as ServicesAdmin } from '../../../images/servicesAdmin.svg';
import { ReactComponent as SettingsAdmin } from '../../../images/settingsAdmin.svg';
import { ReactComponent as TagsAdmin } from '../../../images/tagsAdmin.svg';
import { ReactComponent as VpnAdmin } from '../../../images/vpnAdmin.svg';
import {
    getRobotUnits,
    robotUnitsApiPath,
} from '../../../services/hypervisor';
import {
    getPermissions,
    tagsUrl,
} from '../../../services/orchestrator/TagsService.default';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    isOrgAdminSelector,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { hasPermission } from '../../tags/addedit/properties/TenantTagsUtil';
import { TenantStatusConstants } from '../TenantConstants';
import { useTenantsContext } from '../TenantsContextProvider';

const useTenantAdminViewModel = () => {
    const { tenantId } = useParams() as { tenantId: string };
    const getRoute = useRouteResolver();

    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();
    const theme = useTheme();

    const isOrgAdmin = useSelector(isOrgAdminSelector);
    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);

    const vpnGatewayCost = Number(useFeatureFlagValue(Features.VpnGatewayCost.name));
    const EnableTenantAdminControl = useFeatureFlagValue(Features.EnableTenantAdminControl.name);
    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);

    const { getTenantData } = useTenantsContext();

    const tenant = useMemo(() => getTenantData(tenantId), [ getTenantData, tenantId ]);

    const isOrchestratorEnabled = tenant?.tenantServiceInstances.some(
        (service) => service.serviceType === 'orchestrator' && service.status?.toUpperCase() === TenantStatusConstants.ENABLED
    );

    const { data: permissions } = useSWR(
        isOrchestratorEnabled && accountName && tenant?.name ?
            {
                url: `${tagsUrl}/permissions`,
                accountLogicalName: accountName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            } : null,
        getPermissions,
    );

    const { data: robotUnits } = useSWR(
        process.buildConfigs.isOnPrem ? null :
            {
                url: robotUnitsApiPath,
                accountLogicalName: accountName,
                selectedTenantId: tenantId,
            },
        getRobotUnits,
    );

    const handleTagsClick = useCallback(() => {
        logEvent(PortalTagManagementEvent.CardClick);
        return getRoute(RouteNames.TenantTagsLabels.replace(':tenantId', tenantId));
    }, [ getRoute, tenantId, logEvent ]);

    const cardList: ICard[] = useMemo(() => [
        {
            title: 'CLIENT_SERVICES',
            titleIcon: <ServicesAdmin />,
            description: 'CLIENT_TENANT_HOME_SERVICES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.TenantServices.replace(':tenantId', tenantId)),
            dataCy: 'tenant-home-services-card',
            alt: 'Services',
        },
        {
            title: 'CLIENT_MANAGE_ACCESS',
            titleIcon: <PortalIcon
                name="security"
                size="40px"
                color={theme.palette.blue[500]}
            />,
            description: 'CLIENT_MANAGE_ACCESS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ManageAccess.replace(':tenantId', tenantId)),
            dataCy: 'tenant-home-manage-access-card',
            alt: 'ManageAccess',
            invisible: !EnableTenantAdminControl,
            preview: true,
        },
        {
            title: 'CLIENT_LICENSES',
            titleIcon: <LicensesAdmin />,
            description: 'CLIENT_TENANT_HOME_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ServicesUsage.replace(':tenantId', tenantId)),
            dataCy: 'tenant-home-licenses-card',
            disabled: !isOrgAdmin,
            alt: 'Licenses',
            invisible: AccountLicense[accountPlan] >= AccountLicense.FREEKIT,
        },
        {
            // Check if Orchestrator is enabled for this Tenant
            title: 'CLIENT_TAGS',
            titleIcon: <TagsAdmin />,
            description: 'CLIENT_TENANT_HOME_TAGS_DESCRIPTION',
            onClickLink: handleTagsClick,
            dataCy: 'tenant-home-tags-card',
            alt: 'Tags',
            disabled: (isOrchestratorEnabled && !hasPermission(permissions, [ PermissionType.View ])) || !isOrchestratorEnabled,
            showWarning: !hasPermission(permissions, [ PermissionType.View ]),
            warningTooltip: translate({ id: 'CLIENT_TENANT_TAGS_PERMISSIONS_RESTRICTION' }, { permissions: 'Tags.View' }),
        },
        {
            title: 'CLIENT_GATEWAY',
            titleIcon: <VpnAdmin />,
            description: 'CLIENT_TENANT_HOME_GATEWAY_DESCRIPTION',
            onClickLink: getRoute(RouteNames.VpnGateway.replace(':tenantId', tenantId)),
            dataCy: 'tenant-home-vpn-gateway-card',
            alt: translate({ id: 'CLIENT_GATEWAY' }),
            disabled: tenant?.status === 'Disabled' || !isOrgAdmin,
            showWarning: robotUnits ? robotUnits?.Total < vpnGatewayCost && !robotUnits.Expired : false,
            warningTooltip: translate({ id: 'CLIENT_INSUFFICIENT_RU_VPN_GATEWAY_BANNER_TEXT' }),
            invisible: process.buildConfigs.isOnPrem,
        },
        {
            title: 'CLIENT_AUDIT_LOG',
            titleIcon: <AuditLogsAdmin />,
            description: 'CLIENT_TENANT_HOME_AUDIT_DESCRIPTION',
            onClickLink: getRoute(RouteNames.TenantAudit.replace(':tenantId', tenantId)),
            dataCy: 'tenant-home-audit-card',
            alt: translate({ id: 'CLIENT_AUDIT_LOG' }),
            invisible: !EnableUnifiedAuditExperience,
        },
        // Do No Modify
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: <SettingsAdmin />,
            description: 'CLIENT_TENANT_HOME_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.TenantSettings.replace(':tenantId', tenantId)),
            dataCy: 'tenant-home-settings-card',
            alt: 'Settings',
        },
    ], [
        getRoute,
        tenantId,
        theme.palette.blue,
        EnableTenantAdminControl,
        isOrgAdmin,
        accountPlan,
        handleTagsClick,
        isOrchestratorEnabled,
        permissions,
        translate,
        tenant?.status,
        robotUnits,
        vpnGatewayCost,
        EnableUnifiedAuditExperience,
    ]);

    return {
        cardList,
        tenant,
    };
};

export default useTenantAdminViewModel;
