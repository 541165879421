import type {
    IAddEditIPRestrictionStatus,
    IIPRestrictionStatus,
} from '../../common/interfaces/iprestriction';
import {
    get,
    patch,
} from '../utility/Requests.default';
import { ipNetworkUrl } from './IPNetworkService';

export function getIpConfigurationStatus({ organizationId }: { url: string; organizationId: string }) {
    return get<IIPRestrictionStatus>(`${ipNetworkUrl}/organization/${organizationId}/ipRangeStatus`);
}

export async function updateIpConfigurationStatus(organizationId: string, payload: IAddEditIPRestrictionStatus) {
    return await patch<IAddEditIPRestrictionStatus>(`${ipNetworkUrl}/organization/${organizationId}/ipRangeStatus`, { body: { ... payload } });
}
