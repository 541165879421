import type {
    Allocation,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';
import type { IProductConfiguration } from '@experiences/util';
import {
    getSortedRobotsAndServicesProductConfigurations,
    getSortedUserBundleConfigurations,
} from '@experiences/util';
import assign from 'lodash/assign';
import find from 'lodash/find';
import map from 'lodash/map';

import { getRobotsAndServices } from '../services/getLicenseAllocations';

export function extractProducts(
    productConfigs: IProductConfiguration[],
    productAllocations: Allocation[],
): Allocation[] {
    return map(productConfigs, p => assign(p, find(productAllocations, { code: p.code }))).filter(p => {
        const isAvailable = !process.buildConfigs.unavailableProductCodes?.includes(p.code) ?? true;
        const hasQuantity = p.allocated > 0 || p.total > 0;
        return (
            isAvailable && hasQuantity
        );
    });
}

export const LicenseAllocationWidget: WidgetTemplate<Allocation> = {
    name: 'LicenseAllocation',
    titleKey: 'CLIENT_LICALLOC_WIDGET_TITLE',
    emptyMsgKey: () => '',
    seeAllTextKey: 'CLIENT_SEE_ALL_LICENSES',
    type: 'Allocation',
    cardRows: 5,
    cardHeight: 80,
    tabs: [
        {
            label: 'Users',
            labelKey: 'CLIENT_USERS',
            descriptionKey: '',
            value: 'Users',
        },
        {
            label: 'Robot',
            labelKey: 'CLIENT_ROBOTS_AND_SERVICES',
            descriptionKey: '',
            value: 'Robot',
        },
    ],
    defaultTab: 'Users',
    getSeeAllUrl: (ctx: WidgetContext) => `${window.location.origin}/${ctx.accountLogicalName}/portal_/licensing`,
    fetch: async (ctx: WidgetContext) => {
        const allocations = await getRobotsAndServices(ctx.accessToken, ctx.userId, ctx.organizationGuid);
        const robotAllocations = allocations.productAllocations.map((item) => ({
            ...item,
            category: 'Robot',
        } as Allocation));

        const userAllocations = allocations.userLicensingBundles?.map((item) => ({
            ...item,
            category: 'Users',
        } as Allocation)) ?? [];

        const robotProducts = extractProducts(getSortedRobotsAndServicesProductConfigurations(), robotAllocations);
        const userProducts = extractProducts(getSortedUserBundleConfigurations(), userAllocations);

        return Promise.resolve([ ...robotProducts, ...userProducts ]);
    },
    transform: (data: Allocation[]) => data.map((item: Allocation) => ({
        data: item,
        tabCategory: item.category,
        subtitle: item.code.toUpperCase(),
        header: ``,
        headerKey: `CLIENT_USAGE_HEADER_${item.code.toUpperCase()}`,
        description: ``,
        descriptionKey: `CLIENT_USAGE_DESCRIPTION_${item.code.toUpperCase()}`,
        total: item.total,
        allocated: item.allocated,
    })),
};
