import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Add from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutline';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { LicensingGroupAllocation } from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IDirectoryEntry } from '../../common/interfaces/cis/directory';
import type { IGroupAllocations } from '../../common/interfaces/licenses';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import {
    deleteGroupLicenseAllocation,
    getGroupRuleUserBundleCodes,
    getGroupsAllocationsPage,
    userLicenseUrl,
} from '../../services/licensing/accountant/UserLicenseService';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiPaginatedGrid } from '../common/UiPaginatedGrid';
import { UiPeoplePicker } from '../common/UiPeoplePicker';
import type { IGroupAllocationsPagedResult } from './interfaces/license';
import { UserLicenseSelectFilterComponent } from './subcomponents/UserLicenseSelectFilterComponent';

const useStyles = makeStyles(() =>
    createStyles({
        actionsAndGrid: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '650px',
            width: '100%',
        },
        peoplePicker: { minWidth: '350px' },
    }),
);

const PaginatedGroupAllocationGridComponent: React.FC = () => {
    const EnableExternalUserLicense = getFeatureFlagValue(Features.EnableExternalUserLicense.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();
    const [ selectedGroupId, setSelectedGroupId ] = useState<string | undefined>();
    const { getErrorMessage } = useGetErrorInfo();

    const [ refresh, setRefresh ] = useState(false);
    const [ selectedLicenseCodes, setSelectedLicenseCodes ] = useState<string[] | undefined>();
    const isLicenseExpired = useLicenseExpired();

    const { mutate } = useSWRConfig();

    const {
        data: groupRuleLicenseCodes, mutate: mutateBundleCodes,
    } = useSWR<string[], Error>(
        `${userLicenseUrl}/ublCodes/group`,
        getGroupRuleUserBundleCodes,
    );

    const refreshGridAndActions = useCallback(() => {
        setRefresh(true);
        mutateBundleCodes();
        selectedGroupId && mutate([ selectedGroupId ]);
    }, [ mutate, mutateBundleCodes, selectedGroupId ]);

    useEffect(() => {
        if (location.state && (location.state as any)['refresh']) {
            refreshGridAndActions();
        }
    }, [ location, refreshGridAndActions ]);

    const getUserLicenseNames = useCallback(
        (data: IGroupAllocations) => {
            if (data.useExternalLicense) {
                return translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' });
            }
            if (!data.userBundleLicenses || data.userBundleLicenses.length === 0) {
                return translate({ id: 'CLIENT_NO_ALLOCATION_RULE' });
            }
            return data.userBundleLicenses
                .map(ubl => {
                    const translatedBundleCode = translate({ id: `CLIENT_${ubl}` });
                    const numberOfLeases =
                        data.userBundleLeases?.[ubl] ? data.userBundleLeases[ubl] : 0;
                    const allocatedBundleCount = numberOfLeases > 0 ? ` (${data.userBundleLeases[ubl]})` : '';
                    return `${translatedBundleCode}${allocatedBundleCount}`;
                })
                .join(', ');
        },
        [ translate ],
    );

    const userBundleCodeToTranslationMap = useMemo(() => {
        const bundleToTranslation = new Map<string, string>();
        groupRuleLicenseCodes?.forEach(ublCode =>
            bundleToTranslation.set(ublCode, translate({ id: `CLIENT_${ublCode}` })),
        );
        if (EnableExternalUserLicense) {
            bundleToTranslation.set('EXTERNAL_LICENSE', translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' }));
        } else if (groupRuleLicenseCodes?.length === 0) {
            bundleToTranslation.set('NO_LICENSE', translate({ id: 'CLIENT_NO_LICENSE' }));
        }
        return bundleToTranslation;
    }, [ translate, groupRuleLicenseCodes ]);

    const createDialog = useShowDialog();

    const openDeleteDialog = useCallback(
        async (group: IGroupAllocations) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE' }),
                body: translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE_DETAILS' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                try {
                    await deleteGroupLicenseAllocation(group.id);
                    createNotification(translate({ id: 'CLIENT_GROUP_ALLOCATION_RULE_DELETED' }), notificationType.SUCCESS);
                } catch (error) {
                    const errorObject = await getErrorMessage(error);
                    createNotification(errorObject?.errorMessage || 'Error', notificationType.ERROR);
                }
                mutate(`${userLicenseUrl}/groups`);
                refreshGridAndActions();
            }
        },
        [ createDialog, translate, mutate, refreshGridAndActions, createNotification, getErrorMessage ],
    );

    return (
        <div className={classes.actionsAndGrid}>
            <UiPaginatedGrid<IGroupAllocations, IGroupAllocationsPagedResult, { selectedGroupId?: string; licenseCodes?: string[] }>
                tableId="group-allocations-paginated-ui-grid"
                url={`${userLicenseUrl}/group/page`}
                dataCy="group-allocations-paginated-ui-grid"
                pagination
                fetcher={getGroupsAllocationsPage}
                fetcherArgs={{
                    selectedGroupId,
                    licenseCodes: selectedLicenseCodes ?? [],
                }}
                refreshData={{
                    current: refresh,
                    set: setRefresh,
                }}
                searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                defaultPageSize={10}
                search
                SearchComponent={
                    <>
                        <UiPeoplePicker
                            className={classes.peoplePicker}
                            searchOnly
                            type="group"
                            helper={<></>}
                            ariaLabel={translate({ id: 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER' })}
                            inputPlaceholder={translate({ id: 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER' })}
                            onChange={async (directoryEntries: IDirectoryEntry[]) => {
                                if (directoryEntries.length > 0 && directoryEntries[0]) {
                                    setSelectedGroupId(directoryEntries[0].identifier);
                                } else {
                                    setSelectedGroupId(undefined);
                                }
                                setRefresh(true);
                            }}
                        />
                        <UserLicenseSelectFilterComponent
                            filterMap={userBundleCodeToTranslationMap}
                            header={translate({ id: 'CLIENT_LICENSES' })}
                            filterChange={setSelectedLicenseCodes}
                        />
                    </>
                }
                columns={[
                    {
                        accessor: 'name',
                        disableSortBy: true,
                        Header: translate({ id: 'CLIENT_LICENSES_ALLOCATION_GROUP_NAME' }),
                        width: 35,
                        Cell: ({ row }) => (
                            <span>
                                {row.original.orphan ? translate({ id: 'CLIENT_NOT_APPLICABLE' }) : row.original.name}
                            </span>
                        ),
                    },
                    {
                        accessor: 'licenses',
                        disableSortBy: true,
                        Header: translate({ id: 'CLIENT_LICENSES_ALLOCATION_RULES' }),
                        Cell: ({ row }) => (
                            <Tooltip
                                data-cy="licenses-tooltip"
                                arrow
                                placement="bottom"
                                title={getUserLicenseNames(row.original)}
                            >
                                <span>
                                    {getUserLicenseNames(row.original)}
                                </span>
                            </Tooltip>
                        ),
                        width: 45,
                    },
                ]}
                hiddenColumns={[ 'id' ]}
                tableActions={[
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_ALLOCATE_RULE' }),
                        tooltip: translate({ id: 'CLIENT_ALLOCATE_RULE' }),
                        actionType: GridActionType.Main,
                        icon: <Add />,
                        click: () => {
                            navigate(`${getRoute(RouteNames.LicensingGroupAllocation)}/add`,
                                {
                                    state: {
                                        group: undefined,
                                        previousLocation: location.pathname,
                                    },
                                });
                        },
                        disable: () => isLicenseExpired,
                    },
                ]}
                rowActions={[
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_GROUP_MEMBERS_WITH_LICENSES' }),
                        tooltip: translate({ id: 'CLIENT_GROUP_MEMBERS_WITH_LICENSES' }),
                        actionType: GridActionType.Row,
                        icon: <PersonOutlined data-cy="ui-grid-view-group-allocations-button" />,
                        click: row => {
                            navigate(`${getRoute(LicensingGroupAllocation)}/view`,
                                {
                                    state: {
                                        group: row.original,
                                        previousLocation: location.pathname,
                                    },
                                });
                        },
                        disable: () => isLicenseExpired,
                    },
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' }),
                        tooltip: translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' }),
                        actionType: GridActionType.Row,
                        icon: <AllocateLicenseIcon data-cy="ui-grid-edit-group-allocations-button" />,
                        click: row => {
                            const action =
                                (row.original?.userBundleLicenses?.length ?? 0) !== 0 ||
                                row.original?.useExternalLicense
                                    ? 'edit'
                                    : 'add';
                            navigate(`${getRoute(LicensingGroupAllocation)}/${action}`,
                                {
                                    state: {
                                        group: row.original,
                                        previousLocation: location.pathname,
                                    },
                                });
                        },
                        disable: row => row?.original?.orphan || isLicenseExpired,
                    },
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE' }),
                        tooltip: translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE' }),
                        actionType: GridActionType.Row,
                        icon: <DeleteForeverOutlinedIcon data-cy="delete-allocation-rule-row-action" />,
                        click: row => openDeleteDialog(row.original),
                    },
                ]}
            />
        </div>
    );
};

export default PaginatedGroupAllocationGridComponent;
