import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { SecuritySettingsEvent } from '@experiences/telemetry';
import {
    UiProgressButton,
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import isEqual from 'lodash/isEqual';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { SessionPolicyLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import {
    SessionPolicyKey,
    TimeUnits,
} from '../../../common/constants/SessionPolicySettingConstant';
import { triggerPortalShellRefresh } from '../../../common/hooks/triggerPortalShellRefresh';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useGetSetting } from '../../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { ISettingPayload } from '../../../services/identity/SettingService';
import {
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import UpgradeForFeature from '../../common/UpgradeForFeature';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        centerLoader: { margin: 'auto' },
        footer: {
            position: 'fixed',
            bottom: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            height: '64px',
            width: 'calc(100% - max(min(312px, 20vw), 175px) - 68px)',
            marginLeft: '-24px',
            backgroundColor: theme.palette.semantic.colorBackground,
            zIndex: 1,
        },
        footerButton: { marginRight: '24px' },
        formControlLabelRoot: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        switchLabel: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
        },
        switchDescription: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
        },
        sessionPolicyContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            margin: '15px 16px',
        },
        textContainer: { margin: '0px 8px' },
        timeoutDuration: {
            marginRight: '8px',
            width: '80px',
        },
        timeoutSettings: { marginLeft: '56px' },
        timeoutUnit: {
            marginLeft: '8px',
            width: '120px',
            '& [class*=MuiInputLabel]': { display: 'none' },
        },
        timeoutControl: {
            display: 'flex',
            flexDirection: 'row',
        },
    }),
);

interface ISessionPolicySettingsData {
    timeout: {
        isEnabled: boolean;
        duration: string;
        unit: string;
    };
    limitConcurrentSessions: {
        isEnabled: boolean;
    };
}

const defaultSessionPolicySettingsData: ISessionPolicySettingsData = {
    timeout: {
        isEnabled: false,
        duration: '1',
        unit: TimeUnits.MINUTES,
    },
    limitConcurrentSessions: { isEnabled: false },
};

const SessionPolicyComponent: React.FC = () => {

    const classes = useStyles();

    const { isOffersRevampAndCommunity } = useCheckLicense();
    const createNotification = useUiSnackBar();
    const { formatMessage: translate } = useIntl();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const getLocalizedLink = useDocumentationLinks();

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ loading, setLoading ] = useState(false);

    const keys = useMemo(
        () => [ SessionPolicyKey.AllowConcurrentBrowserSessions, SessionPolicyKey.IdleTimeoutDuration ],
        [],
    );
    const {
        data: fetchedSettings, mutate,
    } = useGetSetting(keys);

    const {
        control,
        formState: {
            isDirty,
            dirtyFields,
            errors,
        },
        handleSubmit,
        setValue,
        reset,
        watch,
        getValues,
        setError,
        clearErrors,
    } = useForm<ISessionPolicySettingsData>({
        mode: 'onSubmit',
        defaultValues: defaultSessionPolicySettingsData,
    });

    const currentIdleTimeout = watch('timeout.isEnabled');

    const msToHM = useCallback((milliseconds?: string) => {
        let duration = Number(milliseconds) || 0;
        let unit;
        // Calculate duration to smallest whole number unit
        const minutes = duration / 1000 / 60;
        const hours = minutes / 60;
        if (hours % 1 === 0 && duration > 0) {
            duration = Math.min(hours, 24);
            unit = TimeUnits.HOURS;
        } else {
            duration = Math.min(minutes, 24 * 60);
            unit = TimeUnits.MINUTES;
        }
        return {
            duration,
            unit,
        };
    }, []);

    useEffect(() => {
        if (fetchedSettings) {
            setLoading(true);
            const fetchedTimeoutDurationSetting = fetchedSettings.find(
                setting => setting.key === SessionPolicyKey.IdleTimeoutDuration,
            );
            const fetchedAllowConcurrentSessionSetting = fetchedSettings.find(
                setting => setting.key === SessionPolicyKey.AllowConcurrentBrowserSessions,
            );

            const convertTime = msToHM(fetchedTimeoutDurationSetting?.value);
            reset({
                timeout: {
                    isEnabled: convertTime.duration > 0,
                    duration: convertTime.duration > 0 ? convertTime.duration.toString() : '1',
                    unit: convertTime.unit,
                },
                limitConcurrentSessions: { isEnabled: fetchedAllowConcurrentSessionSetting?.value === 'false' },
            });
            setLoading(false);
        }
    }, [ fetchedSettings, reset, msToHM ]);

    const timeUnits = useMemo(
        () => ({
            [TimeUnits.MINUTES]: 'CLIENT_MINUTE',
            [TimeUnits.HOURS]: 'CLIENT_HOUR',
        }),
        [],
    );

    const toMillisecond = useCallback((duration: number, unit: string) => {
        switch (unit) {
            case TimeUnits.MINUTES:
                return duration * 60 * 1000;
            case TimeUnits.HOURS:
                return duration * 60 * 60 * 1000;
            default:
                return duration;
        }
    }, []);

    const onSubmit = useCallback(
        async (data: ISessionPolicySettingsData) => {
            try {
                setLoading(true);
                const timeoutInMillisecond = getValues('timeout.isEnabled')
                    ? toMillisecond(parseInt(data.timeout.duration), data.timeout.unit)
                    : 0;
                const allowConcurrentSessions = getValues('limitConcurrentSessions.isEnabled') === false;
                const payload: ISettingPayload = {
                    settings: [
                        {
                            key: 'AllowConcurrentBrowserSessions',
                            value: allowConcurrentSessions.toString(),
                        },
                        {
                            key: 'IdleTimeoutDuration',
                            value: timeoutInMillisecond.toString(),
                        },
                    ],
                    partitionGlobalId,
                };
                await saveSetting(settingUrl, payload);
                createNotification(
                    translate({ id: 'CLIENT_SESSION_POLICY_SUCCESSFULLY_CHANGED' }), notificationType.SUCCESS,
                );
                mutate();
                triggerPortalShellRefresh();
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            } finally {
                setLoading(false);
            }
        },
        [ getValues, toMillisecond, partitionGlobalId, createNotification, translate, mutate, setErrorMessage, getErrorMessage ],
    );

    const filterNonNumber = useCallback((value: string) => {
        const NON_DIGIT = '/[^0-9]/g';
        let numString = value.replace(NON_DIGIT, '');
        numString = isNaN(parseInt(numString)) ? '' : numString;
        return numString;
    }, []);

    const isValidTimeRange = useCallback((num: string, units: TimeUnits) => {
        const numInt = parseInt(num);
        if (
            isNaN(numInt) ||
            (numInt && ((numInt > 24 && units === TimeUnits.HOURS) || (numInt > 24 * 60 && units === TimeUnits.MINUTES)))
        ) {
            return false;
        }
        return true;
    }, []);

    const handleInteger = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const name = 'timeout.duration';
            const { value } = event.target;
            if (value?.trim().length === 0 || value?.trim() === '0' || !value?.trim().match(/[0-9]/g)) {
                setError(name, { type: 'min' });
            } else {
                clearErrors(name);
            }
            const numResult = filterNonNumber(value);
            const units = getValues('timeout.unit') as TimeUnits;
            if (!isValidTimeRange(numResult, units)) {
                setError(name, { type: 'max' });
            }
            const valueChanged = !isEqual(numResult, getValues(name));
            setValue(name, numResult, { shouldDirty: valueChanged });
        },
        [ filterNonNumber, getValues, isValidTimeRange, setValue, setError, clearErrors ],
    );

    const handleUnitOfTime = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const name = 'timeout.duration';
            const numResult = getValues('timeout.duration') as string;
            clearErrors(name);
            const units = event.target.value as TimeUnits;
            if (!isValidTimeRange(numResult, units)) {
                setError(name, { type: 'max' });
            }
        },
        [ clearErrors, getValues, setError, isValidTimeRange ],
    );

    return isOffersRevampAndCommunity ?
        <UpgradeForFeature
            upgradeTitle={translate({ id: 'CLIENT_UPGRADE_SESSION_TITLE' })}
            upgradeMessage={translate({ id: 'CLIENT_UPGRADE_SESSION_DESCRIPTION' })}
            documentationLink={getLocalizedLink({ articleSlug: SessionPolicyLink })}
            level={AccountLicense.ENTERPRISE}
            telemetryTitle={SecuritySettingsEvent.SessionPolicy} />
        : fetchedSettings ? (
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty || Object.keys(dirtyFields).length === 0 || !!errors.timeout?.duration}
                            variant="contained"
                            data-cy="session-policy-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                centerChild
            >
                <div className={classes.sessionPolicyContainer}>
                    <div className="enableIdleTimeout">
                        <Controller
                            name="timeout.isEnabled"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    className={classes.formControlLabelRoot}
                                    control={
                                        <Switch
                                            checked={field.value}
                                            onChange={e => field.onChange(e.target.checked)}
                                            data-cy="session-policy-timeout-enabled-switch"
                                        />
                                    }
                                    label={
                                        <div className={classes.textContainer}>
                                            <UiText className={classes.switchLabel}>
                                                {translate({ id: 'CLIENT_SESSION_POLICY_ENABLE_IDLE_TIMEOUT' })}
                                            </UiText>
                                            <UiText>
                                                <FormattedMessage
                                                    id="CLIENT_SESSION_POLICY_ENABLE_IDLE_TIMEOUT_DESCRIPTION"
                                                    values={{
                                                        strong: (chunk: React.ReactNode[]) => <strong>
                                                            {chunk}
                                                        </strong>,
                                                    }}
                                                />
                                            </UiText>
                                        </div>
                                    }
                                />
                            )}
                        />
                        {currentIdleTimeout && (
                            <div className={classes.timeoutSettings}>
                                <label htmlFor="timeoutDuration">
                                    <InputLabel className="select-label">
                                        {translate({ id: 'CLIENT_SESSION_POLICY_TIMEOUT_SETTINGS' })}
                                    </InputLabel>
                                </label>
                                <div className={classes.timeoutControl}>
                                    <Controller
                                        name="timeout.duration"
                                        control={control}
                                        rules={{
                                            required: true,
                                            min: 1,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="off"
                                                error={!!errors.timeout?.duration}
                                                inputProps={{ 'data-cy': 'timeout-duration-textfield' }}
                                                onChange={event => {
                                                    field.onChange(event);
                                                    handleInteger(event);
                                                }}
                                                id="timeoutDuration"
                                                type='number'
                                                className={classes.timeoutDuration}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    <UiSelect
                                        control={control}
                                        name="timeout.unit"
                                        options={timeUnits}
                                        required
                                        className={classes.timeoutUnit}
                                        dataCy="timeout-unit-select"
                                        inputLabel=""
                                        error={!!errors.timeout?.duration}
                                        onChange={handleUnitOfTime}
                                    />
                                </div>
                                <FormHelperText
                                    error={!!errors.timeout?.duration}
                                    data-cy="timeout-error-text"
                                >
                                    {
                                        (errors.timeout?.duration?.type === 'required' &&
                                            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                        (errors.timeout?.duration?.type === 'min' &&
                                            translate({ id: 'CLIENT_SESSION_POLICY_MIN_DURATION_ERROR' })) ||
                                        (errors.timeout?.duration?.type === 'max' &&
                                            translate({ id: 'CLIENT_SESSION_POLICY_MAX_DURATION_ERROR' })) || ''
                                    }
                                </FormHelperText>
                            </div>
                        )}
                    </div>
                    <div className="limitConcurrentSessions">
                        <Controller
                            name="limitConcurrentSessions.isEnabled"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    className={classes.formControlLabelRoot}
                                    control={
                                        <Switch
                                            checked={field.value}
                                            onChange={e => field.onChange(e.target.checked)}
                                            data-cy="session-policy-concurrent-enabled-switch"
                                        />
                                    }
                                    label={
                                        <div className={classes.textContainer}>
                                            <UiText className={classes.switchLabel}>
                                                {translate({ id: 'CLIENT_SESSION_POLICY_LIMIT_CONCURRENT_SESSIONS' })}
                                            </UiText>
                                            <UiText className={classes.switchDescription}>
                                                {translate({ id: 'CLIENT_SESSION_POLICY_LIMIT_CONCURRENT_SESSIONS_DESCRIPTION' })}
                                            </UiText>
                                        </div>
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
            </UiForm>
        ) : (
            <div className={classes.centerLoader}>
                <CircularProgress />
            </div>
        );
};
export default SessionPolicyComponent;
