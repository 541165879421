import { ERROR_FIELD } from '@experiences/constants';
import type {
    Automation,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getApps } from '../services/getApps';
import { getProcesses } from '../services/getProcesses';
import { getProjects } from '../services/getProjects';

export const AutomationWidget: WidgetTemplate<Automation> = {
    name: 'Automations',
    titleKey: 'CLIENT_AUTOMATIONS_WIDGET_TITLE',
    subtitleKey: 'CLIENT_AUTOMATIONS_WIDGET_SUBTITLE',
    emptyMsgKey: () => 'CLIENT_AUTOMATION_WIDGET_EMPTY',
    type: 'Card',
    cardRows: 2,
    tabs: [
        {
            label: 'Projects',
            labelKey: 'CLIENT_PROJECTS_TAB',
            descriptionKey: 'CLIENT_DRAFT_DESCRIPTION',
            value: 'Projects',
        },
        {
            label: 'Automation',
            labelKey: 'CLIENT_RECENTS_TAB',
            descriptionKey: 'CLIENT_AUTOMATIONS_WIDGET_SUBTITLE',
            value: 'Automation',
        },
    ],
    defaultTab: 'Automation',
    actionHandles: [
        {
            id: 'run',
            handle: (data: Automation, ctx: WidgetContext) => {
                window.location.assign(getResourceUrl(data, ctx));
            },
        },
        {
            id: 'copyUrl',
            handle: (data: Automation, ctx: WidgetContext) => {
                navigator.clipboard.writeText(getResourceUrl(data, ctx));
            },
        },
    ],
    transform: (data: Automation[]) => data.map((item) => ({
        data: item,
        category: item.category,
        header: item.header,
        description: item.description,
        icon: item.icon,
        iconType: 'initial',
        isSubtitleTime: true,
        tabCategory: item.tabCategory,
        subtitleTimePrefixKey: 'CLIENT_APPS_LAST_PUBLISHED',
        subtitle: item.lastActivity,
        actions: [
            {
                id: 'run',
                nameKey: 'CLIENT_ACTION_NAME_RUN',
                ariaLabelKey: 'CLIENT_ACTION_NAME_RUN_ARIA_LABEL',
                icon: 'play_circle',
                type: 'icon',
            },
            {
                id: 'copyUrl',
                nameKey: 'CLIENT_ACTION_NAME_COPYLINK',
                icon: 'link',
                type: 'overflow',
            },
        ],
    })),
    clickHandler: (data: Automation, ctx: WidgetContext) => {
        window.location.assign(getResourceUrl(data, ctx));
    },
    fetch: async (ctx: WidgetContext) => {
        const [ processes, apps, projects ] = await Promise.allSettled([
            getProcesses(ctx.accessToken, ctx.tenant, ctx.accountLogicalName),
            getApps(ctx.accessToken),
            getProjects(ctx.accessToken),
        ]);
        if (processes.status !== 'fulfilled' && apps.status !== 'fulfilled' && projects.status !== 'fulfilled') {
            return Promise.reject(new Error(processes.reason + '\n' + apps.reason + '\n' + projects.reason));
        }

        let automations: Automation[] = [];
        if (processes.status === 'fulfilled') {
            automations = automations.concat(processes.value.map((process) => ({
                header: process.Entity.Name,
                category: 'Process',
                processType: process.Type,
                processId: process.Entity.Id,
                lastActivity: new Date(process.Entity.CreationTime).toISOString(),
                description: process.Entity.Description ?? '',
                tabCategory: 'Automation',
            })));
        }
        if (apps.status === 'fulfilled') {
            const ownedApps = apps.value.filter((app) =>
                app.model.ownerId === ctx.userId || app.model.createdById === ctx.userId);
            automations = automations.concat(ownedApps.map((app) => ({
                header: app.model.title,
                description: app.model.description,
                category: 'App',
                systemName: app.model.systemName,
                lastActivity: app.latestPublishedDate,
                tabCategory: 'Automation',
            })));
        }
        if (processes.status !== 'fulfilled' && apps.status !== 'fulfilled') {
            automations = automations.concat([
                {
                    header: ERROR_FIELD,
                    description: '',
                    category: 'App',
                    systemName: '',
                    lastActivity: '',
                    tabCategory: 'Automation',
                },
            ]);
        }
        if (projects.status === 'fulfilled') {
            automations = automations.concat(projects.value.map((project) => ({
                header: project.name,
                description: project.description,
                category: 'Project',
                systemName: project.id,
                lastActivity: project.lastModifiedTime,
                tabCategory: 'Projects',
            })));
        } else {
            automations = automations.concat([
                {
                    header: ERROR_FIELD,
                    description: '',
                    category: 'Project',
                    systemName: '',
                    lastActivity: '',
                    tabCategory: 'Projects',
                },
            ]);
        }

        automations.sort((a, b) => new Date(b.lastActivity).getTime() - new Date(a.lastActivity).getTime());

        return Promise.resolve(automations);
    },
};

export function getResourceUrl(data: Automation, ctx: WidgetContext) {
    switch (data.category) {
        case 'Process': return `${window.location.origin}/${ctx.accountLogicalName}/${localStorage.getItem(
            'PORTAL_CURRENT_TENANT',
        )}/processes_/${data.processType}/${data.processId}`;
        case 'App': return `${window.location.origin}/${ctx.accountLogicalName}/apps_/default/run/production/${data.systemName}?origin=portalHome`;
        case 'Project': return `${window.location.origin}/${ctx.accountLogicalName}/studio_/designer/${data.systemName}`;
    }
}
