export function startCase(string: string): string {
    if (!string) {
        return '';
    }

    let result = string.replace(/[-_]/g, ' ');

    const split = result.split(' ');
    const replaced = split.map((word) => {
        word = word
            .split('')
            .reduce(
                (res, current, idx) => current.match(/[A-Z]/) && idx ? res + ' ' + current : res + current,
                '',
            );

        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    result = replaced.join(' ');

    return result.replace(/\s\s+/g, ' ').trim();
}
