import {
    AccountLicense,
    CanaryTenantLimitedServicesLinks,
} from '@experiences/constants';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import {
    UiAlertBanner,
    UiText,
} from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { IService } from '../../../../common/interfaces/tenant/tenant';
import {
    getAvailableServices,
    tenantAvailableServicesUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../../../store/selectors';
import CreateEditTenantFormComponent from '../CreateEditTenantFormComponent';
import { getDefaultRegionForTenant } from '../helpers/TenantRegionHelper';
import type { ServiceErrorType } from '../helpers/TenantServiceErrorMessage';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        errorText: {
            fontSize: '11px',
            color: theme.palette.semantic.colorErrorText,
        },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            marginTop: '4px',
        },
    }),
}));

const TenantCreateServicesComponent: React.FC = () => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinkConstant = useLocalizedLinks();

    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ]);

    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });

    const {
        watch, formState: { errors },
    } = useFormContext<ICreateEditTenantPayload>();

    const isCanaryTenant = watch('customProperties.isCanaryTenant');

    const { data: availableServices } = useSWR(
        {
            url: tenantAvailableServicesUri,
            organizationGuid: partitionGlobalId,
            accountName,
            isCanaryTenant,
        },
        getAvailableServices,
    );

    const defaultRegion = useMemo(() => getDefaultRegionForTenant(availableServices), [ availableServices ]);
    const noExistingServicesOnCreate: IService[] = useMemo(() => [], []);

    return (
        <div className={classes.mainContainer}>
            {isCanaryTenant && availableServices &&
            <span style={{
                width: '100%',
                alignContent: 'center',
            }}>
                <UiAlertBanner
                    dataCy='canary-services-banner'
                    type="info"
                    closeable={false}
                    enterpriseTrialAlertBar>
                    <div className={classes.banner}>
                        {translate({ id: 'CLIENT_CANARY_TENANT_SERVICES' })}
                        <Link
                            style={{ marginLeft: '8px' }}
                            data-cy="learn-region-hosting-button"
                            href={getLocalizedLinkConstant(CanaryTenantLimitedServicesLinks)}
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </div>
                </UiAlertBanner>
            </span>}
            <div style={{ marginTop: '16px' }}>
                <CreateEditTenantFormComponent
                    type="add"
                    availableServices={availableServices}
                    services={noExistingServicesOnCreate}
                    isRegionEnabled={isRegionEnabled}
                    validationErrorHandler={validationErrorHandlers}
                    defaultRegion={defaultRegion}
                />
                {!!errors.services && (
                    <UiText className={classes.errorText}>
                        {translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    </UiText>
                )}
            </div>
        </div>
    );
};

export default TenantCreateServicesComponent ;
