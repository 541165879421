import type { Service } from '@uipath/portal-shell-types';
import type { PropsWithChildren } from 'react';
import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

export interface IServiceOrder {
    serviceOrder: Service[];
    setServiceOrder: React.Dispatch<React.SetStateAction<Service[]>>;
}

const ServiceOrderContext = createContext<IServiceOrder>({
    serviceOrder: [],
    setServiceOrder: () => { },
});

export const useServiceOrderContext = () => useContext(ServiceOrderContext);

export const ServiceOrderProvider = ({ children }: PropsWithChildren) => {
    const [ serviceOrder, setServiceOrder ] = useState<Service[]>([]);

    useEffect(() => {
        const callback = (event: any) => {
            setServiceOrder(event.detail);
        };

        document.addEventListener('serviceOrderFetched', callback);

        return () => {
            document.removeEventListener('serviceOrderFetched', callback);
        };
    }, []);

    return (
        <ServiceOrderContext.Provider value={{
            serviceOrder,
            setServiceOrder,
        }}>
            {children}
        </ServiceOrderContext.Provider>
    );
};
