import { useUiDataContext } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';

import { UserPartition } from '../../../common/constants/Constant';
import { Users } from '../../../common/constants/RouteNames';
import { ReservedUsernames } from '../../../common/constants/UsersConstant';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import useIsEmailTaken from '../../../common/hooks/useIsEmailTaken';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IUserCIS } from '../../../common/interfaces/cis/user';
import getUsersInPartition from '../../../services/identity/UserPartitionService';
import {
    createUser,
    putUser,
} from '../../../services/identity/UserService';
import {
    accountGlobalId,
    isHostModeSelector,
    userGlobalId,
} from '../../../store/selectors';
import reduceGroupCIS, {
    mapBasicUserDto,
    mapUpdateBasicUserDto,
} from '../../../util/UserGroupUtil';
import { defaultEditPasswordData } from '../../common/EditPasswordFormComponent';
import type { IAddEditUserData } from '../interfaces/user';
import { defaultEditGroupMembershipData } from './EditGroupMembershipFormComponent';
import { getDefaultEditUserAuthSettingsData } from './EditUserAuthSettingsFormComponent';

const useAddEditUserViewModel = () => {
    const { formatMessage: translate } = useIntl();

    const { setData } = useUiDataContext<{ refresh: boolean }>();
    const createNotification = useUiSnackBar();

    const {
        open, close: closeModal,
    } = useModalState(Users);
    const close = useCallback((refresh?: boolean) => {
        setData({ refresh: refresh ?? false });
        closeModal();
    }, [ closeModal, setData ]);

    const { type } = useParams() as { type: 'add' | 'edit' };

    const currentUserId = useSelector(userGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const isHostMode = useSelector(isHostModeSelector);

    const location: { state?: { user: IUserCIS } } = useLocation();
    const user = useMemo(() => location?.state?.user ?? ({} as IUserCIS), [ location ]);

    const { groups } = useSimpleGroup(!isHostMode);
    const [ loading, setLoading ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(translate({ id: 'CLIENT_ADD_USER_GENERIC_ERROR' }));

    const methods = useForm<IAddEditUserData>({
        mode: 'onChange',
        defaultValues: {
            ...defaultEditPasswordData,
            ...(isHostMode ? {} : defaultEditGroupMembershipData),
            ...(isHostMode ? {} : getDefaultEditUserAuthSettingsData()),
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
        },
    });

    const {
        reset, setError, watch,
    } = methods;

    const watchBypassPasswordRestriction = watch('bypassBasicAuthRestriction');

    const initialFormData: IAddEditUserData | undefined = useMemo(() => {
        if (type === 'edit' && user) {
            return {
                ...defaultEditPasswordData,
                ...(isHostMode ? {} : { groupIds: reduceGroupCIS(groups, user.groupIDs) }),
                ...(isHostMode ? {} : getDefaultEditUserAuthSettingsData(user)),
                userName: user.userName,
                firstName: user.name,
                lastName: user.surname,
                email: user.email,
            };
        }
    }, [ type, user, groups, isHostMode ]);

    const isEmailTaken = useIsEmailTaken(initialFormData?.email);

    useEffect(() => {
        if (initialFormData) {
            reset(initialFormData);
        }
    }, [ reset, initialFormData ]);

    const isUsernameTaken = useCallback(
        async (username: string) => {
            const response = await getUsersInPartition({
                pagination: {
                    top: UserPartition.MAX_RETURNED_USERS,
                    skip: 0,
                    searchTerm: username,
                },
                partitionGlobalId,
            });
            if (username === initialFormData?.userName) {
                return false;
            }
            const lowercaseUsername = username.toLowerCase();
            return (
                ReservedUsernames.some(reservedUsername => reservedUsername.toLowerCase() === lowercaseUsername) ||
        response.results.some(result => result.userName?.toLowerCase() === lowercaseUsername)
            );
        },
        [ initialFormData?.userName, partitionGlobalId ],
    );

    const onSubmit = useCallback(
        async (data: IAddEditUserData) => {
            setLoading(true);

            try {
                if (data.email && (await isEmailTaken(data.email))) {
                    setError('email', { type: 'available' });
                    setLoading(false);
                    return;
                }
                if (type === 'add') {
                    if (await isUsernameTaken(data.userName)) {
                        setError('userName', { type: 'available' });
                        setLoading(false);
                        return;
                    }
                    await createUser(mapBasicUserDto(data, partitionGlobalId));
                } else if (type === 'edit') {
                    await putUser(user.id, mapUpdateBasicUserDto(data), currentUserId === user.id);
                }
            } catch (error) {
                setShowDrawerError(true);
                const errors = (error as any)?.response?.data?.errors;
                if (errors?.[0]) {
                    setErrorMessage(errors[0].description);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_ADD_USER_GENERIC_ERROR' }));
                }
                setLoading(false);
                return;
            }

            setLoading(false);
            createNotification(translate({ id: type === 'add' ? 'CLIENT_NEW_USER_ADDED' : 'CLIENT_USER_UPDATED' }));
            close(true);
        },
        [
            close,
            createNotification,
            currentUserId,
            isEmailTaken,
            isUsernameTaken,
            partitionGlobalId,
            setError,
            translate,
            type,
            user.id,
        ],
    );

    return {
        isHostMode,
        loading,
        type,
        onSubmit,
        methods,
        watchBypassPasswordRestriction,
        groups,
        open,
        closeModal,
        showDrawerError,
        errorMessage,
    };
};

export default useAddEditUserViewModel;
