const AdditionalAuthenticationRequired = 'additional_authentication_required';
const IncorrectPaymentInformation = 'incorrect_payment_information';
const ExpiredCard = 'expired_card';
const TypeOfPurchaseNotSupported = 'type_of_purchase_not_supported';
const CurrencyNotSupported = 'currency_not_supported';
const InsufficientFunds = 'insufficient_funds';
const DeclinedForUnknownReason = 'declined_for_unknown_reason';
const InvalidTaxId = 'invalid_tax_id';

export function mapDeclineCode(declineCode?: string): string | undefined {
    switch (declineCode) {
        case 'authentication_required':
        case 'approve_with_id':
            return AdditionalAuthenticationRequired;
        case 'incorrect_number':
        case 'incorrect_cvc':
        case 'incorrect_pin':
        case 'incorrect_zip':
        case 'invalid_cvc':
        case 'invalid_expiry_month':
        case 'invalid_expiry_year':
        case 'invalid_number':
        case 'invalid_pin':
        case 'offline_pin_required':
        case 'online_or_offline_pin_required':
            return IncorrectPaymentInformation;
        case 'expired_card':
            return ExpiredCard;
        case 'card_not_supported':
        case 'testmode_decline':
            return TypeOfPurchaseNotSupported;
        case 'currency_not_supported':
            return CurrencyNotSupported;
        case 'card_velocity_exceeded':
        case 'insufficient_funds':
        case 'withdrawal_count_limit_exceeded':
            return InsufficientFunds;
        case 'call_issuer':
        case 'do_not_honor':
        case 'do_not_try_again':
        case 'duplicate_transaction':
        case 'fraudulent':
        case 'generic_decline':
        case 'invalid_account':
        case 'invalid_amount':
        case 'issuer_not_available':
        case 'lost_card':
        case 'merchant_blacklist':
        case 'new_account_information_available':
        case 'no_action_taken':
        case 'not_permitted':
        case 'pickup_card':
        case 'pin_try_exceeded':
        case 'processing_error':
        case 'reenter_transaction':
        case 'restricted_card':
        case 'revocation_of_all_authorizations':
        case 'revocation_of_authorization':
        case 'security_violation':
        case 'service_not_allowed':
        case 'stolen_card':
        case 'stop_payment_order':
        case 'transaction_not_allowed':
        case 'try_again_later':
            return DeclinedForUnknownReason;
        case 'tax_id_invalid':
            return InvalidTaxId;
        default:
            return undefined;
    }
}

export function getTranslationId(errorCode?: string): string {
    return errorCode ? `CLIENT_ERROR_${errorCode.toUpperCase()}` : 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY';
}
