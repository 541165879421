import { OperationTypeConstants } from '@experiences/constants';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { TenantStatusConstants } from '../../component/tenants/TenantConstants';
import { useTenantOperationTrackerContext } from '../../component/tenants/TenantOperationTrackerContextProvider';
import {
    createTenant,
    deleteTenant,
    editTenant,
    setServiceStatus,
    setTenantStatus,
} from '../../services/organization/TenantService';
import { notificationType } from '../constants/Constant';
import type {
    IService,
    ITenant,
} from '../interfaces/tenant/tenant';
import { useUiSnackBar } from './useUiSnackBar';

export const useTenantOperations = () => {

    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const commandTableOperations = useTenantOperationTrackerContext();

    const inProgressNotification = useCallback((label: string, tenantName: string) => {
        createNotification(translate({ id: label }, { 0: tenantName }), notificationType.INPROGRESS);
    }, [ createNotification, translate ]);

    const tenantCreate = useCallback(async (data: ICreateEditTenantPayload) => {
        const {
            name, operationId, id,
        } = await createTenant({ ...data });
        commandTableOperations.addTenantOperation(operationId, name, id, OperationTypeConstants.TENANT);
        inProgressNotification('CLIENT_TENANT_CREATION_STARTED', data.name);
    }, [ commandTableOperations, inProgressNotification ]);

    const tenantEdit = useCallback(async (data: ICreateEditTenantPayload, tenant: ITenant, changedServicesObj: any) => {
        // TODO: Fix the CustomProperties
        const operationId = await editTenant(tenant.id, {
            name: data.name ?? tenant?.name,
            color: data.color,
            services: changedServicesObj,
            CustomProperties: data.customProperties ?? (data as any).CustomProperties,
        } as any);
        const operationType = changedServicesObj ? OperationTypeConstants.SERVICE : OperationTypeConstants.TENANT;
        const tenantName = data.name ?? tenant?.name;

        commandTableOperations.addTenantOperation(operationId, tenantName, tenant.id, operationType);
        inProgressNotification('CLIENT_TENANT_EDIT_STARTED', data.name);
    }, [ commandTableOperations, inProgressNotification ]);

    const tenantDelete = useCallback(async (tenant: ITenant) => {
        const operationId = await deleteTenant(tenant.id);
        commandTableOperations.addTenantOperation(operationId, tenant.name, tenant.id, OperationTypeConstants.TENANT);
        inProgressNotification('CLIENT_TENANT_DELETION_STARTED', tenant.name);
    }, [ commandTableOperations, inProgressNotification ]);

    const tenantStatusModify = useCallback(async (service: IService, tenant: ITenant, accountGUID: string, status: string) => {
        if (!service) {
            const operationId = await setTenantStatus(tenant.id, status === TenantStatusConstants.ENABLE);
            commandTableOperations.addTenantOperation(operationId as unknown as string, tenant.name, tenant.id,
                OperationTypeConstants.TENANT);
        } else {
            await setServiceStatus(accountGUID, tenant.id, {
                [service.serviceType]:
        status === TenantStatusConstants.ENABLE ? TenantStatusConstants.ENABLED : TenantStatusConstants.DISABLED,
            });
        }

        inProgressNotification(status === TenantStatusConstants.ENABLE
            ? 'CLIENT_TENANT_ENABLE_NOTIFICATION'
            : 'CLIENT_TENANT_DISABLE_NOTIFICATION', tenant.name);
    }, [ commandTableOperations, inProgressNotification ]);

    return {
        tenantCreate,
        tenantEdit,
        tenantDelete,
        tenantStatusModify,
    };
};
