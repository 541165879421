import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import {
    LegacyProductsToUserBundleLicenseMap,
    ProductTypes,
} from '../../../common/constants/Constant';
import {
    getFriendlyName,
    getIconName,
} from '../../../common/constants/ServicesMapping';
import { concurrentLicensesOpted } from '../../../store/selectors';
import type {
    ITenantServiceLicense,
    ITenantServiceProduct,
} from '../../tenants/interfaces/service';
import ConsumptionInfoComponent from '../../tenants/subcomponents/ConsumptionInfoComponent';
import { minMaxAllowedProductQty } from '../../tenants/subcomponents/helpers/AllocationSectionsHelper';
import {
    concurrencyMapping,
    productSubLabel,
    translationCode,
} from '../../tenants/subcomponents/helpers/ManageLicensesHelper';
import UiLicenseToggleComponent from './UiLicenseToggleComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputGroup: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        labelSubGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        inputMargin: { marginBottom: '12px' },
        overAllocatedWarning: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        serviceLicenseHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '34px',
        },
        serviceHeader: { marginLeft: '12px' },
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            paddingLeft: '3px',
            margin: '1px',
        },
        disabledField: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            color: theme.palette.semantic.colorForegroundDisable,
            borderColor: theme.palette.semantic.colorBorderDisabled,
        },
        smallInputLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '12px',
            lineHeight: '16px',
        },
    }),
);

const UiLicenseAllocationPerServiceComponent: React.FC<{
    type?: 'add' | 'edit';
    name: string;
    serviceLicense: ITenantServiceLicense;
}> = ({
    type = 'edit', name, serviceLicense,
}) => {
    const enableRuFallbackConfiguration = useFeatureFlagValue(Features.EnableRuFallbackConfiguration.name);
    const enableRUIntervalMigration = useFeatureFlagValue(Features.EnableRUIntervalMigration.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const theme = useTheme();

    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const products = useMemo(() => serviceLicense.products, [ serviceLicense ]);

    const {
        register, watch, formState: { errors },
    } = useFormContext();

    const disableCallback = useCallback((product: ITenantServiceProduct) =>
        ({ disableInput: concurrentLicenseEnabled && watch(`${name}.${concurrencyMapping[product.code]}`, 0) > 0 })
    , [ concurrentLicenseEnabled, name, watch ]);

    const minMaxCallback = useCallback((product: ITenantServiceProduct) => minMaxAllowedProductQty(product), []);

    const getTooltip = useCallback((disableInput: boolean, newLicensingUIEnabled: boolean) => {
        if (!disableInput) {
            return '';
        }

        return newLicensingUIEnabled
            ? translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_MULTIUSER' })
            : translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_CONCURRENT' });
    }, [ translate ]);

    const getHelperText = useCallback((product: ITenantServiceProduct) => {
        const {
            minQuantity, maxQuantity,
        } = minMaxCallback(product);

        const errorType = (errors as any)?.[name]?.[product.code]?.type;

        if (errorType === 'required') {
            return translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' });
        } else if (errorType === 'min' || (errorType === 'max' && maxQuantity > 0)) {
            return translate({ id: 'CLIENT_WARN_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (errorType === 'max' && maxQuantity === 0) {
            return translate({ id: 'CLIENT_WARN_NO_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (product.available < 0) {
            return (
                <div className={classes.overAllocatedWarning}>
                    {translate(
                        { id: 'CLIENT_TENANTS_LICENSE_OVERALLOCATED' },
                        { 0: Math.abs(product.available) },
                    )}
                    <Tooltip
                        data-cy="overallocation-tooltip"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={translate(
                            { id: 'CLIENT_LICENSE_OVERALLOCATION_TOOLTIP' },
                        )}
                        arrow
                    >
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                </div>
            );
        }
        return null;
    }, [ name, classes, errors, minMaxCallback, translate ]);

    const mappedProductCallback = (product: ITenantServiceProduct) => {
        const mappedProduct = LegacyProductsToUserBundleLicenseMap[product.code];
        const newLicensingUIEnabled = !!mappedProduct;
        const productCodeForTranslation = newLicensingUIEnabled
            ? mappedProduct.slice(0, -2)
            : product.code;
        const productLabel = newLicensingUIEnabled
            ? mappedProduct.slice(-2)
            : productSubLabel(translationCode(productCodeForTranslation));
        const startDate = product.startDate ? new Date(product.startDate * 1000) : new Date();
        const endDate = product.endDate ? new Date(product.endDate * 1000) : new Date();

        return {
            newLicensingUIEnabled,
            productCodeForTranslation,
            productLabel,
            startDate,
            endDate,
        };
    };

    return <div>
        <div className={classes.serviceLicenseHeader}>
            <PortalCustomIcon
                name={getIconName(serviceLicense.serviceType)}
                size="18px" />
            <UiText
                className={clsx(classes.inputLabel, classes.serviceHeader)}
                variant={FontVariantToken.fontSizeH3}
                data-cy="license-service-type">
                {getFriendlyName(serviceLicense.serviceType)}
            </UiText>
        </div>
        <Divider />
        {products.map((product, j) => {
            const { disableInput } = disableCallback(product);

            const {
                minQuantity, maxQuantity,
            } = minMaxCallback(product);

            const {
                newLicensingUIEnabled, productCodeForTranslation, productLabel, startDate, endDate,
            } = mappedProductCallback(product);

            return (
                <div
                    key={j}
                    className={clsx(classes.input, classes.inputColumn, classes.inputMargin)}>
                    <div className={classes.inputGroup}>
                        <div className={classes.labelSubGroup}>
                            <UiText
                                className={classes.inputLabel}
                                id={`${productCodeForTranslation}Label`}>
                                {translate({ id: `CLIENT_${translationCode(productCodeForTranslation)}` })}
                            </UiText>
                            {productLabel && (
                                <UiText className={clsx(classes.inputLabel, classes.subText)}>
                                    {translate({ id: `CLIENT_${productLabel}` })}
                                </UiText>
                            )}
                            {enableRUIntervalMigration && product.startDate > 0 && product.endDate > 0 && (
                                <UiText
                                    className={classes.smallInputLabel}
                                    data-cy={`${productCodeForTranslation}Interval`}>
                                    <FormattedDate
                                        value={Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate())}
                                        year="numeric"
                                        month="short"
                                        day="numeric"
                                        timeZone="UTC"
                                    />
                                    {' - '}
                                    <FormattedDate
                                        value={Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate())}
                                        year="numeric"
                                        month="short"
                                        day="numeric"
                                        timeZone="UTC"
                                    />
                                </UiText>
                            )}
                        </div>
                        <Tooltip
                            arrow
                            title={getTooltip(disableInput, newLicensingUIEnabled)}
                        >
                            <TextField
                                inputProps={{
                                    className: clsx({ [classes.disabledField]: disableInput }),
                                    min: 0,
                                    ...register(`${name}.${product.code}`, {
                                        validate: {
                                            required: (value) => !Number.isNaN(parseInt(value)),
                                            min: (value) => parseInt(value) === product.quantity || value >= minQuantity,
                                            max: (value) => parseInt(value) === product.quantity || value <= maxQuantity,
                                        },
                                    }),
                                }}
                                InputProps={{ readOnly: disableInput }}
                                error={!!(errors as any)[name]?.[`${product.code}`] || product.available < 0}
                                type="number"
                                helperText={getHelperText(product)}
                                variant="outlined"
                                size="small"
                                style={{ maxWidth: '100px' }}
                                aria-labelledby={`${productCodeForTranslation}Label`}
                            />
                        </Tooltip>
                    </div>
                    {product.type === ProductTypes.ConsumptionInterval ? (
                        <>
                            <ConsumptionInfoComponent
                                product={product}
                                isNewTenant={type === 'add'} />
                            {type === 'edit' && product.code === 'RU' && enableRuFallbackConfiguration && (
                                <UiLicenseToggleComponent
                                    name="fallbackConfiguration.enabled"
                                    switchName="fallbackConfiguration.enabled"
                                    toggleLabelValue="CLIENT_LICENSE_FALLBACK_TOGGLE"
                                    tooltipValue="CLIENT_LICENSE_FALLBACK_TOOLTIP" />
                            )}
                        </>
                    ) : (
                        <UiText style={{
                            color: product.available > 0
                                ? theme.palette.semantic.colorSuccessText
                                : theme.palette.semantic.colorForeground,
                        }}>
                            {translate({ id: 'CLIENT_LICENSES_AVAILABLE' }, { count: product.available })}
                        </UiText>
                    )}
                </div>
            );
        })}
    </div>;
};

export default UiLicenseAllocationPerServiceComponent;
