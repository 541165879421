import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { CustomerManagedKeySwitchLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import {
    kmsUrl,
    saveKmsConfig,
} from '../../../../services/identity/KeyManagementService';
import { accountGlobalId } from '../../../../store/selectors';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: { display: 'flex' },
        buttonInner: { width: '100px' },
        footer: {
            marginTop: '1em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
);

const EncryptionWarningDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, refreshCallback, errorCallback,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);
    const [ loading, setLoading ] = useState(false);
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'zh-TW', 'pt', 'tr', 'ru' ] });

    const handleClick = useCallback(async () => {
        try {
            setLoading(true);
            await saveKmsConfig(kmsUrl, {
                partitionGlobalId,
                keyType: 0,
            });
            refreshCallback();
        } catch (error) {
            errorCallback(error);
        } finally {
            closeDialog();
            setLoading(false);
        }
    }, [ closeDialog, errorCallback, partitionGlobalId, refreshCallback ]);

    const formatStrong = useCallback((chunk: React.ReactNode[]) => (
        <strong>
            {chunk}
        </strong>
    ), []);

    return (
        <>
            <UiText data-cy="encryption-warning-message">
                <FormattedMessage
                    id='CLIENT_ENCRYPTION_WARNING_DESCRIPTION'
                    values={{ strong: formatStrong }}
                />
            </UiText>
            <br />
            <UiText data-cy="encryption-warning-ask">
                {translate({ id: 'CLIENT_ASK_CONFIRMATION' })}
            </UiText>

            <div className={classes.footer}>
                <Link
                    data-cy='encryption-warning-learn-more'
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getLocalizedLink({ articleSlug: CustomerManagedKeySwitchLink })}
                    underline="none">
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </Link>

                <div className={classes.buttonContainer}>

                    <Button
                        className={clsx(classes.button, classes.buttonInner)}
                        color="primary"
                        data-cy="encryption-warning-cancel-button"
                        onClick={() => closeDialog()}
                        variant="outlined"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        className={classes.button}
                        data-cy="encryption-warning-confirmation-button"
                        innerButtonClass={classes.buttonInner}
                        loading={loading}
                        onClick={() => handleClick()}
                        variant="contained"
                    >
                        {translate({ id: 'CLIENT_YES' })}
                    </UiProgressButton>
                </div>
            </div>
        </>
    );
};

export default EncryptionWarningDialogBody;
