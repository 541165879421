import { UiLoader } from '@experiences/ui-common';
import {
    hasAuthParams,
    useAuth,
} from '@uipath/auth-react';
import React from 'react';
import {
    Navigate,
    useParams,
} from 'react-router-dom';

const AuthCallback = () => {
    const { error } = useAuth();
    const { accountName } = useParams() as { accountName?: string };

    // redirect if user is stuck or error has occurred
    if (!hasAuthParams() || error) {
        return <Navigate
            data-cy='auth-callback-navigate'
            to={
                `${accountName ? `/${accountName}` : ''}/portal_/cloudrpa`
            }
        />;
    }

    return <UiLoader type="full" />;
};

export default AuthCallback;
