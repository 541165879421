import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

export const exportLogsUrl = `/api/orchestrator/logsexport`;

type StorageType = 'AzureBlob' | 'AwsS3' | 'GcpStorage';

interface IOrchestratorExportLogsData {
    connectionDetail?: any;
    tenantKey?: string;
    connectionString?: string;
    containerName: string;
    storageType?: StorageType;
    RegionSystemName?: string;
}

export async function getLogsExportDetails({
    url, tenantKey,
}: { url: string; tenantKey: string }) {
    return await get<IOrchestratorExportLogsData>(url, { urlParams: { tenantKey } });
}

export function postLogsExportDetails(body: IOrchestratorExportLogsData) {
    return post<IOrchestratorExportLogsData>(exportLogsUrl, { body });
}

export function deleteLogsExportDetails(tenantKey: string) {
    return axiosDelete(`${exportLogsUrl}?tenantKey=${tenantKey}`);
}
