import * as RouteNames from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import type { ISettingRoute } from '../../common/interfaces/route';

export const HostSettingsRoutes: ISettingRoute[] = [
    {
        text: 'CLIENT_ORGANIZATIONS',
        path: RouteNames.Organizations,
        subRoutes: [ RouteNames.OrganizationsAdd, RouteNames.OrganizationsLicense ],
    },
    {
        text: 'CLIENT_USERS',
        path: RouteNames.Users,
        subRoutes: [ RouteNames.AuthSettings, RouteNames.UserAddEdit ],
    },
    {
        text: 'CLIENT_LICENSES',
        path: RouteNames.Licensing,
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        path: RouteNames.AuditLogs,
        subRoutes: [ RouteNames.AuditLogDialog ],
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        path: RouteNames.Audit,
    },
    {
        text: 'CLIENT_ACCOUNT_SETTINGS_ACTION',
        path: RouteNames.OrganizationAdminHome,
        hide: true,
    },
    {
        text: 'CLIENT_SECURITY_SETTINGS',
        path: RouteNames.AuthSettings,
        subRoutes: [
            RouteNames.SecuritySettings,
            RouteNames.AuthSettingsConfigureAD,
            RouteNames.AuthSettingsConfigureAAD,
            RouteNames.AuthSettingsConfigureGoogle,
            RouteNames.AuthSettingsConfigureSaml,
            RouteNames.SecuritySettingsConfigure,
            RouteNames.AuthSettingsPasswordPolicy,
            RouteNames.SessionPolicy,
        ],
    },
    {
        text: 'CLIENT_GLOBAL_SETTINGS',
        path: RouteNames.EmailSettings,
        subRoutes: [ ...(process.buildConfigs.enableLoginCustomization ? [ RouteNames.LoginCustomization ] : []) ],
    },
    {
        text: 'CLIENT_ORGANIZATION_HOME',
        path: RouteNames.OrganizationHome,
        hide: true,
    },
];

export const HideTenantShellRoutes = [
    RouteNames.TenantMigration,

    routePaths.adminTenantSettingsMigration,
];
