import type { Persona } from '@experiences/interfaces';

/** PERSONA_PRIORITY_ORDER does not handle AUTOMATION_EXPRESS_ADMIN as it is a special case
 * there would be no collision from AUTOMATION_EXPRESS_ADMIN */
export const PERSONA_PRIORITY_ORDER: Persona[] = [
    'ADMIN',
    'RPA_DEVELOPER',
    'CITIZEN_DEV',
    'TPM',
    'BUSINESS_ANALYST',
    'BUSINESS_USER',
    'AUTOMATION_EXPRESS_USER',
    'TESTER',
    'UNKNOWN',
];
