import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { useEcommerce } from '../helpers/useEcommerce';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        nonTaxableEntity: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '35px',
            textAlign: 'justify',
        },
    }),
}));

const EcommerceEuLegalText: React.FC = () => {
    const classes = useStyles();
    const { isEuropeanUnionCountry } = useEcommerce();
    const { formatMessage: translate } = useIntl();

    return (<div>
        {isEuropeanUnionCountry && <UiText
            className={classes.nonTaxableEntity}
            data-cy="non-taxable-entity">
            {translate(
                { id: 'CLIENT_EU_NON_TAXABLE_LEGAL_ENTITY' },
                {
                    0: (
                        <a
                            className={classes.a}
                            href="https://www.uipath.com/company/contact-us"
                            data-cy="contact-sales-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate({ id: 'CLIENT_HERE' })}
                        </a>
                    ),
                },
            )}
        </UiText>}
    </div>);
};

export default EcommerceEuLegalText;
