import { ContactUsLinks } from '@experiences/constants';
import { useLocalizedLinks } from '@experiences/util';
import { useCallback } from 'react';

import { useEcommerceTelemetry } from '../helpers/EcommerceHelpers';

const useEcommerceContactSalesLinkViewModel = () => {
    const logEcommerceEvent = useEcommerceTelemetry();
    const getLocalizedLink = useLocalizedLinks();

    const navigateToContactSales = useCallback(() => {
        logEcommerceEvent('SelectPlan.ContactSales');
        window.open(getLocalizedLink(ContactUsLinks), '_blank');
    }, [ logEcommerceEvent, getLocalizedLink ]);

    const onKeyDown = useCallback((e: { key: string }) => {
        if (e.key === 'Enter') {
            navigateToContactSales();
        }
    }, [ navigateToContactSales ]);

    return {
        navigateToContactSales,
        onKeyDown,
    };
};

export default useEcommerceContactSalesLinkViewModel;
