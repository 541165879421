import orderBy from 'lodash/orderBy';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    getGroups,
    groupUrl,
} from '../../services/identity/GroupService';
import { accountGlobalId } from '../../store/selectors';
import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import { GroupTypes } from '../constants/GroupTypes';
import type { IGroup } from '../interfaces/cis/group';

const useSimpleGroup = (fetchGroups = false) => {
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data, isLoading,
    } = useSWR(
        fetchGroups ? {
            url: groupUrl,
            partitionGlobalId,
        } : null,
        getGroups,
    );

    const groups = useMemo(() =>
        orderBy(data, [ 'type', d => (d.type === GroupTypes.CUSTOM ? d.name : 0) ], [ 'asc', 'asc' ]).map(group => ({
            ...group,
            name: decodeSanitizedHtml(group.name),
        }) as IGroup), [ data ]);

    const getGroupsForUser = useCallback(
        (userGroupIds: string[] = [], useId?: boolean) =>
            groups.filter(group => userGroupIds.indexOf(group.id) > -1).map(group => (useId ? group.id : group.name)),
        [ groups ],
    );

    return {
        loading: isLoading,
        groups,
        getGroupsForUser,
    };
};

export default useSimpleGroup;
