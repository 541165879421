import {
    Features,
    setLocalFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ITenant } from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { ApToggleSwitch } from '@uipath/portal-shell-react';
import React, { useCallback } from 'react';

import * as RouteNames from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';

export const AuditPreviewToggle = ({ tenant }: { tenant?: ITenant }) => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);
    const EnableUnifiedAuditExperienceToggle = useFeatureFlagValue(Features.EnableUnifiedAuditExperienceToggle.name);

    const toggleAuditExperience = useCallback(() => {
        const nextValue = !EnableUnifiedAuditExperience;

        setLocalFeatureFlagValue(Features.EnableUnifiedAuditExperience.name, String(nextValue));

        navigate(nextValue ? routePaths.adminAudit : getRoute(RouteNames.AuditLogs), { replace: true });
    }, [ EnableUnifiedAuditExperience, getRoute, navigate ]);

    return EnableUnifiedAuditExperienceToggle && !tenant ? <ApToggleSwitch
        color='primary'
        label='New audit experience'
        checked={EnableUnifiedAuditExperience}
        onClick={() => toggleAuditExperience()} /> : undefined;
};
