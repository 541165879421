import { AccountLicense } from '@experiences/constants';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import type { ClassNameMap } from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isAdminSelector } from '../../../store/selectors';

export const FormErrorHandler: React.FC<{
    error?: any;
    submitError: string;
    setSubmitError: (hasError: string) => void;
    currentAccountType: keyof typeof AccountLicense;
}> = ({
    error,
    submitError,
    setSubmitError,
    currentAccountType,
}) => {
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);

    return ((!isAdmin ||
        AccountLicense[currentAccountType] === AccountLicense.COMMUNITY ||
        (!!error && error.response?.status !== 404) ||
        submitError)
        ? (
            <div style={{ marginLeft: '24px' }}>
                {submitError ? (
                    <UiText>
                        {submitError}
                    </UiText>
                ) : (
                    <UiText>
                        {!isAdmin || AccountLicense[currentAccountType] === AccountLicense.COMMUNITY
                            ? translate({ id: 'CLIENT_ACCESS_DENIED' })
                            : translate({
                                id: error.response?.status === 403
                                    ? 'CLIENT_ACCESS_DENIED_TEXT' : 'CLIENT_UNKNOWN_ERROR_FROMBACKEND',
                            })}
                    </UiText>
                )}
                {isAdmin && AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setSubmitError('');
                        }}
                        style={{ marginTop: '12px' }}
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                )}
            </div>)
        : null
    );
};

export const FormActionButtons: React.FC<{
    showActions: boolean;
    classes: ClassNameMap;
    loading: boolean;
    isSubmitDisabled: boolean;
    close: () => void;
    onSubmit?: (...props: any) => void;
}> = ({
    showActions = true,
    classes,
    loading,
    isSubmitDisabled,
    close = () => {},
    onSubmit = () => {},
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        showActions ? (
            <div className={classes.actions}>
                <div className={classes.formButtons}>
                    <Button
                        className={classes.cancelButton}
                        onClick={close}
                        color="primary"
                        data-cy="configure-logs-cancel"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        loading={loading}
                        onClick={onSubmit}
                        variant="contained"
                        data-cy="configure-logs-save"
                        disabled={isSubmitDisabled}
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            </div>
        ) : null
    );
};
