import type { ResolveDirectoryEntityType } from '@experiences/interfaces';

export const determineIcon = (type?: ResolveDirectoryEntityType) => {
    if (type === 'LocalUser') {
        return 'basic_auth_user icon-basic_auth_user';
    } else if (type === 'LocalGroup') {
        return 'local_group icon-local_group';
    } else if (type === 'RobotAccount') {
        return 'robot icon-robot';
    } else if (type === 'DirectoryUser') {
        return 'directory_user icon-directory_user';
    } else if (type === 'DirectoryGroup') {
        return 'directory_group icon-directory_group';
    }

    return '';
};
