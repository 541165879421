/* eslint-disable max-len */
import { UiText } from '@experiences/ui-common';
import { getShortNumber } from '@experiences/util';
import ReportProblem from '@mui/icons-material/ReportProblem';
import MuiLinearProgress from '@mui/material/LinearProgress';
import type { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
    withStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const commonStyles = (theme: Theme) => ({
    root: {
        height: 16,
        borderRadius: 16,
    },
    colorPrimary: { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
    bar: {
        borderRadius: 16,
        backgroundColor: theme.palette.semantic.colorPrimary,
    },
});

const ConsumableProgressBar = withStyles(theme =>
    createStyles({
        ...commonStyles(theme),
        dashed: {
            animation: 'none',
            backgroundImage: `radial-gradient(${theme.palette.semantic.colorSecondaryPressed} 0%, ${theme.palette.semantic.colorSecondaryPressed} 0%, transparent 0%)`,
        },
        bar2Buffer: { backgroundColor: theme.palette.semantic.colorPrimaryLighter },
    }),
)(MuiLinearProgress);

const useStyles = makeStyles(theme =>
    createStyles({
        quantityOnProgressBar: {
            position: 'absolute',
            color: theme.palette.semantic.colorBackgroundSecondary,
            top: 0,
            transform: 'translateX(-50%)',
            fontWeight: 400,
            lineHeight: '16px',
            fontSize: '10px',
        },
        availableQuantity: { color: theme.palette.semantic.colorForeground },
        progressBar: { position: 'relative' },

        overconsumptionWarning: {
            top: 0,
            color: theme.palette.semantic.colorWarningIcon,
            fontWeight: 400,
            fontSize: '12px',
            transform: 'translateX(-50%) translateY(2px)',

        },
    }),
);

export const UiConsumableProgressBar: React.FC<{
    consumed: number;
    total: number;
    consumableCode: string;
    ariaLabelledBy?: string;
    ariaDescribedBy?: string;
}> = ({
    consumed, total, consumableCode, ariaLabelledBy, ariaDescribedBy,
}) => {
    const { formatMessage: translate } = useIntl();

    const classes = useStyles();
    const isOverConsumption = consumed > total;
    const consumedPercentage = useMemo(() => Math.round((consumed / total) * 100), [ consumed, total ]);
    const cappedConsumedPercentage = useMemo(() => Math.min(consumedPercentage, 100), [ consumedPercentage ]);

    const consumedText = useMemo(() =>
        translate(
            { id: 'CLIENT_CONSUMABLE_TOOLTIP_CONSUMED' },
            { 0: consumed },
        )
    , [ translate, consumed ]);

    const availableText = useMemo(() =>
        translate(
            { id: `CLIENT_CONSUMABLE_TOOLTIP_AVAILABLE` },
            { 0: Math.max(total - consumed, 0) },
        )
    , [ translate, total, consumed ]);

    const overconsumptionText = useMemo(() =>
        translate(
            { id: 'CLIENT_CONSUMABLE_TOOLTIP_OVERCONSUMPTION' },
            { 0: Math.max(consumed - total, 0) },
        )
    , [ translate, consumed, total ]);

    const overconsumptionProgressBar = useMemo(() =>
        translate(
            { id: 'CLIENT_CONSUMABLE_OVERCONSUMPTION' },
            {
                0: getShortNumber(consumed),
                1: getShortNumber(consumed - total),
            },
        )
    , [ translate, consumed, total ]);

    return (
        <Tooltip
            title={
                <>
                    <p>
                        {consumedText}
                    </p>
                    <p>
                        {availableText}
                    </p>
                    {consumed > total && <p>
                        {overconsumptionText}
                    </p>}
                </>
            }
            data-testid="tooltip"
            aria-label={consumedText}
            aria-describedby={consumedText + ' ' + availableText + ' ' + overconsumptionText}>
            <div className={classes.progressBar}>
                <ConsumableProgressBar
                    data-testid="regular"
                    variant="buffer"
                    value={cappedConsumedPercentage}
                    valueBuffer={cappedConsumedPercentage}
                    tabIndex={0}
                    aria-labelledby={ariaLabelledBy}
                    aria-valuetext={consumedText + ' ' + availableText + ' ' + overconsumptionText}
                />
                {consumedPercentage > 10 && consumedPercentage <= 100 && <UiText
                    className={classes.quantityOnProgressBar}
                    style={{ left: `${cappedConsumedPercentage / 2}%` }}>
                    {getShortNumber(consumed)}
                </UiText>}
                {isOverConsumption && <UiText
                    className={classes.quantityOnProgressBar}
                    style={{ left: `${cappedConsumedPercentage / 2}%` }}>
                    <ReportProblem
                        className={classes.overconsumptionWarning}
                    />
                    {overconsumptionProgressBar}
                </UiText>}
                {consumedPercentage <= 90 && <UiText
                    className={clsx(classes.quantityOnProgressBar, classes.availableQuantity)}
                    style={{ left: `${(100 + cappedConsumedPercentage) / 2}%` }}>
                    {getShortNumber(total - consumed)}
                </UiText>}
            </div>
        </Tooltip>
    );
};
