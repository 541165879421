/* eslint-disable no-unused-vars */

export interface IProductBase {
    code: string;
    quantity: number;
    isProrated?: boolean;
}

export interface IProduct extends IProductBase {
    minQuantity?: number;
    maxQuantity?: number;
}

export interface ICustomPlanForm {
    productQuantities: { [code: string]: number };
}

export interface IUpdateLicenseQuantityForm extends ICustomPlanForm {
    termsAndConditionsAccepted: boolean;
}

interface ISkuPackageBase {
    price?: number;
    type: string;
    planType: string;
    products: IProductBase[];
}

export interface ISkuPackage extends ISkuPackageBase {
    currency: string;
}

export enum Currency {
    USD = 'USD',
    EURO = 'EURO',
    JPY = 'JPY',
}

export interface IPackagesConfig {
    currency: Currency;
    packages: IPackage[];
}

export interface IProductPrice {
    planType?: string;
    price: number;
    code: string;
}

export interface IPackage {
    type: string;
    productQuantities: IProduct[];
    isCustomizable: boolean;
}

export interface INonUsTaxes {
    validVatTaxResponse: ITaxResponse;
    invalidVatTaxResponse: ITaxResponse;
}

export interface ITaxResponse {
    taxValue: number;
    taxRate: number;
    isValid: boolean;
    serviceDown: boolean;
}

export interface IErrorWithStatus extends Error {
    status: number;
    translationId?: string;
    description?: string;
}

export interface IProductPriceOptions {
    code: string;
    planType: string;
    prices: { [currency: string]: number };
    productCategory: string;
}

export interface ISkuPackageDetails extends ISkuPackageBase {
    prices: { [planType: string]: { [currency: string]: number } };
    defaultCurrency: string;
    stripePublishableKey?: string;
    tax?: ITaxResponse;
    taxError?: IErrorWithStatus;
    isEuCountry?: boolean;
}

export type PaymentStatus = 'PAYMENT_INITIALIZED'
| 'PAYMENT_PROCESSED'
| 'PAYMENT_FAILED'
| 'PAYMENT_PENDING_FOR_INCOMPLETE_SUBSCRIPTION'
| 'PAYMENT_OPEN';
export interface IHistoryStatus {
    hasHistoricalData: boolean;
    firstPaymentStatus?: PaymentStatus;
    recurringPaymentStatus?: PaymentStatus;
    incrementalPaymentStatus?: PaymentStatus;
}

export enum BillingEntityCode {
    RO = 'RO',
    US = 'US',
    JP = 'JP',
}

export interface IPlanDetails {
    planType: string;
    nextPayment: string;
    upcomingBill: number;
    startDate: string;
    endDate: string;
    currency: string;
    subscriptionId: string;
    subscriptionScheduleId: string;
    billingEntityCode: BillingEntityCode;
    shouldAllowDecrease: boolean;
    hasLicenceUpdateSubscriptionSchedule: boolean;
    hasPriceChangesSubscriptionSchedule: boolean;
}

export interface ISubscriptionDetails {
    products?: IProductBase[];
    currency: string;
    planType: string;
    paymentMethod?: string;
    countryCode: string;
    addressDetails: IAddressDetails;
    taxId?: string;
    leadDetails: IBusinessInfoPayload;
    organizationName?: string;
    googleAnalyticsId?: string;
    region?: string;
}

export interface ISubscriptionUpdate {
    products: IProductBase[];
}

export interface IBusinessInfoPayload {
    firstName: string;
    lastName: string;
    country: string;
    state?: string;
    businessEmail: string;
    companyName: string;
    jobTitle: string;
    userLanguage: string;
}

export interface IMarketoInfo {
    id?: number;
    cloudPlatformUserId?: string;
    email?: string;
    businessEmail?: string;
    firstName?: string;
    lastName?: string;
    formReceiveMarketingCommunications?: boolean;
    country?: string;
    cloudplatformLanguage?: string;
    Last_Interaction__c?: string;
    Last_Interaction_Details__c?: string;
    cloudplatformAccountID?: string;
    organizationName?: string;
    cloudplatformLicenseCode?: string;
    cloudplatformAccountLicenseExpiresAt?: Date;
    cloudplatformAccountLicenseSubscriptionCode?: string;
    accountURL?: string;
    state?: string;
    jobTitle?: string;
    status?: string;
    reasons?: [
        {
            code?: string;
            message?: string;
        },
    ];
}

export interface ISubscriptionForm {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2?: string;
    country: string;
    currency: string;
    city: string;
    state?: string;
    zipcode?: string;
    taxId?: string;
    nameOnCard: string;
    businessEmail?: string;
    companyName?: string;
    jobTitle?: string;
    logicalName?: string;
    region?: string;
    termsAndConditionsAccepted: boolean;
    marketingConditionsAccepted?: boolean;
}

export interface IAddressDetails {
    line1: string;
    line2?: string;
    country: string;
    city: string;
    state?: string;
    postalcode?: string;
}

export interface IOrderedProductDetails {
    code: string;
    quantity: number;
    totalPrice: number;
    currency: string;
    prorated: boolean;
}

export interface IOrderConfirmationInvoice {
    subtotal: number;
    taxValue: number;
    taxRate: number;
    total: number;
    currency: string;
    created: Date;
    email: string;
    paymentMethodType: string;
    cardBrand: 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown';
    cardLast4: string;
    ibanLast4: string;
    usBankAccountLast4: string;
    incremental?: boolean;
}

export interface IOrderConfirmation {
    status: 'PAYMENT_PROCESSED' | 'PAYMENT_INITIALIZED' | 'PAYMENT_FAILED' | 'PAYMENT_OPEN';
    invoiceData?: IOrderConfirmationInvoice;
    accountName?: string;
    products?: IOrderedProductDetails[];
    organizationId?: string;
    firstPaymentBalanceCheckStatus: 'INITIALIZED' | 'PENDING' | 'VALID' | 'INVALID' | 'TIMED_OUT';
}

export interface ICreateSubscriptionDto {
    stripeSubscriptionId: string;
    subscriptionStatus: string;
    paymentIntentStatus: string;
    paymentIntentClientSecret: string;
    organizationId?: string;
}

export interface IUpdateSubscriptionDto {
    stripeSubscriptionId: string;
    paymentIntentStatus: string;
    paymentIntentClientSecret: string;
    defaultPaymentMethodId: string;
    orderConfirmation: IOrderConfirmation;
    isLicenseDecreaseExecuted: boolean;
    isLicenseIncreaseExecuted: boolean;
}

export interface IAddressValidationResult {
    isValid: boolean;
}

export interface IVatValidation {
    serviceDown: boolean;
    vatCode: string;
    validationStatus: string;
    country: string;
}

export interface ICheckOrganizationResponse {
    ready: boolean;
    organizationName?: string;
}

export interface IEcommerceCheckoutComponentState {
    skuPackage?: ISkuPackage;
    billingInfo?: ISubscriptionForm;
    selectedCurrency?: string;
    existingMarketoData: IBusinessInfoPayload | undefined;
    productsPricesInAllCurrencies: IProductPriceOptions[] | undefined;
}

export interface IEcommerceCheckoutFormState {
    loading: boolean;
    paymentElementFormCompleted: boolean;
    termsAndConditionsAccepted: boolean;
    checkoutFormRef: any;
}

export interface IProductQuantityDifference {
    value: number;
    sign: '+' | '-';
}

export interface IUpcomingInvoiceRequest {
    products: IProductBase[];
}

export interface IUpcomingInvoiceResponse {
    productPrices: IOrderedProductDetails[];
}

export interface IProductQuantityPrice extends IProductBase {
    quantity: number;
    isProrated?: boolean;
    proratedPrice?: number;
}

export interface IEcommerceSuccess {
    title: string;
    line1: string;
    line2?: string;
    button: 'OK' | 'DISMISS';
}

export interface IDirectBuyData {
    productQuantities: { [key: string]: number };
    commitmentType: string;
    currency: string;
}

export interface IOrderSummary {
    price: number;
    currency: string;
}

export interface IProductAndPricesWithDefaultCurrency {
    defaultCurrency: string;
    products: IProductPrices[];
}
export interface IProductPrices {
    code: string;
    prices: { [key: string]: number };
    planType: string;
}

export interface ILicensePendingChange {
    nextPayment: string;
    changedOn: string;
    changedByFirstName: string;
    changedByLastName?: string;
    subscriptionPriceIgnoringPendingChange: number;
    currency: string;
    pendingChangeItems: ILicensePendingChangeItem[];
}

export interface ILicensePendingChangeItem {
    productCode: string;
    currentQuantity: number;
    plannedQuantity: number;
}

export interface IStripeIntegrationConfig {
    countryCodeToPublicKeyMapping: { [key: string]: string };
}

export interface IMonthlyUpsellRequest {
    licenseCode: string;
}

export interface IMonthlyUpsellStartDateValidation {
    isLicenseActivationValid: boolean;
    licenseStartDate: string;
}
