import type { ICard } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { ReactComponent as LicensesAdmin } from '../../images/licensesAdmin.svg';
import { ReactComponent as SettingsAdmin } from '../../images/settingsAdmin.svg';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../services/organization/OnPremOrganizationService';
import {
    accountType,
    isHostModeSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from './AdminBreadCrumbs';

const useStyles = makeStyles(() =>
    createStyles({
        cardIcon: {
            width: '30px',
            marginRight: '4px',
        },
    }),
);

const OrganizationHome: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const isLicenseExpired = useLicenseExpired();

    const isHost = useSelector(isHostModeSelector);
    const AccountType = useSelector(accountType);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const { organizationId } = useParams() as { organizationId: string };

    const { data: organizations } = useSWR(
        !process.buildConfigs.hideForMSI ? { url: `${onPremOrganizationManagementUri}/getAllOrganizations` } : null,
        getAllOnPremOrganizations,
    );

    const organizationName = useMemo(() => organizations?.results.find(orgIterator => orgIterator.id === organizationId)?.name ?? ''
        , [ organizationId, organizations?.results ]);

    const AccountPlan = useMemo(() => {
        switch (AccountType) {
            case 'ENTERPRISE':
                return 'ENTERPRISE_PLAN';
            case 'TRIAL':
                return 'TRIAL_PLAN';
            case 'UNLICENSED':
                return 'UNLICENSED_PLAN';
            default:
                return 'COMMUNITY_PLAN';
        }
    }, [ AccountType ]);

    const cardList: ICard[] = useMemo(() => [
        {
            title: 'CLIENT_LICENSES',
            titleIcon: <LicensesAdmin />,
            description: 'CLIENT_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.HostOrganizationLicense.replace(':organizationId', organizationId)),
            chipText: !isHost ? `CLIENT_${AccountPlan}` : '',
            dataCy: 'org-settings-licenses-card',
            disabled: isLicenseExpired || isUnlicensedMode,
            alt: translate({ id: 'CLIENT_LICENSES' }),
        },
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: <SettingsAdmin />,
            description: 'CLIENT_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.HostOrganizationSettings.replace(':organizationId', organizationId)),
            dataCy: 'org-settings-global-settings-card',
            alt: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ AccountPlan, getRoute, isHost, isLicenseExpired, isUnlicensedMode, organizationId, translate ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationHome.replace(':organizationId', organizationId),
            name: organizationName,
        },
    ], [ organizationId, organizationName ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            maxWidth='1100px'>
            <UiCardGrid
                maxCardWidth="338px"
                style={{
                    marginTop: '20px',
                    justifyItems: 'center',
                }}>
                {cardList.map((card, i) =>
                    !card.invisible &&
                    <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={typeof card.titleIcon === 'string'
                            ? <img
                                className={classes.cardIcon}
                                src={card.titleIcon}
                                alt='accountsAndGroups'
                                style={{ width: '40px' }} />
                            : card.titleIcon}
                        description={card.description}
                        disabled={card.disabled}
                        chipText={card.chipText}
                        onClickLink={() => {
                            let route;
                            if (typeof card.onClickLink === 'string') {
                                route = card.onClickLink;
                            } else {
                                route = card.onClickLink();
                            }
                            navigate(route);
                        }}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default () => (
    <>
        <OrganizationHome />
        <UiSuspensefulOutlet />
    </>
);
