import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import { daysCategory } from '../../../common/constants/Constant';

interface IDurationDropDownProps {
    callback: (day: number) => void;
}

export const DurationDropDown = ({ callback }: IDurationDropDownProps) => {
    const { formatMessage: translate } = useIntl();

    const getDaysList = useMemo(() => {
        const dayList = [];
        dayList.push({
            id: daysCategory.DAY_7,
            text: translate({ id: 'CLIENT_DAYS_SELECTED' }, { number: '7' }),
        });
        dayList.push({
            id: daysCategory.DAY_30,
            text: translate({ id: 'CLIENT_DAYS_SELECTED' }, { number: '30' }),
        });
        dayList.push({
            id: daysCategory.DAY_60,
            text: translate({ id: 'CLIENT_DAYS_SELECTED' }, { number: '60' }),
        });
        dayList.push({
            id: daysCategory.DAY_90,
            text: translate({ id: 'CLIENT_DAYS_SELECTED' }, { number: '90' }),
        });
        return dayList;
    }, [ translate ]);

    const onChange = useCallback(
        (event: any) => {
            if (event.target.value.startsWith('day_')) {
                const day = parseInt(event.target.value.replace('day_', ''), 10);
                callback(day);
            }
        },
        [ callback ],
    );

    return (
        <TextField
            select
            variant="outlined"
            defaultValue={daysCategory.DAY_7}
            onChange={onChange}
            size="small"
            margin="dense"
            data-cy="duration-dropdown-select"
            aria-label={translate({ id: 'CLIENT_USAGE_DURATION_DROPDOWN_LABEL' })}
        >
            {getDaysList.map(day => (
                <MenuItem
                    key={day.id}
                    value={day.id}
                    data-cy={day.id}>
                    {day.text}
                </MenuItem>
            ))}
        </TextField>
    );
};
