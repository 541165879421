import React from 'react';

import InvalidUrl from '../../component/authentication/InvalidUrl';

const CheckGuard = (tester: ((...args: any[]) => boolean) | boolean, component: JSX.Element) => {
    if (typeof tester === 'function') {
        return tester() ? component : <InvalidUrl />;
    }
    return tester ? component : <InvalidUrl />;
};

export default CheckGuard;
