export const TenantStatusConstants = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    INPROGRESS: 'INPROGRESS',
    ENABLE: 'Enable',
    DISABLE: 'Disable',
    ERROR: 'ERROR',
    ENABLE_STATUS_INPROGRESS: 'ENABLE_STATUS_INPROGRESS',
    DISABLE_STATUS_INPROGRESS: 'DISABLE_STATUS_INPROGRESS',
    DELETION_INPROGRESS: 'DELETION_INPROGRESS',
    UNAVAILABLE: 'UNAVAILABLE',
    UNKNOWN: 'UNKNOWN',
    UPDATING: 'UPDATING',
    FAILED: 'FAILED',
    MAINTENANCE: 'MAINTENANCE',
};

export const serviceOrder = [
    'orchestrator',
    'actions',
    'processes',
    'testmanager',
    'insights',
    'connections',
    'dataservice',
    'aifabric',
    'du',
    'reinfer',
    'taskmining',
    'processmining',
    'automationhub',
    'automationstore',
    'serverlesscontrolplane',
];
