import type { TenantTagsTabType } from '@experiences/interfaces';
import { TenantTagsTab } from '@experiences/interfaces';
import {
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';

import { PortalTagManagementOrganizingResourcesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import TenantTagsTabs from './TenantTagsTabs';
import useTenantTagsViewModel from './TenantTagsViewModel';

const useStyles = makeStyles(() =>
    createStyles({ description: { marginBottom: '8px' } }),
);

const TenantTagsComponent: React.FC<{ type: TenantTagsTabType }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'zh-TW', 'ru' ] });

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            disableGutter={[ 'top' ]}
        >
            <UiText className={classes.description}>
                {translate(
                    { id: 'CLIENT_TAGS_LABEL_DESCRIPTION' },
                    {
                        LEARNMORE: <Link
                            href={getLocalizedLink({ articleSlug: PortalTagManagementOrganizingResourcesLink })}
                            rel="noreferrer"
                            target="_blank"
                            underline='none'
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>,
                    },
                )}
            </UiText>
            <TenantTagsTabs type={type} />
        </UiPageContainer>
    );
};

export const TenantTagsComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const {
        tenantId, tenant,
    } = useTenantTagsViewModel();

    const isProperties = useMatch(
        process.buildConfigs.showForMSI ? {
            path: RouteNames.TenantTagsPropertiesMsi,
            end: false,
        } : {
            path: RouteNames.TenantTagsProperties,
            end: false,
        },
    );

    const tabType = useMemo(() => {
        if (isProperties) {
            return TenantTagsTab.PROPERTIES;
        }
        return TenantTagsTab.LABELS;

    }, [ isProperties ]);

    const breadCrumbLinks = useMemo(() => process.buildConfigs.showForMSI
        ? [
            {
                index: 0,
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                index: 1,
                link: RouteNames.TenantTagsMsi,
                name: translate({ id: 'CLIENT_TAGS' }),
            },
        ]
        : [
            {
                index: 0,
                link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                name: tenant?.name ?? '',
            },
            {
                index: 1,
                link: RouteNames.TenantTags.replace(':tenantId', tenantId),
                name: translate({ id: 'CLIENT_TAGS' }),
            },
        ],
    [ organizationName, tenant?.name, tenantId, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <TenantTagsComponent type={tabType} />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default TenantTagsComponent;
