import {
    UiStorage,
    useAuthContext,
    useInterval,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import {
    DIRECT_BUY_DATA,
    DIRECT_BUY_FLOW,
    DIRECT_BUY_ORDER_CONFIRMATION,
    ECOMMERCE_PAYMENT_FAILED_POPUP_KEY,
    SKU_PACKAGE_DETAILS,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from '../lib/helpers/EcommerceHelpers';
import {
    BuyProConfirmation,
    DirectBuyConfirmation,
} from '../lib/helpers/EcommerceRoutes';
import {
    billingSubscriptionUri,
    getDirectBuyOrderConfirmationDetails,
    getOrderConfirmationDetails,
    isOrganizationReady,
} from '../lib/services/BillingService';
import type { ICheckOrganizationResponse } from './interfaces/ecommerce';

function useEcommerceOrderLoadingViewModel(accountNameFromSelector: string) {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const logEcommerceEvent = useEcommerceTelemetry();
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const retriesRef = useRef(0);
    const numberOfRetries = 6;
    const currentLocation = useLocation();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const { token } = useAuthContext();
    const [ isOrganizationReadyResult, setIsOrganizationReadyResult ] = useState<ICheckOrganizationResponse | null>(null);
    const accountName = useMemo(() => {
        if (accountNameFromSelector) {
            return accountNameFromSelector;
        }
        if (currentLocation) {
            return currentLocation.pathname.split('/')[1];
        }
    }, [ accountNameFromSelector, currentLocation ]);
    const paramStripeSubscriptionId = useMemo(
        () => new URLSearchParams(currentLocation.search).get('stripeSubscriptionId'),
        [ currentLocation.search ]
    );
    const paramOrganizationId = useMemo(
        () => new URLSearchParams(currentLocation.search).get('organizationId'),
        [ currentLocation.search ]
    );
    const paramCountryCode = useMemo(
        () => new URLSearchParams(currentLocation.search).get('countryCode'),
        [ currentLocation.search ]
    );
    const checkIfOrganizationIsReady = useCallback(async () => {
        if (paramOrganizationId) {
            const result = await isOrganizationReady(paramOrganizationId, token);
            setIsOrganizationReadyResult(result);
        }
    }, [ paramOrganizationId, token ]);

    useInterval(
        () => checkIfOrganizationIsReady(),
        isSignupDirectBuyFlow && !isOrganizationReadyResult?.ready ? 1000 : null,
    );

    const {
        data: orderConfirmation, isValidating, mutate,
    } = useSWR(
        paramStripeSubscriptionId ? {
            stripeSubscriptionId: paramStripeSubscriptionId,
            organizationId: paramOrganizationId,
            orgName: accountName,
            accessToken: token,
            countryCode: paramCountryCode,
            url: billingSubscriptionUri,
        } : null,
        ({
            stripeSubscriptionId, organizationId, orgName, countryCode, accessToken, url,
        }: {
            stripeSubscriptionId: string;
            organizationId: string | null;
            orgName?: string;
            countryCode: string | null;
            accessToken: string;
            url: string;
        }) => {
            if (isSignupDirectBuyFlow && organizationId && countryCode) {
                return getDirectBuyOrderConfirmationDetails({
                    url,
                    organizationId,
                    countryCode,
                    stripeSubscriptionId,
                    accessToken,
                });
            }
            if (!isSignupDirectBuyFlow && orgName) {
                return getOrderConfirmationDetails({
                    url,
                    stripeSubscriptionId,
                    accountName: orgName,
                    accessToken,
                });
            }
        },
    );

    useEffect(() => {
        if (paramStripeSubscriptionId && accountName && orderConfirmation && !isValidating) {
            if ([ 'PAYMENT_FAILED' ].includes(orderConfirmation.status)) {
                UiStorage.removeItem(DIRECT_BUY_ORDER_CONFIRMATION);
                UiStorage.removeItem(SKU_PACKAGE_DETAILS);
                UiStorage.setItem(ECOMMERCE_PAYMENT_FAILED_POPUP_KEY, 'true');
                navigate(-1);

            } else if ([ 'INITIALIZED', 'PENDING' ].includes(orderConfirmation.firstPaymentBalanceCheckStatus)
                && retriesRef.current <= numberOfRetries) {
                setTimeout(() => {
                    retriesRef.current = retriesRef.current + 1;
                    mutate();
                }, 5000);
            } else {
                if ([ 'INVALID', 'TIMED_OUT' ].includes(orderConfirmation.firstPaymentBalanceCheckStatus)) {
                    const skuPackage = JSON.parse(UiStorage.getItem(SKU_PACKAGE_DETAILS) ?? '{}');
                    logEcommerceEvent('Billing.PayError', {
                        SelectedPlan: skuPackage?.type,
                        Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                        Error: `Bank account balance check failed because of insufficient funds, with result: ${orderConfirmation.firstPaymentBalanceCheckStatus}`,
                    });
                }

                UiStorage.removeItem(DIRECT_BUY_ORDER_CONFIRMATION);
                UiStorage.removeItem(SKU_PACKAGE_DETAILS);
                UiStorage.removeItem(DIRECT_BUY_DATA);
                if (isDirectBuyInProgressFlow) {
                    if (paramOrganizationId) {
                        orderConfirmation.organizationId = paramOrganizationId;
                    }
                    UiStorage.setItem(DIRECT_BUY_ORDER_CONFIRMATION, JSON.stringify(orderConfirmation));
                    const route = getRoute(DirectBuyConfirmation);
                    navigate(route, { state: { orderConfirmation } });
                } else {
                    const route1 = getRoute(BuyProConfirmation);
                    navigate(route1, { state: { orderConfirmation } });
                }
            }
        }
    }, [
        paramStripeSubscriptionId,
        accountName,
        getRoute,
        orderConfirmation,
        token,
        retriesRef,
        isValidating,
        logEcommerceEvent,
        isDirectBuyInProgressFlow,
        paramOrganizationId,
        mutate,
        navigate,
    ]);

    return {
        accountName,
        paramStripeSubscriptionId,
        paramOrganizationId,
        orderConfirmation,
        isValidating,
    };
}

export default useEcommerceOrderLoadingViewModel;
