import { NavCustomizationSetting } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    CatalogResponse,
    Event,
    Recommendation,
    WidgetContext,
    WidgetItem,
} from '@experiences/interfaces';
import capitalize from 'lodash/capitalize';
import words from 'lodash/words';

import { getGlobalSettings } from '../../services/getGlobalSettings';
import { postEventsCollect } from '../../services/postEventsCollect';
import { filterTemplatesByGlobalSettings } from '../TemplatesWidget';

export async function selection(ctx: WidgetContext) {
    const globalSettings = await getGlobalSettings(ctx.accessToken, [ NavCustomizationSetting.MarketplaceHidden ]);
    const isGlobalSettingsEnabled = filterTemplatesByGlobalSettings(globalSettings);

    return isGlobalSettingsEnabled;
}

export function processRecommendation(catalogResponse: CatalogResponse) {
    if (catalogResponse?.zones &&
        catalogResponse.zones.length > 0 &&
        catalogResponse.zones[0].values &&
        catalogResponse.zones[0].values.length > 0) {

        const artifactIds = catalogResponse.zones[0].values.map((item) => item.id);

        return addRecIdToRecommendation(
            catalogResponse.zones[0].values,
            catalogResponse.zones[0].recId,
            catalogResponse.zones[0].id,
            catalogResponse.zones[0].model,
            artifactIds);
    }

    return [];
}

export function transform(data: Recommendation[]): Array<WidgetItem<Recommendation>> {
    return data.map((item) => ({
        data: item,
        category: item.type ? convertTypeToCategoryText(item.type) : item.source,
        header: item.description?.name,
        description: item.description?.shortDescription,
        icons: item.descIcons,
        actions: [
            {
                id: 'try-it-rec',
                nameKey: 'CLIENT_ACTION_NAME_TRYIT',
                type: 'text',
            },
        ],
    }));
}

export function createResourceUrl(data: Recommendation, ctx: WidgetContext) {
    const cloudUrlDomain = `${window.origin}/${ctx.accountLogicalName}`;
    const showRedirectTemplateLinks = getFeatureFlagValue(Features.EnableSRSRedirectTemplateURL.name);

    if (showRedirectTemplateLinks && data.actions && data.actions['redirect:template']) {
        const redirectPathValue = data.actions['redirect:template'][0]
            .replace('{TENANT_ID}', ctx.tenant)
            .replace('{REC_ID}', data.recId);

        return `${cloudUrlDomain}/${redirectPathValue}`;
    } else if (data.resourceUrl) {
        const parsedResourceUrl = new URL(data.resourceUrl);

        return `${cloudUrlDomain}/marketplace_${parsedResourceUrl.pathname}`;
    }

    return data.resourceUrl;
}

export function openResourceUrl(data: Recommendation, ctx: WidgetContext) {
    const sendRealTimeEvents = getFeatureFlagValue(Features.EnableSRSRealTimeEventsCollection.name);

    if (sendRealTimeEvents) {
        const event: Event = {
            'eventName': 'Artifacts.Clicked',
            'artifactId': data.id,
            'metadata': {
                'ClickedItem': data.id,
                'CloudUserId': ctx.userId,
                'CloudOrganizationId': ctx.organizationGuid,
                'CloudTenantId': ctx.tenant,
                'RecommendationId': data.recId,
                'Model': data.model,
                'RecommendationSource': data.source,
                'ZoneId': data.zoneId,
            },
        };
        postEventsCollect([ event ], ctx);
    }

    window.open(createResourceUrl(data, ctx), '_blank');
}

export function convertTypeToCategoryText(recommendationType: string) {
    switch (recommendationType) {
        case 'StudioWebTemplate':
            return 'Template';
        case 'CustomActivity':
            return 'Activity';
        case 'Process':
            return 'Ready to Go Automation';
        default:
            return words(recommendationType).map((word) => capitalize(word))
                .join(' ');
    }
}

export function addRecIdToRecommendation(
    recs: Recommendation[],
    recId: string,
    zoneId: string,
    model: string,
    artifactIds: string[]) {

    return recs.map((rec) => {
        rec.recId = recId;
        rec.zoneId = zoneId;
        rec.model = model;
        rec.artifactIds = artifactIds;

        return rec;
    });
}
