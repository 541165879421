import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import React, {
    useEffect,
    useState,
} from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { SWRConfig } from 'swr';

import { getRouter } from './Router';
import store from './store';

const Router = () => {
    const [ router, setRouter ] = useState<any>(undefined);

    useEffect(() => {
        getRouter().then(r => setRouter(r));
    }, []);

    return router ? <RouterProvider router={router} /> : null;
};

export const App = () => (
    <Provider store={store}>
        <IntlProvider>
            <ApolloThemeProvider>
                <SWRConfig
                    value={{
                        revalidateOnFocus: false,
                        dedupingInterval: ((window as any).Cypress ? 0 : window.env?.SWR_DEDUPE_INTERVAL) ?? 2000,
                    }}
                >
                    <Router />
                </SWRConfig>
            </ApolloThemeProvider>
        </IntlProvider>
    </Provider>
);
