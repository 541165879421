import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useApolloTheme } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import useCheckLicense from '../../../../common/hooks/useCheckLicense';
import EditNavIconFormComponent from './EditNavIconFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        description: {
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        grid: {
            marginTop: '16px',
            marginBottom: '16px',
            width: '100%',
        },
        icon: {
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px',
            '& svg': { fill: theme.palette.semantic.colorIconDefault },
        },
        title: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        tableHeaderCell: {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            borderTop: 0,
            padding: '10px 0px 10px 20px',
            height: '40px',
            minWidth: '35%',
        },
        tableHeaderCellAction: {
            padding: '10px 20px',
            height: '40px',
            minWidth: '30%',
            display: 'flex',
            justifyContent: 'center',
        },
        tableHeaderText: { color: theme.palette.semantic.colorForeground },
    }),
);

const NavCustomizationForm: React.FC = () => {
    const EnableStudioToggleForEnterpriseAndPro = useFeatureFlagValue(Features.EnableStudioToggleForEnterpriseAndPro.name);
    const EnableMarketplaceToggleForEnterprise = useFeatureFlagValue(Features.EnableMarketplaceToggleForEnterprise.name);
    const HideAppsToggle = useFeatureFlagValue(Features.HideAppsToggle.name);
    const HideMarketplaceToggle = useFeatureFlagValue(Features.HideMarketplaceToggle.name);
    const HideStudioToggle = useFeatureFlagValue(Features.HideStudioToggle.name);
    const HideIntegrationServiceToggle = useFeatureFlagValue(Features.HideIntegrationServiceToggle.name);
    const HideInsightsToggle = useFeatureFlagValue(Features.HideInsightsToggle.name);
    const EnableHideAcademyToggle = useFeatureFlagValue(Features.EnableHideAcademyToggle.name);
    const EnableHideCustomerPortalToggle = useFeatureFlagValue(Features.EnableHideCustomerPortalToggle.name);
    const EnableHideAiCenterToggle = useFeatureFlagValue(Features.EnableHideAiCenterToggle.name);
    const EnableHideAutomationHubToggle = useFeatureFlagValue(Features.EnableHideAutomationHubToggle.name);
    const EnableHideCommunicationsMiningToggle = useFeatureFlagValue(Features.EnableHideCommunicationsMiningToggle.name);
    const EnableHideProcessMiningToggle = useFeatureFlagValue(Features.EnableHideProcessMiningToggle.name);
    const EnableSubmitIdeaToggle = useFeatureFlagValue(Features.EnableSubmitIdea.name);

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { theme } = useApolloTheme();
    const {
        isAccountLicenseEnterpriseOrPro, isUserEnterprise,
    } = useCheckLicense();

    return (
        <>
            <UiText className={classes.title}>
                {translate({ id: 'CLIENT_UI_CUSTOMIZATION' })}
            </UiText>
            <UiText className={classes.description}>
                {translate({ id: 'CLIENT_NAVIGATION_CUSTOMIZATION_DESCRIPTION' })}
            </UiText>
            <TableContainer className={classes.grid}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>
                                <UiText className={classes.tableHeaderText}>
                                    {translate({ id: 'CLIENT_UI_ELEMENT' })}
                                </UiText>
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                <UiText className={classes.tableHeaderText}>
                                    {translate({ id: 'CLIENT_EXPERIENCE' })}
                                </UiText>
                            </TableCell>
                            <TableCell
                                className={clsx(classes.tableHeaderCell, classes.tableHeaderCellAction)}
                                align='center'>
                                <UiText className={classes.tableHeaderText}>
                                    {translate({ id: 'CLIENT_HIDE_SHOW' })}
                                </UiText>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            EnableHideAcademyToggle &&
                            <EditNavIconFormComponent
                                id="academy"
                                nameTextId="CLIENT_ACADEMY_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_ACADEMY_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="academy" />
                                    </div>
                                } />
                        }
                        {
                            EnableHideAiCenterToggle &&
                            <EditNavIconFormComponent
                                id="aifabric"
                                nameTextId="CLIENT_AICENTER_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_AICENTER_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="aifabric" />
                                    </div>
                                } />
                        }
                        {
                            !HideAppsToggle && <EditNavIconFormComponent
                                id="apps"
                                nameTextId="CLIENT_APPS"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_APPS_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR_EXPERIENCE' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="apps" />
                                    </div>
                                } />

                        }
                        {
                            EnableHideAutomationHubToggle &&
                            <EditNavIconFormComponent
                                id="automationhub"
                                nameTextId="CLIENT_AUTOMATION_HUB"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_AUTOMATION_HUB_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="automationhub" />
                                    </div>
                                } />
                        }
                        {
                            EnableHideCommunicationsMiningToggle &&
                            <EditNavIconFormComponent
                                id="reinfer"
                                nameTextId="CLIENT_COMMUNICATIONS_MINING_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_COMMUNICATIONS_MINING_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="reinfer" />
                                    </div>
                                } />
                        }
                        {
                            EnableHideCustomerPortalToggle &&
                            <EditNavIconFormComponent
                                id="customerportal"
                                nameTextId="CLIENT_CUSTOMERPORTAL_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_CUSTOMERPORTAL_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="customerportal" />
                                    </div>
                                } />
                        }
                        <EditNavIconFormComponent
                            id="resourceCenter"
                            nameTextId="CLIENT_DOWNLOADS"
                            titleTextId="CLIENT_NAV_PAGE_EDIT_CONFIRMATION_TITLE"
                            descriptionTextId="CLIENT_RESOURCE_MENU_WIDGET_EDIT_CONFIRMATION_DESCRIPTION"
                            tooltipTextId="CLIENT_TOGGLE_DOWNLOADS_ICON_TOOLTIP"
                            uiLocation={translate({ id: 'CLIENT_RESOURCE_MENU_WIDGETS_EXPERIENCE' })}
                            icon={
                                <div className={classes.icon}>
                                    <DownloadOutlinedIcon />
                                </div>
                            } />
                        {
                            !HideIntegrationServiceToggle &&
                            <EditNavIconFormComponent
                                id="integrationService"
                                nameTextId="CLIENT_ECOMMERCE_INTEGRATION_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_INTEGRATIONS_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="integrations" />
                                    </div>
                                } />
                        }
                        {
                            !HideInsightsToggle &&
                            <EditNavIconFormComponent
                                id="insights"
                                nameTextId="CLIENT_INSIGHTS_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_INSIGHTS_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="insights" />
                                    </div>
                                } />
                        }
                        {
                            !HideMarketplaceToggle && (!isUserEnterprise || EnableMarketplaceToggleForEnterprise) &&
                            <EditNavIconFormComponent
                                id="marketplace"
                                nameTextId="CLIENT_MARKETPLACE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_MARKETPLACE_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR_EXPERIENCE' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="marketplace" />
                                    </div>
                                } />
                        }
                        {
                            EnableHideProcessMiningToggle &&
                            <EditNavIconFormComponent
                                id="processmining"
                                nameTextId="CLIENT_PROCESS_MINING_SERVICE"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_PROCESS_MINING_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="processmining" />
                                    </div>
                                } />
                        }
                        {
                            !HideStudioToggle && (!isAccountLicenseEnterpriseOrPro() || EnableStudioToggleForEnterpriseAndPro) &&
                            <EditNavIconFormComponent
                                id="studio"
                                nameTextId="CLIENT_STUDIO"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_STUDIO_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_NAVIGATION_BAR' })}
                                icon={
                                    <div className={classes.icon}>
                                        <PortalCustomIcon
                                            color={theme.palette.semantic.colorIconDefault}
                                            name="studio" />
                                    </div>
                                } />
                        }
                        {
                            EnableSubmitIdeaToggle &&
                            <EditNavIconFormComponent
                                id="submitIdea"
                                nameTextId="CLIENT_SUBMIT_IDEA"
                                titleTextId="CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE"
                                descriptionTextId="CLIENT_RESOURCE_SUBMIT_IDEA_EDIT_CONFIRMATION_DESCRIPTION"
                                tooltipTextId="CLIENT_TOGGLE_SUBMIT_IDEA_ICON_TOOLTIP"
                                uiLocation={translate({ id: 'CLIENT_RESOURCE_MENU_WIDGETS_EXPERIENCE' })}
                                icon={
                                    <div className={classes.icon}>
                                        <LightbulbOutlinedIcon />
                                    </div>
                                } />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default NavCustomizationForm;
