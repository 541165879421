import type { PropsWithChildren } from 'react';
import React, {
    createContext,
    useContext,
    useState,
} from 'react';

interface IUiDataContext<T> {
    data: T;
    setData: React.Dispatch<React.SetStateAction<T>>;
}

const UiDataContext = createContext<IUiDataContext<any>>({
    data: {},
    setData: () => {},
});

export const useUiDataContext = <T extends {}>() => useContext<IUiDataContext<T>>(UiDataContext);

export const UiDataContextProvider = <T extends {}>({
    children, initialState,
}: PropsWithChildren<{ initialState: T }>) => {
    const [ data, setData ] = useState<T>(initialState);

    return (
        <UiDataContext.Provider value={{
            data,
            setData,
        }}>
            {children}
        </UiDataContext.Provider>
    );
};
