export enum AuthSettingKey {
    RestrictBasicAuthentication = 'Auth.RestrictBasicAuthentication',
    EnableBasicAuthenticationForHostTenant = 'Auth.EnableBasicAuthenticationForHostTenant',
}

export enum AuthSettingPasswordKey {
    PasswordComplexity = 'Auth.Password.PasswordComplexity',
    DefaultExpirationDays = 'Auth.Password.DefaultExpirationDays',
    PreviousUseLimit = 'Auth.Password.PreviousUseLimit',
    ShouldChangePasswordAfterFirstLogin = 'Auth.Password.ShouldChangePasswordAfterFirstLogin',
}

export enum AuthSettingUserLockOutKey {
    IsEnabled = 'Auth.UserLockOut.IsEnabled',
    MaxFailedAccessAttemptsBeforeLockout = 'Auth.UserLockOut.MaxFailedAccessAttemptsBeforeLockout',
    DefaultAccountLockoutSeconds = 'Auth.UserLockOut.DefaultAccountLockoutSeconds',
}

export enum ShouldChangePassword {
    NotRequired = 'false',
    Required = 'true',
}
