import {
    UiProgressButton,
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import { useFormKeysValidation } from '@experiences/util';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import validateCertificate from '../helpers/ValidateSAMLCert';
import useSAMLConfigureIDPViewModel from '../saml/SAMLConfigureIDPViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        subtitle: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            marginBottom: '8px',
        },
        copyText: { margin: '-12px 0px' },
        sectionTitle: {
            marginTop: '20px',
            marginBottom: '0px',
        },
        section: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        field: {
            marginBottom: '16px',
            maxWidth: '420px',
        },
        fetchButton: {
            marginLeft: '20px',
            marginTop: '36px',
        },
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
        },
        divider: {
            flexGrow: 1,
            background: '#ddd',
        },
        centerText: {
            margin: '0px 8px',
            width: 'max-content',
            color: theme.palette.semantic.colorForegroundDisable,
        },
        formSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
        selectInput: {
            marginBottom: '12px',
            maxWidth: '420px',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

const SecuritySettingsSAMLGeneralForm: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        loading,
        methods: {
            control,
            formState: { errors },
        },
        metadataStatus,
        entityIds,
        metadataUrls,
        fetchData,
    } = useSAMLConfigureIDPViewModel();

    const activeKeys = useMemo(() => [
        'SingleSignOnServiceUrl',
        'IdentityProviderEntityId',
        'SigningCertificateLocation.CertificateText',
    ], []);

    useFormKeysValidation(activeKeys);

    if (loading) {
        return <CircularProgress
            size={24}
            className={classes.buttonProgress}
            aria-label="ui-progress-button-loading"
            data-cy="ui-progress-button-loading" /> ;
    }

    return <>
        <UiText className={classes.subtitle}>
            {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_SSO_DATA_TO_BE_CONFIGURED' })}
        </UiText>
        <UiSelect
            control={control}
            className={classes.selectInput}
            name="ServiceProviderEntityId"
            inputLabel={
                <div className={classes.fieldLabel}>
                    {translate({ id: 'CLIENT_ENTITY_ID_SELECT' })}
                    <Tooltip
                        title={translate({ id: 'CLIENT_ENTITY_ID_HELPER' })}>
                        <PortalCustomIcon
                            name='info'
                            size='15px' />
                    </Tooltip>
                </div>
            }
            error={!!errors.ServiceProviderEntityId}
            options={entityIds}
            isTranslated
            required
            fullWidth
            dataCy="configure-saml2-entity-id"
        />
        {process.buildConfigs.showMetadataLink && <UiText className={classes.copyText}>
            <strong>
                {`${translate({ id: 'CLIENT_METADATA_URL' })}: `}
            </strong>
            {metadataUrls.metadataUrl}
            <CopyToClipboard text={metadataUrls.metadataUrl}>
                <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                    <IconButton>
                        <FileCopyOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </CopyToClipboard>
        </UiText>}
        <UiText className={classes.copyText}>
            <strong>
                {`${translate({ id: 'CLIENT_ASSERTION_CONSUMER_SERVICE_URL' })}: `}
            </strong>
            {metadataUrls.assertionConsumerUrl}
            <CopyToClipboard text={metadataUrls.assertionConsumerUrl}>
                <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                    <IconButton>
                        <FileCopyOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </CopyToClipboard>
        </UiText>
        <UiText className={classes.copyText}>
            <strong>
                {`${translate({ id: 'CLIENT_ENTITY_ID' })}: `}
            </strong>
            {metadataUrls.entityId}
            <CopyToClipboard text={metadataUrls.entityId}>
                <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                    <IconButton>
                        <FileCopyOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </CopyToClipboard>
        </UiText>
        <UiText className={clsx(classes.subtitle, classes.sectionTitle)}>
            {translate({ id: 'CLIENT_DATA_FROM_IDP' })}
        </UiText>
        <div className={classes.section}>
            <Controller
                control={control}
                name="IdentityProviderMetadataUrl"
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={translate({ id: 'CLIENT_METADATA_URL' })}
                        InputLabelProps={{ id: 'metadataUrlLabel' }}
                        inputProps={{ 'aria-labelledby': 'metadataUrlLabel' }}
                        placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                        variant="outlined"
                        InputProps={{ className: 'Tall' }}
                        fullWidth
                        className={classes.field}
                        error={!!metadataStatus.error}
                        helperText={metadataStatus.error || undefined}
                        disabled={metadataStatus.loading}
                        data-cy="metadata-url-field"
                    />
                )}
            />
            <UiProgressButton
                variant="outlined"
                onClick={fetchData}
                className={classes.fetchButton}
                data-cy="metadata-url-fetch-now-button"
                loading={metadataStatus.loading}
            >
                {translate({ id: 'CLIENT_FETCH_DATA' })}
            </UiProgressButton>
        </div>
        <div className={clsx(classes.dividerContainer, classes.field)}>
            <Divider className={classes.divider} />
            <UiText className={classes.centerText}>
                {translate({ id: 'CLIENT_OR_ENTER_DATA_MANUALLY' })}
            </UiText>
            <Divider className={classes.divider} />
        </div>

        <div className={classes.formSection}>
            <Controller
                control={control}
                rules={{
                    required: true,
                    validate: p => !!p.trim(),
                }}
                name="SingleSignOnServiceUrl"
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={translate({ id: 'CLIENT_SIGN_ON_URL' })}
                        InputLabelProps={{ id: 'signOnUrlLabel' }}
                        inputProps={{ 'aria-labelledby': 'signOnUrlLabel' }}
                        placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                        variant="outlined"
                        InputProps={{ className: 'Tall' }}
                        fullWidth
                        className={classes.field}
                        error={!!errors.SingleSignOnServiceUrl}
                        helperText={
                            errors.SingleSignOnServiceUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        data-cy="single-sign-on-service-url"
                        disabled={metadataStatus.loading}
                    />
                )}
            />
            <Controller
                control={control}
                rules={{
                    required: true,
                    validate: p => !!p.trim(),
                }}
                name="IdentityProviderEntityId"
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}
                        InputLabelProps={{ id: 'identityProviderEntityLabel' }}
                        inputProps={{ 'aria-labelledby': 'identityProviderEntityLabel' }}
                        placeholder={translate({ id: 'CLIENT_ENTER_ID_HERE' })}
                        variant="outlined"
                        InputProps={{ className: 'Tall' }}
                        fullWidth
                        className={classes.field}
                        error={!!errors.IdentityProviderEntityId}
                        helperText={
                            errors.IdentityProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        data-cy="identity-provider-entity-id-field"
                        disabled={metadataStatus.loading}
                    />
                )}
            />
            <Controller
                name="SigningCertificateLocation.CertificateText"
                control={control}
                rules={{
                    required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                    validate: { invalid: async p => (!!p.trim() && (await validateCertificate(p.trim()))) },
                }}
                render={({ field }) =>
                    <TextField
                        {...field}
                        required
                        error={!!errors.SigningCertificateLocation?.CertificateText}
                        fullWidth
                        className={classes.field}
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-labelledby': 'signingCertificateThumbprintLabel' }}
                        helperText={
                            (
                                (errors.SigningCertificateLocation?.CertificateText?.type === 'required'
                                    && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                || (errors.SigningCertificateLocation?.CertificateText?.type === 'invalid'
                                    && translate({ id: 'CLIENT_SIGNING_CERTIFICATE_INVALID' }))
                            )
                        }
                        label={
                            <div className={classes.fieldLabel}>
                                {translate({ id: 'CLIENT_SIGNING_CERTIFICATE_THUMBPRINT' })}
                                <Tooltip
                                    title={translate({ id: 'CLIENT_PASTE_THE_CERTIFICATE' })}>
                                    <PortalCustomIcon
                                        name='info'
                                        size='15px' />
                                </Tooltip>
                            </div>
                        }
                        placeholder={translate({ id: 'CLIENT_PASTE_THE_CERTIFICATE' })}
                        multiline
                        rows={8}
                        data-cy="signing-certificate-field"
                        disabled={metadataStatus.loading}
                        variant="outlined"
                    />}
            />
        </div>
    </>;
};

export default SecuritySettingsSAMLGeneralForm;
