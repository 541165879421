import {
    PORTAL_PREFIX,
    REQUEST_BASE_ROUTE,
} from '@experiences/constants';

const PortalPrefix = PORTAL_PREFIX;

const Licensing = `${PortalPrefix}/licensing`;
export const DirectBuy = `${REQUEST_BASE_ROUTE}/directbuy`;
export const DirectBuyOrderLoading = `${DirectBuy}/orderLoading`;
export const DirectBuyConfirmation = `${DirectBuy}/orderConfirmation`;
export const DirectBuyPresets = `${DirectBuy}/presets`;
export const DirectBuyIndividualProducts = `${DirectBuy}/buyIndividualProducts`;
export const DirectBuyCheckout = `${DirectBuy}/checkout`;
export const BuyPro = `${PortalPrefix}/buypro`;
export const BuyProPresets = `${PortalPrefix}/buyPresets`;
export const BuyProIndividualProducts = `${PortalPrefix}/buyIndividualProducts`;
export const BuyProCheckout = `${BuyPro}/checkout`;
export const BuyProLoading = `${BuyPro}/orderLoading`;
export const BuyProConfirmation = `${BuyPro}/orderConfirmation`;
export const BuyProSuccess = `${Licensing}/success`;
