import {
    HomePageEvent,
    portalTelemetry,
} from '@experiences/telemetry';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { persona } from '../../store/selectors';
import type { CarouselButtonProps } from './CarouselButtonProps';

const CustomButtonFilled = styled(IconButton)(({ theme }) => ({
    color: theme.palette.semantic.colorPrimary,
    height: '12px',
    width: '12px',
}));

const CustomButtonUnfilled = styled(IconButton)<IconButtonProps>(
    ({ theme }) => ({
        color: theme.palette.semantic.colorForegroundLight,
        height: '12px',
        width: '12px',
        '&:hover': { color: theme.palette.semantic.colorPrimary },
    }),
);

const CustomIconFilled = styled(CircleIcon)(({ theme }) => ({
    color: theme.palette.semantic.colorForegroundLink,
    height: '12px',
    width: '12px',
    strokeWidth: '1px',
}));

const CustomIconUnfilled = styled(CircleOutlinedIcon)`
    width: 12px;
    height: 12px;
`;

export const CarouselButton = ({
    idx, currentSlide, slideId, setSlide,
}: CarouselButtonProps) => {
    const { formatMessage: translate } = useIntl();
    const dominantPersona = useSelector(persona);
    const handleClick = useCallback(() => {

        portalTelemetry.trackEvent({
            name: HomePageEvent.CarouselButtonClicked,
            properties: {
                Message: '[IN-HP-50] - Carousel button clicked',
                Code: '[IN-HP-50]',
                Persona: dominantPersona,
                Slide: idx + 1,
                SlideId: slideId,
                Description: currentSlide,
            },
        });

        setSlide({ currentSlide: idx });
    }, [ currentSlide, dominantPersona, idx, setSlide, slideId ]);

    return currentSlide !== idx ? (
        <CustomButtonUnfilled
            data-cy={`carousel-navigate-button-${idx}`}
            aria-label={translate(
                { id: 'CLIENT_UNFILLED_CAROUSEL_BUTTON' },
                { 0: idx.toString() }
            )}
            size="small"
            onClick={handleClick}
        >
            <CustomIconUnfilled />
        </CustomButtonUnfilled>
    ) : (
        <CustomButtonFilled
            aria-label={translate({ id: 'CLIENT_FILLED_CAROUSEL_BUTTON' })}
            size="small"
            onClick={handleClick}
        >
            <CustomIconFilled />
        </CustomButtonFilled>
    );
};
