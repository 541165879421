export interface INavCustomizationData {
    appsHidden: boolean;
    marketplaceHidden: boolean;
    resourceCenterHidden: boolean;
    studioHidden?: boolean;
    integrationServiceHidden?: boolean;
    insightsHidden?: boolean;
    academyHidden?: boolean;
    customerportalHidden?: boolean;
    aifabricHidden?: boolean;
    automationhubHidden?: boolean;
    reinferHidden?: boolean;
    submitIdeaHidden?: boolean;
    processminingHidden?: boolean;
}

export const defaultNavCustomizationData: INavCustomizationData = {
    appsHidden: false,
    marketplaceHidden: false,
    resourceCenterHidden: false,
    studioHidden: false,
    integrationServiceHidden: false,
    insightsHidden: false,
    academyHidden: false,
    customerportalHidden: false,
    aifabricHidden: false,
    automationhubHidden: false,
    reinferHidden: false,
    submitIdeaHidden: false,
    processminingHidden: false,
};

export const defaultEnterpriseNavCustomizationData: INavCustomizationData = {
    ...defaultNavCustomizationData,
    marketplaceHidden: true,
};
