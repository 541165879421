import type { IOrchestratorUser } from '@experiences/interfaces';
import { get } from '@experiences/util';

export function getCurrentOrchestratorUser(accessToken: string, tenantName: string) {
    const url = `/${tenantName}/orchestrator_/odata/Users/UiPath.Server.Configuration.OData.GetCurrentUser`;

    return get<IOrchestratorUser>(url, {
        accessToken,
        stripPortalPath: true,
    });
}
