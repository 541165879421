import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { useNavigateWithParams } from '@experiences/util';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalPeoplePicker } from '@uipath/portal-shell-react';
import type {
    DirectoryEntry,
    PeoplePickerChangedEvent,
    PortalPeoplePickerCustomEvent,
} from '@uipath/portal-shell-types';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { DirectoryEntityType } from '../../../common/interfaces/cis/directory';
import type { IGroup } from '../../../common/interfaces/cis/group';
import type {
    ISAMLProvisioningCondition,
    ISAMLRule,
} from '../../../common/interfaces/cis/saml';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import type { SourceFilters } from '../../../services/identity/DirectoryService';
import {
    createRule,
    getRuleById,
    ruleUrl,
    updateRule,
} from '../../../services/identity/RuleService';
import { accountGlobalId } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import SAMLProvisioningConditionGenerator from './SAMLProvisioningConditionGenerator';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        body: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        breadcrumbText: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 400,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        section: { marginBottom: '16px' },
        subTitle: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            marginBottom: '8px',
        },
        labelText: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputControl: { marginBottom: '16px' },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
            '& > a': {
                color: theme.palette.semantic.colorForegroundInverse,
                'text-decoration': 'none !important',
            },
        },
        header: {
            paddingTop: '24px',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    }),
);

interface ISAMLRuleForm extends Omit<ISAMLRule, 'partitionGlobalId' | 'enabled' | 'assignedGroups' | 'definition'> {
    groups: DirectoryEntry[];
    conditions: ISAMLProvisioningCondition[];
}

const CreateEditSAMLProvisioningRuleComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const { id } = useParams<{ id: string }>();
    const { token } = useUserInfo();

    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data: rule, isValidating, error, mutate,
    } = useSWR(
        type === 'edit' ? {
            url: ruleUrl,
            partitionGlobalId,
            ruleId: parseInt(id ?? ''),
        } : null,
        getRuleById,
    );

    const [ loading, setLoading ] = useState(false);

    const methods = useForm<ISAMLRuleForm>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            groups: [],
            conditions: [],
        },
    });

    const {
        control, handleSubmit, formState, getValues, setValue, setError, clearErrors, reset, watch,
    } = methods;

    const {
        isDirty, isValid, errors,
    } = formState;

    useEffect(() => {
        if (type !== 'edit') {
            return;
        }

        if (rule) {
            reset({
                name: rule.name,
                description: rule.description,
                groups: ((Array.isArray(rule.assignedGroups)
                    ? rule.assignedGroups
                    : [ rule.assignedGroups ]) as IGroup[])
                    .map((group: IGroup) => ({
                        identifier: group.id,
                        displayName: group.name,
                        identityName: group.name,
                        source: group.type === 0 ? 'local' : 'directory',
                        type: DirectoryEntityType.group,
                    })),
                conditions: typeof rule.definition === 'string'
                    ? JSON.parse(rule.definition).Conditions as ISAMLProvisioningCondition[]
                    : rule.definition,
            });
        }
    }, [ reset, rule, type ]);

    const goBack = useCallback(() => {
        navigate(RouteNames.SecuritySettingsSAMLProvisioningRules);
    }, [ navigate ]);

    const onSubmit = useCallback(
        async (data: ISAMLRuleForm) => {
            setLoading(true);
            const mappedData = {
                ...data,
                ruleId: id ? parseInt(id) : undefined,
                partitionGlobalId,
                enabled: rule?.enabled ?? true,
                definition: JSON.stringify({
                    GroupsToAssign: data.groups.map(group => group.identifier),
                    Conditions: data.conditions,
                }),
                groups: undefined,
                conditions: undefined,
            };

            try {
                if (type === 'add') {
                    await createRule(mappedData);
                } else {
                    await updateRule(mappedData);
                }

                createNotification(
                    translate({
                        id: type === 'add'
                            ? 'CLIENT_SAML_PROVISIONING_RULES_CREATED_SUCCESSFULLY'
                            : 'CLIENT_SAML_PROVISIONING_RULES_UPDATED_SUCCESSFULLY',
                    }),
                    notificationType.SUCCESS
                );

                goBack();
            } catch (error) {
                const response = getErrorMessage(error);
                setErrorMessage(response);
            } finally {
                setLoading(false);
            }
        },
        [ createNotification, getErrorMessage, goBack, id, partitionGlobalId, rule?.enabled, setErrorMessage, translate, type ],
    );

    const peoplePickerChangedCallback = useCallback((event: PortalPeoplePickerCustomEvent<PeoplePickerChangedEvent>) => {
        if (!event.detail.data || !Array.isArray(event.detail.data)) {
            return;
        }

        const prevWithoutChip =
                    getValues('groups')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
        const curWithoutChip = event.detail.data.map(chip => omit(chip, [ 'optionId', 'chipType' ]));
        const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
        setValue('groups', event.detail.data, {
            shouldDirty: valuesChanged,
            shouldValidate: valuesChanged,
        });
    }, [ getValues, setValue ]);

    const peoplePickerErrorCallback = useCallback((event: any) => {
        if (event.detail) {
            setError('groups', { type: 'invalid' });
        } else {
            clearErrors('groups');
        }
    }, [ clearErrors, setError ]);

    const peoplePickerLoadingCallback = useCallback((event: any) => setLoading(event.detail), []);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            position="left"
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
        >
            {isValidating && <CircularProgress className={classes.loader} />}
            {!isValidating && error && (
                <div className={classes.body}>
                    <UiText>
                        {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
                    </UiText>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            mutate();
                        }}
                        style={{ marginTop: '12px' }}
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                </div>
            )}
            {!isValidating && !error && (
                <FormProvider {...methods}>
                    <UiForm
                        onSubmit={handleSubmit(onSubmit)}
                        actions={<div className={classes.footer}>
                            <Button
                                className={classes.footerButton}
                                onClick={goBack}
                                data-cy="saml-cancel-button">
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                type="submit"
                                className={classes.footerButton}
                                loading={loading}
                                disabled={!isDirty || !isValid || Object.entries(errors).length > 0 || !watch('groups')?.length}
                                variant="contained"
                                data-cy="saml-test-and-save-button"
                            >
                                {translate({ id: 'CLIENT_SAVE' })}
                            </UiProgressButton>
                        </div>}>
                        <div className={classes.section}>
                            <UiText className={classes.subTitle}>
                                {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_BASIC_DETAILS' })}
                            </UiText>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                                    validate: p => !!p.trim(),
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        label={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_NAME' })}
                                        placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_NAME_PLACEHOLDER' })}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                        className={classes.inputControl}
                                        data-cy="jit-rule-name"
                                    />
                                )}
                            />
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_DESCRIPTION' })}
                                        placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_DESCRIPTION_PLACEHOLDER' })}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        minRows={3}
                                        maxRows={3}
                                        error={!!errors.description}
                                        helperText={errors.description?.message}
                                        className={classes.inputControl}
                                        data-cy="jit-rule-description"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.section}>
                            <UiText className={classes.subTitle}>
                                {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_CONDITIONS' })}
                            </UiText>
                            <SAMLProvisioningConditionGenerator />
                        </div>
                        <div className={classes.section}>
                            <UiText className={classes.subTitle}>
                                {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_ASSIGN_TO_GROUPS' })}
                            </UiText>
                            <Controller
                                name="groups"
                                control={control}
                                rules={{ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) }}
                                render={({ field }) => (
                                    <PortalPeoplePicker
                                        token={token}
                                        sourceFilters={[ 'localGroups' ] as SourceFilters[]}
                                        value={field.value as DirectoryEntry[]}
                                        maxHeight="124px"
                                        onPeoplePickerChanged={peoplePickerChangedCallback}
                                        onPeoplePickerError={peoplePickerErrorCallback}
                                        onPeoplePickerLoading={peoplePickerLoadingCallback}
                                        onPeoplePickerResolving={peoplePickerLoadingCallback}
                                    />
                                )}
                            />
                        </div>
                    </UiForm>
                </FormProvider>
            )}
        </UiPageContainer>
    );
};

export const CreateEditSAMLProvisioningRuleComponentWithParams: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const { type } = useParams() as { type: 'add' | 'edit' };

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() =>
        [
            {
                index: 0,
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                index: 1,
                link: RouteNames.SecuritySettings,
                name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
            },
            {
                index: 2,
                link: RouteNames.SecuritySettingsSAMLProvisioningRules,
                name: translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' }),
            },
            {
                index: 3,
                link: RouteNames.SecuritySettingsSAMLProvisioningRulesAdd,
                name: translate({
                    id: type === 'add'
                        ? 'CLIENT_SAML_PROVISIONING_RULES_ADD_NEW_RULE'
                        : 'CLIENT_SAML_PROVISIONING_RULES_EDIT_RULE',
                }),
            },
            {
                index: 3,
                link: RouteNames.SecuritySettingsSAMLProvisioningRulesEdit,
                name: translate({
                    id: type === 'add'
                        ? 'CLIENT_SAML_PROVISIONING_RULES_ADD_NEW_RULE'
                        : 'CLIENT_SAML_PROVISIONING_RULES_EDIT_RULE',
                }),
            },
        ],
    [ organizationName, translate, type ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <CreateEditSAMLProvisioningRuleComponent type={type} />
    </BreadcrumbProvider>;
};

export default CreateEditSAMLProvisioningRuleComponent;
