export interface IProcess {
    Id: number;
    Name: string;
    Status: string;
    Description?: string;
    CurrentVersion?: IProcessVersion;
    CreationTime: Date;
    OrganizationUnitFullyQualifiedName: string;
}
export interface IProcessVersion {
    Id: number;
    VersionNumber: string;
    creationTime: Date;
}
export interface IQueue {
    Id: number;
    CreationTime: Date;
    Name: string;
    Description?: string;
    CurrentVersion?: IProcessVersion;
    OrganizationUnitFullyQualifiedName: string;
}
export interface IProcessesApp {
    Entity: IProcess | IQueue;
    Type: ProcessType;
}
export enum ProcessType {
    Process = 'process',
    Queue = 'queue',
}
export interface IRecentRun {
    Id: number;
    Name: string;
    StartDate: Date;
    Status: string;
    Type: string;
}
