import { getCISClientId } from '../configuration/AuthConfiguration.default';
import { getAuthDomain } from '../configuration/AuthDomainResolver';
import { clearAuthStorage } from './ClearStorage';

export default function forceLogout(returnUrl?: string) {
    const returnUrlLocal = returnUrl ?? `${window.location.origin}/portal_/cloudrpa`;
    const { CISHostWithAPIPrefix } = getAuthDomain();

    const redirectParams = new URLSearchParams({
        clientid: getCISClientId(),
        postLogoutRedirectUri: returnUrlLocal,
    });

    clearAuthStorage();

    window.location.assign(`https://${CISHostWithAPIPrefix}/ui/account/ForceLogout?${redirectParams.toString()}`);
}
