import { NotificationSettingsEvent } from '@experiences/telemetry';
import {
    SpacingToken,
    UiProgressButton,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    useAuthContext,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { InfoOutlined } from '@mui/icons-material';
import {
    Checkbox,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import { ConnectionDropdown } from '@uipath/connection-service-react-components';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
} from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    ConnectionAffiliatesTeams,
    getTeamsAffiliatedConnection,
    getTeamsConnections,
    teamsConfigurationUrl,
    updateTeamsConfiguration,
} from '../../../../services/notification-preferences';
import { accountGlobalId } from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import UiForm from '../../../common/UiForm';
import { useTenantsContext } from '../../../tenants/TenantsContextProvider';
import type {
    ITeamsChannel,
    ITeamsConnectionConfiguration,
    ITeamsTeam,
} from '../../interfaces/notificationSettings';
import { UiTeamsChannelPicker } from './UiTeamsChannelPicker';
import { UiTeamsPicker } from './UITeamsPicker';

const useStyles = makeStyles((theme) => ({
    input: { marginTop: '32px !important' },
    tabsContainer: { marginBottom: Tokens.Padding.PadL },
    inputLabel: {
        fontWeight: Tokens.FontFamily.FontBrandH4Weight,
        fontSize: Tokens.FontFamily.FontMSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    connectionlabel: {
        marginBottom: '8px',
        float: 'left',
        fontWeight: Tokens.FontFamily.FontBrandH4Weight,
        fontSize: Tokens.FontFamily.FontMSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    rowGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    selectinput: {
        width: '50%',
        marginTop: '5px',
    },
    inputSender: { '& .MuiFormControl-root': { width: '100%' } },
    labelEnableNotificationlink: {
        marginTop: '8px',
        fontSize: Tokens.FontFamily.FontSSize,
    },
    inputHeadText: {
        fontSize: Tokens.FontFamily.FontSSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    inputSubText: {
        fontSize: Tokens.FontFamily.FontSSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    inputMargin: { marginTop: Tokens.Padding.PadM },
    saveButton: { marginRight: Tokens.Padding.PadXl },
    adminlink: {
        cursor: 'pointer',
        marginLeft: '3px',
        color: '#0067df',
        '&:hover': { textDecoration: 'underline' },
    },
    defaultlink: { fontSize: Tokens.FontFamily.FontSSize },
    infoIcon: {
        marginLeft: Tokens.Padding.PadM,
        height: Tokens.FontFamily.FontXsLineHeight,
        width: Tokens.Padding.PadXxxl,
        marginTop: '3px',
    },
}));

const teamsConnectorKey = 'uipath-microsoft-teams';
export const TeamsSettingsFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const accountId = useSelector(accountGlobalId);
    const createNotification = useUiSnackBar();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const { logEvent } = useTelemetryHelper();
    const [ selectTeam, setSelectTeam ] = React.useState<ITeamsTeam>();
    const [ isTeamsEnabled, setIsTeamsEnabled ] = React.useState<boolean>(false);
    const { token } = useAuthContext();
    const { data: configurationData } = useSWR(
        tenantId ? {
            requestUri: ConnectionAffiliatesTeams,
            selectedTenantId: tenantId,
            selectedAccontId: accountId,
        } : null,
        getTeamsAffiliatedConnection,
    );

    const { data: connectionsData } = useSWR(
        tenantId ? {
            selectedTenantId: tenantId,
            selectedAccontId: accountId,
        } : null,
        getTeamsConnections,
    );

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        register,
        reset,
        formState: {
            isValid: isFormValid, isDirty, isSubmitting,
        },
    } =
    useForm<ITeamsConnectionConfiguration>({
        mode: 'onChange',
        defaultValues: {
            supplementConnectionId: '',
            name: '',
            connectorType: 'Teams',
            isScopedToAccount: false,
            isEnabled: false,
            channelKey: '',
            channelName: '',
            teamId: '',
            teamName: '',
        },
    });

    useEffect(() => {
        if (configurationData && connectionsData) {
            setIsTeamsEnabled(configurationData.isEnabled);

            const isConnectionActive = connectionsData?.connections.find(x => x.id === configurationData.supplementConnectionId);

            if (isConnectionActive) {
                const team: ITeamsTeam = {
                    id: configurationData.teamId,
                    displayName: configurationData.teamName,
                };
                setSelectTeam(team);
            }
            reset({
                supplementConnectionId: configurationData.supplementConnectionId,
                isScopedToAccount: configurationData.isScopedToAccount,
                name: configurationData.name,
                isEnabled: configurationData.isEnabled,
                channelKey: configurationData.channelKey,
                channelName: configurationData.channelName,
                teamId: configurationData.teamId,
                teamName: configurationData.teamName,
            });
        }
    }, [ reset, configurationData, connectionsData, register ]);

    const onSubmit = useCallback(async (data: ITeamsConnectionConfiguration) => {
        try {

            logEvent(NotificationSettingsEvent.SetTeamsConfig);
            data.connectorType = 'Teams';
            await updateTeamsConfiguration(teamsConfigurationUrl, data, tenantId);
            createNotification(
                translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_SAVED' }),
                notificationType.SUCCESS,
            );

        } catch (error) {

            const errorMessage = (error as Error)?.message;
            createNotification(
                errorMessage,
                notificationType.ERROR,
            );
        }

    }, [ logEvent, tenantId, createNotification, translate ]);

    const handleChange = useCallback(async (checked: boolean, field: ControllerRenderProps<ITeamsConnectionConfiguration, 'isEnabled'>) => {
        let proceed = false;
        if (checked) {
            proceed = await createDialog({
                title: translate({ id: 'CLIENT_ENABLE_TEAMS_DIALOG_TITLE' }),
                body: (
                    <UiText>
                        {translate({ id: 'CLIENT_ENABLE_TEAMS_DIALOG_MESSAGE' })}
                    </UiText>
                ),
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_ENABLE' }),
                showCancel: true,
            });
        }
        if (proceed && checked) {
            setIsTeamsEnabled(checked);
            field.onChange(checked);
        } else {
            field.onChange(false);
            setIsTeamsEnabled(false);
        }
    }, [ createDialog, translate ]);

    const navigateToDefaultSettings = useCallback(() => {

        const editDefaultProfileRoute = RouteNames.AddNotificationSettingsProfile
            .replace(':id', 'Default')
            .replace(':name', 'Default');
        navigate(getRoute(editDefaultProfileRoute), {
            state: {
                identifier: 'Default',
                type: 'User',
                local: 'true',
                hasEmail: 'false',
            },
        });
    }, [ getRoute, navigate ]);

    const navigateToOrgAdminSettings = useCallback(() => {
        navigate(getRoute(RouteNames.OrganizationSettings));
    }, [ getRoute, navigate ]);
    return (
        <UiStack
            mb={SpacingToken.XS}>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                disableActionsGutters
                id="teamsConfigurationForm"
                actions={
                    <UiStack
                        align="center"
                        pl={SpacingToken.M}
                        mt={SpacingToken.M}>
                        <UiProgressButton
                            loading={isSubmitting}
                            disabled={(!isDirty || !isFormValid) && isTeamsEnabled}
                            className={classes.saveButton}
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            data-cy="add-edit-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </UiStack>
                }
            >
                <UiStack
                    direction="row"
                    pl={SpacingToken.M}>
                    <UiStack
                        direction="column"
                        mr={SpacingToken.L}>
                        <UiStack mt={SpacingToken.Base}>
                            <PortalAlertBar
                                status="warning"
                                cancelable={false}>
                                <FormattedMessage
                                    id="CLIENT_TEAMS_WARNING_NOTIFICATION"
                                    values={{
                                        a: (str: React.ReactNode[]) => <UiText
                                            display="inline"
                                            onClick={navigateToOrgAdminSettings}
                                            className={classes.adminlink}>
                                            {str}
                                        </UiText>,
                                    }}
                                />
                            </PortalAlertBar>
                        </UiStack>
                        <UiStack mt={SpacingToken.XL}>
                            <UiText
                                className={classes.inputLabel}>
                                {translate({ id: 'CLIENT_TEAMS_ENABLE_NOTIFICATION' })}
                            </UiText>
                        </UiStack>
                        <UiStack>
                            <Controller
                                control={control}
                                name="isEnabled"
                                render={({ field }) => (
                                    <Switch
                                        className={classes.inputMargin}
                                        data-cy="teams-config-switch-control"
                                        checked={field.value}
                                        onChange={(e, checked) => {
                                            handleChange(checked, field);
                                        }}
                                        color="primary"
                                    />
                                )}
                            />
                        </UiStack>
                        {isTeamsEnabled && <UiStack direction="column">
                            <UiStack >
                                <UiText className={classes.labelEnableNotificationlink}>
                                    {translate({ id: 'CLIENT_TEAMS_CONFIGURE_MESSAGE' })}
                                </UiText>
                            </UiStack>
                            <UiStack className={classes.inputSubText}>
                                <FormattedMessage
                                    id="CLIENT_TEAMS_CONFIGURE_SUB_MESSAGE"
                                    values={{
                                        a: (str: React.ReactNode[]) => <UiText
                                            display="inline"
                                            onClick={navigateToDefaultSettings}
                                            className={clsx(classes.adminlink, classes.defaultlink)}>
                                            {str}
                                        </UiText>,
                                    }}
                                />
                            </UiStack>

                            <UiStack
                                direction="column"
                                mt={SpacingToken.L}>
                                <UiStack>
                                    <UiText
                                        className={classes.connectionlabel}>
                                        {translate({ id: 'CLIENT_TEAMS_CONNECTION_INPUT_LABEL' })}
                                    </UiText>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_CONNECTION_TOOLTIP' })}>
                                        <InfoOutlined
                                            className={classes.infoIcon}
                                            tabIndex={0}
                                            aria-label={translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_CONNECTION_TOOLTIP' })}
                                        />
                                    </Tooltip>
                                </UiStack>
                                <Controller
                                    name="supplementConnectionId"
                                    control={control}
                                    render={({ field }) => (
                                        <ConnectionDropdown
                                            className={clsx(classes.selectinput)}
                                            orgName={accountId}
                                            tenantName={tenantId}
                                            connectorKey={teamsConnectorKey}
                                            value={field.value}
                                            onChange={(e) => {
                                                setSelectTeam(undefined);
                                                field.onChange(e);
                                                setValue('supplementConnectionId', e);
                                                setValue('name', e);
                                                setValue('teamName', '');
                                                setValue('teamId', '');
                                                setValue('channelKey', '');
                                                setValue('channelName', '');
                                                setSelectTeam(undefined);
                                            }}
                                            accessToken={token}
                                        />
                                    )}
                                />
                            </UiStack>
                            {!!getValues('supplementConnectionId') && <>
                                <UiStack
                                    mt={SpacingToken.M}>
                                    <Controller
                                        {...register('teamId', { required: true })}
                                        control={control}
                                        render={({ field }) => (
                                            <UiTeamsPicker
                                                searchOnly
                                                showTooltip
                                                labelText={translate({ id: 'CLIENT_TEAMS_TEAM_INPUT_LABEL' })}
                                                connectionId={getValues('supplementConnectionId')}
                                                value={{
                                                    id: getValues('teamId'),
                                                    displayName: getValues('teamName'),
                                                }}
                                                onChange={(msftTeamsEntries: ITeamsTeam[]) => {
                                                    if (msftTeamsEntries.length > 0 && msftTeamsEntries[0]) {
                                                        field.onChange(msftTeamsEntries[0].id);
                                                        setSelectTeam(msftTeamsEntries[0]);
                                                        setValue('teamId', msftTeamsEntries[0].id);
                                                        setValue('teamName', msftTeamsEntries[0].displayName);
                                                    } else {
                                                        field.onChange('');
                                                        setValue('teamName', '');
                                                        setValue('teamId', '');
                                                        setSelectTeam(undefined);
                                                    }
                                                    setValue('channelName', '');
                                                    setValue('channelKey', '');
                                                }}
                                            />
                                        )}
                                    />

                                </UiStack>
                            </>}
                            {!!selectTeam && <>
                                <UiStack mt={SpacingToken.M}>

                                    <Controller
                                        {...register('channelKey', { required: true })}
                                        control={control}
                                        render={({ field }) => (
                                            <UiTeamsChannelPicker
                                                searchOnly
                                                showTooltip
                                                labelText={translate({ id: 'CLIENT_TEAMS_CHANNEL_INPUT_LABEL' })}
                                                connectionId={getValues('supplementConnectionId') ?? ''}
                                                teamId={getValues('teamId')}
                                                value={{
                                                    id: getValues('channelKey'),
                                                    displayName: getValues('channelName'),
                                                }}
                                                onChange={(teamsChannelEntries: ITeamsChannel[]) => {
                                                    if (teamsChannelEntries.length > 0 && teamsChannelEntries[0]) {
                                                        field.onChange(teamsChannelEntries[0].id);
                                                        setValue('channelKey', teamsChannelEntries[0].id);
                                                        setValue('channelName', teamsChannelEntries[0].displayName);
                                                    } else {
                                                        setValue('channelName', '');
                                                        setValue('channelKey', '');
                                                        field.onChange('');
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                </UiStack>
                                <UiStack
                                    mt={SpacingToken.Base}
                                    className={classes.inputHeadText}>
                                    <FormattedMessage
                                        id="CLIENT_TEAMS_CHANNEL_CONFIGURE_SUB_MESSAGE"
                                        values={{
                                            a: (str: React.ReactNode[]) => <UiText
                                                display="inline"
                                                onClick={navigateToDefaultSettings}
                                                className={clsx(classes.adminlink, classes.defaultlink)}>
                                                {str}
                                            </UiText>,
                                        }}
                                    />
                                </UiStack>
                            </>}
                            <UiStack mt={SpacingToken.L}>
                                <Controller
                                    control={control}
                                    name="isScopedToAccount"
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    data-cy="teams-account-level-box"
                                                    checked={field.value}
                                                    onChange={e => field.onChange(e.target.checked)}
                                                />
                                            }
                                            label={
                                                <div className={classes.rowGroup}>
                                                    <div>
                                                        <span>
                                                            {translate({ id: 'CLIENT_TEAMS_ACCOUNT_LEVEL_CHECKBOX_LABEL' })}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <Tooltip
                                                            arrow
                                                            title={translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_ACCOUNTLEVEL_TOOLTIP' })}>
                                                            <InfoOutlined
                                                                className={classes.infoIcon}
                                                                tabIndex={0}
                                                                aria-label={translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_ACCOUNTLEVEL_TOOLTIP' })}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            }
                                            data-cy="teamsconfig-accountlevel-check"
                                        />
                                    )}
                                />
                            </UiStack>
                        </UiStack>}
                    </UiStack>
                </UiStack>
            </UiForm>
        </UiStack>
    );
};

