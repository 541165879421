import {
    en,
    useLocalization,
} from '@experiences/locales';
import {
    getGlobalAppOrigin,
    getOrganizationAppOrigin,
    UiStorage,
    useAuthContext,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import type {
    IBusinessInfoPayload,
    ISubscriptionForm,
} from '../../lib/interfaces/ecommerce';
import type { enTranslationKeys } from '../subcomponents/EcommerceCheckoutCallbacks';
import { sendDataToMarketo } from '../subcomponents/EcommerceCheckoutCallbacks';
import {
    DIRECT_BUY_FLOW,
    DIRECT_BUY_ORDER_CONFIRMATION,
    SKU_PACKAGE_DETAILS,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from './EcommerceHelpers';
import {
    BuyProLoading,
    DirectBuyOrderLoading,
} from './EcommerceRoutes';
import {
    getTranslationId,
    mapDeclineCode,
} from './EcommerceStripeErrorCodeMapper';
import { useEcommerce } from './useEcommerce';

export default function useEcommercePaymentElementFormViewModel(
    currentAccountName: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    existingMarketoData: IBusinessInfoPayload | undefined,
) {
    const stripe = useStripe();
    const elements = useElements();
    const getRoute = useRouteResolver();
    const { getValues } = useFormContext<ISubscriptionForm>();
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const {
        organizationId, countryCode,
    } = useEcommerce();
    const logEcommerceEvent = useEcommerceTelemetry();
    const createDialog = useShowDialog();
    const currentAccountLanguage = useLocalization();
    const { token } = useAuthContext();

    const {
        stripeSubscriptionId, currentSkuPackageDetails,
    } = useEcommerce();
    const { formatMessage: translate } = useIntl();

    const redirectUrl = useMemo(() => {
        let orderLoadingUrl;
        if (isDirectBuyInProgressFlow) {
            orderLoadingUrl = getGlobalAppOrigin() + getRoute(DirectBuyOrderLoading);
        } else {
            orderLoadingUrl = getOrganizationAppOrigin() + getRoute(BuyProLoading, currentAccountName);
        }
        return `${orderLoadingUrl}?stripeSubscriptionId=${stripeSubscriptionId}&organizationId=${organizationId}&countryCode=${countryCode}`;
    }, [ countryCode, currentAccountName, getRoute, isDirectBuyInProgressFlow, organizationId, stripeSubscriptionId ]);

    const sendEcommerceEvent = useCallback(
        (eventName: string, otherProperties?: any) => {
            logEcommerceEvent(eventName, {
                SelectedPlan: currentSkuPackageDetails.type,
                Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                ...otherProperties,
            });
        },
        [ isDirectBuyInProgressFlow, logEcommerceEvent, currentSkuPackageDetails.type ],
    );

    const paymentElementHandleSubmit = useCallback(async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setLoading(true);
        UiStorage.removeItem(DIRECT_BUY_ORDER_CONFIRMATION);
        const values = getValues();
        sendEcommerceEvent('Billing.Pay', {
            Currency: values.currency,
            Country: values.country,
            State: values.state,
            City: values.city,
        });
        const confirmParams = { return_url: redirectUrl };

        await sendDataToMarketo(values, existingMarketoData, currentAccountLanguage, token, currentAccountName, isSignupDirectBuyFlow);

        UiStorage.setItem(SKU_PACKAGE_DETAILS, JSON.stringify(currentSkuPackageDetails));
        const confirmPaymentResult = await stripe.confirmPayment({
            elements,
            confirmParams,
        });

        setLoading(false);
        if (confirmPaymentResult.error) {
            const error = confirmPaymentResult.error;
            const specificErrorCode = confirmPaymentResult.error.decline_code ?? confirmPaymentResult.error.code;
            const errorCode = mapDeclineCode(specificErrorCode);
            const translationId = getTranslationId(errorCode);
            let errorBodyMessage, eventErrorMessage;
            if (error.message?.includes('The customer already has a subscription started')) {
                eventErrorMessage = en['CLIENT_STRIPE_SUBSCRIPTION_ALREADY_EXISTS'];
                errorBodyMessage = (
                    <div>
                        {translate({ id: 'CLIENT_STRIPE_SUBSCRIPTION_ALREADY_EXISTS' })}
                    </div>
                );
            } else {
                eventErrorMessage = en[translationId as enTranslationKeys];
                errorBodyMessage = (
                    <FormattedMessage
                        id={translationId}
                        values={{
                            // eslint-disable-next-line react/no-unstable-nested-components
                            p: (msg: React.ReactNode[]) => <p>
                                {msg}
                            </p>,
                            taxIdFormatHint: error.message,
                        }}
                    />
                );
            }
            sendEcommerceEvent('Billing.PayError', {
                Error: error.message,
                Type: error.type,
                Code: eventErrorMessage,
            });

            setLoading(false);
            const title = translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' });
            await createDialog({
                title,
                body: errorBodyMessage,
                icon: 'warning',
                showCancel: false,
                primaryButtonText: translate({ id: 'CLIENT_OK' }),
            });
        }
    }, [
        createDialog,
        currentAccountLanguage,
        currentAccountName,
        elements,
        existingMarketoData,
        getValues,
        redirectUrl,
        sendEcommerceEvent,
        setLoading,
        stripe,
        token,
        translate,
        currentSkuPackageDetails,
        isSignupDirectBuyFlow,
    ]);

    return { paymentElementHandleSubmit };
}
