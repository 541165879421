import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { certificateLocationUsageOptions } from '../../../common/constants/ExternalIdentityProviderConstant';
import type { IEditCertificateLocationsData } from '../../../common/interfaces/externalIdentity';

const useStyles = makeStyles(theme =>
    createStyles({
        section: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        header: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
    }),
);

export const defaultEditCertificateLocationsData: IEditCertificateLocationsData = {
    signingCertificateLocation: { CertificateText: '' },
    serviceCertificateLocation: {
        ServiceCertificateLocationEnabledOnOnpremK8s: false,
        Usage: undefined,
    },
};

const EditCertificateLocationsFormComponent: React.FC = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext<IEditCertificateLocationsData>();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <>
            <div className={classes.section}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_SIGNING_CERTIFICATE' })}
                </UiText>
                <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_CERTIFICATE_TEXT' })}
                </UiText>
                <Controller
                    name="signingCertificateLocation.CertificateText"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            error={!!errors.signingCertificateLocation?.CertificateText}
                            helperText={
                                errors.signingCertificateLocation?.CertificateText?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            }
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            InputProps={{
                                className: 'Tall',
                                multiline: true,
                            }}
                            data-cy="edit-certificate-locations-signing-certificate-text"
                        />
                    )}
                />
            </div>
            <div className={classes.section}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_SERVICE_CERTIFICATE' })}
                </UiText>
                <Controller
                    name="serviceCertificateLocation.ServiceCertificateLocationEnabledOnOnpremK8s"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                    data-cy="edit-certificate-locations-service-enabled"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_ENABLED' })}
                                </div>
                            }
                        />
                    )}
                />
                <UiSelect
                    control={control}
                    name="serviceCertificateLocation.Usage"
                    inputLabel={translate({ id: 'CLIENT_CERTIFICATE_LOCATION_USAGE' })}
                    error={!!errors.serviceCertificateLocation?.Usage}
                    options={certificateLocationUsageOptions}
                    fullWidth
                    dataCy="edit-certificate-locations-service-usage"
                    required
                />
            </div>
        </>
    );
};

export default EditCertificateLocationsFormComponent;
