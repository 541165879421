import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    ExternalAppsTab,
    type ExternalAppsTabType,
} from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import ExternalApplicationsAppRegistrationsPageComponent from '../appRegistration/ExternalApplicationAppRegistrationsPageComponent';
import ExternalApplicationsPageComponent from '../ExternalApplicationsPageComponent';
import ExternalApplicationsPATPageComponent from '../ExternalApplicationsPATPageComponent';

const useStyles = makeStyles(() => createStyles({ tabs: { marginBottom: '8px' } }));

const TabPanel: React.FC<{ value: ExternalAppsTabType }> = ({ value }) => {
    switch (value) {
        case ExternalAppsTab.OAUTH:
            return <ExternalApplicationsPageComponent />;
        case ExternalAppsTab.PAT:
            return <ExternalApplicationsPATPageComponent />;
        case ExternalAppsTab.REG:
            return <ExternalApplicationsAppRegistrationsPageComponent />;
    }
};

const ExternalAppsTabs: React.FC<{ type: ExternalAppsTabType }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ tabValue, setTabValue ] = useState<ExternalAppsTabType>(type ?? ExternalAppsTab.OAUTH);

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);
    const EnableGlobalAppRegistrations = useFeatureFlagValue(Features.EnableGlobalAppRegistrations.name);

    const handleChange = useCallback((_: any, newValue: ExternalAppsTabType) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={clsx('default', classes.tabs)}>
                <Tab
                    value={ExternalAppsTab.OAUTH}
                    label={translate({ id: 'CLIENT_OAUTH_APPS' })}
                    onClick={() => navigate(getRoute(RouteNames.ExternalApplications))}
                    data-cy="oauth-tab"
                    className="default"
                />
                {EnableReferenceTokens && (
                    <Tab
                        value={ExternalAppsTab.PAT}
                        label={translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN' })}
                        onClick={() => navigate(getRoute(RouteNames.ExternalApplicationsPAT))}
                        data-cy="pat-tab"
                        className="default"
                    />
                )}
                {EnableGlobalAppRegistrations && (
                    <Tab
                        value={ExternalAppsTab.REG}
                        label={translate({ id: 'CLIENT_APP_REGISTRATIONS' })}
                        onClick={(() => navigate(getRoute(RouteNames.ExternalAppRegistrations)))}
                        data-cy="reg-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default ExternalAppsTabs;
