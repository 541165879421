import {
    mapAutomationCloudLanguageToStripe,
    useLocalization,
} from '@experiences/locales';
import { UiLogo } from '@experiences/ui-common';
import {
    UiStorage,
    useModalState,
    useProtectRouteFromMissingParams,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import type { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Cookies from 'js-cookie';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import {
    DIRECT_BUY_DATA,
    DIRECT_BUY_FLOW,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from './helpers/EcommerceHelpers';
import {
    BuyPro,
    BuyProIndividualProducts,
    BuyProPresets,
} from './helpers/EcommerceRoutes';
import { useEcommerce } from './helpers/useEcommerce';
import type { IEcommerceCheckoutComponentState } from './interfaces/ecommerce';
import { getPackagesPricesPublic } from './services/BillingService';
import EcommerceCheckoutForm from './subcomponents/EcommerceCheckoutForm';
import EcommerceCheckoutPaymentFormProvider from './subcomponents/EcommerceCheckoutPaymentFormProvider';
import useEcommerceCheckoutViewModel from './useEcommerceCheckoutViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        dialog: { padding: '0px' },
        dialogPaperProps: { padding: '0px !important' },
        backBar: {
            minHeight: '76px',
            maxHeight: '76px',
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: theme.palette.semantic.colorBorderDeEmp,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '22px',
        },
        backLink: {
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
        },
    }),
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    const {
        children, role, ...rest
    } = props;

    // expansion is needed twice because of strange Mui accessibility bug with transitions
    return <Slide
        {...rest}
        direction="up"
        ref={ref}
        {...{ role: 'dialog' }}
        aria-labelledby="checkoutDialog"
    >
        {children}
    </Slide>;
});

export const EcommerceCheckoutComponent: React.FC<{
    location?: {
        state?: IEcommerceCheckoutComponentState;
    };
}> = ({ location }) => {
    const {
        checkoutFormRef,
        enableEcommerceIntegration,
        enableEcommercePlanSelectionRevampM0,
        enableEcommerceRemoveFocusProps,
        loading,
        paymentElementFormCompleted,
        setCheckoutFormRef,
        setLoading,
        setPaymentElementFormCompleted,
        setTermsAndConditionsAccepted,
        termsAndConditionsAccepted,
    } = useEcommerceCheckoutViewModel();

    // Prevents stripe.js from making calls to js.stripe.com when the feature is not enabled
    const { loadStripe } = enableEcommerceIntegration ? require('@stripe/stripe-js') : require('@stripe/stripe-js/pure');
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const logEcommerceEvent = useEcommerceTelemetry();
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();

    const { data: productsPricesInAllCurrenciesFetched } = useSWR(
        {},
        getPackagesPricesPublic,
    );

    const {
        skuPackage, selectedCurrency, billingInfo, existingMarketoData, productsPricesInAllCurrencies,
    } = useMemo(
        () => {
            const data = location?.state ?? {
                skuPackage: undefined,
                selectedCurrency: undefined,
                billingInfo: undefined,
                existingMarketoData: undefined,
                productsPricesInAllCurrencies: undefined,
            };
            if (!data.productsPricesInAllCurrencies && productsPricesInAllCurrenciesFetched) {
                data.productsPricesInAllCurrencies = productsPricesInAllCurrenciesFetched;
            }
            return data;
        },
        [ location?.state, productsPricesInAllCurrenciesFetched ],
    );
    const selectPlanRoute = useMemo(() => {
        if (enableEcommercePlanSelectionRevampM0) {
            return (skuPackage?.type === 'CUSTOM' ? BuyProIndividualProducts : BuyProPresets);
        }
        return BuyPro;
    }, [ enableEcommercePlanSelectionRevampM0, skuPackage ]);
    const {
        open, close: modalStateClose,
    } = useModalState(selectPlanRoute);

    const {
        publicKey, setSelectedPlanType,
    } = useEcommerce(skuPackage, selectedCurrency);

    const ga = Cookies.get('_ga');
    useEffect(() => {
        if (isDirectBuyInProgressFlow) {
            logEcommerceEvent('DirectBuy.OpenForm', { SelectedPlan: JSON.parse(UiStorage.getItem(DIRECT_BUY_DATA) ?? '{}').skuType });
            if (skuPackage?.planType) {
                setSelectedPlanType(skuPackage.planType);
            }
        }
    }, [ logEcommerceEvent, ga, isDirectBuyInProgressFlow, skuPackage, setSelectedPlanType ]);

    useProtectRouteFromMissingParams([ !skuPackage ], BuyPro);
    const locale = useLocalization();

    const close = () => {
        modalStateClose();
    };

    const stripeLocale = useMemo(() => mapAutomationCloudLanguageToStripe(locale), [ locale ]);

    useEffect(() => {
        if (publicKey) {
            loadStripe(publicKey, { locale: stripeLocale });
        }
    }, [ publicKey, stripeLocale, loadStripe ]);

    return (
        <Dialog
            disableAutoFocus={!enableEcommerceRemoveFocusProps}
            disableEnforceFocus={!enableEcommerceRemoveFocusProps}
            disableRestoreFocus
            className={classes.dialog}
            fullScreen
            open={open}
            onClose={() => close()}
            TransitionComponent={Transition}
            data-cy="checkout-dialog"
            aria-label={translate({ id: 'CLIENT_CHECKOUT_DIALOG' })}
            role="dialog"
            id="checkoutDialog"
            PaperProps={{
                className: classes.dialogPaperProps,
                'aria-labelledby': 'checkoutDialog',
            }}
        >
            <div className={classes.backBar}>
                <UiLogo />
                {!isSignupDirectBuyFlow && (
                    <Button
                        variant="text"
                        onClick={() => {
                            logEcommerceEvent(
                                'PaymentInfo.BackToAC', {
                                    SelectedPlan: skuPackage?.type,
                                    Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                                });

                            close();
                        }}
                        className={classes.backLink}
                        data-cy="back-to-automation-cloud-link"
                    >
                        {translate({ id: enableEcommercePlanSelectionRevampM0 ? 'CLIENT_BACK' : 'CLIENT_BACK_TO_AUTOMATION_CLOUD' })}
                    </Button>
                )}
            </div>
            <EcommerceCheckoutPaymentFormProvider
                savedBillingInfo={billingInfo}
                existingMarketoData={existingMarketoData}
            >
                <EcommerceCheckoutForm
                    existingMarketoData={existingMarketoData}
                    productsPricesInAllCurrencies={productsPricesInAllCurrencies}
                    setLoading={setLoading}
                    loading={loading}
                    paymentElementFormCompleted={paymentElementFormCompleted}
                    setPaymentElementFormCompleted={setPaymentElementFormCompleted}
                    checkoutFormRef={checkoutFormRef}
                    termsAndConditionsAccepted={termsAndConditionsAccepted}
                    setTermsAndConditionsAccepted={setTermsAndConditionsAccepted}
                    setCheckoutFormRef={setCheckoutFormRef}
                />
            </EcommerceCheckoutPaymentFormProvider>
        </Dialog>
    );
};
