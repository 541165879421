import { UiText } from '@experiences/ui-common';
import { isStringEmpty } from '@experiences/util';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grow from '@mui/material/Grow';
import type { InputProps } from '@mui/material/Input';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useDebounce } from 'use-debounce';

import type {
    IDirectoryEntry,
    ResolveDirectoryEntityType,
} from '../../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../../common/interfaces/cis/directory';
import type { SourceFilters } from '../../../services/identity/DirectoryService';
import { getDirectoryEntities } from '../../../services/identity/DirectoryService';
import { userPartitionUrl } from '../../../services/identity/UserPartitionService';
import { accountGlobalId } from '../../../store/selectors';
import type { UiPeoplePickerType } from './types';
import useUiPeoplePickerStringIds from './useUiPeoplePickerStringIds';
import type { IInputValidation } from './useUiPeoplePickerValidation';
import useUiPeoplePickerValidation from './useUiPeoplePickerValidation';

const useStyles = makeStyles(theme =>
    createStyles({
        icon: {
            fontSize: '24px',
            marginRight: '12px',
        },
        text: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
        },
        optionDisabled: { background: theme.palette.semantic.colorBackgroundRaised },
        tall: {
            minHeight: '72px',
            display: 'flex',
            alignItems: 'flex-start',
        },
        inputOverride: { minWidth: '100px !important' },
        chipPrimary: {
            backgroundColor: theme.palette.semantic.colorPrimary,
            color: theme.palette.semantic.colorForegroundInverse,
        },
        chipSecondary: {
            backgroundColor: theme.palette.semantic.colorErrorText,
            color: theme.palette.semantic.colorForegroundInverse,
        },
        deleteIcon: { color: `${theme.palette.semantic.colorForegroundInverse} !important` },
    }),
);

const GrowingPaper: React.FC<any> = ({
    children, classes,
}) => (
    <Grow
        in
        style={{ transformOrigin: '50% 0 0' }}
        timeout={750}>
        <Paper classes={classes}>
            {children}
        </Paper>
    </Grow>
);

interface IUiPeoplePicker {
    className?: any;
    type?: UiPeoplePickerType;
    value?: Array<string | IDirectoryEntry>;
    allowInput?: boolean;
    searchOnly?: boolean;
    inputPlaceholder?: string;
    setGlobalFilter?: (_: any) => any;
    InputProps?: InputProps;
    form?: boolean;
    disableClearable?: boolean;
    onChange?: (values: any[]) => void;
    setError?: () => void;
    clearErrors?: () => void;
    helper?: React.ReactNode;
    error?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    tall?: boolean;
    errors?: string[];
    useSafeName?: boolean;
    ariaLabelledBy?: string;
    ariaLabel?: string;
}

export const UiPeoplePicker = ({
    className,
    type = 'all',
    value = [],
    allowInput = true,
    searchOnly,
    inputPlaceholder,
    setGlobalFilter,
    InputProps,
    form = false,
    disableClearable = false,
    onChange,
    setError,
    clearErrors,
    helper,
    error,
    onOpen,
    onClose,
    tall,
    errors,
    useSafeName = false,
    ariaLabelledBy,
    ariaLabel,
}: IUiPeoplePicker) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ inputValidation, setInputValidation ] = useState<IInputValidation>();
    const [ query, setQuery ] = useState<string>('');
    const [ debouncedQuery ] = useDebounce(query, 200);
    const [ selected, setSelected ] = useState<Array<IDirectoryEntry | string>>([]);
    const partitionGlobalId = useSelector(accountGlobalId);
    const {
        defaultHelperTextId, defaultPlaceholderId,
    } = useUiPeoplePickerStringIds(type);
    const {
        validatePeoplePickerStringValue,
        validatePeoplePickerStringValues,
        validatePeoplePickerValues,
        getInputValidation,
        filterOptions,
    } = useUiPeoplePickerValidation();
    const [ inputValue, setInputValue ] = useState<string>('');

    const getEntityTypes = useMemo(() => {
        if (type === 'group') {
            return [ 'localGroups', 'directoryGroups' ];
        }

        if (type === 'user') {
            return [ 'localUsers', 'directoryUsers' ];
        }

        return [ 'localUsers', 'directoryUsers', 'robotAccounts', 'directoryGroups' ];
    }, [ type ]);

    const {
        data: options, isValidating: loading, error: optionsError,
    } = useSWR(
        debouncedQuery.trim() ? {
            url: userPartitionUrl,
            partitionGlobalId,
            sourceFilter: getEntityTypes as SourceFilters[],
            startsWith: debouncedQuery,
        } : null,
        getDirectoryEntities,
    );

    const determineIcon = useCallback((directoryType?: ResolveDirectoryEntityType) => {
        if (directoryType === 'LocalUser') {
            return 'basic_auth_user icon-basic_auth_user';
        } else if (directoryType === 'LocalGroup') {
            return 'local_group icon-local_group';
        } else if (directoryType === 'RobotAccount') {
            return 'robot icon-robot';
        } else if (directoryType === 'DirectoryUser') {
            return 'directory_user icon-directory_user';
        } else if (directoryType === 'DirectoryGroup') {
            return 'directory_group icon-directory_group';
        }

        return '';
    }, []);

    const determineColor = useCallback(
        (tag: IDirectoryEntry | string) => {
            if (typeof tag !== 'string') {
                return 'primary';
            }

            if (validatePeoplePickerStringValue(tag) && !errors?.find(e => e === tag)) {
                return 'default';
            }

            return 'secondary';
        },
        [ errors, validatePeoplePickerStringValue ],
    );

    const resolveUserInput = useCallback(
        (newValue: string) => {
            const commaSeparated = newValue
                .split(/[ ,]+/)
                .map(x => x.trim())
                .filter(x => !isStringEmpty(x));

            if (commaSeparated.length > 0) {
                let newValues: Array<string | IDirectoryEntry> = [];
                if (!allowInput && options && options.length === 1) {
                    const user: IDirectoryEntry = { ...options[0] };
                    newValues = [ user ];
                } else if (allowInput) {
                    if (!validatePeoplePickerStringValues(commaSeparated)) {
                        setError?.();
                    }

                    newValues = commaSeparated;
                }

                if (form) {
                    onChange?.(value.concat(newValues));
                } else {
                    setSelected(selected.concat(newValues));
                }
                setInputValue('');
            }
        },
        [ allowInput, form, onChange, options, selected, setError, validatePeoplePickerStringValues, value ],
    );

    const getSafeDisplayName = useCallback(
        (option: IDirectoryEntry, useEmailFallback = true) => {
            const identityNameFallback = useSafeName ? ` (${option.identityName})` : '';
            const emailFallback = useEmailFallback ? option.email : '';
            return `${option.displayName || emailFallback} ${identityNameFallback}`;
        },
        [ useSafeName ],
    );

    return (
        <Autocomplete
            multiple={!searchOnly}
            freeSolo={allowInput}
            disableClearable={disableClearable}
            options={options ?? []}
            value={searchOnly ? selected[0] : ((form ? value : selected) as any[])}
            inputValue={inputValue}
            getOptionLabel={(option: IDirectoryEntry | string) =>
                typeof option !== 'string' ? option.displayName || option.email || option.identityName : option}
            loading={!!loading}
            loadingText={translate({ id: 'CLIENT_PEOPLE_PICKER_LOADING' })}
            noOptionsText={query ? translate({ id: 'CLIENT_PEOPLE_PICKER_NO_OPTIONS' }, { query }) : ''}
            filterOptions={filterOptions}
            onInputChange={(event, newValue) => {
                if (!event) {
                    return;
                }

                setInputValue(newValue);

                const inputValidation = getInputValidation(newValue);
                setInputValidation(inputValidation);
                if (inputValidation.error) {
                    return;
                }

                setQuery(newValue.trim());

                if (searchOnly) {
                    setGlobalFilter?.(newValue);
                    return;
                }

                if (newValue.indexOf(',') > -1) {
                    resolveUserInput(newValue);
                }
            }}
            onOpen={onOpen}
            onClose={onClose}
            onChange={(_event, newValue, reason) => {
                onChange?.([ newValue ]);

                if (reason === 'removeOption' || reason === 'clear') {
                    clearErrors?.();
                }

                if (!newValue) {
                    return;
                }

                if (searchOnly) {
                    if (typeof newValue === 'string' || !newValue) {
                        setGlobalFilter?.(newValue);
                    } else {
                        setGlobalFilter?.((newValue as IDirectoryEntry).email ?? (newValue as IDirectoryEntry).displayName);
                    }
                    return;
                }

                if (form) {
                    if (Array.isArray(newValue)) {
                        onChange?.(newValue);
                    } else {
                        onChange?.([ newValue ]);
                    }
                } else {
                    if (Array.isArray(newValue)) {
                        setSelected(newValue);
                    } else {
                        setSelected([ newValue ]);
                    }
                }

                const values = newValue as Array<string | IDirectoryEntry>;
                if (!validatePeoplePickerValues(values)) {
                    setError?.();
                }
            }}
            renderOption={(props, option: IDirectoryEntry) => (
                <ListItem
                    {...props}
                    disabled={(form ? value : selected).includes(option)}
                    divider
                    disableGutters>
                    <i className={clsx(determineIcon(option.objectType), classes.icon)} />
                    <div className={classes.text}>
                        <UiText>
                            {getSafeDisplayName(option, false)}
                        </UiText>
                        {option.type === DirectoryEntityType.user && (
                            <UiText>
                                {searchOnly === true ||
                !(form ? value : selected).find(x =>
                    typeof x === 'string' ? x === option.email : x.identifier === option.identifier,
                )
                                    ? option.email
                                    : translate({ id: 'CLIENT_PEOPLE_PICKER_ALREADY_ADDED' })}
                            </UiText>
                        )}
                    </div>
                </ListItem>
            )}
            isOptionEqualToValue={(option: IDirectoryEntry) => {
                if (searchOnly) {
                    return false;
                }

                return !!(form ? value : selected).find(x =>
                    typeof x === 'string' ? x === option.email : x.identifier === option.identifier,
                );
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    className={className}
                    InputProps={{
                        ...params.InputProps,
                        ...InputProps,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        'aria-labelledby': ariaLabelledBy,
                        'aria-label': ariaLabel,
                    }}
                    variant="outlined"
                    placeholder={
                        !(form ? value : selected)?.length ? inputPlaceholder ?? translate({ id: defaultPlaceholderId }) : ''
                    }
                    error={inputValidation?.error || error || !!optionsError}
                    helperText={inputValidation?.errorMessage || helper || translate({ id: defaultHelperTextId })}
                    multiline={!searchOnly}
                    data-cy="ui-people-picker-input"
                />
            )}
            renderTags={(tags: Array<IDirectoryEntry | string>, getProps) =>
                tags.map((tag, i) => {
                    const label = typeof tag !== 'string' ? getSafeDisplayName(tag) : tag;
                    return (
                        <Chip
                            {...getProps({ index: i })}
                            key={`people-picker-chip-${label}`}
                            classes={
                                !searchOnly
                                    ? {
                                        colorPrimary: classes.chipPrimary,
                                        colorSecondary: classes.chipSecondary,
                                        deleteIcon: classes.deleteIcon,
                                    }
                                    : undefined
                            }
                            label={label}
                            color={determineColor(tag)}
                        />
                    );
                })}
            PaperComponent={GrowingPaper}
            classes={{
                option: classes.optionDisabled,
                inputRoot: clsx(tall && classes.tall),
                input: clsx(!searchOnly && classes.inputOverride),
            }}
            data-cy="ui-people-picker"
            aria-labelledby={ariaLabelledBy}
            aria-label={ariaLabel}
        />
    );
};
