import React from 'react';

interface UiLegendProps {
    color: string;
    text: string;
}

export const UiLegend: React.FC<UiLegendProps> = ({
    color, text,
}) => {
    const styles = {
        legend: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '5px',
            marginTop: '8px',
            fontSize: '10px',
        },
        colorBox: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginRight: '5px',
            backgroundColor: color,
        },
    };

    return (
        <div style={styles.legend}>
            <div style={styles.colorBox} />
            {text}
        </div>
    );
};
