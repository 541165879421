import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getSetting } from '../../services/identity/SettingService';
import {
    accountGlobalId,
    userGlobalId,
} from '../../store/selectors';

const settingUrl = `${process.buildConfigs.identityApiBaseRoute}/Setting`;

export const useGetSetting = (keys: string[], passUserId = false) => {
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountId = useSelector(userGlobalId);

    return useSWR(
        {
            url: settingUrl,
            key: keys,
            partitionGlobalId,
            userId: passUserId ? accountId : undefined,
        },
        getSetting,
    );
};
