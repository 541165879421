import { AccountLicense } from '@experiences/constants';
import { SecuritySettingsEvent } from '@experiences/telemetry';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import useCheckLicense from '../../../../common/hooks/useCheckLicense';
import useIsOnPrem from '../../../../common/hooks/useIsOnPrem';
import UpgradeForFeature from '../../../common/UpgradeForFeature';
import OrganizationAccessPolicyGridComponent from './OrganizationAccessPolicyGridComponent';
import OrganizationAccessPolicySettingComponent from './OrganizationAccessPolicySettingComponent';

const useStyles = makeStyles(createStyles({ page: { width: '100%' } }));

const OrganizationAccessPolicyComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const { isEnterprise } = useCheckLicense();
    const isOnPrem = useIsOnPrem();

    if (!isOnPrem && !isEnterprise) {
        return <UpgradeForFeature
            upgradeTitle={translate({ id: 'CLIENT_UPGRADE_ACCESS_POLICY_TITLE' })}
            upgradeMessage={translate({ id: 'CLIENT_ONLY_ALLOWED_MEMBERS_DESCRIPTION' })}
            level={AccountLicense.ENTERPRISE}
            telemetryTitle={SecuritySettingsEvent.AccessPolicy}
        />;
    }

    return <div className={classes.page}>
        <OrganizationAccessPolicySettingComponent />
        <OrganizationAccessPolicyGridComponent />
    </div>;
};

export default OrganizationAccessPolicyComponent;
