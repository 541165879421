import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { IConsumablePool } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import {
    getShortNumber,
    roundTo2Decimals,
} from '@experiences/util';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';

import { UiConsumableProgressBar } from './UiConsumableProgressBar';

const useStyles = makeStyles(theme =>
    createStyles({
        poolDescriptionText: {
            color: theme.palette.semantic.colorForeground,
            marginTop: '8px',
            marginBottom: '8px',
        },
        infoIcon: {
            padding: '2px',
            verticalAlign: 'text-bottom',
        },
        link: {
            textDecoration: 'underline',
            color: theme.palette.semantic.colorBackground,
        },
    }),
);

export const UiConsumablePool: React.FC<{
    consumableCode: string;
    pool: IConsumablePool;
}> = ({
    consumableCode, pool,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const startDate = useMemo(() => (pool.startDate ? new Date(pool.startDate * 1000) : new Date()), [ pool.startDate ]);
    const endDate = useMemo(() => (pool.endDate ? new Date(pool.endDate * 1000) : new Date()), [ pool.endDate ]);
    const enableRUIntervalMigration = useFeatureFlagValue(Features.EnableRUIntervalMigration.name);
    const renderTooltipLink = useCallback((chunks: React.ReactNode[]) => <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href='https://licensing.uipath.com/'>
        {chunks}
    </a>, [ classes.link ]);

    const consumptionPoolDescription = useMemo(() =>
        enableRUIntervalMigration ? `CLIENT_CONSUMABLE_CARD_${consumableCode}_${pool.name}_NEW` : `CLIENT_CONSUMABLE_CARD_${consumableCode}_${pool.name}`,
    [ consumableCode, pool, enableRUIntervalMigration ]);

    const consumptionPoolTooltip = useMemo(() => {
        const translation = translate({
            id: `CLIENT_CONSUMABLE_CARD_TOOLTIP_${consumableCode}_${pool.name}`,
            defaultMessage: `CLIENT_CONSUMABLE_CARD_TOOLTIP_${consumableCode}_${pool.name}`,
        }, { linktag: renderTooltipLink });
        return translation !== `CLIENT_CONSUMABLE_CARD_TOOLTIP_${consumableCode}_${pool.name}`
            && (consumableCode !== 'RU' || !enableRUIntervalMigration) ? translation : undefined;
    }, [ consumableCode, pool, translate, renderTooltipLink, enableRUIntervalMigration ]);

    return <>
        <UiText className={classes.poolDescriptionText}>
            {translate(
                { id: consumptionPoolDescription },
                {
                    0: getShortNumber(pool.total),
                    1: <FormattedDate
                        value={Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate())}
                        year="numeric"
                        month="short"
                        day="numeric"
                        timeZone="UTC"
                    />,
                    2: <FormattedDate
                        value={Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate())}
                        year="numeric"
                        month="short"
                        day="numeric"
                        timeZone="UTC"
                    />,
                },
            )}
            { consumptionPoolTooltip &&
                <Tooltip
                    title={
                        <>
                            {consumptionPoolTooltip}
                        </>
                    }>
                    <InfoOutlined
                        fontSize='small'
                        className={classes.infoIcon} />
                </Tooltip>}
        </UiText>
        <UiConsumableProgressBar
            consumableCode={consumableCode}
            consumed={roundTo2Decimals(pool.consumed)}
            total={roundTo2Decimals(pool.total)} />
    </>;
};
