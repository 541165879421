import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useLocalization,
    useSupportedLanguagesMap,
} from '@experiences/locales';
import {
    LocalizationEvent,
    ThemeEvent,
} from '@experiences/telemetry';
import {
    themeMap,
    useApolloTheme,
} from '@experiences/theme';
import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { ThemeType } from '@uipath/portal-shell-util';
import {
    defaultLanguage,
    defaultTheme,
} from '@uipath/portal-shell-util';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import useSetUserLanguage from '../../../common/hooks/useSetUserLanguage.default';
import useSetUserTheme from '../../../common/hooks/useSetUserTheme.default';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        formContainer: { maxWidth: '482px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
        radio: {
            marginTop: '14px',
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        rowGroup: {
            marginLeft: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        section: { paddingBottom: '20px' },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
            fontWeight: 600,
        },
    }),
);

interface IProfilePreferencesData {
    language: string;
    theme: ThemeType;
}

const ProfilePreferencesComponent: React.FC = () => {
    const isGov = window.env?.ENVIRONMENT?.includes('gov');

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const setUserLanguage = useSetUserLanguage();
    const setUserTheme = useSetUserTheme();
    const selectedLanguage = useLocalization(false);
    const { selectedThemeId } = useApolloTheme();
    const languageOptions = useSupportedLanguagesMap();
    const {
        control,
        setValue,
        formState: { errors },
    } = useForm<IProfilePreferencesData>({
        mode: 'onChange',
        defaultValues: {
            language: isGov ? defaultLanguage : (selectedLanguage ?? defaultLanguage).toLowerCase(),
            theme: selectedThemeId ?? defaultTheme,
        },
    });
    const prevLangRef = useRef<string>();
    prevLangRef.current = selectedLanguage;
    const prevThemeRef = useRef<ThemeType>();
    const { logEvent } = useTelemetryHelper();

    const highContrastLightThemeFlag = useFeatureFlagValue(Features.EnableHighContrastLightTheme.name);

    const handleThemeSelect = useCallback((newTheme: ThemeType) => {
        if (!highContrastLightThemeFlag && newTheme === 'light-hc') {
            return;
        }

        logEvent(ThemeEvent.ChangeTheme, {
            ProductThemeOld: prevThemeRef.current,
            ProductThemeNew: newTheme,
        });
        setUserTheme(newTheme);
        prevThemeRef.current = newTheme;
    }, [ highContrastLightThemeFlag, logEvent, setUserTheme ]);

    const handleLanguageSelect = useCallback((newLanguage: string) => {
        logEvent(LocalizationEvent.ChangeLanguage, {
            ProductLanguageOld: prevLangRef.current,
            ProductLanguageNew: newLanguage,
        });
        setUserLanguage(newLanguage);
        prevLangRef.current = newLanguage;
    }, [ logEvent, setUserLanguage ]);

    useEffect(() => {
        if (selectedThemeId) {
            setValue('theme', selectedThemeId);
        }
    }, [ selectedThemeId, setValue ]);

    useEffect(() => {
        if (selectedLanguage) {
            setValue('language', selectedLanguage.toLowerCase());
        }
    }, [ selectedLanguage, setValue ]);

    let themes = Object.entries(themeMap);
    if (!highContrastLightThemeFlag) {
        themes = themes.filter(([ theme ]) => theme !== 'light-hc');
    }

    return (
        <div className={classes.formContainer}>
            <div className={classes.section}>
                <UiText
                    className={clsx(classes.heading, classes.mainHeading)}
                    role="heading"
                    aria-level={2}>
                    { translate({ id: highContrastLightThemeFlag ? 'CLIENT_LANGUAGE' : 'CLIENT_PREFERENCES' })}
                </UiText>
                { !isGov && <UiSelect
                    control={control}
                    dataCy="profile-preferences-language-select"
                    inputLabel={translate({ id: 'CLIENT_LANGUAGE' })}
                    name="language"
                    options={languageOptions}
                    error={!!errors.language}
                    fullWidth
                    isTranslated
                    required
                    onChange={event => handleLanguageSelect(event.target.value)}
                /> }
            </div>
            {!highContrastLightThemeFlag && (
                <div className={classes.section}>
                    <UiText className={classes.subHeading}>
                        {translate({ id: 'CLIENT_THEME' })}
                    </UiText>
                    <UiText>
                        {translate({ id: 'CLIENT_THEME_CHANGE_DESCRIPTION' })}
                    </UiText>
                    <Controller
                        control={control}
                        name="theme"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <RadioGroup
                                data-cy="profile-preferences-theme-radio-buttons"
                                aria-label={translate({ id: 'CLIENT_THEME_CONTROL_DESCRIPTION' })}
                                className={classes.rowGroup}
                                value={field.value}
                                onChange={event => {
                                    field.onChange(event.target.value);
                                    handleThemeSelect(event.target.value as ThemeType);
                                }}
                            >
                                {themes.map(([ reasonId, reasonValue ], i) => (
                                    <FormControlLabel
                                        key={i}
                                        className={classes.radio}
                                        control={<Radio
                                            value={reasonId}
                                            checked={field.value === reasonId}
                                            color="primary" />}
                                        label={translate({ id: reasonValue })}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                </div>)}
        </div>
    );
};

export default ProfilePreferencesComponent;
