/* eslint-disable max-len */
import {
    AccountLicense,
    SSORecommendedDeploymentLinks,
} from '@experiences/constants';
import { SecuritySettingsEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    getEnvVariableValue,
    useLocalizedLinks,
    useModalState,
    useNavigateWithParams,
} from '@experiences/util';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import configureSSOIcon1 from '../../../images/configureSSOIcon1.svg';
import configureSSOIcon2 from '../../../images/configureSSOIcon2.svg';
import configureSSOIcon3 from '../../../images/configureSSOIcon3.svg';
import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
} from '../../../services/identity/AuthenticationSettingService';
import { isHostModeSelector } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';

interface IUiCardThemeProps {
    clickable: boolean;
}

const useStyles = makeStyles<Theme, IUiCardThemeProps>(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        card: props => ({
            '&:hover': props.clickable ?
                {
                    boxShadow: `0px 1px 10px ${theme.palette.semantic.colorBackgroundGray}, 0px 4px 5px ${theme.palette.semantic.colorBackgroundGray}, 0px 2px 4px -1px ${theme.palette.semantic.colorBackgroundGray}`,
                    cursor: 'pointer',
                } : undefined,
            boxShadow: props.clickable ? `0px 2px 1px -1px ${theme.palette.semantic.colorBackgroundGray}, 0px 1px 1px ${theme.palette.semantic.colorBackgroundGray}, 0px 1px 3px ${theme.palette.semantic.colorBackgroundGray}` : '',
            border: `1px ${theme.palette.semantic.colorBorderDisabled}`,
            maxWidth: '300px',
        }),
        mainContent: {
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            margin: '24px 24px 24px 0',
        },
        headerText: { fontWeight: 600 },
        spacer: { marginTop: '24px' },
        cardSection: {
            display: 'flex',
            flexDirection: 'row',
        },
        cardTitleText: props => ({
            fontWeight: 600,
            fontSize: '16px',
            color: props.clickable ? theme.palette.semantic.colorForeground : theme.palette.semantic.colorForegroundDisable,
        }),
        cardDescriptionText: props => ({
            marginTop: '8px',
            fontWeight: 400,
            fontSize: '14px',
            color: props.clickable ? theme.palette.semantic.colorForeground : theme.palette.semantic.colorForegroundDisable,
        }),
        ssoAdvantage: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
        },
        chooseSSOTitle: {
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'row',
        },
        chipSpacer: { marginLeft: '8px' },
    }),
}));

export const ConfigureSSOComponent: React.FC = () => {
    const navigate = useNavigateWithParams();
    const getLocalizedLink = useLocalizedLinks();

    const { formatMessage: translate } = useIntl();
    const { isOffersRevampAndCommunity } = useCheckLicense();

    const isHostMode = useSelector(isHostModeSelector);

    const classes = useStyles({ clickable: !isOffersRevampAndCommunity });

    const authenticationSetting = useCheckAuthenticationSetting();

    const {
        open, close,
    } = useModalState(RouteNames.SecuritySettings);

    const ssoAdvantageDisplay = useCallback((label: string, image: any) => (
        <span className={classes.ssoAdvantage}>
            <img
                style={{ marginRight: '8px' }}
                src={image}
                alt='' />
            <UiText>
                {translate({ id: label })}
            </UiText>
        </span>
    ), [ classes.ssoAdvantage, translate ]);

    const ssoAdvantageList = useMemo(() =>
        [
            {
                label: 'CLIENT_SSO_ADVANTAGE_1',
                icon: configureSSOIcon1,
            },
            {
                label: 'CLIENT_SSO_ADVANTAGE_2',
                icon: configureSSOIcon2,
            },
            {
                label: 'CLIENT_SSO_ADVANTAGE_3',
                icon: configureSSOIcon3,
            },
        ], [],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_SSO_CONFIGURATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="large"
        >
            <div className={classes.mainContent}>
                <UiText className={classes.headerText}>
                    {translate({ id: 'CLIENT_SSO_ADVANTAGES' })}
                </UiText>
                <div>
                    {ssoAdvantageList.map((item, i) => <div key={`sso-advantage-${i}`}>
                        {ssoAdvantageDisplay(item.label, item.icon)}
                    </div>)}
                </div>
                <UiText className={clsx(classes.headerText, classes.spacer)}>
                    {translate({ id: 'CLIENT_PREREQ' })}
                </UiText>

                <ul style={{ lineHeight: '24px' }}>
                    {[ 1, 2, 3, 4 ].map((number) => <li key={number}>
                        {number === 3
                            ? <UiText>
                                <FormattedMessage
                                    id="CLIENT_SSO_PREREQ_3"
                                    values={{
                                        a: (msg: React.ReactNode[]) =>
                                            (
                                                <a
                                                    className={classes.a}
                                                    href={getLocalizedLink(SSORecommendedDeploymentLinks)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-cy="automation-docs-link"
                                                >
                                                    {msg}
                                                </a>
                                            ),
                                    }}
                                />
                            </UiText>
                            : <UiText>
                                {translate({ id: 'CLIENT_SSO_PREREQ_' + String(number) })}
                            </UiText>}
                    </li>)}
                </ul>
                <div className={classes.chooseSSOTitle}>
                    <UiText className={classes.headerText}>
                        {translate({ id: 'CLIENT_CHOOSE_SSO_OPTION' })}
                    </UiText>
                    {isOffersRevampAndCommunity &&
                            <UiUpgradeChip
                                className={classes.chipSpacer}
                                licenseType={AccountLicense.ENTERPRISE}
                                title={translate({ id: 'CLIENT_CHIP_SSO_HEADER' })}
                                description={translate({ id: 'CLIENT_CHIP_SSO_DESCRIPTION' })}
                                iconDescription={translate({ id: 'CLIENT_CHIP_SSO_ICON_DESCRIPTION' })}
                                icon={
                                    <img
                                        src={configureSSOIcon2}
                                        alt='' />
                                }
                                telemetryTitle={SecuritySettingsEvent.LearnMoreEnterprise} />}
                </div>
                <div className={clsx(classes.cardSection, classes.spacer)}>
                    {!isHostMode && <Card
                        onClick={() => !isOffersRevampAndCommunity
                            ? navigate(`${RouteNames.SecuritySettings}/configure/${AuthenticationSettingType.SAML}`)
                            : undefined}
                        data-cy='saml-configure-card'
                        className={classes.card}
                        style={{ marginRight: '16px' }}
                        aria-label="saml-configure-card">
                        <CardContent>
                            <div
                                className={classes.cardSection}
                                style={{ alignItems: 'center' }}>
                                <div>
                                    <UiText
                                        className={classes.cardTitleText}>
                                        {translate({ id: 'CLIENT_SAML_INTEGRATION' })}
                                    </UiText>
                                </div>

                                {authenticationSetting?.[BulkAuthenticationSettingKey.SAML]?.authenticationSettingType?.toLowerCase()
                                    === AuthenticationSettingType.SAML &&
                                <Chip
                                    style={{ marginLeft: '8px' }}
                                    variant="outlined"
                                    size="small"
                                    label={translate({ id: 'CLIENT_ENABLED' })}
                                    className="success-mini"
                                    data-cy="saml-configure-card-enabled" />}
                            </div>
                            <UiText className={classes.cardDescriptionText}>
                                {translate({ id: 'CLIENT_SAML2_CARD_DESCRIPTION' }, { productName: getEnvVariableValue('PRODUCT_NAME') })}
                            </UiText>
                        </CardContent>
                    </Card>}
                    {!isHostMode && <Card
                        onClick={() => !isOffersRevampAndCommunity
                            ? navigate(`${RouteNames.SecuritySettings}/configure/${AuthenticationSettingType.AAD}`)
                            : undefined}
                        data-cy='aad-configure-card'
                        className={classes.card}
                        aria-label="aad-configure-card">
                        <CardContent>
                            <div
                                className={classes.cardSection}
                                style={{ alignItems: 'center' }}>
                                <div>
                                    <UiText
                                        className={classes.cardTitleText}>
                                        {translate({ id: 'CLIENT_AAD_INTEGRATION' })}
                                    </UiText>
                                </div>

                                {authenticationSetting?.['aad']?.authenticationSettingType?.toLowerCase()
                                    === AuthenticationSettingType.AAD &&
                                <Chip
                                    style={{ marginLeft: '8px' }}
                                    variant="outlined"
                                    size="small"
                                    label={translate({ id: 'CLIENT_ENABLED' })}
                                    className="success-mini"
                                    data-cy="aad-configure-card-enabled" />}
                            </div>
                            <UiText className={classes.cardDescriptionText}>
                                {translate({ id: 'CLIENT_AAD_CARD_DESCRIPTION' }, { productName: getEnvVariableValue('PRODUCT_NAME') })}
                            </UiText>
                        </CardContent>
                    </Card>}
                </div>
            </div>
        </UiDrawer>
    );
};

export default ConfigureSSOComponent;
