export enum Usage {
    Signing = 'Signing',
    Encryption = 'Encryption',
    SigningAndEncryption = 'SigningAndEncryption',
}

export enum StoreName {
    AddressBook = 'AddressBook',
    AuthRoot = 'AuthRoot',
    CertificateAuthority = 'CertificateAuthority',
    Disallowed = 'Disallowed',
    My = 'My',
    Root = 'Root',
    TrustedPeople = 'TrustedPeople',
    TrustedPublisher = 'TrustedPublisher',
}

export enum StoreLocation {
    CurrentUser = 'CurrentUser',
    LocalMachine = 'LocalMachine',
}

export enum ExternalUserMappingStrategy {
    ByUserEmail = 0,
    ByUserName = 1,
    ByExternalProviderKey = 2,
}

export enum Saml2BindingType {
    HttpRedirect = 'HttpRedirect',
    HttpPost = 'HttpPost',
    Artifact = 'Artifact',
}

export enum ExternalAuthenticationScheme {
    AzureAD = 'AzureAD',
    Google = 'google',
    Saml2 = 'Saml2',
    Windows = 'Windows.Scheme',
    Negotiate = 'Negotiate',
}

export const externalUserMappingStrategyOptions = {
    [ExternalUserMappingStrategy.ByExternalProviderKey]: 'CLIENT_BY_EXTERNAL_PROVIDER_KEY_MAPPING_STRATEGY',
    [ExternalUserMappingStrategy.ByUserEmail]: 'CLIENT_BY_USER_EMAIL_MAPPING_STRATEGY',
    [ExternalUserMappingStrategy.ByUserName]: 'CLIENT_BY_USERNAME_MAPPING_STRATEGY',
};

export const samlBindingTypeOptions = {
    [Saml2BindingType.HttpRedirect]: 'CLIENT_HTTP_REDIRECT_BINDING_TYPE',
    [Saml2BindingType.HttpPost]: 'CLIENT_HTTP_POST_BINDING_TYPE',
    [Saml2BindingType.Artifact]: 'CLIENT_ARTIFACT_BINDING_TYPE',
};

export const certificateLocationUsageOptions = {
    [Usage.SigningAndEncryption]: 'CLIENT_SIGNING_AND_ENCRYPTION_CERTIFICATE_LOCATION_USAGE',
    [Usage.Encryption]: 'CLIENT_ENCRYPTION_CERTIFICATE_LOCATION_USAGE',
    [Usage.Signing]: 'CLIENT_SIGNING_CERTIFICATE_LOCATION_USAGE',
};

export const certificateLocationStoreNameOptions = {
    [StoreName.AddressBook]: 'CLIENT_ADDRESS_BOOK_STORE_NAME_OPTION',
    [StoreName.AuthRoot]: 'CLIENT_AUTH_ROOT_STORE_NAME_OPTION',
    [StoreName.CertificateAuthority]: 'CLIENT_CERTIFICATE_AUTHORITY_STORE_NAME_OPTION',
    [StoreName.Disallowed]: 'CLIENT_DISALLOWED_STORE_NAME_OPTION',
    [StoreName.My]: 'CLIENT_MY_STORE_NAME_OPTION',
    [StoreName.Root]: 'CLIENT_ROOT_STORE_NAME_OPTION',
    [StoreName.TrustedPeople]: 'CLIENT_TRUSTED_PEOPLE_STORE_NAME_OPTION',
    [StoreName.TrustedPublisher]: 'CLIENT_TRUSTED_PUBLISHER_STORE_NAME_OPTION',
};

export const certificateLocationStoreLocationOptions = {
    [StoreLocation.CurrentUser]: 'CLIENT_CURRENT_USER_STORE_LOCATION_OPTION',
    [StoreLocation.LocalMachine]: 'CLIENT_LOCAL_MACHINE_STORE_LOCATION_OPTION',
};
