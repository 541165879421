import { makeStyles } from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';

import { CarouselButton } from './CarouselButton';
import { CarouselCloseButton } from './CarouselCloseButton';
import CarouselSlideBottomLeftImageStyle from './CarouselSlideBottomLeftImageStyle';
import CarouselSlideBottomRightImageStyle from './CarouselSlideBottomRightImageStyle';
import CarouselSlideFullLeftImageStyle from './CarouselSlideFullLeftImageStyle';
import CarouselSlideFullRightImageStyle from './CarouselSlideFullRightImageStyle';
import type { CarouselSlideParameters } from './types/CarouselSlideParameters';

const useStyles = makeStyles((theme) => ({
    carouselInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: '20px',
    },
    carouselSlidesBackground: {
        backgroundColor: theme.palette.semantic.colorToggleOffHover,
        width: '100%',
        height: '232px',
        padding: '0px',
        overflow: 'hidden',
        borderRadius: '8px',
    },
    carouselSlideDisplay: {
        width: '100%',
        height: '232px',
        maxHeight: '232px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        transition: 'transform .5s',
        gap: '1%',
    },
    carouselButtonDisplay: {
        display: 'flex',
        justifyContent: 'center',
        columnGap: '20px',
        width: '100%',
        height: '10px',
        alignItems: 'flex-start',
        marginBottom: '16px',
    },
    carouselSlideBackground: {
        width: '100%',
        minWidth: '100%',
        height: '232px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
    },
}));

interface CarouselInnerProps {
    slides: CarouselSlideParameters[];
    setOpen: Function;
}

export const CarouselWrapper = ({
    slides, setOpen,
}: CarouselInnerProps) => {
    const [ slideState, setSlide ] = useState({ currentSlide: 0 });
    const classes = useStyles();

    const getSlideStyle = useCallback((slide: CarouselSlideParameters, idx: number) => {
        switch (slide.backgroundImagePosition) {
            case 'Bottom Left':
                return <CarouselSlideBottomLeftImageStyle
                    {...slide}
                    setOpen={setOpen}
                    idx={idx}
                    currSlide={slideState.currentSlide} />;
            case 'Full Left':
                return <CarouselSlideFullLeftImageStyle
                    {...slide}
                    setOpen={setOpen}
                    idx={idx}
                    currSlide={slideState.currentSlide} />;
                // eslint-disable-next-line no-fallthrough
            case 'Full Right':
                return <CarouselSlideFullRightImageStyle
                    {...slide}
                    setOpen={setOpen}
                    idx={idx}
                    currSlide={slideState.currentSlide} />;
                // eslint-disable-next-line no-fallthrough
            default:
                return <CarouselSlideBottomRightImageStyle
                    {...slide}
                    setOpen={setOpen}
                    idx={idx}
                    currSlide={slideState.currentSlide} />;
        }
    }, [ setOpen, slideState.currentSlide ]);

    return (
        <div
            data-cy="carousel-inner"
            className={classes.carouselInner}>
            <div className={classes.carouselSlidesBackground}>
                <div
                    className={classes.carouselSlideDisplay}
                    style={{
                        transform: 'translateX(-' +
                            String(slideState.currentSlide * 101) +
                            '%)',
                    }}
                >
                    {slides.map((slide, idx) => (
                        <div
                            key={idx}
                            className={classes.carouselSlideBackground}
                        >
                            <CarouselCloseButton
                                fullHeight={slide.backgroundImagePosition === 'Full Left' || slide.backgroundImagePosition === 'Full Right'}
                                {...slide}
                                idx={idx}
                                currSlide={slideState.currentSlide}
                                setOpen={setOpen} />
                            {getSlideStyle(slide, idx)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.carouselButtonDisplay}>
                {slides.map((_slide, idx) => (
                    <CarouselButton
                        slideId={_slide.id}
                        idx={idx}
                        currentSlide={slideState.currentSlide}
                        setSlide={setSlide}
                        key={idx.toString()} />
                ))}
            </div>
        </div>
    );
};
