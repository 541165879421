import { useGetErrorInfo } from '@experiences/error';
import type {
    ILabelModel,
    IPagination,
    IValueModelObject,
    LabelDataType,
    TagType,
} from '@experiences/interfaces';
import {
    OrchestratorObjectType,
    PermissionType,
} from '@experiences/interfaces';
import { PortalTagManagementEvent } from '@experiences/telemetry';
import { useUiDataContext } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import type React from 'react';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    createKeyValue,
    deleteValueFromKey,
    deleteValuesFromKey,
    getLabel,
    getPermissions,
    getValuesForKey,
    tagsUrl,
    updateLabel,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    tenantByIdUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import TenantTagsDeleteDialogBody from '../../common/TenantTagsDeleteDialogBody';
import TenantTagsSaveWarningDialogBody from '../../common/TenantTagsSaveWarningDialogBody';
import { useTagsCancel } from '../../common/useTagsCancel';
import RegexWarningDialogBody from './RegexWarningDialogBody';
import {
    hasPermission,
    isValidRegex,
    validateName,
} from './TenantTagsUtil';
import type { ITenantTagsPropertiesContext } from './types';
import ValuesWarningDialogBody from './ValuesWarningDialogBody';

interface ITagLabelDto extends Omit<Partial<ILabelModel>, 'values'> {
    values: IValueModelObject[];
}

const DEFAULT_VALUES = {
    key: undefined,
    name: '',
    description: '',
    dataType: 'String' as LabelDataType,
    regex: undefined,
    type: 'KeyValue' as TagType,
    values: [],
};

export const ValueDataTypes = {
    ['String']: 'CLIENT_VALUE_DATA_TYPE_STRING',
    ['Number']: 'CLIENT_VALUE_DATA_TYPE_NUMBER',
    ['Boolean']: 'CLIENT_VALUE_DATA_TYPE_BOOLEAN',
    ['Regex']: 'CLIENT_VALUE_DATA_TYPE_REGEX',
};

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const useAddEditTenantTagsPropertiesViewModel = ({ type }: { type: 'add' | 'edit' }) => {
    const { formatMessage: translate } = useIntl();
    const {
        tenantId, id, editType,
    } = useParams() as { tenantId: string; id: string; editType: string };
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const createDialog = useShowDialog();
    const { getErrorMessage } = useGetErrorInfo();
    const createNotification = useUiSnackBar();
    const { logEvent } = useTelemetryHelper();

    const organizationName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);

    const [ prevDataType, setPrevDataType ] = useState<LabelDataType>('String');
    const [ prevRegex, setPrevRegex ] = useState('');
    const [ pagination, setPagination ] = useState<IPagination>({
        searchTerm: '',
        top: DEFAULT_PAGE_SIZE,
        skip: DEFAULT_PAGE_INDEX * DEFAULT_PAGE_SIZE,
    });

    const {
        register, control, handleSubmit, reset, setError, formState, setValue, getValues, clearErrors, watch,
    } = useForm<ITagLabelDto>({
        mode: 'onSubmit',
        defaultValues: DEFAULT_VALUES,
    });

    const {
        errors, isDirty,
    } = formState;

    const [ values, dataType, regex ] = watch([ 'values', 'dataType', 'regex' ]);

    const refreshState = useState(false);
    const [ _, setRefresh ] = refreshState;

    const isAdd = useMemo(() => type === 'add', [ type ]);
    const isEditDetails = useMemo(() => type === 'edit' && editType === 'details', [ editType, type ]);
    const isEditKeyValues = useMemo(() => type === 'edit' && editType === 'keyValues', [ editType, type ]);

    const cancel = useTagsCancel(isDirty, !isEditKeyValues);

    const { mutate } = useSWRConfig();

    const {
        data: tenant, isValidating: tenantLoading,
    } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const {
        data: propertyData, isValidating: propertyLoading,
    } = useSWR(
        type === 'edit' && ((id && tenant) || (process.buildConfigs.showForMSI && accountId && id))
            ? {
                url: `${tagsUrl}/keyValue`,
                accountLogicalName: organizationName,
                tenantName: tenant?.name,
                id,
                selectedAccountId: accountId,
            }
            : null,
        getLabel,
    );

    const {
        data: valuesData, mutate: valuesMutate, isValidating: valuesLoading,
    } = useSWR(
        isEditKeyValues && ((id && tenant) || (process.buildConfigs.showForMSI && accountId && tenant))
            ? {
                url: `${tagsUrl}/keyValue/values`,
                pagination,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                key: id,
                selectedAccountId: accountId,
            } : null,
        getValuesForKey,
    );

    const { data: permissions } = useSWR(
        (organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant) ?
            {
                url: `${tagsUrl}/permissions`,
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            } : null,
        getPermissions,
    );

    const isBooleanDataType = useMemo(() => propertyData ? propertyData.dataType === 'Boolean' : dataType === 'Boolean', [ propertyData, dataType ]);

    const isRegex = useMemo(() => propertyData ? propertyData.dataType === 'Regex' : dataType === 'Regex', [ dataType, propertyData ]);

    const onChangePagination = useCallback(({
        pageIndex, pageSize, searchTerm,
    }: any /* REACT_18_TODO: should be IGridFilters, but its not exported */) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
        };

        setPagination(newPaginationState);

        if (tenant) {
            mutate({
                url: `${tagsUrl}/keyValue/values`,
                pagination: newPaginationState,
                accountLogicalName: organizationName,
                tenantName: tenant?.name,
                key: id,
                selectedAccountId: accountId,
            });
        }
    }, [ accountId, id, mutate, organizationName, tenant ]);

    const onSubmit = useCallback(async (data: ITagLabelDto) => {
        try {
            if (data.name && !validateName(data.name)) {
                setError('name', {
                    type: 'invalid',
                    message: translate({ id: 'CLIENT_TENANT_TAGS_NAME_ERROR' }),
                });
                return;
            }
            if (isAdd) {
                const mappedData = {
                    name: data.name,
                    description: data.description,
                    dataType: data.dataType,
                    regex: data.dataType === 'Regex' ? data.regex : undefined,
                    type: 'KeyValue' as TagType,
                    values: data.values.map(valObj => valObj.normalizedValue),
                };
                await createKeyValue(
                    organizationName,
                    tenant?.name,
                    mappedData,
                    accountId,
                );
                logEvent(PortalTagManagementEvent.CreateProperty);
            } else {
                if (propertyData?.entityReferencesCount) {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TENANT_TAGS_OBJECTS_AFFECTED_BY_EDIT_TITLE' }),
                        customDialogContent: TenantTagsSaveWarningDialogBody,
                        icon: 'info',
                    });
                    if (!proceed) {
                        return;
                    }
                }
                const mappedData = {
                    name: data.name,
                    description: data.description,
                    type: 'KeyValue' as TagType,
                };
                await updateLabel(
                    organizationName,
                    tenant?.name,
                    propertyData?.key,
                    mappedData,
                    accountId,
                );
                logEvent(PortalTagManagementEvent.EditKeyDetails);
            }
            createNotification(translate({ id: 'CLIENT_YOUR_CHANGES_WERE_SAVED_SUCCESSFULLY' }), notificationType.SUCCESS);
            setRefresh(true);
            navigate(getRoute(
                process.buildConfigs.showForMSI
                    ? RouteNames.TenantTagsPropertiesMsi
                    : RouteNames.TenantTagsProperties.replace(':tenantId', tenantId)
            ),
            { state: { refresh: true } });
        } catch (e) {
            const message = await getErrorMessage(e);
            if (message.includes('already exists')) {
                setError('name', {
                    type: 'duplicate',
                    message: translate({ id: 'CLIENT_DUPLICATE_NAME' }),
                });
            }
        }
    }, [
        isAdd,
        createNotification,
        translate,
        setRefresh,
        navigate,
        getRoute,
        tenantId,
        setError,
        organizationName,
        tenant?.name,
        accountId,
        logEvent,
        propertyData?.entityReferencesCount,
        propertyData?.key,
        createDialog,
        getErrorMessage,
    ]);

    useEffect(() => {
        if (type === 'edit' && propertyData) {
            setPrevDataType(propertyData.dataType);
            reset({
                key: propertyData.key,
                name: propertyData.name,
                description: propertyData.description,
                dataType: propertyData.dataType,
                regex: propertyData.regex,
                type: 'KeyValue' as TagType,
                values: [],
            });
        }
    }, [ propertyData, reset, type ]);

    const {
        data: gridResourceData, setData,
    } = useUiDataContext<ITenantTagsPropertiesContext>();

    useEffect(() => {
        if (gridResourceData.Refresh === true) {
            setRefresh(true);
            setData({
                ...gridResourceData,
                Refresh: false,
            });
            if (isEditKeyValues) {
                valuesMutate();
            }
        }

        if (gridResourceData.Values?.length) {
            setValue('values', gridResourceData.Values, { shouldDirty: true });
        }
    }, [ gridResourceData, isEditKeyValues, setData, setRefresh, setValue, valuesMutate ]);

    const calculateDrawerRoute = useMemo(() => {
        let editRoute = '';
        if (isAdd) {
            editRoute = 'key';
        } else if (isEditDetails) {
            editRoute = 'details';
        } else if (isEditKeyValues) {
            editRoute = 'keyValues';
        }

        const cloud: string = type === 'edit'
            ? RouteNames.TenantTagsPropertiesEdit.replace(':tenantId', tenantId)
            : RouteNames.TenantTagsPropertiesAdd.replace(':tenantId', tenantId);
        const msi: string = type === 'edit'
            ? RouteNames.TenantTagsPropertiesEditMsi.replace(':tenantId', tenantId)
            : RouteNames.TenantTagsPropertiesAddMsi.replace(':tenantId', tenantId);

        const base = process.buildConfigs.showForMSI ? msi : cloud;
        const ret = getRoute(`${base
            .replace(':type', type)
            .replace(':editType', editRoute)
            .replace('/:id', isEditDetails || isEditKeyValues ? `/${id}` : '')}/values`);
        return ret;
    }, [ getRoute, id, isAdd, isEditDetails, isEditKeyValues, tenantId, type ]);

    const openValuesDrawer = useCallback(() => {
        let regExpString: string = '';
        if (propertyData?.regex) {
            regExpString = propertyData.regex;
        } else {
            regExpString = getValues('regex') ?? '';
        }
        setData({
            Values: getValues('values'),
            DataType: getValues('dataType') ?? dataType as LabelDataType,
            Regex: new RegExp(regExpString),
        });
        navigate(calculateDrawerRoute);
    }, [ propertyData, setData, getValues, dataType, navigate, calculateDrawerRoute ]);

    const deleteValueRow = useCallback(async (row: IValueModelObject) => {

        const deletedValue = values.filter(value => value.normalizedValue === row.normalizedValue);
        const filteredValues = values.filter(value => value.normalizedValue !== row.normalizedValue);
        const key = row.key;

        try {
            if (type === 'add') {
                setValue('values', filteredValues);
            } else if (type === 'edit') {
                let proceed = true;
                proceed = await createDialog({
                    title: translate({ id: 'CLIENT_TENANT_TAGS_DELETE_VALUE_HEADER' }),
                    customDialogContent: TenantTagsDeleteDialogBody,
                    customDialogContentProps: { deleteType: 'Value' },
                    icon: 'error',
                });

                if (proceed) {
                    await deleteValueFromKey(organizationName, tenant?.name, id, key, accountId);

                    createNotification(
                        translate(
                            { id: 'CLIENT_VALUES_WERE_DELETED_FROM_THE_KEY' },
                            { values: deletedValue.join(', ') }),
                        notificationType.INPROGRESS,
                    );

                    setRefresh(true);
                    valuesMutate();
                }
            }
        } catch (e) {
            createNotification(
                translate({ id: 'CLIENT_FAILED_TO_DELETE_VALUES_FROM_KEY' }, { values: deletedValue.join(', ') }),
                notificationType.ERROR,
            );
        }
    }, [
        accountId,
        values,
        type,
        setValue,
        createDialog,
        translate,
        organizationName,
        tenant,
        id,
        createNotification,
        setRefresh,
        valuesMutate,
    ]);

    const getOrchestratorCount = useCallback((row: IValueModelObject) => row.entityReferencesCount?.reduce((acc, curr) => {
        if (OrchestratorObjectType.includes(curr.entityType)) {
            return acc + curr.count;
        }
        return acc;
    }, 0) ?? 0, []);

    const taggedObjectsTooltipTitle = useCallback((row: IValueModelObject) => {
        const orchestratorCount = getOrchestratorCount(row);
        return orchestratorCount > 0
            ? translate({ id: 'CLIENT_LIST_TAGGED_ORCHESTRATOR_OBJECTS' })
            : translate({ id: 'CLIENT_TAG_HAS_NO_REFERENCES_PROPERTY' });
    }, [ getOrchestratorCount, translate ]);

    const disableDeleteValueRowButton = isBooleanDataType || (isEditKeyValues && !hasPermission(permissions, [ PermissionType.Delete ]));

    const deleteValueRowBulk = useCallback(
        async (rows: IValueModelObject[] | undefined) => {
            if (!rows) {
                return;
            }
            const currentValues = getValues('values');
            const deletedValueKeys = rows.map(row => row.key);
            const deletedValues = rows.map((row) => row.normalizedValue);
            try {
                if (type === 'add') {
                    const filteredValues = currentValues.filter(val => !deletedValues.includes(val.normalizedValue));
                    setValue('values', filteredValues);
                } else if (type === 'edit') {
                    let proceed = true;
                    proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TENANT_TAGS_DELETE_PROPERTY_HEADER' }),
                        customDialogContent: TenantTagsDeleteDialogBody,
                        customDialogContentProps: { deleteType: 'Value' },
                        icon: 'error',
                    });

                    if (proceed) {
                        await deleteValuesFromKey(organizationName, tenant?.name, id, deletedValueKeys, accountId);

                        createNotification(
                            translate(
                                { id: 'CLIENT_VALUES_WERE_DELETED_FROM_THE_KEY' },
                                { values: deletedValues.join(', ') }),
                            notificationType.INPROGRESS,
                        );

                        setRefresh(true);
                        valuesMutate();
                    }
                }
            } catch (e) {
                createNotification(
                    translate({ id: 'CLIENT_FAILED_TO_DELETE_VALUES_FROM_KEY' }, { values: deletedValues.join(', ') }),
                    notificationType.ERROR,
                );
            }
        },
        [
            accountId,
            getValues,
            type,
            setValue,
            createDialog,
            translate,
            organizationName,
            tenant,
            id,
            createNotification,
            setRefresh,
            valuesMutate,
        ],
    );

    const disableAddValuesButton = isBooleanDataType
                || (isAdd && dataType === 'Regex' && !regex)
                || !!errors.regex
                || !hasPermission(permissions, [ PermissionType.Create ]);

    const disableDeleteValuesButton = (isEditKeyValues && !hasPermission(permissions, [ PermissionType.Delete ]));

    const renderedValues = useCallback((index: number, searchTerm: string, size: number, values: IValueModelObject[]) => {
        const filteredValues = values.filter(item => item.displayValue.toLowerCase().includes(searchTerm.toLowerCase()));
        return filteredValues.slice(index * size, index * size + size);
    }, []);

    const setBooleanValues = useCallback(() => {
        const booleanValues = [
            {
                displayValue: 'true',
                id: '',
                key: '',
                normalizedValue: 'true',
            },
            {
                displayValue: 'false',
                id: '',
                key: '',
                normalizedValue: 'false',
            },
        ];
        setValue('values', booleanValues);
    }, [ setValue ]);

    const refreshValues = useCallback(async (newDataType: LabelDataType) => {
        let proceed = true;
        proceed = await createDialog({
            title: translate({ id: 'CLIENT_CHANGE_VALUES_WARNING_HEADER' }),
            customDialogContent: ValuesWarningDialogBody,
            icon: 'error',
        });
        if (proceed) {
            setValue('dataType', newDataType);
            if (newDataType === 'Boolean') {
                setBooleanValues();
            } else {
                setValue('values', []);
            }
            setPrevDataType(newDataType);

        } else {
            setValue('dataType', prevDataType);
            setValue('regex', regex);
        }
    }, [ createDialog, prevDataType, regex, setBooleanValues, setValue, translate ]);

    const changeDataType = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (values.length === 0 && e.target.value === 'Boolean') {
            setValue('dataType', e.target.value);
            setBooleanValues();
            setPrevDataType(e.target.value);
        } else if (values.length > 0 && prevDataType !== 'Boolean') {
            refreshValues(e.target.value as LabelDataType);
        } else {
            setValue('dataType', e.target.value as LabelDataType);
            setValue('values', []);
            setPrevDataType(e.target.value as LabelDataType);
        }
    }, [ prevDataType, refreshValues, setBooleanValues, setValue, values.length ]);

    const handleOnBlurRegex = useCallback(async (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        valueModel: IValueModelObject[],
        prevReg: string,
    ) => {

        // eslint-disable-next-line no-useless-escape
        const invalidChar = [ '\<', '\>', '\%', '\&', '\/', '\?', '\\', '\:' ];
        for (const c of invalidChar) {
            if (e.target.value.includes(c)) {
                setError('regex', {
                    type: 'invalid',
                    message: translate({ id: 'CLIENT_ADD_VALUE_INVALID_REGEX_CHAR_ERROR' }),
                });
                return;
            }
        }

        const isValid = isValidRegex(e.target.value);
        if (!isValid) {
            setError('regex', {
                type: 'invalid',
                message: translate({ id: 'CLIENT_ADD_VALUE_INVALID_REGEX_ERROR' }),
            });
        } else {
            clearErrors('regex');
            if (e.target.value !== prevReg && valueModel?.length > 0) {
                let proceed = false;
                if (prevReg.length > 0) {
                    proceed = await createDialog({
                        title: translate({ id: 'CLIENT_CHANGE_VALUES_WARNING_HEADER' }),
                        customDialogContent: RegexWarningDialogBody,
                        icon: 'warning',
                    });
                }
                if (proceed) {
                    setValue('values', []);
                    setPrevRegex(e.target.value);
                } else {
                    setValue('regex', prevReg);
                }
            } else {
                setPrevRegex(e.target.value);
            }
        }
    }, [ clearErrors, createDialog, setError, setValue, translate ]);

    return {
        isAdmin,
        isEditDetails,
        isEditKeyValues,
        values,
        type,
        id,
        editType,
        property: propertyData,
        organizationName,
        tenant,
        tenantId,
        cancel,
        tenantLoading,
        propertyLoading,
        valuesLoading,
        isRegex,
        methods: {
            register,
            control,
            formState,
            handleSubmit: handleSubmit(onSubmit),
            setValue,
            getValues,
        },
        createDialog,
        openValuesDrawer,
        deleteValueRow,
        deleteValueRowBulk,
        translate,
        refreshState,
        onSubmit,
        changeDataType,
        setBooleanValues,
        refreshValues,
        handleOnBlurRegex,
        prevRegex,
        renderedValues,
        taggedObjectsTooltipTitle,
        data: (isEditKeyValues ? valuesData?.results : renderedValues(pagination.skip, pagination.searchTerm ?? '', pagination.top, values)) ?? [],
        dataLength: valuesData?.totalCount ?? values.length,
        loading: isEditKeyValues ? (propertyLoading || tenantLoading || valuesLoading) : false,
        permissions,
        disableAddValuesButton,
        disableDeleteValuesButton,
        disableDeleteValueRowButton,
        onChangePagination,
        getOrchestratorCount,
        isBooleanDataType,
        refresh: valuesMutate,
    };
};

export default useAddEditTenantTagsPropertiesViewModel;
