// const ASN1 = require('@lapo/asn1js');
const Hex = require('@lapo/asn1js/hex');
const Base64 = require('@lapo/asn1js/base64');

const reHex = /^\s*(?:[0-9A-Fa-f][0-9A-Fa-f]\s*)+$/;

const validateCertificate = async (cert: string) => {
    try {
        reHex.test(cert)
            ? Hex.decode(cert)
            : Base64.unarmor(cert);

        // Deferring ASN1 validation for later, as it doesn't work well with CI
        // const asn1 = ASN1.decode(der);
        // if (asn1.posEnd() !== der.length) {
        //     return false;
        // }

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export default validateCertificate;
