import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import CircularProgress from '@mui/material/CircularProgress';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    getPriceString,
    getTaxPercentageString,
} from '../helpers/EcommerceHelpers';
import type { ITaxResponse } from '../interfaces/ecommerce';
import { EcommercePrice } from './EcommercePrice';

const useStyles = makeStyles(theme =>
    createStyles({
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        subtotalRow: { marginBottom: '12px' },
        subtotalText: { color: theme.palette.semantic.colorForeground },
        dueNow: {
            fontSize: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        tax: {
            marginTop: '2px',
            marginLeft: '3px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        circularLoading: { borderRadius: '30px' },
    }),
);

const EcommerceOrderSummaryFooter: React.FC<{
    tax?: ITaxResponse;
    subscriptionPriceToDisplay: number;
    annualSavingsToDisplay?: number;
    productPricesLoading?: boolean;
    taxLoading?: boolean;
    planType: string;
    currency: string;
}> = ({
    tax,
    subscriptionPriceToDisplay,
    annualSavingsToDisplay,
    productPricesLoading,
    taxLoading,
    planType,
    currency,
}) => {
    const classes = useStyles();
    const language = useLocalization();
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();
    const taxPercentageString =
    useMemo(() => tax !== undefined ? getTaxPercentageString(tax.taxRate, language) : undefined, [ tax, language ]);
    const EnableEcommercePlanSelectionRevampM0 = getFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);

    return (
        <>
            {annualSavingsToDisplay && (
                <div className={clsx(classes.row, classes.subtotalRow, classes.subtotalText)}>
                    <UiText className={classes.subtotalText}>
                        {translate({ id: 'CLIENT_ANNUAL_SAVINGS' })}
                    </UiText>
                    <EcommercePrice
                        value={annualSavingsToDisplay}
                        currency={currency}
                        dataCy="annual-saving-footer-value"
                        fontColor={theme.palette.semantic.colorSuccessText}
                    />
                </div>
            )}
            <div className={clsx(classes.row, classes.subtotalRow, classes.subtotalText)}>
                <UiText className={classes.subtotalText}>
                    {translate({ id: 'CLIENT_SUBTOTAL' })}
                </UiText>
                <EcommercePrice
                    value={subscriptionPriceToDisplay}
                    loading={productPricesLoading}
                    planType={planType}
                    currency={currency}
                    dataCy="subtotal-value"
                />
            </div>
            {tax !== undefined || taxLoading ? (
                <div
                    className={clsx(classes.row, classes.subtotalRow)}
                    data-cy="tax-value">
                    <div className={classes.row}>
                        <UiText>
                            { translate({ id: 'CLIENT_TAX_TITLE' }) +
                        ' ' +
                        translate({ id: 'CLIENT_ESTIMATED' }) +
                        ': ' + ((taxPercentageString !== undefined) ? taxPercentageString : '')}
                        </UiText>
                        {(taxPercentageString === undefined) &&
                         <CircularProgress
                             size={16}
                             className={classes.circularLoading} />}
                    </div>
                    <EcommercePrice
                        value={tax?.taxValue}
                        loading={taxLoading}
                        planType={planType}
                        currency={currency}
                    />

                </div>
            ) : undefined}
            <div className={classes.row}>
                <div className={classes.row}>
                    <UiText className={classes.dueNow}>
                        {EnableEcommercePlanSelectionRevampM0 ? translate({ id: 'CLIENT_DUE_TODAY' }) : translate({ id: 'CLIENT_DUE_NOW' })}
                    </UiText>
                    {tax !== undefined ? (
                        <UiText className={classes.tax}>
                            {translate({ id: 'CLIENT_ESTIMATED' })}
                        </UiText>
                    ) : undefined}
                </div>
                <div className={classes.row}>
                    {(taxLoading || productPricesLoading || !currency) ? (
                        <CircularProgress
                            size={16}
                            className={classes.circularLoading} />
                    ) : (
                        <UiText
                            className={classes.dueNow}
                            data-cy="due-now-value">
                            {getPriceString(subscriptionPriceToDisplay, currency, language, tax?.taxValue)}
                        </UiText>
                    )}
                    {tax !== undefined ? null : (
                        <UiText className={classes.tax}>
                            {translate({ id: 'CLIENT_TAX' })}
                        </UiText>
                    )}
                </div>
            </div>
        </>
    );
};

export default EcommerceOrderSummaryFooter;
