import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useCallback } from 'react';

const useEcommerceLearnMoreViewModel = (
) => {

    const enableEcommerceAnnualRobotUnits = getFeatureFlagValue(Features.enableEcommerceAnnualRobotUnits.name);

    const documentationLinksByProduct: { [key: string]: string | undefined }
        = {
            'RU': 'https://docs.uipath.com/overview/other/latest/overview/service-licensing#robot-units',
            'SPUNATT': 'https://docs.uipath.com/overview/other/latest/overview/service-licensing#robot-units',
        };

    const getLearnMoreSubtitleTranslationId = useCallback(
        (productCode: string, planType: string) => enableEcommerceAnnualRobotUnits
            ? `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_SUBTITLE_${productCode}_${planType}`
            : `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_SUBTITLE_${productCode}`,
        [ enableEcommerceAnnualRobotUnits ]
    );

    const getLearnMoreDescriptionTranslationId = useCallback(
        (productCode: string, index: number, planType: string) => enableEcommerceAnnualRobotUnits
            ? `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_DESCRIPTION_${productCode}_${index}_${planType}`
            : `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_DESCRIPTION_${productCode}_${index}`,
        [ enableEcommerceAnnualRobotUnits ]
    );

    const getLearnMoreTooltipTranslationId = useCallback(
        (productCode: string, planType: string) => productCode === 'RU' && enableEcommerceAnnualRobotUnits
            ? `CLIENT_ECOMMERCE_PRODUCT_INFO_TOOLTIP_ANNUAL_${productCode}_${planType}`
            : `CLIENT_ECOMMERCE_PRODUCT_INFO_TOOLTIP_${productCode}_${planType}`,
        [ enableEcommerceAnnualRobotUnits ]
    );

    return {
        documentationLinksByProduct,
        getLearnMoreSubtitleTranslationId,
        getLearnMoreDescriptionTranslationId,
        getLearnMoreTooltipTranslationId,
    };
};

export default useEcommerceLearnMoreViewModel;
