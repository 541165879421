import TextField from '@mui/material/TextField';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { ISubscriptionForm } from '../interfaces/ecommerce';

const EcommerceCity: React.FC<{
    disabled: boolean;
    leftMargin?: boolean;
}> = ({
    disabled, leftMargin,
}) => {
    const { formatMessage: translate } = useIntl();
    const {
        control,
        formState: { errors },
    } = useFormContext<ISubscriptionForm>();

    return (
        <Controller
            control={control}
            rules={{ required: true }}
            name="city"
            render={({ field }) => (
                <TextField
                    {...field}
                    style={{ marginLeft: leftMargin ? '12px' : '0px' }}
                    variant="outlined"
                    required
                    id="city"
                    label={translate({ id: 'CLIENT_CITY' })}
                    error={!!errors.city}
                    helperText={
                        (errors.city?.type === 'required' &&
                            translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_CITY' }) },
                            )) ||
                (errors.city?.type === 'addressError' &&
                  translate({ id: 'CLIENT_CHECK_CITY' }))
                    }
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                    disabled={disabled}
                    data-cy="ecommerce-form-city"
                />
            )}
        />
    );
};

export default EcommerceCity;
