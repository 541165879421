import { UiText } from '@experiences/ui-common';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';

export const DividerBox = ({ text }: { text: string }) => {
    const theme = useTheme();

    return (
        <>
            <UiText
                variant={FontVariantToken.fontSizeMBold}
                role='heading'
                aria-level={3}
            >
                { text }
            </UiText>
            <Divider sx={{
                margin: '8px 0',
                borderColor: theme.palette.semantic.colorBorderDeEmp,
            }} />
        </>
    );
};
