import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import useEcommerceAddressLine1ViewModel from './UseEcommerceAddressLine1ViewModel';

const useStyles = makeStyles(() =>
    createStyles({
        spacing: {
            marginRight: '16px',
            padding: '0',
            '& .MuiAutocomplete-input': { padding: '0' },
            '& .MuiAutocomplete-inputRoot': { padding: '0' },
            '& .MuiOutlinedInput-root': { padding: '0' },
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                paddingTop: '9.5px',
                paddingBottom: '9.5px',
                paddingRight: '16px',
                paddingLeft: '16px',
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root': { paddingRight: '0' },
        },

    }),
);
const EcommerceAddressLine1: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        optionsList,
        loading,
        selectedListing,
        setSelectedListing,
        onChange,
        getOptionLabel,
        onInputChange,
        errors,
        control,
        RenderOption,
        open,
        AutoCompleteOpen,
        AutoCompleteClose,
        handleBeforeInput,
    } = useEcommerceAddressLine1ViewModel();

    return (
        <Autocomplete
            open={open}
            fullWidth
            disabled={disabled}
            disableClearable
            onOpen={AutoCompleteOpen}
            classes={{ inputRoot: classes.spacing }}
            onClose={AutoCompleteClose}
            onInputChange={(_event, v) => {
                onInputChange(v);
                setSelectedListing(v);
            }}
            onChange={(_event, v) => {
                onChange(v as google.maps.places.AutocompletePrediction);
            }}
            onBeforeInput={handleBeforeInput}
            autoHighlight
            autoComplete={false}
            disablePortal
            className={classes.spacing}
            renderOption={RenderOption}
            value={selectedListing}
            getOptionLabel={getOptionLabel}
            options={optionsList}
            loading={loading}
            renderInput={(params) => (
                <Controller
                    name="addressLine1"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...params}
                            {...field}
                            variant="outlined"
                            required
                            id="addressLine1"
                            label={translate({ id: 'CLIENT_STREET_ADDRESS' })}
                            style={{ marginRight: '12px' }}
                            name="addressLine1"
                            error={!!errors.addressLine1}
                            helperText={
                                (errors.addressLine1?.type === 'required' &&
                                translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_STREET_ADDRESS' }) },
                                )) ||
                            (errors.addressLine1?.type === 'addressError' &&
                            translate({ id: 'CLIENT_CHECK_YOUR_BILLING_ADDRESS' }))
                            }
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                            }}
                            fullWidth
                            disabled={disabled}
                            data-cy="ecommerce-form-address-line-1"
                        />
                    )}
                />
            )}
        />);
};

export default EcommerceAddressLine1;
