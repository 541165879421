import {
    AccountLicense,
    NavCustomizationSetting,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useGetSetting } from '../../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import {
    getDataRetentionPolicy,
    insightsDataRetentionPolicyUrl,
} from '../../../services/insights/DataRetentionService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';
import {
    mapNavCustomizationDataToKeyValuePairs,
    mapSettingArrayToNavCustomizationData,
} from '../../../util/setting/NavCustomizationSettingUtil';
import UiForm from '../../common/UiForm';
import NavCustomizationComponent from './navigationCustomization/NavCustomizationComponent';
import OrganizationDelete from './OrganizationDelete';
import { OrganizationRegion } from './OrganizationRegion';
import {
    defaultEnterpriseNavCustomizationData,
    defaultNavCustomizationData,
    type INavCustomizationData,
} from './types/nav-customization';
import UserLicensingSettingsComponent from './UserLicensingSettingsComponent';

const navCustomizationKeys = [
    NavCustomizationSetting.AppsHidden,
    NavCustomizationSetting.MarketplaceHidden,
    NavCustomizationSetting.ResourceCenterHidden,
    NavCustomizationSetting.StudioWebHidden,
    NavCustomizationSetting.IntegrationServiceHidden,
    NavCustomizationSetting.InsightsHidden,
    NavCustomizationSetting.AcademyHidden,
    NavCustomizationSetting.CustomerPortalHidden,
    NavCustomizationSetting.AiCenterHidden,
    NavCustomizationSetting.AutomationHubHidden,
    NavCustomizationSetting.CommunicationsMiningHidden,
    NavCustomizationSetting.SubmitIdeaHidden,
    NavCustomizationSetting.ProcessMiningHidden,
];

const useStyles = makeStyles(theme =>
    createStyles({
        advancedSettingsContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
        advancedSettingsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        advancedSettings: { width: '100%' },
        divider: { color: theme.palette.semantic.colorBorderDeEmp },
        section: { marginTop: '24px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginLeft: '10px' },
        generalSettings: {
            width: '75vw',
            maxWidth: '900px',
            height: 'calc(100% - 40px)',
            minWidth: '518px',
        },
        centerProgress: {
            margin: 'auto',
            marginTop: '32px',
        },
        headerWithLink: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        retentionPolicyHeader: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            marginTop: '32px',
            marginBottom: '8px',
        },
        retentionPolicyLink: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            marginTop: '32px',
            marginBottom: '8px',
        },
        retentionPolicyBody: {
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '4px',
        },
    }),
);

export const AdvancedSettingsTabComponent: React.FC = () => {
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnabledScheduledOrgMigration = useFeatureFlagValue(Features.EnabledScheduledOrgMigration.name);
    const EnableInsightsDataMonitoring = useFeatureFlagValue(Features.EnableInsightsDataMonitoring.name);

    const partitionGlobalId = useSelector(accountGlobalId);
    const organizationName = useSelector(accountLogicalName);
    const locale = useLocalization();

    const {
        notIncludesLicense, isFreeOrCommunityRevamp, isUserEnterprise,
    } = useCheckLicense();

    const {
        data: insightsData, error: insightsError, mutate: mutateInsights,
    } = useSWR(
        EnableInsightsDataMonitoring ? {
            url: insightsDataRetentionPolicyUrl,
            organizationName,
        } : null,
        getDataRetentionPolicy,
    );

    const deletionDate = useMemo(() => {
        if (!insightsData) {
            return undefined;
        }

        const deletionDateRaw = insightsData.result.DeletionDate;
        if (typeof deletionDateRaw === 'string') {
            return new Date(deletionDateRaw);
        }

        return deletionDateRaw;
    }, [ insightsData ]);

    const showDeleteOrg = useMemo(() =>
        !process.buildConfigs.disableOrganizationSettingsOrganizationDelete && !DisableFeatureFedRamp &&
        notIncludesLicense([ AccountLicense.ENTERPRISE, AccountLicense.PRO ]),
    [ DisableFeatureFedRamp, notIncludesLicense ]);

    const defaultFormValues = isUserEnterprise
        ? defaultEnterpriseNavCustomizationData
        : defaultNavCustomizationData;

    const methods = useForm<INavCustomizationData>({
        mode: 'onSubmit',
        defaultValues: defaultFormValues,
    });

    const {
        reset, handleSubmit, formState,
    } = methods;
    const {
        isDirty, isSubmitting,
    } = formState;

    const {
        data: fetchedSettings, mutate,
    } = useGetSetting(navCustomizationKeys);

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    const onSubmit = useCallback(
        async (data: INavCustomizationData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapNavCustomizationDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }), notificationType.SUCCESS);
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ createNotification, mutate, partitionGlobalId, translate ],
    );

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToNavCustomizationData(
                fetchedSettings,
                defaultFormValues,
            ));
        }
    }, [ reset, fetchedSettings, defaultFormValues ]);

    const InsightsDataMonitoring = useMemo(() => {
        if (!EnableInsightsDataMonitoring) {
            return null;
        }

        if (insightsError && insightsError?.response?.status !== 404) {
            return null;
        }

        if (!insightsData && !insightsError) {
            return <div>
                <Skeleton
                    variant="text"
                    className={classes.retentionPolicyHeader}
                    width="25%" />
                <Skeleton
                    variant="text"
                    className={classes.retentionPolicyBody}
                    width="30%" />
                <Skeleton
                    variant="text"
                    className={classes.retentionPolicyBody}
                    width="30%" />
            </div>;
        }

        return <div>
            <div className={classes.headerWithLink}>
                <UiText
                    variant={FontVariantToken.fontSizeH3}
                    className={classes.retentionPolicyHeader}>
                    {translate({ id: 'CLIENT_INSIGHTS_DATA_RETENTION_POLICY' })}
                </UiText>
                <Link
                    className={classes.retentionPolicyLink}
                    href="https://licensing.uipath.com/"
                    underline="hover">
                    {translate({ id: 'CLIENT_STEPS_TO_UPGRADE_POLICY' })}
                </Link>
            </div>
            {!insightsError && <>
                <UiText className={classes.retentionPolicyBody}>
                    {translate({ id: 'CLIENT_CURRENT_DATA_RETENTION_POLICY' }, { years: insightsData?.result.RetentionTime_Years })}
                </UiText>
                <UiText className={classes.retentionPolicyBody}>
                    {`${translate({ id: 'CLIENT_LAST_UPCOMING_DELETION_DATE' })}: ${deletionDate?.toLocaleDateString(
                        locale,
                        {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                        },
                    )}`}
                </UiText>
            </>}
            {insightsError?.response?.status === 404 && <UiText>
                {translate({ id: 'CLIENT_INSIGHTS_ERROR' })}
                <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => mutateInsights()}>
                    {translate({ id: 'CLIENT_RETRY' })}
                </Link>
            </UiText>}
        </div>;
    }, [
        EnableInsightsDataMonitoring,
        classes.headerWithLink,
        classes.retentionPolicyBody,
        classes.retentionPolicyHeader,
        classes.retentionPolicyLink,
        deletionDate,
        insightsData,
        insightsError,
        locale,
        mutateInsights,
        translate,
    ]);

    const formContents = useMemo(() => (
        <div className={classes.advancedSettingsContainer}>
            <div
                className={classes.advancedSettings}
                data-cy="advanced-settings-page"
                style={{ paddingTop: '20px' }}>
                {!isFreeOrCommunityRevamp && <NavCustomizationComponent />}
                <UserLicensingSettingsComponent />
                {EnabledScheduledOrgMigration && !DisableFeatureFedRamp && <OrganizationRegion />}

                {showDeleteOrg && <OrganizationDelete />}

                {InsightsDataMonitoring}
            </div>
        </div>
    ), [
        DisableFeatureFedRamp,
        EnabledScheduledOrgMigration,
        InsightsDataMonitoring,
        classes,
        isFreeOrCommunityRevamp,
        showDeleteOrg,
    ]);

    return (
        <div className={classes.generalSettings}>
            <UiForm
                centerChild
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    isDirty && <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => {
                                handleCancel();
                            }}
                            color="primary"
                            data-cy="nav-customization-cancel-button"
                        >
                            {translate({ id: 'CLIENT_DISCARD' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="nav-customization-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE_CHANGES' })}
                        </UiProgressButton>
                    </div>
                }
                heightOffset="0px"
            >
                <FormProvider {...methods}>
                    {formContents}
                </FormProvider>
            </UiForm>
        </div>
    );
};
