import type {
    Card,
    WidgetContext,
} from '@experiences/interfaces';
import {
    HomePageEvent,
    portalTelemetry,
} from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import ArrowForwardSharp from '@mui/icons-material/ArrowForwardSharp';
import WatchLaterOutlined from '@mui/icons-material/WatchLaterOutlined';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { WidgetFrameContext } from '../context/WidgetFrameContext';

export interface UiLargeCardProps<T> {
    card: Card<T>;
    id: string;
    clickHandler?: (data: T, ctx: WidgetContext) => void;
    cardIndex: number;
}

export const UiLargeCard = <T extends {}>({
    card, id, clickHandler, cardIndex,
}: UiLargeCardProps<T>) => {
    const { formatMessage: translate } = useIntl();
    const context = useContext(WidgetFrameContext);
    const theme = useTheme();

    return (
        <Box
            data-testid={`${id}-large-card-${cardIndex}`}
            sx={{
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: '220px',
                borderRadius: '8px',
                border: '1px solid',
                padding: '24px',
                borderColor: 'semantic.colorBorderDeEmp',
            }}>
            <Box>
                <Box sx={{
                    marginBottom: '16px',
                    display: 'flex',

                }}>
                    <Box
                        component="img"
                        data-testid={`${id}-large-card-img-${cardIndex}`}
                        sx={{
                            width: '48px',
                            height: '48px',
                        }}
                        src={card.icon}
                        alt={card.header}
                    />
                    <Box
                        component="span"
                        sx={{
                            marginLeft: 'auto',
                            display: 'flex',
                        }}>
                        <WatchLaterOutlined />
                        <UiText
                            variant={FontVariantToken.fontSizeH4}
                            data-testid={`${id}-large-card-time-${cardIndex}`}
                            style={{
                                display: 'inline-block',
                                marginLeft: '8px',
                            }}>
                            {card.subtitle}
                            {' '}
                            {translate({ id: 'CLIENT_WIDGET_TIMEINMINUTES' })}
                        </UiText>
                    </Box>
                </Box>

                <Box
                    data-testid={`${id}-large-card-header-${cardIndex}`}
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '20px',
                        fontWeight: '600',
                        lineHeight: '24px',
                    }}>
                    {card.header}
                </Box>
            </Box>
            <Box flexGrow={1} />
            { card.description && <Link
                href=''
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                }}
                onClick={(e) => {
                    e.preventDefault();

                    portalTelemetry.trackEvent({
                        name: HomePageEvent.LargeCardClicked,
                        properties: {
                            Message: '[IN-HP-90] - card action clicked',
                            Code: '[IN-HP-90]',
                            CardId: id,
                            CardPosition: cardIndex,
                            CardName: card.header,
                            Description: card.description,
                            SubTitle: card.subtitle,
                            Persona: context.persona,
                        },
                    });

                    if (clickHandler) {
                        clickHandler(card.data, context);
                    }
                }}
            >
                <UiText
                    variant={FontVariantToken.fontSizeMBold}
                    data-testid={`${id}-large-card-link-${cardIndex}`}
                    style={{
                        marginRight: '8px',
                        color: theme.palette.semantic.colorForegroundLink,
                    }}>
                    {card.description}
                </UiText>
                <ArrowForwardSharp sx={{ color: 'semantic.colorForegroundLink' }} />
            </Link>}
        </Box>
    );
};
