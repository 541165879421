import type { IProject } from '@experiences/interfaces';
import { get } from '@experiences/util';

export function getProjects(accessToken: string) {
    const studioSearchProjectsUrl = '/studio_/backend/api/Project/SearchProjects';

    return get<{ projects: IProject[] }>(studioSearchProjectsUrl, {
        accessToken,
        stripPortalPath: true,
    }).then((response) => response.projects);
}
