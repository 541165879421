import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import {
    getRelativeTime,
    timestampToDateTime,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    PortalAlertBar,
} from '@uipath/portal-shell-react';
import capitalize from 'lodash/capitalize';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useGetSetting } from '../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IReferenceToken } from '../../common/interfaces/cis/profile';
import type { IReferenceTokenFilterOpts } from '../../services/identity/ReferenceTokenService';
import {
    deleteReferenceTokens,
    getAllReferenceTokens,
    referenceTokenUrl,
} from '../../services/identity/ReferenceTokenService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../store/selectors';
import { useRevokePatByValueDialog } from './pat/useRevokePatByValueDialog';

const useStyles = makeStyles(theme =>
    createStyles({
        allTokens: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        expiredDate: {
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: theme.palette.semantic.colorErrorIcon,
        },
    }),

);

const ExternalApplicationsPATPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const locale = useLocalization();

    const createNotification = useUiSnackBar();

    const [ pagination, setPagination ] = useState<IReferenceTokenFilterOpts>({
        userSearchTerm: '',
        top: 25,
        skip: 0,
        sortBy: 'Expiration',
        sortOrder: 'desc',
    });

    const {
        data: referenceTokens, mutate: mutateReferences, error,
    } = useSWR({
        url: referenceTokenUrl,
        partitionGlobalId,
        opts: pagination,
    }, getAllReferenceTokens);

    const createDialog = useShowDialog();

    const { data: settings } = useGetSetting([ 'ReferenceTokensEnabled' ]);
    const referenceTokensEnabled = useMemo(() =>
        settings?.find(setting => setting.key === 'ReferenceTokensEnabled')?.value?.toLowerCase() === 'true'
    , [ settings ]);

    const goToSettings = useCallback(() => {
        navigate(getRoute(RouteNames.ExternalApplicationsPATSettings));
    }, [ getRoute, navigate ]);

    const onChangePagination = useCallback(({
        filter, sort, pageIndex, pageSize, searchTerm,
    }: any /* REACT_18_TODO: should be IGridFilters, but its not exported */) => {
        const newPaginationState: IReferenceTokenFilterOpts = {
            userSearchTerm: searchTerm ?? '',
            top: pageSize ?? 25,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
            sortBy: capitalize(sort?.[0].field) || 'Expiration',
            sortOrder: sort?.[0].direction === 'asc' ? 'asc' : 'desc',
        };

        setPagination(newPaginationState);
    }, [ ]);

    useEffect(() => {
        mutateReferences();
    }, [ pagination, mutateReferences ]);

    const openRevokeDialog = useCallback(async (rows: IReferenceToken[] | IReferenceToken) => {
        const selectedTokens = Array.isArray(rows) ? rows.map(row => row) : [ rows ];

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_REVOKE_TOKEN' }),
            body: <Box>
                <UiText style={{ marginBottom: '20px' }}>
                    {translate(
                        {
                            id: selectedTokens.length === 1
                                ? 'CLIENT_SHOULD_REVOKE_TOKEN'
                                : 'CLIENT_SHOULD_REVOKE_MULTIPLE_TOKENS',
                        },
                    )}
                </UiText>
                <UiText>
                    {translate({ id: 'CLIENT_ARE_YOU_SURE_YOU_WANT_TO_REVOKE' })}
                </UiText>
            </Box>,
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_REVOKE' }),
        });
        if (proceed) {
            const tokenIds = selectedTokens.map(token => token.id);
            try {
                await deleteReferenceTokens(partitionGlobalId, tokenIds);
                createNotification(translate({ id: 'CLIENT_SUCCESSFULLY_REVOKED_TOKENS' }), notificationType.SUCCESS);
                mutateReferences();
            } catch (e) {
                createNotification(translate({ id: 'CLIENT_FAILED_TO_REVOKE_TOKENS' }), notificationType.ERROR);
            }
        }
    }, [ createDialog, createNotification, mutateReferences, partitionGlobalId, translate ]);

    const enableRevokePATByValue = useFeatureFlagValue(Features.EnableRevokePATByValue.name);
    const openRevokePatByValueDialog = useRevokePatByValueDialog(partitionGlobalId, mutateReferences);

    return <Box>
        <div style={{ marginTop: '12px' }} />
        <PortalAlertBar cancelable={false}>
            {translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN_DISCLAIMER' })}
        </PortalAlertBar>
        <UiText style={{
            marginTop: '16px',
            marginBottom: '24px',
        }}>
            {translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN_DESCRIPTION' })}
        </UiText>
        <UiText className={classes.allTokens}>
            {translate({ id: 'CLIENT_ALL_TOKENS' }, { number: referenceTokens?.totalCount ?? 0 })}
        </UiText>
        <ApDataGridWrapper<IReferenceToken>
            data={referenceTokens?.results ?? []}
            initialFilters={{
                sort: [
                    {
                        field: 'expiration',
                        direction: 'desc',
                        title: translate({ id: 'CLIENT_EXPIRY_DATE' }),
                    },
                ],
            }}
            loading={!referenceTokens && !error}
            refreshable
            refresh={() => mutateReferences()}
            onChange={onChangePagination}
            selectable={referenceTokensEnabled}
            dataCy="ap-data-grid-pat"
        >
            <ApDataGridHeader<IReferenceToken> search>
                {isAdmin && (
                    [
                        ...(enableRevokePATByValue ? [
                            <ApDataGridHeaderButton<IReferenceToken>
                                id="revoke"
                                key="revoke"
                                type="main"
                                render={() => <Button
                                    color="primary"
                                    variant="text"
                                    onClick={openRevokePatByValueDialog}
                                >
                                    {translate({ id: 'CLIENT_REVOKE' })}
                                </Button>}
                            />,
                        ] : []),
                        <ApDataGridHeaderButton<IReferenceToken>
                            id="tokenSettings"
                            key="tokenSettings"
                            type="main"
                            render={() => <Button
                                color="primary"
                                variant="text"
                                onClick={goToSettings}
                                startIcon={<SettingsIcon />}
                                data-cy="ap-grid-token-settings"
                            >
                                {translate({ id: 'CLIENT_SETTINGS' })}
                            </Button>}
                        />,
                        <ApDataGridHeaderButton<IReferenceToken>
                            id="revokeTokens"
                            key="revokeTokens"
                            type="action"
                            icon="delete"
                            color="warn"
                            buttonType="mat-flat-button"
                            text={translate({ id: 'CLIENT_REVOKE' })}
                            label={translate({ id: 'CLIENT_REVOKE' })}
                            onClick={(rows) => openRevokeDialog(rows ?? [])}
                            dataCy="ap-grid-revoke-tokens"
                        />,
                    ]
                )}
            </ApDataGridHeader>

            <ApDataGridColumn<IReferenceToken>
                property="user.displayName"
                title={translate({ id: 'CLIENT_OWNER' })}
                searchable
            />

            <ApDataGridColumn<IReferenceToken>
                property="description"
                title={translate({ id: 'CLIENT_NAME' })}
                searchable
                sortable
            />

            <ApDataGridColumn<IReferenceToken>
                property="lastUsed"
                title={translate({ id: 'CLIENT_LAST_IN_USE' })}
                searchable
                render={(item) => (
                    <Tooltip title={item.lastUsed ? timestampToDateTime(item.lastUsed) : translate({ id: 'CLIENT_NEVER' })}>
                        <UiText>
                            {item.lastUsed ? getRelativeTime(locale)(+new Date(item.lastUsed)) : translate({ id: 'CLIENT_NEVER' })}
                        </UiText>
                    </Tooltip>
                )}
            />

            <ApDataGridColumn<IReferenceToken>
                property="scopes"
                title={translate({ id: 'CLIENT_SCOPES' })}
                searchable
                render={(item) => {
                    const concatenatedString = item.scopes.join(', ');
                    return <Tooltip title={concatenatedString}>
                        <UiText>
                            {concatenatedString}
                        </UiText>
                    </Tooltip>;
                }}
            />

            <ApDataGridColumn<IReferenceToken>
                property="expiration"
                title={translate({ id: 'CLIENT_EXPIRY_DATE' })}
                searchable
                sortable
                sort="desc"
                render={(item) => {
                    const convertedDate = +(new Date(item.expiration));
                    if ((convertedDate - +(new Date())) < 0) {
                        return <UiText className={classes.expiredDate}>
                            <ErrorIcon style={{ marginRight: '4px' }} />
                            {translate({ id: 'CLIENT_EXPIRED' })}
                        </UiText>;
                    }

                    return <UiText>
                        {new Date(item.expiration).toLocaleDateString(locale, {
                            month: 'short',
                            year: 'numeric',
                            day: 'numeric',
                        })}
                    </UiText>;
                }}
            />

            {isAdmin && <ApDataGridRowActions>
                <ApDataGridRowButton<IReferenceToken>
                    id='revokeToken'
                    render={(row, _index) => <Tooltip title={referenceTokensEnabled
                        ? translate({ id: 'CLIENT_REVOKE' })
                        : translate({ id: 'CLIENT_REFERENCE_TOKENS_DISABLED' })}>
                        <Button
                            disabled={!referenceTokensEnabled}
                            color="primary"
                            variant="text"
                            onClick={() => openRevokeDialog(row)}
                            data-cy="revokeToken"
                        >
                            {translate({ id: 'CLIENT_REVOKE' })}
                        </Button>
                    </Tooltip>}
                />
            </ApDataGridRowActions>}

            <ApDataGridFooter
                length={referenceTokens?.totalCount ?? 0}
                pageSizes={[ 5, 10, 25, 50 ]}
            />
        </ApDataGridWrapper>
    </Box>;
};

export default ExternalApplicationsPATPageComponent;
