import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import { useAuthContext } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getPriceString } from '../helpers/EcommerceHelpers';
import { accountLogicalName } from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import type {
    IProduct,
    IProductBase,
    IProductPrice,
    ITaxResponse,
} from '../interfaces/ecommerce';
import {
    billingUrl,
    getUpcomingInvoice,
} from '../services/BillingService';
import EcommerceAmountDueToday from './EcommerceAmountDueToday';
import EcommerceOrderSummaryFooter from './EcommerceOrderSummaryFooter';
import { EcommercePlanTypeRadioGroup }
    from './EcommercePlanTypeRadioGroup';
import { EcommercePrice } from './EcommercePrice';

const useStyles = makeStyles(theme =>
    createStyles({
        summaryContainer: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        },
        topContainer: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            padding: '20px',
        },
        bottomContainer: {
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.semantic.colorBorderDeEmp,
            borderTopWidth: '1px',
            minHeight: '85px',
            padding: '15px 20px',
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        title: {
            marginTop: '2px',
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        packRow: {
            marginBottom: '10px',
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        productRow: {
            display: 'flex',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        alignRight: { marginLeft: 'auto' },
        planType: {
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '20px',
            marginBottom: '3px',
            color: theme.palette.semantic.colorForeground,
        },
        infoText: { color: theme.palette.semantic.colorForegroundDeEmp },
        orderSummarySubtitle: {
            marginTop: '42px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        orderSummaryText: {
            color: theme.palette.semantic.colorForeground,
            marginBottom: '70px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        centered: { alignSelf: 'center' },
        percentageOff: {
            color: theme.palette.semantic.colorSuccessText,
            whiteSpace: 'pre',
        },
        discountRow: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '9px',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'pre-wrap',
        },
        discountIcon: {
            fontSize: '16px',
            marginRight: '4px',
        },
    }),
);

export const EcommerceCheckoutOrderSummary: React.FC<{
    type: string;
    products: IProduct[];
    currency: string;
    price?: number;
    tax?: ITaxResponse;
    taxLoading?: boolean;
    productPricesLoading?: boolean;
    isFirstSubscription: boolean;
    productPrices?: IProductPrice[];
    changes?: { productsToAdd: IProduct[]; productsToDecrease: IProduct[] };
    width?: string;
    disablePlanTypeSelection?: boolean;
    annualSavings?: number;
}> = ({
    type,
    products,
    currency,
    price,
    tax,
    taxLoading,
    productPricesLoading,
    isFirstSubscription,
    productPrices,
    changes,
    width,
    disablePlanTypeSelection,
    annualSavings,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();
    const { token } = useAuthContext();
    const {
        currentSkuPackageDetails, setSelectedPlanType,
    } = useEcommerce();
    const currentAccountName = useSelector(accountLogicalName);

    const emptyData = useMemo(() => {
        if (changes) {
            return changes.productsToAdd.length === 0 && changes.productsToDecrease.length === 0;
        }
        return false;
    }, [ changes ]);

    const upcomingInvoiceProducts = useMemo(() => {
        if (changes?.productsToAdd.length && products?.length) {
            return products.map(productToAdd => ({
                code: productToAdd.code,
                quantity: productToAdd.quantity,
            } as IProductBase));
        }
        return [];
    }, [ changes?.productsToAdd, products ]);

    const { data: upcomingInvoice } = useSWR(
        upcomingInvoiceProducts.length ? {
            upcomingInvoiceRequest: { products: upcomingInvoiceProducts },
            accountName: currentAccountName,
            accessToken: token,
            url: `${billingUrl}/upcomingInvoice`,
        } : undefined,
        getUpcomingInvoice,
    );

    const getProductPrice = useCallback((product: IProduct, allProductPrices: IProductPrice[]) => {
        const productPrice = allProductPrices.find(p =>
            p.code === product.code && p.planType === currentSkuPackageDetails.planType)?.price ?? 0;
        return (product.quantity * productPrice);
    }, [ currentSkuPackageDetails.planType ]);

    const subscriptionPrice = useMemo(() => {
        if (price) {
            return price;
        }
        let priceTotal = 0;
        if (productPrices) {
            products.forEach(product => {
                priceTotal += getProductPrice(product, productPrices);
            });
        }
        return priceTotal;
    }, [ price, products, productPrices, getProductPrice ]);

    const getPlanPrice = useCallback(() => (
        <EcommercePrice
            value={subscriptionPrice}
            loading={productPricesLoading}
            gap
            planType={currentSkuPackageDetails.planType}
            currency={currency}
            dataCy="subscription-price"
        />
    ), [ subscriptionPrice, currentSkuPackageDetails.planType, productPricesLoading, currency ]);

    return (
        <div
            className={classes.summaryContainer}
            style={{ width: width ?? '100%' }}
            data-cy="summary-container">
            <div className={classes.topContainer}>
                <UiText
                    className={classes.title}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_ORDER_SUMMARY' })}
                </UiText>
                {emptyData ? (
                    <>
                        <UiText className={clsx(classes.orderSummarySubtitle, classes.centered)}>
                            {translate({ id: `CLIENT_THERES_NO_CHANGES_IN_YOUR_PLAN` })}
                        </UiText>
                        <UiText className={clsx(classes.orderSummaryText, classes.centered)}>
                            {translate({ id: `CLIENT_CHANGE_THE_LICENSE_QUANTITY` })}
                        </UiText>
                    </>
                ) : (<div>
                    {isFirstSubscription && (
                        <EcommercePlanTypeRadioGroup
                            setPlanType={setSelectedPlanType}
                            planType={currentSkuPackageDetails.planType}
                            disabled={disablePlanTypeSelection}
                        />
                    )}
                    <div className={classes.row}>
                        <UiText
                            className={classes.packRow}
                            data-cy="order-summary-package-type-title">
                            { isFirstSubscription ? translate({ id: `CLIENT_SKU_PACKAGE_PACK_${type}` })
                                : translate({ id: `CLIENT_INCREMENTAL_NEW_${currentSkuPackageDetails.planType}` })}
                        </UiText>
                        {getPlanPrice()}
                    </div>
                    {products.filter(p => p.quantity > 0).map((product, index) => (
                        <div
                            className={classes.productRow}
                            key={product.code}>
                            <UiText
                                key={index}
                                data-cy={`monthly-product-name-${product.code}`}>
                                {`${
                                    product.quantity > 0 ? `${product.quantity} ` : ''
                                }${translate({ id: `CLIENT_PRODUCT_${product.code}` })}`}
                            </UiText>
                            {productPrices && (
                                <UiText
                                    className={classes.alignRight}
                                    data-cy={`monthly-product-price-${product.code}`}>
                                    {getPriceString(getProductPrice(product, productPrices), currency, language)}
                                    {' '}
                          /
                                    {' '}
                                    {translate({ id: `CLIENT_PER_${currentSkuPackageDetails.planType.toUpperCase()}` })}
                                </UiText>
                            )}
                        </div>
                    ))}
                    {isFirstSubscription && (
                        <>
                            <UiText className={classes.planType}>
                                {translate({ id: 'CLIENT_PLAN_TYPE' })}
                            </UiText>
                            <UiText className={classes.productRow}>
                                {translate({ id: `CLIENT_${currentSkuPackageDetails.planType}_PLAN` })}
                            </UiText>
                        </>
                    )}
                </div>)}
            </div>
            { changes && changes.productsToAdd.length > 0 && (
                <div className={classes.bottomContainer} >
                    <EcommerceAmountDueToday
                        productPrices={productPrices}
                        currency={currency}
                        productsToAdd={changes?.productsToAdd}
                        upcomingInvoiceProductPrices={upcomingInvoice?.productPrices}
                        price={price}
                    />
                </div>
            )}
            {!emptyData && isFirstSubscription && (
                <div className={classes.bottomContainer}>
                    <EcommerceOrderSummaryFooter
                        tax={tax}
                        subscriptionPriceToDisplay={subscriptionPrice}
                        annualSavingsToDisplay={isFirstSubscription ? annualSavings : undefined}
                        productPricesLoading={productPricesLoading}
                        taxLoading={taxLoading}
                        planType={currentSkuPackageDetails.planType}
                        currency={currency}
                    />
                </div>
            )}
        </div>
    );
};
