import type { IConsumable } from '@experiences/interfaces';

import type {
    IEnterpriseUpgradeDto,
    IRobotAndServicesLicenses,
} from '../../common/interfaces/licenses';
import type { ITenantAllocation } from '../../common/interfaces/tenant/tenant';
import {
    get,
    post,
    put,
} from '../utility/Requests.default';

export function generateMLServiceKey(licenseType: string) {
    return post('/api/licensing/generateMLServiceKey', { body: { licenseType } });
}

export function upgradeToTrial(accountUserId: string, accountGlobalId: string, contactInfo: IEnterpriseUpgradeDto) {
    return put('/api/licensing/trial', {
        body: {
            accountUserId,
            accountGlobalId,
            contactInfo,
        },
    });
}

export function checkBusinessEmail(email: string): Promise<boolean> {
    return get(
        `/api/auth/Email/validate?email=${email}`,
    );
}

export function getTenantProductAllocations({
    accountGlobalId, tenantGlobalId,
}: { url: string; accountGlobalId: string; tenantGlobalId: string }) {
    return get<IRobotAndServicesLicenses>('/api/licensing/tenantProductAllocation', {
        urlParams: {
            accountGlobalId,
            tenantGlobalId,
        },
    });
}

export async function getTenantConsumables({
    accountGlobalId, tenantGlobalId,
}: { url: string; accountGlobalId: string; tenantGlobalId: string }) {
    return await get<IConsumable[]>('/api/licensing/tenantConsumables', {
        urlParams: {
            accountGlobalId,
            tenantGlobalId,
        },
    });
}

export function getTenantAllocations({
    url: _url, accountGlobalId,
}: { url: string; accountGlobalId: string }) {
    return get<ITenantAllocation[]>('/api/licensing/tenantAllocations', { urlParams: { accountGlobalId } });
}
