import type { IPagination } from '@experiences/interfaces';

import type {
    IRobot,
    IRobotResponse,
    IUpdateRobotDto,
} from '../../common/interfaces/cis/robot';
import {
    mapRobot,
    mapRobotCollection,
} from '../mappers/RobotAccountServiceMapper';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const robotUrl = `${process.buildConfigs.identityApiBaseRoute}/RobotAccount`;

export function getRobots({
    pagination, partitionGlobalId,
}: { pagination: IPagination; partitionGlobalId: string }) {
    return get<IRobotResponse>(`${robotUrl}/${partitionGlobalId}`, { pagination }).then(mapRobotCollection);
}

export function deleteRobots(partitionGlobalId: string, robotAccountIDs: string[]) {
    return axiosDelete(`${robotUrl}/${partitionGlobalId}`, { body: { robotAccountIDs } });
}

export function editRobot(robotAccountId: string, robotData: IUpdateRobotDto) {
    return put<IUpdateRobotDto>(`${robotUrl}/${robotAccountId}`, { body: robotData });
}

export function createRobot(robotData: IUpdateRobotDto) {
    return post<IRobot>(robotUrl, { body: robotData }).then(mapRobot);
}
