import { useMemo } from 'react';
import useSWR from 'swr';

import type { ILicenseAndKey } from '../../../common/interfaces/licenses';
import {
    billingUrl,
    fetchPlanDetails,
} from '../../../services/licensing/BillingService';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../../services/licensing/management/AccountService';
import { useShouldShowManageButton } from '../../licensing/useShouldShowManageButton';

const useEcommercePriceChangesBannerViewModel = (isAdmin: boolean, accountName: string) => {
    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );
    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);
    const shouldShowManageButton = useShouldShowManageButton(accountName, license?.accountType);

    const { data: planDetails } = useSWR(
        shouldShowManageButton ? {
            accountName,
            url: `${billingUrl}/planDetails/priceChanges`,
        } : null,
        fetchPlanDetails,
    );
    const [ hasPriceChangesSubscriptionSchedule ] = useMemo(
        () => [ planDetails?.hasPriceChangesSubscriptionSchedule ],
        [ planDetails ],
    );

    const shouldDisplayPriceChangesBanner = useMemo(
        () => isAdmin && hasPriceChangesSubscriptionSchedule,
        [ isAdmin, hasPriceChangesSubscriptionSchedule ]
    );

    return { shouldDisplayPriceChangesBanner };
};

export default useEcommercePriceChangesBannerViewModel;
