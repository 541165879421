import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    getSortedUserBundleConfigurations,
    useModalState,
    useNavigateWithParams,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalPeoplePicker } from '@uipath/portal-shell-react';
import type { DirectoryEntry } from '@uipath/portal-shell-types';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IDirectoryEntry } from '../../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../../common/interfaces/cis/directory';
import type { IUserCIS } from '../../../common/interfaces/cis/user';
import type { SourceFilters } from '../../../services/identity/DirectoryService';
import type { ISubmitUsersAllocations } from '../../../services/licensing/accountant/UserLicenseService';
import {
    getAccountLicenseAllocation,
    putUserLicenseAllocation,
    retrieveExplicitAllocationsForUserIds,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import { EnableUserLicensingSelector } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IUserLicenseAllocation } from '../../users/interfaces/userLicense';
import { LicensingAllocationFormComponent } from './LicensingAllocationFormComponent';
import { sendPendoTrackEventForAKit } from './LicensingHelpers';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        topMargin: { marginTop: '28px' },
        selectLicenses: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '20px',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

interface ILicenseExplicitAllocation {
    emails: IDirectoryEntry[];
    userBundleLicenses: string[];
    useExternalLicense: boolean;
}

const AddUserAllocationComponent: React.FC = () => {
    const EnableExternalUserLicense = useFeatureFlagValue(Features.EnableExternalUserLicense.name);

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const navigate = useNavigateWithParams();
    const createDialog = useShowDialog();
    const { token } = useUserInfo();
    const { state } = useLocation();

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const users = state?.users as IUserCIS[];
    const previousLocation = state?.previousLocation as string;

    const {
        open, close,
    } = useModalState(previousLocation ?? RouteNames.Licensing);

    const [ loading, setLoading ] = useState(false);

    const confirmAllocationOverrideDialog = useCallback(
        async (userEmails: string) => await createDialog({
            title: `${translate({ id: 'CLIENT_USER_LICENSE_ALLOCATION' })}`,
            body: (
                <div>
                    <p>
                        {translate({ id: 'CLIENT_OVERRIDE_EXPLICIT_ALLOCATION_P1' })}
                    </p>
                    <p>
                        {userEmails}
                    </p>
                    <p>
                        {translate({ id: 'CLIENT_OVERRIDE_EXPLICIT_ALLOCATION_P2' })}
                    </p>
                </div>
            ),
            icon: 'warning',
            primaryButtonText: `${translate({ id: 'CLIENT_SAVE' })}`,
            showCancel: true,
        }),
        [ translate, createDialog ],
    );

    const methods = useForm<ILicenseExplicitAllocation>({
        mode: 'onChange',
        defaultValues: {
            emails:
                users?.map((user: IUserCIS) => ({
                    identifier: user.id,
                    identityName: user.email,
                    email: user.email,
                    displayName: user.email,
                    objectType: 'LocalUser',
                    source: 'local',
                    type: DirectoryEntityType.user,
                    chipType: 'resolved',
                })) ?? [],
            userBundleLicenses: [],
            useExternalLicense: false,
        },
    });

    const {
        control, getValues, setValue, handleSubmit, setError, clearErrors, formState,
    } = useMemo(
        () => methods,
        [ methods ],
    );

    const { isDirty } = formState;

    const { mutate } = useSWRConfig();

    const {
        data: availableUserBundleLicensesForAccount,
        isValidating: availableUserBundleLicensesForAccountIsLoading,
    } = useSWR<IUserLicenseAllocation[], Error>(userLicenseUrl, getAccountLicenseAllocation);

    useEffect(() => {
        if (!EnableUserLicensing) {
            console.warn('Feature not enabled or missing data. Closing...');
            navigate('/invalidurl');
        }
    }, [ EnableUserLicensing, navigate ]);

    const onSubmit = useCallback(
        async (data: ILicenseExplicitAllocation) => {
            setLoading(true);
            const userIds = data.emails.map(directoryEntry => directoryEntry.identifier);
            const userLicenses = await retrieveExplicitAllocationsForUserIds(userIds);
            const usersWithOverride = data.emails
                .filter(e => userLicenses.some(ul => ul.id === e.identifier))
                .map(e => e.email);

            if (usersWithOverride?.length) {
                const proceed = await confirmAllocationOverrideDialog(usersWithOverride.join(', '));
                if (!proceed) {
                    setLoading(false);
                    return;
                }
            }

            if (data.emails.length === 0) {
                setError('emails', { type: 'invalid' });
                setLoading(false);
                return;
            }

            const oneUserEmail = data.emails.length === 1 ? data.emails[0].displayName : '';

            const payload: ISubmitUsersAllocations = {
                userIds: data.emails.map(directoryEntry => directoryEntry.identifier),
                licenseCodes: data.useExternalLicense ? [] : data.userBundleLicenses,
                useExternalLicense: data.useExternalLicense,
            };

            try {
                await putUserLicenseAllocation(payload);
            } catch (error) {
                createNotification(
                    translate(
                        { id: `CLIENT_ALLOCATION_EXPLICITLY_SUBMIT_FAILED${oneUserEmail ? '' : '_MULTIPLE'}` },
                        { 0: oneUserEmail },
                    ),
                    notificationType.ERROR,
                );
                close();
                return;
            }

            sendPendoTrackEventForAKit([], data.userBundleLicenses);

            createNotification(
                translate(
                    { id: `CLIENT_ALLOCATION_EXPLICITLY_SUBMIT${oneUserEmail ? '' : '_MULTIPLE'}` },
                    { 0: oneUserEmail },
                ),
                notificationType.SUCCESS
            );

            await mutate(trialServicesSwrKey);
            setLoading(false);
            close(true);
        },
        [ close, createNotification, translate, setError, confirmAllocationOverrideDialog, mutate ],
    );

    const validUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);
    const filteredAvailableProducts = useMemo(() => {
        if (availableUserBundleLicensesForAccount && Array.isArray(availableUserBundleLicensesForAccount)) {
            return availableUserBundleLicensesForAccount
                .filter(p => p.allocated !== 0 || p.total !== 0)
                .filter(p => validUserBundleCodes.includes(p.code));
        }
        return [];
    }, [ availableUserBundleLicensesForAccount, validUserBundleCodes ]);

    const orgHasUserLicenses = useMemo(
        () => EnableExternalUserLicense || filteredAvailableProducts.length > 0,
        [ EnableExternalUserLicense, filteredAvailableProducts.length ],
    );

    const peoplePickerChangedCallback = useCallback((event: any) => {
        const prevWithoutChip =
                    getValues('emails')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
        const curWithoutChip = event.detail.data.map((chip: IDirectoryEntry) => omit(chip, [ 'optionId', 'chipType' ]));
        const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
        setValue('emails', event.detail.data, {
            shouldDirty: valuesChanged,
            shouldValidate: valuesChanged,
        });
    }, [ getValues, setValue ]);

    const peoplePickerErrorCallback = useCallback((event: any) => {
        if (event.detail) {
            setError('emails', { type: 'invalid' });
        } else {
            clearErrors('emails');
        }
    }, [ clearErrors, setError ]);

    const peoplePickerLoadingCallback = useCallback((event: any) => setLoading(event.detail), []);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ALLOCATE_LICENSES' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={availableUserBundleLicensesForAccountIsLoading || !availableUserBundleLicensesForAccount}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    {orgHasUserLicenses && (
                        <>
                            <div className={classes.topMargin} />
                            <Controller
                                control={control}
                                name="emails"
                                render={({ field }) => (
                                    <PortalPeoplePicker
                                        token={token || undefined}
                                        sourceFilters={[
                                            'localUsers',
                                            'directoryUsers',
                                        ] as SourceFilters[]}
                                        value={field.value as DirectoryEntry[]}
                                        maxHeight="200px"
                                        onPeoplePickerChanged={peoplePickerChangedCallback}
                                        onPeoplePickerError={peoplePickerErrorCallback}
                                        onPeoplePickerLoading={peoplePickerLoadingCallback}
                                        onPeoplePickerResolving={peoplePickerLoadingCallback}
                                    />
                                )}
                            />
                            <UiText className={classes.selectLicenses}>
                                {translate({ id: 'CLIENT_SELECT_LICENSES' })}
                            </UiText>
                        </>
                    )}
                    <LicensingAllocationFormComponent
                        availableUserBundles={
                            availableUserBundleLicensesForAccount &&
                            Array.isArray(availableUserBundleLicensesForAccount)
                                ? filteredAvailableProducts
                                : undefined
                        }
                        allocatedUserBundles={[]}
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default AddUserAllocationComponent;
