// TODO: deprecate this file so we don't have giant block of constants

import {
    ConsumablesAiUnitsLearnMoreLinks,
    ConsumablesApiCallsLearnMoreLinks,
    ConsumablesRobotUnitsLearnMoreLinks,
    ConsumablesTransportUnitsLearnMoreLinks,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { IUiLinkMap } from '@experiences/interfaces';

export const AppEnvironment = { DEVELOPMENT: 'development' };

export const UsageAggregationType = {
    TENANT: 'TENANT',
    ORGANIZATION: 'ORGANIZATION',
};

export const UserRole = {
    DEFAULT_USER: 'DEFAULT_USER',
    TENANT_ADMIN: 'TENANT_ADMIN',
    ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
    ACCOUNT_OWNER: 'ACCOUNT_OWNER',
};

export const daysCategory = {
    DAY_7: 'day_7',
    DAY_30: 'day_30',
    DAY_60: 'day_60',
    DAY_90: 'day_90',
    defaultDurationDays: 7,
};

export const UsageConstants = {
    DAYS: 'DAYS',
    ACCOUNT_USER_ID_LABEL: '{userGlobalId}',
    ATTENDED_ROBOTS: 'attendedRobots',
    UNATTENDED_ROBOTS: 'unattendedRobots',
    TA_UNATTENDED_ROBOTS: 'taUnattendedRobots',
    API_TIME_FORMAT: '%Y-%m-%d',
    API_RESPONSE_ATTENDED_ROBOT: 'ATTENDED_ROBOT',
    API_RESPONSE_UNATTENDED_ROBOT: 'UNATTENDED_ROBOT',
    API_RESPONSE_TA_UNATTENDED_ROBOT: 'TA_UNATTENDED_ROBOT',
    API_RESPONSE_ANALYTICS: 'ANALYTICS',
    API_RESPONSE_SERVICE_ORCHESTRATION: 'SERVICE_ORCHESTRATION',
    ATTENDED: 'ru-attended',
    UNATTENDED: 'ru-unattended',
    TA_UNATTENDED: 'ru-taUnattended',
    NON_PRODUCTION: 'ru-np',
    STUDIO: 'ru-studio',
    USAGE_GRAPH_ID: 'usage-graph',
    USAGE_GRAPH_NAME: 'usage-graph',
    MAX_DAYS_DATA: 90,
};

export const LicenseType: Record<string, string> = {
    ATTENDED_ROBOT: 'ATTENDED_ROBOT',
    ATTENDED_ROBOT_LEGEND_COLOR: '#0085ca',
    UNATTENDED_ROBOT: 'UNATTENDED_ROBOT',
    UNATTENDED_ROBOT_LEGEND_COLOR: '#ff6900',
    TA_UNATTENDED_ROBOT: 'TA_UNATTENDED_ROBOT',
    TA_UNATTENDED_ROBOT_LEGEND_COLOR: '#69ff00',
    ATTENDED_CONCURRENT_USER: 'ATTENDED_CONCURRENT',
    ATTENDED_CONCURRENT_LEGEND_COLOR: '#6fcf97',
    NON_PRODUCTION_ROBOT: 'NONPRODUCTION_ROBOT',
    NONPRODUCTION_ROBOT_LEGEND_COLOR: '#e0a247',
    STUDIO_NAMED_USER: 'DEVELOPMENT_ROBOT',
    STUDIO_NAMED_LEGEND_COLOR: '#e0a247',
    DEVELOPMENT_ROBOT_LEGEND_COLOR: '#e0a247',
    STUDIO_CONCURRENT_USER: 'DEVELOPMENT_CONCURRENT',
    STUDIO_CONCURRENT_LEGEND_COLOR: '#6fcf97',
    DEVELOPMENT_CONCURRENT_LEGEND_COLOR: '#6fcf97',
    STUDIO_X_NAMED_USER: 'STUDIOX_NAMED',
    STUDIOX_NAMED_LEGEND_COLOR: '#0085ca',
    STUDIO_X_CONCURRENT_USER: 'STUDIOX_CONCURRENT',
    STUDIOX_CONCURRENT_LEGEND_COLOR: '#ff6900',
    STUDIO_PRO_NAMED_USER: 'STUDIOPRO_NAMED',
    STUDIOPRO_NAMED_LEGEND_COLOR: '#8500ca',
    STUDIO_PRO_CONCURRENT_USER: 'STUDIOPRO_CONCURRENT',
    STUDIOPRO_CONCURRENT_LEGEND_COLOR: '#69ff00',
    AI_ROBOT: 'AI_ROBOT',
    AI_ROBOT_LEGEND_COLOR: '#0085ca',
    AI_GPU: 'AI_GPU',
    AI_GPU_LEGEND_COLOR: '#6fcf97',
    STUDIO: 'DEVELOPMENT_ROBOT',
    SERVICE_ORCHESTRATION: 'SERVICE_ORCHESTRATION',
    SERVICE_INSTANCES: 'SERVICE_INSTANCES',
    ANALYTICS: 'ANALYTICS',
    AVAILABLE: ' available',
    INVALID: 'is-invalid',
    ATTENDED_NAMED_USER: 'ATTENDED_ROBOT',
    COMPUTER_VISION: 'MLSERVICE_CVMODEL',
    UNATTENDED_RUNTIME: 'UNATTENDED_ROBOT',
    TA_UNATTENDED_RUNTIME: 'TA_UNATTENDED_ROBOT',
    NONPRODUCTION_RUNTIME: 'NONPRODUCTION_ROBOT',
    AI_DOCUMENT_UNDERSTANDING: 'AI_DOCUMENT_UNDERSTANDING',
    AI_DOCUMENT_UNDERSTANDING_LEGEND_COLOR: '#df4f77',
    DATA_SERVICE_UNIT: 'DATA_SERVICE_UNIT',
    ROBOT_UNITS: 'ROBOT_UNITS',
    ROBOT_UNITS_LEGEND_COLOR: '#df4f77',
    AI_UNITS: 'AI_UNITS',
    AI_UNITS_LEGEND_COLOR: '#df4f77',
};

export const productCodeToLicenseTypeMap = new Map([
    [ 'ATTNU', LicenseType.ATTENDED_NAMED_USER ],
    [ 'ATTCU', LicenseType.ATTENDED_CONCURRENT_USER ],
    [ 'STDNU', LicenseType.STUDIO_NAMED_USER ],
    [ 'STDCU', LicenseType.STUDIO_CONCURRENT_USER ],
    [ 'STDXNU', LicenseType.STUDIO_X_NAMED_USER ],
    [ 'STDXCU', LicenseType.STUDIO_X_CONCURRENT_USER ],
    [ 'STDPRONU', LicenseType.STUDIO_PRO_NAMED_USER ],
    [ 'STDPROCU', LicenseType.STUDIO_PRO_CONCURRENT_USER ],
    [ 'AIW', LicenseType.AI_ROBOT ],
    [ 'AIG', LicenseType.AI_GPU ],
    [ 'NONPR', LicenseType.NONPRODUCTION_RUNTIME ],
    [ 'UNATT', LicenseType.UNATTENDED_RUNTIME ],
    [ 'TAUNATT', LicenseType.TA_UNATTENDED_RUNTIME ],
    [ 'TIE', LicenseType.AI_DOCUMENT_UNDERSTANDING ],
    [ 'ORCHT', LicenseType.SERVICE_INSTANCES ],
    [ 'CV', LicenseType.COMPUTER_VISION ],
    [ 'DSU', LicenseType.DATA_SERVICE_UNIT ],
    [ 'RU', LicenseType.ROBOT_UNITS ],
    [ 'AIU', LicenseType.AI_UNITS ],
]);

export const appState = { REDUX_STORE_STATE: 'CloudRPAAppState' };

export const productCodeToTranslationCode = new Map([
    [ 'UNATT', 'UNATTENDEDROBOTS' ],
    [ 'ATTNU', 'ATTENDEDROBOTS' ],
    [ 'ATTCU', 'ATTENDEDCONCURRENTROBOTS' ],
    [ 'STDNU', 'STUDIONAMEDLICENCES' ],
    [ 'NONPR', 'NONPRODUCTIONROBOT' ],
    [ 'STDCU', 'STUDIOCONCURRENTLICENCES' ],
    [ 'STDXNU', 'STUDIOXNAMEDLICENCES' ],
    [ 'STDXCU', 'STUDIOXCONCURRENTLICENCES' ],
    [ 'AIW', 'AIFABRICLICENCES' ],
    [ 'AIG', 'AIGPUHELPERLICENCES' ],
    [ 'TAUNATT', 'TAUNATTENDEDROBOTS' ],
    [ 'STDPRONU', 'STUDIOPRONAMEDLICENCES' ],
    [ 'STDPROCU', 'STUDIOPROCONCURRENTLICENCES' ],
    [ 'DSU', 'DATASERVICEUNIT' ],
    [ 'AHUB', 'AUTOMATION_HUB' ],
    [ 'ANL', 'INSIGHTS' ],
    // user bundles
    [ 'ATTUNU', 'ATTENDEDROBOTS' ],
    [ 'ATTUCU', 'ATTENDEDROBOTS' ],
    [ 'CTZDEVNU', 'CTZDEV' ],
    [ 'CTZDEVCU', 'CTZDEV' ],
    [ 'RPADEVNU', 'RPADEV' ],
    [ 'RPADEVCU', 'RPADEV' ],
    [ 'RPADEVPRONU', 'RPADEVPRO' ],
    [ 'RPADEVPROCU', 'RPADEVPRO' ],
    [ 'TSTDEVPRONU', 'USER_TSTDEVPRO' ],
    [ 'TSTNU', 'USER_TST' ],
]);

export const concurrentProductCodes = [ 'ATTCU', 'STDCU', 'STDXCU', 'STDPROCU' ];

export const UserPartition = { MAX_RETURNED_USERS: 1000 };

export const hyperLink = {
    CLOUD_RPA: 'https://www.uipath.com/developers/guides-and-resources/',
    ORCHESTRATOR: 'https://docs.uipath.com/orchestrator/lang-{0}',
    ROBOT: 'https://docs.uipath.com/robot/lang-{0}',
    STUDIO: 'https://docs.uipath.com/studio/lang-{0}',
    DATA_SERVICE: 'https://docs.uipath.com/data-service/docs/', // ToDo: Add language specific link once available
    AIFABRIC: 'https://docs.uipath.com/ai-fabric/lang-{0}/v0/docs/about-ai-fabric',
    ACTIVITIES_GUIDE: 'https://docs.uipath.com/activities/lang-{0}',
    INTEGRATION_GUIDE: 'https://docs.uipath.com/marketplace/lang-{0}',
    CONNECT: 'https://connect.uipath.com/',
    FORUM: 'https://forum.uipath.com/',
    JAPAN_FORUM: 'https://forum.uipath.com/c/japan',
    ACADEMY: 'https://academy.uipath.com/learn',
    ORCHESTRATOR_MOBILE_ANDROID: 'https://play.google.com/store/apps/details?id=com.uipath.orchestrator',
    ORCHESTRATOR_MOBILE_IOS: 'https://itunes.apple.com/us/app/uipath-orchestrator/id1448283884?mt=8',
    SECURITY_WHITEPAPER: '',
    UIPATH_CERTIFICATIONS: 'https://www.uipath.com/learning/certification',
    AZURE_UIPATH_ROBOT: ' https://azuremarketplace.microsoft.com/{0}-us/marketplace/apps/uipath-5054924.uipath_robot_vm',
    AWS_UIPATH_ROBOT: '',
    CLOUD_PLATFORM_GUIDE: 'https://docs.uipath.com/cloudplatform',
    COMPUTER_VISION:
    'https://docs.uipath.com/activities/docs/about-the-ui-automation-activities-pack#section-computer-vision',
    AUTOMATION_HUB: 'https://docs.uipath.com/automation-hub/docs/introduction-to-the-automation-cloud-integration',
    AUTOMATION_STORE: 'https://docs.uipath.com/automation-hub/docs/automation-store',
    AUTOMATION_OPS: 'https://docs.uipath.com/automation-ops',
    TERMS: 'https://www.uipath.com/legal/terms-of-use',
    TERMS_JA: 'https://www.uipath.com/ja/legal/terms-of-use',
    POLICY: 'https://www.uipath.com/legal/privacy-policy',
    POLICY_JA: 'https://www.uipath.com/ja/legal/privacy-policy',
    UIPATH_REMOTE_RUNTIME_GUIDE: 'https://docs.uipath.com/studio/docs/about-uipath-remote-runtime',
    TASK_CAPTURE: 'https://docs.uipath.com/task-capture',
    BROWSER_MIGRATION: 'https://docs.uipath.com/automation-cloud/docs/using-the-migration-tool',
    UIPATH_REMOTE_RUNTIME_DOWNLOAD_BASE: 'https://download.uipath.com/',
    CONTACT: 'https://www.uipath.com/company/contact-us',
    UIPATH_APPS: 'https://docs.uipath.com/apps/docs/introduction',
    UIPATH_ACTION_CENTER: 'https://docs.uipath.com/action-center/v0/docs/introduction',
    AAD_English_DOCUMENTATION: 'https://docs.uipath.com/automation-cloud/docs/azure-ad-integration',
    AAD_Japanese_DOCUMENTATION: 'https://docs.uipath.com/automation-cloud/lang-ja/docs/azure-ad-integration',
    USER_MANAGEMENT: 'https://docs.uipath.com/automation-cloud/docs/about-users',
    AUTOMATION_SUITE: 'https://docs.uipath.com/automation-suite/docs',
};

export const releaseNote = {
    enterpriseReleaseLink: 'https://www.uipath.com/product/release-notes',
    communityReleaseLink: 'https://forum.uipath.com/c/releases',
    commonReleaseLink: 'https://docs.uipath.com/releasenotes/docs/',
    communityStableReleaseNotes: 'https://forum.uipath.com/c/news/release-notes',
    releaseNoteLink: 'https://docs.uipath.com/releasenotes',
};

export const CharacterLimitForNames = {
    maxAccountNameLength: 30,
    maxNameLength: 32,
    maxExternalAppNameLength: 200,
};

export const accountSetting = {
    SITE_URL: 'cloud.uipath.com/',
    companyNameLength: 256,
    accountLogicalNameLength: 64,
    duplicateNameErrorCode: 7015,
};

export const notificationType = {
    ENABLED: 'success',
    DISABLED: 'success',
    SUCCESS: 'success',
    INPROGRESS: 'default',
    DEFAULT: 'default',
    ENABLE_STATUS_INPROGRESS: 'default',
    DISABLE_STATUS_INPROGRESS: 'default',
    ERROR: 'error',
};

export const ERROR_MAX_STRING_LENGTH = 196;

export const dashboardLicenses = {
    ROBOTS: 'robots',
    STUDIOS: 'studios',
    OTHER_SERVICES: 'other services',
    LINK: 'Link',
    ACTIVE_LINK: 'activeLink',
    ROBOTS_AND_SERVICES: 'robotsAndServices',
    CONSUMABLES: 'consumables',
    USERS: 'users',
    TRIAL_SERVICES: 'trialServices',
    EMPTY: '',
};

export const ConcurrentUserLicenses: string[] = [ 'ATTUCU', 'CTZDEVCU', 'RPADEVCU', 'RPADEVPROCU' ];

export const LegacyProductsToUserBundleLicenseMap: { [key: string]: string } = {
    ATTNU: 'ATTUNU',
    ATTCU: 'ATTUCU',
    STDXNU: 'CTZDEVNU',
    STDXCU: 'CTZDEVCU',
    STDNU: 'RPADEVNU',
    STDCU: 'RPADEVCU',
    STDPRONU: 'RPADEVPRONU',
    STDPROCU: 'RPADEVPROCU',
};

export const licenseTypes = {
    STANDALONE: 'STANDALONE',
    HOSTED: 'HOSTED',
    HOST: 'HOST',
};

export enum ConsumptionInfo {
    AllocatedQuantity = 'CLIENT_ALLOCATED_QTY',
    AvailableForAllocation = 'CLIENT_AVAILABLE_FOR_ALLOCATION_QTY',
    ConsumedOnTenant = 'CLIENT_CONSUMED_ON_TENANT_QTY',
    AllocatedAcrossOtherTenants = 'CLIENT_ALLOCATED_ACROSS_OTHER_TENANTS_QTY',
    ConsumedByDeletedTenants = 'CLIENT_CONSUMED_BY_DELETED_TENANTS_QTY',
    TotalUnitsInAccount = 'CLIENT_TOTAL_UNITS_IN_ACCOUNT',
    ConsumedAtOrganizationLevel = 'CLIENT_CONSUMED_AT_ORGANIZATION_LEVEL',
}

export enum ProductTypes {
    ConsumptionInterval = 'CONSUMPTION_INTERVAL',
    User = 'USER',
    Standard = 'STANDARD',
    Service = 'SERVICE',
    Consumption = 'CONSUMPTION',
    Unlimited = 'UNLIMITED',
}

export enum CustomScopes {
    Tenant = 'tenant',
}

export enum ShellEventNames {
    LOGO_CLICKED = 'logoClicked',
    SERVICE_CLICKED = 'serviceClicked',
}

export const CONSUMABLES_PRODUCTS = [ 'AIU', 'RU' ];

export const consumableToLearnMoreLink: { [key: string]: IUiLinkMap } = {
    AIU: ConsumablesAiUnitsLearnMoreLinks,
    RU: ConsumablesRobotUnitsLearnMoreLinks,
    APICALLS: ConsumablesApiCallsLearnMoreLinks,
    TransportUnits: ConsumablesTransportUnitsLearnMoreLinks,
};

export const consumableToViewUsageMap: { [key: string]: boolean } = {
    AIU: true,
    RU: true,
    APICALLS: true,
    TransportUnits: false,
};

export const useConsumableToServicesMap = (): { [key: string]: string[] } => {
    const enableShowAiuChartForProcessMining = useFeatureFlagValue(Features.EnableShowAiuChartForProcessMining.name);
    const enableShowAiuChartForIntegrationService = useFeatureFlagValue(Features.EnableShowAiuChartForIntegrationService.name);
    const aiuServicesMap = [ 'documentunderstanding', 'taskmining', 'communicationsmining', 'aicenter' ];
    if (enableShowAiuChartForProcessMining) {
        aiuServicesMap.push('processmining');
    }
    if (enableShowAiuChartForIntegrationService) {
        aiuServicesMap.push('integrationservice.genai');
    }

    return {
        RU: [ 'serverless', 'hypervisor' ],
        AIU: aiuServicesMap,
    };
};

export const defaultMonths = [ 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC' ];

export const consumableCodeToApiKeyIdentifier: { [key: string]: string } = {
    CV: 'MLSERVICE_CVMODEL',
    AIU: 'MLSERVICE_TIEMODEL',
};

export enum AsyncJobStatus {
    Started = 'started',
    Completed = 'completed',
    Failed = 'failed',
}

export const QUOTA_REACHED_MESSAGE = 'Quota reached, cannot create more objects.';
