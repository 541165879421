import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';

const useIsDedicated = () => useFeatureFlagValue(
    Features.EnableOnDedicated.name,
);

export default useIsDedicated;
