// @ts-nocheck
import type {
    AbTestingUserInfo,
    IExperiment,
    ILayerParameter,
    IParameterValue,
} from '@experiences/interfaces';

export async function getParameterValue<T>({
    experiement, parameter, userDetails, withExposureEnabled, accessToken,
}: {
    url: string;
    accountName: string;
    experiment: IExperiment;
    parameter: ILayerParameter;
    userDetails: AbTestingUserInfo;
    withExposureEnabled: boolean;
    accessToken: string;
}): Promise<T> {
    return parameter.defaultValue;
}

export async function getParametersValues(experiment: IExperiment, parameters: ILayerParameter[],
    userDetails: AbTestingUserInfo, withExposureEnabled: boolean, accessToken: string
): Promise<IParameterValue[]> {
    return parameters.map(parameter => ({
        key: parameter.key,
        value: parameter.defaultValue,
    }));
}

export async function logEvent(eventName: string, eventValue: string | number | null,
    eventMetadata: Record<string, unknown> | null, userDetails: AbTestingUserInfo, accessToken: string
) {
}
