import {
    prependTo,
    replaceRouteParams,
    useRouteResolver,
} from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React, { useCallback } from 'react';
import {
    useLocation,
    useParams,
} from 'react-router';

import type { IBreadCrumb } from '../interfaces/breadcrumb';

interface IBreadcrumbProviderProps {
    breadcrumbs: Array<IBreadCrumb & { index: number }>;
    original: Array<IBreadCrumb & { index: number }>;
}

const BreadcrumbContext = React.createContext<IBreadcrumbProviderProps>({
    breadcrumbs: [],
    original: [],
});

export const useBreadcrumbs = () => React.useContext(BreadcrumbContext);

const BreadcrumbProvider: React.FC<PropsWithChildren<{ breadcrumbs: Array<IBreadCrumb & { index: number }>; legacy?: boolean }>> = ({
    children, breadcrumbs, legacy = false,
}) => {
    const path = useLocation();
    const getRoute = useRouteResolver();
    const params = useParams();

    const currentBreadcrumbs = useCallback((breadcrumbObj: Array<IBreadCrumb & { index: number }>) => {
        let listOfBreadcrumbs: Array<IBreadCrumb & { index: number }> = [];
        const sortedBreadcrumbObj = breadcrumbObj.sort((breadcrumbA, breadcrumbB) =>
            breadcrumbA.index <= breadcrumbB.index ? -1 : 1
        );
        sortedBreadcrumbObj.every((breadcrumb) => {
            const parsedLink = replaceRouteParams(
                prependTo(breadcrumb.link, !legacy) as string,
                params,
            );
            const parsedPathname = typeof parsedLink === 'string'
                ? parsedLink : parsedLink.pathname ?? '';

            // The provider will only add the breadcrumb to the generated list of breadcrumbs if the relative routes match
            // Working Example: [ { index: 0, link: '/admin/tenant/{tenantId}', name: TenantAdmin },
            //                    { index: 1, link: '/admin/tenant/{tenantId}/subRoute', name: SubRoute } ]
            // Non-working Example: [ { index: 0, link: '/admin/tenant/{tenantId}', name: TenantAdmin },
            //                        { index: 1, link: '/admin/subRoute', name: SubRoute } ]
            if (!listOfBreadcrumbs[breadcrumb.index] && (legacy || path.pathname.includes(parsedPathname))) {
                const paramBreadcrumb = {
                    ...breadcrumb,
                    link: parsedPathname,
                };
                listOfBreadcrumbs = [
                    ...listOfBreadcrumbs.slice(0, breadcrumb.index),
                    paramBreadcrumb,
                    ...listOfBreadcrumbs.slice(breadcrumb.index),
                ];
            }

            if (path.pathname === getRoute(parsedLink)) {
                return false;
            }
            return true;
        });
        return listOfBreadcrumbs;
    }, [ getRoute, legacy, params, path.pathname ]);

    return <BreadcrumbContext.Provider value={{
        breadcrumbs: currentBreadcrumbs(breadcrumbs),
        original: breadcrumbs,
    }}>
        {children}
    </BreadcrumbContext.Provider>;
};

export default BreadcrumbProvider;
