import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ColorErrorTextDark } from '@uipath/apollo-core/lib/_generated/Colors';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import type { INotificationEntry } from './interfaces/notifications';
import { INotificationActionType } from './interfaces/notifications';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
            'text-wrap': 'wrap',
        },
    }),
}));
const ITEM_HEIGHT = 48;
export const NotificationMoreRowActionsComponent: React.FC<{
    notification: INotificationEntry;
    action: any;
}> = ({
    notification, action,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const currentTenant = localStorage.getItem('PORTAL_CURRENT_TENANT');
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const clickMenuItem = useCallback((
        click: (_: any, actionType: INotificationActionType, event: any) => void,
        row: INotificationEntry,
        actionType: INotificationActionType,
        event: React.MouseEvent<HTMLLIElement>,
    ) => {
        setAnchorEl(null);
        click(row, actionType, event);
    }, []);

    const clickMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }, []);

    return (
        <>
            <Tooltip title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    className={classes.showMoreButton}
                    onClick={event => clickMenu(event)}
                    data-cy="ui-grid-show-more-actions-button"
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                data-cy="ui-grid-show-more-actions-menu"
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '30ch',
                    },
                }}
            >
                <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => (clickMenuItem(action, notification, INotificationActionType.UnsubscribeTopic, event))}>
                    <ListItemText primary={translate({ id: 'CLIENT_NOTIFICATION_MENU_MUTE' }, { 0: notification.topicName })} />
                </MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => (clickMenuItem(action, notification, INotificationActionType.UnsubscribeTenant, event))}>
                    <ListItemText primary={translate({ id: 'CLIENT_NOTIFICATION_MENU_MUTE' }, { 0: currentTenant })} />
                </MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => (clickMenuItem(action, notification, INotificationActionType.Delete, event))}>
                    <ListItemText
                        primaryTypographyProps={{ color: ColorErrorTextDark }}
                        primary={translate({ id: 'CLIENT_DELETE' })} />
                </MenuItem>
            </Menu>
        </>
    );
};
