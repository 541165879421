import { isStringEmpty } from '@experiences/util';
import { Loader } from '@googlemaps/js-api-loader';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import {
    DIRECT_BUY_FLOW,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
} from '../helpers/EcommerceHelpers';
import { useEcommerce } from '../helpers/useEcommerce';
import type { ISubscriptionForm } from '../interfaces/ecommerce';

declare global {
    interface Window {
        gm_authFailure(): void;
    }
}
const UseEcommerceAddressLine1ViewModel = () => {
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const logEcommerceEvent = useEcommerceTelemetry();
    function gm_authFailure() {
        logEcommerceEvent('Checkout.AddressSuggestionsError', { Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW });
    }
    window.gm_authFailure = gm_authFailure;

    const isNotAddress = (suggestion: google.maps.places.AutocompletePrediction) => {
        if (!suggestion?.types) {
            return false;
        }
        return !suggestion.types.some(x => [ 'street_address', 'premise', 'establishment', 'route', 'point_of_interest' ].includes(x));
    };
    const [ optionsList, setOptions ] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [ loading, setLoading ] = useState(false);
    const [ selectedListing, setSelectedListing ] = useState<string | undefined | google.maps.places.AutocompletePrediction>('');
    const [ open, setOpen ] = useState(false);
    const { setIsAddressValidationUsed } = useEcommerce();
    const {
        control, formState: { errors }, setValue,
    } = useFormContext<ISubscriptionForm>();

    useEffect(() => {
        const loader = new Loader({
            apiKey: window.env.GOOGLE_PLACES_KEY || '',
            libraries: [ 'places' ],
        });

        loader.importLibrary('places').then(() => {})
            .catch(() => {
                logEcommerceEvent('Checkout.AddressSuggestionsError', { Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW });
            });
    }, [ isDirectBuyInProgressFlow, logEcommerceEvent ]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setLoading(false);
        }
    }, [ open ]);

    const handleBeforeInput = useCallback(() => {
        setIsAddressValidationUsed(false);
    }, [ setIsAddressValidationUsed ]);

    const handleSearch = useCallback(async (term?: string) => {
        const displaySuggestions = function(predictions: google.maps.places.AutocompletePrediction[] | null) {
            const places = predictions?.flatMap(x => isNotAddress(x) ? [] : x);
            setOptions(places ?? []);
        };

        const service = new google.maps.places.AutocompleteService();
        if (term !== undefined && !isStringEmpty(term)) {
            await service.getPlacePredictions({ input: term }, displaySuggestions);
        }
        setLoading(false);
    }, []);

    const getFormattedOptionLabel =
        useCallback((option: google.maps.places.AutocompletePrediction) => option.structured_formatting.main_text, []);

    const onChange = useCallback((inputValue: google.maps.places.AutocompletePrediction | null) => {
        if (inputValue) {
            const value = inputValue.structured_formatting.main_text;
            setValue('addressLine1', value);
            setSelectedListing(getFormattedOptionLabel(inputValue));
            setIsAddressValidationUsed(true);
        }
    }, [ getFormattedOptionLabel, setValue ]);

    const getOptionLabel = useCallback((option: google.maps.places.AutocompletePrediction | string) => {
        if (typeof option === 'string') {
            return option;
        }
        return option.description;
    }, []);

    const onInputChange = useCallback((newInputValue: string) => {
        handleSearch(newInputValue);
        setLoading(true);
    }, [ handleSearch ]);

    const RenderOption = useCallback((optionProps: any, option: google.maps.places.AutocompletePrediction | string) => {
        if (typeof option === 'string') {
            return <li
                {...optionProps}
                key={option}>
                {getOptionLabel(option)}
            </li>;
        }
        return <li
            {...optionProps}
            key={option.place_id}>
            {getOptionLabel(option)}
        </li>;
    }, [ getOptionLabel ]);

    const AutoCompleteOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const AutoCompleteClose = useCallback(() => {
        setOpen(false);
    }, []);

    return {
        optionsList,
        setOptions,
        loading,
        setLoading,
        selectedListing,
        setSelectedListing,
        onChange,
        getOptionLabel,
        onInputChange,
        errors,
        control,
        RenderOption,
        open,
        AutoCompleteOpen,
        AutoCompleteClose,
        getFormattedOptionLabel,
        handleBeforeInput,
    };

};
export default UseEcommerceAddressLine1ViewModel;
