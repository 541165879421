import Checkbox from '@mui/material/Checkbox';
import React, { useEffect } from 'react';

export const IndeterminateCheckbox = React.forwardRef<any, any>(({
    indeterminate, ...rest
}, ref, disabled = false) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [ resolvedRef, indeterminate ]);

    rest.checked = rest.checked && !rest.disabled;
    return (
        <Checkbox
            ref={resolvedRef}
            disabled={disabled}
            indeterminate={indeterminate}
            {...rest}
            inputProps={{ 'aria-label': rest?.['aria-label'] }}
        />
    );
});
