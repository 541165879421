import { UiText } from '@experiences/ui-common';
import { InfoOutlined } from '@mui/icons-material';
import type { InputProps } from '@mui/material';
import {
    Autocomplete,
    ListItem,
    TextField,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tokens, { FontVariantToken } from '@uipath/apollo-core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useDebounce } from 'use-debounce';

import { getTeams } from '../../../../services/notification-preferences';
import { accountGlobalId } from '../../../../store/selectors';
import { useTenantsContext } from '../../../tenants/TenantsContextProvider';
import type { ITeamsTeam } from '../../interfaces/notificationSettings';

const useStyles = makeStyles((theme) => ({
    teamsSelectinput: {
        width: '50%',
        marginTop: '10px',
    },
    teamsText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    teamsInputLabel: {
        fontWeight: Tokens.FontFamily.FontBrandH4Weight,
        fontSize: Tokens.FontFamily.FontMSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
        float: 'left',
    },
    teamsInfoIcon: {
        marginLeft: Tokens.Padding.PadM,
        height: Tokens.FontFamily.FontXsLineHeight,
        width: Tokens.Padding.PadXxxl,
        marginTop: '3px',
    },
    teamsHelptextContainer: {
        marginTop: Tokens.Padding.PadM,
        fontSize: Tokens.FontFamily.FontSSize,
        fontWeight: Tokens.FontFamily.FontWeightDefault,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
}));

interface IUiTeamsPicker {
    value: ITeamsTeam;
    connectionId: string;
    searchOnly?: boolean;
    inputPlaceholder?: string;
    InputProps?: InputProps;
    form?: boolean;
    onChange?: (values: any[]) => void;
    clearErrors?: () => void;
    labelText: string;
    showTooltip: boolean;
    helpText?: string;
}

export const UiTeamsPicker = ({
    value,
    searchOnly,
    onChange,
    clearErrors,
    connectionId = '',
    labelText,
    showTooltip = true,
    helpText = '',
}: IUiTeamsPicker) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ query, setQuery ] = useState<string>(value.displayName ?? '');
    const [ debouncedQuery ] = useDebounce(query, 500);
    const [ selected, setSelected ] = useState<ITeamsTeam[]>([ value ]);

    const [ inputValue, setInputValue ] = useState<string | undefined>(undefined);
    const accountId = useSelector(accountGlobalId);
    const { selectedTenant: { id: tenantId } } = useTenantsContext();

    const {
        data: options, isValidating: loading,
    } = useSWR(
        connectionId && debouncedQuery.trim() ? {
            SupplementConnectionId: connectionId,
            startsWith: debouncedQuery,
            selectedTenantId: tenantId,
            selectedAccountId: accountId,
        } : null,
        getTeams,
    );

    return (
        <Autocomplete
            className={classes.teamsSelectinput}
            data-cy="Teams-picker"
            autoComplete
            value={searchOnly ? selected[0] : (([ value ]) as any[])}
            includeInputInList
            filterSelectedOptions
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.displayName}
            filterOptions={(x) => x}
            options={options ?? []}
            inputValue={inputValue ?? value?.displayName ?? ''}
            loading={!!loading}
            noOptionsText={query && !loading ? translate({ id: 'CLIENT_TEAMS_CHANNEL_PICKER_NO_OPTIONS' }, { query }) : ''}
            loadingText={translate({ id: 'CLIENT_TEAMS_CHANNEL_PICKER_LOADING' })}
            onChange={(_event, newTeamValue, reason) => {
                onChange?.([ newTeamValue ]);

                if (reason === 'removeOption' || reason === 'clear') {
                    clearErrors?.();
                }
                if (Array.isArray(newTeamValue)) {
                    setSelected(newTeamValue);
                } else {
                    setSelected([ newTeamValue ]);
                }

            }}
            onInputChange={(event, newTeamValue) => {
                if (!event) {
                    return;
                }
                setInputValue(newTeamValue);
                setQuery(newTeamValue.trim());
            }}
            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        label={<>
                            <UiText
                                className={classes.teamsInputLabel}>
                                {labelText}
                            </UiText>
                            {showTooltip && <Tooltip
                                arrow
                                title={translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_TEAMS_TOOLTIP' })}>
                                <InfoOutlined
                                    className={classes.teamsInfoIcon}
                                    tabIndex={0}
                                    aria-label={translate({ id: 'CLIENT_NOTIFICATION_TEAMSCONFIG_TEAMS_TOOLTIP' })}
                                />
                            </Tooltip>}
                        </>}
                        fullWidth />
                    {helpText && <UiText
                        className={classes.teamsHelptextContainer}
                        variant={FontVariantToken.fontSizeS} >
                        {helpText}
                    </UiText> }
                </>
            )}
            renderOption={(props, option: ITeamsTeam) => (
                <ListItem
                    {...props}
                    divider
                    disableGutters>
                    <div className={classes.teamsText}>
                        <UiText>
                            {option.displayName}
                        </UiText>
                    </div>
                </ListItem>
            )}
        />

    );
};
