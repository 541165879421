import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    Widget,
    WidgetTemplate,
} from '@experiences/interfaces';
import type { WidgetResources } from '@experiences/widgets';
import { Widgets } from '@experiences/widgets';
import Box from '@mui/material/Box';
import * as React from 'react';

import { WidgetRender } from './WidgetRender';

export interface WidgetFactoryProps {
    widgets: Widget[];
}

export const WidgetFactory = ({ widgets }: WidgetFactoryProps) => {
    const EnableTemplateWidget = useFeatureFlagValue(
        Features.EnableTemplateWidget.name,
    );

    const templates = widgets.map((widget) => Widgets.find((t) => t.name === widget));

    // remove tenants widget if EnableTemplateWidget is set to false
    const filteredTemplates = EnableTemplateWidget
        ? templates
        : templates.filter((t) => t?.name !== 'Templates');

    return <Box
        data-testid="widget-factory-container"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
        }}>
        { filteredTemplates.map((template, idx) => {
            if (template === undefined) {
                return null;
            }

            return (
                <WidgetRender
                    key={idx}
                    template={template as WidgetTemplate<WidgetResources>} />
            );
        }) }
    </Box>;
};
