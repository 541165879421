import { GlobalStyles } from '@experiences/theme';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';

import type { IProductPriceOptions } from '../interfaces/ecommerce';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        dropdownCurrency: {
            marginLeft: '12px',
            '& .MuiSelect-select': {
                paddingTop: '0px !important',
                paddingBottom: '0px !important',
                paddingLeft: '0px !important',
                paddingRight: '32px !important',
            },
            '& fieldset': { border: '0px' },
        },
        focusedDropdown: { '& .MuiSelect-select:focus': { outline: '1px solid' } },
        input: {
            height: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    }),
}));

export const EcommerceCurrencyDropdown: React.FC<{
    selectedCurrency: string;
    setSelectedCurrency: (value: (((prevState: string) => string) | string)) => void;
    productsPricesInAllCurrencies: IProductPriceOptions[];
}> = ({
    selectedCurrency,
    setSelectedCurrency,
    productsPricesInAllCurrencies,
}) => {
    const classes = useStyles();

    const allCurrencies = useMemo<string[]>(
        () => {
            const currenciesSet = new Set(productsPricesInAllCurrencies?.flatMap(p =>
                Object.keys(p.prices)));
            return Array.from(currenciesSet.values()).sort();
        },
        [ productsPricesInAllCurrencies ],
    );
    const handleChange = useCallback((event: any) => {
        if (event.target) {
            setSelectedCurrency(event.target.value);
        }
    },
    [ setSelectedCurrency ],
    );
    return (
        <Select
            className={`${classes.dropdownCurrency} ${classes.focusedDropdown}`}
            value={selectedCurrency}
            onChange={handleChange}
            inputProps={{
                id: 'currency',
                name: 'currency',
                'data-cy': 'ecommerce-select-currency',
                className: classes.input,
            }}
        >
            {allCurrencies.map((currency) => (
                <MenuItem
                    key={currency}
                    value={currency}
                    aria-label={currency}
                >
                    {currency}
                </MenuItem>
            ))}
        </Select>
    );
};
