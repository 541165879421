import { useSelector } from 'react-redux';

import { isHostModeSelector } from '../../store/selectors';
import useIsDedicated from './useIsDedicated';

const useProductNameSuffix = () => {
    const isDedicated = useIsDedicated();
    const isHost = useSelector(isHostModeSelector);

    let productNameSuffix = '';
    if (isDedicated) {
        productNameSuffix += ' Dedicated';
    }
    if (isHost) {
        productNameSuffix += ' | Host';
    }
    return productNameSuffix;
};

export default useProductNameSuffix;
