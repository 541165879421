import type { GridFiltersState } from '@experiences/util';
import { encodeState } from '@experiences/util';
import urljoin from 'url-join';

export const redirectToOrchestrator = (
    organizationName: string | undefined,
    tenantName: string | undefined,
    filterObject: GridFiltersState,
) => {
    const state = encodeState({
        filterObject,
        sortBy: 'name',
        sortOrder: 'asc',
    }).toString();

    return urljoin(
        window.location.origin,
        organizationName ?? '',
        tenantName ?? '',
        'orchestrator_',
        'tags',
        'search',
        `?${state}`,
    );
};
