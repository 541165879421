import type {
    ActionHandle,
    Card,
    WidgetContext,
} from '@experiences/interfaces';
import {
    ApCardContainer,
    ApGridLayout,
} from '@uipath/portal-shell-react';
import React from 'react';

import { UiAllocationCard } from './UiAllocationCard';
import { UiCardWrapper } from './UiCardWrapper';
import { UiLargeCard } from './UiLargeCard';

export interface UiCardsWidgetProps<T> {
    id: string;
    fixed?: boolean;
    cards: Array<Card<T>>;
    rows?: number;
    actionHandles?: Array<ActionHandle<T>>;
    clickHandler?: (data: T, ctx: WidgetContext) => void;
    cardHeight?: number;
}

export const UiCardsWrapper = <T extends {}>({
    cards, id, fixed, actionHandles, rows, clickHandler, cardHeight,
}: UiCardsWidgetProps<T>) => <ApGridLayout
    fixed={fixed}
    rows={rows}
    data-testid={`${id}-card-grid`}
    rowHeightInPixel={cardHeight}>
    {cards.map((card, idx) => (
        <ApCardContainer
            data-testid={`${id}-card-container-${idx}`}
            key={idx}>
            {card.type === 'Normal' &&
                    <UiCardWrapper
                        card={card}
                        id={id}
                        clickHandler={clickHandler}
                        actionHandles={actionHandles}
                        cardIndex={idx}
                        cardHeight={cardHeight} />}
            {card.type === 'Large' &&
                <UiLargeCard
                    card={card}
                    id={id}
                    clickHandler={clickHandler}
                    cardIndex={idx} />}
            {card.type === 'Allocation' &&
                <UiAllocationCard
                    card={card}
                    id={id}
                    cardIndex={idx} />}
        </ApCardContainer>
    ))}
</ApGridLayout>;
