import type { IFolderTreeDTO } from '@experiences/ui-common';

import type {
    IEntityTypeDto,
    IFolderEntityDto,
    IFolderUpdatePayloadEntity,
    INotificationMode,
    INotificationPublisherDto,
    INotificationTopic,
    INotificationTopicDto,
    INotificationTopicGroup,
    INotificationTopicPublisher,
} from '../interfaces/notificationSettings';
import { NotificationMode } from '../interfaces/notificationSettings';

const getGroupedTopics = (topics: INotificationTopicDto[]) => {
    const groupedTopics: INotificationTopicGroup[] = [
        {
            groupName: 'un-grouped',
            topics: [],
            groupIndex: 0,
            modes: [],
            isMandatoryChecked: false,
            isMandatoryIndeterminate: false,
            isVisible: false,
            isUpdating: false,
        },
    ];

    topics.forEach((topic: INotificationTopicDto) => {
        if (topic.group == null) {
            groupedTopics[0].topics.push(getTopicListObject(topic, groupedTopics[0].topics.length));
        } else {
            const groupName = topic.group;
            const groupIndex = groupedTopics.findIndex(tg => tg.groupName === groupName);
            if (groupIndex === -1) {
                const groupTopicItem = {
                    groupName,
                    topics: [ getTopicListObject(topic, 0) ],
                    groupIndex: groupedTopics.length,
                    modes: [],
                    isMandatoryChecked: false,
                    isMandatoryIndeterminate: false,
                    isVisible: false,
                } as INotificationTopicGroup;
                groupedTopics.push(groupTopicItem);
            } else {
                groupedTopics[groupIndex].topics.push(getTopicListObject(topic, groupedTopics[groupIndex].topics.length));
            }
        }
    });

    return groupedTopics.map(oldGroup => {
        const topicGroup = { ...oldGroup };
        topicGroup.modes = Object.values(NotificationMode)
            .filter(modeName => isTopicGroupModeVisible(modeName, topicGroup))
            .map(modeName => ({
                name: modeName,
                isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
            }));
        topicGroup.isMandatoryChecked = isTopicGroupModeMandatory(topicGroup);
        topicGroup.isMandatoryIndeterminate = isTopicGroupMandatoryIndeterminate(topicGroup);
        topicGroup.isVisible = isTopicGroupModeVisibleOption(topicGroup);
        return topicGroup;
    });
};

const getGroupedTopicsByMode = (topicgroups: INotificationTopicGroup[]) => {
    const groupedTopics: INotificationTopicGroup[] = [];

    topicgroups.forEach((topicgroup: INotificationTopicGroup) => {

        topicgroup.topics.forEach((topic: INotificationTopic) => {
            const groupName = topic.category;
            const groupIndex = groupedTopics.findIndex(tg => tg.groupName === groupName);
            if (groupIndex === -1) {
                const groupTopicItem = {
                    groupName,
                    topics: [ topic ],
                    groupIndex: groupedTopics.length,
                    modes: [],
                    isMandatoryChecked: false,
                    isMandatoryIndeterminate: false,
                    isVisible: false,
                } as INotificationTopicGroup;
                groupedTopics.push(groupTopicItem);
            } else {
                groupedTopics[groupIndex].topics.push(topic);
            }
        });
    });

    return groupedTopics.map((topicGroup: INotificationTopicGroup) => ({
        ...topicGroup,
        modes: Object.values(NotificationMode)
            .map(modeName => ({
                name: modeName,
                isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
            })),
        isMandatoryChecked: isTopicGroupModeMandatory(topicGroup),
        isMandatoryIndeterminate: isTopicGroupMandatoryIndeterminate(topicGroup),
        isVisible: isTopicGroupModeVisibleOption(topicGroup),
    }));
};

const isTopicModeVisible = (modeName: NotificationMode, topic: INotificationTopic) =>
    !!topic.modes?.find(mode => mode.name === modeName);

const isTopicGroupModeVisible = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) =>
    topicGroup.topics.some(topic => isTopicModeVisible(modeName, topic));

export const isTopicGroupModeSubscribed = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getTopicDetail(modeName, topicGroup);
    return hasAnySubscribed && !hasAnyUnSubscribed ? true : false;
};

const isPublisherModeSubscribed = (modeName: NotificationMode, publisher: INotificationTopicPublisher) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getPublisherDetail(modeName, publisher);
    return hasAnySubscribed && !hasAnyUnSubscribed ? true : false;
};
const isPublisherModeMandatory = (publisher: INotificationTopicPublisher) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getPublisherDetailForMandatory(publisher);
    return hasAnySubscribed && !hasAnyUnSubscribed ? true : false;
};

const isPublisherModeVisible = (publisher: INotificationTopicPublisher) => {
    const hasAnyVisible = getPublisherDetailForVisible(publisher);
    return hasAnyVisible;
};

const isTopicGroupModeMandatory = (topicGroup: INotificationTopicGroup) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getTopicDetailForMandatory(topicGroup);
    return hasAnySubscribed && !hasAnyUnSubscribed ? true : false;
};
const isTopicGroupModeVisibleOption = (topicGroup: INotificationTopicGroup) => {
    const [ hasAnyVisible ] = getTopicDetailForVisible(topicGroup);
    return hasAnyVisible ? true : false;
};
export const isTopicGroupModeIndeterminate = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getTopicDetail(modeName, topicGroup);
    return hasAnySubscribed && hasAnyUnSubscribed ? true : false;
};

const isPublisherModeIndeterminate = (modeName: NotificationMode, publisher: INotificationTopicPublisher) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getPublisherDetail(modeName, publisher);
    return hasAnySubscribed && hasAnyUnSubscribed ? true : false;
};
const isTopicGroupMandatoryIndeterminate = (topicGroup: INotificationTopicGroup) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getTopicDetailForMandatory(topicGroup);
    return hasAnySubscribed && hasAnyUnSubscribed ? true : false;
};
const isPublisherMandatoryIndeterminate = (publisher: INotificationTopicPublisher) => {
    const [ hasAnySubscribed, hasAnyUnSubscribed ] = getPublisherDetailForMandatory(publisher);
    return hasAnySubscribed && hasAnyUnSubscribed ? true : false;
};
const getPublisherDetail = (modeName: NotificationMode, publisher: INotificationTopicPublisher) => {
    let hasAnySubscribed = false;
    let hasAnyUnSubscribed = false;
    publisher.topicGroups.filter(x => x.isVisible).forEach(topicGroup => {
        topicGroup.topics.filter(x => x.isVisible).forEach(topic => {
            topic.modes?.forEach(mode => {
                if (!!mode && mode.name === modeName) {
                    if (mode?.isSubscribed || topic.isMandatory) {
                        hasAnySubscribed = true;
                    } else {
                        hasAnyUnSubscribed = true;
                    }
                }
            });
        });
    });
    return [ hasAnySubscribed, hasAnyUnSubscribed ];
};
const getPublisherDetailForMandatory = (publisher: INotificationTopicPublisher) => {
    let hasAnyMandatory = false;
    let hasAnyUnMandatory = false;
    publisher.topicGroups.filter(x => x.isVisible).forEach(topicGroup => {
        topicGroup.topics.filter(x => x.isVisible).forEach(topic => {
            if (topic?.isMandatory) {
                hasAnyMandatory = true;
            } else {
                hasAnyUnMandatory = true;
            }
        });
    });
    return [ hasAnyMandatory, hasAnyUnMandatory ];
};

const getPublisherDetailForVisible = (publisher: INotificationTopicPublisher) => publisher.topicGroups.find(topicGroup => topicGroup?.isVisible) !== undefined;

const getTopicDetail = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) => {
    let hasAnySubscribed = false;
    let hasAnyUnSubscribed = false;
    topicGroup.topics.filter(x => x.isVisible).forEach(topic => {
        topic.modes?.forEach(mode => {
            if (!!mode && mode.name === modeName) {
                if (mode?.isSubscribed || topic.isMandatory) {
                    hasAnySubscribed = true;
                } else {
                    hasAnyUnSubscribed = true;
                }
            }
        });
    });
    return [ hasAnySubscribed, hasAnyUnSubscribed ];
};

const getTopicDetailForMandatory = (topicGroup: INotificationTopicGroup) => {
    let hasAnyMandatory = false;
    let hasAnyUnMandatory = false;
    topicGroup.topics.filter(x => x.isVisible).forEach(topic => {
        if (topic?.isMandatory) {
            hasAnyMandatory = true;
        } else {
            hasAnyUnMandatory = true;
        }
    });
    return [ hasAnyMandatory, hasAnyUnMandatory ];
};
const getTopicDetailForVisible = (topicGroup: INotificationTopicGroup) => {
    let hasAnyVisible = false;
    let hasAnyUnVisible = false;
    topicGroup.topics.forEach(topic => {
        if (topic?.isVisible) {
            hasAnyVisible = true;
        } else {
            hasAnyUnVisible = true;
        }
    });
    return [ hasAnyVisible, hasAnyUnVisible ];
};
const getTopicListObject = (topic: INotificationTopicDto, index: number): INotificationTopic => ({
    displayName: topic.displayName,
    topicId: topic.id,
    name: topic.name,
    isSubscribed: topic.isSubscribed,
    topicFiltersUrl: topic.topicFilters,
    topicIndex: index,
    modes: topic.modes?.map((newMode: INotificationMode, index: number) => ({
        name: newMode.name,
        isSubscribed: newMode.isSubscribed,
        isIndeterminate: newMode.isIndeterminate ?? false,
        isSubscribedByDefault: newMode.isSubscribedByDefault,
        isUpdating: false,
    })),
    description: topic.description,
    category: topic.category,
    isUpdating: false,
    isMandatory: topic.isMandatory,
    isVisible: topic.isVisible,
});

export const getPublishersWithGroupedTopics = (publishers: INotificationPublisherDto[]): INotificationTopicPublisher[] => {
    const groupedPublisher: INotificationTopicPublisher[] = publishers
        .map((publisher: INotificationPublisherDto, index: number) => {
            const topicGroups = getGroupedTopics(publisher.topics);
            return {
                name: publisher.name,
                displayName: publisher.displayName,
                modes: publisher.modes
                    .map((newMode: INotificationMode, index: number) => ({
                        name: newMode.name,
                        isSubscribed: newMode.isSubscribed,
                        isIndeterminate: newMode.isIndeterminate ?? false,
                    })),
                publisherIndex: index,
                publisherId: publisher.id,
                topicGroups,
                isMandatory: false,
                isMandatoryInDeterminate: false,
                isVisible: false,
                isUserOptIn: publisher.isUserOptin,
                entityTypes: publisher.entityTypes,
                entities: publisher.entities,
                publisherSubscriptionProfiles: publisher.publisherSubscriptionProfiles ?? [],
            };
        });
    const newgroupedPublisher = groupedPublisher.map((publisher) => {
        const newpublisher = { ...publisher };
        newpublisher.modes = newpublisher.modes
            .map((newMode: INotificationMode, index: number) => ({
                name: newMode.name,
                isSubscribed: isPublisherModeSubscribed(newMode.name, newpublisher),
                isIndeterminate: isPublisherModeIndeterminate(newMode.name, newpublisher),
            }));

        newpublisher.isMandatory = isPublisherModeMandatory(newpublisher);
        newpublisher.isMandatoryInDeterminate = isPublisherMandatoryIndeterminate(newpublisher);
        newpublisher.isVisible = isPublisherModeVisible(newpublisher);
        return newpublisher;
    });

    return newgroupedPublisher;
};

export const getPublishersWithSeverity = (publishers: INotificationTopicPublisher[]): INotificationTopicPublisher[] => {
    const groupedPublisher: INotificationTopicPublisher[] = publishers
        .map((publisher: INotificationTopicPublisher, index: number) => {
            const topicGroups = getGroupedTopicsByMode(publisher.topicGroups);
            return {
                name: publisher.name,
                displayName: publisher.displayName,
                modes: publisher.modes,
                publisherIndex: index,
                publisherId: publisher.publisherId,
                topicGroups,
                isMandatory: false,
                isUserOptIn: publisher.isUserOptIn,
                isMandatoryInDeterminate: false,
                publisherSubscriptionProfiles: publisher.publisherSubscriptionProfiles ?? [],
                isVisible: false,
                entityTypes: [],
                entities: [],
            };
        });
    groupedPublisher.forEach(publisher => {

        publisher.modes = publisher.modes
            .map((newMode: INotificationMode) => ({
                name: newMode.name,
                isSubscribed: isPublisherModeSubscribed(newMode.name, publisher),
                isIndeterminate: isPublisherModeIndeterminate(newMode.name, publisher),
            }));

        publisher.isMandatory = isPublisherModeMandatory(publisher);
        publisher.isMandatoryInDeterminate = isPublisherMandatoryIndeterminate(publisher);
        publisher.isVisible = isPublisherModeVisible(publisher);
    });

    return groupedPublisher;
};

export const findTreeNode = (Key: string, groupedTopics: IFolderTreeDTO[]): IFolderTreeDTO | undefined => {
    const treeNode = groupedTopics.find(x => x.Key === Key);
    if (treeNode !== undefined) {
        return treeNode;
    }
    let childNode = undefined;
    for (const parent of groupedTopics) {
        const node = findTreeNode(Key, parent.children) ?? undefined;
        if (node !== undefined) {
            childNode = node;
            break;
        }
    }
    return childNode;
};

const updateChildrenAndReturn = (
    key: string,
    groupedTopics: IFolderTreeDTO[],
    isSubscribed: boolean,
    type: string,
    name: string,
    isUpdating: boolean
) => {
    let payloadEntities: IFolderUpdatePayloadEntity[] = [];

    groupedTopics.forEach(item => {
        const groupTopicItem = {
            type,
            name: item.Key,
            parentName: key,
            isSubscribed,
        } as IFolderUpdatePayloadEntity;
        item.isChecked = isSubscribed;
        item.isUpdating = isUpdating;
        payloadEntities.push(groupTopicItem);
        if (item.children.length > 0) {
            payloadEntities = [
                ...payloadEntities,
                ...updateChildrenAndReturn(item.Key, item.children, isSubscribed, type, name, isUpdating),
            ];
        }
    });
    return payloadEntities;
};

export const createTreeFromFolderDTO = (entities: IFolderEntityDto[], entityDto: IEntityTypeDto, data?: any[]) => {
    const groupedTopics: IFolderTreeDTO[] = [];
    if (data !== undefined) {
        for (const topic of data) {
            const excludeNode = entities.find(x => x.name === topic.Key && !x.isSubscribed);
            const publicationPayloadProperty = 'Key';
            const projectionProperty = entityDto.projectionProperty;
            const ParentKeyProperty = 'ParentKey';
            const displayName = topic[projectionProperty];
            const mainKey = topic[publicationPayloadProperty];
            const parentKeyPropertyValue = topic[ParentKeyProperty];

            const groupTopicItem = {
                DisplayName: displayName,
                Key: mainKey,
                ParentKey: parentKeyPropertyValue,
                children: [],
                isChecked: excludeNode === undefined ? true : false,
                isHidden: false,
                isCategorized: topic.IsPersonal,
            } as IFolderTreeDTO;

            if (!findParentAndAdd(groupTopicItem, groupedTopics)) {
                groupedTopics.push(groupTopicItem);
            }
        }
    }
    return groupedTopics;
};

const findParentAndAdd = (item: IFolderTreeDTO, groupedTopics: IFolderTreeDTO[]) => {
    const parentKey: string = item.ParentKey;
    if (parentKey === undefined) {
        return false;
    }
    const parent = groupedTopics.find(x => x.Key === parentKey);
    if (parent !== undefined) {
        parent.children.push(item);
        return true;
    }
    for (const parentTopic of groupedTopics) {
        if (findParentAndAdd(item, parentTopic.children)) {
            return true;
        }
    }
    return false;
};

export const updateNodesAndReturnEntities = (entity: IEntityTypeDto, checked: boolean, treeNode?: IFolderTreeDTO) => {
    let payloadEntities: IFolderUpdatePayloadEntity[] = [];

    const groupTopicItem = {
        type: entity.type,
        name: treeNode?.Key,
        parentName: '',
        isSubscribed: checked,
    } as IFolderUpdatePayloadEntity;

    payloadEntities.push(groupTopicItem);
    if (treeNode !== undefined && treeNode?.children.length > 0) {
        payloadEntities = [
            ...payloadEntities, ...updateChildrenAndReturn(treeNode?.Key, treeNode.children,
                checked, entity.type, entity.publicationPayloadProperty, true),
        ];
    }
    return payloadEntities;
};

export const hasAnyChangeInPublisherPreferences = (topicGroups: INotificationTopicGroup[], managedMode: boolean) => {
    const hasChanges = false;

    for (let groupIndex = 0; groupIndex < topicGroups.length; groupIndex++) {
        const topicGroup = topicGroups[groupIndex];
        for (let i = 0; i < topicGroup.topics.length; i++) {
            const topic = topicGroup.topics[i];
            const hasAnyChanged = topic.modes?.find(x => x.isSubscribedByDefault !== x.isSubscribed && !topic.isMandatory);
            if (hasAnyChanged || (managedMode && (topic.isMandatory || !topic.isVisible))) {
                return true;
            }
        }
    }
    return hasChanges;
};
