import {
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import type { IDirectoryEntry } from '../../../common/interfaces/cis/directory';
import { UiPeoplePicker } from '../../common/UiPeoplePicker';

const useStyles = makeStyles(theme =>
    createStyles({
        form: { marginTop: Tokens.Padding.PadXxxl },
        peoplePicker: { minWidth: '350px' },
        configureTitle: {
            marginLeft: '64px',
            fontSize: Tokens.FontFamily.FontHeader4Size,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            marginBottom: Tokens.Padding.PadXxxl,
        },
        configureIcon: {
            color: Tokens.Colors.ColorError500,
            marginRight: Tokens.Padding.PadXl,
        },
        circularProgress: {
            color: Tokens.Colors.ColorWhite,
            marginRight: Tokens.Padding.PadXxxl,
        },
        progressMessage: {
            fontSize: Tokens.FontFamily.FontHeader3Size,
            color: Tokens.Colors.ColorWhite,
        },
        backdrop: { zIndex: 1300 },
        userGroupConfig: { marginBottom: '24px' },
        userGroupTitle: {
            marginBottom: Tokens.Padding.PadS,
            fontWeight: Tokens.FontFamily.FontWeightBold,
        },
        userGroupCantCOnfig: { marginBottom: '24px' },
    }),
);

export const AddUserGroupModal: React.FC = () => {

    const localGroup = 'local';
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const [ refresh, setRefresh ] = useState(false);
    const [ selectGroup, setSelectGroup ] = React.useState<IDirectoryEntry | null>();
    const close = useCallback(
        () =>
            navigate(getRoute(RouteNames.NotificationSettings),
                { state: refresh ? { refresh: true } : undefined }),
        [ navigate, getRoute, refresh ],
    );
    const dialogActions = useMemo(() => {
        const actions = [];
        actions.push(
            <Button
                key="cancel"
                data-cy="button-cancel"
                onClick={close}
                color="primary">
                {translate({ id: 'CLIENT_CANCEL' })}
            </Button>, <Button
                key="configure"
                data-cy="button-configure"
                disabled={!selectGroup}
                variant="contained"
                onClick={() => {
                    if (!selectGroup) {
                        return;
                    }
                    const hasEmail = !!selectGroup.email;
                    const isLocalGroup = selectGroup.source === localGroup;

                    const editProfileRoute = RouteNames.AddNotificationSettingsProfile
                        .replace(':id', `${selectGroup.identifier}`)
                        .replace(':name', `${selectGroup.displayName}`);

                    navigate(getRoute(editProfileRoute), {
                        state: {
                            identifier: selectGroup.identifier,
                            type: 'NewUserGroup',
                            local: isLocalGroup,
                            hasEmail,
                        },
                    });
                }}
                color="primary">
                {translate({ id: 'CLIENT_CONFIGURE' })}
            </Button>,

        );
        return actions;
    }, [ close, translate, selectGroup, navigate, getRoute ]);

    const onEditRow = useCallback((directoryEntries: IDirectoryEntry[]) => {
        if (directoryEntries.length > 0 && directoryEntries[0]) {
            setSelectGroup(directoryEntries[0]);
        } else {
            setSelectGroup(undefined);
        }
        setRefresh(true);
    }, []);

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_SELECT_USER_GROUP' })}
            close={() => close()}
            dialogProps={{
                open: true,
                maxWidth: 'xl',
            }}
            actions={dialogActions}
        >
            <UiText className={classes.userGroupConfig}>
                {translate({ id: 'CLIENT_USER_GROUP_CONFIGURE' })}
            </UiText>
            <UiText className={classes.userGroupTitle}>
                {translate({ id: 'CLIENT_USER_GROUP' })}
            </UiText>
            <UiPeoplePicker
                className={classes.peoplePicker}
                searchOnly
                type="group"
                ariaLabel={translate({ id: 'CLIENT_USER_GROUP' })}
                inputPlaceholder={translate({ id: 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER' })}
                onChange={onEditRow}
            />
        </UiDialog>
    );
};

export default AddUserGroupModal;
