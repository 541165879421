import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import useMediaQuery from '@mui/material/useMediaQuery';
import random from 'lodash/random';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { contentfulSlide } from '../../common/interfaces/slide';
import {
    carouselURL,
    getContentfulResource,
} from '../../services/CarouselCMS';
import { persona } from '../../store/selectors';
import { rotateArray } from './CarouselUtil';
import { CarouselWrapper } from './CarouselWrapper';
import type { CarouselSlideParameters } from './types/CarouselSlideParameters';

const Carousel = () => {
    const carouselMinWidth = useMediaQuery('(min-width:768px)');
    const language = useLocalization();
    const EnableHomePageCarousel = useFeatureFlagValue(Features.EnableHomePageCarousel.name);
    const [ isOpen, setOpen ] = useState(localStorage.getItem('cloudRPA_carousel_enabled') !== 'false');

    const dominantPersona = useSelector(persona);

    const [ slides, setSlides ] = useState<CarouselSlideParameters[]>([]);
    const { data } = useSWR(
        EnableHomePageCarousel ? {
            url: carouselURL,
            language,
            dominantPersona,
        } : null,
        getContentfulResource,
    );

    const initialStartIdx = () => Number(localStorage.getItem('cloudRPA_carousel_start_index') ?? -1);
    const [ startIdx, setStartIdx ] = useState(initialStartIdx);

    const carouselSlidesDataArray = useMemo(() =>
        data?.homePageCarousel?.slidesCollection?.items
            .filter((obj) => obj != null)
            .map((obj: contentfulSlide) => ({
                ...obj,
                lineImage: obj.lineImage ? obj.lineImage.url : undefined,
                backgroundImage: obj.backgroundImage
                    ? obj.backgroundImage.url
                    : undefined,
            })), [ data ]);

    useEffect(() => {
        if (carouselSlidesDataArray !== undefined) {
            let rotatedArray = carouselSlidesDataArray;

            if (startIdx === -1) {
                setStartIdx(random(0, carouselSlidesDataArray.length - 1));
            }

            const newStartIdx = (startIdx + 1) % carouselSlidesDataArray.length;

            // Rotate array based on startIdx that is either fetched or generated randomly
            rotatedArray = rotateArray(carouselSlidesDataArray, newStartIdx);

            localStorage.setItem('cloudRPA_carousel_start_index', `${newStartIdx}`);

            setSlides(rotatedArray);
        }

    }, [ startIdx, carouselSlidesDataArray ]);

    return (
        <>
            {isOpen && slides.length !== 0 && carouselMinWidth && (
                <CarouselWrapper
                    slides={slides}
                    setOpen={setOpen} />
            )}
        </>
    );
};

export default Carousel;
