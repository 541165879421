import { NotificationSettingsEvent } from '@experiences/telemetry';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, { useEffect } from 'react';

import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import EmailSettingsFormComponent from './EmailSettingsComponent';

const useStyles = makeStyles((theme) =>
    createStyles({ tabsContainer: { marginBottom: Tokens.Padding.PadL } }),
);

const EmailConfigComponent: React.FC = () => {
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();

    useEffect(() => {
        logEvent(NotificationSettingsEvent.SlackConfigNavigate);
    }, [ logEvent ]);

    return (
        <div
            data-cy="notification-settings-tabs"
            className={classes.tabsContainer}>
            <EmailSettingsFormComponent />
        </div>
    );
};

export default EmailConfigComponent;
