import {
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import { isGuid } from '@experiences/util';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import useOrganizationMismatchViewModel from './OrganizationMismatchViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        warningIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            fontSize: '32px',
        },
        orgsBodyText: { wordBreak: 'break-word' },
    }),
);

const OrganizationMismatchDialog: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        shouldRenderDialog, close, logout, accountName, accountNameInStore, accountGuidInStore,
    } = useOrganizationMismatchViewModel();

    return shouldRenderDialog ? (
        <UiDialog
            close={close}
            title={translate({ id: 'CLIENT_SWITCH_ORGS_HEADER' })}
            icon={<WarningIcon className={classes.warningIcon} />}
            width="420px"
            actions={[
                <Button
                    key={1}
                    variant="contained"
                    onClick={() => logout({ returnUrl: `${window.location.origin}/${accountName}/portal_/cloudrpa` })}
                    data-cy="organization-switcher-continue-button"
                >
                    {translate({ id: 'CLIENT_SIGN_OUT' })}
                </Button>,
                <Button
                    key={2}
                    variant="outlined"
                    onClick={close}
                    data-cy="organization-switcher-cancel-button">
                    {translate({ id: 'CLIENT_BACK' })}
                </Button>,
            ]}
        >
            <UiText
                className={classes.orgsBodyText}
                data-cy="organization-switcher-text">
                {translate(
                    { id: 'CLIENT_SWITCH_ORGS_BODY_TEXT' },
                    {
                        0: <strong>
                            {!isGuid(accountName) ? accountNameInStore : accountGuidInStore}
                        </strong>,
                        1: <strong>
                            {accountName}
                        </strong>,
                    },
                )}
            </UiText>
        </UiDialog>
    ) : null;
};

export default OrganizationMismatchDialog;
