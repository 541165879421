import type {
    IAlert,
    IPagination,
    ISort,
} from '@experiences/interfaces';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSWR from 'swr';

import type { IGridProps } from '../UiGrid';
import { UiGrid } from '../UiGrid';

export const DEFAULT_PAGINATION = {
    top: 25,
    skip: 0,
};

interface IPaginationProps<T, R, V extends {} = Record<string, unknown>> {
    url: string;
    fetcher: ({
        pagination, ...args
    }: { pagination: IPagination } & V) => Promise<R>;
    fetcherArgs?: V;
    refreshData?: { current: boolean; set: (value: boolean) => void };
    setLoading?: (value: boolean) => void ;
    getPagedNumber?: React.Dispatch<React.SetStateAction<IPagination>>;
    hideRefresh?: boolean;
    getGridData?: (data?: R) => T[];
    getTotalCount?: (data?: R) => number;
    alert?: IAlert;
}

/*
 * Expects response (R) to be in form of { results: T[]; totalCount: number }
 * if not, use getGridData and getTotalCount to specify how to get data and total count
 */
export const UiPaginatedGrid = <T extends object, R = any, V extends {} = Record<string, unknown>>({
    url,
    fetcher,
    fetcherArgs = {} as V,
    refreshData,
    setLoading,
    getPagedNumber,
    hideRefresh = false,
    getGridData,
    getTotalCount,
    initialSort,
    alert,
    ...rest
}: Omit<IGridProps<T>, 'data'> & IPaginationProps<T, R, V>) => {
    const [ totalCount, setTotalCount ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ query, setQuery ] = useState<string | undefined>(undefined);
    // paginated grid only supports sorting by one field
    const [ sort, setSort ] = useState<ISort>({
        by: initialSort?.[0].id || undefined,
        order: initialSort?.[0].desc ? 'desc' : undefined,
    });
    const [ pagination, setPagination ] = useState<IPagination>({
        ...DEFAULT_PAGINATION,
        sortBy: sort.by,
        sortOrder: sort.order,
    });

    const updatePagination = useCallback((pageProps: any) => {
        setPagination((prev: IPagination) => ({
            ...prev,
            ...pageProps,
        }));
        getPagedNumber?.((prev: IPagination) => ({
            ...prev,
            ...pageProps,
        }));
    }, [ getPagedNumber ]);

    const [ renderAlert, setRenderAlert ] = useState<boolean>(false);

    const {
        data, error, isValidating, mutate,
    } = useSWR<R, Error>(
        Object.keys(fetcherArgs).length > 0 ? {
            url,
            pagination,
            ...fetcherArgs,
        } : {
            url,
            pagination,
        },
        () => fetcher({
            pagination,
            ...fetcherArgs,
        }),
    );

    useEffect(() => {
        if (refreshData?.current === true) {
            mutate();
            refreshData.set(false);
        }
    }, [ refreshData, pagination, url, fetcherArgs, mutate ]);

    useEffect(() => {
        setLoading?.(isValidating);
    }, [ setLoading, isValidating ]);

    useEffect(() => {
        const total = getTotalCount?.(data) ?? (data as any)?.totalCount;
        if (total) {
            setTotalCount(total);
        }
    }, [ data, getTotalCount ]);

    useEffect(() => {
        if (alert) {
            setRenderAlert(!!alert.message && alert.condition(pagination, sort, totalCount));
        }
    }, [ pagination, sort, totalCount, alert ]);

    const onPageChange = useCallback(
        (pageIndex: number, pageSize: number) => {
            updatePagination({
                top: pageSize,
                skip: pageIndex * pageSize,
            });
        },
        [ updatePagination ],
    );

    const onSearch = useCallback((query?: string) => {
        setCurrentPage(0);
        query ?
            updatePagination({
                skip: 0,
                searchTerm: query,
            })
            : updatePagination({
                ...DEFAULT_PAGINATION,
                searchTerm: '',
            });

    }, [ updatePagination ]);

    const nextSortOrder = useMemo(() => {
        let nextOrder: 'asc' | 'desc' | undefined = undefined;
        if (sort?.order === undefined) {
            nextOrder = 'asc';
        } else if (sort?.order === 'asc') {
            nextOrder = 'desc';
        } else {
            nextOrder = undefined;
        }
        return nextOrder;
    }, [ sort ]);

    const onSort = useCallback(
        (by?: string) => {
            // only get next sort order value if user clicks same attribute again
            const nextOrder = by === sort.by ? nextSortOrder : 'asc';
            const nextBy = nextOrder === undefined ? undefined : by;

            setSort({
                by: nextBy,
                order: nextOrder,
            });
            updatePagination({
                sortBy: nextBy,
                sortOrder: nextOrder,
            });
        },
        [ sort.by, nextSortOrder, updatePagination ],
    );

    const onRefresh = useMemo(
        () =>
            hideRefresh
                ? undefined
                : () => mutate(),
        [ hideRefresh, mutate ],
    );

    const defaultResults = useMemo(() => (data as any)?.results || [], [ data ]);

    return (
        <>
            <Fade in={renderAlert}>
                <Alert
                    icon={<InfoIcon fontSize="inherit" />}
                    severity="info"
                    onClose={() => {
                        setRenderAlert(false);
                        alert?.onClose?.();
                    }}
                    style={{
                        position: 'absolute',
                        top: '140px',
                        zIndex: 5,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    {alert?.message}
                </Alert>
            </Fade>
            <UiGrid<T>
                {...rest}
                refresh={onRefresh}
                paginationProps={{
                    onPageChange,
                    onSearch,
                    onSort,
                    total: totalCount,
                    sort,
                    query: {
                        current: query,
                        set: setQuery,
                    },
                    page: {
                        current: currentPage,
                        set: setCurrentPage,
                    },
                }}
                data={(getGridData?.(data)) || defaultResults}
                loading={rest.loading || isValidating}
                error={error}
            />
        </>
    );
};
