import type { IEcommerceSuccess } from '@experiences/ecommerce';
import {
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import * as RouteNames from '../../../common/constants/RouteNames';
import { triggerPortalShellRefresh } from '../../../common/hooks/triggerPortalShellRefresh';

const useStyles = makeStyles(theme =>
    createStyles({
        greenIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            fontSize: '32px',
        },
    }),
);

const EcommerceSuccessComponent: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        triggerPortalShellRefresh();
    }, []);

    const close = useCallback(() => {
        navigate(getRoute(RouteNames.Licensing));
    }, [ getRoute, navigate ]);

    const ecommerceSuccess = useMemo(() => {
        if (location?.state?.ecommerceSuccess) {
            return location?.state?.ecommerceSuccess;
        }
        return {
            title: translate({ id: 'CLIENT_CONGRATULATIONS' }),
            line1: translate({ id: 'CLIENT_CONGRATS_PRO_SUBSCRIPTION_TEXT' }),
            line2: translate({ id: 'CLIENT_CONGRATS_TEXT_2' }),
            button: 'DISMISS',
        } as IEcommerceSuccess;
    }, [ location?.state?.ecommerceSuccess, translate ]);

    return (
        <UiDialog
            close={close}
            title={ecommerceSuccess?.title}
            icon={<CheckCircleIcon className={classes.greenIcon} />}
            width="426px"
            dataCy="ecommerce-success"
            actions={[
                <Button
                    variant={ecommerceSuccess.button === 'DISMISS' ? 'outlined' : 'contained'}
                    onClick={close}
                    key="close"
                    data-cy="dialog-dismiss-button">
                    {translate({ id: `CLIENT_${ecommerceSuccess.button}` })}
                </Button>,
            ]}
        >
            <UiText
                style={{ marginBottom: '10px' }}
                data-cy="congrats-text">
                {ecommerceSuccess?.line1}
            </UiText>
            <UiText>
                {ecommerceSuccess?.line2}
            </UiText>
        </UiDialog>
    );
};

export default EcommerceSuccessComponent;
