import {
    getPublishersWithGroupedTopics,
    getPublishersWithSeverity,
    isTopicGroupModeIndeterminate,
    isTopicGroupModeSubscribed,
} from '../helpers/topicsGrouper';
import type {
    INotificationMode,
    INotificationPublisherDto,
    INotificationSettingsAction,
    INotificationSettingsActionRestoreDefaultData,
    INotificationSettingsActionUpdateModeData,
    INotificationSettingsActionUpdateTabIndexData,
    INotificationSettingsActionUpdateTopicData,
    INotificationSettingsReducer,
    INotificationSeverityModeData,
} from '../interfaces/notificationSettings';
import { ActionTypes as ACTION_TYPE } from './actionTypes';

export const initialNotificationSettingsReducerState = {
    tabIndex: 0,
    publishers: [],
    publishersWithGroupedTopics: [],
    publishersWithSeverity: [],
} as INotificationSettingsReducer;

function processPublishers(publishers: INotificationPublisherDto[]) {
    const publishersWithGroupedTopics = getPublishersWithGroupedTopics(publishers);
    const publishersWithSeverity = getPublishersWithSeverity(publishersWithGroupedTopics);
    return {
        publishers,
        publishersWithGroupedTopics,
        publishersWithSeverity,
    };
}

export const notificationSettingsReducer =
(state: INotificationSettingsReducer, action: INotificationSettingsAction): INotificationSettingsReducer => {
    switch (action.type) {
        case ACTION_TYPE.NS_INITIALIZE_NOTIFICATION_SETTINGS: {
            const publisherData = action.data;
            return {
                tabIndex: state.tabIndex >= publisherData.length ? 0 : state.tabIndex,
                ...processPublishers(publisherData),
            };
        }
        case ACTION_TYPE.NS_SET_TAB_INDEX: {
            const actionData: INotificationSettingsActionUpdateTabIndexData = action.data;

            return {
                ...state,
                tabIndex: actionData.tabIndex,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION: {
            return toggleTopicSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed, mode,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];
            const targetTopic = targetPublisher.topicGroups[groupIndex!].topics[topicIndex!];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }
                return {
                    ...publisher,
                    topics: publisher.topics.map((topic) => {
                        if (topic.name !== targetTopic.name) {
                            return topic;
                        }
                        return {
                            ...topic,
                            isSubscribed: isSubscribed!,
                            modes: topic.modes?.map(topicMode => {
                                if (topicMode.name !== mode) {
                                    return topicMode;
                                }
                                return {
                                    ...topicMode,
                                    isSubscribed: isSubscribed!,
                                    isUpdating: false,
                                };
                            }),
                        };
                    }),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed, mode,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];
            const targetTopicGroup = targetPublisher.topicGroups[groupIndex!];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }
                return {
                    ...publisher,
                    topics: publisher.topics.map((topic) => {
                        if (topic.group !== targetTopicGroup.groupName &&
                            !(topic.group == null && targetTopicGroup.groupName === 'un-grouped')) {
                            return topic;
                        }
                        return {
                            ...topic,
                            isSubscribed: isSubscribed!,
                            modes: topic.modes?.map(topicMode => {
                                if (topicMode.name !== mode) {
                                    return topicMode;
                                }
                                return {
                                    ...topicMode,
                                    isSubscribed: isSubscribed!,
                                    isUpdating: false,
                                };
                            }),
                        };
                    }),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION: {
            return togglePublisherSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, mode, isSubscribed,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }
                return {
                    ...publisher,
                    topics: publisher.topics.map((topic) => ({
                        ...topic,
                        isSubscribed: isSubscribed!,
                        modes: topic.modes?.map(topicMode => {
                            if (topicMode.name !== mode) {
                                return topicMode;
                            }
                            return {
                                ...topicMode,
                                isSubscribed: isSubscribed!,
                                isUpdating: false,
                            };
                        }),
                    })),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_RESET: {
            const {
                publisherIndex, isSubscribed,
            } = action.data;
            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];

            const newPublishers = state.publishersWithGroupedTopics.map((publisher) => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }

                const updatedTopicGroups = publisher.topicGroups.map(topicGroup => ({
                    ...topicGroup,
                    isMandatoryChecked: false,
                    isMandatoryIndeterminate: false,
                    isVisible: true,
                    isUpdating: false,
                    topics: topicGroup.topics.map(topic => ({
                        ...topic,
                        isSubscribed: isSubscribed!,
                        isMandatory: false,
                        isVisible: true,
                        modes: topic.modes?.map(topicMode => ({
                            ...topicMode,
                            isUpdating: false,
                            isSubscribed: topicMode.isSubscribedByDefault,
                            isIndeterminate: false,
                        })),
                    })),
                    modes: topicGroup.modes?.map(topicMode => ({
                        ...topicMode,
                        isSubscribed: isTopicGroupModeSubscribed(topicMode.name, topicGroup),
                        isIndeterminate: isTopicGroupModeIndeterminate(topicMode.name, topicGroup),
                        isUpdating: false,
                    })),
                }));

                return {
                    ...publisher,
                    isMandatory: false,
                    isMandatoryInDeterminate: false,
                    isVisible: true,
                    isUpdating: false,
                    topicGroups: updatedTopicGroups,
                    modes: publisher.modes?.map(topicMode => ({
                        ...topicMode,
                        isUpdating: false,
                        isSubscribed: topicMode.isSubscribedByDefault ?? isSubscribed,
                        isIndeterminate: false,
                    })),
                };
            });

            const publishersWithSeverity = getPublishersWithSeverity(newPublishers);

            return {
                ...state,
                publishersWithGroupedTopics: newPublishers,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED: {
            return togglePublisherSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE: {
            return toggleChannelModeUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE_UPDATED: {
            const {
                publisherIndex, modeIndex, newModeStatus,
            } = action.data;

            const updatedPublishersWithGroupedTopics = state.publishersWithGroupedTopics.map((publisher, pIndex) => {
                if (pIndex !== publisherIndex) {
                    return publisher;
                }

                const updatedModes = publisher.modes.map((mode, mIndex) => {
                    if (mIndex !== modeIndex) {
                        return mode;
                    }

                    return {
                        ...mode,
                        isActive: newModeStatus!,
                        isUpdating: false,
                    };
                });

                return {
                    ...publisher,
                    modes: updatedModes,
                };
            });

            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE_UPDATE_FAILED: {
            return toggleChannelModeUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS: {
            return toggleRestoreSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED: {
            const { publisherIndex } = action.data;
            const targetPublisherName = state.publishersWithGroupedTopics[publisherIndex].name;

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisherName) {
                    return publisher;
                }

                const updatedTopics = publisher.topics.map(topic => ({
                    ...topic,
                    isSubscribed: true,
                    modes: topic.modes?.map(topicMode => ({
                        ...topicMode,
                        isSubscribed: topicMode.isSubscribedByDefault,
                        isUpdating: false,
                    })),
                }));

                return {
                    ...publisher,
                    topics: updatedTopics,
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED: {
            return toggleRestoreSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY: {
            return toggleTopicGroupSeveritySubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED: {
            const {
                publisherid,
                severity,
                isSubscribed,
                mode,
            } = action.data as INotificationSeverityModeData;

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.id !== publisherid) {
                    return publisher;
                }

                const updatedTopics = publisher.topics.map(topic => {
                    if (topic.category !== severity || topic.isMandatory) {
                        return topic;
                    }

                    const updatedModes = topic.modes?.map(topicMode => {
                        if (topicMode.name !== mode) {
                            return topicMode;
                        }

                        return {
                            ...topicMode,
                            isSubscribed: isSubscribed!,
                        };
                    });

                    return {
                        ...topic,
                        isSubscribed: isSubscribed!,
                        modes: updatedModes,
                    };
                });

                return {
                    ...publisher,
                    topics: updatedTopics,
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED_FAILED: {
            return toggleTopicGroupSeveritySubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY: {
            return toggleTopicSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];
            const targetTopicGroup = targetPublisher.topicGroups[groupIndex!];
            const targetTopic = targetTopicGroup.topics[topicIndex!];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }

                return {
                    ...publisher,
                    topics: publisher.topics.map(topic => {
                        if (topic.name !== targetTopic.name) {
                            return topic;
                        }

                        return {
                            ...topic,
                            isMandatory: isSubscribed!,
                        };
                    }),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];
            const targetTopicGroup = targetPublisher.topicGroups[groupIndex!];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }

                return {
                    ...publisher,
                    topics: publisher.topics.map(topic => {
                        if (topic.group !== targetTopicGroup.groupName || !topic.isVisible) {
                            return topic;
                        }

                        return {
                            ...topic,
                            isMandatory: isSubscribed!,
                        };
                    }),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION: {
            return togglePublisherSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, isSubscribed,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }

                return {
                    ...publisher,
                    topics: publisher.topics.map(topic => ({
                        ...topic,
                        isMandatory: isSubscribed!,
                    })),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATE_FAILED: {
            return togglePublisherSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE: {
            return toggleTopicSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];
            const targetTopicGroup = targetPublisher.topicGroups[groupIndex!];
            const targetTopic = targetTopicGroup.topics[topicIndex!];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }

                return {
                    ...publisher,
                    topics: publisher.topics.map((topic) => {
                        if (topic.name !== targetTopic.name) {
                            return topic;
                        }

                        return {
                            ...topic,
                            isVisible: isSubscribed!,
                            isMandatory: false,
                            isSubscribed: false,
                            modes: topic.modes?.map(topicMode => ({
                                ...topicMode,
                                isSubscribed: false,
                                isIndeterminate: false,
                            })),
                        };
                    }),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action.data, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action.data, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed,
            } = action.data;

            const targetPublisher = state.publishersWithGroupedTopics[publisherIndex];
            const targetTopicGroup = targetPublisher.topicGroups[groupIndex!];

            const updatedPublishers = state.publishers.map(publisher => {
                if (publisher.name !== targetPublisher.name) {
                    return publisher;
                }

                return {
                    ...publisher,
                    topics: publisher.topics.map((topic) => {
                        if (topic.group !== targetTopicGroup.groupName) {
                            return topic;
                        }

                        return {
                            ...topic,
                            isVisible: isSubscribed!,
                            isMandatory: false,
                            isSubscribed: false,
                            modes: topic.modes?.map(topicMode => ({
                                ...topicMode,
                                isSubscribed: false,
                                isIndeterminate: false,
                            })),
                        };
                    }),
                };
            });

            return {
                ...state,
                ...processPublishers(updatedPublishers),
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action.data, state, false);
        }
        default: {
            return state;
        }
    }
};

function toggleTopicSubscriptionUpdate(
    data: INotificationSettingsActionUpdateTopicData, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, topicIndex, mode,
    } = data;

    const updatedPublishersWithGroupedTopics = state.publishersWithGroupedTopics.map((publisher, pIndex) => {
        if (pIndex !== publisherIndex) {
            return publisher;
        }

        return {
            ...publisher,
            topicGroups: publisher.topicGroups.map((group, gIndex) => {
                if (gIndex !== groupIndex) {
                    return group;
                }

                return {
                    ...group,
                    topics: group.topics.map((topic, tIndex) => {
                        if (tIndex !== topicIndex) {
                            return topic;
                        }

                        const updatedTopic = {
                            ...topic,
                            isUpdating,
                        };

                        if (mode !== undefined) {
                            updatedTopic.modes = topic.modes?.map((m: INotificationMode) => {
                                if (m.name !== mode) {
                                    return m;
                                }

                                return {
                                    ...m,
                                    isUpdating,
                                };
                            });
                        }

                        return updatedTopic;
                    }),
                };
            }),
        };
    });

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleTopicGroupSubscriptionUpdate(
    data: INotificationSettingsActionUpdateTopicData, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, mode,
    } = data;

    const updatedPublishersWithGroupedTopics = state.publishersWithGroupedTopics.map((publisher, pIndex) => {
        if (pIndex !== publisherIndex) {
            return publisher;
        }

        return {
            ...publisher,
            topicGroups: publisher.topicGroups.map((group, gIndex) => {
                if (gIndex !== groupIndex) {
                    return group;
                }

                const pGroup = {
                    ...group,
                    isUpdating,
                };
                if (mode !== undefined) {
                    pGroup.modes = pGroup.modes?.map((m: INotificationMode) => {
                        if (m.name !== mode) {
                            return m;
                        }
                        return {
                            ...m,
                            isUpdating,
                        };
                    });
                }
                return pGroup;
            }),
        };
    });

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleTopicGroupSeveritySubscriptionUpdate(
    data: INotificationSettingsActionUpdateTopicData, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, mode,
    } = data;

    const updatedPublishersWithSeverity = state.publishersWithSeverity.map((publisher, pIndex) => {
        if (pIndex !== publisherIndex) {
            return publisher;
        }

        const updatedTopicGroups = publisher.topicGroups.map((group, gIndex) => {
            if (gIndex !== groupIndex) {
                return group;
            }

            const updatedModes = group.modes?.map(m => {
                if (m.name !== mode) {
                    return m;
                }

                return {
                    ...m,
                    isUpdating,
                };
            });

            return {
                ...group,
                isUpdating,
                modes: updatedModes,
            };
        });

        return {
            ...publisher,
            topicGroups: updatedTopicGroups,
        };
    });

    return {
        ...state,
        publishersWithSeverity: updatedPublishersWithSeverity,
    };
}

function togglePublisherSubscriptionUpdate(
    data: INotificationSettingsActionUpdateTopicData, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, mode,
    } = data;

    const updatedPublishersWithGroupedTopics = state.publishersWithGroupedTopics.map((publisher, pIndex) => {
        if (pIndex !== publisherIndex) {
            return publisher;
        }

        let updatedModes = publisher.modes;
        if (mode !== undefined) {
            updatedModes = publisher.modes?.map((m: INotificationMode) => {
                if (m.name !== mode) {
                    return m;
                }

                return {
                    ...m,
                    isUpdating,
                };
            });
        }

        return {
            ...publisher,
            isUpdating,
            modes: updatedModes,
        };
    });

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleChannelModeUpdate(
    data: INotificationSettingsActionUpdateModeData, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, modeIndex,
    } = data;

    const updatedPublishersWithGroupedTopics = state.publishersWithGroupedTopics.map((publisher, pIndex) => {
        if (pIndex !== publisherIndex) {
            return publisher;
        }

        const updatedModes = publisher.modes.map((mode, mIndex) => {
            if (mIndex !== modeIndex) {
                return mode;
            }

            return {
                ...mode,
                isUpdating,
            };
        });

        return {
            ...publisher,
            modes: updatedModes,
        };
    });

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleRestoreSubscriptionUpdate(
    data: INotificationSettingsActionRestoreDefaultData, state: INotificationSettingsReducer, isUpdating: boolean) {
    const { publisherIndex } = data;
    const targetPublisherName = state.publishersWithGroupedTopics[publisherIndex].name;

    const updatedPublishersWithGroupedTopics = state.publishersWithGroupedTopics.map(publisher => {
        if (publisher.name !== targetPublisherName) {
            return publisher;
        }

        const updatedModes = publisher.modes?.map(mode => ({
            ...mode,
            isUpdating,
        }));

        return {
            ...publisher,
            isUpdating,
            modes: updatedModes,
        };
    });

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}
