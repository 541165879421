import { UiText } from '@experiences/ui-common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import {
    createStyles,
    makeStyles,
    withStyles,
} from '@mui/styles';
import type { ReactNode } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        heading: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
    }),
);

const Accordion = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        marginTop: '10px',
        '&$expanded': { marginTop: '10px' },
        '&:before': { display: 'none' },
    },
    expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles({
    root: {
        paddingLeft: '0px',
        paddingRight: '0px',
    },
})(MuiAccordionDetails);

const AccordionSummary = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        minHeight: '38px',
        height: '38px',
        '&$expanded': { minHeight: '38px' },
    },
    content: { '&$expanded': {} },
    expanded: {},
}))(MuiAccordionSummary);

export const UiAccordion: React.FC<{
    children?: ReactNode;
    titleTranslationCode: string;
    expandedByDefault?: boolean;
    disabled?: boolean;
    onChange?: (expanded: boolean) => void;
}> = ({
    children, titleTranslationCode, expandedByDefault = true, disabled = false, onChange = null,
}) => {
    const classes = useStyles();
    const [ expanded, setExpanded ] = React.useState<boolean>(expandedByDefault);
    const { formatMessage: translate } = useIntl();
    const handleChange = () => {
        setExpanded(!expanded);

        if (onChange) {
            onChange(!expanded);
        }
    };

    return (
        <Accordion
            expanded={expanded}
            disabled={disabled}
            onChange={handleChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`${titleTranslationCode}Accordion`}>
                <UiText
                    className={classes.heading}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: titleTranslationCode })}
                </UiText>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
