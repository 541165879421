import { MessageTemplateName } from '../common/constants/MessageTemplateConstant';
import type {
    ICreateMessageTemplateDto,
    IMessageTemplate,
} from '../common/interfaces/cis/message-template';
import type { ILoginCustomizationData } from '../component/organizationsettings/subcomponents/types/login-customization';
import { decodeXmlCharacters } from './DecodeSanitizedHtml';

export const mapToLoginCustomizationData = (loginLayoutMessageTemplate?: IMessageTemplate): ILoginCustomizationData =>
    ({ loginLayout: loginLayoutMessageTemplate?.value ? decodeXmlCharacters(loginLayoutMessageTemplate.value) : '' });

export const mapLoginCustomizationDataToMessageTemplate = (
    loginCustomizationData: ILoginCustomizationData,
    loginLayoutMessageTemplate: IMessageTemplate,
): IMessageTemplate => ({
    ...loginLayoutMessageTemplate,
    value: loginCustomizationData.loginLayout,
});

export const mapLoginCustomizationDataToCreateMessageTemplateDto = (
    loginCustomizationData: ILoginCustomizationData,
): ICreateMessageTemplateDto => ({
    name: MessageTemplateName.LoginLayout,
    value: loginCustomizationData.loginLayout,
});
